import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { EcoSessionState } from '@common/modules/session/state/session.state';
import { UsersApiService } from '@common/services/api/sg/user/userApi.service';
import { ExternalLinkService } from '@common/services/external-links.service';
import { TeamsAuthService } from '@common/services/teams-auth.service';
import { JwtTokenUtil } from '@common/utils/jwt-token-util';
import { Select, Store } from '@ngxs/store';
import { CarouselDialogComponent } from 'projects/@assist-ops/components/carousel-dialog/carousel-dialog.component';
import { DisplayService } from 'projects/@common/modules/display/display.service';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { SessionService } from 'projects/@common/modules/session/session.service';
import { GraphToolkitService } from 'projects/@common/services/graph-toolkit.service';
import { MobileService } from 'projects/@common/services/mobile.service';
import { StorageService } from 'projects/@common/services/storage.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'saine-gestion-root',
  templateUrl: './saine.gestion.app.component.html',
})
export class SaineGestionComponent implements OnInit {
  @Select(EcoSessionState.authentified)
  public isAuthentified$: Observable<boolean>;
  @Select(EcoSessionState.loginMatrix) public loginMatrix$: Observable<{
    [key: string]: { [key: string]: string; };
  }>;
  public loaded = false;
  public isInTeams = TeamsAuthService.isInTeams;

  constructor(
    private i18n: I18nService,
    private session: SessionService,
    private display: DisplayService,
    private graphToolkitService: GraphToolkitService,
    private teamsAuthService: TeamsAuthService,
    private router: Router,
    private store: Store,
    private dialog: MatDialog,
    private storage: StorageService,
    public mobileService: MobileService,
    private externalLinksService: ExternalLinkService
  ) {
  }

  async ngOnInit() {
    await this.i18n.initialize();
    await this.session.loadBrowserSessionIfNeeded();
    await this.display.loadDataFromBrowserIfNeeded();
    await this.graphToolkitService.activateGraphToolkit();
    await this.initTeams();
    this.loaded = true;
    this.isAuthentified$.subscribe((value) => {
      this.openCarouselDialog(value);

      if (!value) {
        this.dialog.closeAll();
      }
    });

  }

  private async initTeams() {
    if (TeamsAuthService.isInTeams) {
      const token = await this.teamsAuthService.init();
      const decodedToken = JwtTokenUtil.decodeJwtToken(token);
      await this.i18n.setCurrentLocale(this.teamsAuthService.locale);
      if (this.store.selectSnapshot(EcoSessionState.userGuid) === decodedToken?.oid && this.store.selectSnapshot(EcoSessionState.authentified)) {
        this.router.navigate([this.teamsAuthService.tabRoute]);
      } else {
        this.router.navigate([`/login/office`], {
          queryParams: { jwt: token, state: JSON.stringify({ email: decodedToken?.upn }) },
        });
      }
    }
  }

  private openCarouselDialog(isAuthentified: boolean): void {
    let loginMatrix;
    const hasCarouselBeenOpened = this.storage.getLocal('carouselOpen');
    this.loginMatrix$.subscribe((value) => (loginMatrix = value));

    if (
      !loginMatrix?.sg?.desktop &&
      !this.mobileService.isMobile() &&
      !hasCarouselBeenOpened &&
      isAuthentified
    ) {
      this.dialog.open(CarouselDialogComponent, {
        width: '75%',
        minWidth: '720px',
        maxWidth: '1000px',
        panelClass: 'custom-dialog',
      });
    }
  }
}
