<div class="expiration-date">
  <ui-date-picker
    label="guestUser.detail.expirationDate"
    [minDate]="expirationDateMinimum"
    [defaultTimestamp]="user.expirationDate"
    (onDateSelection)="onExpirationDateSelected($event)"
  ></ui-date-picker>
  <div *ngIf="guestUserPeriodInDays" class="expiration-time">
    {{
      'guestUser.detail.guestUserPeriodInDays'
        | t: { days: guestUserPeriodInDays }
    }}
  </div>
</div>
