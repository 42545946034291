import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UserDashboardContainer } from '../user-dashboard/user-dashboard.component';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';

export enum CollapsableCardEnum {
  BREAKTIME = 'breaktime',
  CYBER_BEHAVIOR = 'cyberBehavior',
  SHARED_ASSETS = 'sharedAssets',
  FAVORITES = 'favorites'
}

export interface IMobileCollapseCard {
  name: CollapsableCardEnum;
  isCollapsed: boolean;
}

@Component({
  selector: 'user-dashboard-mobile',
  templateUrl: './user-dashboard-mobile.container.html',
  styleUrls: [ '../dashboard.container.scss' ],
  encapsulation: ViewEncapsulation.None,
})
export class UserDashboardMobileContainer extends UserDashboardContainer implements OnInit {
  public dashboardTitle: string;

  public breaktimeCard: IMobileCollapseCard = {
    name: CollapsableCardEnum.BREAKTIME,
    isCollapsed: true,
  };

  public cyberBehaviorCard: IMobileCollapseCard = {
    name: CollapsableCardEnum.CYBER_BEHAVIOR,
    isCollapsed: true,
  };

  public sharedAssetsCard: IMobileCollapseCard = {
    name: CollapsableCardEnum.SHARED_ASSETS,
    isCollapsed: true,
  };

  public favoritesCard: IMobileCollapseCard = {
    name: CollapsableCardEnum.FAVORITES,
    isCollapsed: true,
  };

  public collapsableCardEnum = CollapsableCardEnum;

  constructor(protected readonly i18n: I18nService) {
    super();
  }

  ngOnInit(): void {
    const translatedHello = this.i18n.translate('common.hello');
    this.dashboardTitle = `${translatedHello} ${this.currentUser.firstName || this.currentUser.name}`;
  }

  handleCallapsable(isCollapsed: boolean, card: CollapsableCardEnum) {
    const collapseCards = [ this.breaktimeCard, this.cyberBehaviorCard, this.sharedAssetsCard, this.favoritesCard ];
    for (const collapseCard of collapseCards) {
      if (collapseCard.name === card) {
        collapseCard.isCollapsed = !isCollapsed;
      } else {
        collapseCard.isCollapsed = true;
      }
    }
  }
}
