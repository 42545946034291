import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';

@Component({
  selector: 'dashboard-card',
  templateUrl: './dashboard-card.component.html',
  styleUrls: [ './dashboard-card.component.scss' ],
})
export class DashboardCardComponent implements OnInit {
  @Input() public cardTitle = '';

  @Input() public cardSecondTitle = '';

  @Input() public isExpended = true;

  @Input() public iconName: string;

  @Input() public displayAvatar = false;

  public currentUser: any;

  constructor(private readonly store: Store) {}

  ngOnInit(): void {
    this.currentUser = this.store.selectSnapshot((state) => state.session?.user);
  }

  public toggleExpanded() {
    this.isExpended = !this.isExpended;
  }
}
