<div class="drawer-detail--scrollable visible">
  <div class="drawer-detail--content">
    <ui-title [name]="'requests.drawer.history.title' | t"></ui-title>

    <ui-spinner *ngIf="isLoading; else history"></ui-spinner>

    <ng-template #history>
      <div
        *ngFor="let requestApprover of requestApproversHistory; let last = last"
        class="history-element"
      >
        <section
          class="history-element--header"
          (click)="toggleRow(requestApprover)"
          [class.expended-approver]="
            requestApprover[EXPENDABLE_APPROVER_ATTRIBUTE]
          "
        >
          <div class="history-element--header--collapsable">
            <img
              [src]="
                'assets/favicons/icon_chevron_' +
                (requestApprover[EXPENDABLE_APPROVER_ATTRIBUTE]
                  ? 'open'
                  : 'close') +
                '.svg'
              "
            />
          </div>
          <div class="history-element--header--timestamp">
            {{ requestApprover.timestamp | completeDT }}
          </div>
          <div class="history-element--header--approver">
            {{ requestApprover.name }}
          </div>
          <div>
            <ui-static
              [label]="''"
              [value]="
                'requests.drawer.history.action.' +
                  (last ? 'CREATED' : requestApprover.status) | t
              "
              [iconName]="getActionIcon(requestApprover.status)"
              [isCustomIcon]="true"
              [isSvg]="true"
            ></ui-static>
          </div>
        </section>

        <div
          *ngIf="requestApprover[EXPENDABLE_APPROVER_ATTRIBUTE]"
          class="history-element--justification expended-approver"
        >
          <ui-field-group *ngIf="!last">
            <ui-static
              [label]="'requests.drawer.history.approverRole' | t"
              [value]="
                (requestApprover.type
                  ? 'requests.drawer.history.approverRole.' +
                    requestApprover.type
                  : 'common.system'
                ) | t
              "
            ></ui-static>
          </ui-field-group>

          <ui-field-group>
            <ui-switch-text-static
              [label]="(
                last
                  ? 'requests.drawer.history.justification'
                  : 'requests.drawer.history.note') | t
              "
              [value]="requestApprover.note"
            ></ui-switch-text-static>
          </ui-field-group>
        </div>
      </div>
    </ng-template>
  </div>
</div>
