import { Injectable } from '@angular/core';
import { State, Selector, StateContext, Action } from '@ngxs/store';
import { clone } from 'projects/@common/utils/utils';

export class ReloadSharedFiles {
  static readonly type = '[Shares] Reload';
}

export interface ShareStateModel {
  reloadAccumulator: number;
}

export const initialState: ShareStateModel = {
  reloadAccumulator: 0,
};

@State<ShareStateModel>({
  name: 'shares',
  defaults: clone(initialState),
})
@Injectable()
export class SharesState {
  public constructor() {}

  @Selector()
  public static reloadAccumulator(state: ShareStateModel): number {
    return state.reloadAccumulator;
  }

  @Action(ReloadSharedFiles)
  public reloadSharedFiles(ctx: StateContext<ShareStateModel>): void {
    const value = ctx.getState().reloadAccumulator;
    ctx.patchState({
      reloadAccumulator: value + 1,
    });
  }
}
