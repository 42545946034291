<div class="container">
  <p>
  {{ ('groups.dialog.structure.info.' + template.type) | t }}
  </p>
  <span class="radio-buttons-title">
    <p class="title">{{ 'groups.dialog.structure.apply.question' | t: { resourceType: 'groups.dialog.' + template.type + '.short' | t } }}</p>
    <ui-tooltip  icon="assets/favicons/icon_info_small.svg">
      {{ ('groups.dialog.structure.tooltip.notImpacted.' + template.type) | t }}
      <br>
      <br>
      <ng-container *ngIf="template.type === groupTypeEnum.O365OFFICETEAMS">
        {{ ('groups.dialog.structure.tooltip.' + groupTypeEnum.O365OFFICETEAMS) | t }}
        <br>
        <br>
      </ng-container>
      {{ ('groups.dialog.structure.tooltip.' + groupTypeEnum.O365OFFICEGROUP) | t }}
    </ui-tooltip>
  </span>
  <form [formGroup]="structureForm">
  <mat-radio-group class="radio-buttons" formControlName="keepStructure" >
    <mat-radio-button [value]="true">
      <p class="no-margin">{{ 'groups.dialog.structure.button.yes' | t }}</p>
    </mat-radio-button>
    <mat-radio-button [value]="false">
      <p class="no-margin">{{ 'groups.dialog.structure.button.no' | t }}</p>
    </mat-radio-button>
  </mat-radio-group>
  </form>

  <p class="title">
  {{ 'groups.dialog.structure.preview' | t }} 
  </p>

  <div class="structure">
    <div class="model-info">
      <sg-model-information 
         [template]="template" 
         [showExpiration]="false"
         [expandChannels]="true"
         [showPermissions]="false"
         ></sg-model-information>
    </div>
  </div>
</div>

