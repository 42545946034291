import { Directive, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { StepperNavigation } from './sg-stepper.component';

@Directive({
  selector: '[sg-step]',
})
export class SgStep {
  @Input() public stepName = '';

  @Input() public saveButton = false;

  @Input() public canGoBack = true;

  @Input() public canGoForward = true;

  @Input() public customFooter: TemplateRef<any>;

  @Output() public onNavigation: EventEmitter<{ index: number, direction: StepperNavigation }> = new EventEmitter();

  constructor(public templateRef: TemplateRef<any>) {}
}
