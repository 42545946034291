import { Pipe, PipeTransform } from '@angular/core';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { FileShareTypeEnum } from 'projects/@common/services/api/sg/shares/shares.definitions';


@Pipe({
  name: 'expositionTranslate',
})
export class ExpositionTranslatePipe implements PipeTransform {
  constructor(protected readonly i18n: I18nService) {}

  public transform(value: string): string {
    if (
      value === FileShareTypeEnum.PUBLIC ||
      value === FileShareTypeEnum.COMPANY ||
      value === FileShareTypeEnum.SECURITY_GROUP
    ) {
      return this.i18n.translate(`shares.list.expositionsNbr.${value}.value`);
    }
    return value;
  }
}
