import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AutocompleteTypes } from '@ui-kit/components/autocomplete/autocomplete.component';
import { GroupTypeEnum } from '@ui-kit/util/icon-util';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import {
  GroupTemplateReach,
  IGroupTemplate
} from 'projects/@common/services/api/sg/group-templates/group-templates.definitions';
import { UsersApiService } from 'projects/@common/services/api/sg/user/userApi.service';

interface Manager {
  name: string;
  o365UserId: string;
  mail: string
}

@Component({
  selector: 'sg-request-section',
  templateUrl: './request-section.component.html',
  styleUrls: [ './request-section.component.scss' ],
})
export class RequestSectionComponent implements OnInit {
  @Input() public template: IGroupTemplate;
  @Input() public resourceName: string;
  @Input() public justification: string;
  @Input() public managers: Manager[];

  public usersAutocomplete = AutocompleteTypes.USERS_OFFLINE;
  public requestForm: UntypedFormGroup;
  public managersFormArray: UntypedFormArray;
  public usersToSearch: Manager[];

  public isLoadingApprovers = false;

  constructor(
    private i18n: I18nService,
    private fb: UntypedFormBuilder,
    private usersApiService: UsersApiService
  ) {}

  ngOnInit(): void {
    this.initFormGroup();
    this.updateDefaultApproversBasedOnReach();
  }

  public get approbationTooltip(): string {
    switch (this.template?.type) {
      case GroupTypeEnum.O365OFFICEGROUP:
        return this.i18n.translate('requests.drawer.creation.manager.tooltip', {
          type: this.i18n.translate('groupO365.filter').toLowerCase(),
        });
      case GroupTypeEnum.O365OFFICETEAMS:
        return this.i18n.translate('requests.drawer.creation.manager.tooltip', {
          type: this.i18n.translate('SecurityGroup.filter').toLowerCase(),
        });
      default:
        return this.i18n.translate('requests.drawer.creation.manager.tooltip', {
          type: this.i18n.translate('SecurityGroup.filter').toLowerCase(),
        });
    }
  }

  public get selectedIds(): string[] {
    return this.selectedManagers?.map((manager) => manager?.o365UserId) || [];
  }

  public get selectedManagers(): Manager[] {
    return this.requestForm?.value?.managers;
  }

  public getTranslatedGroupType(template: IGroupTemplate): string {
    return this.i18n.translate(`groups.dialog.${template?.type}.short`);
  }

  public addManager(user: any): void {
    if (user) {
      this.managersFormArray.push(this.fb.control({
        name: user.name,
        o365UserId: user.o365UserId,
      }));
    }
  }

  public removeManager(owner: any): void {
    const index = this.managersFormArray.controls.findIndex((control) => control.value.o365UserId === owner.o365UserId);
    this.managersFormArray.removeAt(index);
  }

  private updateDefaultApproversBasedOnReach() {
    if (this.template.reach === GroupTemplateReach.GLOBAL) {
      this.isLoadingApprovers = true;
      this.usersApiService
        .listResourceApprovers({ usersOnly: true })
        .then((response) => {
          this.template.defaultApprovers = response.items.map((defaultApprover) => ({
            o365UserId: defaultApprover.id,
            firstName: defaultApprover.displayName,
            mail: defaultApprover.mail,
          }));

          this.usersToSearch = this.formattedApprovers;
          if (this.template.defaultApprovers?.length > 2) {
            this.managersFormArray.clear();
          }
          this.initFormGroup();
        })
        .finally(() => (this.isLoadingApprovers = false));
    } else {
      this.usersToSearch = this.template?.defaultApprovers?.map((defaultApprover) => ({
        name: defaultApprover.firstName,
        o365UserId: defaultApprover.o365UserId,
        mail: defaultApprover.mail,
      }));
    }
  }

  private initFormGroup(): void {
    let managers = this.template.defaultApprovers?.length <= 2 ? this.formattedApprovers : [];
    if (this.managers?.length > 0) {
      managers = this.managers;
    }
    this.managersFormArray = this.fb.array([], Validators.required);
    for (const manager of managers) {
      this.managersFormArray.push(this.fb.group(manager));
    }

    this.requestForm = this.fb.group({
      description: this.fb.control(this.resourceName || null, [ Validators.required ]),
      justification: this.fb.control(this.justification, [ Validators.required ]),
      managers: this.managersFormArray,
    });
  }

  private get formattedApprovers() {
    return this.template.defaultApprovers?.map((defaultApprover) => ({
      name: defaultApprover.firstName,
      o365UserId: defaultApprover.o365UserId,
      mail: defaultApprover.mail,
    }));
  }
}
