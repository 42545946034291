import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { BaseDialogComponent } from 'projects/@assist-ops/components/base-dialog/base-dialog.component';


@Component({
  selector: 'certify-help-dialog',
  templateUrl: './certify-help-dialog.component.html',
  styleUrls: [ './certify-help-dialog.component.scss' ],
})
export class CertifyHelpDialogComponent implements OnInit {
  constructor (public dialogRef: MatDialogRef<BaseDialogComponent>) {}

  ngOnInit(): void {
  }
}
