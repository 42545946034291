import { Component, OnInit } from '@angular/core';
import { DisplayService } from '@common/modules/display/display.service';
import { FeatureFlagsEnum } from '@common/modules/layout/definitions/base-sidebar.service';
import { UsersApiService } from '@common/services/api/sg/user/userApi.service';
import { UiTabsColor } from '@ui-kit/components/ui-tabs/ui-tabs.component';
import { IBreadcrumbs } from 'projects/@assist-ops/components/breadcrumbs/breadcrumbs.component';
import { UserProfile } from 'projects/@assist-ops/components/user-info-dashboard/user-info-dashboard.component';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import {
  BadgeEnum,
  IConformities,
  IUserAction,
} from 'projects/@common/services/api/sg/md.eco-profile/md.eco-profile.definition';
import { DashboardProfileService } from 'projects/@common/services/dashboard-profile.service';
import { DOCUMENTATION_SECTION, generateDocumentationLink } from 'projects/@common/utils/eco.documentation-utils';

@Component({
  selector: 'sg-eco-profile',
  templateUrl: './eco-profile.component.html',
  styleUrls: ['./eco-profile.component.scss'],
})
export class EcoProfileComponent implements OnInit {
  public documentationSection = generateDocumentationLink(
    DOCUMENTATION_SECTION.sg.dashboardProfileDetail,
    this.i18n.currentLocale
  );

  public userProfileData: UserProfile;
  public userProfileReporting: UserProfile & { hasUsersReporting: number; teamName: string };

  public hasUsersReporting = false;

  public uiTabsColor = UiTabsColor;

  public featureFlagEnum = FeatureFlagsEnum;

  private isFirstLoad = true;

  public breadcrumbs: IBreadcrumbs[] = [
    { nodeName: 'breadcrumb.dashboard', nodeLink: '/dashboard' },
    { nodeName: 'eco-profile.page.breadcrumbs', nodeLink: null },
  ];

  constructor(
    private dashboardProfileService: DashboardProfileService,
    private usersApiService: UsersApiService,
    private i18n: I18nService,
    private displayService: DisplayService
  ) {}

  public handleReportingRefresh(userIds: string[]) {
    this.dashboardProfileService.getDashboardReportingProfile(userIds, this.isFirstLoad);
  }

  public get displayReportingProfile(): boolean {
    return (
      this.displayService.meetsRequirements({ flags: [FeatureFlagsEnum.TEAM_PROFILE] }) &&
      this.hasUsersReporting
    );
  }

  ngOnInit(): void {
    this.usersApiService.listUsersReportingTo().then(response => this.hasUsersReporting = response.items.length > 0).catch(() =>{})

    this.dashboardProfileService.getDashboardUserProfile();
    this.dashboardProfileService.dashboardUserProfile.subscribe((value) => {
      this.userProfileData = {
        userActions: value.userActions,
        conformities: value.conformities,
        badges: value.badges,
      };
    });
    this.dashboardProfileService.getDashboardReportingProfile();
    this.dashboardProfileService.dashboardReporingProfile.subscribe((value) => {
      this.userProfileReporting = {
        userActions: value.data.userActions,
        conformities: value.data.conformities,
        badges: value.data.badges,
        hasUsersReporting: value.hasUsersReporting,
        riskFactors: this.isFirstLoad ? value.data.riskFactors : this.userProfileReporting.riskFactors,
        teamName: this.isFirstLoad ? value.teamName : this.userProfileReporting.teamName,
      };
      this.isFirstLoad = false;
    });
  }
}
