import { Component, ViewEncapsulation, Input } from '@angular/core';

@Component({
  selector: 'requests-table-process-number',
  templateUrl: './requests-table-process-number.component.html',
  styleUrls: [ './requests-table-process-number.component.scss' ],
  host: {
    class: 'requests-table-process-number',
  },
  encapsulation: ViewEncapsulation.None,
})
export class RequestsTableProcessNumber {
  @Input() number = 0;
}
