import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { UiStaticTableRowType } from '@ui-kit/components/ui-static-table/ui-static-table-row/ui-static-table-row.component';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { FileActivityTypeEnum, IFileActivity } from 'projects/@common/services/api/sg/shares/shares.definitions';
import { LabelWithTextComponent } from './label-with-text/label-with-text.component';

@Component({
  selector: 'shared-file-activities-table',
  templateUrl: './shared-file-activities-table.component.html',
  styleUrls: [ './shared-file-activities-table.component.scss' ],
})
export class SharedFileActivitiesTableComponent implements OnInit, OnChanges {
  @Input() public activities: IFileActivity[];

  public columnsDef: any[] = [];

  public activitiesData: IFileActivity[];

  public translatedToolTip: string;

  constructor (private i18n: I18nService) {
  }

  ngOnChanges(): void {
    this.setupTableData();
  }

  public ngOnInit() {
    this.setupTableData();
    this.setupTableDef();
    this.translatedToolTip = this.i18n.translate('files.drawer.tabs.activities.tooltip');
  }

  public getSystemJustification(action: FileActivityTypeEnum): string {
    switch (action) {
      case FileActivityTypeEnum.ACTIVATION:
        return this.i18n.translate('files.drawer.tabs.activities.type.ActivateNotifications.system');
      default:
        return action;
    }
  }

  private setupTableData(): void {
    this.activitiesData = this.activities.map((activity) => ({
      activityType: activity.activityType,
      isAnonymous: activity.isAnonymous,
      timestamp: activity.timestamp,
      userEmail: activity.userEmail,
      userName: activity.userName,
      justification: activity.justification,
      isSystem: activity.isSystem,
      silencingEnds: activity.silencingEnds,
      __isNotExpandable: !(activity.justification || activity.isSystem),
    }));
  }

  private setupTableDef(): void {
    this.columnsDef = [
      {
        label: '',
        field: 'icon',
        type: UiStaticTableRowType.EXPENDABLE_ICON,
        showIcon: (row: IFileActivity) => !!row.justification || row.isSystem,
        width: '32px',
        isResizable: false,
      },
      {
        label: this.i18n.translate('files.drawer.tabs.activities.history'),
        field: 'activityType',
        type: UiStaticTableRowType.DYNAMIC_COMPONENT_HOST,
        component: LabelWithTextComponent,
        paramName: 'activity',
        isResizable: false,
        noSorting: true,
      },
      {
        label: this.i18n.translate('files.drawer.tabs.activities.date'),
        field: 'timestamp',
        type: UiStaticTableRowType.DATE_AND_HOUR,
        translationKeyPrefix: 'sharedFile.drawer.shares.table.permission.',
        isResizable: false,
        width: '140px',
      },
    ];
  }
}
