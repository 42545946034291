import { Directive, EventEmitter, HostListener, Output } from "@angular/core";

@Directive({
  selector: '[scrollToBottom]',
})
export class ScrollToBottomDirective {
  private lastScrollTop = 0;

  @Output() onScrollToBottom: EventEmitter<void> = new EventEmitter();

  @HostListener('scroll', [ '$event' ])
  onScroll(event: any) {
    const tableViewHeight = event.target?.offsetHeight;
    const tableScrollHeight = event.target?.scrollHeight;
    const scrollLocation = event.target?.scrollTop;
    if (tableViewHeight && tableScrollHeight && scrollLocation) {
      const limit = tableScrollHeight - tableViewHeight - 200;

      if (scrollLocation > limit && scrollLocation > this.lastScrollTop) {
        this.onScrollToBottom.emit();
      }
      this.lastScrollTop = scrollLocation;
    }
  }
}
