<base-dialog
  class="min-width"
  *ngIf="!showConfirmDialog"
  [title]="'certify.title'  | t : { groupName : data.group.displayName }"
  [isEditing]="isInSecondStep"
  [description]="(isInSecondStep ? '' : 'certify.second.title') | t"
  (onCancel)="dialogRef.close()"
>

  <ng-container *ngIf="isInSecondStep;else ownedGroupList">
    <ng-template *ngTemplateOutlet="groupMemberList"></ng-template>
  </ng-container>
</base-dialog>

<base-dialog
  *ngIf="showConfirmDialog"
  class="confirm-container"
  [title]="'certify.confirm.title' | t"
  [isEditing]="true"
>
  <div class="text">
    <ul>
      <li *ngIf="numberOfCertified > 0">{{ 'certify.confirm.description.1' | t: {number: numberOfCertified } }}</li>
      <li *ngIf="numberOfRevoke > 0">{{ 'certify.confirm.description.2' | t: {number: numberOfRevoke } }}</li>
    </ul>
    <p>{{ 'certify.confirm.description.continue' | t }} </p>
  </div>
  <div class="buttons">
    <button
      ui-button
      color="standard"
      [loading]="isSaving"
      (click)="onSave()"
    >{{'certify.confirm.button.confirm' | t}}</button>
    <button
      [disabled]="isSaving"
      ui-button
      color="secondary"
      (click)="toggleConfirmDialog()"
    >{{'certify.confirm.button.cancel' | t}}</button>
  </div>
</base-dialog>

<ng-template #ownedGroupList>
  <div
    class="group-list-container"
    scrollToBottom
    (onScrollToBottom)="loadMore()"
  >
    <p>{{ 'certify.group.selection' | t}}</p>
    <div class="buttons-container">
      <div class="toggle-button-container">
        <ui-toggle (onClick)="onToggle($event)"></ui-toggle>
        <span class="toggle-txt-description">{{ 'certify.toggle' | t }}</span>
      </div>
      <ui-table-search-tool
        [query]="search"
        [placeholder]="'ui.table-search-tool.placeholder'| t"
        [tooltipPlacementRight]="true"
        (clear)="onSearch(null)"
        (query)="onSearch($event)"
      ></ui-table-search-tool>
    </div>
    <p class="teams-and-sites-number-txt">
      {{ 'certify.teams.and.sites' | t: {number: resourceResponse?.ownedGroups?.length || 0} }}
    </p>
    <ng-container *ngIf="!isLoading">
      <ng-container *ngIf="resourceResponse?.ownedGroups.length > 0; else emptyList">
        <ng-container *ngFor="let ownedGroup of resourceResponse?.ownedGroups">
          <ui-title-description-button
            [name]="ownedGroup.displayName"
            [description]="ownedGroup.certificationInformation.lastValidation ?  getLastValidationTranslation(ownedGroup.certificationInformation) : ('certify.update' | t)"
            [withBorder]="true"
            [image]="ownedGroup.type === groupTypeEnum.O365OFFICETEAMS ? 'icon_resource_avatar_teams.svg' : 'icon_resource_avatar_sharepoint.svg'"
            [rightImage]="ownedGroup.certificationInformation.lastValidation ? 'icon_pause_ok.svg' : 'icon_pause_warning.svg'"
            [textAlertColor]="!ownedGroup.certificationInformation.lastValidation"
            (click)="getGroupMembers(ownedGroup)"
          ></ui-title-description-button>
        </ng-container>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="isLoading || isLoadingMore">
      <ui-spinner
        class="table-loading-spinner"
        size="medium"
        modifier="secondary"
      ></ui-spinner>
    </ng-container>
  </div>
</ng-template>

<ng-template #groupMemberList>
  <div class="title">
    <p>{{ 'certify.description' | t}}</p>
  </div>
  <div class="toolbar">
    <ui-table-total-tool
      [total]="profileUsers?.length || 0"
      [locale]="i18n.currentLocale"
    >
    </ui-table-total-tool>
    <ui-table-search-tool
      class="search"
      [query]="search"
      [placeholder]="'ui.table-search-tool.placeholder'| t"
      (clear)="onUserSearch(null)"
      (query)="onUserSearch($event)"
    ></ui-table-search-tool>
  </div>
  <certify-users-table
    class="table-container"
    [isFromQuickActions]="dataFromQuickAction?.fromQuickAction"
    [isLoading]="isLoading"
    [users]="profileUsers"
    [unfilteredUsers]="cloneUsers"
    [owners]="owners"
    [showBadges]="true"
    [total]="total"
    (onSave)="toggleConfirmDialog()"
    (onCertify)="onCertify($event)"
    (onCancel)="dialogRef.close()"
  ></certify-users-table>
</ng-template>

<ng-template #emptyList>
  <p class="empty">{{ 'quick.actions.empty.list' | t }} </p>
</ng-template>
