import { NgModule } from '@angular/core';
import { MatRadioModule } from '@angular/material/radio';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { RouterModule } from '@angular/router';
import { NgxsModule } from '@ngxs/store';
import { DirectivesModule } from 'projects/@common/modules/directives/directives.module';
import { PipesModule } from 'projects/@common/modules/pipes/pipes.module';
import { FeatureSharedModule } from 'projects/@common/modules/shared/feature.shared.module';
import { BackgroundTasksButtonComponent } from './components/background-tasks/components/background-tasks-button.component';
import { OpsManagerNoticesListComponent } from './components/background-tasks/components/ops-manager-notices-list/ops-manager-notices-list/ops-manager-notices-list.component';
import { OpsManagerNotificationDetailDrawer } from './components/background-tasks/components/ops-manager-notices-list/ops-manager-notification-detail-drawer/ops-manager-notification-detail-drawer.component';
import { OpsManagerNotificationSectionComponent } from './components/background-tasks/components/ops-manager-notices-list/ops-manager-notification-section/ops-manager-notification-section.component';
import { OpsManagerNotificationComponent } from './components/background-tasks/components/ops-manager-notices-list/ops-manager-notification/ops-manager-notification.component';
import { BaseDialogComponent } from './components/base-dialog/base-dialog.component';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { CollapsableDescriptionComponent } from './components/collapsable-description/collapsable-description.component';
import { DefaultApprovingComponent } from './components/default-approving/default-approving.component';
import { DefaultResourceSharingTypeComponent } from './components/default-resource-sharing-type/default-resource-sharing-type.component';
import { SensitiveFileDrawer } from './components/drawer/sensitive-file/sensitive-file-drawer/sensitive-file-drawer.container';
import { LabelWithTextComponent } from './components/drawer/shared-file-activities/shared-file-activities-table/label-with-text/label-with-text.component';
import { SharedFileActivitiesTableComponent } from './components/drawer/shared-file-activities/shared-file-activities-table/shared-file-activities-table.component';
import { SharedFileActivities } from './components/drawer/shared-file-activities/shared-file-activities.component';
import { SharedFileDetails } from './components/drawer/shared-file-details/shared-file-details.component';
import { SharedFileSharesMobile } from './components/drawer/shared-file-shares/shared-file-shares-mobile/shared-file-shares-mobile.component';
import { SharedFileSharesRevokeAll } from './components/drawer/shared-file-shares/shared-file-shares-revoke-all/shared-file-shares-revoke-all.component';
import { SharedTo } from './components/drawer/shared-file-shares/shares-section/shared-to/shared-to.component';
import { ShareDetails } from './components/drawer/shared-file-shares/shares-section/share-details/share-details.component';
import { BatchRevokeComponent } from './components/popups/batch-revoke/batch-revoke.component';
import { ResourcesSharesMobileComponent } from './components/resoures-shares-table/resources-shares-mobile/resources-shares-mobile.component';
import { ResourcesSharesTableComponent } from './components/resoures-shares-table/resources-shares-table.component';
import { EcoProfileCardComponent } from './components/eco-profile-card/eco-profile-card.component';
import { EcoProfileRowComponent } from './components/eco-profile-card/eco-profile-row/eco-profile-row.component';
import { RiskBadgeComponent } from './components/risk-badge/risk-badge.component';
import { SgStep } from './components/sg-stepper/sg-step.directive';
import { SgStepper } from './components/sg-stepper/sg-stepper.component';
import { ShareLastAccess } from './components/share-last-access/share-last-access.component';
import { ShareRiskScore } from './components/share-risk-score/share-risk-score.component';
import { SharedFilesMobileComponent } from './components/shared-files-table/shared-files-mobile/shared-files-mobile.component';
import { SharedFilesTableComponent } from './components/shared-files-table/shared-files-table.component';
import { SharesResourceAvatar } from './components/shares-resource-avatar/shares-resource-avatar.component';
import { SharedFileMobileComponent } from './components/shared-files-table/shared-files-mobile/shared-file-mobile/shared-file-mobile.component';
import { SilenceShareNotificationComponent } from './components/silence-share-notification/silence-share-notification.component';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { SharedFileDrawer } from './containers/drawer/shared-file-drawer/shared-file-drawer.container';
import { UsersSharesContainer } from './containers/users-shares/users-shares.container';
import { ExpositionTranslatePipe } from './pipes/expositionTranslate.pipe';
import { LastAccessedFormatPipe } from './pipes/lastAccessedFormat.pipe';
import { SearchBarState } from './store/search-bar.store';
import { SharesState } from './store/shares.store';
import { SharedFileShares } from './components/drawer/shared-file-shares/shared-file-shares.component';
import { SharedFileSharesRevoke } from './components/drawer/shared-file-shares/shared-file-shares-revoke/shared-file-shares-revoke.component';
import { SharesSection } from './components/drawer/shared-file-shares/shares-section/shares-section.component';
import { TablesModule } from './components/tables/tables.module';
import { CarouselModule } from './components/carousel-dialog/carousel.module';
import { RevokeSharesDialogComponent } from './components/revoke-shares-dialog/revoke-shares-dialog.component';
import { ComponentsModule } from 'projects/@common/components/components.module';
import { SensitiveFileDetailsComponent } from './components/drawer/sensitive-file/sensitive-file-details/sensitive-file-details.component';
import { SensitiveFilesActionsDialogComponent } from './components/sensitive-files-actions-dialog/sensitive-files-actions-dialog.component';
import { AdminTop10HighRiskSharesComponent } from './components/admin-top10-high-risk-shares/admin-top10-high-risk-shares.component';
import { UiKitModule } from '@ui-kit/uikit.module';
import { CommonModule } from '@angular/common';
import { AdminResourcesNumberWithSharesComponent } from './components/admin-resources-number-with-shares/admin-resources-number-with-shares.component';
import { AdminUsersSharesComponent } from './components/admin-users-shares/admin-users-shares.component';
import { AdminCardsComponent } from './components/admin-card/admin-cards.component';
import { AdminCardComponent } from './components/admin-card/admin-card/admin-card.component';
import { UserInfoDashbaordComponent } from './components/user-info-dashboard/user-info-dashboard.component';
import { AdminTop10RowComponent } from './components/admin-top10-high-risk-shares/top10-row/admin-top10-row.component';

const components = [
  AdminCardComponent,
  AdminCardsComponent,
  AdminResourcesNumberWithSharesComponent,
  AdminTop10HighRiskSharesComponent,
  AdminTop10RowComponent,
  AdminUsersSharesComponent,
  BackgroundTasksButtonComponent,
  BaseDialogComponent,
  BatchRevokeComponent,
  BreadcrumbsComponent,
  CollapsableDescriptionComponent,
  DefaultApprovingComponent,
  DefaultApprovingComponent,
  DefaultResourceSharingTypeComponent,
  DefaultResourceSharingTypeComponent,
  EcoProfileCardComponent,
  EcoProfileRowComponent,
  ExpositionTranslatePipe,
  LabelWithTextComponent,
  LastAccessedFormatPipe,
  OpsManagerNoticesListComponent,
  OpsManagerNotificationComponent,
  OpsManagerNotificationDetailDrawer,
  OpsManagerNotificationSectionComponent,
  ResourcesSharesMobileComponent,
  ResourcesSharesTableComponent,
  RevokeSharesDialogComponent,
  RiskBadgeComponent,
  SensitiveFileDetailsComponent,
  SensitiveFileDrawer,
  SensitiveFilesActionsDialogComponent,
  SgStep,
  SgStepper,
  ShareDetails,
  ShareLastAccess,
  ShareRiskScore,
  SharedFileActivities,
  SharedFileActivitiesTableComponent,
  SharedFileDetails,
  SharedFileDrawer,
  SharedFileMobileComponent,
  SharedFileShares,
  SharedFileSharesMobile,
  SharedFileSharesRevoke,
  SharedFileSharesRevokeAll,
  SharedFilesMobileComponent,
  SharedFilesTableComponent,
  SharedTo,
  SharesResourceAvatar,
  SharesSection,
  SilenceShareNotificationComponent,
  ToolbarComponent,
  UserInfoDashbaordComponent,
  UsersSharesContainer,
];

@NgModule({
  declarations: [ ...components ],
  exports: [ ...components ],
  imports: [
    MatSelectModule,
    TablesModule,
    MatRadioModule,
    FormsModule,
    DirectivesModule,
    PipesModule,
    NgxsModule.forFeature([ SharesState, SearchBarState ]),
    ...FeatureSharedModule.commonImports(),
    RouterModule,
    MatStepperModule,
    MatButtonToggleModule,
    CarouselModule,
    ComponentsModule,
    ReactiveFormsModule,
    UiKitModule,
    CommonModule
  ],
})
export class AssistOpsModule {
}
