<div class="slide-container">
  <h2>{{ "dashboard.dialog.carousel.page6.title" | t }}</h2>
  <h3>{{ "dashboard.dialog.carousel.page6.second-title" | t }}</h3>
  <p>{{ "dashboard.dialog.carousel.page6.text1" | t }}</p>
  <p *ngIf="isNewClient">
    <a style="font-weight: bold" [href]="getLocalizedLink()">{{ 'dashboard.dialog.carousel.page6.contact' | t }}</a> {{ 'dashboard.dialog.carousel.page6.contact.2' | t }}
  </p>

  <div class="bottom-section">
    <img src="assets/favicons/icon_illustration_notification.svg"/>
  </div>
</div>
