import { Component, ViewEncapsulation, Input, EventEmitter, Output } from '@angular/core';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { ISharedFilePermission } from 'projects/@common/services/api/sg/shares/shares.definitions';

@Component({
  selector: 'shared-file-shares-mobile',
  templateUrl: './shared-file-shares-mobile.component.html',
  styleUrls: [ './shared-file-shares-mobile.component.scss' ],
  encapsulation: ViewEncapsulation.None,
})
export class SharedFileSharesMobile {
  @Input() public shares: ISharedFilePermission[];

  @Input() public isAutomaticRevokeActivated = false;

  @Input() public isEditing: boolean;

  @Input() public isLoading: boolean;

  @Output() public onPermissionRemove: EventEmitter<ISharedFilePermission> = new EventEmitter();

  constructor(private i18n: I18nService) {}

  public convertAndMapFileRiskToSeverity(share: any): any {
    const riskLowercase = share.risk.toLowerCase();
    return { risk: riskLowercase, translatedRisk: this.i18n.translate(`shares.shareRiskScore.${riskLowercase}`) };
  }
}
