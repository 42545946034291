<base-dialog
  [title]="'revoke.dialog.title' | t"
  [hasStepper]="false"
  (onCancel)="dialogRef.close()"
>
  <div class="container">
    <ng-container *ngIf="!isLoading">
      <ng-container *ngIf="!noRiskUserShares">
        <ng-container *ngIf="!actionType">
          <div class="revoke-buttons-container">
            <div
              class="second-title"
              [innerHTML]="'revoke.dialog.second.title' | t"
            ></div>
            <ng-container *ngFor="let button of buttonList">
              <ui-title-description-button
                [revokeStyle]="true"
                imageSize="60px"
                [name]="button.name + button.type | t"
                [description]="button.description + button.type | t"
                [withBorder]="true"
                [image]="button.image"
                (click)="revoke(button.type)"
              ></ui-title-description-button>
            </ng-container>
          </div>
        </ng-container>
        <ng-container *ngIf="actionType">
          <batch-revoke
            [isFromQuickActions]="true"
            [showBatchRevokeDialog]="true"
            [actionType]="actionType"
            (showBatchRevokeDialogChange)="dialogRef.close()"
            [resourceId]="data?.resourceId"
          >
          </batch-revoke>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="noRiskUserShares">
        <div class="no-risk-shares-container">
          <img src="assets/favicons/icon_no_risk_shares.svg" />
          <p class="no-risk-shares-title">{{'dashboard.revocation.dialog.no_risk_shares.title' | t}}</p>
          <p class="no-risk-shares-txt">{{'dashboard.revocation.dialog.no_risk_shares.txt' | t}}</p>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="isLoading">
      <ui-spinner
        size="medium"
        modifier="secondary"
      ></ui-spinner>
    </ng-container>
  </div>
</base-dialog>
