import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'shares-description',
  templateUrl: './shares-description.component.html',
  styleUrls: [ './shares-description.component.scss' ],
})
export class SharesDescriptionComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {

  }
}
