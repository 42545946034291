import { Component, Input, OnInit } from '@angular/core';

export enum BannerTypeEnum {
  warning = 'warning',
  ok = 'ok'
}

@Component({
  selector: 'sg-banners',
  templateUrl: './banners.component.html',
  styleUrls: [ './banners.component.scss' ],
})
export class BannersComponent implements OnInit {
  @Input()
  public type: BannerTypeEnum = BannerTypeEnum.ok;

  @Input()
  public text: string;

  constructor () { }

  ngOnInit(): void {
  }

  public get bannerIcon(): string {
    return `assets/favicons/icon_pause_${this.type}.svg`;
  }

  public get background(): string {
    return `${this.type}-background`;
  }
}
