import { StorageService } from '../../../storage.service';

export abstract class TemporaryDataAbstract {
  protected storageKey: string;
  protected TIME_TO_LIVE = 1800000; // 30m
  protected constructor(protected storageService: StorageService) {
  }

  // MUST INCLUDE DATE IN DATA
  protected add(data: any, keyCompare: string): void {
    const current: any[] = this.getFilteredValue(this.storageKey);
    const toReplaceIndex = current.findIndex((ttl) => ttl[keyCompare] === data[keyCompare]);
    toReplaceIndex >= 0 ? current.splice(toReplaceIndex, 1, data) : current.push(data);

    this.storageService.setLocal(this.storageKey, JSON.stringify(current));
  }

  protected remove(ids: string[], keyCompare: string): void {
    const current: any[] = this.getFilteredValue(this.storageKey);
    this.storageService.setLocal(this.storageKey, JSON.stringify(current.filter((value) => !ids.includes(value[keyCompare]))));
  }

  protected getFilteredValue(value: string): any[] {
    const parsedData = JSON.parse(this.storageService.getLocal(value)) || [];
    const filteredData = parsedData.filter((value) => (Date.now() - this.TIME_TO_LIVE) < value.date);
    this.storageService.setLocal(value, JSON.stringify(filteredData));

    return filteredData;
  }
}
