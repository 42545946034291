<div class="slide-container">
  <h2>{{ "dashboard.dialog.carousel.page3.title" | t }}</h2>
  <h3>{{ "dashboard.dialog.carousel.page3.second-title" | t }}</h3>
  <p>{{ "dashboard.dialog.carousel.page3.text1" | t }}</p>
  <p>{{ "dashboard.dialog.carousel.page3.text2" | t }}</p>
  <div class="bottom-section">
    <img src="assets/favicons/icon_illustration_group_actions.svg" />
    <div class="illustration-dashboard-container">
      <img src="assets/favicons/icon_illustration_dashboard.svg" />
    </div>
    <div class="dropdown-txt">
      <p>{{ "dashboard.md-eco.quickAction.option" | t }}</p>
      <p>{{ "dashboard.md-eco.quickAction.option.4" | t }}</p>
      <p>{{ "dashboard.md-eco.quickAction.option.5" | t }}</p>
      <p>{{ "dashboard.md-eco.quickAction.option.6" | t }}</p>
      <p>{{ "dashboard.md-eco.quickAction.option.7" | t }}</p>
      <p>...</p>
    </div>
  </div>
</div>
