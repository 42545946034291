import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import { AfterViewInit, Component, Input, OnChanges } from '@angular/core';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';

@Component({
  selector: 'sg-monthly-actions-graph',
  templateUrl: './monthly-actions-graph.component.html',
  styleUrls: ['./monthly-actions-graph.component.scss'],
})
export class MonthlyActionsGraphComponent implements AfterViewInit, OnChanges {
  @Input()
  data: { month: string; value: number }[];

  private chart: am4charts.XYChart;
  public id = `chartdiv-${Math.floor(Math.random() * 10000)}`;

  constructor(public i18nService: I18nService) {
    am4core.addLicense('CH259911983');
  }

  ngOnChanges() {
    if (this.chart) {
      this.chart.data = this.data;
    }
  }

  ngAfterViewInit(): void {
    this.generateChart();
    this.setDateAxis();
    this.setValueAxis();
    this.setColumns();
    
  }

  private generateChart(): void {
    this.chart = am4core.create(this.id, am4charts.XYChart);
    this.chart.data = this.data;

    this.chart.cursor = new am4charts.XYCursor();
    this.chart.cursor.lineY.disabled = true;
    this.chart.cursor.lineX.disabled = true;

    this.chart.paddingBottom = 0;
    this.chart.paddingLeft = 0;
    this.chart.paddingRight = 0;
  }

  private setDateAxis(): void {
    const dateAxis = this.chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.dateFormats.setKey('month', 'MMMMM');
    dateAxis.periodChangeDateFormats.setKey('month', 'MMMMM');
    dateAxis.dateFormatter = new am4core.DateFormatter();

    dateAxis.renderer.minGridDistance = 10;
    dateAxis.renderer.labels.template.fontSize = 14;
    dateAxis.renderer.labels.template.fill = am4core.color('#757575');
    dateAxis.renderer.grid.template.disabled = true;
    dateAxis.tooltipDateFormat = 'MMM, yyyy';

    const axisTooltip = dateAxis.tooltip;
    axisTooltip.background.fill = am4core.color('#404C5B');
    axisTooltip.background.strokeWidth = 0;
    axisTooltip.background.cornerRadius = 3;
    axisTooltip.dy = 5;
  }

  private setValueAxis(): void {
    const valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.cursorTooltipEnabled = false;
    valueAxis.renderer.labels.template.fontSize = 14;
    valueAxis.renderer.minGridDistance = 20;
    valueAxis.renderer.labels.template.fill = am4core.color('#757575');
    valueAxis.min = 0;
    valueAxis.maxPrecision = 0;
    valueAxis.tooltip.disabled = true;
  }

  private setColumns(): void {
    const series = this.chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueY = 'value';
    series.dataFields.dateX = 'date';
    series.columns.template.maxWidth = 8;
    series.columns.template.fill = am4core.color('#00d873');
    series.columns.template.stroke = am4core.color('#00d873');
    series.columns.template.tooltipText = `{value} ${this.i18nService.translate('eco-profile.page.tab.user-profile.weekly-section.weekly-graph.good-actions')}`;
    series.tooltip.adapter.add('disabled', (disabled, target) => {
      if (target.dataItem && target.dataItem.values.valueY.value === 0) {
        return true;
      }
      return disabled;
    });
    series.tooltip.getFillFromObject = false;
    series.tooltip.background.strokeWidth = 0;
    series.tooltip.background.cornerRadius = 3;
    series.tooltip.background.fill = am4core.color('#404C5B');
  }
}
