import { Component, Input, OnInit } from '@angular/core';

export interface IAdminCardInformations {
  number: number;
  total?: {total: number, percentage?: number};
  text: string;
  image: string;
  tooltipText?: string;
}

@Component({
  selector: 'sg-admin-card',
  templateUrl: './admin-card.component.html',
  styleUrls: [ './admin-card.component.scss' ],
})
export class AdminCardComponent {
  @Input() cardInformations: IAdminCardInformations;

  public get isPurcentageData(): boolean {
    return (
      this.cardInformations.text === 'dashboard.admin.card.text.commitmentLevel'
    );
  }

  constructor() {}

}
