import { Component, OnDestroy, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { TableFilterData } from '@ui-kit/components/ui-table-tools/ui-table-filter-tool/ui-table-filter-data';
import { UiTableDirection } from '@ui-kit/components/ui-table/ui-table.component';
import { RequestStatusEnum } from '@ui-kit/util/icon-util';
import { Accumulatables, Accumulator, AccumulatorsState } from 'projects/@common/modules/accumulator/accumulator.store';
import { TableFilterToolDataFactory } from 'projects/@common/modules/i18n/component-wrapper/table-filter-tool-data.factory';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { DrawerPageEnum } from 'projects/@common/modules/layout/components/drawer/drawerPage.enum';
import { DrawerService } from 'projects/@common/modules/layout/components/drawer/services/drawer.service';
import { RequestsApiService } from 'projects/@common/services/api/requests/requests-api.service';
import { RequestList } from 'projects/@common/services/api/requests/requests.definitions';
import { Observable, Subscription } from 'rxjs';
import { RequestDrawerContainer } from '../../containers/request-drawer/request-drawer.container';
import { ListCategoryType } from '../../containers/requests.container';

enum RequestMobileFilterEnum {
  ALL = 'all',
  TO_PROCESS = 'toProcess',
  MINE_IN_PROGRESS = 'mineInProgress',
  OTHERS = 'others'
}

@Component({
  selector: 'request-list-mobile',
  templateUrl: './request-list-mobile.component.html',
  styleUrls: [ './request-list-mobile.component.scss' ],
})
export class RequestListMobileComponent implements OnInit, OnDestroy {
  @Select(AccumulatorsState.accumulators) private accumulators$: Observable<Accumulator[]>;
  public requests: RequestList[];
  public isLoading = false;
  public nextToken = 0;
  public total: number;
  public requestStatusEnum = RequestStatusEnum;
  public requestsMobileFilterEnum = RequestMobileFilterEnum;
  public requestsFilter = RequestMobileFilterEnum.ALL;
  public sortFilterDirection = UiTableDirection.Desc;
  public requestsFilterEnumTableFilterData: TableFilterData;
  private readonly LIMIT = 25;
  private currentAccumulatorCount: number;
  private accumulatorSubscription: Subscription;
  private category: ListCategoryType;

  constructor(
    private readonly requestsService: RequestsApiService,
    private tableFilterToolDataFactory: TableFilterToolDataFactory,
    private i18n: I18nService,
    private readonly drawerService: DrawerService
  ) {
  }

  public ngOnInit(): void {
    this.handleRefresh();

    this.accumulatorSubscription = this.accumulators$.subscribe((accumulators) => {
      const count = accumulators
        .filter((accumulator) => accumulator.accumulatable === Accumulatables.REQUESTS_LIST)
        .shift()?.accumulator;
      if (count !== this.currentAccumulatorCount) {
        this.handleRefresh();
        this.currentAccumulatorCount = count;
      }
    });
    this.requestsFilterEnumTableFilterData = this.tableFilterToolDataFactory.create(this.requestsMobileFilterEnum, 'request.list.mobile.', this.requestsFilter, true);
  }

  public ngOnDestroy() {
    this.accumulatorSubscription?.unsubscribe();
  }

  public loadMoreRequests() {
    this.isLoading = true;
    this.requestsService
      .getRequests({
        language: this.i18n.currentLocale,
        from: this.nextToken,
        limit: this.LIMIT,
        sort: 'createdAt',
        direction: this.sortFilterDirection,
      })
      .then((requests) => {
        this.requests.push(...requests.items);
        this.total = requests.total;
        this.nextToken = +requests.nextToken;
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  public handleRefresh() {
    this.nextToken = 0;
    this.requests = [];
    this.loadMoreRequests();
  }

  public handleFilter(filter: RequestMobileFilterEnum) {
    this.requestsFilter = filter;
    this.category = null;
    switch (this.requestsFilter) {
      case RequestMobileFilterEnum.TO_PROCESS:
        this.category = ListCategoryType.ACTION_REQUIRED;
        break;
      case RequestMobileFilterEnum.MINE_IN_PROGRESS:
        this.category = ListCategoryType.MY_REQUESTS;
        break;
      case RequestMobileFilterEnum.OTHERS:
        this.category = ListCategoryType.OTHER_REQUESTS;
        break;
      case RequestMobileFilterEnum.ALL:
      default:
        break;
    }
    this.handleRefresh();
  }

  public handleSort(direction: UiTableDirection) {
    this.sortFilterDirection = direction;
    this.handleRefresh();
  }

  public handleRequestClick(request: RequestList) {
    this.drawerService.replaceCurrentDrawer(RequestDrawerContainer, {
      component: DrawerPageEnum.requestDrawer,
      data: { title: request.description, number: request.number, requestId: request.id },
    });
  }
}
