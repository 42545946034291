<sg-page-layout
  [showRightSide]="false"
  [title]="'requests.title' | t"
  [documentationLink]="documentationSection"
  [breadcrumbs]="breadcrumbs"
>

  <div class="request-card-container">
    <div
      class="toolbar"
      *ngIf="!mobileService.windowWidthSmallerThan(900)"
    >
      <ng-container toolbar>
        <ui-table-total-tool [total]="total"></ui-table-total-tool>
        <ui-table-search-tool
          [query]="requestsQuery"
          [placeholder]="'ui.table-search-tool.placeholder'| t"
          (clear)="handleSearchClear()"
          (query)="handleSearchQuery($event)"
        ></ui-table-search-tool>
        <ui-table-filter-tool
          [tableFilterData]="requestsFilterEnumTableFilterData"
          [titlesDifferentThanOptions]="[requestsFilterEnum.ALL, requestsFilterEnum.ASSIGNATION]"
          (select)="handleFilter($event)"
        ></ui-table-filter-tool>
        <ui-table-refresh-tool
          [loading]="isLoading"
          (click)="handleRefresh()"
        ></ui-table-refresh-tool>
      </ng-container>
    </div>
  </div>

  <ui-card *ngIf="!mobileService.windowWidthSmallerThan(900)">
    <div
      *ngFor="let requestTableDefinition of requestTableDefinitions; let first = first; let index = index"
      [class.space-with-top-requests]="!first"
    >
      <requests-collapsable-table
        [tableTitle]="requestTableDefinition.title"
        [isCollapsed]="requestTableDefinitions.length > 1 && (!requestTableDefinition.filter || selectedFilter !== requestTableDefinition.filter)"
        [columnDef]="requestTableDefinition.columnDef"
        [statusesFilter]="requestTableDefinition.statusesFilter"
        [categoryFilter]="requestTableDefinition.categoryFilter"
        [showToProcess]="currentFilter !== requestsFilterEnum.CATEGORIES"
        [fulltext]="requestsQuery"
        [headerIcon]="requestTableDefinition.headerIcon"
        (onStatusUpdate)="updateTableStatus(index, $event)"
        (checkbox-event)="handleCheckboxEvent($event.event, $event.item)"
      ></requests-collapsable-table>
    </div>
  </ui-card>
  <request-list-mobile *ngIf="mobileService.windowWidthSmallerThan(900)"></request-list-mobile>
</sg-page-layout>
