import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'collapsable-description',
  templateUrl: './collapsable-description.component.html',
  styleUrls: [ './collapsable-description.component.scss' ],
})
export class CollapsableDescriptionComponent implements OnInit {
  @Input() public icon: string;
  @Input() public isSmall = false;
  @Input() public title: string;
  @Input() public descriptionExpanded = false;

  ngOnInit(): void {
  }

  public toggleDescription(): void {
    this.descriptionExpanded = !this.descriptionExpanded;
  }
}
