import { Injectable } from '@angular/core';
import { TableFilterData } from '@ui-kit/components/ui-table-tools/ui-table-filter-tool/ui-table-filter-data';
import { I18nService } from '../i18n.service';

@Injectable({
  providedIn: 'root',
})
export class TableFilterToolDataFactory {
  constructor(private i18n: I18nService) {
  }

  public create(optionEnum: any, prefix: string, defaultValue: any, needTranslationSuffix = true, excludeEnumValue?: any[]): TableFilterData {
    let optionEnumValues = Object.values(optionEnum);
    if (excludeEnumValue) {
      optionEnumValues = optionEnumValues.filter((excludeValue) => !excludeEnumValue.includes(excludeValue));
    }
    const options = optionEnumValues.map((value) => ({
      value,
      translated: this.i18n.translate(`${prefix}${value}${needTranslationSuffix ? '.filter' : ''}`),
      translatedTooltip: this.i18n.translate(`${prefix}${value}.tooltip`),
      translatedWithTitleSuffix: this.i18n.translate(`${prefix}${value}${needTranslationSuffix ? '.filter.title' : ''}`),
      isDefault: value === defaultValue,
    }));

    return new TableFilterData(options);
  }
}
