<div class="container">
  <div class="info-text">
    <span class="no-margin info-text">
      {{ 'groups.dialog.apply.confirmation.description' | t : {resourceType: getTranslatedGroupType(template)} }}
      <img class="image" src="assets/favicons/icon_hourglass.svg">
    </span>
  </div>
  <p class="no-margin top-message">
   {{ 'groups.dialog.apply.confirmation.preview' | t }}
  </p>
  <div class="preview-section">
    <div class="resource-info">
    <span class="text-image">
      <img class="image--small" src="assets/favicons/icon_paper_cropped.svg">
      <p class="top-message">{{ template.name }}</p> 
    </span>
    <p> {{template.description}} </p>
    </div>
    <div 
      *ngIf="neededCertification" 
      class="sub-preview-section"
      >
      <span class="text-image">
        <img class="image--big" src="assets/favicons/icon_certifier.svg">
        <p> {{ 'groups.dialog.apply.confirmation.access' | t }}</p> 
      </span>
      <span *ngIf="confirmNumber > 0" class="text-image text-image--access">
        <img class="image--small" src="assets/favicons/icon_small_check.svg">
        <p>
        {{ 
            'groups.dialog.apply.confirmation.confirm.message' | t : { confirmNumber: confirmNumber }
        }}
      </span>
      <span *ngIf="revokeNumber > 0" class="text-image text-image--access">
        <img class="image--small" src="assets/favicons/icon_small_check.svg">
        <p>
        {{ 
            'groups.dialog.apply.confirmation.revoke.message' | t : {
            revokeNumber: revokeNumber,
            resourceType: getTranslatedGroupType(template) 
          }
        }}
      </span>
    </div>
  <div class="sub-preview-section">
        <sg-model-information 
          [template]="template" 
          [showChannels]="keepStructure"
        ></sg-model-information> 
  </div>
  </div>


</div>
