import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IBreadcrumbs } from 'projects/@assist-ops/components/breadcrumbs/breadcrumbs.component';
import { MobileService } from 'projects/@common/services/mobile.service';

@Component({
  selector: 'sg-page-layout',
  templateUrl: './page-layout.component.html',
  styleUrls: [ './page-layout.component.scss' ],
})
export class PageLayoutComponent implements OnInit {
  @Input() public breadcrumbs: IBreadcrumbs[];
  @Input() public showRightSide = true;
  @Input() public title: string;
  @Input() public documentationLink: string;

  @Output() onScrollToBottom: EventEmitter<void> = new EventEmitter();
  @Output() onTooltipEvent: EventEmitter<void> = new EventEmitter();

  constructor(public mobileService: MobileService) { }

  ngOnInit(): void {
  }
}
