import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MultiSelectData } from '@ui-kit/components/ui-multi-select/multi-select-data';
import { MultiSelectDataFactory } from 'projects/@common/modules/i18n/component-wrapper/multi-select-data.factory';
import { FileRiskEnum, ISharedFile } from 'projects/@common/services/api/sg/shares/shares.definitions';
import { MobileService } from 'projects/@common/services/mobile.service';

@Component({
  selector: 'shared-files-mobile',
  templateUrl: './shared-files-mobile.component.html',
  styleUrls: [ './shared-files-mobile.component.scss' ],
})
export class SharedFilesMobileComponent implements OnInit {
  @Input() public sharedFiles: ISharedFile[];
  @Input() public isLoading = false;
  @Input() public isAutomaticRevokeActivated = false;
  @Output() public onCheck = new EventEmitter<{ event: boolean; item: ISharedFile }>();
  @Output() public onRefresh = new EventEmitter<void>();
  @Output() public onRiskFilter = new EventEmitter<string[]>();
  @Output() public onSearch = new EventEmitter<string>();
  public textSearch = "";
  public riskMultiSelectData: MultiSelectData;
  public shareRisksFilter = FileRiskEnum;
  public defaultRiskFilter = this.route.snapshot.queryParams.sharesRiskFilter;

  constructor(
    public mobileService: MobileService,
    private multiSelectFactory: MultiSelectDataFactory,
    private route: ActivatedRoute
  ) {}
  public ngOnInit() {
    this.riskMultiSelectData = this.multiSelectFactory.create(this.shareRisksFilter, this.defaultRiskFilter ? [ this.defaultRiskFilter ] : [], 'file.risk.');
  }

  public refresh(): void {
    this.onRefresh.emit();
  }
  public sortResources(filter: string[]) {
    this.onRiskFilter.emit(filter);
  }
  public clearTextSearch() {
    this.textSearch = null;
    this.onSearch.emit(this.textSearch);
  }
  public textSearchAction(query: string) {
    this.textSearch = query;
    this.onSearch.emit(this.textSearch);
  }

  public getExternalMessage(file: ISharedFile): boolean {
    if (file.exposure === 'Public' || file.hasGuestShares) {
      return true;
    }
    return false;
  }
}
