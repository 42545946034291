import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { DrawerService } from 'projects/@common/modules/layout/components/drawer/services/drawer.service';
import { FileRiskEnum } from 'projects/@common/services/api/sg/shares/shares.definitions';
import { UserBehaviorApiService } from 'projects/@common/services/api/sg/user-behavior/user-behavior.api';
import { MobileService } from 'projects/@common/services/mobile.service';
import { UserRequestsFilterEnum } from 'projects/console-sg/src/app/pages/requests/containers/requests.container';

export interface IBreaktimeTask {
  icon: string;
  taskName: string;
  device?: any;
  translate?: {
    name: string;
    date: string;
    groupName: string;
  };
  data?: any;
  twoActions?: boolean;
}

export enum IBreakTimeTaskEnum {
  IS_THERE_AUTOMATIC_REVOKE_SHARES_MADE_BY_USER = 'isThereAutomaticRevokeSharesMadeByUser',
  IS_THERE_HIGH_RISK_SHARES_MADE_BY_USER = 'isThereHighRiskSharesMadeByUser',
  IS_THERE_AUTOMATIC_REVOKE_SHARES_IN_USER_RESOURCES = 'isThereAutomaticRevokeSharesInUserResources',
  IS_THERE_HIGH_RISK_SHARES_IN_USER_RESOURCES = 'isThereHighRiskSharesInUserResources',
  DOES_USER_HAS_PENDING_REQUESTS = 'doesUserHasPendingRequests'
}

@Component({
  selector: 'breaktime-task',
  templateUrl: './breaktime-task.component.html',
  styleUrls: [ './breaktime-task.component.scss' ],
})
export class BreaktimeTask implements OnInit {
  @Input() public isFirst = false;

  @Input() public isLast = false;

  @Input() public task: IBreaktimeTask;

  @Input() public currentUser: any;

  @Output() public removeTask: EventEmitter<IBreaktimeTask> = new EventEmitter();

  public isCollapsed = true;
  public isActionLoading = false;
  public showRemoveDisableDeviceConfirmationDialog = false;
  public isMobile = false;
  public revokeHighRiskDialog = false;
  public resourceGuid = null;

  constructor(
    private readonly drawerService: DrawerService,
    private readonly userBehaviorService: UserBehaviorApiService,
    private readonly router: Router,
    private readonly store: Store,
    private readonly mobileService: MobileService
  ) {
  }

  ngOnInit(): void {
    this.isMobile = this.mobileService.isMobile();
    this.isCollapsed = !this.isMobile;
  }

  public toggleTaskDescription() {
    if (!this.isMobile) {
      this.isCollapsed = !this.isCollapsed;
    }
  }

  public clickOnAction1() {
    switch (this.task.taskName) {
      case IBreakTimeTaskEnum.IS_THERE_AUTOMATIC_REVOKE_SHARES_MADE_BY_USER:
      case IBreakTimeTaskEnum.IS_THERE_HIGH_RISK_SHARES_MADE_BY_USER:
        this.router.navigate([ '/myshares' ], {
          queryParams: {
            userGuid: this.currentUser.guid,
            riskFilter: FileRiskEnum.HIGH,
          },
        });
        break;

      case IBreakTimeTaskEnum.IS_THERE_AUTOMATIC_REVOKE_SHARES_IN_USER_RESOURCES:
      case IBreakTimeTaskEnum.IS_THERE_HIGH_RISK_SHARES_IN_USER_RESOURCES:
        this.router.navigate([ '/shares/resources' ], {
          queryParams: {
            userGuid: this.currentUser.guid,
            riskFilter: FileRiskEnum.HIGH,
          },
        });
        break;

      case IBreakTimeTaskEnum.DOES_USER_HAS_PENDING_REQUESTS:
        this.router.navigate([ '/requests' ], {
          queryParams: {
            filterType: UserRequestsFilterEnum.CATEGORIES,
            filter: 'pending',
          },
        });
        break;

      default:
        break;
    }
  }

  public clickOnAction2() {
    if (this.task.taskName === 'isThereHighRiskSharesMadeByUser') {
      this.resourceGuid = null;
      this.revokeHighRiskDialog = true;
    }
  }
}
