<div class="list-container">
  <div class="list-container--top-section">
    <h2 [innerHTML]="config?.title | t"></h2>
    <ng-container *ngIf="yearsDropdownFilter?.length > 1">
      <mat-select
        #select
        class="list-container--top-section--select"
        [value]="defaultYearSelected"
        (selectionChange)="handleSelection($event.value)"
      >
        <mat-option
          *ngFor="let year of yearsDropdownFilter"
          [value]="year"
        >{{ year }}</mat-option>
      </mat-select>
    </ng-container>
  </div>
  <table>
    <ng-container *ngFor="let action of config?.actions">
      <ng-container *display="{flags: action.flagRequired}">
        <tr *ngIf="action && action.shouldDisplay">
          <td
            *ngIf="action.image"
            class="first-cell"
          >
            <img [src]="'assets/favicons/' + action.image">
          </td>
          <td class="second-cell" [ngStyle]="{'width': secondCellWidth}">{{action.name | t}}</td>
          <td class="third-cell ">
            {{action.description}}
            <ui-tooltip
              class="tooltip"
              *ngIf="action.tooltip"
              [isSmall]="true"
            >
              {{action.tooltip}}
            </ui-tooltip>
          </td>
        </tr>
      </ng-container>
    </ng-container>
  </table>
  <p
    class="empty-message"
    *ngIf="shouldDisplay(config?.actions)"
  >{{config.emptyMessage | t}}</p>
</div>
