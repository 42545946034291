import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DisplayService } from '@common/modules/display/display.service';
import { FeaturesEnum } from '@md.eco/interfaces/featureFlags';
import { IDefaultApprover } from '@common/services/api/sg/group-templates/group-templates.definitions';
import { OrganizationsApiService } from '@common/services/api/sg/organizations/organizationsApi.service';
import { DateUtils } from '@common/utils/date-utils';
import { Select } from '@ngxs/store';
import { AutocompleteTypes } from '@ui-kit/components/autocomplete/autocomplete.component';
import { UiDialog } from '@ui-kit/components/ui-dialog/ui-dialog.component';
import { LanguageEnum } from '@ui-kit/interfaces/ILanguage';
import { IconUtil } from '@ui-kit/util/icon-util';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { NoticeLevels, NoticeService } from 'projects/@common/modules/notice/notice.service';
import { EcoSessionState } from 'projects/@common/modules/session/state/session.state';
import { IdentitiesApi } from 'projects/@common/services/api/iam/identities/identities.api';
import { IdentityProviderTypes, List, ListUsersResponse } from 'projects/@common/services/api/iam/identities/identities.api.definitions';
import { GroupsApiService } from 'projects/@common/services/api/sg/groups/groupsApi.service';
import { UsersApiService } from 'projects/@common/services/api/sg/user/userApi.service';
import { EmailUtil } from 'projects/@common/utils/email-util';
import { Observable } from 'rxjs';
import { UserStatusEnum } from '../../resources-dialogs/sections/informations-section/informations-section.component';


@Component({
  selector: 'invite-guest-user-stepper',
  templateUrl: './invite-guest-user-stepper.component.html',
  styleUrls: ['./invite-guest-user-stepper.component.scss']
})
export class InviteGuestUserStepperComponent implements OnInit {
  @Select(EcoSessionState.user) public currentUser$: Observable<string>;
  public currentUser: any; /* IUser */
  public guestUserForm: FormGroup;
  public approversForm: FormGroup;
  public approvers = [];
  public isLoading = false;
  public isSaving = false;
  public currentStepIndex = 1;
  public usersAutocomplete = AutocompleteTypes.USERS;
  public guestExists = false;
  public autocompleteType = AutocompleteTypes.RESOURCES;
  public selectedItems = [];
  public languageOptions = Object.values(LanguageEnum).map(language => {
    return {
      value: language,
      displayValue: this.i18n.translate(language)
    };
  });
  public showConfirmationDialog = false;
  public minExpirationDate = DateUtils.tomorrowDate();
  public maxExpirationDate: Date;
  public featureFlagsEnum = FeaturesEnum;
  public selectedExpirationDate = null;
  private readonly DEBOUNCE_TIME = 300;
  private readonly MAXIMUM_RESOURCES = 20;
  private checkGroupNameTakenTimeout: any;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { email: string; externalUserCollaboration: boolean; },
    public dialogRef: MatDialogRef<UiDialog>,
    public i18n: I18nService,
    private notice: NoticeService,
    private usersApiService: UsersApiService,
    private groupsApiService: GroupsApiService,
    private identitiesApi: IdentitiesApi,
    private organizationsApiService: OrganizationsApiService,
    private displayService: DisplayService,
  ) {
  }

  public get isAutocompleteDisabled(): boolean {
    return this.selectedItems.length === this.MAXIMUM_RESOURCES;
  }

  public get firstApprovers(): IDefaultApprover[] {
    return this.approvers.slice(0, 3);
  }

  public get selectedResourcesOwner(): boolean {
    return this.selectedItems.some(selectedItem => selectedItem.isOwner);
  }

  public get selectedResourcesMember(): boolean {
    return this.selectedItems.some(selectedItem => !selectedItem.isOwner);
  }

  public get itemsToIgnore(): any[] {
    return [{ guid: this.currentUser.oid }, ...this.approvers];
  }

  ngOnInit(): void {
    this.organizationsApiService.getOrganizationSettings()
      .then(response => {
        const date = new Date();
        date.setDate(date.getDate() + response.guestExpirationDateLimit);
        this.maxExpirationDate = date;
      });

    this.currentUser$.subscribe((currentUser) => this.currentUser = currentUser);
    this.initInfoForm();
    this.initApproverForm();
  }

  public removeGroup(id: string): void {
    const indexToChop = this.selectedItems.findIndex(element => element.id === id);
    if (indexToChop > -1) {
      this.selectedItems.splice(indexToChop, 1);
    }
  }

  public handleDateChangeEvent(expirationDate: string) {
    this.selectedExpirationDate = expirationDate;
    this.guestUserForm.patchValue({ expirationDate });
  }

  public invite(): void {
    this.isSaving = true;
    const payload = this.guestUserForm.value;
    payload.approverO365Ids = this.approvers.map(approver => approver.o365UserId);
    payload.justification = this.approversForm.value.justification;
    payload.resourcesToAdd = this.selectedItems.map(item => ({
      id: item.id,
      name: item.displayName,
      isOwner: item.isOwner,
      type: item.groupType,
    }));

    this.usersApiService.inviteGuestUser(payload)
      .then(response => {
        this.notice.notifyUser({ message: 'guests.steps.confirmation', level: NoticeLevels.SUCCESS });
        this.showConfirmationDialog = true;
      })
      .catch(error => console.error(error));
  }

  public close(): void {
    this.dialogRef.close();
  }

  public usersAutocompleteSearchFunction = (params: any): Promise<List<ListUsersResponse, number | string, {
    idpType: IdentityProviderTypes;
  }>> => {
    return this.usersApiService.listGuestManagers({
      forInvitation: true,
      filter: "CURRENT",
      limit: 25,
      fulltext: params.search,
      usersOnly: true
    });
  };

  public resourcesAutocompleteSearchFunction = (params: any): Promise<any> => {
    return this.groupsApiService.listBasicOwnedGroup({
      limit: params.search.length > 0 ? 999 : 50,
      fulltext: params.search
    });
  };

  public removeApprover(owner: any): void {
    this.approvers = this.approvers.filter(item => item.guid !== owner.guid);
  }

  public async checkGuestUserEmailTaken(control: AbstractControl): Promise<any> {
    if (this.guestUserForm) {
      clearTimeout(this.checkGroupNameTakenTimeout);
      const guestExists = await new Promise(resolve => {
        this.checkGroupNameTakenTimeout = setTimeout(async () => {
          const groupname = control.value;
          if (groupname) {
            let guests;
            try {
              guests = await this.identitiesApi.listUsers(null, {
                filter: UserStatusEnum.GUESTS,
                search: control.value
              });
            } catch (error) {
            }
            if (guests?.total > 0 && guests.items.some(guest => guest.mail === control.value)) {
              resolve(true);
            }
            resolve(false);
          }
        }, this.DEBOUNCE_TIME);
      });
      if (guestExists || control.value.length === 0) {
        this.guestExists = true;
        return null;
      }
      this.guestExists = false;
      return null;
    }
  }

  public getResourceIcon(resource: any): string {
    resource['groupType'] = resource.type;
    return IconUtil.getResourceAvatarIcon(resource);
  }

  private initInfoForm(): void {
    this.guestUserForm = new FormGroup({
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      email: new FormControl(this.data?.email || '', [Validators.required, EmailUtil.emailValidation(this)], this.checkGuestUserEmailTaken.bind(this)),
      messageLanguage: new FormControl(LanguageEnum.FRENCH, []),
      expirationDate: new FormControl(this.selectedExpirationDate)
    });

    if (this.displayService.meetsRequirements({ flags: [FeaturesEnum.GUESTS_EXPIRATION_MANAGEMENT] })) {
      this.guestUserForm.get('expirationDate').addValidators([Validators.required]);
      this.guestUserForm.get('expirationDate').updateValueAndValidity();
    }
  }

  private initApproverForm(): void {
    this.approversForm = new FormGroup({
      justification: new FormControl('', [Validators.required]),
    });
  }
}
