<widget-container
  [title]="'dashboard.sharesTypeProportions' | t"
  [withBottomMargin]="true"
  [withPadding]="mobileService.isMobile()"
>
  <high-risk-shares></high-risk-shares>
  <div
    *ngIf="!isLoading"
    class="shares-proportions-v2"
  >
    <div class="shares-proportions-v2--content">
      <div class="shares-proportions-v2--content--title">
        {{ 'dashboard.sharesTypeProportions.chart.title' | t }}
      </div>
      <div>
        <dynamic-chart
          [locale]="i18nService.currentLocale"
          [data]="sharesTypeCountsChartData"
          name="sharesTypeProportionsv2"
          [type]="chartType"
          [key]="key"
          [series]="series"
          [innerSeriesLabel]="{
            count:
              sharesTypeCounts.company +
              sharesTypeCounts.guests +
              sharesTypeCounts.members +
              sharesTypeCounts.publicLinks +
              sharesTypeCounts.securityGroups
          }"
          pieChartInnerRadius="60"
          legendTemplate="{name}"
          legendSecondColumnTemplate="{value.percent}%"
        ></dynamic-chart>
      </div>
      <div
        *ngIf="mobileService.isMobile()"
        class="shares-proportions-v2--content--review-link"
        (click)="handleReviewClick()"
      >
        {{ 'dashboard.highRiskShares.review' | t }}
      </div>
    </div>

    <div
      class="shares-proportions-v2--spinner"
      *ngIf="isLoading"
    >
      <ui-spinner
        size="medium"
        modifier="secondary"
      ></ui-spinner>
    </div>
  </div>
</widget-container>