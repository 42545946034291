<div class="container">
  <div class="title">
    <p class="semi-bold">{{ 'certify.description' | t}}</p>
    <img
      (click)="openContextualHelp()"
      class="image"
      src="assets/favicons/icon_contextual_help_small.svg"
    >
  </div>
    <p>{{ 'groups.dialog.certify.mandatory.message' | t }}</p>
  <div class="toolbar">
    <ui-table-total-tool
      [total]="profileUsers?.length || 0"
      [locale]="i18n.currentLocale"
    >
    </ui-table-total-tool>
    <ui-table-search-tool
      class="search"
      [query]="fulltext"
      [placeholder]="'ui.table-search-tool.placeholder'| t"
      (clear)="onUserSearch(null)"
      (query)="onUserSearch($event)"
    ></ui-table-search-tool>
  </div>
  <certify-users-rows
    *ngIf="!isLoading"
    class="table-container"
    [isLoading]="isLoading"
    [users]="profileUsers"
    [unfilteredUsers]="cloneUsers"
    [owners]="owners"
    [showBadges]="true"
    [isApplyDialog]="true"
    [total]="total"
    (onCertify)="onCertify($event)"
  ></certify-users-rows>

  <ng-container *ngIf="!isLoading && profileUsers?.length <= 0">
    <span class="empty-table-message">{{ 'certify.no.user' | t }}</span>
  </ng-container>
  
  <ng-container *ngIf="isLoading">
    <ui-spinner size="medium"></ui-spinner>
  </ng-container>
</div>

