<base-dialog
  [title]="'guests.steps.title' | t"
  (onCancel)="dialogRef.close()"
  [hasStepper]="!showConfirmationDialog"
>
  <sg-stepper
    *ngIf="currentStepIndex === 1 && !showConfirmationDialog"
    (onCancel)="dialogRef.close()"
    (onSave)="invite()"
    [isSaving]="isSaving"
    [isGuestUserType]="true"
    minWidth="600px"
  >
    <ng-template sg-step [stepName]="'guests.steps.step.informations.title' | t"
                 [canGoForward]=" !guestExists && guestUserForm.valid">

      <form ui-form>
        <section class="content-form">
          <div>
            <ui-field-group>
              <ui-field
                [label]="'guests.steps.label.firstName' | t"
                name="firstName"
                control="firstName"
                [required]="true"
                [group]="guestUserForm"
                [forceInvalid]="false"
                [locale]="i18n.currentLocale"
                maxlength="50"
              ></ui-field>
              <ui-field
                [label]="'guests.steps.label.lastName' | t"
                name="lastName"
                control="lastName"
                [required]="true"
                [group]="guestUserForm"
                [forceInvalid]="false"
                [locale]="i18n.currentLocale"
                maxlength="50"
              ></ui-field>
            </ui-field-group>
            <ui-field-group>
              <ui-field
                [label]="'guests.steps.label.email' | t"
                name="email"
                control="email"
                [required]="true"
                [group]="guestUserForm"
                [forceInvalid]="false"
                [locale]="i18n.currentLocale"
                maxlength="50"
              ></ui-field>
              <ui-select
                [options]="languageOptions"
                name="language"
                [group]="guestUserForm"
                control="messageLanguage"
                [label]="'guests.steps.label.language' | t"
                [required]="false"
              >
              </ui-select>
            </ui-field-group>
            <p class="errorMessage"
               *ngIf="guestExists && this.guestUserForm.controls['email'].valid"> {{ 'guests.quickAction.search.guestFound.message' | t }} </p>

              <ui-date-picker
                *display="{flags: [featureFlagsEnum.GUESTS_EXPIRATION_MANAGEMENT]}"
                #datePicker
                class="date-picker"
                [label]="'guests.steps.label.expirationDate' | t"
                [withClearButton]="false"
                [minDate]="minExpirationDate"
                [maxDate]="maxExpirationDate"
                [required]="true"
                [defaultTimestamp]="selectedExpirationDate"
                [placeholder]="'common.formats.date_short_placeholder' | t"
                (onDateSelection)="handleDateChangeEvent($event)">
              </ui-date-picker>
          </div>
        </section>
      </form>
    </ng-template>
    <ng-template sg-step [stepName]="'guests.steps.step.approvers.title' | t"
                 [canGoForward]="approvers.length > 0 && approversForm.valid">
      <form ui-form>
        <section>
          <div>
            <p class="description">{{ 'guest.justification.description' | t }}</p>
            <p class="description-small">{{ 'guest.justification.description.small' | t }}</p>
            <ui-field-group class="emailWithWarning">
              <ui-switch-text-static
                [isEditing]="true"
                [label]="'guests.steps.label.justification' | t"
                name="justification"
                control="justification"
                [required]="true"
                [group]="approversForm"
                maxlength="1024"
                [rowsNumber]="5"
              >
              </ui-switch-text-static>
            </ui-field-group>
            <div class="approvers" >
              <div class="spacing">
                <p class="description">{{ 'guest.approuver.description' | t }}</p>
                <p class="description-small">{{ 'guest.approuver.description.small' | t }}</p>
              </div>
              <label ui-label [required]="true">{{ 'guests.steps.label.approvers' | t }}</label>
              <autocomplete
                [locale]="i18n.currentLocale"
                name="userAutocomplete"
                required="true"
                expandHeight="max-content"
                maxlength="50"
                [canMultiSelect]="true"
                [placeholder]="'guests.steps.search.approvers' | t"
                [(selectedItems)]="approvers"
                [searchFunction]="usersAutocompleteSearchFunction"
                [autocompleteType]="usersAutocomplete"
                [itemsToIgnore]="itemsToIgnore"
                expandHeight="max-content"
              >
              </autocomplete>
              <ui-user-tags [users]="approvers" [readOnly]="false"
                            (onDeleteUser)="removeApprover($event)"></ui-user-tags>
            </div>
          </div>
        </section>
      </form>
    </ng-template>

    <ng-template *ngIf="data?.externalUserCollaboration" sg-step [stepName]="'guests.steps.step.resources.title' | t"
                 [canGoForward]="true">
      <div class="content">
        <p class="desc">{{ 'guests.steps.label.resources.description' | t }}</p>
        <div class="autocomplete-container">
          <autocomplete
            [locale]="i18n.currentLocale"
            [disabled]="isAutocompleteDisabled"
            #autocomplete
            class="field-margin"
            [canMultiSelect]="true"
            [label]="'resources' | t"
            [searchFunction]="resourcesAutocompleteSearchFunction"
            [placeholder]="'common.search' | t"
            name="autocomplete"
            expandHeight="max-content"
            [(selectedItems)]="selectedItems"
            [autocompleteType]="autocompleteType"
          ></autocomplete>
          <p class="info">{{ 'guests.steps.label.resources.info' | t }}</p>
        </div>
        <div *ngIf="selectedItems?.length" class="resource-list">
          <h2>{{ 'guest.selected.title' | t: { selectedNumber: selectedItems.length } }}</h2>
          <ng-container *ngTemplateOutlet="selectedResources; context: {hasDelete: true}"></ng-container>
        </div>
      </div>

    </ng-template>

    <ng-template
      sg-step
      [stepName]="'guests.steps.step.review.title' | t"
      [canGoForward]="true"
      [saveButton]="true"
    >
      <div class="content">
        <p class="confirmation-message">{{ 'guests.steps.reminder' | t }}</p>
        <div class="content--spacing">
          <img src="assets/favicons/icon_users_admin.svg">
          <h4>{{ 'guests.steps.label.guest.collaborator' | t }}</h4>
        </div>
        <div class="content--informations content--informations--static">
          <ui-static
            [label]="'guests.steps.label.name.full' | t"
            [value]="guestUserForm.value.firstName + ' ' + guestUserForm.value.lastName"
          ></ui-static>
          <ui-static
            [label]="'guests.steps.label.email' | t"
            [value]="guestUserForm.value.email"
          ></ui-static>
        </div>
        <div class="content--spacing">
          <img src="assets/favicons/icon_users_admin.svg">
          <h4>{{ 'guests.steps.label.approvers' | t }}</h4>
        </div>
        <div class="content--informations content--informations--static">
          <ui-user-tags class="tags" [users]="approvers" [readOnly]="true"></ui-user-tags>
          <ui-static
          *display="{flags: [featureFlagsEnum.GUESTS_EXPIRATION_MANAGEMENT]}"
          [label]="'guests.steps.label.expirationDate' | t" [value]="guestUserForm.value.expirationDate | dt : { locale: i18n.currentLocale }"></ui-static>
        </div>
        <div class="selected-resource-container" *ngIf="selectedItems.length">
          <div class="resource-container-header">
            <img src="assets/favicons/icon_admin_menu_resources_active.svg">
            <h4>{{ 'guests.steps.label.resource.toConfirm' | t: { numberSelected: selectedItems.length } }}</h4>
          </div>
          <div class="selected-resource-content">
            <ng-container *ngTemplateOutlet="selectedResources; context: {hasDelete: false}"></ng-container>
          </div>
        </div>
      </div>
    </ng-template>
  </sg-stepper>

  <sg-confirmation-section
    *ngIf="showConfirmationDialog"
    [defaultApprovers]="firstApprovers"
    [addLineBreak]="false"
  >
  <div class="confirmation">
    <p>{{ 'guests.steps.confirmation.message' | t }}</p>
    <div class="info-confirmation-box" *ngIf="selectedResourcesOwner || selectedResourcesMember">
      <ui-information-message>
        <div class="info-confirmation-box-message-container">
          <p class="info-confirmation-box-message-title">{{ 'guests.steps.confirmation.note.title' | t }}</p>
          <p>{{ 'guests.steps.confirmation.note.message' | t }}</p>
        </div>
      </ui-information-message>
    </div>
  </div>
  </sg-confirmation-section>
</base-dialog>

<ng-template #selectedResources let-hasDelete="hasDelete">
  <ng-container *ngFor="let resource of selectedItems; let index = index; let last = last">
    <div class="choice-infos-row" [class.lastRow]="last">
      <div class="choice-infos-container-resource">
        <div class="row-icon">
          <img class="resource-type-avatar" [src]="getResourceIcon(resource)"/>
        </div>

        <div class="choices-infos">
          <div>{{ resource.displayName }}</div>
          <div class="email-part">{{ resource.description }}</div>
        </div>
      </div>
      <span *ngIf="resource.isOwner" class="badge owner">{{ 'ressource.badge.owner' | t }}</span>
      <span *ngIf="!resource.isOwner" class="badge owner">{{ 'ressource.badge.member' | t }}</span>
      <button *ngIf="hasDelete" class="remove-icon" (click)="removeGroup(resource.id)">
        <img src="assets/favicons/close.svg">
      </button>
    </div>
  </ng-container>
</ng-template>
