import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { EcoProfileComponent } from './eco-profile.component';

const routes: Routes = [
  {
    path: '',
    component: EcoProfileComponent,
  },
];

@NgModule({
  imports: [ RouterModule.forChild(routes) ],
  exports: [ RouterModule ],
})
export class EcoProfileRoutingModule {
}
