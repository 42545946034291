import { Injectable } from '@angular/core';
import { GroupsTemporaryDataService } from '@common/services/api/sg/temporary-data/groups-temporary-data.service';
import { LanguageEnum } from '@ui-kit/interfaces/ILanguage';
import { DTPipe } from '@ui-kit/pipes/dt.pipe';
import { GroupTypeEnum } from '@ui-kit/util/icon-util';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { GroupTemplateLifecycleEnum } from 'projects/@common/services/api/sg/group-templates/group-templates.definitions';
import { GroupsApiService } from 'projects/@common/services/api/sg/groups/groupsApi.service';
import { BehaviorSubject } from 'rxjs';
import { CriteriaFactory, CriteriaTypeEnum, ResourceCard } from './resourceCard';

export enum ResourceStatusEnum {
  RISK = "RISK",
  COMPLIANT = "COMPLIANT"
}


export interface IOwnerGroup {
  displayName: string;
  description: string;
  createdAt: number;
  id: string;
  isCertifyExpiring: boolean;
  isCertifyExpired: boolean;
  type: GroupTypeEnum;
  certificationInformation: { lastValidation: number, lastValidatorName: string; };
  archiveDate?: number;
  lastActivityDate?: number;
  securityModel: {
    isExpiring: boolean,
    expirationDuration: number,
    expirationTrigger: GroupTemplateLifecycleEnum,
    name: string;
  };
}

export interface IResourceResponse {
  ownedGroups: IOwnerGroup[];
  nextToken: string;
  total: number
}

@Injectable({
  providedIn: 'root',
})
export class ResourcesListService {
  public cardsSubject$ = new BehaviorSubject<ResourceCard[]>([]);

  public userResourceAvailable: boolean;

  public initialUserResources: ResourceCard[] = [];

  public filteredResources: ResourceCard[] = [];

  public textSearch: string = '';

  public isLoading = false;

  public isLoadingMore = false;

  public getResourceGroupRequest = new GetResourceGroupRequest();

  public resourceResponse: IResourceResponse;

  private totalOwnedGroups: IOwnerGroup[];

  public total = 0;

  constructor (
    private temporaryDataService: GroupsTemporaryDataService,
    private groupsApi: GroupsApiService,
    private i18nService: I18nService,
    private dtPipe: DTPipe
  ) {
  }

  public resourceWithRisk(): boolean {
    return this.initialUserResources.length > 0 ? !this.initialUserResources.every((resource) => resource.isResourceValid()) : false;
  }

  public clearAll(): void {
    this.userResourceAvailable = null;
    this.filteredResources = [];
    this.initialUserResources = [];
    this.getResourceGroupRequest.resetAll();
    this.emitNextValue();
  }

  public async textSearchAction(value: string): Promise<void> {
    this.getResourceGroupRequest.resetFrom();
    if (value) {
      this.textSearch = value.trim();
      this.getResourceGroupRequest.fulltext = value.trim();
      await this.getCards();
      this.emitNextValue();
    } else {
      this.clearTextSearch();
    }
  }

  public getCardsTotals(): Promise<{ total: number, atRisk: number; atRiskGroupIds: string[]; }> {
    return this.groupsApi.getUserRiskGroups();
  }

  public async clearTextSearch(): Promise<void> {
    this.textSearch = '';
    this.getResourceGroupRequest.fulltext = null;
    await this.getCards();
    this.emitNextValue();
  }

  public async sortResources(type: ResourceStatusEnum[]): Promise<void> {
    if (type.includes(ResourceStatusEnum.RISK)) {
      this.getResourceGroupRequest.validationFilter = ResourceStatusEnum.RISK;
    }

    if (type.includes(ResourceStatusEnum.COMPLIANT)) {
      this.getResourceGroupRequest.validationFilter = ResourceStatusEnum.COMPLIANT;
    }

    if (type.length === 0 || (type.includes(ResourceStatusEnum.COMPLIANT) && type.includes(ResourceStatusEnum.RISK))) {
      this.getResourceGroupRequest.validationFilter = 'ALL';
    }

    this.getResourceGroupRequest.resetFrom();

    await this.getCards();
    this.emitNextValue();
  }

  public emitNextValue(): void {
    this.cardsSubject$.next(this.filteredResources);
  }

  public getOwnerGroupById(id: string): IOwnerGroup {
    return this.totalOwnedGroups.find((group) => group.id === id);
  }

  public async getCards(): Promise<void> {
    this.getResourceGroupRequest.resetFrom();
    this.isLoading = true;
    const tempResponse = await this.groupsApi.listOwnedGroup(this.getResourceGroupRequest);
    this.total = tempResponse.total - this.temporaryDataService.groupsToHide;
    tempResponse.ownedGroups = this.temporaryDataService.getOwnerGroupWithFake(tempResponse.ownedGroups);
    this.resourceResponse = tempResponse;
    this.totalOwnedGroups = tempResponse.ownedGroups;
    if (this.getResourceGroupRequest.isDefaultCall()) {
      this.initialUserResources = this.mapRequestToCards(this.resourceResponse.ownedGroups);
      this.userResourceAvailable = this.resourceResponse.ownedGroups.length > 0;
    }

    this.filteredResources = this.mapRequestToCards(this.totalOwnedGroups);
    this.isLoading = false;
    this.emitNextValue();
  }

  public async loadMore(): Promise<void> {
    if (!this.isLoadingMore && this.canLoadMore()) {
      this.isLoadingMore = true;
      this.getResourceGroupRequest.from = this.resourceResponse.nextToken;

      const tempResponse = await this.groupsApi.listOwnedGroup(this.getResourceGroupRequest).finally(() => this.isLoadingMore = false);
      this.total = tempResponse.total - this.temporaryDataService.groupsToHide;
      tempResponse.ownedGroups = this.temporaryDataService.getOwnerGroupWithFake(tempResponse.ownedGroups);
      this.resourceResponse = tempResponse;
      this.totalOwnedGroups = this.totalOwnedGroups.concat(tempResponse.ownedGroups);
      this.filteredResources = this.mapRequestToCards(this.totalOwnedGroups);
      this.emitNextValue();
    }
  }

  public getResourceWithAction(): ResourceCard[] {
    return this.initialUserResources.filter((resource) => !resource.isResourceValid());
  }

  public canLoadMore(): boolean {
    return !!this.resourceResponse?.nextToken;
  }

  private mapRequestToCards(listOfResources: IOwnerGroup[]): ResourceCard[] {
    return listOfResources.map((resource) => {
      const card = new ResourceCard(resource.type, resource.displayName, resource.description, resource.id, resource.securityModel);
      const criteriaFactory = new CriteriaFactory(this.i18nService);

      card.addCriteria(criteriaFactory.createCriteria(CriteriaTypeEnum.securityModel, !!resource.securityModel?.name, this.getsecurityModelDescription(resource.securityModel?.name), true));

      if (resource.securityModel?.name) {
        card.addCriteria(criteriaFactory.createCriteria(CriteriaTypeEnum.expiration, this.getExpirationValidity(resource.archiveDate), this.getExpirationDescription(resource.securityModel, resource.archiveDate), true, resource.securityModel?.isExpiring));
      }
      card.addCriteria(criteriaFactory.createCriteria(CriteriaTypeEnum.certificate, !!resource.certificationInformation.lastValidation && !resource.isCertifyExpired, this.getCertificationDescription(resource), true, resource.isCertifyExpiring));

      return card;
    });
  }

  private getsecurityModelDescription(securityModel: string): string {
    return securityModel
      ? securityModel
      : this.i18nService.translate('shares.gestions.security_model.invalid');
  }

  private getExpirationValidity(expDate: number): boolean {
    const actualDate = new Date().getTime();
    return expDate > actualDate;
  }

  private getExpirationDescription(securityModel: { expirationDuration: number, expirationTrigger: GroupTemplateLifecycleEnum; }, archiveDate?: number): string {
    return securityModel.expirationTrigger === GroupTemplateLifecycleEnum.INACTIVITY
      ? this.i18nService.translate('shares.gestions.security_model.expiration.inactivity', { nbDays: securityModel.expirationDuration, archiveDate: this.dtPipe.transform(archiveDate, { withTime: false, locale: this.i18nService.currentLocale }) })
      : this.i18nService.translate('shares.gestions.security_model.expiration.fromCreation', { nbDays: securityModel.expirationDuration, archiveDate: this.dtPipe.transform(archiveDate, { withTime: false, locale: this.i18nService.currentLocale }) });
  }

  private getCertificationDescription(group: IOwnerGroup): string {
    const local = { withTime: false, locale: this.i18nService.currentLocale as LanguageEnum, withSeconds: false };
    let description: string = null;

    if (group.isCertifyExpired) {
      description = this.i18nService.translate('shares.gestions.security_model.certification.expired');
    }

    if (group.isCertifyExpired === false && group.certificationInformation.lastValidation) {
      description = this.i18nService.translate('shares.gestions.security_model.certification.date', { date: this.dtPipe.transform(group.certificationInformation.lastValidation, local), validator: group.certificationInformation.lastValidatorName });
    }

    if (group.isCertifyExpired === null) {
      description = this.i18nService.translate('shares.gestions.security_model.certification.date.invalid');
    }

    return description;
  }
}

export class GetResourceGroupRequest {
  from?: any;
  limit?: number;
  fulltext?: string;
  validationFilter?: string;

  constructor (jsonObj?: any) {
    Object.assign(this, jsonObj);
  }

  public resetAll(): void {
    this.from = null;
    this.limit = null;
    this.fulltext = null;
    this.validationFilter = null;
  }

  public isDefaultCall(): boolean {
    return !this.from && !this.fulltext && !this.limit && !this.validationFilter;
  }

  public resetFrom(): void {
    this.from = null;
  }

  public getQueryParams(): any {
    const params = {
      from: this.from,
      limit: 25,
      fulltext: this.fulltext,
      validationFilter: this.validationFilter,
    };

    return params;
  }
}
