import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MobileService } from '@common/services/mobile.service';
import { Store } from '@ngxs/store';
import { CarouselDialogComponent } from 'projects/@assist-ops/components/carousel-dialog/carousel-dialog.component';
import { ProfileCardColorEnum } from 'projects/@assist-ops/components/eco-profile-card/eco-profile-card.component';
import { UserProfile } from 'projects/@assist-ops/components/user-info-dashboard/user-info-dashboard.component';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { FeatureFlagsEnum } from 'projects/@common/modules/layout/definitions/base-sidebar.service';
import { EcoSessionState } from 'projects/@common/modules/session/state/session.state';
import { BadgeEnum } from 'projects/@common/services/api/sg/md.eco-profile/md.eco-profile.definition';
import { StorageService } from 'projects/@common/services/storage.service';
import { toTitleCaseName } from 'projects/@common/utils/utils';


@Component({
  selector: 'sg-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent implements OnInit {
  public portrait: { name: string, year: number; };

  @Input()
  userProfile: UserProfile;

  public cardColorEnum = ProfileCardColorEnum;

  public badges: any;

  public action: any;

  public year: any;

  public monthlyData: { date: Date, value: number; }[];

  public badgesList: { badge: BadgeEnum, count: number }[] = [];

  public totalBadges: number = 0;

  constructor(
    public mobileService: MobileService,
    private readonly store: Store,
    private i18n: I18nService,
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private storage: StorageService
  ) {
  }

  ngOnInit(): void {
    this.setupBadge();
    this.setupActionList();
    this.setupMonthlyChart();
    this.setupPortrait();
    this.setupBadgesList();
  }

  public restartCarousel(): void {
    this.dialog.open(CarouselDialogComponent, {
      width: '75%',
      minWidth: '720px',
      maxWidth: '1000px',
      panelClass: 'custom-dialog',
    });
  }

  private setupPortrait(): void {
    const username = toTitleCaseName(this.store.selectSnapshot(EcoSessionState.userName).split(" ")[0]);

    this.portrait = {
      name: username.charAt(0).toUpperCase() + username.slice(1),
      year: new Date().getFullYear(),
    };
  }

  private setupBadge(): void {
    this.badges = [
      {
        icon: 'assets/favicons/icon_Thumbs_up_green.svg',
        name: 'eco-profile.page.tab.user-profile.user-info.badge.action',
        quantity: this.userProfile.userActions.total - this.userProfile.userActions.revokeUsers
      },
      {
        icon: 'assets/favicons/icon_pause_ok_no_fill.svg',
        name: 'eco-profile.page.tab.user-profile.user-info.badge.week',
        quantity: this.userProfile.conformities.yearlyCount?.[new Date().getFullYear()] || 0
      },
      {
        icon: 'assets/favicons/icon_medal.svg',
        name: 'eco-profile.page.tab.user-profile.user-info.badge.amount',
        quantity: this.userProfile.badges.userBadges.length
      },
    ];
  }

  private setupBadgesList(): void {
    const badgeCounts = this.groupBadges(this.userProfile.badges.userBadges);
    this.badgesList = Array.from(badgeCounts.entries()).map(([badge, count]) => ({ badge, count }));
    this.totalBadges = Array.from(badgeCounts.values()).reduce((sum, count) => sum + count, 0);
  }

  private groupBadges(badges: BadgeEnum[]): Map<BadgeEnum, number> {
    const badgeMap = new Map<BadgeEnum, number>();
    badges.forEach((badge) => {
      if (badgeMap.has(badge)) {
        badgeMap.set(badge, badgeMap.get(badge)! + 1);
      } else {
        badgeMap.set(badge, 1);
      }
    });
    return badgeMap;
  }

  private setupActionList(): void {
    this.action = {
      title: 'eco-profile.page.tab.user-profile.proactive-section.title',
      emptyMessage: 'eco-profile.page.tab.user-profile.proactive-section.no-value',
      actions: [
        {
          name: 'eco-profile.page.tab.user-profile.proactive-section.revocation.label',
          description: this.i18n.translate('eco-profile.page.tab.user-profile.proactive-section.revocation.values', { shareAmount: this.userProfile.userActions.revokeShares.actions }),
          shouldDisplay: this.userProfile.userActions.revokeShares.actions > 0,
          tooltip: this.i18n.translate('eco-profile.page.tab.user-profile.proactive-section.revocation.tooltip', { shareLinkAmount: this.userProfile.userActions.revokeShares.links }),
          image: 'icon_unlink_grey.svg',
          flagRequired: [FeatureFlagsEnum.SHARES_MANAGEMENT],
        },
        {
          name: 'eco-profile.page.tab.user-profile.proactive-section.certification.label',
          description: this.i18n.translate('eco-profile.page.tab.user-profile.proactive-section.certification.values', { actionNumber: this.userProfile.userActions.certify.actions }),
          shouldDisplay: this.userProfile.userActions.certify.actions > 0,
          tooltip: this.i18n.translate('eco-profile.page.tab.user-profile.proactive-section.certification.tooltip', { revokeNumber: this.userProfile.userActions.certify.revokes }),
          image: 'icon_certifier.svg',
          flagRequired: [FeatureFlagsEnum.RESOURCE_MANAGEMENT],
        },
        {
          name: 'eco-profile.page.tab.user-profile.proactive-section.sensitive-files-removed.label',
          description: this.i18n.translate('eco-profile.page.tab.user-profile.proactive-section.sensitive-files-removed.values', { actionNumber: this.userProfile.userActions.removeSensitiveFile }),
          shouldDisplay: this.userProfile.userActions.removeSensitiveFile > 0,
          tooltip: this.i18n.translate('eco-profile.page.tab.user-profile.proactive-section.sensitive-files-removed.tooltip', { revokeNumber: this.userProfile.userActions.removeSensitiveFile }),
          image: 'icon_eye_off.svg',
          flagRequired: [FeatureFlagsEnum.DLP_MANAGEMENT],
        },
        {
          name: 'eco-profile.page.tab.user-profile.proactive-section.archive.label',
          description: this.userProfile.userActions.archive,
          shouldDisplay: this.userProfile.userActions.archive > 0,
          image: 'icon_template_added.svg',
          flagRequired: [FeatureFlagsEnum.RESOURCE_MANAGEMENT],
        },
        {
          name: 'eco-profile.page.tab.user-profile.proactive-section.template.label',
          description: this.userProfile.userActions.addTemplate,
          shouldDisplay: this.userProfile.userActions.addTemplate > 0,
          image: 'icon_template_added.svg',
          flagRequired: [FeatureFlagsEnum.RESOURCE_MANAGEMENT],
        }],
      knowMoreText: 'eco-profile.page.tab.user-profile.proactive-section.know-more',
    };

    const weeklyStreak = this.longestStreakDate();

    this.year = {
      title: 'eco-profile.page.tab.user-profile.weekly-section.title',
      emptyMessage: 'eco-profile.page.tab.user-profile.weekly-section.no-value',
      actions: [
        {
          name: 'eco-profile.page.tab.user-profile.weekly-section.current.label',
          description: this.i18n.translate('eco-profile.page.tab.user-profile.weekly-section.current.desc', { total: this.userProfile.conformities.currentStreakTotal }),
          shouldDisplay: true,
        },
        {
          name: 'eco-profile.page.tab.user-profile.weekly-section.total-year.label',
          description: this.i18n.translate('eco-profile.page.tab.user-profile.weekly-section.total-year.desc', { total: this.userProfile.conformities?.yearlyCount?.[new Date().getFullYear()] }),
          shouldDisplay: true,
        },
        {
          name: 'eco-profile.page.tab.user-profile.weekly-section.best-sequence.label',
          description: this.i18n.translate('eco-profile.page.tab.user-profile.weekly-section.best-sequence.desc', {
            total: this.userProfile.conformities?.longestStreakTotal,
            firstWeek: weeklyStreak.firstWeek,
            lastWeek: weeklyStreak.lastWeek
          }),
          shouldDisplay: true,
        },
      ],
      knowMoreText: `eco-profile.page.tab.user-profile.weekly-section.know-more`,
    };
  }

  private setupMonthlyChart(): void {
    if (this.userProfile.userActions.monthlyCounts) {
      this.monthlyData = this.userProfile.userActions.monthlyCounts?.map((month) => ({
        date: new Date(month.date),
        value: month.total,
      }));
    }
  }

  private longestStreakDate(): { firstWeek: string, lastWeek: string; } {
    if (this.userProfile.conformities.longestStreakValues.length > 0) {
      const firstWeek = Math.min(...this.userProfile.conformities.longestStreakValues);
      const lastWeek = Math.max(...this.userProfile.conformities.longestStreakValues);

      if (firstWeek && lastWeek) {
        let firstWeekReadable: string;

        if (new Date(firstWeek).getFullYear() === new Date(lastWeek).getFullYear()) {
          firstWeekReadable =
            this.datePipe.transform(new Date(firstWeek), this.i18n.currentLocale === 'fr' ? 'dd MMMM' : 'MMM dd', null, this.i18n.dateCurrentLocale);
        } else {
          firstWeekReadable = this.datePipe.transform(new Date(firstWeek), 'longDate', null, this.i18n.dateCurrentLocale);
        }

        const lastWeekReadable = this.datePipe.transform(new Date(lastWeek), 'longDate', null, this.i18n.dateCurrentLocale);

        return {
          firstWeek: firstWeekReadable,
          lastWeek: lastWeekReadable,
        };
      }
    }

    return {
      firstWeek: '0',
      lastWeek: '0',
    };
  }
}
