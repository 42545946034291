import { NgModule } from '@angular/core';
import { CarouselDialogComponent } from './carousel-dialog.component';
import { PipesModule } from 'projects/@common/modules/pipes/pipes.module';
import { FeatureSharedModule } from 'projects/@common/modules/shared/feature.shared.module';
import { GenericDescriptionSlideComponent } from './generic-description-slide/generic-description-slide.component';
import { IntroductionSlideComponent } from './introduction-slide/introduction-slide.component';
import { QuickActionsDescriptionSlideComponent } from './quick-actions-description-slide/quick-actions-description-slide.component';
import { EcoBreaktimeDescriptionSlideComponent } from './eco-breaktime-description-slide/eco-breaktime-description-slide.component';
import { EcoProfileDescriptionSlideComponent } from './eco-profile-description-slide/eco-profile-description-slide.component';
import { SummaryDescriptionSlideComponent } from './summary-description-slide/summary-description-slide.component';

const components = [
  CarouselDialogComponent,
  IntroductionSlideComponent,
  GenericDescriptionSlideComponent,
  QuickActionsDescriptionSlideComponent,
  EcoBreaktimeDescriptionSlideComponent,
  EcoProfileDescriptionSlideComponent,
  SummaryDescriptionSlideComponent,
];

@NgModule({
  declarations: [ ...components ],
  exports: [ ...components ],
  imports: [ PipesModule, ...FeatureSharedModule.commonImports() ],
})
export class CarouselModule {}
