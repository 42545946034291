import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UiKitModule } from '@ui-kit/uikit.module';
import { AssistOpsModule } from 'projects/@assist-ops/assist-ops.module';
import { DirectivesModule } from 'projects/@common/modules/directives/directives.module';
import { I18nModule } from 'projects/@common/modules/i18n/i18n.module';
import { BannersComponent } from './components/banners/banners.component';
import { SgDialogsModule } from './components/dialogs/dialogs.module';
import { PageLayoutComponent } from './components/page-layout/page-layout.component';
import { ResourceCardContentComponent } from './components/resource-card-content/resource-card-content.component';
import { ResourceCardHeaderComponent } from './components/resource-card-header/resource-card-header.component';

const components = [
  ResourceCardHeaderComponent,
  ResourceCardContentComponent,
  BannersComponent,
  PageLayoutComponent,
];

@NgModule({
  declarations: [ ...components ],
  exports: [ ...components ],
  imports: [
    I18nModule,
    UiKitModule,
    CommonModule,
    AssistOpsModule,
    DirectivesModule,
    SgDialogsModule,
  ],
})
export class SgSharedModule { }
