import { ChangeDetectorRef, Component, OnChanges, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { GroupsTemporaryDataService } from '@common/services/api/sg/temporary-data/groups-temporary-data.service';
import { Select, Store } from '@ngxs/store';
import { Eco } from 'projects/@common/definitions/eco';
import { EcoSessionState } from 'projects/@common/modules/session/state/session.state';
import { NotificationsApiService } from 'projects/@common/services/api/notifications/notifications-api.service';
import { RequestsApiService } from 'projects/@common/services/api/requests/requests-api.service';
import { GroupsApiService } from 'projects/@common/services/api/sg/groups/groupsApi.service';
import { MobileService } from 'projects/@common/services/mobile.service';
import { TeamsAuthService } from 'projects/@common/services/teams-auth.service';
import { Observable } from 'rxjs';
import { GroupQuickActionDialogComponent, OwnerGroupActionType } from '../../../shared/components/dialogs/resources-dialogs/groupe-quick-action-dialog/group-quick-action-dialog.component';
import { ListCategoryType } from '../../requests/containers/requests.container';
import IOrganization = Eco.IOrganization;

export interface IUserWidgets {
  breaktimeTasks?: IWidgetActive;
  sharesDistribution?: IWidgetActive;
  resourcesWithShares?: IWidgetActive;
}

export interface IWidgetActive {
  isActive?: boolean;
}

@Component({
  selector: 'dashboard',
  templateUrl: './dashboard.container.html',
  styleUrls: [ './dashboard.container.scss' ],
  encapsulation: ViewEncapsulation.None,
})
export class DashboardContainer implements OnInit, OnChanges {
  @Select(EcoSessionState.user) public currentUser$: Observable<any>;
  @Select(EcoSessionState.organization)
  public currentOrganization$: Observable<IOrganization>;
  public userTotalRequest: number = 0;
  public groupAmount: {
    total: number;
    atRisk: number;
    atRiskGroupIds: string[];
  } = { total: null, atRisk: null, atRiskGroupIds: [] };
  public badgeNotifications = [];
  private organizationGuid: string;

  constructor(
    private requestsApiService: RequestsApiService,
    protected readonly store: Store,
    private cdr: ChangeDetectorRef,
    public mobileService: MobileService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private groupsApi: GroupsApiService,
    private teamsAuthService: TeamsAuthService,
    private notificationsService: NotificationsApiService,
    private temporaryDataService: GroupsTemporaryDataService
  ) { }

  async ngOnInit(): Promise<void> {
    if (TeamsAuthService.isInTeams) {
      if (this.teamsAuthService.context.page.subPageId) {
        this.router.navigateByUrl(`/${this.teamsAuthService.context.page.subPageId}`);
        this.teamsAuthService.context.page.subPageId = null;
      }
    }

    const queryParams = this.route.snapshot.queryParams;
    if (queryParams.redirect) {
      this.router.navigateByUrl(queryParams.redirect);
    }

    this.currentOrganization$.subscribe((value) => (this.organizationGuid = value?.id));

    this.loadUserRisk();
    this.requestsApiService
      .getRequests({ limit: 1, category: ListCategoryType.ACTION_REQUIRED })
      .then((value) => (this.userTotalRequest = value.toProcessNumber));

    this.getBadgeNotifications();
    this.notificationsService.getObservable().subscribe((event) => {
      const messageEvent = event as MessageEvent;
      const notification = JSON.parse(messageEvent.data);
      if (
        notification?.sentWithConfig?.payload?.relatedObject?.find((element) => element?.objectType === 'newBadgeTypes')
      ) {
        this.getBadgeNotifications();
      }
    });

    if (+queryParams.accessRevision) {
      this.certifyAccesses();
    }
    if (+queryParams.archiveResources) {
      this.archiveResources();
    }
  }

  public loadUserRisk(): void {
    this.groupsApi
      .getUserRiskGroups()
      .then((response) => {
        const atRiskGroupIds = this.temporaryDataService.getAtRiskResources(response.atRiskGroupIds);
        response = { ...response, atRiskGroupIds, atRisk: atRiskGroupIds.length };
        this.groupAmount = response;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  public ngOnChanges() {
    setTimeout(() => {
      this.cdr.detectChanges();
    }, 1);
  }

  private getBadgeNotifications() {
    this.notificationsService
      .listNotifications({
        from: 0,
        order: 'DESC',
        size: 50,
        searchText: 'Badges',
      })
      .then((response) => {
        this.badgeNotifications = [];
        const values = response.items.filter((item) => item?.sentWithConfig?.payload?.relatedObject.find((value) => value?.objectType === 'newBadgeTypes') && !item.seen);
        this.badgeNotifications.push(...values);
      });
  }

  private certifyAccesses(): void {
    this.dialog.open(GroupQuickActionDialogComponent, {
      maxWidth: !this.mobileService.isMobile() ? '75%' : '100%',
      width: 'max-content',
      panelClass: 'custom-dialog',
      data: {
        type: OwnerGroupActionType.certifyAccesses,
        dialogTitle: 'certify.title.noGroup',
        dialogSubtitle: 'certify.second.title',
        dialogDescription: 'certify.group.selection',
      },
    });
  }

  private archiveResources(): void {
    this.dialog.open(GroupQuickActionDialogComponent, {
      minWidth: '730px',
      maxWidth: !this.mobileService.isMobile() ? '75%' : '100%',
      width: 'max-content',
      panelClass: 'custom-dialog',
      data: {
        type: OwnerGroupActionType.archive,
        dialogTitle: 'group.action.remediation.button.archive',
        dialogSubtitle: 'quickAction.model.title.description',
        dialogDescription: 'quickAction.archive.subtitle',
      },
    });
  }
}
