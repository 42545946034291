import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { BaseDialogComponent } from 'projects/@assist-ops/components/base-dialog/base-dialog.component';
import { Accumulatables, Accumulate } from 'projects/@common/modules/accumulator/accumulator.store';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { DrawerService } from 'projects/@common/modules/layout/components/drawer/services/drawer.service';
import { NoticeLevels, NoticeService } from 'projects/@common/modules/notice/notice.service';
import { OrganizationsApiService } from 'projects/@common/services/api/sg/organizations/organizationsApi.service';
import { SharesApiService } from 'projects/@common/services/api/sg/shares/shares-api.service';

@Component({
  selector: 'silence-share-notification',
  templateUrl: './silence-share-notification.component.html',
  styleUrls: [ './silence-share-notification.component.scss' ],
})
export class SilenceShareNotificationComponent implements OnInit {
  public silencingForm: UntypedFormGroup;

  public options: string[] = [];
  public isLoading = false;

  constructor(
    public dialogRef: MatDialogRef<BaseDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { sharedFileId: string; options: string[] },
    public dialog: MatDialog,
    private drawerService: DrawerService,
    private readonly formBuilder: UntypedFormBuilder,
    private i18n: I18nService,
    private sharesApi: SharesApiService,
    private store: Store,
    private noticeService: NoticeService,
    private organizationsApiService: OrganizationsApiService
  ) {}

  ngOnInit(): void {
    for (const option of this.data.options) {
      this.options.push(this.i18n.translate('groups.action.archive_date.new_expiration_duration_vavlue', { duration: option }));
    }
    this.silencingForm = this.formBuilder.group({
      silenceDuration: this.formBuilder.control(this.options[0], Validators.required),
      justification: this.formBuilder.control('', Validators.required),
    });
  }

  public cancel(): void {
    this.dialogRef.close();
  }

  public save(): void {
    this.sharesApi
      .silenceSharedFileNotifications(this.data.sharedFileId, {
        justification: this.silencingForm.value.justification,
        silenceDuration: this.convertOptionToNumber(this.silencingForm.value.silenceDuration),
      })
      .then(() => {
        this.noticeService.notifyUser({
          message: 'shares.success.notification.silence.message',
          level: NoticeLevels.SUCCESS,
        });
      })
      .catch((error) => {
        this.noticeService.notifyUser({
          message: 'shares.error.notification.silence.message',
          level: NoticeLevels.ERROR,
        });
      })
      .finally(() => {
        this.dialogRef.close();
        this.drawerService.hideDrawer();
        this.store.dispatch(new Accumulate({ accumulatable: Accumulatables.LIST_REFRESHER }));
      });
  }

  private convertOptionToNumber(option: string): number {
    return +option.split(' ')[0];
  }
}
