import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ResourceSharesApiService } from 'projects/@common/services/api/sg/resourceSharedFiles/resource-shares-api.service';
import { BaseDialogComponent } from '../base-dialog/base-dialog.component';

export enum RevokeTypeEnum {
  EXPRESS_REVOKE = 'expressRevoke',
  BATCH_REVOKE = 'batchRevoke',
  REVIEW_REVOKE = 'reviewRevoke'
}

@Component({
  selector: 'revoke-shares-dialog',
  templateUrl: './revoke-shares-dialog.component.html',
  styleUrls: [ './revoke-shares-dialog.component.scss' ],
})
export class RevokeSharesDialogComponent implements OnInit {
  public noRiskUserShares: boolean;

  public actionType: RevokeTypeEnum;

  public isLoading = false;

  private buttonName = 'revoke.dialog.button.title.';

  private buttonDescription = 'revoke.dialog.button.text.';
  public buttonList = [
    {
      name: this.buttonName,
      description: this.buttonDescription,
      image: 'icon_list.svg',
      type: RevokeTypeEnum.BATCH_REVOKE,
    },
    {
      name: this.buttonName,
      description: this.buttonDescription,
      image: 'icon_list_search.svg',
      type: RevokeTypeEnum.REVIEW_REVOKE,
    },
  ];

  constructor(
    public dialogRef: MatDialogRef<BaseDialogComponent>,
    public resourceSharesService: ResourceSharesApiService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.getUserRiskResources();
    this.actionType = this.data?.actionType;
  }

  public revoke(revokeType: RevokeTypeEnum): void {
    if (revokeType === RevokeTypeEnum.REVIEW_REVOKE) {
      this.router.navigateByUrl(`myshares`);
      this.dialogRef.close();
      return;
    }

    this.actionType = revokeType;
  }

  private getUserRiskResources(): void {
    this.isLoading = true;
    this.resourceSharesService.getUserRiskResources()
      .then((riskUserShares) => {
        if (!riskUserShares.riskUserSharesHIGH && !riskUserShares.riskUserSharesMED && !riskUserShares.riskUserSharesLOW && (this.data?.resourceId && !riskUserShares.riskUserResources)) {
          this.noRiskUserShares = true;
          return;
        }

        if (riskUserShares.riskUserSharesHIGH || riskUserShares.riskUserSharesMED) {
          this.buttonList =
            [
              {
                name: this.buttonName, description: this.buttonDescription,
                image: 'icon_target.svg', type: RevokeTypeEnum.EXPRESS_REVOKE,
              },
              ...this.buttonList,
            ];
        }
      })
      .finally(() => {
        this.isLoading = false;
      });
  }
}
