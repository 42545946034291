<div class="profile-container">

  <div class="left-side">
    <!-- user info -->
   <user-info-dashboard [userProfile]="userProfile"></user-info-dashboard>

    <div class="cards">
      <!-- proactive actions -->
      <sg-eco-profile-card [cardColor]="cardColorEnum.white">
        <ng-container *ngTemplateOutlet="cardContent; context:{config: action}"></ng-container>
      </sg-eco-profile-card>

      <!-- weekly-->
      <sg-eco-profile-card [cardColor]="cardColorEnum.white">
        <ng-container *ngTemplateOutlet="cardContent; context:{config: year}"></ng-container>
      </sg-eco-profile-card>
    </div>

    <!-- badges-->
    <sg-eco-profile-card
      [cardColor]="cardColorEnum.white"
      *ngIf="badgesList.length > 0"
    >
      <div class="list-container">
        <h2>{{'eco-profile.page.tab.user-profile.user-info.badge.amount' | t: {quantity: totalBadges} }}</h2>
        <sg-badges-details [badgesList]="badgesList"></sg-badges-details>
      </div>
    </sg-eco-profile-card>
  </div>

  <div class="right-side">
    <!-- monthly graph-->
    <sg-monthly-actions-graph [data]="monthlyData"></sg-monthly-actions-graph>
    <!-- weekly graph-->
    <sg-consecutive-weekly-graph
      *ngIf="userProfile.conformities.yearlyCount"
      [conformities]="userProfile.conformities"
    ></sg-consecutive-weekly-graph>
    <!-- introductory carousel -->
    <sg-eco-profile-card [cardColor]="cardColorEnum.white" *ngIf="!mobileService.isMobile()">
      <div class="introduction-link">
        <img src="/assets/favicons/icon_info.svg" alt="Info Icon" class="info-icon">
        <a (click)="restartCarousel()">{{'eco-profile.page.tab.user-profile.introductory-carousel' | t:portrait}}</a>
      </div>
    </sg-eco-profile-card>
  </div>
</div>


<ng-template
  #cardContent
  let-config="config"
  let-minWidth="minWidth"
>
  <sg-eco-profile-row [secondCellWidth]="minWidth" [config]="config"></sg-eco-profile-row>
</ng-template>
