import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { SharedTo } from './shared-to/shared-to.component';
import { ISharedFilePermission } from 'projects/@common/services/api/sg/shares/shares.definitions';
import {
  StaticTableDataMapper
} from 'projects/@common/modules/i18n/component-wrapper/static-table-data-mapper.service';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { UiDotIndicatorComponent } from '@ui-kit/components/ui-dot-indicator/ui-dot-indicator.component';
import { UiStaticTableRowType } from '@ui-kit/components/ui-static-table/ui-static-table-row/ui-static-table-row.component';
import { UiTableDirection } from '@ui-kit/components/ui-table/ui-table.component';
import { DTPipe } from '@ui-kit/pipes/dt.pipe';

@Component({
  selector: 'shares-section',
  templateUrl: './shares-section.component.html',
  host: {
    class: 'shares-section',
  },
  encapsulation: ViewEncapsulation.None,
})
export class SharesSection implements OnInit, OnChanges {
  @Input() public shares: ISharedFilePermission[];

  @Input() public isAutomaticRevokeActivated = false;

  @Input() public fileCreationDateLimit: number;

  @Input() public sort: string;

  @Input() public direction: UiTableDirection;

  @Input() public isEditing: boolean;

  @Input() public isLoading: boolean;

  @Output() public onSorting: EventEmitter<any> = new EventEmitter();

  @Output() public onPermissionRemove: EventEmitter<ISharedFilePermission> = new EventEmitter();

  public isChangingColumnDef = false;

  public columnsDef: any[] = [
    {
      label: '',
      field: 'icon',
      type: UiStaticTableRowType.EXPENDABLE_ICON,
      width: '32px',
      isResizable: false,
    },
    {
      label: 'sharedFile.drawer.shares.table.sharedTo',
      field: 'sharedTo',
      type: UiStaticTableRowType.DYNAMIC_COMPONENT_HOST,
      component: SharedTo,
      paramName: 'sharedFilePermission',
      secondParamName: 'isAutomaticRevokeActivated',
      isResizable: false,
    },
    {
      label: 'sharedFile.drawer.shares.table.risk',
      field: 'risk',
      type: UiStaticTableRowType.DYNAMIC_COMPONENT_HOST,
      component: UiDotIndicatorComponent,
      paramName: 'riskInfo',
      firstParamData: 'riskInfo',
      isResizable: false,
      width: '60px',
    },
    {
      label: 'sharedFile.drawer.shares.table.permission',
      field: 'permission',
      type: UiStaticTableRowType.TRANSLATED_TEXT,
      translationKeyPrefix: 'sharedFile.drawer.shares.table.permission.',
      isResizable: false,
      width: '130px',
    },
  ];

  private lastIsEditing = false;

  constructor(public staticTableDataMapper: StaticTableDataMapper, private i18n: I18nService) {
  }


  public ngOnInit() {
    this.columnsDef[1].secondParamValue = this.isAutomaticRevokeActivated;

    if (this.isAutomaticRevokeActivated) {
      this.columnsDef.splice(2, 0, {
        label: 'sharedFile.drawer.shares.table.revocation',
        field: 'automaticRevokeDate',
        type: UiStaticTableRowType.PIPED_FIELD,
        pipe: DTPipe,
        isResizable: false,
        width: '135px',
      });
    }
    this.shares = this.shares.map((share) => {
      const riskLowercase = share.risk.toLowerCase();
      share.riskInfo = { risk: riskLowercase, translatedRisk: this.i18n.translate(`shares.shareRiskScore.${riskLowercase}`) };
      return share;
    });
    [ this.columnsDef, this.shares ] = this.staticTableDataMapper.translate(this.columnsDef, this.shares);
  }

  public ngOnChanges() {
    if (this.isEditing !== this.lastIsEditing) {
      this.isChangingColumnDef = true;

      if (this.isEditing) {
        this.columnsDef.push({
          label: '',
          field: 'remove',
          type: UiStaticTableRowType.ICON,
          getIcon: () => 'assets/favicons/icon_remove.svg',
          noSorting: true,
          width: '50px',
          isResizable: false,
          onClick: this.removePermission.bind(this),
        });

        for (const columnDef of this.columnsDef) {
          columnDef['noSorting'] = true;
        }
      } else {
        this.columnsDef = this.columnsDef.filter((columnDef) => columnDef.field !== 'remove');

        for (const columnDef of this.columnsDef) {
          columnDef['noSorting'] = false;
        }
      }

      this.lastIsEditing = this.isEditing;
      setTimeout(() => {
        this.isChangingColumnDef = false;
      }, 1);
    }
    this.shares = this.shares.map((share) => {
      const riskLowercase = share.risk.toLowerCase();
      share.riskInfo = { risk: riskLowercase, translatedRisk: this.i18n.translate(`shares.shareRiskScore.${riskLowercase}`) };
      return share;
    });
    [ this.columnsDef, this.shares ] = this.staticTableDataMapper.translate(this.columnsDef, this.shares);
  }

  public handleSorting(sortingColumn: string, sortingDirection: UiTableDirection) {
    this.onSorting.emit({
      sortingColumn,
      sortingDirection,
    });
  }

  public removePermission(permissionToRemove: ISharedFilePermission) {
    this.onPermissionRemove.emit(permissionToRemove);
  }
}
