<div
  page
  [appPagePaths]="['org', 'userShares']"
  [specialPageName]="'shares.users' | t"
  scrollToBottom
  [contextualHelp]="documentationSection"
  (onScrollToBottom)="loadMore()"
  [disableOrganizationSelector]="true"
>
  <ng-container description>
    {{ 'shares.users.pageDescription.admin' | t }}
  </ng-container>

  <ng-container toolbar>
    <ui-table-total-tool [total]="total"></ui-table-total-tool>
    <ui-table-search-tool
      *ngIf="!mobileService.isMobile()"
      [placeholder]="'ui.table-search-tool.placeholder' | t"
      [query]="fulltext"
      (clear)="handleSearchClear()"
      (query)="handleSearchQuery($event)"
    ></ui-table-search-tool>
    <ui-table-filter-tool
      [tableFilterData]="sourcesSelectData"
      (select)="handleSourceFilter($event)"
    ></ui-table-filter-tool>
    <ui-multi-select
      [multiSelectData]="risksMultiSelectData"
      (onOptionSelect)="handleRiskFilter($event)"
    ></ui-multi-select>
  </ng-container>

  <ui-card>
    <ui-static-table
      [loading]="isLoading"
      [dataSource]="dataSource"
      [columnsDef]="columnsDef"
      [twoHeaders]="true"
      [direction]="direction"
      [sortHeader]="sortParent"
      [sort]="sortField"
      (on-row-click)="handleRowClick($event)"
      [addPaddingOnFirst]="false"
      [isCustomSorting]="true"
      (handleCustomSort)="handleSort($event)"
      [locale]="i18n.currentLocale"
    >
    </ui-static-table>
  </ui-card>
</div>
