import { Directive, HostListener, Input, OnDestroy, TemplateRef, ViewContainerRef, NgZone } from '@angular/core';

@Directive({
  selector: '[componentTooltipDirective]',
})
export class ComponentTooltipDirective implements OnDestroy {
  @Input() tooltipDelay = 200;
  @Input() isActivated = true;
  @Input() overrideHeightOffset = 0;

  private domElement: HTMLElement;
  private classSelector = "component-tooltip-directive-dom-container";
  private initTimer: NodeJS.Timeout;
  private selfDestroyTimer: NodeJS.Timeout;

  public contentRef!: TemplateRef<any>;

  private viewContainerRef: ViewContainerRef;

  @Input('componentTooltip')
  set componentRef(component: TemplateRef<any>) {
    if (this.isActivated) {
      this.contentRef = component;
    }
  }

  constructor(viewContainerRef: ViewContainerRef, private ngZone: NgZone) {
    this.viewContainerRef = viewContainerRef;
  }

  ngOnDestroy(): void {
    if (this.isActivated) {
      this.deleteTooltip();
    }
  }

  @HostListener('mouseenter', ['$event']) onMouseEnter(mouseEvent: MouseEvent) {
    if (this.isActivated) {
      this.initTooltip(mouseEvent);
    }
  }

  @HostListener('mouseleave') onMouseLeave() {
    if (this.isActivated) {
      this.deleteTooltip();
    }
  }

  private deleteTooltip(): void {
    this.initTimer && clearTimeout(this.initTimer);
    this.selfDestroyTimer && clearTimeout(this.selfDestroyTimer);
    if (this.domElement) {
      this.domElement.remove();
      this.domElement = null;
    }
  }

  private initTooltip(mouseEvent: MouseEvent): void {
    this.createDOMElement();
    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        this.setPosition(mouseEvent);
        this.showTooltip();
      }, 0);
    });
  }

  private createDOMElement(): void {
    this.domElement = document.createElement('div');
    const embeddedViewRef = this.viewContainerRef.createEmbeddedView(this.contentRef);

    for (const node of embeddedViewRef.rootNodes) {
      this.domElement.appendChild(node);
    }

    this.domElement.classList.add(this.classSelector);
    this.domElement.style.position = "fixed";
    this.domElement.style.visibility = "hidden";
    this.domElement.style.zIndex = "99999";
    this.domElement.style.left = "-9999px";  // Position off-screen initially
    this.domElement.style.top = "0";
    document.body.appendChild(this.domElement);
  }

  private showTooltip(): void {
    this.domElement.style.visibility = "visible";
  }

  private setPosition(mouseEvent: MouseEvent): void {
    if (this.domElement) {
      const rect = this.domElement.getBoundingClientRect();
      const tooltipWidth = rect.width;
      const tooltipHeight = rect.height;

      let x = mouseEvent.clientX + 10;  // 10px offset from cursor
      let y = mouseEvent.clientY + 10;

      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;

      // Adjust horizontal position
      if (x + tooltipWidth > windowWidth) {
        x = mouseEvent.clientX - tooltipWidth - 10;
      }

      // Adjust vertical position
      if (y + tooltipHeight > windowHeight) {
        y = mouseEvent.clientY - tooltipHeight - 10;
      }

      // Ensure tooltip doesn't go off-screen
      x = Math.max(0, Math.min(x, windowWidth - tooltipWidth));
      y = Math.max(0, Math.min(y, windowHeight - tooltipHeight));

      this.domElement.style.left = `${x}px`;
      this.domElement.style.top = `${y}px`;
    }
  }
}
