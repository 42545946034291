import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { AutocompleteTypes } from '@ui-kit/components/autocomplete/autocomplete.component';
import { GroupTypeEnum } from '@ui-kit/util/icon-util';
import { Accumulatables, Accumulate } from 'projects/@common/modules/accumulator/accumulator.store';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { EcoSessionState } from 'projects/@common/modules/session/state/session.state';
import { IdentitiesApi } from 'projects/@common/services/api/iam/identities/identities.api';
import { IdentityProviderTypes, List, ListUsersResponse } from 'projects/@common/services/api/iam/identities/identities.api.definitions';
import { GroupVisibilityEnum } from 'projects/@common/services/api/sg/groups/groups.definitions';

import { GroupsApiService } from 'projects/@common/services/api/sg/groups/groupsApi.service';
import { Observable } from 'rxjs';

export enum UserStatusEnum {
  GUESTS = "Guest",
  NOT_GUEST = "Member"
}

@Component({
  selector: 'sg-informations-section',
  templateUrl: './informations-section.component.html',
  styleUrls: [ './informations-section.component.scss' ],
})
export class InformationsSectionComponent implements OnInit {
  @Input() public resourceInfo = null;

  @Output() public groupOwnersChange: EventEmitter<any[]> = new EventEmitter();

  public informationForm: UntypedFormGroup;
  public groupOwners: any[] = [];
  public o365UsersFilter = UserStatusEnum.NOT_GUEST;
  public usersAutocomplete = AutocompleteTypes.USERS;

  public currentUser: any; // IUser

  public groupTypeEnum = GroupTypeEnum;
  public groupVisibilityEnum = GroupVisibilityEnum;

  @Select(EcoSessionState.user) public currentUser$: Observable<any>;

  constructor(
    public groupsApiService: GroupsApiService,
    public identitiesApi: IdentitiesApi,
    public fb: UntypedFormBuilder,
    public store: Store,
    public i18nService: I18nService
  ) {
  }

  public get ownersFormGroup(): UntypedFormGroup {
    return this.fb.group({
      name: null,
      o365UserId: null,
    });
  }

  ngOnInit(): void {
    this.initOwners();
    this.initForm();
  }

  public usersAutocompleteSearchFunction = (params: any): Promise<List<ListUsersResponse, number | string, {
    idpType: IdentityProviderTypes;
  }>> => this.groupsApiService.getUsersSublistByOrganization(params);

  public setOwner(): void {
    const users = this.groupOwners.map((currentUser) => ({
      guid: currentUser.o365UserId,
      o365UserId: currentUser.o365UserId,
      name: currentUser.firstName || currentUser.name,
    }));

    while ((this.informationForm.get('owners') as UntypedFormArray).length !== 0) {
      (this.informationForm.get('owners') as UntypedFormArray).removeAt(0);
    }

    users.forEach((value) => {
      (this.informationForm.get('owners') as UntypedFormArray).push(this.fb.control({}));
    });
  }

  public addOwner(user: any): void {
    (this.informationForm.get('owners') as UntypedFormArray).push(this.fb.control({
      guid: user.guid,
      name: user.firstName,
      o365UserId: user.o365UserId,
    }));
    this.store.dispatch(new Accumulate({ accumulatable: Accumulatables.GROUP_CREATION_OWNERS_CHANGE }));
  }

  public removeOwner(owner: any): void {
    if (this.groupOwners.length > 1) {
      const index = (this.informationForm.get('owners') as UntypedFormArray).controls.findIndex((control) => control.value.guid === owner.guid);

      (this.informationForm.get('owners') as UntypedFormArray).removeAt(index);
      this.groupOwners = this.groupOwners.filter((item) => item.guid !== owner.guid);
      this.groupOwnersChange.emit(this.groupOwners);
      this.store.dispatch(new Accumulate({ accumulatable: Accumulatables.GROUP_CREATION_OWNERS_CHANGE }));
    }
  }

  private initForm(): void {
    this.informationForm = this.fb.group({
      name: this.fb.control(this.resourceInfo?.name || null, {
        validators: [ Validators.required, this.checkGroupNameValidation ],
        updateOn: 'blur',
      }),
      description: this.fb.control(this.resourceInfo?.description || null, [ Validators.required ]),
      visibility: this.fb.control(GroupVisibilityEnum.PRIVATE, [ Validators.required ]),
      groupType: this.fb.control(this.resourceInfo?.groupType, [ Validators.required ]),
      owners: this.fb.array([ ...this.groupOwners ]),
    });
  }

  private initOwners(): void {
    if (!this.resourceInfo?.owners.length) {
      this.currentUser$.subscribe((currentUser) => {
        this.currentUser = currentUser;
        this.groupOwners.push({
          guid: this.currentUser.oid,
          o365UserId: this.currentUser.oid,
          name: this.currentUser.name,
        });
      });
    } else {
      this.groupOwners = this.groupOwners.concat(this.resourceInfo.owners);
    }
  }

  private checkGroupNameValidation = (control: AbstractControl): ValidationErrors => {
    if (control.value && control.value.length > 0) {
      // eslint-disable-next-line no-useless-escape
      const validRegexp = new RegExp(`^[A-Za-zÀ-ÖØ-öø-ž0-9\\-_,.\\s]{1,49}[A-Za-zÀ-ÖØ-öø-ž0-9\\-_,\\s](?![. ])$`);
      if (!validRegexp.test(control.value)) {
        return { invalidGroupNameDetail: true };
      }
    }
    return null;
  };
}
