import { Component, Input, OnInit } from '@angular/core';
import { SeverityEnum } from '@ui-kit/components/ui-severity-chip/ui-severity-chip.component';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { FileRiskEnum } from 'projects/@common/services/api/sg/shares/shares.definitions';

@Component({
  selector: 'risk-badge',
  templateUrl: './risk-badge.component.html',
  styleUrls: [ './risk-badge.component.scss' ],
})
export class RiskBadgeComponent implements OnInit {
  @Input() resource;

  public severityValue: SeverityEnum;
  public customText;

  constructor(private i18n: I18nService) {}

  public ngOnInit() {
    this.convertAndMapFileRiskToSeverity();
  }

  private convertAndMapFileRiskToSeverity() {
    let severityValueLabelKey: SeverityEnum;
    switch (this.resource.risk || this.resource.riskLevel) {
      case FileRiskEnum.LOW:
        this.severityValue = SeverityEnum.OK;
        severityValueLabelKey = SeverityEnum.OK;

        break;
      case FileRiskEnum.MODERATE:
        this.severityValue = SeverityEnum.HIGH;
        severityValueLabelKey = SeverityEnum.MODERATE;

        break;
      default:
        this.severityValue = SeverityEnum.DISASTER;
        severityValueLabelKey = SeverityEnum.DISASTER;
        break;
    }

    if (this.resource.hasJustification) {
      this.severityValue = SeverityEnum.NA;
    }

    this.customText = this.i18n.translate(`shares.shareRiskScore.${severityValueLabelKey}`);
  }
}
