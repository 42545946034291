import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'htmlSanitize',
})
export class HtmlSanitizer implements PipeTransform {
  constructor(protected sanitizer: DomSanitizer) {
  }
  async transform(value: string): Promise<string> {
    return this.sanitizer.sanitize(SecurityContext.HTML, value);
  }
}
