import { Component, Input, ContentChildren, QueryList, AfterContentInit, Output, EventEmitter } from '@angular/core';
import { Store } from '@ngxs/store';
import { ToolbarCollapsableElementComponent } from '@ui-kit/components/toolbar-filter/toolbar-collapsable-element/toolbar-collapsable-element.component';
import { SetTopbarState } from 'projects/@common/modules/layout/stores/app.state';
import { MobileService } from 'projects/@common/services/mobile.service';

@Component({
  selector: 'toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: [ './toolbar.component.scss' ],
})
export class ToolbarComponent implements AfterContentInit {
  @ContentChildren(ToolbarCollapsableElementComponent)
    filtersInsideCollapsableArea: QueryList<ToolbarCollapsableElementComponent>;

  @Input() public total: number;

  @Input() public activatedFiltersNumber = 2;

  @Output() public onApply = new EventEmitter<void>();

  @Output() public onCancel = new EventEmitter<void>();

  @Output() public onClearAllFilters = new EventEmitter<void>();

  public filters: ToolbarCollapsableElementComponent[];

  public areFiltersExpanded = false;

  constructor(public readonly mobileService: MobileService, private readonly store: Store) {}

  public ngAfterContentInit() {
    this.filters = this.filtersInsideCollapsableArea.toArray();
  }

  public toggleFilters() {
    this.areFiltersExpanded = !this.areFiltersExpanded;
    if (this.mobileService.isMobile()) {
      this.store.dispatch(new SetTopbarState({ disabled: this.areFiltersExpanded }));
    }
  }

  public applyMobileFilters() {
    this.onApply.emit();
    this.toggleFilters();
  }

  public cancelMobileFilters() {
    this.onCancel.emit();
    this.toggleFilters();
  }

  public clearAllMobileFilters() {
    this.onClearAllFilters.emit();
    this.toggleFilters();
  }
}
