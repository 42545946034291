<ng-container *ngIf="isFromQuickActions">
  <ng-container *ngIf="isLoadingTypesNumbers">
    <ui-spinner
      size="medium"
      modifier="secondary"
    ></ui-spinner>
  </ng-container>

  <ng-container *ngIf="showBatchRevokeDialog">
    <ng-container *ngTemplateOutlet="batchRevoke"></ng-container>
  </ng-container>

  <ng-container *ngIf="showBatchRevokeConfirmationDialog">
    <ng-container *ngTemplateOutlet="individualRevoke"></ng-container>
  </ng-container>

  <ng-container *ngTemplateOutlet="buttons"></ng-container>
</ng-container>

<ng-template #buttons>
  <section class="buttons">
    <button
      ui-button
      color="standard"
      (click)="showBatchRevokeDialog ? onRevokeFilesForm(dialogActions.ACTION) : onRevokeFilesConfirmation(dialogActions.ACTION)"
      [disabled]="!batchRevokeForm?.valid"
    >
      {{ 'common.action.revoke' | t }}
    </button>
    <button
      ui-button
      color="secondary"
      (click)="onRevokeFilesForm()"
    >
      {{ 'common.action.cancel' | t }}
    </button>
  </section>
</ng-template>

<ng-container *ngIf="!isFromQuickActions">
  <ui-dialog
    *ngIf="showBatchRevokeDialog"
    [headtitle]="'shares.dialog.batchRevoke.title' | t"
    [actionButton]="'common.action.revoke' | t"
    [cancelButton]="'common.action.cancel' | t"
    (onButtonClick)="onRevokeFilesForm($event)"
    [isLoading]="isLoadingTypesNumbers"
    [isFullMobileScreen]="true"
    [actionButtonDisabledCondition]="!batchRevokeForm?.valid"
  >
    <ng-container *ngTemplateOutlet="batchRevoke"></ng-container>
  </ui-dialog>

  <ui-dialog
    *ngIf="showBatchRevokeConfirmationDialog"
    [headtitle]="'shares.dialog.batchRevoke.confirmation.title' | t"
    [actionButton]="'shares.dialog.batchRevoke.confirmation.button.ok' | t"
    [cancelButton]="'shares.dialog.batchRevoke.confirmation.button.cancel' | t"
    (onButtonClick)="onRevokeFilesConfirmation($event)"
    [isLoading]="isRevoking || isLoadingTypesNumbers"
    [actionButtonDisabledCondition]="!willRevokeSomeShares"
  >
    <ng-container *ngTemplateOutlet="individualRevoke"></ng-container>
  </ui-dialog>
</ng-container>

<ng-template #batchRevoke>
  <ng-container *ngIf="!isLoadingTypesNumbers">
    <div class="revoke-form--subtitle">
      {{ 'shares.dialog.batchRevoke.subtitle' | t }}
    </div>
    <div class="revoke-form--explanation">
      {{ 'shares.dialog.batchRevoke.explanations' | t }}
    </div>

    <div class="revoke-form--separator"></div>

    <ng-container *ngTemplateOutlet="
        fileTypeToRevoke;
        context: {
          controlName: 'riskHigh',
          calendar: false
        }
      "></ng-container>

    <ng-container *ngTemplateOutlet="
        fileTypeToRevoke;
        context: {
          controlName: 'riskModerate',
          calendar: false
        }
      "></ng-container>

    <ng-container *ngTemplateOutlet="
        fileTypeToRevoke;
        context: {
          controlName: 'publicShares',
          icon: 'icon_link_public',
          calendar: false
        }
      "></ng-container>

    <ng-container *ngTemplateOutlet="
        fileTypeToRevoke;
        context: {
          controlName: 'organizationalShares',
          icon: 'icon_link_organization',
          calendar: false
        }
      "></ng-container>

    <ng-container *ngTemplateOutlet="
        fileTypeToRevoke;
        context: {
          controlName: 'groupShares',
          icon: 'icon_link_group',
          calendar: false
        }
      "></ng-container>

    <ng-container *ngTemplateOutlet="
        fileTypeToRevoke;
        context: {
          controlName: 'guestShares',
          icon: 'icon_link_guest',
          calendar: false
        }
      "></ng-container>

    <ng-container *ngTemplateOutlet="
        fileTypeToRevoke;
        context: {
          controlName: 'memberShares',
          icon: 'icon_link_member',
          calendar: false
        }
      "></ng-container>

    <ng-container *ngTemplateOutlet="
        fileTypeToRevoke;
        context: {
          controlName: 'sharedSince',
          icon: 'icon_avatar_calendar',
          calendar: true
        }
      "></ng-container>

    <ng-container *ngTemplateOutlet="
        fileTypeToRevoke;
        context: {
          controlName: 'inactiveSince',
          icon: 'icon_avatar_calendar',
          calendar: true
        }
      "></ng-container>
  </ng-container>
</ng-template>

<ng-template #individualRevoke>
  <div
    *ngIf="!isLoadingTypesNumbers"
    class="confirmation-message"
  >
    <ul class="confirmation-message--list">
      <ng-container *ngFor="let controlName of formControls">
        <li *ngIf="batchRevokeForm?.get(controlName).value">
          {{ 'shares.dialog.batchRevoke.' + controlName + '.title' | t }}
          <ng-container *ngIf="
              controlName === 'sharedSince' || controlName === 'inactiveSince'
            ">
            {{
            convertDateToTimestamp(
            batchRevokeForm?.get(controlName + 'Date').value
            ) | dt: {locale: i18n.currentLocale}
            }}
          </ng-container>

          ({{ sharesNumbersPerType[controlName] }})
        </li>
      </ng-container>
    </ul>
    <div class="confirmation-message--checkbox" *ngIf="silencingOptions?.length">
      <ui-checkbox
        [fontSizeType]="fontSize"
        [checked]="includeSilenced"
        [label]="'shares.dialog.backRevoke.checkbox.message' | t"
        [isEditable]="true"
        (onCheck)="handleIncludeSilencedShares($event)"
      ></ui-checkbox>
    </div>
    <div>{{ (
      willRevokeSomeShares ?
      'shares.dialog.batchRevoke.confirmation.message' :
      'shares.dialog.batchRevoke.noShares.message')
      | t }}</div>
  </div>
</ng-template>

<ng-template
  #fileTypeToRevoke
  let-controlName="controlName"
  let-icon="icon"
  let-calendar="calendar"
>
  <div class="revoke-form--row">
    <div class="revoke-form--row--checkbox">
      <ui-checkbox
        *ngIf="
          sharesNumbersPerType[controlName] || calendar;
          else inactiveCustomCheckbox
        "
        [controlName]="controlName"
        [group]="batchRevokeForm"
        [isEditable]="true"
      ></ui-checkbox>

      <ng-template #inactiveCustomCheckbox>
        <div class="custom-inactive-checkbox"></div>
      </ng-template>
    </div>

    <div
      class="revoke-form--row--title"
      [ngClass]="{
        'revoke-form--row--title--with-calendar':
          calendar && !mobileService.isMobile(),
        'revoke-form--row--title--with-number':
          !calendar && !mobileService.isMobile(),
        'revoke-form--row--title--mobile': mobileService.isMobile()
      }"
    >
      <div>
        {{ 'shares.dialog.batchRevoke.' + controlName + '.title' | t }}
      </div>
      <div class="revoke-form--row--title--explanation">
        {{
        'shares.dialog.batchRevoke.' + controlName + '.explanation'
        | t
        : (controlName === 'organizationalShares' ||
        controlName === 'guestShares' ||
        controlName === 'memberShares'
        ? { orgName: organizationName }
        : {})
        }}
      </div>

      <ng-container *ngIf="calendar && mobileService.isMobile()">
        <ng-container *ngTemplateOutlet="
            calendarTemplate;
            context: {
              controlName: controlName
            }
          "></ng-container>
      </ng-container>
    </div>

    <ng-container *ngIf="calendar && !mobileService.isMobile()">
      <ng-container *ngTemplateOutlet="
          calendarTemplate;
          context: {
            controlName: controlName
          }
        "></ng-container>
    </ng-container>

    <div
      *ngIf="!calendar"
      class="revoke-form--row--number"
    >
      ({{ sharesNumbersPerType[controlName] }})
    </div>

    <div
      class="revoke-form--row--icon"
      *ngIf="!mobileService.isMobile()"
    >
      <img
        *ngIf="icon"
        [src]="'assets/favicons/' + icon + '.svg'"
      />

      <ui-severity-chip
        *ngIf="!icon"
        [severity]="controlName === 'riskHigh' ? 'disaster' : 'high'"
        [customText]="('shares.dialog.batchRevoke.severity.' + controlName) | t"
      ></ui-severity-chip>
    </div>
  </div>
  <div class="revoke-form--separator"></div>
</ng-template>

<ng-template
  #calendarTemplate
  let-controlName="controlName"
>
  <div class="revoke-form--row--calendar">
    <ui-date-picker
      [group]="batchRevokeForm"
      [control]="controlName + 'Date'"
      [maxDate]="yesterdayDate"
      [disabled]="!batchRevokeForm?.get(controlName).value"
    ></ui-date-picker>
  </div>
</ng-template>
