import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiEndpointPrefixEnum, EcoApi } from 'projects/@common/definitions/eco-api';
import { FeatureFlagsEnum } from 'projects/@common/modules/layout/definitions/base-sidebar.service';
import { EcoUrlService } from '../../core/url.service';
import { IUserDevice } from './devices-api.definitions';

@Injectable({
  providedIn: 'root',
})
export class DevicesApiService extends EcoApi {
  protected prefix = ApiEndpointPrefixEnum.sg;

  constructor(readonly http: HttpClient, url: EcoUrlService) {
    super(http, url);
  }

  public getUserDevices(): Promise<IUserDevice[]> {
    return this.http.get<any>(`${this.host}/me/devices/owned`, this.getFlagParams({ flags: [ FeatureFlagsEnum.DEVICE_MANAGEMENT ] })).toPromise();
  }
}
