import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { TimeUtil } from '@ui-kit/services/time-util';
import { Eco } from 'projects/@common/definitions/eco';
import { AbstractDrawer } from 'projects/@common/modules/layout/components/drawer/AbstractDrawer';
import { CookInedibleDrawerSnack } from 'projects/@common/modules/notice/implementations/snackbar/state/snackbar-drawer.state';
import { NoticeService } from 'projects/@common/modules/notice/notice.service';
import { EcoSessionState } from 'projects/@common/modules/session/state/session.state';
import { EcoUrlService } from 'projects/@common/services/api/core/url.service';
import { BackgroundTasksApiService } from 'projects/@common/services/api/notifications/backgroundTasks-api.service';
import { NotificationsApiService } from 'projects/@common/services/api/notifications/notifications-api.service';
import { MobileService } from 'projects/@common/services/mobile.service';
import { Subscription } from 'rxjs';
import { OpsManagerNotificationsSectionEnum } from '../../../enums/notificationSection.enum';
import { OrganizationSupportTypeEnum } from '../../../enums/organizationSupportType.enum';


@Component({
  selector: 'ops-manager-notices-list',
  templateUrl: './ops-manager-notices-list.component.html',
  styleUrls: [ './ops-manager-notices-list.component.scss' ],
})
export class OpsManagerNoticesListComponent extends AbstractDrawer implements OnInit, OnDestroy {
  @Input() public data: any;
  public isLoading = false;
  public notifications: any[] = [];
  public todayNotifications: any[] = [];
  public yesterdayNotifications: any[] = [];
  public opsManagerNotificationsSectionEnum = OpsManagerNotificationsSectionEnum;
  public oldestNotifications: any[] = [];
  public total = 0;
  private currentOrganizationSubscription: Subscription;
  private currentOrganization: Eco.IOrganization;
  private mondataSupportCenter = EcoUrlService.GetMondataSupportCenterUrl();
  private nextToken = 0;

  private lastScrollTop = 0;

  private supportType: OrganizationSupportTypeEnum = OrganizationSupportTypeEnum.MONDATA;

  private supportLink: string;

  private isAllLoaded = false;

  constructor(
    public mobileService: MobileService,
    private readonly router: Router,
    private readonly notificationsService: NotificationsApiService,
    private notice: NoticeService,
    private readonly backgroundTasksService: BackgroundTasksApiService,
    private readonly store: Store
  ) {
    super(store);
    this.currentOrganizationSubscription = this.store.select(EcoSessionState.organization).subscribe((organization) => {
      this.currentOrganization = organization;
    });
  }

  ngOnInit(): void {
    this.loadNotification(true);
  }

  ngOnDestroy(): void {
    this.currentOrganizationSubscription?.unsubscribe();
  }

  public addNotificationStatus(): void {
    const jobGuids = this.notifications.filter((notification) => !notification?.status).map((notification) => notification.jobGuid);
    this.backgroundTasksService.getJobStatus(jobGuids).then((items) => {
      for (const item of items) {
        const notification = this.notifications.find((notification) => notification.jobGuid === item.jobGuid);
        notification.status = item.status;
      }
      this.notifications = this.notifications.filter((notification) => notification.status);
    }).finally(() => this.isLoading = false);
  }

  public async handleRefresh(): Promise<void> {
    this.notifications = [];
    this.nextToken = 0;
    this.isAllLoaded = false;
    this.loadNotification(true);
  }

  @HostListener('scroll', [ '$event.target' ])
  onScroll(event: any) {
    const tableViewHeight = event.target.offsetHeight;
    const tableScrollHeight = event.target.scrollHeight;
    const scrollLocation = event.target.scrollTop;

    const limit = tableScrollHeight - tableViewHeight - 1;

    if (scrollLocation > limit && scrollLocation > this.lastScrollTop) {
      this.loadNotification();
    }
    this.lastScrollTop = scrollLocation;
  }

  private filterOpsManagerNotifications(): void {
    this.notifications = this.notifications.filter((notification) => {
      const jobGuid = notification.sentWithConfig?.payload?.relatedObject?.find((element) => element.objectType === 'jobGuid')?.objectGuid;
      if (jobGuid) {
        notification.jobGuid = jobGuid;
        return notification;
      }
    });
  }

  private loadNotification(isRefresh = false): void {
    if (!this.isLoading && !this.isAllLoaded) {
      if (isRefresh) {
        this.isLoading = true;
      }
      this.notificationsService.listNotifications({
        from: this.nextToken,
        size: 50,
        order: 'DESC',
      })
        .then((response) => {
          this.notifications.push(...response.items);
          this.total = response.total;
          this.nextToken = this.nextToken + response?.items?.length;
          if (this.nextToken >= this.total) {
            this.isAllLoaded = true;
          }
          this.filterOpsManagerNotifications();
          this.classifyNotifications();
          this.addNotificationStatus();
        })
        .catch((error) => {
          this.store.dispatch(new CookInedibleDrawerSnack('operations.error.message'));
        });
    }
  }

  private classifyNotifications(): void {
    this.todayNotifications = this.notifications.filter((notification) => notification.sent >= TimeUtil.today);
    this.yesterdayNotifications = this.notifications.filter((notification) => notification.sent >= TimeUtil.yesterday && notification.sent < TimeUtil.today);
    this.oldestNotifications = this.notifications.filter((notification) => notification.sent < TimeUtil.yesterday);
  }
}
