import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UiKitModule } from '@ui-kit/uikit.module';
import { AssistOpsModule } from 'projects/@assist-ops/assist-ops.module';
import { ComponentsModule } from 'projects/@common/components/components.module';
import { DirectivesModule } from 'projects/@common/modules/directives/directives.module';
import { I18nModule } from 'projects/@common/modules/i18n/i18n.module';
import { SgDialogsModule } from '../../shared/components/dialogs/dialogs.module';
import { SgSharedModule } from '../../shared/shared.module';
import { ResourceQuickActionComponent } from './resource-quick-actions/resource-quick-actions.component';
import { TeamsResourcesDescriptionComponent } from './teams-resources-description/teams-resources-description.component';
import { TeamsResourcesComponent } from './teams-resources.component';
import { TeamsResourcesRoutingModule } from './teams-resources.routing.modules.ts';

const components = [
  TeamsResourcesComponent,
  TeamsResourcesDescriptionComponent,
  ResourceQuickActionComponent,
];

@NgModule({
  declarations: [ ...components ],
  exports: [ ...components ],
  imports: [
    I18nModule,
    UiKitModule,
    TeamsResourcesRoutingModule,
    SgSharedModule,
    CommonModule,
    DirectivesModule,
    AssistOpsModule,
    SgDialogsModule,
    ComponentsModule,
  ],
})
export class TeamsResourcesModule {
}
