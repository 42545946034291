<ng-container *ngIf="isLoading">
  <ui-spinner size="medium" modifier="secondary"></ui-spinner>
</ng-container>
<ng-container *ngIf="!isLoading">
  <form class="form" ui-form (ngSubmit)="processApprobation(true)">
    <div>
      <div class="request-form-process--label">
        {{ 'request.takeAction.label' | t }}
      </div>
      <ui-field-group>
        <ui-switch-text-static
          [isEditing]="true"
          label=""
          name="note"
          control="note"
          [group]="requestApprobationForm"
          maxlength="500"
          [rowsNumber]="2"
          [placeholder]="'request.takeAction.placeholder' | t"
        ></ui-switch-text-static>
      </ui-field-group>
    </div>
  </form>
  <button
    icon="icon_conforme"
    [custom-icon]="true"
    [isSvg]="true"
    icon-size="small"
    color="linkBorded"
    ui-button
    (click)="processApprobation(true)"
  >
    {{ 'common.action.accept' | t }}
  </button>
  <button
    *ngIf="showDeclineButton"
    icon="icon_stop"
    [custom-icon]="true"
    [isSvg]="true"
    icon-size="small"
    color="linkBorded"
    ui-button
    (click)="processApprobation(false)"
  >
    {{ 'common.action.decline' | t }}
  </button>
</ng-container>
