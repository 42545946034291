import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { LanguageEnum } from '@ui-kit/interfaces/ILanguage';
import { DTPipe } from '@ui-kit/pipes/dt.pipe';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { DrawerPageEnum } from 'projects/@common/modules/layout/components/drawer/drawerPage.enum';
import { DrawerService } from 'projects/@common/modules/layout/components/drawer/services/drawer.service';
import { DateAgoPipe } from 'projects/@common/modules/pipes/dateAgo.pipe';
import { NotificationsApiService } from 'projects/@common/services/api/notifications/notifications-api.service';
import { MobileService } from 'projects/@common/services/mobile.service';
import { OrganizationSupportTypeEnum } from '../../../enums/organizationSupportType.enum';
import { OpsManagerNotificationDetailDrawer } from '../ops-manager-notification-detail-drawer/ops-manager-notification-detail-drawer.component';
import { OpsManagerNotificationsStatusEnum, OpsManagerService } from '../ops-manager.service';

@Component({
  selector: 'ops-manager-notification',
  templateUrl: './ops-manager-notification.component.html',
  styleUrls: [ './ops-manager-notification.component.scss' ],
  providers: [ DateAgoPipe ],
})
export class OpsManagerNotificationComponent implements OnInit {
  @Input() public notification: any;

  @Input() public useDateFormat = false;

  @Input() public supportType: OrganizationSupportTypeEnum;

  @Input() public supportLink: string;

  public notificationStatus: OpsManagerNotificationsStatusEnum = OpsManagerNotificationsStatusEnum.PENDING;

  public notificationIcon = '';

  public constructor (
    private readonly store: Store,
    private readonly dtPipe: DTPipe,
    private readonly i18n: I18nService,
    private readonly dateAgoPipe: DateAgoPipe,
    public readonly mobileService: MobileService,
    private readonly drawerService: DrawerService,
    private opsManagerService: OpsManagerService,
    private notificationsService: NotificationsApiService
  ) { }

  public ngOnInit(): void {
    this.notificationStatus = this.opsManagerService.getNotificationStatus(this.notificationPendingOperationsCount, this.notification);
    this.notificationIcon = this.opsManagerService.getNotificationIconByStatus(this.notificationStatus);
  }

  public get notificationMessage(): string {
    return this.store.selectSnapshot<string>((state) => state.i18n.locale) === LanguageEnum.FRENCH
      ? this.notification.title.fr
      : this.notification.title.en;
  }

  public get sent(): string {
    if (this.useDateFormat) {
      return this.dtPipe.transform(this.notification.sent, { withTime: false, locale: this.i18n.currentLocale, withSeconds: false });
    }

    const hours = Math.floor((Date.now() - this.notification.sent) / (1000 * 60 * 60));
    if (hours < 24) {
      return this.dateAgoPipe.transform(this.notification.sent, false, this.i18n.currentLocale);
    }
    return this.i18n.translate('interval.ago', {
      counter: hours,
      interval: this.i18n.translate('since.hours'),
    });
  }


  public get notificationPendingOperationsCount(): number {
    return (
      this.notification?.status?.operationsCount - this.notification?.status?.error - (this.notification?.status?.critical || 0) - this.notification?.status?.success
    );
  }

  public onNotificationClick(): void {
    if (!this.notification.seen) {
      this.notificationsService.markNotifAsRead(this.notification);
    }
    this.drawerService.showDrawer(OpsManagerNotificationDetailDrawer, {
      component: DrawerPageEnum.opsManagerDrawer,
      data: {
        title: this.notificationMessage,
        notification: this.notification,
        supportType: this.supportType,
        supportLink: this.supportLink,
        createdAt: this.sent,
      },
    });
  }
}
