import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { IBreadcrumbs } from 'projects/@assist-ops/components/breadcrumbs/breadcrumbs.component';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { IUserDevice, MobileOsEnum } from 'projects/@common/services/api/sg/devices/devices-api.definitions';
import { DevicesApiService } from 'projects/@common/services/api/sg/devices/devices-api.service';
import { DOCUMENTATION_SECTION, generateDocumentationLink } from 'projects/@common/utils/eco.documentation-utils';
import { PageDescriptionService } from 'projects/console-sg/src/services/page-description.service';
import { BannerTypeEnum } from '../../shared/components/banners/banners.component';
import { PageDescriptions } from '../../shared/components/page-layout/page-descriptions.enum';
import { CriteriaFactory, CriteriaTypeEnum, DeviceEnum, ResourceCard, ResourceTypesEnum } from '../teams-resources/services/resourceCard';
import { SgExpendableCardComponent } from 'projects/@common/components/sg-expendable-card/sg-expendable-card.component';

@Component({
  selector: 'sg-device-conformity',
  templateUrl: './device-conformity.component.html',
  styleUrls: [ './device-conformity.component.scss' ],
})
export class DeviceConformityComponent implements OnInit {
  public documentationSection = generateDocumentationLink(DOCUMENTATION_SECTION.sg.deviceHealthCheck, this.i18nService.currentLocale);
  @ViewChildren(SgExpendableCardComponent) cardsRef!: QueryList<SgExpendableCardComponent>;

  public breadcrumbs: IBreadcrumbs[] = [
    { nodeName: 'breadcrumb.dashboard', nodeLink: '/dashboard' },
    { nodeName: 'devices.gestion.device.breadcrumbs', nodeLink: null },
  ];

  public devices: ResourceCard[] = [];

  public mobileEnum = MobileOsEnum;
  public isLoading = true;

  constructor(
    private pageDescriptionService: PageDescriptionService,
    private i18nService: I18nService,
    private devicesApiService: DevicesApiService
  ) { }


  ngOnInit(): void {
    this.devicesApiService.getUserDevices()
      .then((value) => this.devices = value.map((device) => this.mapDevicesToCards(device)))
      .finally(() => {
        this.isLoading = false;
      });
  }

  public mapDevicesToCards(device: IUserDevice): ResourceCard {
    const deviceCard = new ResourceCard(this.getDeviceType(device.operatingSystem), device.displayName);
    const criteriaFactory = new CriteriaFactory(this.i18nService);

    if (!device.isCompliant) {
      deviceCard.setErrorDescription(this.i18nService.translate('devices.gestion.device_error_description'));
      deviceCard.setCardToInvalid();
    }

    const status = this.i18nService.translate(`user.detail.${device.accountEnabled ? 'isEnabled' : 'isDisabled'}`);
    deviceCard.addCriteria(criteriaFactory.createCriteria(CriteriaTypeEnum.device_state, true, status));

    deviceCard.addCriteria(criteriaFactory.createCriteria(CriteriaTypeEnum.os, true, device.operatingSystem));

    const deviceIdCriteria = criteriaFactory.createCriteria(CriteriaTypeEnum.device_id, true, device.id);
    deviceIdCriteria.addCopyButton(this.i18nService.translate('devices.gestion.device_id.copy_text'));
    deviceCard.addCriteria(deviceIdCriteria);

    deviceCard.removeCriteriaIcons();

    return deviceCard;
  }

  public openDescription(): void {
    this.pageDescriptionService.openTooltip(PageDescriptions.DEVICES);
  }

  public getDeviceType(os: any): ResourceTypesEnum {
    return Object.values(MobileOsEnum).includes(os) ? DeviceEnum.mobile : DeviceEnum.desktop;
  }

  public get deviceCompliant(): boolean {
    return this.devices.every((device) => device.isResourceValid());
  }

  public getBannerType(): BannerTypeEnum {
    return this.deviceCompliant ? BannerTypeEnum.ok : BannerTypeEnum.warning;
  }

  public getBannerMessage(): string {
    if (this.devices.length > 0) {
      return this.deviceCompliant ? this.i18nService.translate('devices.gestion.device.banner.complient.message') : this.i18nService.translate('devices.gestion.device.banner.error.message');
    }
    return this.i18nService.translate('devices.gestion.device.banner.empty.message');
  }
}
