<base-dialog
  *ngIf="!selectedGroup"
  [title]="data.dialogTitle | t"
  [description]="data.dialogSubtitle | t"
  [hasStepper]="selectedGroup"
  (onCancel)="onCancel()"
>
  <div
    class="container"
    *ngIf="!selectedGroup"
    scrollToBottom
    (onScrollToBottom)="loadMore()"
  >
    <ng-container>
      <span class="contextual-help">
      <p *ngIf="!hideToggle"> {{data.dialogDescription | t}}</p>
        <img
          *ngIf="hideContextualHelp"
          (click)="openContextualHelp()"
          class="image"
          src="assets/favicons/icon_contextual_help_small.svg"
        >
      </span>
      <div class="topbar-dialog">
        <div class="toggle-container">
          <p *ngIf="hideToggle">
            {{data.dialogDescription | t}}
          </p>
          <ui-toggle
            [toggled]="showModelWithoutGroup"
            (onClick)="handleToggle($event)"
            [labelEnabled]="toggleLabel | t"
            [labelDisabled]="toggleLabel | t"
          ></ui-toggle>
        </div>
      </div>
        <div class="search-container">
          <ui-table-total-tool
            [total]="groupList?.length || 0"
            [locale]="i18n.currentLocale"
          >
          </ui-table-total-tool>
          <ui-table-search-tool
            [query]="fulltext"
            (clear)="handleSearchClear()"
            (query)="handleSearchQuery($event)"
            [placeholder]="'quickAction.search.resource' | t"
            class="search-bar"
          ></ui-table-search-tool>
        </div>
      <div class="title">
        <h4>{{'quickAction.model.subsubtitle' | t}}</h4>
        <hr>
      </div>
      <ng-container *ngTemplateOutlet="groupList.length > 0 || isLoading ? ownerGroupList : emptyList"></ng-container>
    </ng-container>
  </div>

  <ng-template #ownerGroupList>
    <ng-container *ngFor="let ownedGroup of groupList">
      <ui-title-description-button
        *ngIf="!isLoading"
        [name]="ownedGroup.displayName"
        [description]="getGroupDisplayInfo(ownedGroup).description"
        [textAlertColor]="getGroupDisplayInfo(ownedGroup).descriptionColor"
        [withBorder]="true"
        [image]="ownedGroup.type === groupTypeEnum.O365OFFICETEAMS ? 'icon_resource_avatar_teams.svg' : 'icon_resource_avatar_sharepoint.svg'"
        [rightImage]="getGroupDisplayInfo(ownedGroup).icon"
        (click)="onGroupClick(ownedGroup)"
      ></ui-title-description-button>
    </ng-container>
    <ng-container *ngIf="isLoading || isLoadingMore">
      <ui-spinner
        size="medium"
        modifier="secondary"
      ></ui-spinner>
    </ng-container>
  </ng-template>

  <ng-template #emptyList>
    <p class="empty">{{ 'quick.actions.empty.list' | t }} </p>
  </ng-template>
</base-dialog>

<ng-container *ngIf="selectedGroup">
  <ng-container [ngSwitch]="data.type">
    <sg-apply-model-dialog
      [dataFromQuickAction]="actionData"
      *ngSwitchCase="actionType.securityModel" >
    </sg-apply-model-dialog>
    <certify-access-dialog
      [dataFromQuickAction]="actionData"
      *ngSwitchCase="actionType.certifyAccesses"
    ></certify-access-dialog>
    <edit-group-expiration-date-dialog
      [dataFromQuickAction]="actionData"
      *ngSwitchCase="actionType.updateExpirationDate"
    ></edit-group-expiration-date-dialog>
    <archive-group-dialog
      [dataFromQuickAction]="actionData"
      *ngSwitchCase="actionType.archive"
    ></archive-group-dialog>
  </ng-container>
</ng-container>
