import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { GroupTypeEnum } from '@ui-kit/util/icon-util';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { IGroupTemplate } from 'projects/@common/services/api/sg/group-templates/group-templates.definitions';
import { IGroupMember } from 'projects/@common/services/api/sg/groups/groups.definitions';

@Component({
  selector: 'sg-apply-confirmation-section',
  templateUrl: './apply-confirmation-section.component.html',
  styleUrls: [ './apply-confirmation-section.component.scss' ],
})
export class ApplyConfirmationSectionComponent implements OnInit {
  @Input() public template: IGroupTemplate;
  @Input() public resourceName: string =  'caaaarl';
  @Input() public keepStructure: boolean;
  @Input() public certifiedUsers: IGroupMember[] = [];
  @Input() public neededCertification: boolean;

  public requestForm: UntypedFormGroup;

  constructor(private i18n: I18nService, private fb: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.initFormGroup();
  }

  public get confirmNumber(): number {
    return this.certifiedUsers?.filter((user) => user.isCertified).length;
  }

  public get revokeNumber(): number {
    return this.certifiedUsers?.filter((user) => !user.isCertified).length;
  }

  public get approbationTooltip(): string {
    switch (this.template?.type) {
      case GroupTypeEnum.O365OFFICEGROUP:
        return this.i18n.translate('requests.drawer.creation.manager.tooltip', { type: this.i18n.translate('groupO365.filter').toLowerCase() });
      case GroupTypeEnum.O365OFFICETEAMS:
        return this.i18n.translate('requests.drawer.creation.manager.tooltip', { type: this.i18n.translate('SecurityGroup.filter').toLowerCase() });
      default:
        return this.i18n.translate('requests.drawer.creation.manager.tooltip', { type: this.i18n.translate('SecurityGroup.filter').toLowerCase() });
    }
  }

  public getTranslatedGroupType(template: IGroupTemplate): string {
    return this.i18n.translate(`groups.dialog.${template?.type}.short`);
  }

  private initFormGroup(): void {
    this.requestForm = this.fb.group({
      description: this.fb.control(this.resourceName || null, [ Validators.required ]),
      justification: this.fb.control(null, [ Validators.required ]),
      managers: this.fb.control(
        this.template.defaultApprovers.map((defaultApprover) => ({
          name: defaultApprover.firstName,
          o365UserId: defaultApprover.o365UserId,
        })),
        []
      ),
    });
  }
}
