import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Select } from '@ngxs/store';
import { BaseDialogComponent } from 'projects/@assist-ops/components/base-dialog/base-dialog.component';
import { StepperNavigation } from 'projects/@assist-ops/components/sg-stepper/sg-stepper.component';
import { NoticeLevels, NoticeService } from 'projects/@common/modules/notice/notice.service';
import { EcoSessionState } from 'projects/@common/modules/session/state/session.state';
import { IDefaultApprover, IGroupTemplate } from 'projects/@common/services/api/sg/group-templates/group-templates.definitions';
import { GroupsApiService } from 'projects/@common/services/api/sg/groups/groupsApi.service';
import { Observable } from 'rxjs';
import { InformationsSectionComponent } from '../sections/informations-section/informations-section.component';
import { ModelSectionComponent } from '../sections/model-section/model-section.component';
import { RequestSectionComponent } from '../sections/request-section/request-section.component';

enum SectionIndexes {
  INFORMATION = 1,
  MODEL = 2,
  REQUEST = 3
}

@Component({
  selector: 'sg-create-resource-dialog',
  templateUrl: './create-resource-dialog.component.html',
  styleUrls: [ './create-resource-dialog.component.scss' ],
})

export class CreateResourceDialogComponent implements OnInit {
  @Select(EcoSessionState.user) public currentUser$: Observable<string>;

  @ViewChild('information') informationsSection: InformationsSectionComponent;
  @ViewChild('model') public modelSection: ModelSectionComponent;
  @ViewChild('request') public requestSection: RequestSectionComponent;
  public currentStepIndex = 0;
  public isSaving = false;
  public showConfirmationDialog = false;
  public currentUser: any; // IUser

  public template: IGroupTemplate = null;

  public requestForm: UntypedFormGroup;

  constructor(
    public dialogRef: MatDialogRef<BaseDialogComponent>,
    public fb: UntypedFormBuilder,
    private groupsApiService: GroupsApiService,
    private noticeService: NoticeService
  ) {
  }

  public get nextApprovers(): IDefaultApprover[] {
    const ownersAsDefaultApprovers: IDefaultApprover[] = this.requestForm.getRawValue().requestInformation.owners.map((owner) => ({ firstName: owner.name, o365UserId: owner.o365UserId }));
    const managers = this.requestForm?.value?.request?.managers?.map((owner) => ({ firstName: owner.name, o365UserId: owner.o365UserId }));
    const isRequesterOwner = ownersAsDefaultApprovers.some((owner) => owner.o365UserId === this.currentUser.oid);

    return isRequesterOwner ? managers : ownersAsDefaultApprovers;
  }

  ngOnInit(): void {
    this.currentUser$.subscribe((currentUser) => this.currentUser = currentUser);
    this.requestForm = this.fb.group({
      request: {
        description: null,
        justification: null,
        managers: this.fb.array([]),
      },
      requestInformation: {
        name: null,
        description: null,
        email: null,
        groupType: null,
        owners: [],
        templateId: null,
        visibility: null,
      },
    });
  }

  public save(): void {
    this.isSaving = true;
    this.updateForm(
      this.requestSection.requestForm,
      {
        index: SectionIndexes.REQUEST,
        direction: StepperNavigation.forward,
      }
    );

    this.groupsApiService.createGroup(this.requestForm.getRawValue())
      .then((response) => {
        this.noticeService.notifyUser({
          message: 'groups.dialog.request.confirmation.message',
          level: NoticeLevels.SUCCESS,
        });
        this.showConfirmationDialog = true;
      })
      .catch((error) => {
        this.noticeService.notifyUser({
          message: 'groups.dialog.request.confirmation.message.error',
          level: NoticeLevels.ERROR,
        });
      });
  }

  public updateForm(form: UntypedFormGroup, event: any): void {
    switch (event?.index) {
      case SectionIndexes.INFORMATION:
        this.requestForm.patchValue({ request: this.requestForm.value.request, requestInformation: { ...form.value } });
        break;
      case SectionIndexes.MODEL:
        const oldTemplate = this.template;
        let managers = this.requestForm.value.request?.managers;
        this.template = form.value?.securityModel;
        if (oldTemplate?.id !== this.template?.id) {
          managers = [];
        }
        this.requestForm.patchValue({
          request: { ...this.requestForm.value.request, managers },
          requestInformation: { ...this.requestForm.value.requestInformation, templateId: form.value?.securityModel?.id },
        });
        break;
      case SectionIndexes.REQUEST:
        this.requestForm.patchValue({ request: form.value, requestInformation: this.requestForm.value.requestInformation });
        break;
    }
  }
}
