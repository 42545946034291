<div [ngClass]="{'learn-more': true, 'learn-more--expanded': descriptionExpanded}" (click)="toggleDescription()">
  <div *ngIf="icon" class="text-image">
    <img [class.small]="isSmall" [src]="icon">
  </div>
  <p>{{title}}</p>
  <img class="chevron" [class.open]="descriptionExpanded"[src]="'assets/favicons/icon_chevron_close_dark.svg'">
</div>
<div *ngIf="descriptionExpanded">
  <ng-content></ng-content>
</div>
