<div
  class="mobile-dashboard-card"
  [ngClass]="{ 'with-top-margin': withTopMargin }"
>
  <div class="mobile-dashboard-card--top-section" (click)="emitCollapseState()">
    <div class="mobile-dashboard-card--top-section--title-section">
      <div class="mobile-dashboard-card--top-section--title-section--title">
        {{ title | t }}
      </div>
      <div class="mobile-dashboard-card--top-section--title-section--subtitle">
        {{ subtitle | t }}
      </div>
      <ng-content select="[cardIndicators]"></ng-content>
    </div>
    <ui-collapsable-toggle-image
      [isCollapsed]="isCollapsed"
    ></ui-collapsable-toggle-image>
  </div>
  <div
    class="mobile-dashboard-card--content"
    [@expandCollapse]="isCollapsed ? 'collapse' : 'expand'"
  >
    <ng-content></ng-content>
  </div>
</div>
