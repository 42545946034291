import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { GroupsTemporaryDataService } from '@common/services/api/sg/temporary-data/groups-temporary-data.service';
import { Select } from '@ngxs/store';
import { DTPipe } from '@ui-kit/pipes/dt.pipe';
import { GroupTypeEnum } from '@ui-kit/util/icon-util';
import { BaseDialogComponent } from 'projects/@assist-ops/components/base-dialog/base-dialog.component';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { Notice, NoticeLevels, NoticeService } from 'projects/@common/modules/notice/notice.service';
import { EcoSessionState } from 'projects/@common/modules/session/state/session.state';
import { IGroupMember } from 'projects/@common/services/api/sg/groups/groups.definitions';
import { GroupsApiService } from 'projects/@common/services/api/sg/groups/groupsApi.service';
import { DashboardProfileService } from 'projects/@common/services/dashboard-profile.service';
import { clone, normalizeString } from 'projects/@common/utils/utils';
import {
  GetResourceGroupRequest,
  IOwnerGroup,
  IResourceResponse,
} from 'projects/console-sg/src/app/pages/teams-resources/services/resources-list.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'certify-access-dialog',
  templateUrl: './certify-access-dialog.component.html',
  styleUrls: ['./certify-access-dialog.component.scss'],
})
export class CertifyAccessDialogComponent implements OnInit {
  @Select(EcoSessionState.user) public currentUser$: Observable<string>;
  public currentUser: any; // IUser
  public search = '';

  public request: GetResourceGroupRequest;

  public isLoading = true;

  public profileUsers: IGroupMember[];

  public owners: IGroupMember[];

  public cloneUsers: IGroupMember[];

  public showConfirmDialog = false;

  public resourceResponse: IResourceResponse;

  public groupTypeEnum = GroupTypeEnum;

  public isFromQuickActions: boolean;

  public isInSecondStep = false;

  public isToggled = false;

  public total = 0;

  public isSaving = false;

  private firstLoad = true;

  private isLoadingMore = false;

  @Input() public dataFromQuickAction: {
    group: IOwnerGroup;
    fromQuickAction: boolean;
    refresh: any;
  };

  constructor(
    public dialogRef: MatDialogRef<BaseDialogComponent>,
    public groupsApiService: GroupsApiService,
    private noticeService: NoticeService,
    private dtPipe: DTPipe,
    private i18n: I18nService,
    public dialog: MatDialog,
    private temporaryDataService: GroupsTemporaryDataService,
    private dashboardProfileService: DashboardProfileService,
    @Inject(MAT_DIALOG_DATA) public data: { name: string; group?: IOwnerGroup; refresh?: any }
  ) {
    this.request = new GetResourceGroupRequest();
  }

  public get numberOfCertified(): number {
    return this.cloneUsers.filter((user) => user.isCertified).length;
  }

  public get numberOfRevoke(): number {
    return this.cloneUsers.filter((user) => !user.isCertified).length;
  }

  private get canLoadMore(): boolean {
    return !!this.resourceResponse?.nextToken;
  }

  public toggleConfirmDialog(): void {
    this.showConfirmDialog = !this.showConfirmDialog;
  }

  ngOnInit(): void {
    if (this.dataFromQuickAction) {
      this.data.group = this.dataFromQuickAction.group;
      this.data.name = this.dataFromQuickAction.group.displayName;
    }
    this.currentUser$.subscribe((currentUser) => (this.currentUser = currentUser));
    this.getGroupMembers(this.data);
  }

  public onCertify(value: { id: string; isCertified: boolean }) {
    if ([true, false].includes(value.isCertified)) {
      this.cloneUsers.find((user) => user.o365UserId === value.id).isCertified = value.isCertified;
    }
  }

  public onSave(): void {
    if (!this.isSaving) {
      this.isSaving = true;
      const membersToRevoke = this.cloneUsers
        .filter((user) => !user.isCertified)
        .map((user) => ({
          name: user.name,
          o365UserId: user.o365UserId,
        }));
      this.groupsApiService
        .revokeUserAccess(this.data.group.id, this.data.name, membersToRevoke)
        .then(() => {
          this.temporaryDataService.addOwnerGroup(this.tempValidation(this.data.group));
          this.noticeService.notifyUser({ message: 'certify.confirmation.message', level: NoticeLevels.SUCCESS });
          if (!!this.data.refresh) {
            this.data.refresh();
          }
          if (!this.dataFromQuickAction) {
            this.dialogRef.close();
          }
          if (this.dataFromQuickAction) {
            this.dataFromQuickAction.refresh();
            this.dashboardProfileService.getDashboardUserProfile();
          }
        })
        .catch((e) => {
          console.error(e);
          this.noticeService.notifyUser({ message: 'certify.confirmation.error', level: NoticeLevels.SUCCESS });
        })
        .finally(() => {
          this.toggleConfirmDialog();
          this.isSaving = false;
        });
    }
  }

  public async getGroupMembers(group: any): Promise<void> {
    this.onClear();
    this.search = null;
    this.isInSecondStep = true;
    this.isLoading = true;

    if (this.isFromQuickActions) {
      this.data = {
        name: group.displayName,
        group,
      };
    }
    this.groupsApiService
      .listGroupMembers(group.group.id, this.search)
      .then((res) => {
        if (this.firstLoad) {
          this.total = res.members.length;
          this.profileUsers = res.members
            .map((member) => ({
              ...member,
              isCertified: true,
            }))
            .sort((a, b) => a.name.localeCompare(b.name));
          this.owners = this.profileUsers.filter((member) => member.isOwner);
          this.cloneUsers = clone(this.profileUsers);
        } else {
          this.profileUsers = res.members
            .map((member) => ({
              ...member,
              isCertified: this.cloneUsers.find((user) => user.o365UserId === member.o365UserId).isCertified,
            }))
            .sort((a, b) => a.name.localeCompare(b.name));
        }
      })
      .finally(() => {
        this.isLoading = false;
        this.firstLoad = false;
      });
  }

  public async loadMore(): Promise<void> {
    if (!this.isLoadingMore && this.canLoadMore) {
      this.isLoadingMore = true;
      this.request.from = this.resourceResponse.nextToken;
      this.groupsApiService
        .listOwnedGroup(this.request)
        .then((response) => {
          this.resourceResponse.ownedGroups = this.resourceResponse.ownedGroups.concat(response.ownedGroups);
          this.resourceResponse.nextToken = response.nextToken;
          this.toggledGroup();
        })
        .catch((error) => {
          this.noticeService.notifyUser(
            new Notice(this.i18n.translate('common.error.get.500.message'), NoticeLevels.ERROR)
          );
        })
        .finally(() => {
          this.isLoadingMore = false;
        });
    }
  }

  public onSearch(value: string): void {
    this.search = value;
    this.setFilters();
  }

  public onUserSearch(value: string): void {
    this.search = value;
    this.setUserFilter();
  }

  public onToggle(event: boolean): void {
    this.isToggled = event;
    this.setFilters();
  }

  private onClear() {
    this.profileUsers = this.cloneUsers;
  }

  public getLastValidationTranslation(certificationInformation: {
    lastValidation: number;
    lastValidatorName: string;
  }): string {
    const translatedDate = this.dtPipe.transform(certificationInformation.lastValidation, {
      locale: this.i18n.currentLocale,
      withTime: false,
      withSeconds: false,
    });
    const translation = this.i18n.translate('certify.update.checked', {
      date: translatedDate,
      validator: certificationInformation.lastValidatorName,
    });
    return translation;
  }

  private async setFilters(): Promise<void> {
    this.request.fulltext = this.search;
    this.getOwnedGroups();
  }

  public setUserFilter(): void {
    this.onClear();
    if (this.search) {
      this.isLoading = true;
      this.profileUsers = this.profileUsers?.filter((user) =>
        user.name.toLowerCase().includes(normalizeString(this.search))
      );
      this.isLoading = false;
    }
  }

  private toggledGroup(): void {
    if (this.isToggled) {
      this.resourceResponse.ownedGroups = this.resourceResponse?.ownedGroups.filter(
        (group) => !group?.certificationInformation?.lastValidation
      );
    }
  }

  private async getOwnedGroups(): Promise<void> {
    this.isLoading = true;
    this.request.resetFrom();
    this.groupsApiService
      .listOwnedGroup(this.request)
      .then((response) => {
        this.resourceResponse = response;
        this.toggledGroup();
      })
      .catch((error) => {
        this.noticeService.notifyUser(
          new Notice(this.i18n.translate('common.error.get.500.message'), NoticeLevels.ERROR)
        );
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  private tempValidation(group: IOwnerGroup): IOwnerGroup {
    const tempGroup = { ...group };
    tempGroup.certificationInformation = { lastValidation: Date.now(), lastValidatorName: this.currentUser.name };
    tempGroup.isCertifyExpiring = false;
    tempGroup.isCertifyExpired = false;
    return tempGroup;
  }
}
