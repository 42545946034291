<sg-eco-profile-card [cardColor]="color">
  <div class="user-content">
    <p class="pre-line">
    <span>{{ ('eco-profile.page.tab.user-profile.user-info-name.greeting'  + (isTeamDashboard ? '.team' : '')) | t : portrait }}</span>
    {{ ('eco-profile.page.tab.user-profile.user-info-name' + (isTeamDashboard ? '.team' : '')) | t : portrait }}
    </p>
    <div class="badges-container">
      <ng-container *ngFor="let badge of badges">
        <div class="profile-badge">
          <img [src]="badge.icon">
          <p class="badge-name">{{ badge.name | t : badge }}</p>
        </div>
      </ng-container>
    </div>
  </div>
</sg-eco-profile-card>
