<base-dialog
  [title]="'groups.dialog.create.title'"
  [isEditing]="false"
  [hasStepper]="!showConfirmationDialog"
  (onCancel)="dialogRef.close()"
>
  <sg-stepper
    *ngIf="!showConfirmationDialog"
    (onCancel)="dialogRef.close()"
    (onSave)="save()"
    [isSaving]="isSaving"
    minWidth="51%"
  >
    <ng-template
      sg-step
      [stepName]="'groups.dialog.intro.stepper' | t"
      [canGoForward]="true"
      [saveButton]="false"
    >
      <div class="step">
        <sg-explanation-section></sg-explanation-section>
      </div>
    </ng-template>

    <ng-template
      sg-step
      [stepName]="'groups.dialog.info.stepper' | t"
      [canGoForward]="informationsSection?.informationForm.valid"
      [saveButton]="false"
      (onNavigation)="updateForm(informationsSection.informationForm, $event)"
    >
      <div class="step no-scroll">
        <sg-informations-section
          #information
          [resourceInfo]="{
            'name': requestForm.value.requestInformation.name,
            'description': requestForm.value.requestInformation.description,
            'groupType': requestForm.value.requestInformation.groupType,
            'owners': requestForm.value.requestInformation.owners
            }"
        >
        </sg-informations-section>
      </div>
    </ng-template>

    <ng-template
      sg-step
      [stepName]="'create.group.step.model' | t"
      [canGoForward]="modelSection?.modelForm.valid"
      [saveButton]="false"
      (onNavigation)="updateForm(modelSection.modelForm, $event)"
    >
      <div class="step">
        <sg-model-section
          #model
          [currentTemplate]="template"
          [modelType]="requestForm.value.requestInformation?.groupType"
        ></sg-model-section>
      </div>
    </ng-template>

    <ng-template
      sg-step
      [stepName]="'common.action.submit' | t "
      [canGoForward]="true"
      [saveButton]="requestSection?.requestForm.valid"
      (onNavigation)="updateForm(requestSection.requestForm, $event)"
    >
      <div class="step">
        <sg-request-section
          #request
          [resourceName]="requestForm.value.requestInformation?.name"
          [justification]="requestForm.value.request?.justification"
          [managers]="requestForm.value.request?.managers"
          [template]="template"
        ></sg-request-section>
      </div>
    </ng-template>
  </sg-stepper>
  <sg-confirmation-section *ngIf="showConfirmationDialog" [defaultApprovers]="nextApprovers" >
    <p>
    {{ 'groups.dialog.confirmation.request.submessage1' | t }}
    <br>
    {{ 'groups.dialog.confirmation.request.submessage2' | t }}
    </p>
  </sg-confirmation-section>
</base-dialog>
