import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthnRedirectTeamsComponent } from '@common/modules/auth/components/redirect/authn-redirect-teams.component';
import { AuthnSignInComponent } from 'projects/@common/modules/auth/components/signin/sign-in.component';
import { AdminConsentRedirectComponent } from 'projects/@common/modules/auth/components/signup/adminconsent-redirect.component';
import { SignupComponent } from 'projects/@common/modules/auth/components/signup/sign-up.component';
import { SignupRedirectComponent } from 'projects/@common/modules/auth/components/signup/signup-redirect.component';
import { DevEnvironmentGuard } from 'projects/@common/modules/guards/dev-environment.guard';
import { DisplayGuard } from 'projects/@common/modules/guards/display.guard';
import { ImagesBankComponent } from 'projects/@common/modules/layout/components/images-bank/images-bank.component';
import { FeaturesEnum } from '@md.eco/interfaces/featureFlags';
import { PrivateLayout } from 'projects/@common/modules/layout/private.layout';
import { AuthGuard } from 'projects/@common/modules/session/auth.guard';
import { DashboardModule } from './pages/dashboard/dashboard.module';
import { DeviceConformityModule } from './pages/device-conformity/device-conformity.module';
import { EcoProfileModule } from './pages/eco-profile/eco-profile.module';
import { OnboardingContactUsComponent } from './pages/onboardings/onboarding-contact-us.component';
import { OnboardingRestrictedAccessComponent } from './pages/onboardings/onboarding-restrictedAccess.component';
import { RequestsModule } from './pages/requests/requests.module';
import { SensitiveFilesModule } from './pages/sensitive-files/sensitive-files.module';
import { SharesModule } from './pages/shares/shares.module';
import { TeamsResourcesModule } from './pages/teams-resources/teams-resources.module';

const routes: Routes = [
  {
    path: 'IMAGES',
    component: ImagesBankComponent,
    canActivate: [ DevEnvironmentGuard ],
  },
  { path: 'onboard/contact', component: OnboardingContactUsComponent},
  { path: 'onboard/restrictedAccess', component: OnboardingRestrictedAccessComponent},
  { path: 'signin', component: AuthnSignInComponent },
  { path: 'login/office', component: AuthnRedirectTeamsComponent },
  { path: 'signup', component: SignupComponent },
  { path: 'signup/redirect', component: SignupRedirectComponent },
  { path: 'adminconsent/redirect', component: AdminConsentRedirectComponent },
  {
    path: '',
    component: PrivateLayout,
    canActivate: [ AuthGuard ],
    children: [
      {
        path: 'dashboard',
        loadChildren: () => DashboardModule,
      },
      {
        path: 'ecoProfile',
        loadChildren: () => EcoProfileModule,
      },
      {
        path: 'teamsResources',
        loadChildren: () => TeamsResourcesModule,
        canLoad: [ DisplayGuard ],
        data: {
          requirements: {
            flags: [ FeaturesEnum.RESOURCE_MANAGEMENT ],
          },
        },
      },
      {
        path: 'devices',
        loadChildren: () => DeviceConformityModule,
        canLoad: [ DisplayGuard ],
        data: {
          requirements: {
            flags: [ FeaturesEnum.DEVICE_MANAGEMENT ],
          },
        },
      },
      {
        path: 'myshares',
        loadChildren: () => SharesModule,
        canLoad: [ DisplayGuard ],
        data: {
          requirements: {
            flags: [ FeaturesEnum.SHARES_MANAGEMENT ],
          },
        },
      },
      {
        path: 'files',
        loadChildren: () => SensitiveFilesModule,
        canLoad: [ DisplayGuard ],
        data: {
          requirements: {
            flags: [ FeaturesEnum.DLP_MANAGEMENT ],
          },
        },
      },
      {
        path: 'requests',
        loadChildren: () => RequestsModule,
      },
      {
        path: '**',
        loadChildren: () => DashboardModule,
      },
    ],
  },
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ],
})
export class SaineGestionRoutingModule {
}
