<ui-carousel [locale]="locale" [learnMoreFinish]="data?.isNewClient" (closeDialogEmitter)="closeDialog()">
  <div #slide>
    <introduction-slide></introduction-slide>
  </div>
  <div #slide>
    <generic-description-slide></generic-description-slide>
  </div>
  <div #slide>
    <quick-actions-description-slide></quick-actions-description-slide>
  </div>
  <div #slide>
    <eco-breaktime-description-slide></eco-breaktime-description-slide>
  </div>
  <div #slide>
    <eco-profile-description-slide></eco-profile-description-slide>
  </div>
  <div #slide>
    <summary-description-slide [isNewClient]="data?.isNewClient"></summary-description-slide>
  </div>
</ui-carousel>
