<div *ngIf="!isLoading" class="high-risk-shares">
  <div class="high-risk-shares--title">
    {{ 'dashboard.highRiskShares.title' | t }}
  </div>
  <div class="high-risk-shares--subtitle">
    {{ 'dashboard.highRiskShares.subtitle' | t }}
  </div>
  <div class="high-risk-shares--data">
    <ui-circle-indicator
      [value]="totalCount"
      size="small"
      [color]="totalCount > 0 ? 'red' : 'gray'"
    ></ui-circle-indicator>
    <div class="high-risk-shares--data--stats">
      <div class="high-risk-shares--data--stats--line">
        <div class="high-risk-shares--data--stats--value">
          {{ foldersCount }}
        </div>
        <div class="high-risk-shares--data--stats--label">
          {{ 'common.folders' | t }}
        </div>
      </div>
      <div class="high-risk-shares--data--stats--separator"></div>
      <div class="high-risk-shares--data--stats--line">
        <div class="high-risk-shares--data--stats--value">{{ filesCount }}</div>
        <div class="high-risk-shares--data--stats--label">
          {{ 'common.files' | t }}
        </div>
      </div>
    </div>
  </div>
  <div [class]="totalCount > 0 ? 'button-container': null" class="button-top-spacing">
    <div *ngIf="!mobileService.isMobile()" class="high-risk-shares--review-link" (click)="handleReviewClick()">
      {{ 'dashboard.highRiskShares.review' | t }}
    </div>
    <ng-container *ngIf="totalCount > 0">
      <span class="pipe">|</span>
      <div *ngIf="!mobileService.isMobile()" class="high-risk-shares--review-link"
           (click)="revokeHighRiskDialog = true">
        {{ 'dashboard.highRiskShares.revoke' | t }}
      </div>
    </ng-container>
  </div>
</div>

<batch-revoke [(revokeHighRiskDialog)]="revokeHighRiskDialog"></batch-revoke>
