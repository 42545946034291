<div class="request-list-mobile--toolbar">
  <ui-table-total-tool [total]="total"></ui-table-total-tool>
  <ui-table-filter-tool
    [tableFilterData]="requestsFilterEnumTableFilterData"
    (select)="handleFilter($event)"
  ></ui-table-filter-tool>
  <ui-table-refresh-tool
    [loading]="isLoading"
    (click)="handleRefresh()"
  ></ui-table-refresh-tool>
</div>

<div class="request-list-mobile--content">
  <div
    class="request-list-mobile--content"
    *ngFor="let request of requests"
  >
    <ui-card
      [withPadding]="true"
      [withShadow]="true"
      (click)="handleRequestClick(request)"
    >
      <div class="request-list-mobile--type">
        #{{ request.number }} -
        {{ 'request.type.enum.' + request.type | t }}
      </div>
      <div class="request-list-mobile--object">
        {{ request.description }}
      </div>
      <div class="request-list-mobile--requester">
        {{ request.requesterName }} - {{ request.createdAt | dt: {locale: i18n.currentLocale} }}
      </div>
      <div class="request-list-mobile--status">
        <request-status-badge [request]="request"></request-status-badge>
        <ui-static
          *ngIf="
            request.status !== requestStatusEnum.APPROVED &&
            request.status !== requestStatusEnum.DECLINED
          "
          label=""
          [value]="'request.status.enum.' + request.status | t"
          [iconName]="
            request.status === requestStatusEnum.CANCELED
              ? 'favicons/icon_non_applicable'
              : 'favicons/icon_in_progress'
          "
          [isSvg]="true"
          [isCustomIcon]="true"
        ></ui-static>
      </div>
    </ui-card>
  </div>
</div>
<div class="request-list-mobile--button">
  <button
    *ngIf="nextToken != total"
    ui-button
    color="linkBorded"
    [loading]="isLoading"
    (click)="loadMoreRequests()"
  >
    {{ 'common.showMore' | t }}
  </button>
</div>