import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'guest-user-expiration-date',
  templateUrl: './guest-user-expiration-date.component.html',
})
export class GuestUserExpirationDateComponent implements OnInit {
  private readonly DAY_TIME_IN_MILLISECONDS = 24 * 60 * 60 * 1000;

  @Input() public user: any;

  @Output() public onExpirationDateUpdate: EventEmitter<number> = new EventEmitter();

  public expirationDateMinimum = new Date();

  public guestUserPeriodInDays: number;

  public ngOnInit() {
    this.expirationDateMinimum.setTime(this.expirationDateMinimum.getTime() + this.DAY_TIME_IN_MILLISECONDS);
    this.guestUserPeriodInDays = Math.ceil((this.user.expirationDate - Date.now()) / this.DAY_TIME_IN_MILLISECONDS);
  }

  public onExpirationDateSelected(selectedExpirationDate: number) {
    this.user.expirationDate = selectedExpirationDate;
    this.guestUserPeriodInDays = Math.ceil((selectedExpirationDate - Date.now()) / this.DAY_TIME_IN_MILLISECONDS);
    this.onExpirationDateUpdate.emit(selectedExpirationDate);
  }
}
