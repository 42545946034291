<sg-page-layout
  [title]="'sensitiveFiles.list' | t"
  [breadcrumbs]="breadcrumbs"
  [showRightSide]="true"
  scrollToBottom
  (onTooltipEvent)="openDescription()"
  (onScrollToBottom)="filesListService.onScroll()"
>
  <ui-card *ngIf="!mobileService.isMobile(requiredWidthForMobile)">
    <ng-container *ngIf="!filesListService.initialLoading && (filesListService?.datasource?.length > 0 || filesListService.fulltext || filesListService.isLoading); else noSensitiveFiles">
    <div class="toolbar">
      <ng-container *ngTemplateOutlet="toolbar"></ng-container>
    </div>
    <ng-container *ngIf="!filesListService.initialLoading">
      <ui-static-table
        [canSelectOnlyOne]="true"
        [locale]="i18n.currentLocale"
        [loading]="filesListService.isLoading"
        [dataSource]="filesListService.datasource"
        [columnsDef]="filesListService.columnsDef"
        [addPaddingOnFirst]="false"
        (on-row-click)="filesListService.handleRowClick($event)"
        (checkbox-event)="filesListService.handleCheckboxEvent($event.event, $event.item)"
        [isCustomSorting]="true"
        (handleCustomSort)="filesListService.handleSort($event.sortColumn, $event.sortDirection)"
        [sort]="filesListService.sort"
        [direction]="filesListService.direction"
      >
      </ui-static-table>
    </ng-container>

    </ng-container>
    <ui-spinner *ngIf="filesListService.initialLoading"></ui-spinner>
  </ui-card>

  <div class="page-description" description *ngIf="!mobileService.isMobile(requiredWidthForMobile)">
    <sensitive-files-description></sensitive-files-description>
  </div>

  <ng-container *ngIf="mobileService.isMobile(requiredWidthForMobile)">
      <sensitive-files-mobile-list></sensitive-files-mobile-list>
  </ng-container>

</sg-page-layout>

<ng-template #toolbar>
  <toolbar toolbar [total]="filesListService.total">
    <div contentBeforeTotal class="button-revoke-all">
      <button
        color="linkFlat"
        ui-button
        [custom-icon]="true"
        icon="icon_pause_file"
        [isSvg]="true"
        (click)="filesListService.onAction(filesListService.selected, true)"
        [disabled]="!filesListService.selected"
      >
        {{ 'sensitiveFile.takeAction' | t }}
      </button>
    </div>

    <ui-table-search-tool
      class="tools"
      [query]="filesListService.fulltext"
      [placeholder]="'ui.table-search-tool.placeholder' | t"
      (clear)="filesListService.handleSearchClear()"
      (query)="filesListService.handleSearchQuery($event)"
    ></ui-table-search-tool>

    <ui-table-refresh-tool
      class="tools"
      [loading]="filesListService.isLoading"
      (click)="filesListService.handleRefresh()"
    ></ui-table-refresh-tool>
  </toolbar>
</ng-template>

<ng-template #noSensitiveFiles>
  <div *ngIf="!filesListService.isLoading" class="no-files">
    <img
      class="image"
      src="assets/favicons/icon_pause_ok_green_background.svg"
    />
    <div class="text">
      <p class="title">{{'sensitiveFile.none.atRisk.title' | t }} </p>
      <p class="description">{{'sensitiveFile.none.atRisk.subTitle' | t }} </p>
    </div>
  </div>
</ng-template>

