<div
  class="page-container"
  scrollToBottom
  (onScrollToBottom)="onScrollToBottom.emit()"
>
  <div class="sticky-top">
    <div class="path-container">
      <breadcrumbs [breadcrumbs]="breadcrumbs"></breadcrumbs>
    </div>
    <div class="title-container">
      <h1>
        <span>
          {{title}}
          <a
            *ngIf="title && documentationLink"
            class="doc-button desktop"
            [href]="documentationLink"
            target="_blank"
          ></a>
          <img
            *ngIf="mobileService.isMobile(1350) && title && showRightSide"
            (click)="onTooltipEvent.emit()"
            src="assets/favicons/icon_info_small_cropped.svg"
          >
        </span>
      </h1>
      <div class="mobile-doc-container">
        <a
          *ngIf="title && documentationLink"
          class="doc-button"
          [href]="documentationLink"
          target="_blank"
        ></a>
      </div>
    </div>
  </div>

  <div [ngClass]="{'content': showRightSide, 'content-full-width': !showRightSide}">
    <div [ngClass]="{'left-side': showRightSide}">
      <ng-content></ng-content>
    </div>
    <div
      class="right-side"
      *ngIf="showRightSide"
    >
      <ng-content select="[description]"></ng-content>
    </div>
  </div>
</div>
