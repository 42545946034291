import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RequestStatusEnum } from '@ui-kit/util/icon-util';
import { ApiEndpointPrefixEnum, EcoApi } from 'projects/@common/definitions/eco-api';
import { FeaturesEnum } from '@md.eco/interfaces/featureFlags';
import { UrlUtils } from 'projects/@common/utils/utils';
import { ListCategoryType } from 'projects/console-sg/src/app/pages/requests/containers/requests.container';
import { EcoUrlService } from '../core/url.service';
import { GetRequestResponse, GetRequestsResponse } from './requests.definitions';

@Injectable({
  providedIn: 'root',
})
export class RequestsApiService extends EcoApi {
  protected prefix = ApiEndpointPrefixEnum.requests;

  constructor(readonly http: HttpClient, url: EcoUrlService) {
    super(http, url);
  }

  public async getRequest(requestId: string): Promise<GetRequestResponse> {
    return this.http.get<any>(`${this.host}/requests/${requestId}`).toPromise();
  }

  public async getRequests(params: {
    language?: string,
    sort?: string;
    fullText?: string;
    direction?: string;
    limit?: number;
    from?: number;
    category?: ListCategoryType;
    status?: RequestStatusEnum;
  } = {}): Promise<GetRequestsResponse> {
    return this.http.get<any>(`${this.host}/requests?${UrlUtils.jsonToQueryParams(params)}`).toPromise();
  }

  public async updateDecision(
    approverId: string,
    requestId: string,
    decision: boolean,
    note: string
  ): Promise<any> {
    const url = `${this.host}/requests/${requestId}/decision`;
    return this.http.patch<any>(url, { id: approverId, decision, note }, this.getFlagParams({ flags: [FeaturesEnum.RESOURCE_MANAGEMENT, FeaturesEnum.GUESTS_MANAGEMENT] })).toPromise();
  }
}
