import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'teams-resources-description',
  templateUrl: './teams-resources-description.component.html',
  styleUrls: [ './teams-resources-description.component.scss' ],
})
export class TeamsResourcesDescriptionComponent {
  constructor () { }
}
