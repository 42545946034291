import { Component, Input, OnInit } from '@angular/core';
import { IConformities } from 'projects/@common/services/api/sg/md.eco-profile/md.eco-profile.definition';

const YEARLY_WEEKS = 52;
@Component({
  selector: 'sg-consecutive-weekly-graph',
  templateUrl: './consecutive-weekly-graph.component.html',
  styleUrls: [ './consecutive-weekly-graph.component.scss' ],
})
export class ConsecutiveWeeklyGraphComponent implements OnInit {
  @Input() conformities: IConformities;

  public consecutiveStreak = [];

  constructor () {
  }

  ngOnInit(): void {
    this.calculGraphWith();
  }

  private calculGraphWith(): void {
    for (const key in this.conformities.yearlyCount) {
      this.consecutiveStreak.push({
        year: key,
        amount: this.conformities.yearlyCount[key],
        width: null,
        isCurrentYear: new Date().getFullYear() === parseInt(key),
      });
    }

    this.consecutiveStreak.forEach((event) => {
      event.width = Math.floor((event.amount * 100) / YEARLY_WEEKS);
    });
  }
}
