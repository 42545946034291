<ng-container *ngIf="!hasResourcesWithShares">
  <div
    *ngIf="!mobileService.isMobile(requiredWidthForMobile)"
    class="toolbar"
  >
    <ng-container *ngTemplateOutlet="toolbar"></ng-container>
  </div>
</ng-container>
<ui-card
  *ngIf="!mobileService.isMobile(requiredWidthForMobile)"
  scrollToBottom
  (onScrollToBottom)="onScroll()"
>
  <ng-container *ngIf="hasResourcesWithShares">
    <div [class.toolbar]="!mobileService.isMobile(requiredWidthForMobile)">
      <ng-container *ngTemplateOutlet="toolbar"></ng-container>
    </div>
  </ng-container>
  <div
    class="flex-card"
    *ngIf="!hasResourcesWithShares && !mobileService.isMobile(requiredWidthForMobile) && !isFromConsoleOrg"
  >
    <ng-container *ngTemplateOutlet="revokeButtons"></ng-container>
  </div>
  <ng-container *ngIf="!mobileService.isMobile(requiredWidthForMobile) && !this.initialLoad">
    <ui-static-table
      [locale]="i18nService.currentLocale"
      [loading]="isLoading"
      [dataSource]="shares"
      [columnsDef]="filesColumnsDef"
      [addPaddingOnFirst]="false"
      (on-row-click)="handleRowClick($event)"
      (checkbox-event)="handleShareCheckbox($event)"
      [isCustomSorting]="true"
      (handleCustomSort)="handleSort($event.sortColumn,$event.sortDirection)"
      [sort]="sort"
      [direction]="direction"
    >
    </ui-static-table>
  </ng-container>
  <ui-spinner *ngIf="initialLoad"></ui-spinner>
</ui-card>

<ng-container *ngIf="mobileService.isMobile(requiredWidthForMobile)">
  <shared-files-mobile
    [isLoading]="isLoading"
    [sharedFiles]="shares"
    [isAutomaticRevokeActivated]="isAutomaticRevokeActivated"
    (onCheck)="handleShareCheckbox($event)"
    (onRefresh)="handleRefresh()"
    (onRiskFilter)="handleRisksFilter($event)"
    (onSearch)="handleSearchQuery($event)"
  ></shared-files-mobile>
</ng-container>

<ng-template #revokeButtons>
  <app-edit-with-modal #editor *ngIf="!mobileService.isMobile(requiredWidthForMobile)">
    <div class="edit-content small-gap" [ngClass]="{ 'disabled':disabled, 'pointer': !disabled }" [tooltip]="disabled ? null : ('group.action.remediation.message'| t)">
      <button
        ui-button
        *ngIf="!selectedSharedFiles.length > 0"
        [color]="mobileService.isMobile(requiredWidthForMobile) ? 'mobileListFlat' : 'linkFlat'"
        [ngClass]="{
        'margin--small--bottom': !mobileService.isMobile(requiredWidthForMobile),
        'shared-files--revoke-button-mobile': mobileService.isMobile(requiredWidthForMobile)
      }"
        icon="close"
        [icon-size]="mobileService.isMobile(requiredWidthForMobile) ? 'small' : 'medium'"
        (click)="toggle()"
      >
        {{'shares.dialog.revoke.actionButton' | t}}
        <img
          alt="edit-icon"
          src="assets/favicons/icon_chevron_down.svg"
        />
      </button>
    </div>
    <div
      class="revoke-content"
      content
    >
      <button
        class="subButton"
        (click)="openExpressRevokeDialog()"
        [disabled]="disableExpressRevoke"
      >
        <div>
          <p>{{'shares.button.revoke.express.revoke' | t}}</p>
          <p class="subButton--description">{{'shares.button.revoke.express.revoke.description' | t}}</p>
        </div>
      </button>
      <button
        class="subButton"
        (click)="showBatchRevokeDialog = true; toggle()"
      >
        <div>
          <p>{{'shares.button.revoke.express.revoke.byType' | t}} </p>
          <p class="subButton--description">{{'shares.button.revoke.express.revoke.byType.description' | t}}</p>
        </div>
      </button>
    </div>
  </app-edit-with-modal>

  <button
    *ngIf="selectedSharedFiles.length > 0 && !isFromConsoleOrg"
    ui-button
    [color]="mobileService.isMobile(requiredWidthForMobile) ? 'mobileListFlat' : 'linkFlat'"
    [ngClass]="{
        'margin--small--bottom': !mobileService.isMobile(requiredWidthForMobile),
        'shared-files--revoke-button-mobile': mobileService.isMobile(requiredWidthForMobile)
      }"
    icon="close"
    [icon-size]="mobileService.isMobile(requiredWidthForMobile) ? 'small' : 'medium'"
    (click)="revokeShares()"
  >
    {{ 'common.action.revokeWithCount' | t: { count: selectedSharedFiles.length, plural: selectedSharedFiles.length > 1 ? 's' : '' } }}
  </button>
</ng-template>

<batch-revoke
  [(showBatchRevokeDialog)]="showBatchRevokeDialog"
  (showBatchRevokeDialogChange)="handleRefresh()"
  [resourceId]="libraryGuid"
  [userId]="isMyShares ? null : userGuid"
>
</batch-revoke>


<ui-dialog
  *ngIf="showRevokeDialog"
  [isSmall]="true"
  [headtitle]="revokeDialogTitle"
  [actionButton]="'shares.dialog.revoke.actionButton' | t"
  [message]="revokeDialogMessage"
  [cancelButton]="'common.action.cancel' | t"
  (onButtonClick)="onRevokeSharesCallback($event)"
  [isLoading]="isLoading"
>
  <p class="confirmation-text">
    {{ 'sharedFile.drawer.shares.dialog.revokeAll.message3' | t }}
    <img
      style="vertical-align:middle;white-space: pre-line;"
      [src]="'assets/favicons/icon_in_progress_grey.svg'"
    />
    {{ 'sharedFile.drawer.shares.dialog.revokeAll.message4' | t }}
  </p>
</ui-dialog>

<ng-template #toolbar>
  <toolbar
    toolbar
    [total]="total"
    [activatedFiltersNumber]="activatedFilters"
    (onApply)="applySelectedFilters()"
    (onCancel)="cancelSelectedFilters()"
    (onClearAllFilters)="clearAllFilters()"
  >
    <div
      *ngIf="hasResourcesWithShares || mobileService.isMobile(requiredWidthForMobile)"
      contentBeforeTotal
      class="button-revoke-all"
    >
      <ng-container *ngTemplateOutlet="revokeButtons"></ng-container>
    </div>

    <ui-table-search-tool
      class="tools"
      *ngIf="!mobileService.isMobile(requiredWidthForMobile)"
      [query]="fulltext"
      [placeholder]="'ui.table-search-tool.placeholder' | t"
      (clear)="handleSearchClear()"
      (query)="handleSearchQuery($event)"
    ></ui-table-search-tool>

    <ui-multi-select
      class="tools"
      *ngIf="!mobileService.isMobile(requiredWidthForMobile)"
      [multiSelectData]="riskMultiSelectData"
      (onOptionSelect)="handleRisksFilter($event)"
    ></ui-multi-select>


    <ui-table-refresh-tool
      class="tools"
      [loading]="isLoading"
      (click)="handleRefresh()"
    ></ui-table-refresh-tool>

    <div
      toolbar-collapsable-element
      [elementTitle]="'shares.filter.label.visibilities' | t"
    >
      <ui-multi-select
        [multiSelectData]="visibilitiesMultiSelectData"
        (onOptionSelect)="handleVisibilityFilter($event)"
      ></ui-multi-select>
    </div>

    <div
      toolbar-collapsable-element
      [elementTitle]="'shares.filter.label.permissions' | t"
    >
      <ui-multi-select
        [multiSelectData]="permissionsMultiSelectData"
        (onOptionSelect)="handlePermissionsFilter($event)"
      ></ui-multi-select>
    </div>

    <div
      toolbar-collapsable-element
      [elementTitle]="'shares.filter.label.elements' | t"
    >
      <ui-multi-select
        [multiSelectData]="itemsMultiSelectData"
        (onOptionSelect)="handleItemsFilter($event)"
      ></ui-multi-select>
    </div>
  </toolbar>
</ng-template>
