import { Component, Input, OnChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { UiTab } from '@ui-kit/components/ui-tabs/ui-tab.component';
import { UiTabs } from '@ui-kit/components/ui-tabs/ui-tabs.component';
import { AbstractDrawer } from 'projects/@common/modules/layout/components/drawer/AbstractDrawer';
import { DrawerState } from 'projects/@common/modules/layout/components/drawer/stores/drawer.store';
import { FileTypeEnum } from 'projects/@common/services/api/sg/shares/shares.definitions';
import { MobileService } from 'projects/@common/services/mobile.service';
import { Observable } from 'rxjs';
import { SharedFileShares } from '../../../components/drawer/shared-file-shares/shared-file-shares.component';

export interface ISharedFileDrawerData {
  fileId: string;
  title: string;
  fileType: FileTypeEnum;
  isFromConsoleOrg: boolean;
  fileCreationDateLimit: number;
  risk: string;
}

@Component({
  selector: 'shared-file-drawer',
  templateUrl: './shared-file-drawer.container.html',
  styleUrls: [ './shared-file-drawer.container.scss' ],
  host: {
    class: 'shared-file-drawer',
  },
  encapsulation: ViewEncapsulation.None,
})
export class SharedFileDrawer extends AbstractDrawer implements OnChanges {
  @Input() public data: ISharedFileDrawerData = null;

  @Select(DrawerState.isEditing) public isEditing$: Observable<boolean>;

  @Select(DrawerState.tabName) public tabName$: Observable<string>;

  @ViewChild('tabs', { static: true }) private tabs: UiTabs;

  @ViewChild('sharesTab', { static: true }) private sharesTab: UiTab;

  @ViewChild('sharesComponent', { static: true }) private sharesComponent: SharedFileShares;

  public sharedFileSharesComponentTabName = SharedFileShares.COMPONENT;

  constructor(protected readonly store: Store, public readonly mobileService: MobileService) {
    super(store);
  }

  public ngOnChanges() {
    super.ngOnChanges();
  }

  public setState(state: any): void {
    this.tabs.selectTabIndex(state.tabIndex);
  }

  public getState(): any {
    return {
      tabIndex: this.tabs.getSelectedTabIndex(),
    };
  }

  public manageShares() {
    this.tabs.selectTab(this.sharesTab);
    this.sharesComponent.toggleEditMode(true);
  }
}
