import { Component, OnInit } from '@angular/core';
import { OrganizationsApiService } from '@common/services/api/sg/organizations/organizationsApi.service';

@Component({
  selector: 'shares-description',
  templateUrl: './shares-description.component.html',
  styleUrls: ['./shares-description.component.scss'],
})
export class SharesDescriptionComponent implements OnInit {
  constructor(private organizationsApiService: OrganizationsApiService) {}

  public hasLabels: boolean = false;

  ngOnInit(): void {
    this.organizationsApiService.getOrganizationHasSensitivityLabels().then((response) => this.hasLabels = response);
  }

  public get labelAmount(): number {
    return this.hasLabels ? 5 : 4;
  }
}
