import { Injectable } from "@angular/core";
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';

export interface SharedFileFactor {
  exposure: string,
  type: string,
  permission: string,
  sharesDurationThresholds?: string,
  label?: number;
}

@Injectable({
  providedIn: 'root',
})
export class SeverityDecidingFactorService {
  constructor(private i18nService: I18nService) { }

  public handleFactorDisplayValue(factors: { name: keyof SharedFileFactor, value: string | number; }[]): string[] {
    return factors?.map((factor) => this.sentenceBuilder(factor));
  }

  public sentenceBuilder(factor: { name: keyof SharedFileFactor, value: string | number; }): string {
    switch (factor?.name) {
      case 'label':
        return this.i18nService.translate(`factor.${factor?.name}`, { data: this.i18nService.translate(`dashboard.admin.sharedFiles.severity.distribution.severity.${factor.value}`) });
      default:
        return this.i18nService.translate(`factor.${factor?.name}.${(factor?.value as string).toLowerCase()}`);
    }
  }
}
