import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'shares-resources-description',
  templateUrl: './shares-resources-description.component.html',
  styleUrls: [ './shares-resources-description.component.scss' ],
})
export class SharesResourcesDescriptionComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {

  }
}
