import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { LanguageEnum } from '@ui-kit/interfaces/ILanguage';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { DateUtils } from 'projects/@common/utils/date-utils';

@Pipe({
  name: 'lastAccessedFormat',
})
export class LastAccessedFormatPipe implements PipeTransform {
  constructor(protected readonly i18n: I18nService, private datepipe: DatePipe) {}

  public transform(value: string | number, lastAccessedDate: number): string {
    const days = DateUtils.daysPassedSince(lastAccessedDate);

    if (!value && days >= 30) {
      return this.i18n.translate('shares.list.NOT_ACCESSED.value', { days: 30 });
    }

    if (!value && days <= 2) {
      return this.i18n.translate('shares.list.NOT_ACCESSED.value.one.day');
    }

    if (!value && days < 30) {
      return this.i18n.translate('shares.list.NOT_ACCESSED.value', { days: days });
    }

    if (typeof value === 'string') {
      const key = `shares.list.${value}.value`;
      return this.i18n.translate(key);
    }

    return this.datepipe.transform(
      new Date(value),
      'longDate',
      null,
      this.i18n.currentLocale === LanguageEnum.FRENCH ? 'fr-ca' : 'en'
    );
  }
}
