import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngxs/store';
import { HideDialog } from '@ui-kit/components/ui-dialog/stores/ui-dialog.store';
import { DialogActions } from '@ui-kit/components/ui-dialog/ui-dialog.component';
import { ISharedFilePermission } from 'projects/@common/services/api/sg/shares/shares.definitions';

@Component({
  selector: 'shared-file-shares-revoke',
  templateUrl: './shared-file-shares-revoke.component.html',
  styleUrls: [ './shared-file-shares-revoke.component.scss' ],
  host: {
    class: 'shared-file-shares-revoke',
  },
  encapsulation: ViewEncapsulation.None,
})
export class SharedFileSharesRevoke {
  @Input() public showRevokeShareConfirmationDialog = false;

  @Input() public permissionToRemove: ISharedFilePermission;

  @Output() public showRevokeShareConfirmationDialogChange: EventEmitter<boolean> = new EventEmitter();

  @Output() public isRevokingShare: EventEmitter<ISharedFilePermission> = new EventEmitter();

  public isRevokingAll = false;

  constructor(private readonly store: Store) {}

  public onRevokeShareConfirmation(event: DialogActions) {
    if (event === DialogActions.ACTION) {
      this.isRevokingShare.emit(this.permissionToRemove);
    } else {
      this.isRevokingShare.emit(null);
    }

    this.showRevokeShareConfirmationDialog = false;
    this.showRevokeShareConfirmationDialogChange.emit(this.showRevokeShareConfirmationDialog);
    this.store.dispatch(new HideDialog());
  }
}
