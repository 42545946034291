<base-dialog
  [title]="'sensitiveFile.takeAction' | t"
  [hasStepper]="false"
  (onCancel)="dialogRef.close()"
  *ngIf="!hasToConfirm"
>
  <div class="container">
    <ng-container *ngIf="!isLoading">
      <p class="description">{{ 'sensitiveFile.action.description' | t }}</p>
      <ui-field-group>
        <ui-switch-text-static
          [isEditing]="true"
          [required]="true"
          [label]="'common.justification' | t"
          name="justification"
          control="justification"
          [value]=""
          [group]="form"
          maxlength="500"
          [rowsNumber]="4"
        ></ui-switch-text-static>
      </ui-field-group>

      <ng-container [formGroup]="form">
        <mat-radio-group formControlName="type" class="radio-buttons">
          <mat-radio-button
            [disabled]="quarantineDisabled(reason.value)"
            *ngFor="let reason of reasons"
            [value]="reason.value"
          >
            <div class="flex">
              <span>{{ reason.displayValue | t }}</span>
              <ui-tooltip>
                <p>{{ reason.hint | t }}</p>
              </ui-tooltip>
            </div>
          </mat-radio-button>
        </mat-radio-group>
      </ng-container>

      <ng-container *ngTemplateOutlet="buttons"></ng-container>
    </ng-container>

    <ng-container *ngIf="isLoading">
      <ui-spinner size="medium" modifier="secondary"></ui-spinner>
    </ng-container>
  </div>
</base-dialog>

<div class="container" *ngIf="hasToConfirm">
  <div class="confirmation-modal-container">
    <h2>{{ 'sensitiveFile.' + form.get('type').value | t }}</h2>
    <p [innerHTML]="'sensitiveFile.' + form.get('type').value + '.desc' | t: { fileName: data?.name }"></p>

    <ng-container *ngIf="form.get('type').value === 'remove' && data?.similarFileNames?.length">
      <p class="no-bottom-margin">{{ 'sensitiveFile.remove.desc.also' | t }}</p>
      <ul class="no-top-margin">
        <li *ngFor="let name of data?.similarFileNames"><strong>{{ name }}</strong></li>
      </ul>
    </ng-container>

    <p>{{ 'sensitiveFile.' + form.get('type').value + '.desc2' | t }}</p>

    <ng-container *ngTemplateOutlet="buttons"></ng-container>
  </div>
</div>

<ng-template #buttons>
  <section class="button-wrapper">
    <button ui-button color="standard" (click)="onConfirm()" [disabled]="form.invalid">
      {{ 'common.action.confirm' | t }}
    </button>
    <button ui-button color="secondary" (click)="dialogRef.close()">{{ 'common.action.cancel' | t }}</button>
  </section>
</ng-template>
