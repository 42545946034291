<div class="shared-file-shares-mobile">
  <ng-container *ngIf="shares?.length">
    <div ui-mobile-card *ngFor="let share of shares">
      <div class="shared-file-shares-mobile--header">
        <shared-to
          [sharedFilePermission]="share"
          [withSpecialMargins]="false"
          [isEditing]="isEditing"
        ></shared-to>

        <div class="shared-file-shares-mobile--header--right-part">
          <ui-dot-indicator
            [riskInfo]="convertAndMapFileRiskToSeverity(share)"
          ></ui-dot-indicator>

          <div
            class="share-delete"
            *ngIf="isEditing"
            (click)="onPermissionRemove.emit(share)"
          >
            <img src="assets/favicons/icon_remove.svg" />
          </div>
        </div>
      </div>

      <div class="shared-file-shares-mobile--information">
        <div>
          {{
            'sharedFile.drawer.shares.table.permission.' + share.permission | t
          }}
        </div>
        <div
          *ngIf="isAutomaticRevokeActivated && share.automaticRevokeDate"
          class="shared-file-shares-mobile--information--revocation"
        >
          {{
            'sharedFile.drawer.shares.table.revocationWithDate'
              | t: { date: share.automaticRevokeDate | dt }
          }}
        </div>
      </div>

      <share-details
        [sharedFilePermission]="share"
        [isSelect]="true"
      ></share-details>
    </div>
  </ng-container>

  <ui-spinner *ngIf="isLoading"></ui-spinner>
</div>
