import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { DefaultSiteSharingLinkTypeEnum } from 'projects/@common/services/api/sg/group-templates/group-templates.definitions';
import { DefaultResourceSharingForm } from './default-resource-sharing.form';

@Component({
  selector: 'app-default-resource-sharing-type',
  templateUrl: './default-resource-sharing-type.component.html',
  styleUrls: [ './default-resource-sharing-type.component.scss' ],
})
export class DefaultResourceSharingTypeComponent implements OnInit, OnChanges {
  @Input() isEditing: boolean;

  @Input() canShareToAnyone: boolean;

  @Input() defaultResourceSharingForm: DefaultResourceSharingForm;

  public sharingOptions: any[];

  constructor(private i18n: I18nService) {}

  public ngOnInit() {
    this.sharingOptions = this.filteredSharingOptions;
  }

  public ngOnChanges(): void {
    this.sharingOptions = this.filteredSharingOptions;
  }

  get filteredSharingOptions(): any[] {
    const defaultSharingOptions = [
      { value: DefaultSiteSharingLinkTypeEnum.EXISTING_ACCESS, displayValue: this.i18n.translate(`defaultSharingLinkType.${DefaultSiteSharingLinkTypeEnum.EXISTING_ACCESS}`) },
      { value: DefaultSiteSharingLinkTypeEnum.SPECIFIC_PEOPLE, displayValue: this.i18n.translate(`defaultSharingLinkType.${DefaultSiteSharingLinkTypeEnum.SPECIFIC_PEOPLE}`) },
      { value: DefaultSiteSharingLinkTypeEnum.ONLY_ORGANIZATION, displayValue: this.i18n.translate(`defaultSharingLinkType.${DefaultSiteSharingLinkTypeEnum.ONLY_ORGANIZATION}`) },
    ];

    if (this.canShareToAnyone) {
      return [ ...defaultSharingOptions, { value: DefaultSiteSharingLinkTypeEnum.ANYONE, displayValue: this.i18n.translate(`defaultSharingLinkType.${DefaultSiteSharingLinkTypeEnum.ANYONE}`) } ];
    }

    return defaultSharingOptions;
  }

  get defaultSharingDisplayValue(): string {
    return this.defaultResourceSharingForm.type || this.defaultResourceSharingForm.type === 0
      ? `defaultSharingLinkType.${this.defaultResourceSharingForm.type}`
      : '';
  }
}
