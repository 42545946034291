import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { UiTableDirection } from '@ui-kit/components/ui-table/ui-table.component';
import { IGroupMember } from 'projects/@common/services/api/sg/groups/groups.definitions';
import { MobileService } from 'projects/@common/services/mobile.service';

@Component({
  selector: 'certify-users-table',
  templateUrl: './certify-users-table.component.html',
  styleUrls: [ './tables.component.scss', './certify-users-table.component.scss' ],
})
export class CertifyUsersTableComponent implements OnChanges {
  @Input() public isFromQuickActions: boolean;
  @Input() public users: IGroupMember[];
  @Input() public unfilteredUsers: IGroupMember[];
  @Input() public owners: IGroupMember[];
  @Input() public isLoading = false;
  @Input() public showBadges = false;
  @Input() public disableSave = false;
  @Input() public total = 0;
  @Output() public onCancel: EventEmitter<void> = new EventEmitter();
  @Output() public onSave: EventEmitter<void> = new EventEmitter();
  @Output() public onCertify: EventEmitter<any> = new EventEmitter();

  public direction: UiTableDirection = UiTableDirection.Asc;

  constructor(
    public mobileService: MobileService,
    private cdr: ChangeDetectorRef
  ) {
  }

  public get confirmNumber(): number {
    return this.unfilteredUsers?.filter((user) => user.isCertified).length;
  }

  public get revokeNumber(): number {
    return this.unfilteredUsers?.filter((user) => !user.isCertified).length;
  }

  public get isLastOwner(): boolean {
    return this.unfilteredUsers?.filter((user) => user.isOwner && user.isCertified).length === 1;
  }

  public isToggleDisabled(user: IGroupMember): boolean {
    return user.isPrivateChannelOwner || (user.isOwner && user.isCertified ? this.isLastOwner : false);
  }

  ngOnChanges() {
    this.cdr.detectChanges();
  }

  public save() {
    this.onSave.emit();
  }

  public cancel() {
    this.onCancel.emit();
  }
}
