import { Directive } from '@angular/core';
import { NG_VALIDATORS, AbstractControl } from '@angular/forms';
import { CustomValidators } from 'projects/@common/utils/validators';

@Directive({
  selector: '[phoneNumberValidator]',
  providers: [ { provide: NG_VALIDATORS, useExisting: PhoneNumberValidatorDirective, multi: true } ],
})
export class PhoneNumberValidatorDirective {
  validate(control: AbstractControl): { [key: string]: any } | null {
    return CustomValidators.validatePhoneNumber(control);
  }
}
