<div *ngIf="isApplyDialog" class="skip-checkbox">
  <ui-checkbox
    [checked]="skipFirstStep"
    [isEditable]="true"
    [label]="'groups.dialog.intro.skip' | t "
    [fontSizeType]="checkboxFontSizeEnum.SMALL"
    controlName="skipApplyExplanation"
    [group]="explanationForm"
  ></ui-checkbox>
</div>
<div class="container" [class.custom-top-padding]="isApplyDialog">
  <div class="section">
    <div class="section-card">
      <h5>{{getMessageForModel('groups.dialog.intro.what-is-it.label') | t}}</h5>
      <p>{{getMessageForModel('groups.dialog.intro.what-is-it.text') | t }}</p>
    </div>
    <div class="section-card max-height">
      <h5>{{'groups.dialog.intro.what-should-I-do.label' | t}}</h5>
      <span *ngIf="isApplyDialog" class="preamble">
        {{'groups.dialog.intro.what-should-I-do.icon.model.description' | t }}
        <img  class="image-with-text" src="assets/favicons/icon_pause_warning.svg">
      </span>
      <ol>
        <li class="padded-list-element"> {{getMessageForModel('groups.dialog.intro.what-should-I-do.list_element.1') | t}} </li>
        <li class="padded-list-element">{{getMessageForModel('groups.dialog.intro.what-should-I-do.list_element.2') | t}}</li>
        <li class="padded-list-element">{{getMessageForModel('groups.dialog.intro.what-should-I-do.list_element.3') | t}}</li>
        <li class="padded-list-element">{{getMessageForModel('groups.dialog.intro.what-should-I-do.list_element.4') | t}}</li>
        <li class="padded-list-element" *ngIf="isApplyDialog">
          <span >
          {{getMessageForModel('groups.dialog.intro.what-should-I-do.list_element.5') | t}}
           <img class="image-with-text" src="assets/favicons/icon_pause_ok.svg">
          </span>
        </li>
      </ol>
    </div>
  </div>
  <div class="section">
    <div class="section-card">
      <h5>{{getMessageForModel('groups.dialog.intro.wich-is-ideal.label') | t}}</h5>
      <p class="pre-line">{{getMessageForModel('groups.dialog.intro.wich-is-ideal.text') | t}}</p>
      <ul *ngIf="isApplyDialog">
        <li>{{getMessageForModel('groups.dialog.intro.wich-is-ideal.list_element.1') | t}}</li>
        <li>{{getMessageForModel('groups.dialog.intro.wich-is-ideal.list_element.2') | t}}</li>
        <li>{{getMessageForModel('groups.dialog.intro.wich-is-ideal.list_element.3') | t}}</li>
        <li>{{getMessageForModel('groups.dialog.intro.wich-is-ideal.list_element.4') | t}}</li>
      </ul>
    </div>
    <div class="section-card" [class.section-card--white-background]="isApplyDialog">
      <div class="reward">
        <div class="text">
          <h5>{{getMessageForModel('groups.dialog.intro.role.label') | t}}</h5>
          <p class="pre-line">{{getMessageForModel('groups.dialog.intro.role.text') | t}}</p>
        </div>
        <div *ngIf="isApplyDialog" class="medal-icon">
          <img class="image"src="assets/favicons/icon_medal.svg">
        </div>
      </div>
    </div>
  </div>
</div>
