import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GroupsTemporaryDataService } from '@common/services/api/sg/temporary-data/groups-temporary-data.service';
import { UiDialog } from '@ui-kit/components/ui-dialog/ui-dialog.component';
import { TimeUtil } from '@ui-kit/services/time-util';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { NoticeLevels, NoticeService } from 'projects/@common/modules/notice/notice.service';
import { GroupsApiService } from 'projects/@common/services/api/sg/groups/groupsApi.service';
import { DashboardProfileService } from 'projects/@common/services/dashboard-profile.service';
import { IOwnerGroup } from 'projects/console-sg/src/app/pages/teams-resources/services/resources-list.service';
import { DropdownActions } from '../group-actions/dropdownActions.enum';

@Component({
  selector: 'edit-group-expiration-date-dialog',
  templateUrl: './edit-group-expiration-date-dialog.component.html',
  styleUrls: [ './edit-group-expiration-date-dialog.component.scss' ],
})
export class EditGroupExpirationDateDialogComponent implements OnInit {
  public minExpirationDate = new Date();

  @Input() public dataFromQuickAction: {
    group: IOwnerGroup;
    fromQuickAction: boolean;
    refresh: any;
  };

  public maxExpirationDate: Date;

  private newArchiveDate;

  public isSaving = false;

  constructor (
    public dialogRef: MatDialogRef<UiDialog>,
    public readonly i18n: I18nService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private notice: NoticeService,
    private groupsApiService: GroupsApiService,
    private temporaryDataService: GroupsTemporaryDataService,
    private dashboardProfileService: DashboardProfileService
  ) { }

  ngOnInit(): void {
    if (this.dataFromQuickAction) {
      this.data.group = this.dataFromQuickAction.group;
    }

    this.maxExpirationDate = new Date(new Date().getTime() + this.data.group.securityModel.expirationDuration * TimeUtil.dayDuration);
    this.newArchiveDate = this.maxExpirationDate.getTime();
  }

  public updateDate(): void {
    if (!this.isSaving) {
      this.isSaving = true;
      this.groupsApiService.updateGroupArchiveDate({ groupOfficeId: this.data.group.id, newArchiveDate: this.newArchiveDate }).then((response) => {
        this.notice.notifyUser({
          message: `group.action.confirmation.message.${DropdownActions.EXP_DATE}`,
          level: NoticeLevels.SUCCESS,
          secondMessage: `group.action.confirmation.submessage.${DropdownActions.EXP_DATE}`,
        });
        this.data.group.archiveDate = this.newArchiveDate;
        this.data.group.securityModel.isExpiring = false;
        this.temporaryDataService.addOwnerGroup(this.data.group);
        if (!!this.data.refresh) {
          this.data.refresh();
        }
      })
        .catch((error) => {
          this.notice.notifyUser({ message: 'groups.action.archive_date.error', level: NoticeLevels.ERROR });
        })
        .finally(() => {
          if (!this.dataFromQuickAction) {
            this.dialogRef.close();
          }
          if (this.dataFromQuickAction?.fromQuickAction) {
            this.dataFromQuickAction.refresh();
            this.dashboardProfileService.getDashboardUserProfile();
          }
          this.isSaving = false;
        });
    }
  }

  public onExpirationDateSelected(date: any): void {
    this.newArchiveDate = date;
  }

  public close() {
    this.dialogRef.close();
  }
}
