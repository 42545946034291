import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { SharesApiService } from 'projects/@common/services/api/sg/shares/shares-api.service';
import { Notice, NoticeLevels, NoticeService } from 'projects/@common/modules/notice/notice.service';
import { EcoSessionState } from 'projects/@common/modules/session/state/session.state';
import { FileRiskEnum } from 'projects/@common/services/api/sg/shares/shares.definitions';
import { MobileService } from 'projects/@common/services/mobile.service';

@Component({
  selector: 'high-risk-shares',
  templateUrl: './high-risk-shares.component.html',
  styleUrls: [ './high-risk-shares.component.scss' ],
  encapsulation: ViewEncapsulation.None,
})
export class HighRiskSharesComponent implements OnInit {
  public userId: string;
  public isLoading = false;
  public filesCount = 0;
  public foldersCount = 0;
  public totalCount = 0;
  public revokeHighRiskDialog = false;

  constructor(
    private sharesService: SharesApiService,
    private router: Router,
    private store: Store,
    public mobileService: MobileService,
    private noticeService: NoticeService
  ) {
  }

  ngOnInit() {
    this.userId = this.store.selectSnapshot(EcoSessionState.userGuid);
    this.isLoading = true;
    this.sharesService
      .getUserHighRiskCount()
      .then((counts) => {
        this.filesCount = counts.files;
        this.foldersCount = counts.folders;
        this.totalCount = counts.files + counts.folders;
      })
      .catch((error) => {
        this.noticeService.notifyUser(new Notice('common.error.retry', NoticeLevels.ERROR));
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  public async handleReviewClick(): Promise<void> {
    this.router.navigate([ `/myshares` ], {
      queryParams: {
        sharesRiskFilter: FileRiskEnum.HIGH,
      },
    });
  }
}
