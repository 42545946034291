import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiEndpointPrefixEnum, EcoApi } from "projects/@common/definitions/eco-api";
import { EcoUrlService } from "../../core/url.service";

@Injectable({
  providedIn: 'root',
})
export class TeamsApiService extends EcoApi {
  protected prefix = ApiEndpointPrefixEnum.sg;

  constructor(readonly http: HttpClient, url: EcoUrlService) {
    super(http, url);
  }

  public async getAppId(): Promise<{ appId: string }> {
    const url = `${this.host}/teams/appId`;
    return this.http.get<{ appId: string }>(url).toPromise();
  }

  public async validateTeamOwnerChannelOwnership(teamId: string): Promise<any> {
    const url = `${this.host}/teams/${teamId}/channels/isAllOwner`;
    return this.http.get<any>(url).toPromise();
  }
}
