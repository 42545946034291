import { Component, Input, OnInit } from '@angular/core';
import { I18nService } from '@common/modules/i18n/i18n.service';
import { LanguageEnum } from '@ui-kit/interfaces/ILanguage';


@Component({
  selector: 'summary-description-slide',
  templateUrl: './summary-description-slide.component.html',
  styleUrls: [ './summary-description-slide.component.scss' ],
})
export class SummaryDescriptionSlideComponent implements OnInit {
  @Input() isNewClient?: boolean;

  constructor(private readonly i18n: I18nService) {
  }

  getLocalizedLink() {
    return `https://appsource.microsoft.com/${this.i18n.currentLocale == LanguageEnum.ENGLISH ? "en" : "fr"}-ca/product/web-apps/mondata.saas-mdeco-cybersecuriteproactive?tab=Overview`;
  }

  ngOnInit(): void {
  }
}
