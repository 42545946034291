import { NgModule } from '@angular/core';
import { AssistOpsModule } from 'projects/@assist-ops/assist-ops.module';
import { FeatureSharedModule } from 'projects/@common/modules/shared/feature.shared.module';
import { SgSharedModule } from '../../shared/shared.module';
import { SharesModule } from '../shares/shares.module';
import { SensitiveFilesList } from './sensitive-files-list/sensitive-files-list';
import { SensitiveFilesDescriptionComponent } from './sensitive-files-description/sensitive-files-description.component';
import { SensitiveFilesRoutingModule } from './sensitive-files-routing.module';
import { SensitiveFilesMobileListComponent } from './sensitive-files-list/sensitive-files-mobile-list/sensitive-files-mobile-list.component';
import { DirectivesModule } from '@common/modules/directives/directives.module';

const components = [ SensitiveFilesList, SensitiveFilesDescriptionComponent, SensitiveFilesMobileListComponent ];

@NgModule({
  declarations: [
    ...components,
  ],
  exports: [
    ...components,
  ],
  imports: [
    SensitiveFilesRoutingModule,
    AssistOpsModule,
    ...FeatureSharedModule.commonImports(),
    SgSharedModule,
    DirectivesModule,
    SharesModule,
  ],
})
export class SensitiveFilesModule {
}
