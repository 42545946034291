import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { GroupsTemporaryDataService } from '@common/services/api/sg/temporary-data/groups-temporary-data.service';
import { DTPipe } from '@ui-kit/pipes/dt.pipe';
import { GroupTypeEnum } from '@ui-kit/util/icon-util';
import { BaseDialogComponent } from 'projects/@assist-ops/components/base-dialog/base-dialog.component';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { NoticeService } from 'projects/@common/modules/notice/notice.service';
import { GroupTemplateLifecycleEnum } from 'projects/@common/services/api/sg/group-templates/group-templates.definitions';
import { GroupsApiService } from 'projects/@common/services/api/sg/groups/groupsApi.service';
import { MobileService } from 'projects/@common/services/mobile.service';
import {
  GetResourceGroupRequest,
  IOwnerGroup,
  IResourceResponse
} from 'projects/console-sg/src/app/pages/teams-resources/services/resources-list.service';
import { ModelsHelpDialogComponent } from '../sections/model-section/help-dialog/models-help-dialog.component';

export enum OwnerGroupActionType {
  securityModel = 'securityModel',
  archive = 'archive',
  updateExpirationDate = 'updateExpirationDate',
  certifyAccesses = 'certifyAccesses'
}
export interface GroupActionDialogData {
  dialogTitle: string;
  dialogSubtitle: string;
  dialogDescription: string;
  type: OwnerGroupActionType;
  fromQuickAction: boolean;
}

@Component({
  selector: 'group-quick-action-dialog',
  templateUrl: './group-quick-action-dialog.component.html',
  styleUrls: ['./group-quick-action-dialog.component.scss'],
})
export class GroupQuickActionDialogComponent implements OnInit {
  public isLoading = false;
  public isLoadingMore = false;

  public getResourceGroupRequest: GetResourceGroupRequest;
  public getResourceGroupResponse: IResourceResponse;

  public groupList: IOwnerGroup[] = [];
  public selectedGroup: IOwnerGroup;

  public actionType = OwnerGroupActionType;

  public groupTypeEnum = GroupTypeEnum;

  public temporaryGroup: IOwnerGroup[] = [];

  public fulltext: string = null;
  public showModelWithoutGroup: boolean = false;

  private tempSearch: string = null;
  private filter: string = null;

  constructor(
    private temporaryDataService: GroupsTemporaryDataService,
    public dialogRef: MatDialogRef<BaseDialogComponent>,
    public dialog: MatDialog,
    public i18n: I18nService,
    private dt: DTPipe,
    private groupsApi: GroupsApiService,
    private noticeService: NoticeService,
    private mobileService: MobileService,
    private route: ActivatedRoute,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: GroupActionDialogData
  ) { }

  ngOnInit(): void {
    const { accessRevision, resourceName, requiredOnly, archiveResources } = this.route.snapshot.queryParams;
    if (+accessRevision || +archiveResources) {
      this.showModelWithoutGroup = !!(+requiredOnly);
      this.fulltext = resourceName ?? null;
      this.tempSearch = this.fulltext;
      this.showGroup();
    }
    this.getOwnedGroups();
  }

  public getValidationFilter(): string {
    if (!this.filter && [OwnerGroupActionType.archive, OwnerGroupActionType.updateExpirationDate].includes(this.data.type)) {
      return this.getResourceGroupRequest.validationFilter = 'WITH_TEMPLATE';
    }
    return this.filter;
  }

  public get hideToggle(): boolean {
    return [OwnerGroupActionType.archive, OwnerGroupActionType.updateExpirationDate, OwnerGroupActionType.certifyAccesses].includes(this.data.type);
  }

  public get hideContextualHelp(): boolean {
    return this.data.type === OwnerGroupActionType.securityModel;
  }

  public get toggleLabel(): string {
    if (this.data.type === OwnerGroupActionType.certifyAccesses) {
      return 'certify.toggle';
    }

    if ([OwnerGroupActionType.archive, OwnerGroupActionType.updateExpirationDate].includes(this.data.type)) {
      return 'resource.expired.toggle';
    }

    return 'quickAction.model.toggle.disabled';
  }

  public refreshToGroup(): void {
    this.router.navigate([], { relativeTo: this.route, queryParams: null, replaceUrl: true });
    this.selectedGroup = null;
    this.fulltext = this.tempSearch;
    this.getOwnedGroups();
  }

  public get actionData(): any {
    return {
      group: this.selectedGroup,
      refresh: this.refreshToGroup.bind(this),
      fromQuickAction: true,
    };
  }

  public onGroupClick(selectedGroup: IOwnerGroup): void {
    this.selectedGroup = selectedGroup;
  }

  public handleToggle(event: boolean): void {
    this.showModelWithoutGroup = event;
    this.showGroup();
    this.getOwnedGroups();
  }

  public showGroup(): void {
    if (this.showModelWithoutGroup) {
      if ([OwnerGroupActionType.archive, OwnerGroupActionType.updateExpirationDate].includes(this.data.type)) {
        this.filter = 'WITH_TEMPLATE_NOT_COMPLIANT';
      }

      if (this.data.type === OwnerGroupActionType.certifyAccesses) {
        this.filter = 'CERTIFICATION_EXPIRED';
      }

      if (this.data.type === OwnerGroupActionType.securityModel) {
        this.filter = 'WITHOUT_TEMPLATE';
      }
    } else {
      this.filter = null;
    }
  }

  public handleSearchQuery(event: string): void {
    this.fulltext = event;
    this.tempSearch = event;
    this.getOwnedGroups();
  }

  public handleSearchClear(): void {
    this.fulltext = null;
    this.getOwnedGroups();
  }

  public getGroupDisplayInfo(ownedGroup: IOwnerGroup): {
    icon: string;
    description: string;
    descriptionColor: boolean;
  } {
    switch (this.data.type) {
      case OwnerGroupActionType.updateExpirationDate:
      case OwnerGroupActionType.archive:
        const archiveDate = {
          archiveDate: this.dt.transform(ownedGroup.archiveDate, {
            withTime: false,
            locale: this.i18n.currentLocale,
            withSeconds: false,
          }),
          nbDays: ownedGroup.securityModel.expirationDuration,
        };
        return {
          icon: this.getIcon(ownedGroup.archiveDate < Date.now(), ownedGroup?.securityModel?.isExpiring),
          description:
            ownedGroup.securityModel.expirationTrigger === GroupTemplateLifecycleEnum.INACTIVITY
              ? this.i18n.translate('shares.gestions.security_model.expiration.inactivity', archiveDate)
              : this.i18n.translate('shares.gestions.security_model.expiration.fromCreation', archiveDate),
          descriptionColor: ownedGroup.archiveDate < Date.now(),
        };
      case OwnerGroupActionType.securityModel:
        const option = { modelName: ownedGroup.securityModel.name };
        return ownedGroup.securityModel.name
          ? {
            icon: 'icon_pause_ok.svg',
            description: this.i18n.translate('quickAction.model.description.withModel', option),
            descriptionColor: false,
          }
          : {
            icon: 'icon_pause_warning.svg',
            description: this.i18n.translate('quickAction.model.description.noModel', option),
            descriptionColor: true,
          };
      case OwnerGroupActionType.certifyAccesses:
        const translateOptions = this.dt.transform(ownedGroup.certificationInformation.lastValidation, {
          locale: this.i18n.currentLocale,
          withTime: false,
          withSeconds: false,
        });
        const isExpired = ownedGroup.isCertifyExpired || ownedGroup.isCertifyExpired === null;
        return {
          icon: this.getIcon(isExpired, ownedGroup?.isCertifyExpiring),
          description: isExpired
            ? this.i18n.translate('certify.update')
            : this.i18n.translate('certify.update.checked', {
              date: translateOptions,
              validator: ownedGroup.certificationInformation.lastValidatorName,
            }),
          descriptionColor: isExpired,
        };
      default:
        return {
          icon: 'icon_pause_warning.svg',
          description: '',
          descriptionColor: true,
        };
    }
  }

  private getIcon(isExpired: boolean, isExpiring: boolean) {
    let icon = 'icon_pause_ok.svg';
    if (isExpired) {
      icon = 'icon_pause_warning.svg';
    }
    if (isExpiring) {
      icon = 'icon_pause_alert.svg';
    }
    return icon;
  }

  public loadMore(): void {
    if (!this.isLoadingMore && !!this.getResourceGroupResponse.nextToken) {
      this.isLoadingMore = true;
      this.getResourceGroupRequest = new GetResourceGroupRequest({
        fulltext: this.fulltext,
        from: this.getResourceGroupResponse.nextToken,
      });
      this.getResourceGroupRequest.validationFilter = this.getValidationFilter();
      this.groupsApi
        .listOwnedGroup(this.getResourceGroupRequest)
        .then((response) => {
          this.getResourceGroupResponse = response;
          const tempResponse = response;
          tempResponse.ownedGroups = this.temporaryDataService.getOwnerGroupWithFake(tempResponse.ownedGroups);
          this.groupList = this.groupList.concat(tempResponse.ownedGroups);
          this.temporaryGroup = [...this.groupList];
        })
        .catch((error) => this.noticeService.error('common.error.get.500.message'))
        .finally(() => (this.isLoadingMore = false));
    }
  }

  public openContextualHelp(): void {
    this.dialog.open(ModelsHelpDialogComponent, {
      maxWidth: !this.mobileService.isMobile() ? '75%' : '100%',
      width: 'max-content',
      panelClass: 'custom-dialog',
      data: {},
    });
  }

  public onCancel() {
    this.router.navigate([], { relativeTo: this.route, queryParams: null, replaceUrl: true });
    this.dialogRef.close();
  }

  private async getOwnedGroups(): Promise<void> {
    this.isLoading = true;
    this.getResourceGroupRequest = new GetResourceGroupRequest({ fulltext: this.fulltext });
    this.getResourceGroupRequest.resetFrom();
    this.getResourceGroupRequest.validationFilter = this.getValidationFilter();

    this.groupsApi
      .listOwnedGroup(this.getResourceGroupRequest)
      .then((response) => {
        this.getResourceGroupResponse = response;
        const tempResponse = response;
        tempResponse.ownedGroups = this.filterTemporaryData(this.temporaryDataService.getOwnerGroupWithFake(tempResponse.ownedGroups));
        this.groupList = tempResponse.ownedGroups;
        this.temporaryGroup = [...this.groupList];
      })
      .catch((error) => this.noticeService.error('common.error.get.500.message'))
      .finally(() => (this.isLoading = false));
  }

  private filterTemporaryData(ownedGroups: IOwnerGroup[]): IOwnerGroup[] {
    const filter = this.getValidationFilter();
    if (filter === 'CERTIFICATION_EXPIRED') {
      return ownedGroups.filter((group) => group.isCertifyExpired);
    } else if (filter === 'WITHOUT_TEMPLATE') {
      return ownedGroups.filter((group) => !group.securityModel?.name);
    }
    return ownedGroups;
  }
}
