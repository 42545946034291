import { NgModule } from '@angular/core';
import { AssistOpsModule } from 'projects/@assist-ops/assist-ops.module';
import { ExpositionTranslatePipe } from 'projects/@assist-ops/pipes/expositionTranslate.pipe';
import { LastAccessedFormatPipe } from 'projects/@assist-ops/pipes/lastAccessedFormat.pipe';
import { FeatureSharedModule } from 'projects/@common/modules/shared/feature.shared.module';
import { SgSharedModule } from '../../shared/shared.module';
import { SharesDescriptionComponent } from './containers/shares-descriptions/shares-description/shares-description.component';
import { SharesResourcesDescriptionComponent } from './containers/shares-descriptions/shares-resources-description/shares-resources-description.component';
import { SharesResourcesContainer } from './containers/shares-resources/shares-resources.container';
import { SharesContainer } from './containers/shares/shares.container';
import { SharesRoutingModule } from './shares-routing.module';

@NgModule({
  declarations: [
    SharesContainer,
    SharesResourcesContainer,
    SharesDescriptionComponent,
    SharesResourcesDescriptionComponent,
  ],
  providers: [
    LastAccessedFormatPipe,
    ExpositionTranslatePipe,
  ],
  imports: [
    SharesRoutingModule,
    SgSharedModule,
    AssistOpsModule,
    ...FeatureSharedModule.commonImports(),
  ],
  exports: [
    SharesDescriptionComponent,
    SharesResourcesDescriptionComponent,
  ],
})
export class SharesModule { }
