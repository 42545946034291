import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EditWithModalComponent } from 'projects/@common/components/edit-with-modal/edit-with-modal.component';
import { MobileService } from 'projects/@common/services/mobile.service';
import { CreateResourceDialogComponent } from '../../../shared/components/dialogs/resources-dialogs/create-resource-dialog/create-resource-dialog.component';
import { GroupQuickActionDialogComponent, OwnerGroupActionType } from '../../../shared/components/dialogs/resources-dialogs/groupe-quick-action-dialog/group-quick-action-dialog.component';
import { ResourcesListService } from '../services/resources-list.service';

@Component({
  selector: 'resource-quick-actions',
  templateUrl: './resource-quick-actions.component.html',
  styleUrls: ['./resource-quick-actions.component.scss'],
})
export class ResourceQuickActionComponent implements OnInit {
  @ViewChild('editor') public editWithModalComponent: EditWithModalComponent;
  @Input() hasResourceApprovers = true;
  @Input() hasArchiver = false;
  public resources = [];
  public ownerGroupActionType = OwnerGroupActionType;
  private type: OwnerGroupActionType;

  constructor(private eRef: ElementRef, public dialog: MatDialog, public mobileService: MobileService, public resourcesListService: ResourcesListService,) {
  }

  private get data() {
    const data = { type: this.type };
    switch (this.type) {
      case OwnerGroupActionType.securityModel:
        return {
          dialogTitle: 'group.action.remediation.button.model',
          dialogSubtitle: 'quickAction.model.title.description',
          dialogDescription: 'quickAction.model.subtitle', ...data,
        };
      case OwnerGroupActionType.certifyAccesses:
        return {
          type: OwnerGroupActionType.certifyAccesses,
          dialogTitle: 'certify.title.noGroup',
          dialogSubtitle: 'certify.second.title',
          dialogDescription: 'certify.group.selection', ...data,
        };
      case OwnerGroupActionType.updateExpirationDate:
        return {
          dialogTitle: 'group.action.remediation.button.date',
          dialogSubtitle: 'quickAction.model.title.description',
          dialogDescription: 'quickAction.date.subtitle', ...data,
        };
      case OwnerGroupActionType.archive:
        return {
          dialogTitle: 'group.action.remediation.button.archive',
          dialogSubtitle: 'quickAction.model.title.description',
          dialogDescription: 'quickAction.archive.subtitle', ...data,
        };
    }
  }

  ngOnInit(): void {
    this.resourcesListService.cardsSubject$.subscribe((value) => {
      this.resources = value;
    });
  }

  @HostListener('document:click', ['$event']) @HostListener('document:keydown.escape', ['$event']) onClose(event: KeyboardEvent): void {
    if (this.editWithModalComponent.isOpen && !this.eRef.nativeElement.contains(event.target)) {
      this.editWithModalComponent?.close();
    }
  }

  public toggle() {
    this.editWithModalComponent.toggle();
  }

  public openQuickActionDialog(groupActionType: OwnerGroupActionType) {
    this.type = groupActionType;
    this.dialog.open(GroupQuickActionDialogComponent, {
      minWidth: '730px',
      maxWidth: !this.mobileService.isMobile() ? '75%' : '100%', width: '50vw', maxHeight: '85vh', panelClass: 'custom-dialog', data: this.data,
    });
    this.toggle();
  }

  public createResource() {
    this.dialog.open(CreateResourceDialogComponent, {
      minWidth: '730px',
      maxWidth: !this.mobileService.isMobile() ? '75%' : '100%', width: '50vw', maxHeight: '85vh', panelClass: 'custom-dialog',
    });
  }
}
