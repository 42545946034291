import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { I18nService } from '@common/modules/i18n/i18n.service';
import { SensitiveFilesApiService } from '@common/services/api/sg/files/sensitive-files-api.service';
import { LanguageEnum } from '@ui-kit/interfaces/ILanguage';
import { BaseDialogComponent } from '../base-dialog/base-dialog.component';
import { ISensitiveFileDrawerData } from '../drawer/sensitive-file/sensitive-file-drawer/sensitive-file-drawer.container';

export enum ActionType {
  REMOVE = 'remove',
  FALSE_POSITIVE = 'falsePositive',
  JUSTIFY = 'justify'
}

@Component({
  selector: 'sensitive-files-actions-dialog',
  templateUrl: './sensitive-files-actions-dialog.component.html',
  styleUrls: ['./sensitive-files-actions-dialog.component.scss'],
})
export class SensitiveFilesActionsDialogComponent implements OnInit {
  public isLoading = false;
  public form: UntypedFormGroup;
  public hasToConfirm = false;
  public reasons = [
    {
      value: ActionType.REMOVE,
      displayValue: 'common.remove',
      hint: 'sensitiveFile.hint.remove',
    },
    {
      value: ActionType.FALSE_POSITIVE,
      displayValue: 'sensitiveFile.falsePositive',
      hint: 'sensitiveFile.hint.false',
    },
    {
      value: ActionType.JUSTIFY,
      displayValue: 'common.justify',
      hint: 'sensitiveFile.hint.justify',
    },
  ];

  public data: ISensitiveFileDrawerData = null;

  constructor(
    public dialogRef: MatDialogRef<BaseDialogComponent>,
    public i18n: I18nService,
    public fb: UntypedFormBuilder,
    private sensitiveFilesApiService: SensitiveFilesApiService,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.form = this.fb.group({
      justification: ['', Validators.required],
      type: ['', Validators.required],
    });
    this.data = this.dialogData;
    if (this.dialogData.isFromList) {
      this.sensitiveFilesApiService
        .describeSensitiveFile(this.dialogData.fileId, this.i18n.currentLocale as LanguageEnum)
        .then((data) => {
          this.data = data;
        });
    }
    this.updateHint();
  }

  public onConfirm(): void {
    if (!this.hasToConfirm) {
      this.hasToConfirm = true;
    } else {
      this.dialogRef.close(this.form.getRawValue());
    }
  }

  public onCancel(): void {
    this.dialogRef.close();
  }

  public quarantineDisabled(reason: ActionType): boolean {
    return reason === ActionType.REMOVE && this.data?.sourceType === 'OneDrive' && this.data?.dlpType !== 'Purview';
  }

  private updateHint(): void {
    if (this.data?.sourceType === 'OneDrive' && this.data?.dlpType !== 'Purview') {
      this.reasons[0].hint = 'sensitiveFile.action.disabled';
    }
  }
}
