<ui-static-table
  *ngIf="!isChangingColumnDef"
  [columnsDef]="columnsDef"
  [loading]="isLoading"
  [dataSource]="shares"
  [expendedTemplate]="expendablePermissionContent"
  [addPaddingOnFirst]="false"
  [isSelectingOnlyOneAtATime]="false"
  [isCustomSorting]="true"
  [sort]="sort"
  [direction]="direction"
  (handleCustomSort)="handleSorting($event.sortColumn, $event.sortDirection)"
>
</ui-static-table>

<ng-template
  #expendablePermissionContent
  let-permission="row"
  let-isSelect="isSelect"
>
  <share-details
    [sharedFilePermission]="permission"
    [isSelect]="isSelect"
    [fileCreationDateLimit]="fileCreationDateLimit"
  ></share-details>
</ng-template>
