<div class="content">
  <div class="description-box">
    <p>{{info.description}}</p>
    <p
      class="invalid"
      *ngIf="info.errorDescription"
    >{{info.errorDescription}}</p>
  </div>

  <div class="spinner link-container" *ngIf="isLinkLoading">
    <ui-spinner size="medium"></ui-spinner>
  </div>
  <div class="link-container" *ngIf="link">
    <span class="title">{{'files.drawer.tabs.details.location' | t}}</span>
    <a class="link" target="_blank" [title]="link" [href]="link">
      <span class="text">{{info.isGroup ? link : 'https://teams.microsoft.com/l/team/...'}}</span>
      <ui-icon
        class="openLink"
        name="open-new"
        size="small"
        modifier="on-header"
        [title]="'files.drawer.tabs.details.link.open' | t"
      ></ui-icon>
    </a>
  </div>

  <div class="protection-section">
    <span>{{'shares.gestions.proactif' | t}}</span>
    <div class="item-container">
      <ng-container *ngFor="let criteria of info.criterias">
        <div class="item">
          <div class="left-side">
            <div class="img-container">
              <div class="img-wrapper">
                <img [src]="criteria.iconSrc">
              </div>
            </div>
            <p>{{criteria.name}}</p>
          </div>
          <div class="right-side">
            <img
              *ngIf="criteria.showValidityIcon"
              [src]="criteria.criteriaIcon"
            >
            <p
              [class.description-criteria]="hideDescriptionInMobile"
              [class.invalid]="!criteria.validity"
              [class.clickable]="criteria.isClickable"
              (click)="onClick(criteria.type)"
              [tooltip]="criteria.tooltipText"
            >{{criteria.description}}</p>
            <button
              class="invisible"
              *ngIf="criteria.copyButton.show"
              (click)="copy(criteria.description)"
            >
              <img
                [tooltip]="criteria.copyButton.text"
                class="copy"
                src="assets/favicons/icon_copy.svg"
              >
            </button>

          </div>
          <div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
<ng-template #spinner>
  <div class="spinner">
    <ui-spinner size="medium"></ui-spinner>
  </div>
</ng-template>
