import { Clipboard } from '@angular/cdk/clipboard';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { GroupsApiService } from '@common/services/api/sg/groups/groupsApi.service';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { Notice, NoticeLevels, NoticeService } from 'projects/@common/modules/notice/notice.service';
import { ResourceCard } from '../../../pages/teams-resources/services/resourceCard';
import { DropdownActions } from '../dialogs/resources-dialogs/group-actions/dropdownActions.enum';

@Component({
  selector: 'sg-resource-card-content',
  templateUrl: './resource-card-content.component.html',
  styleUrls: [ './resource-card-content.component.scss' ],
})
export class ResourceCardContentComponent implements OnInit, OnChanges {
  @Input() public info: ResourceCard;
  @Input() public hideDescriptionInMobile = true;
  @Input() public isOpen = false;
  @Output() click: EventEmitter<DropdownActions> = new EventEmitter();

  public link: string;
  public isLinkLoading = false;

  constructor(
    private readonly clipboard: Clipboard,
    private i18nService: I18nService,
    private noticeService: NoticeService,
    private groupsApiService: GroupsApiService
  ) {
  }

  ngOnInit(): void {
  }

  ngOnChanges() {
    if (this.isOpen && !this.link) {
      this.isLinkLoading = true;
      this.groupsApiService.getWebUrl(this.info.resourceId)
        .then((result) => {
          this.link = result?.url;
        })
        .finally(() => this.isLinkLoading = false);
    }
  }

  public copy(text: string): void {
    this.clipboard.copy(text);
    this.noticeService.notifyUser(new Notice(this.i18nService.translate('devices.gestion.device_id.copy_text.confirmation'), NoticeLevels.SNACK));
  }

  public onClick(actionType: DropdownActions): void {
    this.click.emit(actionType);
  }
}
