<div class="drawer-detail--scrollable tab-menu hidden">
  <div class="drawer-detail--content file-details">
    <ui-spinner *ngIf="isLoading" size="medium" modifier="secondary"></ui-spinner>
    <button
      *ngIf="!isLoading && !mobileService.isMobile(1000)"
      class="actions"
      (click)="filesListService.onAction(file)"
      color="linkFlat"
      ui-button
      [custom-icon]="true"
      icon="icon_pause_file"
      [isSvg]="true"
    >
      {{ 'sensitiveFile.takeAction' | t }}
    </button>

    <ng-container *ngIf="!isLoading">
      <ui-title [name]="'files.drawer.tabs.details.properties' | t"></ui-title>
      <label ui-label class="margin--small--top">{{ 'files.drawer.tabs.details.name' | t }}</label>

      <div class="file-details--name">
        <img class="icon-image" [src]="getFileIcon(file.name)" />
        <span class="location">{{ file.name }}</span>
      </div>

      <label ui-label class="margin--small--top">{{ 'files.drawer.tabs.details.location' | t }}</label>
      <div class="file-details--name">
        <img class="icon-image" [src]="getFileIcon('')" />
        <span class="location">{{ file.path }}</span>
      </div>

      <div class="margin--small--top">
        <ui-label class="magin--small--top ui-label">
          {{ 'sensitiveFile.drawer.risk.label' | t }}
        </ui-label>
        <risk-badge [resource]="file"></risk-badge>
      </div>

      <div class="flex-box center-horizontal margin--small--top">
        <ui-static [label]="'sensitiveFile.type' | t" [value]="dataTypes" [allowLineBreak]="true"></ui-static>
      </div>

      <div class="flex-box center-horizontal margin--small--top">
        <ui-static
          [label]="'files.drawer.tabs.details.createdBy' | t"
          [value]="file?.fullName ? file.fullName : ('sensitiveFile.unknown.creator' | t)"
        ></ui-static>

        <ui-static
          [label]="'files.drawer.tabs.details.createdDate' | t"
          [value]="file?.createdAt | dt: { locale: i18n.currentLocale }"
        ></ui-static>
      </div>

      <div class="flex-box center-horizontal margin--small--top">
        <ui-static
          [label]="'common.lastScanned' | t"
          [value]="file?.lastScanned | dt: { locale: i18n.currentLocale }"
        ></ui-static>
      </div>
    </ng-container>
  </div>
</div>
