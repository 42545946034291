import { Component, Input, OnChanges } from '@angular/core';
import { RequestTypeEnum } from 'projects/@common/services/api/requests/requests.definitions';
import { MobileService } from 'projects/@common/services/mobile.service';

@Component({
  selector: 'request-details',
  templateUrl: './request-details.component.html',
  styleUrls: [ './request-details.component.scss' ],
})
export class RequestDetailsComponent implements OnChanges {
  @Input() public request: any;

  @Input() public hasNotBeenProcessed = false;

  @Input() public isLoading = false;

  public requestDetails: any;
  public requestTypeEnum = RequestTypeEnum;

  constructor(public mobileService: MobileService) {
  }

  public ngOnChanges() {
    this.setVariables();
  }

  private setVariables() {
    if (this.request) {
      this.requestDetails = this.request.requestInformation;
    }
  }
}
