<div [ngStyle]="{ display: 'flex' }">
  <button ui-button icon="icon_add_circle" *ngIf="hasResourceApprovers" [custom-icon]="true" [isSvg]="true" (click)="createResource()">
    <ng-container *ngIf="!mobileService.isMobile(900)">
      {{ 'groups.dialog.create.title' | t }}
    </ng-container>
  </button>

  <app-edit-with-modal #editor>
    <button ui-button [disabled]="resources.length === 0" [color]="'lightGreyFlat'" (click)="toggle()">
      {{ 'teams.resources.quick.actions' | t }}
      <img alt="edit-icon" src="assets/favicons/icon_chevron_down.svg" />
    </button>
    <div content>
      <span class="subButton border">
        <img src="assets/favicons/icon_paper.svg" />
        <button (click)="openQuickActionDialog(ownerGroupActionType.securityModel)">
          <div>
            <p>{{ 'group.action.remediation.button.model' | t }}</p>
            <p class="subButton--description">{{ 'dashboard.md-eco.quickAction.option.7.description' | t }}</p>
          </div>
        </button>
      </span>
      <span class="subButton border">
        <img src="assets/favicons/icon_certifier.svg" />
        <button (click)="openQuickActionDialog(ownerGroupActionType.certifyAccesses)">
          <div>
            <p>{{ 'group.action.remediation.button.certify' | t }}</p>
            <p class="subButton--description">{{ 'dashboard.md-eco.quickAction.option.8.description' | t }}</p>
          </div>
        </button>
      </span>
      <span class="subButton" [ngClass]="{'border': hasArchiver}">
        <img src="assets/favicons/icon_calendar.svg" />
        <button (click)="openQuickActionDialog(ownerGroupActionType.updateExpirationDate)">
          <div>
            <p>{{ 'group.action.remediation.button.date' | t }}</p>
            <p class="subButton--description">{{ 'dashboard.md-eco.quickAction.option.9.description' | t }}</p>
          </div>
        </button>
      </span>
      <span class="subButton" *ngIf="hasArchiver">
        <img src="assets/favicons/icon_archive_pale_grey.svg" />
        <button (click)="openQuickActionDialog(ownerGroupActionType.archive)">
          <div>
            <p>{{ 'group.action.remediation.button.archive' | t }}</p>
            <p class="subButton--description">{{ 'dashboard.md-eco.quickAction.option.10.description' | t }}</p>
          </div>
        </button>
      </span>
    </div>
  </app-edit-with-modal>
</div>
