import { Injectable } from '@angular/core';

interface IUnexpandableRowsValidator<T> {
  (element: T): boolean;
}

@Injectable({
  providedIn: 'root',
})
export class UiStaticTableMapperService {
  constructor() {}

  public setUnexpandableRows<T>(data: (T & { __isNotExpandable?: boolean, __selected?: boolean })[], validator: IUnexpandableRowsValidator<T>, openNewlyExpandable: boolean = false) {
    let newSelected = -1;
    data.forEach((element, index) => {
      const original = element.__isNotExpandable;
      element.__isNotExpandable = validator(element);

      const select = (
        openNewlyExpandable &&
        original === true &&
        !element.__selected &&
        !element.__isNotExpandable
      );
      if (select) {
        newSelected = index;
        element.__selected = true;
      } else {
        element.__selected = element.__selected && !element.__isNotExpandable;
      }
    });

    if (newSelected >= 0) {
      data.forEach((element, index) => {
        element.__selected = index === newSelected;
      });
    }
  }
}
