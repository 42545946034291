<div class="title-wrapper">
  <p class="title">{{ 'shares.description.title' | t }}</p>
  <img src="assets/favicons/icon_protection.svg" />
</div>
<p class="text bold">{{ 'shares.description.text.subtext1' | t }}</p>
<p class="subtitle">{{ 'shares.description.text.indicators.subtitle' | t }}</p>
<div class="list">
  <div class="listElement">
      <ui-severity-chip
        [severity]="'disaster'"
        [customText]="'shares.shareRiskScore.high' | t"
      ></ui-severity-chip>
  <p class="list-element-description">{{ 'shares.description.text.indicators.high.text' | t }}</p>
  </div>

  <div class="listElement">
      <ui-severity-chip
        [severity]="'high'"
        [customText]="'shares.shareRiskScore.moderate' | t"
      ></ui-severity-chip>
  <p class="list-element-description">{{ 'shares.description.text.indicators.medium.text' | t }}</p>
  </div>

  <div class="listElement">
      <ui-severity-chip
        [severity]="'ok'"
        [customText]="'shares.shareRiskScore.low' | t"
      ></ui-severity-chip>
  <p class="list-element-description">{{ 'shares.description.text.indicators.low.text' | t }}</p>
  </div>
</div>

<p class="subtitle">{{ 'shares.description.text.learn.subtitle' | t }}</p>

<collapsable-description
  [isSmall]="true"
  [icon]="'assets/favicons/icon_odometre.svg'"
  [title]="'shares.description.text.learn.criterias' | t"
>
  <p class="preamble">{{ 'shares.description.text.learn.list.preamble' | t: { amount: labelAmount } }}</p>
  <div class="bullet-points">
    <ul>
      <li>{{ 'shares.description.text.learn.list.item1' | t }}</li>
      <li>{{ 'shares.description.text.learn.list.item2' | t }}</li>
      <li>{{ 'shares.description.text.learn.list.item3' | t }}</li>
      <li>{{ 'shares.description.text.learn.list.item4' | t }}</li>
      <li *ngIf="hasLabels">{{ 'shares.description.text.learn.list.item5' | t }}</li>
    </ul>
  </div>
</collapsable-description>

<collapsable-description [isSmall]="true" [icon]="'assets/favicons/icon_permissions_cropped.svg'" [title]="'Autres options'">
  <div class="text other-options">
    <p>
      {{ 'shares.description.text.learn.more.notif.1' | t }}
      <img class="inline-icon bell" src="assets/favicons/icon_deactivate_notification_green_cropped.svg" />
      {{ 'shares.description.text.learn.more.notif.2' | t }}
    </p>

    <p>
      {{ 'shares.description.text.learn.more.external.1' | t }}
      <img class="inline-icon time" src="assets/favicons/icon_guest_user_cropped.svg" />
      {{ 'shares.description.text.learn.more.external.2' | t }}
    </p>
  </div>
</collapsable-description>

<!-- <a href="http://google.ca" class="outside-link"> -->
<!--   <p class="subtitle"> {{'common.learn.more' | t }} </p> -->
<!--   <img src="assets/favicons/icon_external_list.svg"> -->
<!-- </a> -->
