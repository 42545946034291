import { Component, Input, OnInit } from '@angular/core';

export enum ProfileCardColorEnum {
  green = 'green',
  yellow = 'yellow',
  blue = 'blue',
  white = 'white',
  paleBlue = 'paleBlue'
}

@Component({
  selector: 'sg-eco-profile-card',
  templateUrl: './eco-profile-card.component.html',
  styleUrls: [ './eco-profile-card.component.scss' ],
})
export class EcoProfileCardComponent implements OnInit {
  @Input() cardColor: ProfileCardColorEnum = ProfileCardColorEnum.white;

  constructor () { }

  ngOnInit(): void {
  }
}
