import { Component, Input, OnInit } from '@angular/core';
import { FileTypeEnum, IconUtil } from '@ui-kit/util/icon-util';
import { SharedFileDrawer } from 'projects/@assist-ops/containers/drawer/shared-file-drawer/shared-file-drawer.container';
import { DrawerPageEnum } from 'projects/@common/modules/layout/components/drawer/drawerPage.enum';
import { DrawerService } from 'projects/@common/modules/layout/components/drawer/services/drawer.service';
import { ISharedFile } from 'projects/@common/services/api/sg/shares/shares.definitions';

@Component({
  selector: 'shared-file-mobile',
  templateUrl: './shared-file-mobile.component.html',
  styleUrls: [ './shared-file-mobile.component.scss' ],
})
export class SharedFileMobileComponent implements OnInit {
  @Input() isHeader: boolean;

  @Input() public sharedFile: ISharedFile;

  constructor (private drawerService: DrawerService) { }

  ngOnInit(): void {
  }

  public getOfficeIcon(file: ISharedFile): string {
    const extension = file?.name.substring(file?.name.lastIndexOf('.') + 1, file?.name.length);
    if (extension === file?.name) {
      return IconUtil.getFileIconFromFileExtension(null);
    }
    return IconUtil.getFileIconFromFileExtension(extension);
  }

  public handleRowClick(file: ISharedFile) {
    this.drawerService.replaceCurrentDrawer(SharedFileDrawer, {
      component: DrawerPageEnum.userFilesDrawer,
      data: {
        fileType: file.fileType || FileTypeEnum.FILE,
        fileId: file.id,
        title: `${file.name}`,
        risk: file.risk,
      },
    });
  }
}
