import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { I18nService } from '@common/modules/i18n/i18n.service';
import { GroupTemplatesApiService } from '@common/services/api/sg/group-templates/group-templates-api.service';

@Component({
  selector: 'group-creation-details',
  templateUrl: './group-creation-details.component.html',
  styleUrls: [ './group-creation-details.component.scss' ],
})
export class GroupCreationDetailsComponent implements OnInit {
  @Input() public groupCreationDetails: any;

  @Input() public canEditOwner = false;

  @Input() public isEditing = false;

  public detailsForm: UntypedFormGroup;

  public owners: any[];
  public securityModelName: string;

  constructor(
    public readonly i18n: I18nService,
    private templatesService: GroupTemplatesApiService
  ) {
  }

  public ngOnInit(): void {
    this.owners = this.groupCreationDetails.owners;
    this.createGroupCreationDetailsForm();
    this.templatesService.getEcoGroupTemplate(this.groupCreationDetails.templateId)
      .then((response) => this.securityModelName = response.name);
  }

  private createGroupCreationDetailsForm() {
    this.detailsForm = new UntypedFormGroup({
      name: new UntypedFormControl(this.groupCreationDetails.name, Validators.required),
      description: new UntypedFormControl(this.groupCreationDetails.description, Validators.required),
      owners: new UntypedFormControl(this.groupCreationDetails.owners, Validators.required),
    });
  }
}
