import { Component, ElementRef, HostListener, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UsersApiService } from '@common/services/api/sg/user/userApi.service';
import { Select } from '@ngxs/store';
import { RevokeSharesDialogComponent } from 'projects/@assist-ops/components/revoke-shares-dialog/revoke-shares-dialog.component';
import { DisplayService } from 'projects/@common/modules/display/display.service';
import { FeatureFlagsEnum } from 'projects/@common/modules/layout/definitions/base-sidebar.service';
import { EcoSessionState } from 'projects/@common/modules/session/state/session.state';
import { MobileService } from 'projects/@common/services/mobile.service';
import { InviteGuestUserQuickActionComponent } from 'projects/console-sg/src/app/shared/components/dialogs/guest-users-dialogs/invite-guest-user-quickAction/invite-guest-user-quickAction.component';
import { CreateResourceDialogComponent } from 'projects/console-sg/src/app/shared/components/dialogs/resources-dialogs/create-resource-dialog/create-resource-dialog.component';
import { GroupQuickActionDialogComponent, OwnerGroupActionType } from 'projects/console-sg/src/app/shared/components/dialogs/resources-dialogs/groupe-quick-action-dialog/group-quick-action-dialog.component';
import { Observable } from 'rxjs';

export class Action {
  id: number;
  flagRequired: FeatureFlagsEnum[];
  disabled: boolean;
  value: string;
  show: boolean;
  action?: Function;

  constructor(id: number, flagRequired: FeatureFlagsEnum[], disabled: boolean, action: Function) {
    this.id = id;
    this.flagRequired = flagRequired;
    this.disabled = disabled;
    this.action = action;
  }
}

@Component({
  selector: 'sg-quick-actions-selector', templateUrl: './quick-actions-selector.component.html', styleUrls: ['./quick-actions-selector.component.scss'],
})
export class QuickActionsSelectorComponent implements OnInit, OnChanges {
  @Input() public groupAmount = null;
  @Select(EcoSessionState.user) public currentUser$: Observable<string>;
  public isOpen = false;
  public hasResourceApprovers = false;
  public hasAvailableApprovers: false;
  public currentUser: any; /* IUser */
  public options: Action[] = [
    new Action(1, [FeatureFlagsEnum.SHARES_MANAGEMENT], !this.isOnlyShares, () => this.router.navigate(['/myshares'])),
    new Action(
      2, [FeatureFlagsEnum.SHARES_MANAGEMENT], !this.isOnlyShares, () => this.router.navigate(['/myshares'], { queryParams: { tabIndexSelected: 1 } })),
    new Action(3, [FeatureFlagsEnum.SHARES_MANAGEMENT], false, () => this.revokeShares()),
    new Action(4, [FeatureFlagsEnum.RESOURCE_MANAGEMENT], true, () => this.createGroup()),
    new Action(5, [FeatureFlagsEnum.RESOURCE_MANAGEMENT], true, () => this.router.navigate(['/teamsResources'])),
    new Action(6, [FeatureFlagsEnum.RESOURCE_MANAGEMENT], false, () => this.applySecurityModel()),
    new Action(7, [FeatureFlagsEnum.RESOURCE_MANAGEMENT], true, () => this.certifyAccesses()),
    new Action(8, [FeatureFlagsEnum.RESOURCE_MANAGEMENT], true, () => this.updateExpirationDate()), // Gérer l'expiration
    new Action(9, [FeatureFlagsEnum.RESOURCE_MANAGEMENT], true, () => this.archiveGroup()), // Archiver une resources
    new Action(10, [], false, () => this.router.navigate(['/requests'])), // Voir la conformité de mes resourcess
    new Action(11, [FeatureFlagsEnum.GUESTS_MANAGEMENT], true, () => this.inviteGuestUser()), // Ajouter un utilisteur invité
  ];
  @Input() private hasArchiver = false;

  constructor(
    private eRef: ElementRef,
    public dialog: MatDialog,
    private usersApiService: UsersApiService,
    private router: Router,
    private mobileService: MobileService,
    private displayService: DisplayService
  ) {
  }

  public get isOnlyShares(): boolean {
    return this.displayService.meetsRequirements({ flags: [FeatureFlagsEnum.SHARES_MANAGEMENT] }) &&
      !this.displayService.meetsRequirements({ flags: [FeatureFlagsEnum.RESOURCE_MANAGEMENT] }) &&
      !this.displayService.meetsRequirements({ flags: [FeatureFlagsEnum.GUESTS_MANAGEMENT] });
  }

  public ngOnInit(): void {
    this.currentUser$.subscribe((currentUser) => this.currentUser = currentUser);
    this.usersApiService.listResourceApprovers({ usersOnly: true }).then((response) => {
      this.hasResourceApprovers = !!response?.items?.length;
      if (this.hasResourceApprovers) {
        this.options[3].disabled = false;
      }
    });
    this.usersApiService.listGuestManagers({ forInvitation: true, filter: "CURRENT", limit: 999 }).then(response => {
      this.hasAvailableApprovers = response.items.filter(manager => manager.id !== this.currentUser.oid).length;
      if (this.hasAvailableApprovers) {
        this.options[10].disabled = false;
      }
    });
  }

  ngOnChanges(simpleChange: SimpleChanges): void {
    this.groupAmount = simpleChange.groupAmount?.currentValue;
    this.options[5].disabled = this.groupAmount?.total === 0;
    this.options[6].disabled = this.groupAmount?.total === 0;
    this.options[7].disabled = this.groupAmount?.total === 0;
    this.options[8].disabled = this.groupAmount?.total === 0 || !this.hasArchiver;
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent): void {
    if (this.isOpen) {
      this.toggle();
    }
  }

  @HostListener('document:click', ['$event'])
  clickOut(event: any) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      if (this.isOpen) {
        this.isOpen = false;
      }
    }
  }

  public toggle(): void {
    this.isOpen = !this.isOpen;
    this.resetShow();
  }

  public onMouseEnter(option: Action): void {
    this.resetShow();
    option.show = true;
  }

  public onMouseOut(option: Action): void {
    option.show = false;
  }

  public getNoHeightOnDescription(index: number): boolean {
    return index > this.options.findIndex((option) => option.show);
  }

  public onClick(option: Action): any {
    return option.action();
  }

  private resetShow(): void {
    this.options.forEach((value) => value.show = false);
  }

  private createGroup(): void {
    this.dialog.open(CreateResourceDialogComponent, {
      maxWidth: !this.mobileService.isMobile() ? '75%' : '100%', width: '50vw', maxHeight: '85vh', panelClass: 'custom-dialog',
      minWidth: '730px'
    });
    this.toggle();
  }

  private revokeShares(): void {
    this.dialog.open(RevokeSharesDialogComponent, {
      maxWidth: '50vw', maxHeight: '85vh', panelClass: 'custom-dialog',
    });
    this.toggle();
  }

  private inviteGuestUser(): void {
    this.dialog.open(InviteGuestUserQuickActionComponent, {
      minHeight: '40%', maxWidth: '50%', panelClass: 'custom-dialog'
    });
    this.toggle();
  }

  private certifyAccesses(): void {
    this.dialog.open(GroupQuickActionDialogComponent, {
      minWidth: '660px',
      maxHeight: '85vh', panelClass: 'custom-dialog', data: {
        type: OwnerGroupActionType.certifyAccesses,
        dialogTitle: 'certify.title.noGroup',
        dialogSubtitle: 'certify.second.title',
        dialogDescription: 'certify.group.selection',
      },
    });
    this.toggle();
  }

  private applySecurityModel(): void {
    this.dialog.open(GroupQuickActionDialogComponent, {
      minWidth: '730px',
      maxWidth: !this.mobileService.isMobile() ? '75%' : '100%', maxHeight: '85vh', panelClass: 'custom-dialog', data: {
        type: OwnerGroupActionType.securityModel,
        dialogTitle: 'group.action.remediation.button.model',
        dialogSubtitle: 'quickAction.model.title.description',
        dialogDescription: 'quickAction.model.subtitle',
      },
    });
    this.toggle();
  }

  private updateExpirationDate(): void {
    this.dialog.open(GroupQuickActionDialogComponent, {
      minWidth: '730px',
      maxWidth: !this.mobileService.isMobile() ? '75%' : '100%', width: '50vw', maxHeight: '85vh', panelClass: 'custom-dialog', data: {
        type: OwnerGroupActionType.updateExpirationDate,
        dialogTitle: 'group.action.remediation.button.date',
        dialogSubtitle: 'quickAction.model.title.description',
        dialogDescription: 'quickAction.date.subtitle',
      },
    });
    this.toggle();
  }

  private archiveGroup(): void {
    this.dialog.open(GroupQuickActionDialogComponent, {
      minWidth: '730px',
      maxWidth: !this.mobileService.isMobile() ? '75%' : '100%', width: '50vw', maxHeight: '85vh', panelClass: 'custom-dialog', data: {
        type: OwnerGroupActionType.archive,
        dialogTitle: 'group.action.remediation.button.archive',
        dialogSubtitle: 'quickAction.model.title.description',
        dialogDescription: 'quickAction.archive.subtitle',
      },
    });
    this.toggle();
  }
}
