import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { Store } from '@ngxs/store';
import { UiTableDirection } from '@ui-kit/components/ui-table/ui-table.component';
import { RequestStatusEnum } from '@ui-kit/util/icon-util';
import { TableStatus } from 'projects/@common/modules/directives/tables-manager.directive';
import { StaticTableDataMapper } from 'projects/@common/modules/i18n/component-wrapper/static-table-data-mapper.service';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { DrawerPageEnum } from 'projects/@common/modules/layout/components/drawer/drawerPage.enum';
import { DrawerService } from 'projects/@common/modules/layout/components/drawer/services/drawer.service';
import { RequestsApiService } from 'projects/@common/services/api/requests/requests-api.service';
import { RequestList } from 'projects/@common/services/api/requests/requests.definitions';
import { RequestDrawerContainer } from '../../containers/request-drawer/request-drawer.container';
import { ListCategoryType } from '../../containers/requests.container';
import { RequestsTableProcessNumber } from './requests-table-process-number/requests-table-process-number.component';

@Component({
  selector: 'requests-collapsable-table',
  templateUrl: './requests-collapsable-table.component.html',
  styleUrls: ['./requests-collapsable-table.component.scss'],
  host: {
    class: 'requests-collapsable-table',
  },
  encapsulation: ViewEncapsulation.None,
})
export class RequestsCollapsableTable implements OnInit {
  @Input() tableTitle = '';
  @Input() isCollapsed = false;
  @Input() headerIcon: string;
  @Input() columnDef: any[] = [];
  @Input() statusesFilter: RequestStatusEnum;
  @Input() categoryFilter: ListCategoryType;
  @Input() fulltext: string;
  @Input() showToProcess = false;
  @Output() onStatusUpdate: EventEmitter<TableStatus> = new EventEmitter();
  @Output('checkbox-event') public checkboxEventEmitter: EventEmitter<any> =
    new EventEmitter<any>();
  public requests: RequestList[] = [];
  public isLoading = false;
  public isLoadingMore = false;
  public isSorting = false;
  public canLoadMore = false;
  public sort = 'createdAt';
  public direction = UiTableDirection.Desc;
  public requestsToProcess = 0;
  public total = 0;
  public toProcessTableTitle: any;
  private readonly LIMIT = 25;
  private nextToken = 0;

  constructor(
    private requestsService: RequestsApiService,
    private drawerService: DrawerService,
    private cdr: ChangeDetectorRef,
    public i18n: I18nService,
    private store: Store,
    private staticTableDataMapper: StaticTableDataMapper
  ) { }

  public ngOnInit(): void {
    this.setStatusUpdate(true, 0);
    this.loadRequests();
  }

  public handleSort(sortParams: any) {
    this.sort = sortParams.sortColumn;
    this.direction = sortParams.sortDirection;
    this.nextToken = 0;
    this.requests = [];
    this.isSorting = true;
    this.isLoading = true;
    this.loadRequests();
  }

  public handleLoadMore() {
    this.isLoadingMore = true;
    this.loadRequests();
  }

  public setStatusUpdate(isLoading: boolean, total: number) {
    this.isLoading = isLoading;
    this.onStatusUpdate.emit({ isLoading, total });
  }

  public handleRowClick(request: RequestList) {
    this.drawerService.replaceCurrentDrawer(RequestDrawerContainer, {
      component: DrawerPageEnum.requestDrawer,
      data: {
        title: request.description,
        number: request.number,
        requestId: request.id,
      },
    });
  }

  private loadRequests() {
    const sort =
      this.sort === 'description'
        ? `description_${this.i18n.currentLocale}`
        : this.sort;
    this.requestsService
      .getRequests({
        language: this.i18n.currentLocale,
        from: this.nextToken,
        limit: this.LIMIT,
        status: this.statusesFilter,
        fullText: this.fulltext,
        category: this.categoryFilter,
        sort,
        direction: this.direction,
      })
      .then((requests) => {
        this.requests = this.requests.concat(requests.items.map((request) => {
          request.requesterName = request.requesterName
            ? request.requesterName
            : this.i18n.translate('common.system');
          return request;
        }));
        this.nextToken = +requests.nextToken;
        this.total = requests.total;
        this.canLoadMore = requests.total > this.nextToken;
        this.requestsToProcess = requests.toProcessNumber;

        if (
          (!this.statusesFilter && !this.categoryFilter) ||
          this.statusesFilter === RequestStatusEnum.PENDING
        ) {
          this.toProcessTableTitle = {
            component: RequestsTableProcessNumber,
            paramName: 'number',
            item: requests.toProcessNumber,
          };
        }

        this.setStatusUpdate(false, this.total);

        setTimeout(() => {
          this.cdr.detectChanges();
        });
      })
      .catch(() => {
        this.canLoadMore = false;
        this.setStatusUpdate(false, 0);
      })
      .finally(() => {
        this.isSorting = false;
        this.isLoadingMore = false;
        this.isLoading = false;
        [this.columnDef, this.requests] = this.staticTableDataMapper.translate(
          this.columnDef,
          this.requests
        );
      });
  }
}
