<widget-container
  class="large-widget"
  [title]="'dashboard.resourcesMonitoring.title' | t"
  [subtitle]="'dashboard.resourcesMonitoring.subtitle' | t"
  [withBottomMargin]="isMobile"
>
  <div
    *ngIf="!isLoading && stats"
    class="my-shared-assets--content"
  >
    <div
      *ngIf="!isMobile"
      class="my-shared-assets--content--left"
    >
      <div class="my-shared-assets--content--table">
        <ng-container *ngTemplateOutlet="chips"></ng-container>

        <ui-static-table
          *ngIf="!isLoadingTable"
          [dataSource]="stats.topResourcesStats"
          [columnsDef]="columnDef"
          [addPaddingOnFirst]="false"
          [hoverable]="false"
          [smallRow]="true"
          [canSelectItems]="false"
          [useEmptyMessage]="false"
        ></ui-static-table>

        <div
          *ngIf="!stats.topResourcesStats.length && !isLoadingTable"
          class="my-shared-assets--content--table--empty"
        >
          {{
          'dashboard.resourcesMonitoring.table.empty'
          | t
          : {
          level: (
          'shares.shareRiskScore.' + riskFilter.toLowerCase() | t
          ).toLowerCase()
          }
          }}
        </div>

        <div
          *ngIf="stats.remainingResourcesCount && !isLoadingTable"
          class="my-shared-assets--content--remaining-assets"
        >
          +{{ stats.remainingResourcesCount }}
        </div>

        <div *ngIf="isLoadingTable">
          <ui-spinner></ui-spinner>
        </div>
      </div>

      <div class="my-shared-assets--content--manage">
        <ui-anchor
          [label]="'dashboard.resourcesMonitoring.manage' | t"
          (onClick)="handleManageClick()"
        ></ui-anchor>
      </div>
    </div>

    <div
      *ngIf="!isMobile"
      class="my-shared-assets--content--right"
    >
      <div class="my-shared-assets--content--chart">
        <dynamic-chart
          [locale]="i18n.currentLocale"
          [data]="riskProportionsData"
          name="riskProportions"
          type="DonutChart"
          key="level"
          [series]="['count']"
          [innerSeriesLabel]="{ count: stats.highCount + stats.moderateCount + stats.lowCount }"
          pieChartInnerRadius="60"
          legendTemplate="{name} {value} ({value.percent}%)"
        ></dynamic-chart>
      </div>

      <div class="my-shared-assets--content--explanation">
        {{ 'dashboard.resourcesMonitoring.description' | t }}
      </div>
    </div>

    <div
      *ngIf="isMobile"
      class="my-shared-assets--content--mobile"
    >
      <ng-container *ngTemplateOutlet="chips"></ng-container>
      <div
        *ngIf="isAutomaticRevokeActivated && riskFilter === fileRiskEnum.HIGH"
        class="my-shared-assets--content--mobile--warning"
      >
        <img src="assets/favicons/breaktime/icon_revoke_warning.svg" />
        <div>{{ 'dashboard.resourcesMonitoring.revoke.warning' | t }}</div>
      </div>
      <div class="my-shared-assets--content--mobile--data-title">
        <div class="my-shared-assets--content--mobile--data-title--title">
          {{
          'dashboard.resourcesMonitoring.mobile.data.title.' +
          riskFilter.toLowerCase() | t
          }}
        </div>
        <ui-tooltip
          *ngIf="riskFilter === fileRiskEnum.HIGH"
          [isSmall]="true"
        >
          {{ 'dashboard.resourcesMonitoring.table.revokeDate.tooltip' | t }}
        </ui-tooltip>
      </div>
      <div
        *ngIf="!isLoadingTable && stats.topResourcesStats.length"
        class="my-shared-assets--content--mobile--data"
      >
        <div
          *ngFor="let resource of stats.topResourcesStats"
          class="my-shared-assets--content--mobile--data--resource"
        >
          <div class="my-shared-assets--content--mobile--data--name">
            {{ resource.resource }}
          </div>
          <div
            *ngIf="resource.nearestRevokeDate"
            class="my-shared-assets--content--mobile--data--revoke-date"
          >
            {{ 'dashboard.resourcesMonitoring.mobile.data.revoke' | t }}
            {{ resource.nearestRevokeDate | dt: {locale: i18n.currentLocale} }}
          </div>
        </div>
        <div
          *ngIf="stats.remainingResourcesCount && !isLoadingTable"
          class="my-shared-assets--content--mobile--data--resource"
        >
          +{{ stats.remainingResourcesCount }}
        </div>
      </div>
      <ng-container *ngTemplateOutlet="noData"></ng-container>
      <ui-spinner *ngIf="isLoadingTable"></ui-spinner>
      <div
        class="my-shared-assets--content--mobile--manage"
        (click)="handleManageClick()"
      >
        {{ 'dashboard.resourcesMonitoring.manage' | t }}
      </div>
    </div>
  </div>

  <div
    *ngIf="isLoading"
    class="my-shared-assets--loading"
  >
    <ui-spinner></ui-spinner>
  </div>
</widget-container>

<ng-template #chips>
  <div class="my-shared-assets--content--chips">
    <ui-severity-chip
      [ngClass]="{
        'my-shared-assets--content--chips--gray':
          riskFilter !== fileRiskEnum.HIGH
      }"
      [severity]="riskFilter === fileRiskEnum.HIGH ? 'disaster' : 'na'"
      [customText]="
        ('shares.shareRiskScore.high' | t) + ' (' + stats.highCount + ')'
      "
      [isInvertedColor]="riskFilter !== fileRiskEnum.HIGH"
      (click)="handleRiskFilter(fileRiskEnum.HIGH); $event.stopPropagation()"
    ></ui-severity-chip>
    <ui-severity-chip
      [ngClass]="{
        'my-shared-assets--content--chips--gray':
          riskFilter !== fileRiskEnum.MODERATE
      }"
      [severity]="riskFilter === fileRiskEnum.MODERATE ? 'high' : 'na'"
      [customText]="
        ('shares.shareRiskScore.moderate' | t) +
        ' (' +
        stats.moderateCount +
        ')'
      "
      [isInvertedColor]="riskFilter !== fileRiskEnum.MODERATE"
      (click)="
        handleRiskFilter(fileRiskEnum.MODERATE); $event.stopPropagation()
      "
    ></ui-severity-chip>
    <ui-severity-chip
      [ngClass]="{
        'my-shared-assets--content--chips--gray':
          riskFilter !== fileRiskEnum.LOW
      }"
      [severity]="riskFilter === fileRiskEnum.LOW ? 'ok' : 'na'"
      [customText]="
        ('shares.shareRiskScore.low' | t) + ' (' + stats.lowCount + ')'
      "
      [isInvertedColor]="riskFilter !== fileRiskEnum.LOW"
      (click)="handleRiskFilter(fileRiskEnum.LOW); $event.stopPropagation()"
    ></ui-severity-chip>
  </div>
</ng-template>

<ng-template #noData>
  <div
    *ngIf="!isLoadingTable && !stats.topResourcesStats.length"
    class="my-shared-assets--content--no-data"
  >
    {{
    'dashboard.resourcesMonitoring.table.empty'
    | t
    : {
    level: (
    'shares.shareRiskScore.' + riskFilter.toLowerCase() | t
    ).toLowerCase()
    }
    }}
  </div>
</ng-template>
