<div
  *ngIf="
    request.status === requestStatusEnum.APPROVED ||
    request.status === requestStatusEnum.DECLINED ||
    request.status === requestStatusEnum.CANCELED ||
    request.status === requestStatusEnum.ERROR
  "
  [class]="'request-status-badge request-status-badge_' + request.status"
>
  {{ 'request.status.enum.' + request.status | t }}
</div>
