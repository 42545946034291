<div class="files-container">
  <div class="mobile-toolbar" *ngIf="mobileService.isMobile(1000)">
    <div class="tools-container">
      <ui-table-search-tool
        class="search-tool"
        [query]="filesListService.fulltext"
        [placeholder]="'ui.table-search-tool.placeholder' | t"
        (clear)="filesListService.handleSearchClear()"
        (query)="filesListService.handleSearchQuery($event)"
      ></ui-table-search-tool>

      <ui-table-refresh-tool
        class="tools"
        [loading]="filesListService.isLoading"
        (click)="filesListService.handleRefresh()"
      ></ui-table-refresh-tool>
    </div>
    <div class="total-tool">
      <ui-table-total-tool [total]="filesListService.total"></ui-table-total-tool>
    </div>
  </div>

  <div *ngFor="let data of filesListService.datasource; let first = first"
       [ngClass]="{ 'shared-files-mobile--content--first': first }">
    <div class="expendable-card-container">
      <sg-expendable-card>

        <div header class="header">
          <div class="left-side">
            <img class="shared-files-mobile--icon" [src]="filesListService.getFileIcon(data)"/>
            <div class="name">{{ data.name }}</div>
          </div>
          <risk-badge class="risk-badge" [resource]="data" [smallSize]="true"></risk-badge>
        </div>

        <div content>
          <p>Source : <span>{{ 'sensitive.sourceType.' + data.sourceType | t }}</span></p>
          <p>Types : <span>{{ data.patterns }}</span></p>
          <p> {{ 'common.lastScanned' | t }} : <span>{{ data.lastScanned | dt }}</span></p>
          <a (click)="filesListService.handleRowClick(data)">{{ 'dashboard.md-eco.myProfile.card.detailRedirect' | t }}</a>
        </div>

      </sg-expendable-card>
    </div>
  </div>


  <div *ngIf="!filesListService.isLoading && !filesListService.datasource?.length"
       class="shared-files-mobile--no-content">
    {{ "common.nomatch" | t }}
  </div>
</div>
