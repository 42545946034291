<ui-collapsable-table
  [headerIcon]="headerIcon"
  [tableTitle]="tableTitle | t"
  [dynamicTitle]="showToProcess ? toProcessTableTitle : null"
  [spaceBetweenDynamicTitle]="false"
  [numberItems]="total"
  [columnsDef]="columnDef"
  [dataSource]="requests"
  [isCollapsed]="isCollapsed"
  (onRowClick)="handleRowClick($event)"
  [canLoadMore]="canLoadMore && !isSorting"
  [isLoading]="isLoading"
  [isLoadingMore]="isLoadingMore"
  [isCustomSorting]="true"
  [locale]="i18n.currentLocale"
  (handleCustomSort)="handleSort($event)"
  [sort]="sort"
  [direction]="direction"
  (onLoadMore)="handleLoadMore()"
  [addPaddingOnFirst]="false"
></ui-collapsable-table>
