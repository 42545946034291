<div class="container">
  <div
    class="label-container"
    (click)="toggle()"
  >
    <ng-content select="[label]"></ng-content>
    <img [src]="
      'assets/favicons/icon_chevron_' +
      (isOpen ? 'open.svg' : 'close.svg')
    " />
  </div>
  <div
    class="content"
    [class.show]="isOpen"
  >
    <ng-content></ng-content>
  </div>
</div>