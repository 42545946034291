export interface IUserDevice {
  id: string;
  displayName: string;
  isCompliant: boolean;
  isManaged: boolean;
  accountEnabled: boolean;
  approximateLastSignInDateTime: number;
  createdDateTime: number;
  operatingSystem: any;
  operatingSystemVersion: string;
}

export enum MobileOsEnum {
  android = 'android',
  android_for_work = 'androidforwork',
  ios = 'ios',
  iphone_os = 'iphone os'
}
