import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UiKitModule } from '@ui-kit/uikit.module';
import { AssistOpsModule } from 'projects/@assist-ops/assist-ops.module';
import { I18nModule } from 'projects/@common/modules/i18n/i18n.module';
import { PipesModule } from 'projects/@common/modules/pipes/pipes.module';
import { FeatureSharedModule } from 'projects/@common/modules/shared/feature.shared.module';
import { SgSharedModule } from '../../shared/shared.module';
import { GroupCreationDetailsComponent } from './components/request-details/group-creation-details/group-creation-details.component';
import { RequestDetailsComponent } from './components/request-details/request-details.component';
import { ResourceAddDetailsComponent } from './components/request-details/resource-add-details/resource-add-details.component';
import { RequestFormProcessComponent } from './components/request-form/request-form-process/request-form-process.component';
import { RequestFormComponent } from './components/request-form/request-form.component';
import { RequestHistoryComponent } from './components/request-history/request-history.component';
import { RequestListMobileComponent } from './components/request-list-mobile/request-list-mobile.component';
import { RequestStatusBadgeComponent } from './components/request-status-badge/request-status-badge.component';
import { RequestsCollapsableTable } from './components/requests-collapsable-table/requests-collapsable-table.component';
import { RequestsTableProcessNumber } from './components/requests-collapsable-table/requests-table-process-number/requests-table-process-number.component';
import { RequestDrawerContainer } from './containers/request-drawer/request-drawer.container';
import { RequestsContainer } from './containers/requests.container';
import { RequestsRoutingModule } from './requests-routing.module';

@NgModule({
  declarations: [
    RequestDrawerContainer,
    RequestFormComponent,
    RequestDetailsComponent,
    RequestHistoryComponent,
    RequestsContainer,
    RequestsCollapsableTable,
    GroupCreationDetailsComponent,
    RequestsTableProcessNumber,
    RequestFormProcessComponent,
    RequestListMobileComponent,
    RequestStatusBadgeComponent,
    ResourceAddDetailsComponent
  ],
  providers: [],
  imports: [
    SgSharedModule,
    UiKitModule,
    CommonModule,
    RequestsRoutingModule,
    PipesModule,
    FormsModule,
    ReactiveFormsModule,
    FeatureSharedModule,
    AssistOpsModule,
    I18nModule,
  ],
  exports: [ RequestDrawerContainer, RequestStatusBadgeComponent ],
})
export class RequestsModule {}
