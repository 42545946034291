<ui-dialog
  *ngIf="showRevokeShareConfirmationDialog"
  [headtitle]="
    'sharedFile.drawer.shares.dialog.revoke.title.' +
    permissionToRemove.shareType?.toLowerCase()
  "
  actionButton="sharedFile.drawer.shares.dialog.button.ok"
  cancelButton="sharedFile.drawer.shares.dialog.button.cancel"
  (onButtonClick)="onRevokeShareConfirmation($event)"
  [isInDrawer]="true"
>
  {{ 'sharedFile.drawer.shares.dialog.revoke.message1' | t }}

  <br />
  <ul>
    <li *ngFor="let user of permissionToRemove.linkUsers">
      {{ user }}
    </li>
  </ul>

  {{ 'sharedFile.drawer.shares.dialog.revoke.message2' | t }}
</ui-dialog>
