import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EcoUrlService } from '../../core/url.service';

@Injectable({
  providedIn: 'root',
})
export class UserBehaviorApiService {
  private readonly prefix = '/sg/breaktime';
  private readonly host: string;

  constructor(private http: HttpClient, url: EcoUrlService) {
    this.host = url.baseUrl + this.prefix;
  }

  public async getBreaktimeTasks(): Promise<any> {
    const url = `${this.host}/profile`;
    return this.http.get<any>(url).toPromise();
  }
}
