<div class="container">
  <div class="container--top-section">
    <p class="title">{{ "dashboard.md-eco.myProfile.card.title" | t }}</p>
    <a routerLink="/ecoProfile">{{
      "dashboard.md-eco.myProfile.card.detailRedirect" | t
      }}</a>
  </div>
  <div class="container--main-section">
    <ui-tabs
      #tabs
      [color]="tabColor"
    >
      <ui-tab
        [tabTitle]="userFirstName"
        [isShown]="true"
      >
        <div class="user-informations">
          <ng-container
            [ngTemplateOutlet]="data"
            [ngTemplateOutletContext]="{elements: getUserElements()}"
          ></ng-container>
        </div>
      </ui-tab>
      <ui-tab
        [tabTitle]="'dashboard.md-eco.myProfile.card.secondTable' | t"
        [isShown]="true"
      >
        <div class="org-informations">
          <ng-container
            [ngTemplateOutlet]="data"
            [ngTemplateOutletContext]="{elements: getOrgElements()}"
          ></ng-container>
        </div>
      </ui-tab>
    </ui-tabs>
  </div>
</div>

<ng-template
  #data
  let-elements="elements"
>
  <ng-container *ngFor="let element of elements; let i = index">
    <div class="list-element">
      <div class="img-container">
        <img [src]="'assets/favicons/' + element.img" />
      </div>
      <p class="bold">{{ element.quantity }}</p>
      &nbsp;
      <p [innerHTML]="element.label | t:{quantity: element.quantity}"></p>
    </div>
  </ng-container>
</ng-template>

