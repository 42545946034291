import { Pipe, PipeTransform } from '@angular/core';
import { HandleBarUtils } from '../../utils/handlebars-utils';
import { IParameterStore } from '../../services/api/tools/parameters/parameters.definitions';

@Pipe({
  name: 'parameters',
})
export class ParametersPipe implements PipeTransform {
  async transform(value: string, pipeParams: IParameterStore[]): Promise<string> {
    if (value) {
      const handleBarParams = HandleBarUtils.mustacheParameterBuilder(pipeParams);
      return HandleBarUtils.mustacheReplace(value, handleBarParams);
    }
  }
}
