<div class="container">
  <div *ngIf="isHeader" class="container--header">
    <div class="container--header--left-section">
      <div class="img-container">
        <img
          class="shared-files-mobile--icon"
          [src]="getOfficeIcon(sharedFile)"
        />
      </div>
      <div class="name">
        {{ sharedFile.name }}
      </div>
      <share-risk-score class="risk-score" [resource]="sharedFile" [smallSize]="true"></share-risk-score>
    </div>
  </div>
  <div *ngIf="!isHeader" class="container--content">
    <p *ngIf="sharedFile.resourceName">{{ 'shares.list.columns.name.resource' | t }} : <span>{{ sharedFile.resourceName }}</span></p>
    <p *ngIf="sharedFile.lastAccessed">{{ 'shares.list.columns.name.lastAccessed' | t }} : <span>{{ sharedFile.lastAccessed | dt }}</span></p>
    <a (click)="handleRowClick(sharedFile)">{{ 'dashboard.md-eco.myProfile.card.detailRedirect' | t }}</a>
  </div>
</div>
