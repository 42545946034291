import { Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { GroupsTemporaryDataService } from '@common/services/api/sg/temporary-data/groups-temporary-data.service';
import { MultiSelectData } from '@ui-kit/components/ui-multi-select/multi-select-data';
import { IBreadcrumbs } from 'projects/@assist-ops/components/breadcrumbs/breadcrumbs.component';
import { SgExpendableCardComponent } from 'projects/@common/components/sg-expendable-card/sg-expendable-card.component';
import { MultiSelectDataFactory } from 'projects/@common/modules/i18n/component-wrapper/multi-select-data.factory';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { UsersApiService } from 'projects/@common/services/api/sg/user/userApi.service';
import { MobileService } from 'projects/@common/services/mobile.service';
import { DOCUMENTATION_SECTION, generateDocumentationLink } from 'projects/@common/utils/eco.documentation-utils';
import { PageDescriptionService } from 'projects/console-sg/src/services/page-description.service';
import { BannerTypeEnum } from '../../shared/components/banners/banners.component';
import { DropdownActions } from '../../shared/components/dialogs/resources-dialogs/group-actions/dropdownActions.enum';
import { GroupActionsComponent } from '../../shared/components/dialogs/resources-dialogs/group-actions/group-actions.component';
import { PageDescriptions } from '../../shared/components/page-layout/page-descriptions.enum';
import { ResourceCard } from './services/resourceCard';
import { GetResourceGroupRequest, ResourcesListService, ResourceStatusEnum } from './services/resources-list.service';

@Component({
  selector: 'sg-teams-resources',
  templateUrl: './teams-resources.component.html',
  styleUrls: ['./teams-resources.component.scss'],
})
export class TeamsResourcesComponent implements OnInit, OnDestroy {
  @ViewChildren(SgExpendableCardComponent) cardsRef!: QueryList<SgExpendableCardComponent>;
  @ViewChildren(GroupActionsComponent) groupActionsComponent: QueryList<GroupActionsComponent>;

  public breadcrumbs: IBreadcrumbs[] = [
    { nodeName: 'breadcrumb.dashboard', nodeLink: '/dashboard' },
    { nodeName: 'breadcrumb.teams.resource', nodeLink: null },
  ];

  public documentationSection = generateDocumentationLink(DOCUMENTATION_SECTION.sg.resourceHealthCheck, this.i18nService.currentLocale);
  public cards: ResourceCard[];
  public cardsCopy: ResourceCard[];
  public multiSelectData: MultiSelectData;
  public resourceStatusEnum = ResourceStatusEnum;
  public getResourceGroupRequest = new GetResourceGroupRequest();
  public groupsNotCompliant: { total: number, atRisk: number; atRiskGroupIds: string[]; } = {
    total: null,
    atRisk: null,
    atRiskGroupIds: [],
  };
  public initialLoading = true;
  public total = 0;
  public hasArchiver = false;
  public hasResourceApprovers = true;
  public openedCards = [];
  private currentPos = 0;
  private loadLimit = this.getResourceGroupRequest.getQueryParams().limit;
  private data = {};

  constructor(
    public mobileService: MobileService,
    public resourcesListService: ResourcesListService,
    private pageDescriptionService: PageDescriptionService,
    private i18nService: I18nService,
    private multiSelectFactory: MultiSelectDataFactory,
    private temporaryDataService: GroupsTemporaryDataService,
    private usersApi: UsersApiService
  ) {
    this.multiSelectData = this.multiSelectFactory.create(this.resourceStatusEnum, [], 'shares.gestions.');
  }

  public get resourceTotal(): number {
    return this.groupsNotCompliant.total;
  }

  public get currentResourceTotal(): number {
    return this.resourcesListService.filteredResources.length;
  }

  async ngOnInit(): Promise<void> {
    this.resourcesListService.cardsSubject$.subscribe((value) => {
      this.cards = value;
      this.cardsCopy = value;
    });

    const getCardsPromise = this.resourcesListService.getCards();
    const getCardsTotalsPromise = this.resourcesListService.getCardsTotals();
    const getListResourceApprovers = this.usersApi.listResourceApprovers({ usersOnly: true });

    Promise.all([getCardsPromise, getCardsTotalsPromise, getListResourceApprovers]).then((response) => {
      const atRiskGroupIds = this.temporaryDataService.getAtRiskResources(response[1].atRiskGroupIds);
      response[1] = {...response[1], atRiskGroupIds, atRisk: atRiskGroupIds?.length}
      this.groupsNotCompliant = response[1];
      this.hasResourceApprovers = !!response[2]?.items?.length;
      this.initialLoading = false;
    });

    this.getOrganizationArchiver();
  }

  public async refresh(): Promise<void> {
    this.openedCards = [];
    this.refreshCardStates();
    await this.resourcesListService.getCards();
    this.resourcesListService.getCardsTotals().then((response) => {
      response.atRiskGroupIds = this.temporaryDataService.getAtRiskResources(response.atRiskGroupIds);
      this.groupsNotCompliant = response;
    });

    this.cards = this.cards.slice(0, this.loadLimit);
  }

  public refreshCardStates() {
    this.cardsRef.forEach((card, index) => {
      if (card.isOpen) {
        this.openedCards.push(index);
      }
    });
  }

  ngOnDestroy(): void {
    this.resourcesListService.clearAll();
  }

  public openDescription(): void {
    this.pageDescriptionService.openTooltip(PageDescriptions.TEAMS_RESOURCES);
  }

  public async sortResources(type: ResourceStatusEnum[]) {
    await this.resourcesListService.sortResources(type);

    if (this.resourcesListService.getResourceGroupRequest.validationFilter !== 'ALL') {
      this.cards = this.cards.slice(0, this.loadLimit);
    }
  }

  public async loadMore(): Promise<void> {
    switch (this.resourcesListService.getResourceGroupRequest.validationFilter) {
      case ResourceStatusEnum.COMPLIANT:
        await this.loadMoreDataFromUi();
        break;

      case ResourceStatusEnum.RISK:
        await this.loadMoreDataFromUi();
        break;

      default:
        await this.loadMoreDataFromServer();
        break;
    }
  }

  public getBannerType(): BannerTypeEnum {
    return this.groupsNotCompliant.atRiskGroupIds.length === 0 ? BannerTypeEnum.ok : BannerTypeEnum.warning;
  }

  public getBannerMessage(): string {
    if (this.resourcesListService.initialUserResources.length === 0) {
      return this.i18nService.translate('shares.gestions.banner.no-resources.message');
    }
    if (this.groupsNotCompliant.atRiskGroupIds.length === 0) {
      return this.i18nService.translate('shares.gestions.banner.compliant.resource.message');
    }
    return this.mobileService.isMobile()
      ? this.i18nService.translate('shares.gestions.banner.risky.resource.message.mobile')
      : this.i18nService.translate('shares.gestions.banner.risky.resource.message', { total: this.groupsNotCompliant.atRiskGroupIds.length });
  }

  public openDialog(actionType: DropdownActions, index: number): void {
    this.groupActionsComponent.toArray()[index].selectOption(actionType);
  }


  private async loadMoreDataFromServer(): Promise<void> {
    await this.resourcesListService.loadMore();
    if (this.resourcesListService.getResourceGroupRequest.validationFilter === ResourceStatusEnum.RISK ||
      this.resourcesListService.getResourceGroupRequest.validationFilter === ResourceStatusEnum.COMPLIANT) {
      this.cards = this.cardsCopy.slice(0, this.currentPos + this.loadLimit);
    }
  }

  private async loadMoreDataFromUi(): Promise<void> {
    this.currentPos += this.loadLimit;
    this.cards = this.cards.concat(this.cardsCopy.slice(this.currentPos, this.currentPos + this.loadLimit));

    if (this.currentPos >= this.cards.length) {
      this.currentPos = this.cards.length;
      await this.loadMoreDataFromServer();
    }
  }

  private getOrganizationArchiver(): void {
    this.usersApi.getArchiver().then((result) => {
      this.hasArchiver = result;
    });
  }
}
