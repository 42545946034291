<div class="content">
  <div class="title-wrapper">
    <p class="title">{{'shares.gestions.description.title' | t}}</p>
  </div>
  <p class="text bold">{{ 'shares.gestions.description.text.preamble' | t }} </p>
  <p class="text bold" [innerHTML]="'shares.gestions.description.text.preamble2' | t "></p>
  <p class="subtitle"> {{ 'shares.gestions.description.text.subtitle1' | t }}  </p>
  <div class="indicators">
    <div class="indicator">
      <div class="text-image">
        <img src="assets/favicons/icon_pause_ok.svg">
      </div>
      <p>{{'shares.gestions.description.text.subtext1' | t }}  </p>
    </div>

    <div class="indicator">
      <div class="text-image">
        <img src="assets/favicons/icon_pause_alert.svg">
      </div>
      <p>{{'shares.gestions.description.text.warn' | t }}  </p>
    </div>

    <div class="indicator">
      <div class="text-image">
        <img src="assets/favicons/icon_pause_warning.svg">
      </div>
      <p>{{'shares.gestions.description.text.subtext2' | t }}  </p>
    </div>
  </div>
  <p class="subtitle">{{ 'shares.gestions.description.text.subtitle2' | t }}</p>

  <div class="learn-more">
    <collapsable-description [title]="'shares.gestions.security_model.label' | t" icon="assets/favicons/icon_paper.svg">
      <p>{{'shares.gestions.description.text.subtext3' | t }} </p>
      <ul>
        <li>{{'shares.gestions.description.text.subtext3.item1' | t }}</li>
        <li>{{'shares.gestions.description.text.subtext3.item2' | t }}</li>
        <li>{{'shares.gestions.description.text.subtext3.item3' | t }}</li>
        <li>{{'shares.gestions.description.text.subtext3.item4' | t }}</li>
      </ul>
    </collapsable-description>
    <collapsable-description [title]="'shares.gestions.security_model.certification.label' | t " icon="assets/favicons/icon_certifier.svg">
      <p>{{'shares.gestions.description.text.subtext4' | t }} </p>
      <ul>
        <li>{{'shares.gestions.description.text.subtext4.item1' | t }}  </li>
        <li>{{ 'shares.gestions.description.text.subtext4.item2' | t }}  </li>
      </ul>
      <p>{{'shares.gestions.description.text.subtext5' | t }} </p>
    </collapsable-description>
    <collapsable-description [title]="'guestUser.detail.expirationDate' | t " icon="assets/favicons/icon_calendar.svg">
      <p>{{ 'shares.gestions.description.text.subtext6' | t }}</p>
    </collapsable-description>
    <collapsable-description [title]="'groups.action.confirmation.action' | t" icon="assets/favicons/icon_archive_pale_grey.svg">
      <p>{{ 'shares.gestions.description.text.subtext7' | t }} </p>
    </collapsable-description>
  </div>

  <!-- <a href="http://google.ca" class="outside-link"> -->
  <!--   <p class="subtitle"> {{'common.learn.more' | t }} </p> -->
  <!--   <img src="assets/favicons/icon_external_list.svg"> -->
  <!-- </a> -->
</div>
