<div class="content">
  <ui-severity-chip
    componentTooltipDirective
    [overrideHeightOffset]="120"
    [componentTooltip]="tooltip"
    [isActivated]="showRiskFactor && riskFactors?.length > 0"
    [severity]="severityValue"
    [customText]="customText"
  ></ui-severity-chip>
  <img
    *ngIf="resource.silencingEnds && showBellIcon"
    src="assets/favicons/icon_deactivate_notification.svg"
    [title]="'file.risk.SILENCED.tooltip' | t"
  />
</div>

<ng-template #tooltip>
  <div class="factorTooltip">
    <p class="title" *ngIf="!mobileService.isMobile()">{{'factor.tooltip.label' | t: {severity: 'file.risk.' + resource.risk + '.filter' | t} }}</p>
    <ul>
      <li *ngFor="let label of riskFactors">{{label}}</li>
    </ul>
  </div>
</ng-template>
