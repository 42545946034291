<div class="selector-container">
  <div class="button-hover">
    <button
      class="selector-container--content"
      (click)="toggle()"
    >
      <p class="selector-container--content--text">{{'dashboard.md-eco.quickAction.option' | t}}</p>
      <img src="assets/favicons/icon_chevron_open_action.svg">
    </button>
  </div>

  <div
    id="option-container"
    class="selector-container--options-container"
    *ngIf="isOpen"
  >
    <div class="selector-container--options-container--left-panel">
      <ul>
        <li *ngFor="let option of options; let i = index">
          <ng-container *display="{flags : option.flagRequired}">
            <button
              *ngIf="!option.disabled"
              class="selector-container--options-container--left-panel--option"
              [class.selected]="option.show && !option.disabled"
              [class.disabled]="option.disabled"
              (focus)="onMouseEnter(option)"
              (mouseenter)="onMouseEnter(option)"
              (click)="onClick(option)"
            >
              <p>{{'dashboard.md-eco.quickAction.option.' + (option.id) | t}}</p>
            </button>
          </ng-container>
        </li>
      </ul>
    </div>

    <div class="selector-container--options-container--right-panel">
      <ng-container *ngFor="let option of options; let i = index">
        <div
          *ngIf="!option.disabled"
          class="selector-container--options-container--right-panel--description"
          [class.no-height]="!option.show"
        >
          <p *ngIf="option.show && !option.disabled">{{'dashboard.md-eco.quickAction.option.' + (i + 1) + '.description'
            | t}}</p>
        </div>
      </ng-container>
    </div>
  </div>
</div>
