<div
  *ngIf="!isLoading && userProfile.hasUsersReporting > 0; else spinner"
  class="container container--white column-flex"
>
  <user-info-dashboard
    [color]="cardColorEnum.paleBlue"
    [userProfile]="userProfile"
    [isTeamDashboard]="true"
  ></user-info-dashboard>
  <div class="filtered-content">
    <p class="sectionTitle">{{ 'eco-profile.page.tab.user-profile.user-info-name.sub.greeting.team' | t }}</p>
    <div class="filters">
      <section class="expandable-box--header">
        <img src="assets/favicons/icon_filter.svg" />
        {{ 'toolbar.filters.collapsable.button.name' | t: { locale: i18n.currentLocale } }}
        <ui-anchor
          *ngIf="selectedUsers.length > 0"
          [label]="'toolbar.filters.eraseAll.button.name' | t: { locale: i18n.currentLocale }"
          (onClick)="selectedUsers?.length ? (selectedUsers = []) : null; handleRefresh()"
        ></ui-anchor>
      </section>
      <autocomplete
        [canMultiSelect]="true"
        [placeholder]="'eco-profile.page.tab.team-profile.autocomplete.placeholder' | t"
        [autocompleteType]="usersAutocomplete"
        (onItemSelected)="onItemSelected($event)"
        [searchFunction]="usersAutocompleteSearchFunction"
        name="userAutocomplete"
        [locale]="i18n.currentLocale"
        [itemsToIgnore]="selectedUsers"
        [preserveSearchQuery]="false"
      ></autocomplete>
      <ui-user-tags
        [users]="selectedUsers"
        [readOnly]="false"
        (onDeleteUser)="removeDefaultApprover($event)"
      ></ui-user-tags>
    </div>
    <div class="container">
      <div class="column left">
        <div class="cards">
          <sg-admin-cards
            [userProfile]="userProfile"
            [isFromConsoleOrg]="false"
            [totals]="selectedUsers?.length && !isLoading ? allData : null"
          ></sg-admin-cards>
          <sg-eco-profile-card [cardColor]="cardColorEnum.white">
            <sg-eco-profile-row secondCellWidth="175px" [config]="weeks"></sg-eco-profile-row>
          </sg-eco-profile-card>
          <sg-monthly-actions-graph [data]="monthlyData"></sg-monthly-actions-graph>
        </div>

        <sg-eco-profile-card [cardColor]="cardColorEnum.white" secondCard>
          <sg-eco-profile-row *ngIf="action" [config]="action"></sg-eco-profile-row>
        </sg-eco-profile-card>
      </div>
      <div class="column right">
        <admin-top10-high-risk-shares
          [isFromConsoleOrg]="false"
          [selectedUsers]="selectedUsers"
          customEmptyMessageKey="eco-profile.page.tab.team-profile.resources.shares.empty"
        ></admin-top10-high-risk-shares>
        <admin-users-shares
          [isFromConsoleOrg]="false"
          [selectedUsers]="selectedUsers"
          customEmptyMessageKey="eco-profile.page.tab.team-profile.resources.shares.empty"
        ></admin-users-shares>
      </div>
    </div>
  </div>
</div>

<ng-template #spinner>
  <ui-spinner *ngIf="isLoading" class="profile-spinner" size="medium" modifier="secondary"></ui-spinner>
  <div class="no-reporting" *ngIf="!isLoading">
    <p>{{ 'eco-profile.page.tab.team-profile.no-reporting' | t }}</p>
  </div>
</ng-template>
