import { Component, Input, OnInit } from '@angular/core';
import { ITranslatedField } from 'projects/@common/definitions/ITranslatedField';
import { ResourceCard } from '../../../pages/teams-resources/services/resourceCard';

export interface CardHeader {
  resourceId: string;
  logo: string;
  title: ITranslatedField;
  isValid: boolean;
}

@Component({
  selector: 'sg-resource-card-header',
  templateUrl: './resource-card-header.component.html',
  styleUrls: [ './resource-card-header.component.scss' ],
})
export class ResourceCardHeaderComponent implements OnInit {
  @Input()
  public info: ResourceCard;

  constructor() {
  }

  public get logo(): { icon: string, background: string; } {
    return {
      icon: `assets/favicons/${this.info.resourceIcon.icon}.svg`,
      background: this.info.resourceIcon.background,
    };
  }

  public get validityLogo(): string {
    let image = 'ok';
    if (this.info.isWarning()) {
      image = 'alert';
    }
    if (!this.info.isResourceValid()) {
      image = 'warning';
    }
    return `assets/favicons/icon_pause_${image}.svg`;
  }

  public get validityTooltip(): string {
    return this.info.isResourceValid() ? 'common.compliant' : 'common.atRisk';
  }

  ngOnInit(): void {
  }
}
