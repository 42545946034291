<div class="title-wrapper">
  <p class="title">{{'shares.description.title' | t}}</p>
  <img src="assets/favicons/icon_protection.svg">
</div>
<p class="text bold">{{ 'shares.description.text.subtext1' | t }} </p>
<p class="subtitle">{{ 'shares.description.text.indicators.subtitle' | t }}</p>
<div class="list">
  <div class="listElement">
    <div class="indicator">
      <ui-dot-indicator [riskInfo]="{'risk': 'high', 'translatedRisk': 'HIGH RISK'}"></ui-dot-indicator>
    </div>
    <p [ngStyle]="{'color': '#DE3618'}">{{ 'shares.shareRiskScore.high' | t }} </p>
  </div>
  <p class="list-element-description">{{ 'shares.description.text.indicators.high.text' | t }} </p>

  <div class="listElement">
    <div class="indicator">
      <ui-dot-indicator [riskInfo]="{'risk': 'moderate', 'translatedRisk': 'HIGH RISK'}"></ui-dot-indicator>
    </div>
    <p [ngStyle]="{'color': '#FF7800'}">{{ 'shares.shareRiskScore.moderate' | t }} </p>
  </div>
  <p class="list-element-description">{{ 'shares.description.text.indicators.medium.text' | t }} </p>

  <div class="listElement">
    <div class="indicator">
      <ui-dot-indicator [riskInfo]="{'risk': 'low', 'translatedRisk': 'HIGH RISK'}"></ui-dot-indicator>
    </div>
    <p [ngStyle]="{'color': '#0BB786'}">{{ 'shares.shareRiskScore.low' | t }} </p>
  </div>
  <p class="list-element-description">{{ 'shares.description.text.indicators.low.text' | t }} </p>
</div>

<p class="subtitle">{{ 'shares.description.text.learn.subtitle' | t }} </p>

<collapsable-description
  [isSmall]="true"
  [icon]="'assets/favicons/icon_odometre.svg'"
  [title]="'shares.description.text.learn.criterias' | t "
>
  <p class="preamble"> {{ 'shares.description.text.learn.list.preamble' | t }}</p>
  <div class="bullet-points">
    <ul>
      <li>{{'shares.description.text.learn.list.item1' | t }} </li>
      <li>{{'shares.description.text.learn.list.item2' | t }} </li>
      <li>{{'shares.description.text.learn.list.item3' | t }} </li>
      <li>{{'shares.description.text.learn.list.item4' | t }} </li>
    </ul>
  </div>
</collapsable-description>

<!-- <a href="http://google.ca" class="outside-link"> -->
<!--   <p class="subtitle"> {{'common.learn.more' | t }} </p> -->
<!--   <img src="assets/favicons/icon_external_list.svg"> -->
<!-- </a> -->