import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { TeamsResourcesComponent } from './teams-resources.component';

const routes: Routes = [
  {
    path: '',
    component: TeamsResourcesComponent,
  },
];

@NgModule({
  imports: [ RouterModule.forChild(routes) ],
  exports: [ RouterModule ],
})
export class TeamsResourcesRoutingModule {
}
