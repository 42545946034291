import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ComponentTooltipDirective } from './component-tooltip.directive';
import { PhoneMaskDirective } from './phone-number-mask.directive';
import { PhoneNumberValidatorDirective } from './phone-number-validator.directive';
import { ScrollToBottomDirective } from './scroll-to-bottom.directive';

const directives = [
  ScrollToBottomDirective,
  PhoneNumberValidatorDirective,
  PhoneMaskDirective,
  ComponentTooltipDirective,
];

@NgModule({
  exports: [ ...directives ],
  declarations: [ ...directives ],
  imports: [
    CommonModule,
  ],
})
export class DirectivesModule { }
