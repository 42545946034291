import { Component, Input, ViewEncapsulation } from '@angular/core';
import { IUserWidgets } from '../dashboard.container';
import { IResourceSharedFilesStats } from 'projects/@common/services/api/sg/shares/shares.definitions';

@Component({
  selector: 'user-dashboard',
  templateUrl: './user-dashboard.component.html',
  styleUrls: [ '../dashboard.container.scss' ],
  encapsulation: ViewEncapsulation.None,
})
export class UserDashboardContainer {
  @Input() public currentUser: any;

  @Input() public orgIsCISOnly = false;

  @Input() public enabledWidgets: IUserWidgets;

  @Input() public isLoadingWidgetPreferences: boolean;

  public resourcesMonitoringStats: IResourceSharedFilesStats;

  public setResourceMonitoringStats(stats: IResourceSharedFilesStats): void {
    this.resourcesMonitoringStats = stats;
  }
}
