<div
  class="shared-to"
  [ngClass]="{
    'shared-to--group':
      sharedFilePermission.groupType && !mobileService.isMobile(),
    'shared-to--group-mobile':
      sharedFilePermission.groupType && mobileService.isMobile(),
    'shared-to--special-margins': withSpecialMargins
  }"
>
  <div *ngIf="!isLink; else isLinkIcon">
    <ui-avatar
      *ngIf="!sharedFilePermission.groupType; else groupResourceAvatar"
      [officeId]="sharedFilePermission.o365Id"
      [name]="sharedFilePermission.name"
    ></ui-avatar>
  </div>

  <div class="shared-to--name">
    <ng-container *ngIf="!isLink; else isLinkName">
      <div>
        {{ sharedFilePermission.name }}
        <span
          *ngIf="sharedFilePermission.shareType === 'Guest'"
          class="shared-to--name--guest-badge"
        >
          {{ 'common.guest' | t }}
        </span>
      </div>
      <div
        class="second-information"
        [ngClass]="{
          'second-information--mobile': mobileService.isMobile() && !isEditing,
          'second-information--mobile--isEditing':
            mobileService.isMobile() && isEditing,
          'second-information--with-revocation':
            isAutomaticRevokeActivated && !mobileService.isMobile()
        }"
      >
        {{ sharedFilePermission.email }}
      </div>
    </ng-container>
  </div>
</div>

<ng-template #groupResourceAvatar>
  <ui-avatar-resource
    [groupType]="sharedFilePermission.groupType"
  ></ui-avatar-resource>
</ng-template>

<ng-template #isLinkIcon>
  <img [src]="'assets/favicons/' + iconName + '.svg'" />
</ng-template>

<ng-template #isLinkName>
  <div>{{ linkName | t }}</div>

  <div
    *ngIf="sharedFilePermission?.shareType === 'Public'"
    class="second-information"
    [ngClass]="{
      'second-information--mobile': mobileService.isMobile() && !isEditing,
      'second-information--mobile--isEditing':
        mobileService.isMobile() && isEditing,
      'second-information--with-revocation':
        isAutomaticRevokeActivated && !mobileService.isMobile()
    }"
  >
    {{
      'sharedFile.drawer.shares.table.details.public' +
        (sharedFilePermission?.isPasswordProtected ? '.withPassword' : '') | t
    }}
  </div>

  <div
    *ngIf="sharedFilePermission?.shareType === 'Company'"
    class="second-information"
    [ngClass]="{
      'second-information--mobile': mobileService.isMobile() && !isEditing,
      'second-information--mobile--isEditing':
        mobileService.isMobile() && isEditing,
      'second-information--with-revocation':
        isAutomaticRevokeActivated && !mobileService.isMobile()
    }"
  >
    {{ 'sharedFile.drawer.shares.table.details.organization' | t }}
  </div>
</ng-template>
