<sg-page-layout
  [title]="'resourcesWithShares.title' | t: { resourceName: name }"
  [breadcrumbs]="breadcrumbs"
  (onTooltipEvent)="openDescription()"
  (onScrollToBottom)="scroll()"
>
  <div [ngClass]="{ 'ui-tabs-hidden': hasResourcesWithShares }">
    <shared-files-table
      [disableExpressRevoke]="disableExpressRevokeResources"
      [hasResourcesWithShares]="hasResourcesWithShares"
      (isSharedFilesLoading)="isSharedFilesLoadingFunc()"
      (refresh)="onRefresh()"
    ></shared-files-table>
  </div>

  <div
    class="page-description"
    description
  >
  <shares-resources-description></shares-resources-description>
  </div>
</sg-page-layout>

<ng-template #mobileDescription >
  <shares-resources-description></shares-resources-description>
</ng-template>
