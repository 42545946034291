<ng-container *ngIf="authConfirmed && isOnAllowedPage">
  <ng-container *ngIf="!isCollapsed; else collapsed">
    <div (mouseenter)="onHoverEnter()" (mouseleave)="onHoverLeave()" class="positioning">
      <button (click)="collapse()" *ngIf="showHideButton" class="hideButton">
        <img [src]="'assets/favicons/close.svg'" width="10" height="10" />
      </button>
      <button class="main" (click)="openDrawer()" [ngStyle]="{ 'background-color': buttonColor }">
        <img [src]="'assets/favicons/icon_hourglass.svg'" width="30" height="30" />
      </button>
    </div>
  </ng-container>
</ng-container>

<ng-template #collapsed>
  <div class="positioning positioning--toSide">
    <button (click)="unCollapse()" class="showButton" [ngStyle]="{ 'background-color': buttonColor }">
      <img [src]="'assets/favicons/icon_chevron_close_left_dark.svg'" width="20" height="20" />
    </button>
  </div>
</ng-template>
