<div
  class="notification-section--header"
  [class.notification-section--header-mobile]="mobileService.isMobile()"
>
  <span>{{ 'operations.' + section | t }}</span>
</div>
<div
  [class.notification-section--content]="!mobileService.isMobile()"
  [class.notification-section--content--mobile]="mobileService.isMobile()"
>
  <ng-container *ngFor="let notification of notifications">
    <ops-manager-notification
      [notification]="notification"
      [useDateFormat]="section === opsManagerNotificationsSectionEnum.OLDEST"
      [supportType]="supportType"
      [supportLink]="supportLink"
    ></ops-manager-notification>
  </ng-container>
</div>
