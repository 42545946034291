import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { I18nService } from '../i18n/i18n.service';

@Pipe({
  name: 'completeDT',
  pure: false,
})
export class CompleteDTPipe implements PipeTransform {
  constructor(private readonly i18nService: I18nService, private readonly datepipe: DatePipe) {}

  public transform(value: number | null | undefined): string {
    if (!value) {
      return '-';
    }

    const date = new Date(value);
    const todaysDate = new Date().setHours(0, 0, 0, 0);
    const yesterdaysDate = todaysDate - 1 * 24 * 60 * 60 * 1000;

    if (date.setHours(0, 0, 0, 0) === todaysDate) {
      const time = this.getTime(value, 'h:mm:ss a');
      return this.i18nService.translate('datepipe.complete.today', { time });
    }
    if (date.setHours(0, 0, 0, 0) === yesterdaysDate) {
      const time = this.getTime(value, 'h:mm:ss a');
      return this.i18nService.translate('datepipe.complete.yesterday', { time });
    }
    return this.getTime(value, 'medium');
  }

  private getTime(date: number, format: string) {
    return this.datepipe.transform(
      new Date(date),
      format,
      null,
      this.i18nService.currentLocale === 'fr' ? 'fr-ca' : 'en'
    );
  }
}
