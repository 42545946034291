import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DrawerService } from '@common/modules/layout/components/drawer/services/drawer.service';
import { NoticeLevels, NoticeService } from '@common/modules/notice/notice.service';
import { RequestsApiService } from 'projects/@common/services/api/requests/requests-api.service';
import { GetRequestResponse } from 'projects/@common/services/api/requests/requests.definitions';
import { RequestService } from '../../../services/request.service';

@Component({
  selector: 'request-form-process',
  templateUrl: './request-form-process.component.html',
  styleUrls: ['./request-form-process.component.scss'],
})
export class RequestFormProcessComponent implements OnInit {
  @Input() public request: GetRequestResponse;

  @Input() public showDeclineButton = true;

  public requestApprobationForm: UntypedFormGroup;

  public isLoading = false;

  constructor(
    private readonly requestsApiService: RequestsApiService,
    private readonly requestService: RequestService,
    private readonly noticeService: NoticeService,
    private readonly drawerService: DrawerService
  ) {}

  ngOnInit(): void {
    this.createRequestApprobationForm();
  }

  public processApprobation(isAccepted: boolean) {
    this.isLoading = true;
    const note = this.requestApprobationForm.get('note').value;
    this.requestsApiService
      .updateDecision(this.request.approverId, this.request.id, isAccepted, note)
      .then((response) => {
        this.requestService.update.next();
        this.noticeService.notifyUser({ message: 'request.update.success.message', level: NoticeLevels.SUCCESS });
        this.drawerService.hideDrawer();
      })
      .catch((e) =>
        this.noticeService.notifyUser({ message: 'request.update.error.500.message', level: NoticeLevels.ERROR })
      )
      .finally(() => this.isLoading = false);
  }

  private createRequestApprobationForm() {
    this.requestApprobationForm = new UntypedFormGroup({
      note: new UntypedFormControl(null),
    });
  }
}
