import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { SharesContainer } from './containers/shares/shares.container';
import { SharesResourcesContainer } from './containers/shares-resources/shares-resources.container';

const routes: Routes = [
  {
    path: '',
    component: SharesContainer,
  },
  {
    path: 'resource',
    component: SharesResourcesContainer,
  },
];

@NgModule({
  imports: [ RouterModule.forChild(routes) ],
  exports: [ RouterModule ],
})
export class SharesRoutingModule {
}
