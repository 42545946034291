<div class="drawer-detail--scrollable tab-menu hidden">
  <div class="drawer-detail--content file-details">
    <ui-spinner
      *ngIf="isLoading"
      size="medium"
      modifier="secondary"
    ></ui-spinner>
    <ng-container *ngIf="!isLoading">
      <div
        *ngIf="risk === 'HIGH'"
        class="file-details--top-content"
      >
        <ng-container *ngTemplateOutlet="topContent"></ng-container>
      </div>
      <ui-title [name]="'files.drawer.tabs.details.properties' | t"></ui-title>
      <!-- <div
        class="info"
        *ngIf="showSeverityGuideInfo"
      >
        <img
          class="info-img"
          src="../../../assets/favicons/info-icon.png"
        >
        <p
          class="hint-text"
          [innerHtml]="'factor.hint.educational' | t"
        ></p>
      </div> -->
      <label
        ui-label
        class="margin--small--top"
      >{{
        'files.drawer.tabs.details.name' | t
        }}</label>

      <div class="file-details--name">
        <img
          class="icon-image"
          [src]="fileIcon"
        />
        <a
          *ngIf="!isAdmin"
          class="location"
          [href]=""
          target="_blank"
          [title]="'files.drawer.tabs.details.link.open' | t"
        >
          {{ sharedFile.name }}</a>
        <span
          *ngIf="isAdmin"
          class="location"
        > {{ sharedFile.name }}</span>
      </div>

      <label
        ui-label
        class="margin--small--top"
      >{{
        'files.drawer.tabs.details.location' | t
        }}</label>
      <div class="file-details--name">
        <img
          class="icon-image"
          [src]="getfileIcon('')"
        />
        <a
          *ngIf="!isAdmin"
          class="location"
          [href]="sharedFile.webFolder"
          target="_blank"
          [title]="'files.drawer.tabs.details.link.open' | t"
        >{{ sharedFile.webFolder }}</a>
        <span
          *ngIf="isAdmin"
          class="location"
        >
          {{ sharedFile.webFolder }}</span>
        <ui-icon
          *ngIf="!isAdmin"
          class="openLink"
          name="open-new"
          size="small"
          modifier="on-header"
          (click)="openFile(sharedFile.webFolder); $event.stopPropagation()"
          [title]="'files.drawer.tabs.details.link.open' | t"
        ></ui-icon>
      </div>

      <div class="margin--small--top">
        <ui-label class="magin--small--top ui-label">
          {{'shares.drawer.detail.risk.label' | t }}
        </ui-label>
        <share-risk-score
          [showRiskFactor]="false"
          [resource]="sharedFile"
          [showBellIcon]="false"
        ></share-risk-score>
        <div
          class="factorTooltip"
          *ngIf="showSeverityGuideInfo && riskFactors?.length"
        >
          <p class="title">{{'factor.tooltip.label' | t: {severity: 'file.risk.' + risk + '.filter' | t} }}</p>
          <ul>
            <li *ngFor="let label of riskFactors">{{label}}</li>
          </ul>
        </div>
      </div>

      <div class="flex-box center-horizontal margin--small--top">
        <ui-static
          [label]="'files.drawer.tabs.details.createdBy' | t"
          [value]="sharedFile?.createdBy"
        ></ui-static>
        <ui-static
          [label]="'files.drawer.tabs.details.size' | t"
          [value]="sharedFile?.size | fileSize"
        ></ui-static>
      </div>

      <div class="flex-box center-horizontal margin--small--top">
        <ui-static
          [label]="'files.drawer.tabs.details.updatedBy' | t"
          [value]="sharedFile?.updatedBy"
        ></ui-static>
        <ui-static
          [label]="'files.drawer.tabs.details.createdDate' | t"
          [value]="sharedFile?.createdAt | dt: {locale: i18n.currentLocale}"
        ></ui-static>
      </div>

      <div class="flex-box center-horizontal margin--small--top">
        <ui-static
          [label]="'files.drawer.tabs.details.lastAccessDate' | t"
          [value]="sharedFile?.lastAccessed | lastAccessedFormat: fileCreationDateLimit"
        ></ui-static>
        <ui-static
          [label]="'files.drawer.tabs.details.updatedDate' | t"
          [value]="sharedFile?.updatedAt | dt: {locale: i18n.currentLocale}"
        ></ui-static>
      </div>
    </ng-container>
  </div>
</div>

<ng-template #topContent>
  <ng-container *ngIf="isLoadingTopContent && !sharedFile.silencingEnds">
    <ui-spinner size="medium"></ui-spinner>
  </ng-container>
  <button
    *ngIf="!isLoadingTopContent && !sharedFile.silencingEnds && !isFromConsoleOrg && options?.length > 0"
    ui-button
    icon="icon_deactivate_notification_green"
    [custom-icon]="true"
    [isSvg]="true"
    icon-size="small"
    color="listActionFlat"
    (click)="openSilencingDialog()"
  >
    {{'sharedFile.drawer.details.silence.button' | t }}
  </button>
  <ui-information-message *ngIf="sharedFile.silencingEnds">
    <img src="assets/favicons/icon_deactivate_notification.svg">
    {{'sharedFile.drawer.details.silence.message' | t : { date: sharedFile.silencingEnds | dt : { locale:
    i18n.currentLocale } } }}
  </ui-information-message>
</ng-template>