import { color } from '@amcharts/amcharts4/core';
import { Component, Input, OnChanges, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { MobileService } from '@common/services/mobile.service';
import { LanguageEnum } from '@ui-kit/interfaces/ILanguage';
import { User } from 'projects/@common/definitions/user.definition';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { SharesApiService } from 'projects/@common/services/api/sg/shares/shares-api.service';
import { FileRiskEnum, ISharedFilesStats } from 'projects/@common/services/api/sg/shares/shares.definitions';

@Component({
  selector: 'admin-users-shares',
  templateUrl: './admin-users-shares.component.html',
  styleUrls: ['./admin-users-shares.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AdminUsersSharesComponent implements OnInit, OnChanges {
  @Input() isFromConsoleOrg = true;
  @Input() selectedUsers: User[] = [];
  @Input() customEmptyMessageKey: string;
  public stats: ISharedFilesStats;
  public isLoading = false;
  public usersSharesData: any[] = [];
  public legendTemplate = '';
  public locale: LanguageEnum;
  private readonly COLOR_RED = '#DE3618';
  private readonly COLOR_ORANGE = '#ff7800';
  private readonly COLOR_GREEN = '#00d873';
  private readonly COLOR_GRAY = '#999';

  constructor(
    public mobileService: MobileService,
    private sharesService: SharesApiService,
    private readonly i18n: I18nService,
    private router: Router
  ) {
  }

  public get chartWidth(): string {
    return `${this.mobileService.currentWidth - 60}px`;
  }

  public get hasLevelWithShares(): boolean {
    return Object.values(this.usersSharesData).some((value) => value.count > 0);
  }

  ngOnInit() {
    this.locale = this.i18n.currentLocale as LanguageEnum;
    this.loadShares();
  }

  ngOnChanges() {
    this.loadShares();
  }

  private loadShares() {
    if (!this.isLoading) {
      this.isLoading = true;
      this.sharesService
        .getSharedFilesMonitoringStats(
          !this.isFromConsoleOrg,
          null,
          this.selectedUsers.map((user) => user.o365UserId)
        )
        .then((stats) => {
          this.stats = stats;
          this.formatData();
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.isLoading = false));
    }
  }

  public handleAction() {
    this.router.navigate(['/shares/users']);
  }

  public handleLegendItemClick(filter: any) {
    let riskFilter;
    switch (filter) {
      case 'Élevé' || 'High':
        riskFilter = FileRiskEnum.HIGH;
        break;
      case 'Modéré' || 'Moderate':
        riskFilter = FileRiskEnum.MODERATE;
        break;
      case 'Faible' || 'Low':
        riskFilter = FileRiskEnum.LOW;
        break;
      default:
      // do nothing
    }
    this.router.navigate(['/shares/users'], {
      queryParams: {
        riskFilter,
      },
    });
  }

  private formatData() {
    this.usersSharesData = [];

    this.legendTemplate = `{name}: {value} ${this.i18n.translate('common.shares')} [{color2}]({value.percent}%)[/]`;

    this.usersSharesData.push({
      level: this.i18n.translate('shares.shareRiskScore.high'),
      count: this.stats.highCount,
      color: color(this.COLOR_RED),
      color2: color(this.COLOR_GRAY),
    });

    this.usersSharesData.push({
      level: this.i18n.translate('shares.shareRiskScore.moderate'),
      count: this.stats.moderateCount,
      color: color(this.COLOR_ORANGE),
      color2: color(this.COLOR_GRAY),
    });

    this.usersSharesData.push({
      level: this.i18n.translate('shares.shareRiskScore.low'),
      count: this.stats.lowCount,
      color: color(this.COLOR_GREEN),
      color2: color(this.COLOR_GRAY),
    });
  }
}
