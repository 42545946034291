import { Injectable } from '@angular/core';
import { IOwnerGroup } from 'projects/console-sg/src/app/pages/teams-resources/services/resources-list.service';
import { StorageService } from '../../../storage.service';
import { TemporaryDataAbstract } from './temporary-data.abstract';


type OwnerGroupWithHide = IOwnerGroup & { toHide?: boolean; };
type TtlOwnerGroup = { group: OwnerGroupWithHide; } & { date: number; };

@Injectable({
  providedIn: 'root',
})
export class GroupsTemporaryDataService extends TemporaryDataAbstract {
  private ownedGroupsKey = 'ownedGroups';
  protected storageKey = 'inProgressGroups';

  constructor(protected storageService: StorageService) {
    super(storageService);
  }

  public addInProgressGroup(jobGuid: string) {
    const ttlProgressGroup = { jobGuid, date: Date.now() };
    this.add(ttlProgressGroup, 'jobGuid');
  }

  public removeInProgressJobGuid(jobGuids: string[]) {
    this.remove(jobGuids, 'jobGuid');
  }

  public removeInProgressOwnerGroup(groupIds: string[]) {
    const current: any[] = this.getFilteredValue(this.ownedGroupsKey);
    this.storageService.setLocal(this.ownedGroupsKey, JSON.stringify(current.filter((value) => !groupIds.includes(value.group.id))));
  }

  public get inProgressGroupsJobGuids(): string[] {
    return this.getFilteredValue(this.storageKey).map((data) => data.jobGuid).filter((value) => value);
  }

  public addOwnerGroup(ownerGroup: OwnerGroupWithHide): void {
    const ttlGroup: TtlOwnerGroup = {
      date: Date.now(),
      group: ownerGroup,
    };

    const currentGroup: TtlOwnerGroup[] = this.getFilteredValue(this.ownedGroupsKey);
    const toReplaceIndex = currentGroup.findIndex((ttl) => ttl.group.id === ownerGroup.id);
    toReplaceIndex >= 0 ? currentGroup.splice(toReplaceIndex, 1, ttlGroup) : currentGroup.push(ttlGroup);
    this.storageService.setLocal(this.ownedGroupsKey, JSON.stringify(currentGroup));
  }

  public getOwnerGroupWithFake(originGroup: OwnerGroupWithHide[]): IOwnerGroup[] {
    const fakeGroups = this.fetchOwnerGroup();
    const originCopy = [ ...originGroup ];

    originGroup.forEach((originGroup, index) => {
      const elementToReplace = fakeGroups.find((fakeGroup) => fakeGroup.id === originGroup.id);
      if (elementToReplace) {
        originCopy.splice(index, 1, elementToReplace);
      }
    });

    return originCopy.filter((group) => !group.toHide);
  }

  public getAtRiskResources(ids: string[]): string[] {
    const originCopy = [ ...ids ];
    const groups: TtlOwnerGroup[] = this.getFilteredValue(this.ownedGroupsKey);
    const toIgnoreIndexes : number[] = [];

    originCopy.forEach((groupId, index) => {
      const temporaryAssociatedGroup = groups.find((temporaryGroup) => temporaryGroup.group.id === groupId)?.group;
      if (temporaryAssociatedGroup?.toHide || (!!temporaryAssociatedGroup?.certificationInformation?.lastValidation && !!temporaryAssociatedGroup?.securityModel.name && !temporaryAssociatedGroup?.isCertifyExpired)) {
        toIgnoreIndexes.push(index);
      }
    });

    return originCopy.filter((element, index) => !toIgnoreIndexes.includes(index));
  }

  public get groupsToHide(): number {
    return this.fetchOwnerGroup().filter((group: OwnerGroupWithHide) => group.toHide).length
  }

  private fetchOwnerGroup(): IOwnerGroup[] {
    const groups: TtlOwnerGroup[] = this.getFilteredValue(this.ownedGroupsKey);
    return groups.map((group) => group.group);
  }
}
