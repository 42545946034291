import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';
import { TeamsAuthService } from '@common/services/teams-auth.service';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { UiKitModule } from '@ui-kit/uikit.module';
import { AssistOpsModule } from 'projects/@assist-ops/assist-ops.module';
import { Stages } from 'projects/@common/definitions/stages';
import { environment } from 'projects/@common/environments/environment';
import { AuthModule } from 'projects/@common/modules/auth/auth.module';
import { DisplayModule } from 'projects/@common/modules/display/display.module';
import { ConsoleType } from 'projects/@common/modules/layout/private.layout';
import { PrivateModule } from 'projects/@common/modules/layout/private.module';
import { PipesModule } from 'projects/@common/modules/pipes/pipes.module';
import { RootSharedModule } from 'projects/@common/modules/shared/root.shared.module';
import { SAINE_GESTION_APP_PAGES } from '../configuration/saine-gestion.pages.enum';
import { SaineGestionPermissionsMapping } from '../configuration/saine-gestion.permission.mapping';
import { SaineGestionToolsSidebar } from '../configuration/saine-gestion.tools.sidebar.service';
import { DeviceConformityModule } from './pages/device-conformity/device-conformity.module';
import { MobileDescriptionWrapperContainer } from './pages/mobile-description-wrapper/mobile-description-wrapper.container';
import { OnboardingContactUsComponent } from './pages/onboardings/onboarding-contact-us.component';
import { OnboardingRestrictedAccessComponent } from './pages/onboardings/onboarding-restrictedAccess.component';
import { SensitiveFilesModule } from './pages/sensitive-files/sensitive-files.module';
import { SharesModule } from './pages/shares/shares.module';
import { TeamsResourcesModule } from './pages/teams-resources/teams-resources.module';
import { SaineGestionComponent } from './saine.gestion.app.component';
import { SaineGestionRoutingModule } from './saine.gestion.app.routing.module';

const ngxs = [
  NgxsReduxDevtoolsPluginModule.forRoot({
    name: 'MD.SAINE.GESTION',
    disabled: environment.stage === Stages.PROD,
  }),
];

const components = [
  SaineGestionComponent,
  OnboardingContactUsComponent,
  OnboardingRestrictedAccessComponent,
  MobileDescriptionWrapperContainer,
];

@NgModule({
  declarations: [ ...components ],
  exports: [ ...components ],
  imports: [
    ReactiveFormsModule,
    PipesModule,
    RootSharedModule,
    SaineGestionRoutingModule,
    SharesModule,
    SensitiveFilesModule,
    TeamsResourcesModule,
    DeviceConformityModule,
    AssistOpsModule,
    MatRadioModule,
    FormsModule,
    MatDialogModule,
    UiKitModule,
    AuthModule.forRoot({
      redirectAfterLogin: '/dashboard',
    }),
    DisplayModule.forRoot({
      permissionsMapping: SaineGestionPermissionsMapping,
    }),
    PrivateModule.inject({
      toolsSidebar: new SaineGestionToolsSidebar(),
      pagesEnum: SAINE_GESTION_APP_PAGES,
      disableHeader: TeamsAuthService.isInTeams,
      disableSidebar: TeamsAuthService.isInTeams,
      consoleType: ConsoleType.SG,
    }),

    ...ngxs,
  ],
  providers: [
    ...RootSharedModule.getCommonProviders(),
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: true, disableClose: true } },
    { provide: STEPPER_GLOBAL_OPTIONS, useValue: { displayDefaultIndicatorType: false } },
  ],
  bootstrap: [ SaineGestionComponent ],
})
export class SaineGestionModule {
}
