import { Component, Input, OnInit } from '@angular/core';
import { BadgeEnum, imageMapping } from 'projects/@common/services/api/sg/md.eco-profile/md.eco-profile.definition';

@Component({
  selector: 'sg-badges-details',
  templateUrl: './badges-details.component.html',
  styleUrls: [ './badges-details.component.scss' ],
})
export class BadgesDetailsComponent implements OnInit {
  @Input() badgesList: BadgeEnum[];

  public badgeMapping = imageMapping;

  constructor () { }

  ngOnInit(): void {
  }
}
