import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { GroupTypeEnum } from '@ui-kit/util/icon-util';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { IGroupTemplate, IGroupTemplateFolder } from 'projects/@common/services/api/sg/group-templates/group-templates.definitions';

type TemplateWithDescription = IGroupTemplate & { descriptionExpanded: boolean; };

@Component({
  selector: 'sg-model-cards',
  templateUrl: './model-cards.component.html',
  styleUrls: [ './model-cards.component.scss' ],
})
export class ModelCardsComponent implements OnChanges {
  public readonly TEMPLATE_SELECTION_PARAM = 'isSelected';

  @Input() public selectedTemplate: IGroupTemplate = null;
  @Input() public templates: TemplateWithDescription[];
  @Input() public isLoading: boolean;
  @Input() public showChannels = true;

  @Output() public onTemplateSelection: EventEmitter<IGroupTemplate> = new EventEmitter();

  constructor(private i18n: I18nService) { }

  ngOnChanges(): void {
    if (this.selectedTemplate) {
      this.selectTemplate(this.selectedTemplate);
    }
  }

  public expandDescription(template: TemplateWithDescription): void {
    const index = this.templates.findIndex((templateThing) => templateThing.id === template.id);
    this.templates[index].descriptionExpanded = !this.templates[index].descriptionExpanded;
  }

  public shouldShowSubpermissions(permissions: any): boolean {
    return Object.values(permissions).some((permission) => permission);
  }

  public getFoldersSectionTitle(template: TemplateWithDescription): string {
    const translationKey = template.type === GroupTypeEnum.O365OFFICETEAMS ? 'groups.dialog.model.card.channels.title' : 'groups.dialog.model.card.folders.only.title';
    return this.i18n.translate(translationKey, {
      channelNumber: template.channels?.length || 0,
      folderNumber: this.computeFoldersTotal(template.folders),
    });
  }

  public getTotalPermissions(permissions: any): number {
    if (!permissions) {
      return 0;
    }
    const keys = Object.keys(permissions);
    let total = 0;
    keys.forEach((key) => {
      Object.values(permissions[key]).forEach((subpermission) => {
        total += subpermission ? 1 : 0;
      });
    });

    return total;
  }

  public getTranslatedGroupType(template: TemplateWithDescription): string {
    return this.i18n.translate(`groups.dialog.${template.type}.short`);
  }

  public computeFoldersTotal(folders: IGroupTemplateFolder[]): number {
    let count = 0;
    count += folders.length;
    folders.forEach((folder) => {
      if (folder.subFolders.length > 0) {
        count += this.computeFoldersTotal(folder.subFolders);
      }
    });
    return count;
  }

  private selectTemplate(template: IGroupTemplate): void {
    this.clearTemplateSelections();
    template[this.TEMPLATE_SELECTION_PARAM] = 'true';
    this.onTemplateSelection.emit(template);
  }

  private clearTemplateSelections(): void {
    this.templates.forEach((template) => (template[this.TEMPLATE_SELECTION_PARAM] = null));
  }
}
