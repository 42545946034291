import { animate, AnimationMetadata, state, style, transition, trigger } from '@angular/animations';

export const expandCollapseAnimation = (): AnimationMetadata => trigger('expandCollapse', [
  state(
    'collapse',
    style({
      height: 0,
      opacity: 0,
    })
  ),
  state(
    'expand',
    style({
      opacity: 1,
    })
  ),
  transition('collapse <=> expand', animate('500ms ease-in-out')),
]);
