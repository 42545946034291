import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'device-conformity-description',
  templateUrl: './device-conformity-description.component.html',
  styleUrls: [ './device-conformity-description.component.scss' ],
})
export class DeviceConformityDescriptionComponent implements OnInit {
  constructor () { }

  ngOnInit(): void {
  }
}
