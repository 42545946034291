import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { FontSizeTypeEnum } from '@ui-kit/components/ui-checkbox/interface/font-size-type.enum';

@Component({
  selector: 'sg-explanation-section',
  templateUrl: './explanation-section.component.html',
  styleUrls: [ './explanation-section.component.scss' ],
})
export class ExplanationSectionComponent implements OnInit {
  @Input() public isApplyDialog = false;

  @Input() public skipFirstStep = false;

  public checkboxFontSizeEnum = FontSizeTypeEnum;

  public explanationForm: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.explanationForm = this.fb.group({
      skipApplyExplanation: this.fb.control(this.skipFirstStep, []),
    });
  }

  public getMessageForModel(key: string): string {
    return this.isApplyDialog ? `${key}.model` : key;
  }
}
