<div
  class="card-container"
  [class.disabled]="disabled"
>
  <div class="card-container--icon-section">
    <img
      class="card-container--icon-section--logo"
      [class.disabled]="disabled"
      [src]="card.logo | t"
    >
    <img
      *ngIf="!card.isLoadingIcon && !disabled"
      class="card-container--icon-section--state-icon"
      [tooltip]="validityTooltip | t"
      [src]="card.stateLogo | t"
    >
    <ui-spinner
      *ngIf="card.isLoadingIcon && !disabled"
      class="card-container--icon-section--state-icon"
      size="small"
    ></ui-spinner>
  </div>

  <div class="card-container--card-text-action">
    <p
      class="card-container--card-text-action--card-title"
      [class.disabled]="disabled"
    >{{card.title | t}}</p>
    <p
      class="card-container--card-text-action--description"
      [class.disabled]="disabled"
    >{{card.description | t}}</p>
    <button
      [disabled]="disabled"
      class="button"
      (click)="handleClick(card.type)"
    >{{ buttonLabel | t}}</button>
  </div>
</div>