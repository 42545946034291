<table
  class="tables-table"
  [ngStyle]="{'padding-left': isApplyDialog ? null : '32px' }"
>
  <tr class="tables--headers">
    <th class="tables--headers--primary-column tables--headers--sortable">
      <div>{{ 'users.title' | t }} ({{total}})</div>
    </th>
    <th></th>
  </tr>
</table>
<div
  class="rows"
  [ngStyle]="{
    'height': isApplyDialog ? '40vh': null,
    'max-height': isApplyDialog ? null : '50vh' ,
    'padding-left': isApplyDialog ? null : '32px'
  }"
  *ngIf="!isLoading && users?.length > 0"
>
  <ng-container *ngFor="let user of users; let last = last;">
    <tr
      style="display: flex"
      [ngClass]="{ 'tables--row-separator': !last }"
    >
      <td class="tables--name-part">
        <div class="users-table--avatar">
          <ui-avatar size="small" [user]="user"></ui-avatar>
        </div>
        <div class="tables--name-part--name users-table--name">
          <div class="user-name" [title]="user.name">
              <span [class.users-table--disabled-user]="user.isDisabled">{{
                user.name
                }}</span>
            <div class="badges-container" *ngIf="showBadges">
                <span *ngIf="user.isGuest" class="badge guest">{{
                  'common.guest' | t
                  }}</span>
              <span *ngIf="user.isDisabled" class="badge disabled">{{
                'detection.activation.inactive.badge' | t
                }}</span>
              <span *ngIf="user.isOwner" class="badge owner">{{
                'ressource.badge.owner' | t
                }}</span>

              <span *ngIf="user.isPrivateChannelOwner" class="badge owner">{{
                'certify.badge.channelOwner' | t
                }}</span>
            </div>
          </div>
          <span
            class="tables--name-part--secondary-info"
            [title]="user?.upn"
          >
                {{ user?.upn }}
            </span>
        </div>
      </td>
      <td class="toggle-container">
        <mat-button-toggle-group *ngIf="!isToggleDisabled(user)"
                                 (valueChange)="onCertify.emit({id: user.o365UserId, isCertified: $event})"
                                 [(value)]="user.isCertified" class="toggle-group">
          <mat-button-toggle [value]="true" [class.disable-toggle]="isToggleDisabled(user)"
                             [class.toggle-confirm]="user.isCertified">{{ 'certify.confirm.button' | t }}</mat-button-toggle>
          <mat-button-toggle [value]="false" [class.disable-toggle]="isToggleDisabled(user)"
                             [class.toggle-revoke]="!isToggleDisabled(user) && !user.isCertified">{{ 'certify.revoke.button' | t }}</mat-button-toggle>
        </mat-button-toggle-group>
        <div class="owner-container" *ngIf="isToggleDisabled(user)">
          <p class="owner-message">{{'certify.confirm.owner.disable' | t}}</p>
          <img src="assets/favicons/icon_info.svg" [useUIToolTipFormat]="true"
               [tooltip]="'certify.confirm.owner.disable.info' | t">
        </div>
        <div class="waiting-icon-container" *ngIf="!isToggleDisabled(user)">
          <img *ngIf="user.isInactive" src="assets/favicons/icon_waiting.svg" [useUIToolTipFormat]="true" [tooltip]="'certify.inactive.info' | t">
        </div>
      </td>
    </tr>
  </ng-container>
</div>

