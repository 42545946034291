<div class="title-wrapper">
  <p class="title">{{'devices.gestion.device.description.title' | t}}</p>
  <img src="assets/favicons/icon_protection.svg">
</div>
<p class="text bold">{{ 'devices.gestion.description.text.preamble' | t }}</p>
<p class="subtitle">{{ 'devices.gestion.description.text.subtitle1' | t }} </p>
<div class="indicators">
  <div class="indicator">
    <div class="text-image">
      <img src="assets/favicons/icon_pause_ok.svg">
    </div>
    <p>{{ 'devices.gestion.description.text.subtext1' | t }}</p>
  </div>
  <div class="indicator">
    <div class="text-image">
      <img src="assets/favicons/icon_pause_warning.svg">
    </div>
    <p>{{ 'devices.gestion.description.text.subtext2' | t }}</p>
  </div>
</div>
<p class="subtitle">{{ 'devices.gestion.description.text.subtitle2' | t }}</p>

<div class="learn-more">
  <collapsable-description [title]="'devices.gestion.description.text.subtext3.title' | t" icon="assets/favicons/icon_paper.svg">
    <p>{{ 'devices.gestion.description.text.subtext3' | t }}</p> 
  </collapsable-description>
</div>

<!-- <a href="http://google.ca" class="outside-link"> -->
<!--   <p class="subtitle"> {{ 'common.learn.more' | t }} </p> -->
<!--   <img src="assets/favicons/icon_external_list.svg"> -->
<!-- </a> -->
