import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngxs/store';
import { IWarningMessage } from '@ui-kit/components/ui-warning/ui-warning.component';
import { IconUtil } from '@ui-kit/util/icon-util';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { DrawerService } from 'projects/@common/modules/layout/components/drawer/services/drawer.service';
import { EcoSessionState } from 'projects/@common/modules/session/state/session.state';
import { RequestsApiService } from 'projects/@common/services/api/requests/requests-api.service';
import { GetRequestResponse } from 'projects/@common/services/api/requests/requests.definitions';
import { MobileService } from 'projects/@common/services/mobile.service';

@Component({
  selector: 'request-form',
  templateUrl: './request-form.component.html',
  styleUrls: [ './request-form.component.scss' ],
})
export class RequestFormComponent implements OnInit, OnChanges {
  @Input() public request: GetRequestResponse;
  @Input() public isLoading = false;
  @Input() public includeReturnButton = false;
  @Input() public showDeclineButton = true;
  @Output() onCancelRequestEmitter = new EventEmitter();

  public requestForm: UntypedFormGroup;
  public userId: any;
  public status: string;
  public icon: string;
  public warningMessages: IWarningMessage[] = [];

  constructor(
    public readonly mobileService: MobileService,
    private readonly store: Store,
    private readonly requestsService: RequestsApiService,
    private readonly i18nService: I18nService,
    private readonly drawerService: DrawerService
  ) {
  }

  public ngOnInit() {
    this.userId = this.store.selectSnapshot(EcoSessionState.userGuid);
  }

  public ngOnChanges() {
    if (this.request) {
      this.setVariables();
    }
  }

  public handleReturnButtonClick() {
    this.drawerService.previousDrawer();
  }

  private setVariables() {
    this.status = this.i18nService.translate(`request.status.enum.${this.request.status}`);
    this.icon = IconUtil.getRequestStatusIcon(this.request?.status as any);
  }
}
