export enum OperationStatus {
  PENDING = "PENDING",
  QUEUED = "QUEUED",
  WARNING = "WARNING",
  ERROR = "ERROR",
  PROCESSING = "PROCESSING",
  CRITICAL = "CRITICAL",
  COMPLETED = "COMPLETED",
  CANCELLED = "CANCELLED"
}

export interface IOperationManagerJobDetails {
  jobStatus: IOperationManagerStatus;
  operations: IOperationDetail[];
}

export interface IOperationManagerStatus {
  operationsCount: number;
  success: number;
  error: number;
  critical: number;
}

export interface IOperationDetail {
  guid: string;
  name: string;
  createdAt: number;
  error: string;
  status: OperationStatus;
  payload: any;
}

