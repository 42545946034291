import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AutocompleteTypes } from '@ui-kit/components/autocomplete/autocomplete.component';
import { WarningColors } from '@ui-kit/components/ui-warning/ui-warning.component';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { IdentityProviderTypes, List, ListUsersResponse } from 'projects/@common/services/api/iam/identities/identities.api.definitions';
import { GroupTemplateReach } from 'projects/@common/services/api/sg/group-templates/group-templates.definitions';
import { OrganizationsApiService } from 'projects/@common/services/api/sg/organizations/organizationsApi.service';
import { UsersApiService } from 'projects/@common/services/api/sg/user/userApi.service';
import { GroupTemplateFormService } from 'projects/console-org/src/app/csp/group-templates/services/group-template-form.service';

@Component({
  selector: 'default-approving',
  templateUrl: './default-approving.component.html',
  styleUrls: ['./default-approving.component.scss'],
})
export class DefaultApprovingComponent implements OnInit , OnChanges {
  @Input() defaultApprovers: string[];
  @Input() isEditing: boolean;
  @Input() groupTemplateForm: GroupTemplateFormService;

  @Output() onDefaultApproverDelete: EventEmitter<any> = new EventEmitter();
  @Output() onClearApprovers: EventEmitter<any> = new EventEmitter();

  public segmentationIsDeactivated = false;
  public isLoadingPossibleValues = false;
  public groupTemplateReach = GroupTemplateReach;
  public usersAutocomplete = AutocompleteTypes.USERS;
  public warningColors = WarningColors;
  public reachOptions = [];
  public customAutocompleteType = AutocompleteTypes.CUSTOM;
  public customValues = [];
  public messages = [{ messageKey: this.i18n.translate('settings.general.segmentation.autocomplete.empty')  }]


  constructor(public i18n: I18nService, private organizationApiService: OrganizationsApiService, private userApiService: UsersApiService) {
  }

  ngOnChanges(simpleChanges: SimpleChanges){
    if(simpleChanges?.defaultApprovers?.previousValue?.length !== simpleChanges?.defaultApprovers?.currentValue?.length ){
      this.handleApproversChange()
    }
  }

  public get canDisplaySegment(): boolean {
    return this.groupTemplateForm.approversForm.value.reach === this.groupTemplateReach.SEGMENTED && this.isEditing && !this.isLoadingPossibleValues;
  }

  public get canEditApproversAutocomplete(): boolean {
    return this.groupTemplateForm.approversForm.value.reach === GroupTemplateReach.SEGMENTED ? this.groupTemplateForm.approversForm?.value?.attribute[0]?.value && this.isEditing : false;
  }

  public get selectedAttribute(): string {
    return this.groupTemplateForm.approversForm?.value?.attribute?.[0]?.displayValue;
  }

  ngOnInit() {
    this.reachOptions = this.getReachOptions();
    this.isLoadingPossibleValues = true;
    this.organizationApiService
      .getOrganizationAttributeValues()
      .then((response) => {
        this.customValues = response
          .map((possibleValue) => ({
            value: possibleValue.id, displayValue: possibleValue.name,
          }))
          .sort((a, b) => a.value.localeCompare(b.value));
      })
      .catch((error) => {
        if (error.status === 424) {
          this.segmentationIsDeactivated = true;
        }
      })
      .finally(() => {
        this.isLoadingPossibleValues = false;
        this.reachOptions = this.getReachOptions();
      });
  }

  public removeDefaultApprover(defaultApprover: any): void {
    this.onDefaultApproverDelete.emit(defaultApprover);
  }

  public handleApproversChange(){
    if(this.defaultApprovers.length === 1 && this.defaultApprovers.length > 0){
      this.messages= [{ messageKey: this.i18n.translate('settings.general.segmentation.autocomplete.empty')  }, { messageKey: this.i18n.translate('groupTemplates.details.defaultApproving.single.approver')  }]
    } else {
      this.messages = [{ messageKey: this.i18n.translate('settings.general.segmentation.autocomplete.empty')  }]
    }
  }

  public selectReach(reach: string): void {
    this.groupTemplateForm.approversForm.patchValue({ reach });
  }

  public clearAttribute(): void {
    this.onClearApprovers.emit();
    this.groupTemplateForm.approversForm.patchValue({ attribute: [] });
    this.handleApproversChange()
  }

  public onAttributeSelected(attribute: string): void {
    this.onClearApprovers.emit();
    this.groupTemplateForm.approversForm.patchValue({
      ...this.groupTemplateForm.approversForm.value, attribute: attribute ? [attribute] : [],
    });
  }

  public usersAutocompleteSearchFunction = (params: any): Promise<List<ListUsersResponse, number | string, {
    idpType: IdentityProviderTypes
  }>> => this.segmentationIsDeactivated ? this.userApiService.listResourceApprovers({
    limit: 999, usersOnly: true, fulltext: params.search
  }) : this.userApiService.getUsersBySegmentationAttribute({
    attributeValue: this.groupTemplateForm.approversForm.value.attribute[0].value, searchQuery: params.search,
  });

  private getReachOptions() {
    return [{ value: GroupTemplateReach.GLOBAL, displayValue: 'default.approving.reach.global' }, {
      value: GroupTemplateReach.SEGMENTED,
      displayValue: this.segmentationIsDeactivated ? 'default.approving.reach.specific' : 'default.approving.reach.specific.segment'
    }];
  }
}
