import { Injectable } from '@angular/core';
import { BaseSidebar, FeatureFlagsEnum, ServiceTypeEnum } from '@common/modules/layout/definitions/base-sidebar.service';
import { DisplayRequirements } from 'projects/@common/modules/display/display.service';

@Injectable()
export class SaineGestionToolsSidebar extends BaseSidebar {
  private elements = [
    {
      rootAction: true,
      name: 'dashboard.title',
      icon: 'icon_admin_menu_resources',
      route: '/dashboard',
    },
    {
      rootAction: true,
      name: 'sidebar.resources',
      icon: 'icon_admin_menu_resources',
      route: '/teamsResources',
      flags: [ FeatureFlagsEnum.RESOURCE_MANAGEMENT ],
    },
    {
      rootAction: true,
      name: 'sidebar.navigation.workspace.sub.shares.name',
      icon: 'icon_admin_menu_shares',
      route: '/myshares',
      flags: [ FeatureFlagsEnum.SHARES_MANAGEMENT ],
    },
    {
      rootAction: true,
      name: 'sidebar.navigation.workspace.sub.dlp.name',
      icon: 'icon_admin_menu_shares',
      route: '/files',
      flags: [ FeatureFlagsEnum.DLP_MANAGEMENT ],
    },
    {
      rootAction: true,
      name: 'sidebar.navigation.workspace.sub.requests.name',
      icon: 'icon_admin_menu_requests',
      route: '/requests',
    },
  ];

  constructor() {
    super();
  }

  public unsubscribe() {
  }

  public getElements() {
    return this.elements;
  }

  public getRequirements(): DisplayRequirements {
    return {
      services: [ ServiceTypeEnum.PROACTIVE_CYBERSECURITY ],
    };
  }
}
