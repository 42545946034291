import { Injectable } from '@angular/core';
import { StorageService } from '../../../storage.service';
import { TemporaryDataAbstract } from './temporary-data.abstract';

interface IRevokedShares {
  jobGuid: string;
  sharedFileIds: string[];
  date: number;
};

@Injectable({
  providedIn: 'root',
})
export class SharesTemporaryDataService extends TemporaryDataAbstract {
  protected storageKey = 'revokedShares';
  constructor(protected storageService: StorageService) {
    super(storageService);
  }

  public addRevokeShares(jobGuid: string, sharedFileIds: string[]): void {
    const ttlRevokedShares: IRevokedShares = { jobGuid, sharedFileIds, date: Date.now() };
    this.add(ttlRevokedShares, 'jobGuid');
  }

  public removeRevokedShares(jobGuids: string[]): void {
    this.remove(jobGuids, 'jobGuid');
  }

  public get revokedSharesJobGuids(): string[] {
    return this.getFilteredValue(this.storageKey).map((data) => data.jobGuid);
  }

  public getPendingSharedFileIds(pendingJobGuids: string[]): string[] {
    const current: IRevokedShares[] = this.getFilteredValue(this.storageKey);
    const pending = current.filter((data) => pendingJobGuids.includes(data.jobGuid));
    return [ ...new Set(pending.map((data) => data.sharedFileIds).flat(1)) ];
  }
}
