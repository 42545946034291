<widget-container
  [title]="'dashboard.admin.usersSharedFiles.title' | t"
  [withIcon]="true"
  iconName="icon_users_widget"
  [withBottomMargin]="isFromConsoleOrg"
  [locale]="locale"
>
  <div *ngIf="hasLevelWithShares && !isLoading" class="admin-users-shares--content">
    <div>
      <dynamic-chart
        [locale]="i18n.currentLocale"
        [legend]="true"
        [data]="usersSharesData"
        name="usersShares"
        type="DonutChart"
        key="level"
        [series]="['count']"
        [innerSeriesLabel]="{
          count: stats?.highCount + stats?.moderateCount + stats?.lowCount,
        }"
        pieChartHeight="120px"
        pieChartRadius="30"
        pieChartInnerRadius="70"
        [pieChartWidth]="mobileService.windowWidthSmallerThan(450) ? chartWidth : '380px'"
        [legendTemplate]="legendTemplate"
        [hasLegendRedirection]="isFromConsoleOrg"
        (redirectionValue)="handleLegendItemClick($event)"
      ></dynamic-chart>
    </div>
    <ng-container *ngIf="isFromConsoleOrg">
      <div class="admin-users-shares--content--text">
        {{ 'dashboard.admin.usersSharedFiles.text' | t }}
      </div>
      <div class="admin-users-shares--content--action" (click)="handleAction()">
        {{ 'dashboard.admin.usersSharedFiles.action' | t }}
      </div>
    </ng-container>
  </div>
  <ui-spinner *ngIf="isLoading"></ui-spinner>
  <div *ngIf="!hasLevelWithShares && !isLoading" class="admin-tendency-widget--no-history">
    {{ (customEmptyMessageKey ?? 'dashboard.admin.noData') | t }}
  </div>
</widget-container>
