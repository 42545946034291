<sg-page-layout
  [title]="'devices.gestion.device.title' | t"
  [breadcrumbs]="breadcrumbs"
  [documentationLink]="documentationSection"
  (onTooltipEvent)="openDescription()"
>
  <ng-container *ngIf="!isLoading; else spinner">
    <sg-banners
      [type]="getBannerType()"
      [text]="getBannerMessage()"
    ></sg-banners>

    <ng-container *ngIf="devices.length > 0; else noDevice">
      <div class="card-container">
        <ng-container *ngFor="let device of devices">
          <sg-expendable-card #expendable>
            <sg-resource-card-header
              header
              class="full-width"
              [info]="device"
            ></sg-resource-card-header>
            <sg-resource-card-content
              content
              [hideDescriptionInMobile]="false"
              class="full-width"
              [info]="device"
            ></sg-resource-card-content>
          </sg-expendable-card>
        </ng-container>
      </div>
    </ng-container>

  </ng-container>

  <div
    class="page-description"
    description
  >
    <device-conformity-description></device-conformity-description>
  </div>
</sg-page-layout>

<ng-template #spinner>
  <ui-spinner
    class="spinner-container"
    size="medium"
    modifier="secondary"
  ></ui-spinner>
</ng-template>

<ng-template #noDevice>
  <div class="no-device">
    <p>{{'devices.gestion.no_device.message' | t }} </p>
    <img
      class="no-device-image"
      src="assets/favicons/logo_madame_pied_table.svg"
    />
  </div>
</ng-template>

<ng-template #mobileDescription>
  <device-conformity-description></device-conformity-description>
</ng-template>
