import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TeamsApiService } from 'projects/@common/services/api/sg/teams/teams-api.service';
import { TeamsAuthService } from 'projects/@common/services/teams-auth.service';

@Component({
  selector: 'sg-request-awaiting',
  templateUrl: './request-awaiting.component.html',
  styleUrls: [ './request-awaiting.component.scss' ],
})
export class RequestAwaitingComponent implements OnInit {
  @Input()
  public userTotalRequest: number;

  constructor (private teamsService: TeamsApiService, private router: Router) { }

  ngOnInit(): void {
  }

  public get isInTeams() {
    return TeamsAuthService.isInTeams;
  }

  public async goToRequests() {
    this.router.navigateByUrl('/requests');
  }
}
