import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UiKitModule } from '@ui-kit/uikit.module';
import { AssistOpsModule } from 'projects/@assist-ops/assist-ops.module';
import { ComponentsModule } from 'projects/@common/components/components.module';
import { DirectivesModule } from 'projects/@common/modules/directives/directives.module';
import { I18nModule } from 'projects/@common/modules/i18n/i18n.module';
import { SgSharedModule } from '../../shared/shared.module';
import { DeviceConformityDescriptionComponent } from './device-conformity-description/device-conformity-description.component';
import { DeviceConformityComponent } from './device-conformity.component';
import { DeviceConformityRoutingModule } from './device-conformity.routing.module';

const components = [
  DeviceConformityComponent,
  DeviceConformityDescriptionComponent,
];

@NgModule({
  declarations: [ ...components ],
  exports: [ ...components ],
  imports: [
    I18nModule,
    SgSharedModule,
    UiKitModule,
    CommonModule,
    DirectivesModule,
    AssistOpsModule,
    DeviceConformityRoutingModule,
    ComponentsModule,
  ],
})
export class DeviceConformityModule { }
