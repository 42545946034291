import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MobileService } from "projects/@common/services/mobile.service";
import { PageDescriptions } from "../../shared/components/page-layout/page-descriptions.enum";

@Component({
  selector: 'sg-mobile-description-wrapper',
  templateUrl: './mobile-description-wrapper.container.html',
  styleUrls: [ './mobile-description-wrapper.container.scss' ],
})
export class MobileDescriptionWrapperContainer implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: { pageDescription: PageDescriptions },
    public mobileService: MobileService
  ) {}

  public pageDescriptions = PageDescriptions;

  ngOnInit(): void {

  }

  public close(): void {
    this.dialogRef.close();
  }
}
