<div>
  <ng-container *ngIf="!isLoading; else loadingAnimation">
    <ng-container
      *ngFor="
        let task of tasks;
        let first = first;
        let last = last;
        let index = index
      "
    >
      <ng-container *ngIf="task.device">
          <breaktime-task
            *ngIf="index < DEFAULT_TASKS_NUMBER_TO_SHOW || showMore"
            [isFirst]="first"
            [isLast]="
              last || (!showMore && index === DEFAULT_TASKS_NUMBER_TO_SHOW - 1)
            "
            [task]="task"
            [currentUser]="currentUser"
            (removeTask)="removeTask($event)"
          ></breaktime-task>
      </ng-container>
      <ng-container *ngIf="!task.device">
        <breaktime-task
          *ngIf="index < DEFAULT_TASKS_NUMBER_TO_SHOW || showMore"
          [isFirst]="first"
          [isLast]="
            last || (!showMore && index === DEFAULT_TASKS_NUMBER_TO_SHOW - 1)
          "
          [task]="task"
          [currentUser]="currentUser"
          (removeTask)="removeTask($event)"
        ></breaktime-task>
      </ng-container>
    </ng-container>

    <section
      *ngIf="tasks?.length < 1"
      class="empty-tasks"
      [ngClass]="{ 'empty-tasks--mobile': mobileService.isMobile() }"
    >
      <img src="assets/favicons/icon_congrats.svg" />
      <div class="empty-tasks--congrats">
        {{ 'dashboard.breaktime.tasks.empty.congrats' | t }}
      </div>
      <div class="empty-tasks--message">
        {{ 'dashboard.breaktime.tasks.empty.message' | t }}
      </div>
    </section>
  </ng-container>

  <div
    *ngIf="tasks.length > DEFAULT_TASKS_NUMBER_TO_SHOW"
    class="show-more-tasks"
    [ngClass]="{ 'show-more-tasks--mobile': mobileService.isMobile() }"
    (click)="toggleShowMore()"
  >
    {{
      'dashboard.breaktime.tasks.' + (showMore ? 'showLess' : 'showMore') | t
    }}
  </div>
</div>

<ng-template #loadingAnimation>
  <ui-spinner></ui-spinner>
</ng-template>
