<sg-page-layout
  [title]="'shares.list' | t"
  [breadcrumbs]="breadcrumbs"
  [documentationLink]="documentationSection"
  [showRightSide]="showRightSide"
  (onTooltipEvent)="openDescription()"
  (onScrollToBottom)="scroll()"
>
  <div [ngClass]="{ 'hidden': (!isResourcesLoading && !isSharedFilesLoading) }">
    <ui-spinner></ui-spinner>
  </div>
  <div *ngIf="resourceGuid || !hasResourcesWithShares">
    <ng-container *ngIf="hasPersonnalShares > 0 || isFiltered; else noRiskyShares">
      <div [ngClass]="{ 'hidden': isSharedFilesLoading }">
        <shared-files-table
          [disableExpressRevoke]="disableExpressRevokePersonnal"
          (refresh)="refreshBanner(true)"
          [hasResourcesWithShares]="false"
          (isSharedFilesLoading)="isSharedFilesLoadingFunc()"
          (sharesNumber)="getSharesNumber($event)"
        ></shared-files-table>
      </div>
    </ng-container>
  </div>

  <div *ngIf="!resourceGuid && hasResourcesWithShares">
    <div [ngClass]="{ 'hidden': isResourcesLoading || isSharedFilesLoading }">
      <div [ngClass]="{ 'ui-tabs-hidden': false }">
        <ui-tabs
          #tabs
          [color]="tabColor"
          (tabClicked)="updateQueryParams()"
        >

          <ui-tab
            [icon]="sharesIcon"
            [iconOpacity]="1.0"
            [tabTitle]="'myShares.title' | t"
          >
            <ng-container *ngIf="hasPersonnalShares > 0 || isFiltered; else noRiskyShares">
              <sg-banners
                *ngIf="hasHighRisksResources.riskUserSharesHIGH"
                class="warning"
                [type]="'warning'"
                [text]="'shares.dialog.warning.highRisk.shares' | t"
              ></sg-banners>
              <div class="shares-container">

              <shared-files-table
                (refresh)="refreshBanner(true)"
                [disableExpressRevoke]="disableExpressRevokePersonnal"
                [hasResourcesWithShares]="hasResourcesWithShares"
                [requiredWidthForMobile]="1000"
                (isSharedFilesLoading)="isSharedFilesLoadingFunc()"
                (sharesNumber)="getSharesNumber($event)"
              ></shared-files-table>
              </div>
            </ng-container>
          </ui-tab>


          <ui-tab
            [icon]="resourcesIcon"
            [iconOpacity]="1.0"
            [tabTitle]="'myShares.resources.title' | t"
          >
            <ng-container *ngIf="hasResourcesWithShares > 0; else noRiskyShares">
              <div class="shares-container">
                <sg-banners
                  *ngIf="hasHighRisksResources.riskUserResources"
                  class="warning no-padding"
                  [type]="'warning'"
                  [text]="'shares.dialog.warning.highRisk.resources' | t"
                ></sg-banners>
                <resources-shares-table
                  [disableExpressRevoke]="disableExpressRevokeResources"
                  (refresh)="refreshBanner(false)"
                  [hasPersonnalShares]="hasHighRisksResources.riskUserSharesHIGH"
                  (resourcesNumber)="getResourcesNumber($event)"
                ></resources-shares-table>
              </div>
            </ng-container>
          </ui-tab>
        </ui-tabs>

      </div>
    </div>
  </div>

  <div
    class="page-description"
    description
  >
    <shares-description></shares-description>
  </div>

</sg-page-layout>

<ng-template #noRiskyShares>
  <div class="no-shares">
    <img
      class="image"
      src="assets/favicons/icon_pause_ok_green_background.svg"
    />
    <div class="text">
      <p class="title">{{'shares.none.atRisk.title' | t }} </p>
      <p class="description">{{'shares.none.atRisk.subTitle' | t }} </p>
    </div>
  </div>
</ng-template>

<ng-template #mobileDescription>
  <shares-description></shares-description>
</ng-template>
