import { Component, Input, OnChanges, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { AbstractDrawer } from '@common/modules/layout/components/drawer/AbstractDrawer';
import { SensitiveFilesApiService } from '@common/services/api/sg/files/sensitive-files-api.service';
import { MobileService } from '@common/services/mobile.service';
import { Store } from '@ngxs/store';
import { UiTabs } from '@ui-kit/components/ui-tabs/ui-tabs.component';
import { LanguageEnum } from '@ui-kit/interfaces/ILanguage';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { DrawerService } from 'projects/@common/modules/layout/components/drawer/services/drawer.service';
import { NoticeLevels, NoticeService } from 'projects/@common/modules/notice/notice.service';

export interface ISensitiveFileDrawerData {
  fileId: string;
  name: string;
  path: string;
  createdAt: number;
  sourceType: string;
  risk: string;
  lastScanned: number;
  upn: string;
  likelihood: number;
  hasJustification: boolean;
  fullName: string;
  oid: string;
  patterns: {
    [key: string]: number;
  };
  createdBy: string;
  dlpType: string;
  similarFileNames: string[];
}

@Component({
  selector: 'sensitive-file-drawer',
  templateUrl: './sensitive-file-drawer.container.html',
  styleUrls: [ './sensitive-file-drawer.container.scss' ],
  host: {
    class: 'sensitive-file-drawer',
  },
  encapsulation: ViewEncapsulation.None,
})
export class SensitiveFileDrawer extends AbstractDrawer implements OnChanges, OnInit {
  @Input() public data: { id: string; fileName: string } = null;
  @ViewChild('tabs', { static: true }) private tabs: UiTabs;

  public file: ISensitiveFileDrawerData = null;
  public isLoading = false;

  constructor(
    protected readonly store: Store,
    public readonly mobileService: MobileService,
    private readonly noticeService: NoticeService,
    private drawerService: DrawerService,
    private sensitiveFilesApiService: SensitiveFilesApiService,
    private i18n: I18nService
  ) {
    super(store);
  }

  ngOnInit() {
    this.loadFile();
  }

  public ngOnChanges() {
    super.ngOnChanges();
  }

  public setState(state: any): void {
    this.tabs.selectTabIndex(state.tabIndex);
  }

  public getState(): any {
    return {
      tabIndex: this.tabs.getSelectedTabIndex(),
    };
  }

  private loadFile() {
    this.isLoading = true;
    this.sensitiveFilesApiService
      .describeSensitiveFile(this.data.id, this.i18n.currentLocale as LanguageEnum)
      .then((response) => {
        this.file = response;
      })
      .catch((e) => {
        this.noticeService.notifyUser({ message: 'sensitiveFiles.describe.error', level: NoticeLevels.ERROR });
        this.drawerService.hideDrawer();
      })
      .finally(() => {
        this.isLoading = false;
      });
  }
}
