<base-dialog
  class="min-width"
  [title]="'groups.dialog.apply.title' | t: { groupName: data.group.displayName }"
  [isEditing]="true"
  [hasStepper]="true"
  (onCancel)="dialogRef.close()"
>
  <sg-stepper (onCancel)="dialogRef.close()" (onSave)="save()" [isSaving]="isSaving" #sgStepper>
    <ng-template
      sg-step
      [stepName]="'groups.dialog.intro.stepper' | t"
      [canGoForward]="true"
      [saveButton]="false"
      (onNavigation)="updateForm(explanationSection?.explanationForm, $event)"
    >
      <div class="step">
        <sg-explanation-section
          #explanation
          [isApplyDialog]="true"
          [skipFirstStep]="shouldSkipExplanation"
        ></sg-explanation-section>
      </div>
    </ng-template>

    <ng-template
      sg-step
      [stepName]="'create.group.step.model' | t"
      [canGoForward]="modelSection?.modelForm.valid"
      [saveButton]="false"
      (onNavigation)="updateForm(modelSection?.modelForm, $event)"
    >
      <div class="step">
        <sg-model-section
          #model
          [currentTemplateId]="template?.id"
          [modelType]="data.group.type"
          [showChannels]="false"
        ></sg-model-section>
      </div>
    </ng-template>

    <ng-template
      *ngIf="showStructureSection"
      sg-step
      [stepName]="'groups.dialog.apply.structure.step' | t"
      [canGoForward]="true"
      [saveButton]="false"
      (onNavigation)="updateForm(structureSection?.structureForm, $event)"
    >
      <div class="step">
        <sg-structure-section
          #structure
          [template]="template"
          [isApplyDialog]="true"
          [keepStructure]="requestForm.value.keepStructure"
        ></sg-structure-section>
      </div>
    </ng-template>

    <ng-template
      *ngIf="data?.group?.isCertifyExpired"
      sg-step
      [stepName]="'groups.dialog.apply.access.step' | t"
      [canGoForward]="certifyIsLoaded"
      [saveButton]="false"
      [customFooter]="certifyBottomIndicators"
      (onNavigation)="updateForm(null, $event)"
    >
      <div class="step">
        <sg-certify-section
          #certify
          [dataFromQuickAction]="data"
          [firstLoad]="requestForm.value.revokeUsers?.length === 0"
          [cloneUsers]="requestForm.value.revokeUsers"
          (hasLoaded)="handleCertifyLoaded()"
        ></sg-certify-section>
      </div>
    </ng-template>

    <ng-template
      sg-step
      [stepName]="'groups.dialog.apply.confirmation.step' | t"
      [canGoForward]="true"
      [saveButton]="true"
    >
      <div class="step">
        <sg-apply-confirmation-section
          #confirmation
          [resourceName]="requestForm.value.requestInformation?.name"
          [template]="template"
          [keepStructure]="requestForm.value.keepStructure"
          [certifiedUsers]="requestForm.value.revokeUsers"
          [neededCertification]="data?.group?.isCertifyExpired"
        ></sg-apply-confirmation-section>
      </div>
    </ng-template>
  </sg-stepper>
</base-dialog>

<ng-template #certifyBottomIndicators>
  <div class="bottom-indicators">
    <certify-bottom-indicators
      [isLoading]="certifySection?.isLoading"
      [unfilteredUsers]="certifySection?.cloneUsers"
    ></certify-bottom-indicators>
  </div>
</ng-template>
