<ui-tabs #tabs color="drawerTabs">
  <ui-tab
    [tabName]="'form'"
    [tabTitle]="'requests.drawer.creation.tab.request' | t"
    [isShown]="
      !(isEditing$ | async) || (tabName$ | async) === 'form'
    "
  >
    <request-form
      *ngIf="!isLoading"
      [request]="request"
      [isLoading]="isLoading || isLoading"
      [includeReturnButton]="data.includeReturnButton"
      [showDeclineButton]="request.type !== 'RESOURCE_ARCHIVING'"
    ></request-form>
  </ui-tab>

  <ng-container *ngIf="displayDetailsTab">
    <ui-tab
      [tabName]="'details'"
      [tabTitle]="'requests.drawer.creation.tab.context' | t"
      [isShown]="
        !(isEditing$ | async) || (tabName$ | async) === 'details'
      "
    >
      <request-details
        [request]="request"
        [hasNotBeenProcessed]="hasNotBeenProcessed"
        [isLoading]="isLoading"
      ></request-details>
    </ui-tab>

    <ui-tab
      [tabName]="'history'"
      [tabTitle]="'requests.drawer.tab.history' | t"
      [isShown]="
      !(isEditing$ | async) || (tabName$ | async) === 'history'
    "
    >
      <request-history
        [isLoading]="isLoading"
        [requestApproversHistory]="requestApproversHistory"
      ></request-history>
    </ui-tab>
  </ng-container>

  <ng-container *ngIf="!displayDetailsTab">
    <ui-tab
      [tabName]="'history'"
      [tabTitle]="'requests.drawer.tab.history' | t"
      [isShown]="
      !(isEditing$ | async) || (tabName$ | async) === 'history'
    "
    >
      <request-history
        [isLoading]="isLoading"
        [requestApproversHistory]="requestApproversHistory"
      ></request-history>
    </ui-tab>
  </ng-container>
</ui-tabs>
