import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, Store } from '@ngxs/store';
import { UiTabsColor } from '@ui-kit/components/ui-tabs/ui-tabs.component';
import { IBreadcrumbs } from 'projects/@assist-ops/components/breadcrumbs/breadcrumbs.component';
import { SharedFilesTableComponent } from 'projects/@assist-ops/components/shared-files-table/shared-files-table.component';
import { EcoSessionState } from 'projects/@common/modules/session/state/session.state';
import { ResourceSharesApiService } from 'projects/@common/services/api/sg/resourceSharedFiles/resource-shares-api.service';
import { MobileService } from 'projects/@common/services/mobile.service';
import { TeamsAuthService } from 'projects/@common/services/teams-auth.service';
import { PageDescriptions } from 'projects/console-sg/src/app/shared/components/page-layout/page-descriptions.enum';
import { PageDescriptionService } from 'projects/console-sg/src/services/page-description.service';

@Component({
  selector: 'shares-resources',
  templateUrl: './shares-resources.container.html',
  styleUrls: [ './shares-resources.container.scss' ],

})
export class SharesResourcesContainer implements OnInit {
  @ViewChild(SharedFilesTableComponent) private sharedFilesTable: SharedFilesTableComponent;

  public breadcrumbs: IBreadcrumbs[] = [
    { nodeName: 'breadcrumb.dashboard', nodeLink: '/dashboard' },
    { nodeName: 'breadcrumb.teams.shares', nodeLink: '/myshares', nodeParams: { tabIndexSelected: '1' } },
    { nodeName: 'breadcrumb.teams.shares.resources', nodeLink: null },
  ];

  public hasHighRisksResources: { riskUserShares?: boolean, riskUserResources?: boolean; } = {};

  public sharesIcon = null;

  public resourcesIcon = null;

  public name = this.route.snapshot.queryParams.name;

  public userGuid = this.route.snapshot.queryParams.userGuid;

  public isMyShares: boolean;

  public isResourcesLoading = true;

  public isSharedFilesLoading = true;
  public resourceGuid = this.route.snapshot.queryParams.guid;

  public disableExpressRevokeResources = false;
  private totalResourcesNumber = 0;

  constructor(
    protected readonly store: Store,
    protected readonly actions$: Actions,
    private route: ActivatedRoute,
    private pageDescriptionService: PageDescriptionService,
    private router: Router,
    public mobileService: MobileService,
    private resourceSharesApiService: ResourceSharesApiService
  ) {
  }

  public get hasResourcesWithShares(): boolean {
    return this.totalResourcesNumber > 0;
  }

  public get tabColor(): string {
    return TeamsAuthService.isInTeams ? UiTabsColor.TeamsTabs : UiTabsColor.lightTabs;
  }

  public get specialPageName(): string {
    if (this.isResourcesLoading) {
      return '';
    }

    return this.hasResourcesWithShares ? 'shares.list' : 'myShares.list';
  }

  public async ngOnInit() {
    this.isMyShares = this.router.url.includes('/shares/myShares');

    if (this.isMyShares) {
      this.userGuid = this.store.selectSnapshot(EcoSessionState.userGuid);
    }

    if (!this.router.url.includes('resources') && !this.isMyShares) {
      this.userGuid = this.route.snapshot.params.guid;
      this.name = this.route.snapshot.queryParams.name;
    }
    this.expressRevokedDisabled();
  }

  public openDescription(): void {
    this.pageDescriptionService.openTooltip(PageDescriptions.RESOURCES_SHARES);
  }

  public getResourcesNumber(total: number): void {
    this.totalResourcesNumber = total;
    this.isResourcesLoading = false;
  }

  public isSharedFilesLoadingFunc(): void {
    this.isSharedFilesLoading = false;
  }

  public scroll(): void {
    this.sharedFilesTable.onScroll();
  }

  public onRefresh() {
    this.expressRevokedDisabled();
  }

  public expressRevokedDisabled() {
    this.resourceSharesApiService.getResourceFilesSharesTypesNumbers(this.resourceGuid, { includeSilenced: true })
      .then((response) => {
        this.disableExpressRevokeResources = response.riskHigh === 0 && response.riskModerate === 0;
      })
      .catch(() => {});
  }
}
