import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatRadioModule } from '@angular/material/radio';
import { UiKitModule } from '@ui-kit/uikit.module';
import { AssistOpsModule } from 'projects/@assist-ops/assist-ops.module';
import { I18nModule } from 'projects/@common/modules/i18n/i18n.module';
import { FeatureSharedModule } from 'projects/@common/modules/shared/feature.shared.module';
import { DashboardCardComponent } from './components/dashboard-card/dashboard-card.component';
import { MobileDashboardCardComponent } from './components/mobile-dashbord-card/mobile-dashboard-card.component';
import { MyProfileComponent } from './components/my-profile/my-profile.component';
import { MySharedAssetsComponent } from './components/my-shared-assets/my-shared-assets.component';
import { NewBadgeBannerComponent } from './components/new-badge-banner/new-badge-banner.component';
import { PauseCardComponent } from './components/pause-eco/components/pause-card/pause-card.component';
import { PauseEcoComponent } from './components/pause-eco/pause-eco.component';
import { QuickActionsSelectorComponent } from './components/quick-actions/components/quick-actions-selector/quick-actions-selector.component';
import { QuickActionsComponent } from './components/quick-actions/quick-actions.component';
import { RequestAwaitingComponent } from './components/request-awaiting/request-awaiting.component';
import { BreaktimeTask } from './components/user/eco-breaktime/breaktime-task/breaktime-task.component';
import { EcoBreaktime } from './components/user/eco-breaktime/eco-breaktime.component';
import { HighRiskSharesComponent } from './components/user/high-risk-shares/high-risk-shares.component';
import { SharesTypeProportionsComponentV2 } from './components/user/shares-type-proportions-v2/shares-type-proportions-v2.component';
import { DashboardContainer } from './containers/dashboard.container';
import { UserDashboardMobileContainer } from './containers/user-dashboard-mobile/user-dashboard-mobile.container';
import { UserDashboardContainer } from './containers/user-dashboard/user-dashboard.component';
import { DashboardRoutingModule } from './dashboard-routing.module';

@NgModule({
  declarations: [
    DashboardContainer,
    UserDashboardContainer,
    DashboardCardComponent,
    EcoBreaktime,
    BreaktimeTask,
    MySharedAssetsComponent,
    UserDashboardMobileContainer,
    MobileDashboardCardComponent,
    HighRiskSharesComponent,
    SharesTypeProportionsComponentV2,
    QuickActionsComponent,
    PauseEcoComponent,
    PauseCardComponent,
    RequestAwaitingComponent,
    QuickActionsComponent,
    QuickActionsSelectorComponent,
    NewBadgeBannerComponent,
    MyProfileComponent,
  ],
  imports: [
    MatButtonToggleModule,
    MatRadioModule,
    FormsModule,
    DashboardRoutingModule,
    CommonModule,
    UiKitModule,
    AssistOpsModule,
    FeatureSharedModule,
    I18nModule,
    AssistOpsModule,
  ],
})
export class DashboardModule {}
