import { Component, OnInit, Input } from '@angular/core';
import { IGroupTemplate } from 'projects/@common/services/api/sg/group-templates/group-templates.definitions';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { GroupTypeEnum } from '@ui-kit/util/icon-util';

@Component({
  selector: 'sg-structure-section',
  templateUrl: './structure-section.component.html',
  styleUrls: [ './structure-section.component.scss' ],
})
export class StructureSectionComponent implements OnInit {
  @Input() public template: IGroupTemplate;
  @Input() public keepStructure = false;

  public groupTypeEnum = GroupTypeEnum;

  public structureForm: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.structureForm = this.fb.group({
      keepStructure : this.fb.control(this.keepStructure, [ Validators.required ]),
    });
  }
}
