<div class="shared-files-mobile-container">
  <div class="mobile-toolbar" *ngIf="mobileService.isMobile()">
    <div class="tools-container">
      <ui-table-search-tool
        class="search-tool"
        [query]="textSearch"
        (clear)="clearTextSearch()"
        (query)="textSearchAction($event)"
        [placeholder]="'report-publicFiles.fulltext.placeholder' | t">
      </ui-table-search-tool>
      <ui-multi-select
        *ngIf="riskMultiSelectData"
        [multiSelectData]="riskMultiSelectData"
        [leftOrientation]="mobileService.isMobile()"
        (onOptionSelect)="sortResources($event)">
      </ui-multi-select>
      <ui-table-refresh-tool [loading]="isLoading" (click)="refresh()"></ui-table-refresh-tool>
    </div>
    <div class="total-tool">
      <ui-table-total-tool [total]="sharedFiles.length"></ui-table-total-tool>
    </div>
  </div>
  <div class="shared-files-flex-container">
    <div *ngFor="let sharedFile of sharedFiles; let first = first" [ngClass]="{ 'shared-files-mobile--content--first': first }">
      <div class="expendable-card-container">
        <sg-expendable-card #expendable>
          <div header>
            <shared-file-mobile [isHeader]="true" [sharedFile]="sharedFile"></shared-file-mobile>
          </div>
          <div content>
            <shared-file-mobile [isHeader]="false" [sharedFile]="sharedFile"></shared-file-mobile>
          </div>
        </sg-expendable-card>
      </div>
    </div>
  </div>

  <div *ngIf="!isLoading && !sharedFiles?.length" class="shared-files-mobile--no-content">
    {{ 'shares.none.filter' | t }}
  </div>
</div>
