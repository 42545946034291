import { Injector } from '@angular/core';
import { DTPipe } from '@ui-kit/pipes/dt.pipe';
import { TimeDurationPipe } from '@ui-kit/pipes/time-duration.pipe';
import * as Handlebars from "handlebars";
import { I18nService } from "projects/@common/modules/i18n/i18n.service";
import { Eco } from "../definitions/eco";
import { TranslatedObjectPipe } from '../modules/i18n/translatedObject.pipe';
import { IParameterStore } from "../services/api/tools/parameters/parameters.definitions";

export type mustacheParamsType = { [key: string]: { [key: string]: any; }; };

export enum ParameterValueType {
  STRING = 'string',
  HTML = 'html'
}

// Used to change a string from "You need to call {{parameter.contact}}" to "You need to call Lyne"
export class HandleBarUtils {
  public static locale: Eco.Languages;

  public static registerHelpers(injector: Injector): void {
    const i18nService = injector.get(I18nService);
    const dtPipe = injector.get(DTPipe);
    const timeDurationPipe = injector.get(TimeDurationPipe);
    const translatedObjectLocalePipe = injector.get(TranslatedObjectPipe);

    HandleBarUtils.locale = i18nService.currentLocale as Eco.Languages;

    Handlebars.registerHelper("translate", (label: string, paramDef: any, arg1: any, arg2: any, arg3: any, arg4: any) => {
      const params = {};
      if (paramDef && typeof paramDef === 'string') {
        let index = 0;
        for (const param of paramDef?.split(',') || []) {
          params[param] = index === 0 ? arg1 : index === 1 ? arg2 : index === 2 ? arg3 : index === 3 ? arg4 : '';
          index++;
        }
      }
      return i18nService.translateWithLanguage(label, HandleBarUtils.locale, params);
    });

    Handlebars.registerHelper('length', (obj) => obj?.length || 0);

    Handlebars.registerHelper('lastArrayItem', (array) => array && array.length > 0 ? array[array.length - 1] : null);

    Handlebars.registerHelper("richtext", (data: any) => `<div class="normal-font">${data}</div>`);

    Handlebars.registerHelper("fallback", (object: any) => translatedObjectLocalePipe.transform(object, HandleBarUtils.locale));

    Handlebars.registerHelper("replace", (str, params) => HandleBarUtils.mustacheReplace(str, HandleBarUtils.mustacheParameterBuilder(params)));

    Handlebars.registerHelper("now", () => dtPipe.transform(Date.now(), { withTime: false, locale: HandleBarUtils.locale }));

    Handlebars.registerHelper("nowWithTime", (defValue: string) => dtPipe.transform(Date.now(), { withTime: true, locale: HandleBarUtils.locale }) || defValue || '-');

    Handlebars.registerHelper("toDate", (epoch: number, defValue?: string) => dtPipe.transform(epoch, { withTime: false, locale: HandleBarUtils.locale }) || defValue || '-');

    Handlebars.registerHelper("toDateTime", (epoch: number, defValue?: string) => epoch ? dtPipe.transform(epoch, { withTime: true, locale: HandleBarUtils.locale }) : '-');

    Handlebars.registerHelper("duration", (msec: number, defValue?: string) => timeDurationPipe.transform(msec) || defValue || '-');

    Handlebars.registerHelper("durationFromInterval", (start: number, end: number, defValue?: string) => timeDurationPipe.transform(Math.abs(start - end)) || defValue || '-');

    Handlebars.registerHelper("durationFromMinutes", (minutes: number, defValue?: string) => timeDurationPipe.transform(minutes * 60000) || defValue || '-');

    Handlebars.registerHelper("durationFromSeconds", (minutes: number, defValue?: string) => timeDurationPipe.transform(minutes * 1000) || defValue || '-');

    Handlebars.registerHelper("lowercase", (value: string) => `${value || ''}`.toLowerCase());

    Handlebars.registerHelper("uppercase", (value: string) => `${value || ''}`.toUpperCase());

    Handlebars.registerHelper('concat', (...strs) => strs.filter((arg) => typeof arg !== 'object').join(''));

    Handlebars.registerHelper('ifEquals', function (arg1, arg2, options): string {
      return (arg1 === arg2) ? options.fn(this) : options.inverse(this);
    });

    Handlebars.registerHelper('ifNotEquals', function (arg1, arg2, options): string {
      return (arg1 !== arg2) ? options.fn(this) : options.inverse(this);
    });

    Handlebars.registerHelper('includes', function (arg1, arg2, options): string {
      if (typeof arg1 === 'string') {
        return arg1.includes(arg2) ? options.fn(this) : options.inverse(this);
      }
      return options.inverse(this);
    });

    Handlebars.registerHelper('isEqual', (value1, value2, options) => value1 === value2);

    Handlebars.registerHelper('isNotEqual', (value1, value2, options) => value1 !== value2);

    Handlebars.registerHelper('isObjectEmpty', (value) => Object.keys(value).length ? value : '');
  }

  public static mustacheFinder(text: string, prefix?: string): string[] {
    const variablesFound = [];
    const regex = new RegExp(`(?<!\\\\)({{)(?<variable>${prefix ? this.escapeRegex(prefix) : "\\w+"}\\.[0-9a-zA-Z\\-/_\\[\\]+=.@]+)(}})`, 'gi');
    for (const match of text.matchAll(regex)) {
      if (prefix) {
        variablesFound.push(match.groups?.variable.split(`${prefix}.`)[1].replace('[', '').replace(']', ''));
      } else {
        variablesFound.push(match.groups?.variable.replace('[', '').replace(']', ''));
      }
    }
    return variablesFound;
  }

  private static escapeRegex(stringToReplace: string): string {
    return stringToReplace.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
  }

  private static mustacheEscaper(text: string): string {
    const regex = new RegExp(`(?<!\\\\)({{)(?<variable>\\w+\\.[0-9a-zA-Z\\-/_\\[\\]+=.@]+)(}})`, 'gi');
    const deconstructedString = text.split(regex);
    for (const [ index, value ] of deconstructedString.entries()) {
      if (index !== 0 && index <= deconstructedString.length && deconstructedString[index - 1] === "{{" && deconstructedString[index + 1] === "}}") {
        const newValue = value
          .replace(/\s+/g, '')
          .split(".")
          .map((e) => {
            let partialVariable = e;
            partialVariable = partialVariable.startsWith("[") ? partialVariable : `[${partialVariable}`;
            partialVariable = partialVariable.endsWith("]") ? partialVariable : `${partialVariable}]`;
            return partialVariable;
          })
          .join(".");
        deconstructedString[index] = newValue;
      }
    }
    return deconstructedString.join('');
  }

  private static appendMissing(text: string, source): string[] {
    this.mustacheFinder(text).forEach((variable) => {
      source = this.findAndAddToArray(source, variable.split('.'), variable);
    });

    return source;
  }

  private static findAndAddToArray(obj, path: string[], value: string): any {
    if (!obj[path[0]]) {
      obj[path[0]] = path.length > 1 ? this.findAndAddToArray([], path.slice(1), value) : `?{{${value}}}`;
    } else if (typeof obj[path[0]] === 'object') {
      obj[path[0]] = this.findAndAddToArray(obj[path[0]], path.slice(1), value);
    }
    return obj;
  }

  public static mustacheReplace(text: string, source: mustacheParamsType): string {
    const templateText = Handlebars.compile(this.mustacheEscaper(text), { noEscape: true });

    return templateText(this.appendMissing(text, source));
  }

  public static mustacheParameterBuilder(array: IParameterStore[]): mustacheParamsType {
    return {
      parameter: array.reduce((prev: { [name: string]: string; }, curr) => ({
        ...prev,
        [curr.name.includes('.') ? curr.name.split('.')[1] : curr.name]: curr.value.content,
      }), {}),
    };
  }
}
