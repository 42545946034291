import { Pipe, PipeTransform } from '@angular/core';
import { I18nService } from '../i18n/i18n.service';

@Pipe({
  name: 'userListToFullname',
  pure: true,
})
export class UserListToFullnamePipe implements PipeTransform {
  constructor(protected readonly i18n: I18nService) {}

  public transform(users: any[]): string {
    if (users.length === 0) {
      const key = 'shares.list.UNKNOWN.value';
      return this.i18n.translate(key);
    }

    const fullNameList = users.map((user) => [ user.firstName, user.lastName ].join(' '));
    return fullNameList.join(', ');
  }
}
