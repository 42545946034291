import { Component, Input, OnInit } from '@angular/core';
import { MobileService } from '@common/services/mobile.service';
import { SeverityEnum } from '@ui-kit/components/ui-severity-chip/ui-severity-chip.component';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { FileRiskEnum } from 'projects/@common/services/api/sg/shares/shares.definitions';
import { SeverityDecidingFactorService } from './severityDecidingFactor.service';

@Component({
  selector: 'share-risk-score',
  templateUrl: './share-risk-score.component.html',
  styleUrls: ['./share-risk-score.component.scss'],
})
export class ShareRiskScore implements OnInit {
  @Input() resource;
  @Input() showBellIcon = true;
  @Input() showRiskFactor = true;

  @Input() smallSize = false;

  public severityValue: SeverityEnum;

  public customText;

  public riskFactors: string[];

  constructor(
    public mobileService: MobileService,
    private i18n: I18nService,
    private severityDecidingFactorService: SeverityDecidingFactorService
  ) {
  }

  public ngOnInit() {
    this.convertAndMapFileRiskToSeverity();
    this.riskFactors = this.severityDecidingFactorService.handleFactorDisplayValue(this.resource.riskAssociatedFactors);
  }

  private convertAndMapFileRiskToSeverity() {
    if (this.resource.risk === FileRiskEnum.LOW) {
      this.severityValue = SeverityEnum.OK;
      this.customText = 'shares.shareRiskScore.low';
    } else if (this.resource.risk === FileRiskEnum.MODERATE) {
      this.severityValue = SeverityEnum.HIGH;
      this.customText = 'shares.shareRiskScore.moderate';
    } else {
      this.severityValue = SeverityEnum.DISASTER;
      this.customText = 'shares.shareRiskScore.high';
    }

    if (this.resource.isPending) {
      this.severityValue = SeverityEnum.NA;
    }

    this.customText = this.i18n.translate(this.customText);
  }
}
