import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatRadioModule } from '@angular/material/radio';
import { DisplayModule } from '@common/modules/display/display.module';
import { UiKitModule } from '@ui-kit/uikit.module';
import { AssistOpsModule } from 'projects/@assist-ops/assist-ops.module';
import { ComponentsModule } from 'projects/@common/components/components.module';
import { DirectivesModule } from 'projects/@common/modules/directives/directives.module';
import { I18nModule } from 'projects/@common/modules/i18n/i18n.module';
import { InviteGuestUserQuickActionComponent } from './guest-users-dialogs/invite-guest-user-quickAction/invite-guest-user-quickAction.component';
import { InviteGuestUserStepperComponent } from './guest-users-dialogs/invite-guest-user-stepper/invite-guest-user-stepper.component';
import { ApplyModelDialogComponent } from './resources-dialogs/apply-model-dialog/apply-model-dialog.component';
import { ArchiveGroupDialogComponent } from './resources-dialogs/archive-group-dialog/archive-group-dialog.component';
import { CertifyAccessDialogComponent } from './resources-dialogs/certify-action/certify-access-dialog/certify-access-dialog.component';
import { CertifyBottomIndicatorsComponent } from './resources-dialogs/certify-action/certify-users-table/certify-bottom-indicators/certify-bottom-indicators.component';
import { CertifyUsersRowsComponent } from './resources-dialogs/certify-action/certify-users-table/certify-users-rows/certify-users-rows.component';
import { CertifyUsersTableComponent } from './resources-dialogs/certify-action/certify-users-table/certify-users-table.component';
import { CreateResourceDialogComponent } from './resources-dialogs/create-resource-dialog/create-resource-dialog.component';
import { EditGroupExpirationDateDialogComponent } from './resources-dialogs/edit-group-expiration-date-dialog/edit-group-expiration-date-dialog.component';
import { GroupActionsComponent } from './resources-dialogs/group-actions/group-actions.component';
import { GroupQuickActionDialogComponent } from './resources-dialogs/groupe-quick-action-dialog/group-quick-action-dialog.component';
import { ApplyConfirmationSectionComponent } from './resources-dialogs/sections/apply-confirmation-section/apply-confirmation-section.component';
import { CertifySectionComponent } from './resources-dialogs/sections/certify-section/certify-section.component';
import { CertifyHelpDialogComponent } from './resources-dialogs/sections/certify-section/help-dialog/certify-help-dialog.component';
import { ConfirmationSectionComponent } from './resources-dialogs/sections/confirmation-section/confirmation-section.component';
import { ExplanationSectionComponent } from './resources-dialogs/sections/explanation-section/explanation-section.component';
import { InformationsSectionComponent } from './resources-dialogs/sections/informations-section/informations-section.component';
import { ModelsHelpDialogComponent } from './resources-dialogs/sections/model-section/help-dialog/models-help-dialog.component';
import { ModelCardsComponent } from './resources-dialogs/sections/model-section/model-cards/model-cards.component';
import { ModelInformationComponent } from './resources-dialogs/sections/model-section/model-cards/model-informations/model-informations.component';
import { ModelSectionComponent } from './resources-dialogs/sections/model-section/model-section.component';
import { RequestSectionComponent } from './resources-dialogs/sections/request-section/request-section.component';
import { StructureSectionComponent } from './resources-dialogs/sections/structure-section/structure-section.component';

const components = [
  InviteGuestUserQuickActionComponent,
  InviteGuestUserStepperComponent,
  ApplyModelDialogComponent,
  CertifyAccessDialogComponent,
  CertifyUsersTableComponent,
  CertifyUsersRowsComponent,
  CertifyBottomIndicatorsComponent,
  CertifyHelpDialogComponent,
  CreateResourceDialogComponent,
  EditGroupExpirationDateDialogComponent,
  GroupActionsComponent,
  GroupQuickActionDialogComponent,
  ArchiveGroupDialogComponent,

  ModelCardsComponent,
  ModelInformationComponent,
  ModelsHelpDialogComponent,
  ModelSectionComponent,
  InformationsSectionComponent,
  RequestSectionComponent,
  ConfirmationSectionComponent,
  ExplanationSectionComponent,
  StructureSectionComponent,
  CertifySectionComponent,
  ApplyConfirmationSectionComponent,

];

@NgModule({
  declarations: [...components],
  exports: [...components],
  imports: [
    ComponentsModule,
    MatRadioModule,
    MatButtonToggleModule,
    ReactiveFormsModule,
    FormsModule,
    UiKitModule,
    CommonModule,
    AssistOpsModule,
    I18nModule,
    DirectivesModule,
    DisplayModule,
  ],
})
export class SgDialogsModule { }
