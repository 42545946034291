<div class="dashboard-card">
  <div class="dashboard-card--header" (click)="toggleExpanded()">
    <!--Coins ronds, avatar, -->
    <img
      *ngIf="iconName"
      class="dashboard-card--header--background-icon"
      [src]="'../../../../assets/favicons/' + iconName + '.svg'"
    />

    <div
      class="dashboard-card--header--avatar"
      *ngIf="displayAvatar && currentUser"
    >
      <ui-avatar [user]="currentUser" size="medium"></ui-avatar>
    </div>

    <div class="dashboard-card--header--title">
      <div class="dashboard-card--header--title--primary">
        {{ cardTitle | t }}
      </div>
      <div class="dashboard-card--header--title--secondary">
        {{ cardSecondTitle | t }}
      </div>
    </div>

    <div class="dashboard-card--header--additionnal">
      <ng-content select="[additionnalHeaderContent]"></ng-content>
    </div>

    <img
      [src]="
        '../../../../assets/favicons/icon_chevron_' +
        (isExpended ? 'open' : 'close') +
        '.svg'
      "
      class="dashboard-card--header--chevron"
    />
  </div>

  <div *ngIf="isExpended" class="dashboard-card--content">
    <ng-content></ng-content>
  </div>
</div>
