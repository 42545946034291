<div class="slide-container">
  <h2>{{ "dashboard.dialog.carousel.page5.title" | t }}</h2>
  <h3>{{ "dashboard.dialog.carousel.page5.second-title" | t }}</h3>
  <p>{{ "dashboard.dialog.carousel.page5.text1" | t }}</p>
  <div class="bottom-section">
    <div class="left-section">
      <img
        class="dashboard-icon"
        src="assets/favicons/icon_illustration_dashboard_profile.svg"
      />
      <div class="stroke-1"></div>
      <div class="stroke-2"></div>
      <div class="stroke-3"></div>
    </div>
    <div class="right-section">
      <div *ngFor="let item of items; let last = last" class="item">
        <div class="img-container">
          <img [src]="item.img" />
        </div>
        <div class="txt-container">
          <p class="bold">{{ item.txtTitle | t }}</p>
          <p [class.last-item]="last">{{ item.txtDescription | t }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
