<ui-tabs #tabs color="drawerTabs" [tabsTitle]="data.title">

  <ui-tab
    [tabTitle]="'sharedFile.drawer.details.tab.name' | t"
    [isShown]="!(isEditing$ | async)"
  >
    <shared-file-details
      [fileId]="data.fileId"
      [fileCreationDateLimit]="data.fileCreationDateLimit"
      [isFromConsoleOrg]="data.isFromConsoleOrg"
      [risk]="data.risk"
    ></shared-file-details>
  </ui-tab>

  <ui-tab
    #sharesTab
    [tabTitle]="'sharedFile.drawer.shares.tab.name' | t"
    [isShown]="
      !(isEditing$ | async) || (tabName$ | async) === sharedFileSharesComponentTabName
    "
  >
    <shared-file-shares
      #sharesComponent
      [fileId]="data.fileId"
      [fileName]="data.title"
      [fileType]="data.fileType"
      [fileCreationDateLimit]="data.fileCreationDateLimit"
      [isFromConsoleOrg]="data.isFromConsoleOrg"
    ></shared-file-shares>
  </ui-tab>

  <ui-tab
    [tabTitle]="'sharedFile.drawer.activities.tab.name' | t"
    [isShown]="!(isEditing$ | async)"
  >
    <shared-file-activities [isFromConsoleOrg]="data.isFromConsoleOrg" [fileId]="data.fileId"></shared-file-activities>
  </ui-tab>
</ui-tabs>
