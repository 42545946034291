<div class="container">
  <div class="image-title-group">
    <div
      [style]="{'background-color': logo.background}"
      class="image-background"
    >
      <img [src]="logo.icon">
    </div>
    <span>{{info.name}}</span>
  </div>
  <img
    [src]="validityLogo"
    [tooltip]="validityTooltip | t"
  >
</div>