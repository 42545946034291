import { Component, Input } from '@angular/core';
import { GroupTypeEnum } from '@ui-kit/util/icon-util';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import {
  GroupTemplateReach,
  IGroupTemplate,
  IGroupTemplateFolder
} from 'projects/@common/services/api/sg/group-templates/group-templates.definitions';

@Component({
  selector: 'sg-model-information',
  templateUrl: './model-informations.component.html',
  styleUrls: [ './model-informations.component.scss' ],
})
export class ModelInformationComponent {
  @Input() public template: IGroupTemplate;
  @Input() public showExpiration = true;
  @Input() public showChannels = true;
  @Input() public expandChannels = false;
  @Input() public showPermissions = true;

  constructor(private i18n: I18nService) {}

  public shouldShowSubpermissions(permissions: any): boolean {
    return Object.values(permissions).some((permission) => permission);
  }

  public getFoldersSectionTitle(template: IGroupTemplate): string {
    const translationKey =
      template.type === GroupTypeEnum.O365OFFICETEAMS
        ? 'groups.dialog.model.card.channels.title'
        : 'groups.dialog.model.card.folders.only.title';
    return this.i18n.translate(translationKey, {
      channelNumber: template.channels?.length || 0,
      folderNumber: this.computeFoldersTotal(template.folders),
    });
  }

  public getTotalPermissions(permissions: any): number {
    if (!permissions) {
      return 0;
    }
    const keys = Object.keys(permissions);
    let total = 0;
    keys.forEach((key) => {
      Object.values(permissions[key]).forEach((subpermission) => {
        total += subpermission ? 1 : 0;
      });
    });

    return total;
  }

  public getTranslatedGroupType(template: IGroupTemplate): string {
    return this.i18n.translate(`groups.dialog.${template.type}.short`);
  }

  public computeFoldersTotal(folders: IGroupTemplateFolder[]): number {
    let count = 0;
    count += folders.length;
    folders.forEach((folder) => {
      if (folder.subFolders.length > 0) {
        count += this.computeFoldersTotal(folder.subFolders);
      }
    });
    return count;
  }

  public get segmentationInformation(): { text: string; icon: string } {
    return this.template.reach === GroupTemplateReach.SEGMENTED
      ? {
        text: this.template.attribute ? this.i18n.translate('groups.dialog.model.card.specific.segmented', { attributeValue: this.template.attribute }) : this.i18n.translate('groups.dialog.model.card.specific'),
        icon: 'assets/favicons/icon_calendar.svg',
      }
      : {
        text: this.i18n.translate('groups.dialog.model.card.global'),
        icon: 'assets/favicons/icon_buildings_gray.svg',
      };
  }
}
