import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UiDialog } from '@ui-kit/components/ui-dialog/ui-dialog.component';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { IdentitiesApi } from 'projects/@common/services/api/iam/identities/identities.api';
import { OrganizationsApiService } from 'projects/@common/services/api/sg/organizations/organizationsApi.service';
import { MobileService } from 'projects/@common/services/mobile.service';
import { DOCUMENTATION_SECTION, generateDocumentationLink } from 'projects/@common/utils/eco.documentation-utils';
import { EmailUtil } from 'projects/@common/utils/email-util';
import { IOwnerGroup } from 'projects/console-sg/src/app/pages/teams-resources/services/resources-list.service';
import { UserStatusEnum } from '../../resources-dialogs/sections/informations-section/informations-section.component';
import { InviteGuestUserStepperComponent } from '../invite-guest-user-stepper/invite-guest-user-stepper.component';


@Component({
  selector: 'invite-guest-user-quickAction',
  templateUrl: './invite-guest-user-quickAction.component.html',
  styleUrls: [ './invite-guest-user-quickAction.component.scss' ],
})
export class InviteGuestUserQuickActionComponent implements OnInit {

  @HostListener('document:keydown.enter', [ '$event' ])
  onInviteKey(event: KeyboardEvent): void {
    if(this.emailForm.valid){
      this.onInvite();
    }
  }

  public documentationLink = generateDocumentationLink(DOCUMENTATION_SECTION.sg.inviteGuestUser, this.i18n.currentLocale);

  private readonly DEBOUNCE_TIME = 300;
  private checkGroupNameTakenTimeout: any;

  public emailForm: UntypedFormGroup;

  public isLoading = false;

  public locale = this.i18n.currentLocale;

  public guestExists = true;

  public emailSearched;

  public buttonLoading = false;

  public externalUserCollaboration: boolean;

  constructor(
    public dialogRef: MatDialogRef<UiDialog>,
    @Inject(MAT_DIALOG_DATA) public data: { groupOfficeId: string, group: IOwnerGroup & { toHide: boolean; }; refresh: any; },
    private dialog: MatDialog,
    private mobileService: MobileService,
    private identitiesApi: IdentitiesApi,
    private i18n: I18nService,
    private organizationsApiService: OrganizationsApiService

  ) { }

  ngOnInit(): void {
    this.initForm();
    this.getOrganizationSettings();
  }

  public onInvite(): void {
    this.dialogRef.close();
    this.dialog.open(InviteGuestUserStepperComponent, {
      minWidth: '730px',
      maxWidth: !this.mobileService.isMobile() ? '75%' : '100%',
      height: '660px',
      panelClass: 'custom-dialog',
      data: {
        email: this.emailSearched,
        externalUserCollaboration: this.externalUserCollaboration,
      },
    });
  }

  public close(): void {
    this.dialogRef.close();
  }

  public initForm(): void {
    this.emailForm = new UntypedFormGroup({
      email: new UntypedFormControl(
        '',
        [
          Validators.required,
          EmailUtil.emailValidation(this),
        ],
        this.checkGuestUserEmailTaken.bind(this)
      ),
    });
  }

  public getOrganizationSettings(): void {
    this.organizationsApiService.getOrganizationSettings()
      .then((response) => {
        this.externalUserCollaboration = response.externalUserCollaboration;
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  public async checkGuestUserEmailTaken(control: AbstractControl): Promise<any> {
    if (this.emailForm) {
      this.buttonLoading = true;
      clearTimeout(this.checkGroupNameTakenTimeout);
      const guestExists = await new Promise((resolve) => {
        this.checkGroupNameTakenTimeout = setTimeout(async () => {
          const groupname = control.value;
          if (groupname) {
            let guests;
            try {
              guests = await this.identitiesApi.listUsers(null, { filter: UserStatusEnum.GUESTS, search: (control.value as string).toLowerCase() });
            } catch (error) { }
            if (guests?.total > 0 && guests.items.some((guest) => guest.mail === (control.value as string).toLocaleLowerCase())) {
              resolve(true);
            }
            resolve(false);
          }
        }, this.DEBOUNCE_TIME);
      });
      this.buttonLoading = false;
      if (guestExists || control.value.length === 0) {
        this.guestExists = true;
        return null;
      }
      this.emailSearched = control.value;
      this.guestExists = false;
      return null;
    }
  }
}
