import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { RequestsContainer } from './containers/requests.container';

const routes: Routes = [
  {
    path: '',
    component: RequestsContainer,
  },
];

@NgModule({
  imports: [ RouterModule.forChild(routes) ],
  exports: [ RouterModule ],
})
export class RequestsRoutingModule {}
