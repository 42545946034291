import { IMultiSelectData } from './ui-multi-select.component';

export class MultiSelectData {
  constructor(public options: IMultiSelectData[] = []) { }

  public get optionSelected(): string[] {
    return this.options
      .filter((opt) => !opt.isDefaultValue)
      .filter((opt) => opt.isSelected)
      .map((opt) => opt.option);
  }

  public get selectedOptionsTranslated(): string {
    return this.options
      .filter((option) => option.isSelected)
      .map((option) => option.translatedShort || option.translated)
      .join(', ');
  }

  public setSelectionToDefault(): void {
    this.options.map((option) => {
      if (!option.isDefaultValue) {
        option.isSelected = false;
      }
    });
  }

  public handleSelection(selection: IMultiSelectData): void {
    if (selection.isDefaultValue) {
      this.setSelectionToDefault();
    } else {
      const optionToSelect = this.options.find((op) => op.option === selection.option);
      optionToSelect.isSelected = !optionToSelect.isSelected;
    }

    this.handleDefaultOptionSelection();
  }

  public handleDefaultOptionSelection(): void {
    if (this.defaultOption) {
      this.defaultOption.isSelected = this.options
        .filter((option) => !option.isDefaultValue)
        .every((option) => !option.isSelected);
    }
  }

  private get defaultOption(): IMultiSelectData {
    return this.options.find((option) => option.isDefaultValue);
  }
}
