<div 
  page
  [appPagePaths]="['user', 'mdAssistOps', 'dashboard']"
>
  <div *ngIf="!orgIsCISOnly" class="dashboard--content">
    <div class="widget-preferences-loader">
      <ui-spinner *ngIf="isLoadingWidgetPreferences"></ui-spinner>
    </div>

    <div class="dashboard--column primary-column">
      <quick-actions></quick-actions>
      <dashboard-card
        *ngIf="enabledWidgets?.breaktimeTasks?.isActive"
        iconName="icon_dashboard_coffee_break"
        cardTitle="dashboard.coffeeBreak.title"
        cardSecondTitle="dashboard.coffeeBreak.secondaryTitle"
      >
        <eco-breaktime></eco-breaktime>
      </dashboard-card>

      <dashboard-card
        iconName="icon_dashboard_cyberbehaviour"
        cardTitle="dashboard.myCyberBehaviour.title"
        [displayAvatar]="true"
        *ngIf="enabledWidgets"
      >
        <shares-type-proportions-v2 *ngIf="enabledWidgets?.sharesDistribution?.isActive">
        </shares-type-proportions-v2>
      </dashboard-card>
      <ng-container *ngIf="enabledWidgets?.resourcesWithShares?.isActive">
        <dashboard-card
          iconName="icon_dashboard_resources_monitoring"
          cardTitle="dashboard.resourcesMonitoring.card.title"
          cardSecondTitle="dashboard.resourcesMonitoring.card.subtitle"
        >
          <div
            additionnalHeaderContent
            *ngIf="resourcesMonitoringStats"
            class="dashboard-resource-monitoring-header"
          >
            <div
              *ngFor="let level of ['high', 'moderate', 'low']"
              [ngClass]="{
                    'dashboard-resource-monitoring-header--high':
                      level === 'high',
                    'dashboard-resource-monitoring-header--moderate':
                      level === 'moderate',
                    'dashboard-resource-monitoring-header--low': level === 'low'
                  }"
            >
              {{
              ('shares.shareRiskScore.' + level | t) +
              ' (' +
              resourcesMonitoringStats[level + 'Count'] +
              ')'
              }}
            </div>
          </div>
          <my-shared-assets
            (onStatsLoaded)="setResourceMonitoringStats($event)"
          ></my-shared-assets>
        </dashboard-card>
      </ng-container>
    </div>
  </div>
</div>
