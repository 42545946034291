<div class="container">
  <div class="section">
    <span class="label">
      <div class="title">
        <p>{{ 'groups.dialog.model.choose.message' | t }}</p>
        <img
          (click)="openContextualHelp()"
          class="image"
          src="assets/favicons/icon_contextual_help_small.svg"
        />
      </div>
      <p>
        {{ 'groups.dialog.model.choose.subtext1' | t }}
        <br />
        {{ 'groups.dialog.model.choose.subtext2' | t }}
        <br />
        <br />
        {{ 'groups.dialog.model.choose.contactIt' | t }}
      </p>
    </span>
  </div>
  <ui-tabs
    #tabs
    [color]="uiTabsColor.lightTabs"
    [borderBelowHeader]="true"
    [hasNoLeftPadding]="true"
    [hasWhiteBackroundColor]="false"
    (tabClicked)="onTabChange($event)"
  >
    <div class="tab-container">
      <ui-tab
        [tabTitle]="'groups.dialog.model.card.segmenteds' | t"
        tabImageActive="assets/favicons/icon_target_green.svg"
        tabImageInactive="assets/favicons/icon_target_gray.svg"
      >
      <div *ngIf="hasContextualisation && userAttributeValue" class="specific-model" >
        <p [innerHTML]="'groups.dialog.model.specific.models' | t: { attributeValue: userAttributeValue }" ></p>
      </div>
        <div class="searchSection" [ngClass]="{'searchSection--padded': !hasContextualisation || !userAttributeValue}">
          <ui-table-total-tool
            [total]="specificTemplates.total"
            [locale]="i18n.currentLocale"
          > </ui-table-total-tool>
          <ui-table-search-tool
            [query]="fulltext"
            [placeholder]="'groups.dialog.model.search.placeholder' | t"
            (clear)="handleSearchClear()"
            (query)="handleSearchQuery($event)"
          >
          </ui-table-search-tool>
        </div>
        <div>
          <sg-model-cards
            [templates]="specificTemplates.items"
            [selectedTemplate]="currentTemplate"
            [isLoading]="isLoading"
            [showChannels]="showChannels"
            (onTemplateSelection)="handleTemplateSelection($event)"
          ></sg-model-cards>
          <div>
            <p
              *ngIf="specificTemplates.items.length === 0 && !isLoading"
              class="empty"
            >
              {{ emptyTableKey | t: { attribute: userAttributeValue } }}
            </p>
          </div>
        </div>
      </ui-tab>
    </div>

    <div class="tab-container">
      <ui-tab
        [tabTitle]="'groups.dialog.model.card.globals' | t"
        tabImageActive="assets/favicons/icon_buildings_green.svg"
        tabImageInactive="assets/favicons/icon_buildings_gray.svg"
      >
        <ng-container *ngTemplateOutlet="genericModels"></ng-container>
      </ui-tab>
    </div>
  </ui-tabs>
</div>

<ng-template #genericModels>
  <div class="searchSection searchSection--padded">
    <ui-table-total-tool
      [total]="genericTemplates.total"
      [locale]="i18n.currentLocale"
    > </ui-table-total-tool>
    <ui-table-search-tool
      [query]="fulltext"
      [placeholder]="'groups.dialog.model.search.placeholder' | t"
      (clear)="handleSearchClear()"
      (query)="handleSearchQuery($event)"
    >
    </ui-table-search-tool>
  </div>
  <div>
    <sg-model-cards
      [templates]="genericTemplates.items"
      [selectedTemplate]="currentTemplate"
      [isLoading]="isLoading"
      [showChannels]="showChannels"
      (onTemplateSelection)="handleTemplateSelection($event)"
    ></sg-model-cards>
    <div>
      <p
        *ngIf="genericTemplates.items.length === 0 && !isLoading"
        class="empty"
      >
        {{ 'groups.dialog.model.card.details.empty' | t }}
      </p>
    </div>
  </div>
</ng-template>
