import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngxs/store';
import { SharesApiService } from 'projects/@common/services/api/sg/shares/shares-api.service';
import { Accumulatables, Accumulate } from 'projects/@common/modules/accumulator/accumulator.store';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { FileTypeEnum } from 'projects/@common/services/api/sg/shares/shares.definitions';
import { DrawerService } from 'projects/@common/modules/layout/components/drawer/services/drawer.service';
import { Notice, NoticeLevels, NoticeService } from 'projects/@common/modules/notice/notice.service';
import { HideDialog } from '@ui-kit/components/ui-dialog/stores/ui-dialog.store';
import { DialogActions } from '@ui-kit/components/ui-dialog/ui-dialog.component';

@Component({
  selector: 'shared-file-shares-revoke-all',
  templateUrl: './shared-file-shares-revoke-all.component.html',
  host: {
    class: 'shared-file-shares-revoke-all',
  },
  encapsulation: ViewEncapsulation.None,
})
export class SharedFileSharesRevokeAll {
  @Input() fileId: string;

  @Input() fileName: string;

  @Input() fileType: FileTypeEnum;

  @Input() public showRevokeAllSharesConfirmationDialog = false;

  @Output() public showRevokeAllSharesConfirmationDialogChange: EventEmitter<boolean> = new EventEmitter();

  public isRevokingAll = false;

  constructor (
    private readonly drawerService: DrawerService,
    private readonly store: Store,
    private readonly sharesService: SharesApiService,
    private readonly noticesService: NoticeService,
    private readonly i18nService: I18nService
  ) { }

  public onRevokeAllSharesConfirmation(event: DialogActions) {
    if (event === DialogActions.ACTION) {
      this.revokeAllShares();
    } else {
      this.closeDialog();
    }
  }

  private revokeAllShares() {
    this.isRevokingAll = true;
    this.sharesService
      .revokeSharedFiles([ this.fileId ])
      .then(() => {
        this.isRevokingAll = false;
        this.noticesService.notifyUser(new Notice(
          'sharedFile.drawer.shares.dialog.revokeAll.success1',
          NoticeLevels.SUCCESS,
          {},
          'sharedFile.drawer.shares.dialog.revokeAll.success2',
          { fileType: this.i18nService.translate(`sharedFile.drawer.shares.dialog.revokeAll.fileType.${this.fileType?.toLowerCase()}`) }
        ));
        this.store.dispatch(new Accumulate({ accumulatable: Accumulatables.LIST_REFRESHER }));
        this.closeDialog();
        this.drawerService.previousDrawer();
      })
      .catch((error) => {
        this.noticesService.notifyUser(new Notice(
          'sharedFile.drawer.shares.dialog.revokeAll.error1',
          NoticeLevels.ERROR,
          {},
          'sharedFile.drawer.shares.dialog.revokeAll.error2'
        ));
      });
  }

  private closeDialog() {
    this.showRevokeAllSharesConfirmationDialog = false;
    this.showRevokeAllSharesConfirmationDialogChange.emit(this.showRevokeAllSharesConfirmationDialog);
    this.store.dispatch(new HideDialog());
  }
}
