<base-dialog
    [title]="'sharedFile.drawer.shares.table.details.silenced.title' | t"
    [isEditing]="true"
    [hasStepper]="false"
    (onCancel)="dialogRef.close()"
    >
    <div class="content">
      <p>{{'sharedFile.drawer.shares.table.details.silenced.description' | t}}</p>

      <form>
        <ui-switch-radio-static
          [label]="'sharedFile.drawer.shares.table.details.silenced.choose' | t"
          name="silenceDuration"
          [isEditing]="true"
          control="silenceDuration"
          [group]="silencingForm"
          [options]="options"
          [style]="'horizontal'"
        ></ui-switch-radio-static>
        <ui-field-group>
          <ui-switch-text-static
            [isEditing]="true"
            [required]="true"
            [label]="'requests.drawer.creation.justification' | t "
            name="justification"
            control="justification"
            [value]=""
            [group]="silencingForm"
            maxlength="500"
            [rowsNumber]="4"
          ></ui-switch-text-static>
        </ui-field-group>
      </form>

      <div class="buttons">
        <button
            ui-button
            color="standard"
            class="margin--small--bottom"
            (click)="save()"
            [disabled]="!silencingForm.valid"
            >
          {{ 'common.action.save' | t }}
        </button>
        <button
            ui-button
            color="secondary"
            class="margin--small--bottom"
            (click)="cancel()"
            >
          {{ 'common.action.cancel' | t }}
        </button>
      </div>
    </div>
</base-dialog>
