import { Stages } from '../definitions/stages';
import { environment } from '../environments/environment';

export const DOCUMENTATION_SECTION = {
  home: '/',
  sg: {
    root: 'proactive-security/',
    overview: 'proactive-security/overview',
    dashboard: 'proactive-security/user/dashboard/',
    dashboardProfile: 'proactive-security/user/dashboard/eco-profile/',
    dashboardProfileDetail: 'proactive-security/user/dashboard/eco-profile/eco-profile-detail',
    quickAction: 'proactive-security/user/dashboard/quick-actions',
    request: 'proactive-security/user/dashboard/requests',
    shareHealthCheck: 'proactive-security/user/sharesHealthCheck/',
    reviewShare: 'proactive-security/user/sharesHealthCheck/sharesExamine',
    revokeShare: 'proactive-security/user/sharesHealthCheck/sharesRevoke',
    expressRevoke: 'proactive-security/user/sharesHealthCheck/sharesRevokeExpress',
    silenceShare: 'proactive-security/user/sharesHealthCheck/sharesSilence',
    resourceHealthCheck: 'proactive-security/user/groups/resourcesExamine',
    deviceHealthCheck: 'proactive-security/user/devices',
    inviteGuestUser: 'proactive-security/user/guestUsers/inviteGuestUser',
    admin: {
      userShares: 'proactive-security/admin/shares',
      resourceShares: 'proactive-security/admin/shares/resources',
    },
  },
  support: 'support',
};


export function generateDocumentationLink(path: string, locale: string): string {
  let url = 'https://';

  switch (environment.stage) {
    case Stages.DEV:
      url = url.concat(`docs.dev.f2s.io/`);
      break;
    case Stages.QA:
      url = url.concat(`docs.qa.f2s.io/`);
      break;
    case Stages.PROD:
      url = url.concat(`docs.mondata.ai/`);
      break;
  }

  return url.concat(`${locale === 'en' ? '' : 'fr/'}${path}`);
}
