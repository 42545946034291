import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { marked } from 'marked';

@Pipe({
  name: 'markdown',
})
export class MarkdownPipe implements PipeTransform {
  constructor(protected sanitizer: DomSanitizer) {
  }

  transform(value: string, ...args: unknown[]): string {
    if (value) {
      return this.sanitizer.sanitize(SecurityContext.HTML, marked.parse(value));
    }
  }
}
