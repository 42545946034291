import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'eco-profile-description-slide',
  templateUrl: './eco-profile-description-slide.component.html',
  styleUrls: [ './eco-profile-description-slide.component.scss' ],
})
export class EcoProfileDescriptionSlideComponent implements OnInit {
  public items = [
    {
      txtTitle: 'dashboard.dialog.carousel.page5.text2',
      txtDescription: 'dashboard.dialog.carousel.page5.text3',
      img: 'assets/favicons/icon_Thumbs_up_green.svg',
    },
    {
      txtTitle: 'dashboard.dialog.carousel.page5.text4',
      txtDescription: 'dashboard.dialog.carousel.page5.text5',
      img: 'assets/favicons/icon_pause_ok_no_fill.svg',
    },
    {
      txtTitle: 'dashboard.dialog.carousel.page5.text6',
      txtDescription: 'dashboard.dialog.carousel.page5.text7',
      img: 'assets/favicons/icon_medal.svg',
    },
  ];

  constructor() {}

  ngOnInit(): void {}
}
