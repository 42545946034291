import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { UiTabsColor } from '@ui-kit/components/ui-tabs/ui-tabs.component';
import { EcoSessionState } from 'projects/@common/modules/session/state/session.state';
import { GetDashboardProfileResponse } from 'projects/@common/services/api/sg/md.eco-profile/md.eco-profile.definition';
import { DashboardProfileService } from 'projects/@common/services/dashboard-profile.service';
import { toTitleCaseName } from 'projects/@common/utils/utils';

export interface IProfileElements {
  quantity: string | number;
  label: string,
  img: string;
}

@Component({
  selector: 'sg-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: [ './my-profile.component.scss' ],
})
export class MyProfileComponent implements OnInit {
  public tabColor = UiTabsColor.DashboardTabs;

  public userFirstName = toTitleCaseName(this.store.selectSnapshot(EcoSessionState.userName).split(' ')[0]);

  public userTotals = [
    { quantity: 0, label: 'dashboard.md-eco.myProfile.card.user.action', img: 'icon_Thumbs_up_green.svg' },
    { quantity: 0, label: 'dashboard.md-eco.myProfile.card.user.weeks', img: 'icon_pause_ok_no_fill.svg' },
    { quantity: 0, label: 'dashboard.md-eco.myProfile.card.user.badges', img: 'icon_medal.svg' },
  ];

  public orgTotals = [
    { quantity: 0, label: 'dashboard.md-eco.myProfile.card.org.action', img: 'icon_unlink.svg' },
    { quantity: 0, label: 'dashboard.md-eco.myProfile.card.org.badges', img: 'icon_checkbox_green.svg' },
  ];

  public isLoaded = false;

  constructor(
    protected readonly store: Store,
    private dashboardProfileService: DashboardProfileService
  ) { }

  ngOnInit(): void {
    this.dashboardProfileService.getDashboardUserProfile();
    this.dashboardProfileService.dashboardUserProfile.subscribe((value) => {
      this.setUserActions(value);
      this.setOrgActions(value);
    });
  }

  public getUserElements(): IProfileElements[] {
    return this.userTotals;
  }

  public getOrgElements(): IProfileElements[] {
    return this.orgTotals;
  }

  private setUserActions(profile: GetDashboardProfileResponse): void {
    this.userTotals = [
      { quantity: profile.userActions.total - profile.userActions.revokeUsers || 0, label: 'dashboard.md-eco.myProfile.card.user.action', img: 'icon_Thumbs_up_green.svg' },
      { quantity: profile.badges.userBadges.length || 0, label: 'dashboard.md-eco.myProfile.card.user.badges', img: 'icon_medal.svg' },
      { quantity: profile.conformities.yearlyCount?.[new Date().getFullYear()] || 0, label: 'dashboard.md-eco.myProfile.card.user.weeks', img: 'icon_pause_ok_no_fill.svg' },
    ];
  }

  private setOrgActions(profile: GetDashboardProfileResponse): void {
    this.orgTotals = [
      { quantity: profile.organizationActions.total || 0, label: 'dashboard.md-eco.myProfile.card.org.action', img: 'icon_Thumbs_up_green.svg' },
      { quantity: profile.badges.organizationBadgesCount || 0, label: 'dashboard.md-eco.myProfile.card.org.badges', img: 'icon_medal.svg' },
    ];
  }
}
