<div
  class="ops-manager-job-detail--container drawer-detail--scrollable hidden"
  (scroll)="onScroll($event)"
>
  <div class="ops-manager-job-detail--tools">
    <button
      *ngIf="!mobileService.isMobile()"
      ui-button
      icon="chevron-left"
      icon-size="medium"
      color="listActionFlat"
      (click)="goToPreviousDrawer()"
    >
      {{ 'common.action.return' | t }}
    </button>
    <button
      ui-button
      icon="icon_refresh"
      [isSvg]="true"
      [custom-icon]="true"
      custom-icon-size="medium"
      color="listActionFlat"
      (click)="handleRefresh()"
    >
      {{ 'ops-manager.notices.refresh' | t }}
    </button>
    <!-- <button -->
    <!--   ui-button -->
    <!--   icon="icon_external_list_active" -->
    <!--   [isSvg]="true" -->
    <!--   [custom-icon]="true" -->
    <!--   custom-icon-size="medium" -->
    <!--   color="listActionFlat" -->
    <!--   (click)="opsManagerService.handleSupportCenterClick()" -->
    <!-- > -->
    <!--   {{ 'topbar.support.popover.actions.mdsupport' | t }} -->
    <!-- </button> -->
  </div>
  <div class="ops-manager-job-detail--header">
    <div class="ops-manager-job-detail--header--title">
      {{ notificationMessage }}
    </div>
    <div class="ops-manager-job-detail--header--info">{{ data.createdAt }}</div>
    <div class="ops-manager-job-status">
      <img
        [src]="notificationIcon"
        [alt]="notificationIcon"
      />
      <div class="ops-manager-job-detail--header--title">
        {{ 'ops-manager.notices.status.' + notificationStatus | t }}
      </div>
    </div>
    <div class="ops-manager-job-detail--header--info">
      {{ 'ops-manager.notices.status.' + notificationStatus + '.info' | t }}
    </div>
  </div>
  <ui-static-table
    [loading]="loading"
    [expendedTemplate]="template"
    [dataSource]="operations"
    [columnsDef]="columnsDef"
    [isSelectingOnlyOneAtATime]="false"
    [addPaddingOnFirst]="false"
    [hoverable]="true"
  >
  </ui-static-table>
  <ng-template
    #template
    let-row="row"
  >
    <div class="ops-manager-job-detail--error">
      <div class="ops-manager-job-detail--error--operation-container">
        <div class="ops-manager-job-detail--error--operation-id">
          {{ row.guid }}
        </div>
        <img
          class="copy_icon"
          src="assets/favicons/icon_copy_small.svg"
          (click)="copyOperationGuidToClipBoard(row.guid)"
        />
      </div>
      <div class="ops-manager-job-detail--error--info">{{ row.error }}</div>
    </div>
  </ng-template>
</div>
