<div class="admin-card-container">
  <p class="admin-card-container--title">
    {{ cardInformations.number }} <span *ngIf="isPurcentageData">%</span>
  </p>
  <div class="admin-card-container--text">
    <p>
      {{ cardInformations.text | t : { number: cardInformations.number , total: cardInformations.total } }}
    </p>
    <ui-tooltip
      *ngIf="cardInformations.tooltipText"
      [isSmall]="true"
      [data]="cardInformations.tooltipText | t"
    >
    </ui-tooltip>
  </div>
  <div class="admin-card-container--img-container">
    <img [src]="'assets/favicons/' + cardInformations.image" />
  </div>
</div>
