import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Accumulate, Accumulatables } from '@common/modules/accumulator/accumulator.store';
import { Store } from '@ngxs/store';
import { UiTableDirection } from '@ui-kit/components/ui-table/ui-table.component';
import { IWarningMessage } from '@ui-kit/components/ui-warning/ui-warning.component';
import { DrawerService } from 'projects/@common/modules/layout/components/drawer/services/drawer.service';
import { SetDrawerIsEditing } from 'projects/@common/modules/layout/components/drawer/stores/drawer.store';
import { Notice, NoticeLevels, NoticeService } from 'projects/@common/modules/notice/notice.service';
import { SharesApiService } from 'projects/@common/services/api/sg/shares/shares-api.service';
import { FileShareTypeEnum, FileTypeEnum, ISharedFilePermission } from 'projects/@common/services/api/sg/shares/shares.definitions';
import { MobileService } from 'projects/@common/services/mobile.service';
import * as uuid from 'uuid';

@Component({
  selector: 'shared-file-shares',
  templateUrl: './shared-file-shares.component.html',
  styleUrls: [ './shared-file-shares.component.scss' ],
  host: {
    class: 'shared-file-shares',
  },
  encapsulation: ViewEncapsulation.None,
})
export class SharedFileShares implements OnInit {
  public static readonly COMPONENT = 'SharedFileShares';
  public readonly automaticRevokeWarningMessage: IWarningMessage[] = [
    {
      messageKey: 'sharedFile.drawer.shares.automaticRevoke.warning',
    },
  ];
  @Input() fileId: string;
  @Input() fileName: string;
  @Input() fileType: FileTypeEnum;
  @Input() fileCreationDateLimit: number;
  @Input() isFromConsoleOrg: boolean;
  public isLoadingShares = true;
  public isLoadingIsAutomaticRevokeActivated = true;
  public isEditing = false;
  public isSaving = false;
  public sharedFilesPermissions: ISharedFilePermission[] = [];
  public sharedFilesPermissionsCopy: ISharedFilePermission[] = [];
  public showRevokeAllSharesConfirmationDialog = false;
  public showRevokeShareConfirmationDialog = false;
  public isAutomaticRevokeActivated = false;
  public temporaryDeletedPermissionsIds = [];
  public sort = 'risk';
  public direction: UiTableDirection = UiTableDirection.Desc;
  public permissionToRemove: ISharedFilePermission;
  private readonly PAGE_LIMIT = 25;
  private from: number;

  private total: number;

  private latestSearchId: string;

  private permissionsToDelete: string[] = [];

  constructor(
    private readonly store: Store,
    private readonly sharesService: SharesApiService,
    public readonly mobileService: MobileService,
    private readonly noticeService: NoticeService,
    private readonly drawerAService: DrawerService
  ) {
  }

  public ngOnInit() {
    this.temporaryDeletedPermissionsIds = [];
    this.sharesService
      .getAutomaticRevokeActivated()
      .then((automaticRevoke) => {
        this.isAutomaticRevokeActivated = automaticRevoke.isActivated;
      })
      .finally(() => {
        this.isLoadingIsAutomaticRevokeActivated = false;
      });

    this.loadShares();
  }

  public loadMoreShares() {
    if (!this.isLoadingShares && this.from < this.total) {
      this.loadShares();
    }
  }

  public handleSorting(sort: string, direction: UiTableDirection) {
    this.sort = sort;
    this.direction = direction;
    this.handleRefresh();
  }

  public toggleEditMode(isEditing: boolean) {
    this.isEditing = isEditing;
    if (!this.isEditing) {
      this.sharedFilesPermissions = [ ...this.sharedFilesPermissionsCopy ];
      this.permissionsToDelete = [];
    }

    this.store.dispatch(new SetDrawerIsEditing({
      isEditing: this.isEditing,
      tabName: SharedFileShares.COMPONENT,
    }));
  }

  public checkRemovePermission(permissionToRemove: ISharedFilePermission) {
    if (
      (permissionToRemove.shareType === FileShareTypeEnum.COMPANY ||
        permissionToRemove.shareType === FileShareTypeEnum.PUBLIC) &&
      permissionToRemove.linkUsers?.length
    ) {
      this.permissionToRemove = permissionToRemove;
      this.showRevokeShareConfirmationDialog = true;
    } else {
      this.removePermission(permissionToRemove);
    }
  }

  public removePermission(permissionToRemove: ISharedFilePermission) {
    if (permissionToRemove) {
      this.permissionsToDelete.push(permissionToRemove.permissionId);
      this.sharedFilesPermissions = this.sharedFilesPermissions.filter((sharedFilesPermission) => sharedFilesPermission.permissionId !== permissionToRemove.permissionId);
    }
  }

  public savePermissionsModifications() {
    this.isSaving = true;
    this.sharesService
      .removeSharedFilePermissions(this.fileId, this.permissionsToDelete)
      .then(() => {
        this.temporaryDeletedPermissionsIds = [ ...this.permissionsToDelete ];
        this.noticeService.notifyUser(new Notice('shares.dialog.batchRevoke.confirmation.success.message', NoticeLevels.SUCCESS));
        this.store.dispatch(new Accumulate({ accumulatable: Accumulatables.LIST_REFRESHER }));
        this.handleRefresh();
      })
      .finally(() => {
        this.isSaving = false;
        this.toggleEditMode(false);
      });
  }

  private loadShares() {
    this.isLoadingShares = true;

    const currentSearchId = uuid.v4();
    this.latestSearchId = currentSearchId;

    const params = {
      from: this.from,
      size: this.PAGE_LIMIT,
      sort: this.sort,
      direction: this.direction,
    };
    const call = this.isFromConsoleOrg ? this.sharesService.getSharedFilePermissionsOrg(this.fileId, params) : this.sharesService.getSharedFilePermissions(this.fileId, params);

    call.then((sharedLinkPermissions) => {
      if (currentSearchId === this.latestSearchId) {
        this.sharedFilesPermissions = this.sharedFilesPermissions.concat(sharedLinkPermissions.items);
        this.sharedFilesPermissions = this.sharedFilesPermissions.filter((permission) => !this.temporaryDeletedPermissionsIds.includes(permission.permissionId));
        this.sharedFilesPermissionsCopy = [ ...this.sharedFilesPermissions ];
        this.from = +sharedLinkPermissions.nextToken;
        this.total = sharedLinkPermissions.total;
        this.isLoadingShares = false;
      }
    })
      .catch(() => {
        this.isLoadingShares = false;
      });
  }

  private handleRefresh() {
    this.isLoadingShares = true;
    this.from = 0;

    if (this.sharedFilesPermissions.length === 0) {
      this.drawerAService.previousDrawer();
    }

    this.sharedFilesPermissions = [];
    this.sharedFilesPermissionsCopy = [];
    this.permissionsToDelete = [];
    this.loadShares();
  }
}
