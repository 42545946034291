import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { I18nService } from '@common/modules/i18n/i18n.service';
import {
  IUserAction
} from '@common/services/api/sg/md.eco-profile/md.eco-profile.definition';
import { UsersApiService } from '@common/services/api/sg/user/userApi.service';
import { DashboardProfileService } from '@common/services/dashboard-profile.service';
import { AutocompleteTypes } from '@ui-kit/components/autocomplete/autocomplete.component';
import { ProfileCardColorEnum } from 'projects/@assist-ops/components/eco-profile-card/eco-profile-card.component';
import { IEcoProfileConfig } from 'projects/@assist-ops/components/eco-profile-card/eco-profile-row/eco-profile-row.component';
import { UserProfile } from 'projects/@assist-ops/components/user-info-dashboard/user-info-dashboard.component';
import { User } from 'projects/@common/definitions/user.definition';
import { FeaturesEnum } from '@md.eco/interfaces/featureFlags';
import {
  IdentityProviderTypes,
  List,
  ListUsersResponse,
} from 'projects/@common/services/api/iam/identities/identities.api.definitions';

@Component({
  selector: 'sg-team-profile',
  templateUrl: './team-profile.component.html',
  styleUrls: ['./team-profile.component.scss'],
})
export class TeamProfileComponent implements OnChanges {
  @Input() userProfile: UserProfile & { hasUsersReporting: number; };
  @Output() refreshEmitter: EventEmitter<string[]> = new EventEmitter();

  public action: IEcoProfileConfig = {
    title: 'eco-profile.page.tab.user-profile.proactive-section.title',
    emptyMessage: 'eco-profile.page.tab.user-profile.proactive-section.no-value',
    actions: [],
  };

  public selectedUsers: User[] = [];

  public weeks;

  public searchQuery = null;

  public cardColorEnum = ProfileCardColorEnum;

  public monthlyData: { date: Date; value: number; }[] = [];

  public usersAutocomplete = AutocompleteTypes.USERS;

  public isLoading = false;

  private allData: UserProfile & { hasUsersReporting: number; };

  constructor(
    public i18n: I18nService,
    private dashboardProfileService: DashboardProfileService,
    private datePipe: DatePipe,
    private usersApi: UsersApiService
  ) { }

  ngOnChanges(): void {
    this.isLoading = true;
    if (!this.allData) {
      this.allData = this.userProfile;
    }
    this.setupMonthlyChart();
    this.setupActionRows(this.userProfile.userActions);
    this.setupWeeksRows();
    this.isLoading = false;
  }

  public onItemSelected(user: User): void {
    if (user) {
      this.selectedUsers = [...this.selectedUsers, user];
      this.handleRefresh();
    }
  }

  public removeDefaultApprover(toRemoveUser: User) {
    this.selectedUsers = this.selectedUsers.filter((user) => user.o365UserId !== toRemoveUser.o365UserId);
    this.handleRefresh();
  }

  public handleRefresh() {
    this.refreshEmitter.emit(this.selectedUsers.map((user) => user.o365UserId));
  }

  private setupActionRows(userActions: IUserAction): void {
    this.action.actions = [
      {
        name: 'eco-profile.page.tab.user-profile.proactive-section.revocation.label',
        description: this.i18n.translate('dashboard.admin.row.revokedShares.description', {
          number: this.userProfile.userActions.revokeShares.actions,
          numberRevokeShareLinks: this.userProfile.userActions.revokeShares.links,
        }),
        shouldDisplay: this.userProfile.userActions.revokeShares.actions > 0,
        image: 'icon_unlink_grey.svg',
        flagRequired: [FeaturesEnum.SHARES_MANAGEMENT],
      },
      {
        name: 'eco-profile.page.tab.user-profile.proactive-section.certification.label',
        description: this.i18n.translate('dashboard.admin.row.reviews.description', {
          number: this.userProfile.userActions.certify.actions,
          numberRevoke: this.userProfile.userActions.certify.revokes,
        }),
        shouldDisplay: this.userProfile.userActions.certify.actions > 0,
        image: 'icon_certifier.svg',
        flagRequired: [FeaturesEnum.RESOURCE_MANAGEMENT],
      },
      {
        name: 'eco-profile.page.tab.user-profile.proactive-section.sensitive-files-removed.label',
        description: this.i18n.translate(
          'eco-profile.page.tab.user-profile.proactive-section.sensitive-files-removed.values',
          { actionNumber: this.userProfile.userActions.removeSensitiveFile }
        ),
        shouldDisplay: this.userProfile.userActions.removeSensitiveFile > 0,
        image: 'icon_eye_off.svg',
        flagRequired: [FeaturesEnum.DLP_MANAGEMENT],
      },
      {
        name: 'dashboard.admin.row.securityModels.title',
        description: this.i18n.translate('dashboard.admin.row.securityModels.description', {
          number: userActions?.addTemplate,
        }),
        shouldDisplay: userActions?.addTemplate > 0,
        image: 'icon_template_added.svg',
        flagRequired: [FeaturesEnum.RESOURCE_MANAGEMENT],
      },
      {
        name: 'dashboard.admin.row.archived.title',
        description: this.i18n.translate('dashboard.admin.row.archived.description', { total: userActions?.archive }),
        shouldDisplay: userActions?.archive > 0,
        image: 'icon_archive_pale_grey.svg',
        flagRequired: [FeaturesEnum.RESOURCE_MANAGEMENT],
      },
    ];
  }

  private longestStreakDate(): { firstWeek: string; lastWeek: string; } {
    if (this.userProfile.conformities.longestStreakValues.length > 0) {
      const firstWeek = Math.min(...this.userProfile.conformities.longestStreakValues);
      const lastWeek = Math.max(...this.userProfile.conformities.longestStreakValues);

      if (firstWeek && lastWeek) {
        let firstWeekReadable: string;

        if (new Date(firstWeek).getFullYear() === new Date(lastWeek).getFullYear()) {
          firstWeekReadable = this.datePipe.transform(
            new Date(firstWeek),
            this.i18n.currentLocale === 'fr' ? 'dd MMMM' : 'MMM dd',
            null,
            this.i18n.dateCurrentLocale
          );
        } else {
          firstWeekReadable = this.datePipe.transform(
            new Date(firstWeek),
            'longDate',
            null,
            this.i18n.dateCurrentLocale
          );
        }

        const lastWeekReadable = this.datePipe.transform(
          new Date(lastWeek),
          'longDate',
          null,
          this.i18n.dateCurrentLocale
        );

        return {
          firstWeek: firstWeekReadable,
          lastWeek: lastWeekReadable,
        };
      }
    }

    return {
      firstWeek: '0',
      lastWeek: '0',
    };
  }

  private setupWeeksRows() {
    const weeklyStreak = this.longestStreakDate();
    this.weeks = {
      title: 'eco-profile.page.tab.user-profile.weekly-section.title',
      emptyMessage: 'eco-profile.page.tab.user-profile.weekly-section.no-value',
      actions: [
        {
          name: 'eco-profile.page.tab.user-profile.weekly-section.current.label',
          description: this.i18n.translate('eco-profile.page.tab.user-profile.weekly-section.current.desc', {
            total: this.userProfile.conformities.currentStreakTotal,
          }),
          shouldDisplay: true,
        },
        {
          name: 'eco-profile.page.tab.user-profile.weekly-section.total-year.label',
          description: this.i18n.translate('eco-profile.page.tab.user-profile.weekly-section.total-year.desc', {
            total: this.userProfile.conformities?.yearlyCount?.[new Date().getFullYear()],
          }),
          shouldDisplay: true,
        },
        {
          name: 'eco-profile.page.tab.user-profile.weekly-section.best-sequence.label',
          description: this.i18n.translate('eco-profile.page.tab.user-profile.weekly-section.best-sequence.desc', {
            total: this.userProfile.conformities?.longestStreakTotal,
            firstWeek: weeklyStreak.firstWeek,
            lastWeek: weeklyStreak.lastWeek,
          }),
          shouldDisplay: true,
        },
      ],
      knowMoreText: `eco-profile.page.tab.user-profile.weekly-section.know-more`,
    };
  }

  private setupMonthlyChart(): void {
    this.monthlyData = this.userProfile?.userActions?.monthlyCounts?.map((month) => ({
      date: new Date(month.date),
      value: month.total,
    }));
  }

  public usersAutocompleteSearchFunction = (
    params: any
  ): Promise<List<ListUsersResponse, number | string, { idpType: IdentityProviderTypes; }>> =>
    this.usersApi.listUsersReportingTo(params.search);
}
