import { color } from '@amcharts/amcharts4/core';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { LanguageEnum } from '@ui-kit/interfaces/ILanguage';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { SharesApiService } from 'projects/@common/services/api/sg/shares/shares-api.service';
import {
  FileRiskEnum,
  IResourceSharedFilesStats
} from 'projects/@common/services/api/sg/shares/shares.definitions';

@Component({
  selector: 'admin-resources-number-with-shares',
  templateUrl: './admin-resources-number-with-shares.component.html',
  styleUrls: [ './admin-resources-number-with-shares.component.scss' ],
  encapsulation: ViewEncapsulation.None,
})
export class AdminResourcesNumberWithSharesComponent implements OnInit {
  private readonly COLOR_RED = '#DE3618';

  private readonly COLOR_ORANGE = '#ff7800';

  private readonly COLOR_GREEN = '#00d873';

  private readonly COLOR_GRAY = '#999';

  public stats: IResourceSharedFilesStats;

  public isLoading = false;

  public resourcesNumberWithSharesData: any[] = [];

  public legendTemplate = '';

  public locale: LanguageEnum;

  constructor (
    private sharesService: SharesApiService,
    private readonly i18n: I18nService,
    private router: Router
  ) { }

  ngOnInit() {
    this.locale = this.i18n.currentLocale as LanguageEnum;
    this.isLoading = true;
    this.sharesService
      .getResourceMonitoringStatsAdmin()
      .then((stats) => {
        this.stats = stats;
        this.formatData();
      })
      .finally(() => (this.isLoading = false));
  }

  private formatData() {
    this.resourcesNumberWithSharesData = [];

    this.legendTemplate = `{name}: {value} ${this.i18n.translate('common.resources')} [{color2}]({value.percent}%)[/]`;

    this.resourcesNumberWithSharesData.push({
      level: this.i18n.translate('shares.shareRiskScore.high'),
      count: this.stats.highCount,
      color: color(this.COLOR_RED),
      color2: color(this.COLOR_GRAY),
    });

    this.resourcesNumberWithSharesData.push({
      level: this.i18n.translate('shares.shareRiskScore.moderate'),
      count: this.stats.moderateCount,
      color: color(this.COLOR_ORANGE),
      color2: color(this.COLOR_GRAY),
    });

    this.resourcesNumberWithSharesData.push({
      level: this.i18n.translate('shares.shareRiskScore.low'),
      count: this.stats.lowCount,
      color: color(this.COLOR_GREEN),
      color2: color(this.COLOR_GRAY),
    });
  }

  public handleAction() {
    this.router.navigate([ '/shares/resources' ]);
  }

  public handleLegendItemClick(filter: any) {
    let riskFilter;
    switch (filter) {
      case 'Élevé' || 'High':
        riskFilter = FileRiskEnum.HIGH;
        break;
      case 'Modéré' || 'Moderate':
        riskFilter = FileRiskEnum.MODERATE;
        break;
      case 'Faible' || 'Low':
        riskFilter = FileRiskEnum.LOW;
        break;
      default:
      // do nothing
    }
    this.router.navigate([ '/shares/resources' ], {
      queryParams: {
        riskFilter,
      },
    });
  }
}
