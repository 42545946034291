<ui-static-table
  *ngIf="activitiesData"
  [columnsDef]="columnsDef"
  [dataSource]="activitiesData"
  [expendedTemplate]="justification"
  [addPaddingOnFirst]="false"
  [isSelectingOnlyOneAtATime]="false"
  [isCustomSorting]="false"
  [useEmptyMessage]="false"
  [locale]="i18n.currentLocale"
  sort="timestamp"
  direction="desc"
>
</ui-static-table>

<div
  class="no-activities"
  *ngIf="activitiesData.length === 0"
>
  {{ 'files.drawer.tabs.activities.noActivities' | t }}
</div>

<ng-template
  #justification
  let-row="row"
>
  <div class="justification-container">
    <p class="label">{{ 'files.drawer.tabs.activities.justification' | t }}</p>
    <p>{{row.isSystem ? getSystemJustification(row.activityType) : row.justification}}</p>
  </div>
</ng-template>
