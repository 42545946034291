<div class="share-details-infos">
  <ng-container *ngIf="sharedFilePermission?.createdBy && sharedFilePermission?.createdAt">
    {{
    'sharedFile.drawer.shares.table.details.createdBy'
    | t
    : {
    createdBy: sharedFilePermission?.createdBy,
    createdAt: (sharedFilePermission?.createdAt | dt)
    }
    }}
    <br />
  </ng-container>

  <ng-container *ngIf="sharedFilePermission?.createdAt">
    {{
    'sharedFile.drawer.shares.table.details.activeSince'
    | t: { activeSince: (sharedFilePermission?.createdAt | dateAgo: {timeOnly: i18nService.currentLocale === 'fr', locale : i18nService.currentLocale} ) }
    }}
    <br />
  </ng-container>

  <ng-container *ngIf="sharedFilePermission?.expiresAt">
    {{
    'sharedFile.drawer.shares.table.details.expiration'
    | t: { expiration: (sharedFilePermission?.expiresAt | dt) }
    }}
    <br />
  </ng-container>

  <ng-container>
    {{
    'sharedFile.drawer.shares.table.details.lastAccess'
    | t
    : {
    lastAccess:
    (sharedFilePermission?.lastAccessed | lastAccessedFormat: fileCreationDateLimit)
    }
    }}
    <br />
  </ng-container>
</div>
