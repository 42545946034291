import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiEndpointPrefixEnum, EcoApi } from 'projects/@common/definitions/eco-api';
import { EcoUrlService } from '../../core/url.service';

@Injectable({
  providedIn: 'root',
})
export class OrganizationsApiService extends EcoApi {
  protected prefix = ApiEndpointPrefixEnum.sg;

  constructor(readonly http: HttpClient, url: EcoUrlService) {
    super(http, url);
  }

  public async updateOrganizationSettings(newSettings: any): Promise<any> {
    const url = `${this.host}/organization/settings`;
    return this.http.put(url, newSettings).toPromise();
  }

  public async getOrganizationSettings(): Promise<any> {
    const url = `${this.host}/organization/settings`;
    return this.http.get(url).toPromise();
  }

  public async updateOrganizationSensitivityLabels(newSettings: any): Promise<any> {
    const url = `${this.host}/organization/sensitivityLabels`;
    return this.http.put(url, newSettings).toPromise();
  }

  public async getOrganizationSensitivityLabels(): Promise<any> {
    const url = `${this.host}/organization/sensitivityLabels`;
    return this.http.get(url).toPromise();
  }

  public async getOrganizationAttributeValues(): Promise<any> {
    const url = `${this.host}/users/attributes/values`;
    return this.http.get(url).toPromise();
  }
}
