import { Component, Input, OnChanges, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Accumulatables, Accumulate } from '@common/modules/accumulator/accumulator.store';
import { DrawerService } from '@common/modules/layout/components/drawer/services/drawer.service';
import { NoticeLevels, NoticeService } from '@common/modules/notice/notice.service';
import { Select, Store } from '@ngxs/store';
import { IconUtil } from '@ui-kit/util/icon-util';
import { I18nService } from "projects/@common/modules/i18n/i18n.service";
import { EcoSessionState } from 'projects/@common/modules/session/state/session.state';
import { OrganizationsApiService } from 'projects/@common/services/api/sg/organizations/organizationsApi.service';
import { SharesApiService } from 'projects/@common/services/api/sg/shares/shares-api.service';
import { FileRiskEnum, ISharedFile } from 'projects/@common/services/api/sg/shares/shares.definitions';
import { MobileService } from 'projects/@common/services/mobile.service';
import { Observable } from 'rxjs';
import { SeverityDecidingFactorService } from '../../share-risk-score/severityDecidingFactor.service';
import { SilenceShareNotificationComponent } from '../../silence-share-notification/silence-share-notification.component';

@Component({
  selector: 'shared-file-details',
  templateUrl: './shared-file-details.component.html',
  styleUrls: ['./shared-file-details.component.scss'],
  host: {
    class: 'shared-file-shares',
  },
  encapsulation: ViewEncapsulation.None,
})
export class SharedFileDetails implements OnInit, OnChanges {
  @Input() public fileId: string;
  @Input() public fileCreationDateLimit: number;
  @Input() public isFromConsoleOrg: boolean = false;
  @Input() public risk: FileRiskEnum;
  @Select(EcoSessionState.user) public currentUser$: Observable<any>;

  public sharedFile: ISharedFile & { webFolder: string; };

  public isLoading = true;
  public isLoadingTopContent = false;

  public isAdmin = false;

  public fileIcon: string;

  public options;

  public riskFactors: string[];

  constructor(
    private readonly store: Store,
    private sharesService: SharesApiService,
    private dialog: MatDialog,
    private mobileService: MobileService,
    public i18n: I18nService,
    private severityDecidingFactorService: SeverityDecidingFactorService,
    private organizationsApiService: OrganizationsApiService,
    private noticeService: NoticeService,
    private drawerService: DrawerService
  ) {
  }

  public get showSeverityGuideInfo(): boolean {
    return this.risk !== FileRiskEnum.LOW;
  }

  public ngOnInit(): void {
    this.currentUser$.subscribe((user) => this.isAdmin = this.isAdmin || user.isAdmin);
    this.isLoadingTopContent = true;
    this.organizationsApiService.getOrganizationSettings()
      .then((response) => {
        this.options = response.silencingOptions;
      })
      .finally(() => {
        this.isLoadingTopContent = false;
      });
  }

  public ngOnChanges() {
    this.isLoading = true;

    const call = this.isFromConsoleOrg ? this.sharesService.getSharedFileDetailsOrg(this.fileId) : this.sharesService.getSharedFileDetails(this.fileId);

    call.then((file) => {
      this.sharedFile = { ...file, webFolder: file.webUrl?.substring(0, file.webUrl.lastIndexOf('/')) };
      this.fileIcon = this.getfileIcon(this.sharedFile.name);
      this.sharedFile.risk = this.risk;
      this.riskFactors = this.severityDecidingFactorService.handleFactorDisplayValue(this.sharedFile.riskAssociatedFactors);
    })
      .catch(error => {
        if (error.status === 403) {
          this.store.dispatch(new Accumulate({ accumulatable: Accumulatables.LIST_REFRESHER }));
          this.noticeService.notifyUser({ message: 'shares.drawer.error.details.forbidden', level: NoticeLevels.ERROR });
        } else {
          this.noticeService.notifyUser({ message: 'shares.drawer.error.details.general', level: NoticeLevels.ERROR });
        }
        this.drawerService.hideDrawer();
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  public getfileIcon(fileName: string): string {
    const extension = fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length);

    if (extension !== fileName) {
      return IconUtil.getFileIconFromFileExtension(extension);
    }

    return IconUtil.getFileIconFromFileExtension(null);
  }

  public openFile(webUrl: string) {
    window.open(webUrl, '_blank');
  }

  public openSilencingDialog() {
    this.dialog.open(SilenceShareNotificationComponent, {
      maxWidth: !this.mobileService.isMobile() ? '75%' : '100%',
      panelClass: 'custom-dialog',
      data: {
        sharedFileId: this.fileId,
        options: this.options.map((option) => option.toString()),
      },
    });
  }
}
