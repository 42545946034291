import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'sg-basic-expandable',
  templateUrl: './basic-expandable.component.html',
  styleUrls: [ './basic-expandable.component.scss' ],
})
export class BasicExpandableComponent implements OnInit {
  public isOpen = false;
  constructor () { }

  ngOnInit(): void {
  }

  public toggle() {
    this.isOpen = !this.isOpen;
  }
}
