import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EcoUrlService } from 'projects/@common/services/api/core/url.service';
import { EmailUtil } from 'projects/@common/utils/email-util';
import { OrganizationSupportTypeEnum } from '../../enums/organizationSupportType.enum';

export enum OpsManagerNotificationsStatusEnum {
  COMPLETED = 'COMPLETED',
  COMPLETED_WITH_ERROR = 'COMPLETED_WITH_ERROR',
  ERROR = 'ERROR',
  CRITICAL = 'CRITICAL',
  PENDING = 'PENDING'
}

@Injectable({
  providedIn: 'root',
})
export class OpsManagerService {
  private mondataSupportCenter = EcoUrlService.GetMondataSupportCenterUrl();

  public supportLink: string;

  constructor(private readonly router: Router) {}

  public getNotificationIconByStatus(status: OpsManagerNotificationsStatusEnum): string {
    switch (status) {
      case OpsManagerNotificationsStatusEnum.COMPLETED:
        return 'assets/favicons/icon_conforme.svg';
      case OpsManagerNotificationsStatusEnum.PENDING:
        return 'assets/favicons/icon_in_progress.svg';
      case OpsManagerNotificationsStatusEnum.COMPLETED_WITH_ERROR:
        return 'assets/favicons/icon_alert.svg';
      case OpsManagerNotificationsStatusEnum.ERROR:
        return 'assets/favicons/icon_x_red.svg';
      default:
        return 'assets/favicons/icon_in_progress.svg';
    }
  }

  public getNotificationStatus(notificationPendingOperationsCount: number, notification: any): OpsManagerNotificationsStatusEnum {
    if (notification?.status?.success === notification?.status?.operationsCount) {
      return OpsManagerNotificationsStatusEnum.COMPLETED;
    }

    if (notificationPendingOperationsCount > 0) {
      return OpsManagerNotificationsStatusEnum.PENDING;
    }

    if (notification?.status?.critical > 0) {
      return OpsManagerNotificationsStatusEnum.ERROR;
    }

    if (
      notificationPendingOperationsCount === 0 &&
      notification?.status?.error !== 0 &&
      notification?.status?.error < notification?.status?.operationsCount
    ) {
      return OpsManagerNotificationsStatusEnum.COMPLETED_WITH_ERROR;
    }

    return OpsManagerNotificationsStatusEnum.ERROR;
  }

  public handleSupportCenterClick(supportType: OrganizationSupportTypeEnum = OrganizationSupportTypeEnum.MONDATA): void {
    switch (supportType) {
      case OrganizationSupportTypeEnum.MONDATA:
        this.router.navigate([ '/externalRedirect', { externalUrl: this.mondataSupportCenter } ]);
        break;
      case OrganizationSupportTypeEnum.EXTERNAL_LINK:
        this.router.navigate([ '/externalRedirect', { externalUrl: this.supportLink } ]);
        break;
      case OrganizationSupportTypeEnum.EMAIL_LINK:
        this.router.navigate([
          '/externalRedirect',
          { externalUrl: EmailUtil.getMailTo(this.supportLink) },
        ]);
        break;
      default:
        break;
    }
  }
}
