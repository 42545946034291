<base-dialog
    [title]="'groups.action.message.EXP_DATE' |  t : {groupName : data.group.displayName }"
  (onCancel)="dialogRef.close()"
>
  <div class="all-container">
    <div class="text">
      <ng-container
        *ngIf="data.group.securityModel.expirationTrigger === 'INACTIVITY'"
      >
        <ui-field-group>
          <ui-static
            [label]="'groups.action.archive_date.expiration_duration' | t"
            [value]="
              'groups.action.archive_date.new_expiration_duration_vavlue'
                | t : { duration: data.group.securityModel.expirationDuration }
            "
          ></ui-static>
          <ui-static
            [label]="'groups.action.archive_date.current_expiration' | t"
            [value]="
              data.group.archiveDate | dt : { locale: i18n.currentLocale }
            "
          ></ui-static>
          <ui-static
            [label]="'groups.action.archive_date.new_expiration_duration' | t"
            [value]="maxExpirationDate | dt : { locale: i18n.currentLocale }"
          ></ui-static>
        </ui-field-group>
        <div
          [innerHTML]="
            'groups.action.archive_date.message.inactivity'
              | t
                : {
                    date:
                      maxExpirationDate | dt : { locale: i18n.currentLocale }
                  }
          "
        ></div>
      </ng-container>
      <ng-container
        *ngIf="data.group.securityModel.expirationTrigger === 'CREATION'"
      >
        <p>
          {{
            "groups.action.archive_date.message.creation.intro"
              | t
                : {
                    archiveDate:
                      data.group.archiveDate
                      | dt : { locale: i18n.currentLocale }
                  }
          }}
        </p>
        <p>
          {{
            "groups.action.archive_date.message.creation.detail"
              | t
                : {
                    date:
                      maxExpirationDate | dt : { locale: i18n.currentLocale }
                  }
          }}
        </p>
        <ui-date-picker
          class="date-picker"
          [label]="'groups.action.archive_date.new_expiration_duration' | t"
          [minDate]="minExpirationDate"
          [maxDate]="maxExpirationDate"
          [defaultTimestamp]="maxExpirationDate.getTime()"
          (onDateSelection)="onExpirationDateSelected($event)"
        ></ui-date-picker>
      </ng-container>
    </div>
    <div class="buttons">
      <button
        [loading]="isSaving"
        ui-button
        color="standard"
        (click)="updateDate()"
      >
        {{ "groups.action.updateDate.button.label" | t }}
      </button>
      <button
        [disabled]="isSaving"
        ui-button
        color="secondary"
        (click)="close()"
      >
        {{ "common.action.cancel" | t }}
      </button>
    </div>
  </div>
</base-dialog>
