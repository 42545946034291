export const SAINE_GESTION_APP_PAGES = {
  user: {
    mdAssistOps: {
      dashboard: {
        pageTitleTranslationKey: 'dashboard.title',
      },
      userShares: {
        pageTitleTranslationKey: 'shares.userShares',
      },
      resourcesWithShares: {
        pageTitleTranslationKey: 'shares.resourcesWithShares',
      },
      requestsTracking: {
        pageTitleTranslationKey: 'requests.title',
      },
    },
  },
};
