import { ChangeDetectorRef, Directive } from '@angular/core';

export interface TableStatus {
  isLoading: boolean;
  total: number;
}

@Directive()
export class TablesManager {
  protected isRefreshed = true;

  protected tableStatuses: any = {};

  constructor(protected readonly cdr: ChangeDetectorRef) {}

  public get total() {
    let total = 0;
    for (const index in this.tableStatuses) {
      if (this.tableStatuses[index]) {
        total += this.tableStatuses[index].total;
      }
    }
    return total;
  }

  public get isLoading() {
    if (this.isRefreshed) {
      return true;
    }
    for (const index in this.tableStatuses) {
      if (this.tableStatuses[index] && this.tableStatuses[index].isLoading) {
        return true;
      }
    }
    return false;
  }

  public updateTableStatus(index: number, tableStatus: TableStatus) {
    setTimeout(() => {
      this.isRefreshed = false;
      this.tableStatuses[`${index}`] = tableStatus;
      this.cdr.detectChanges();
    });
  }
}
