import { Component, Input, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { I18nService } from '@common/modules/i18n/i18n.service';
import { BaseDialogComponent } from 'projects/@assist-ops/components/base-dialog/base-dialog.component';
import { IDefaultApprover } from 'projects/@common/services/api/sg/group-templates/group-templates.definitions';

@Component({
  selector: 'sg-confirmation-section',
  templateUrl: './confirmation-section.component.html',
  styleUrls: [ './confirmation-section.component.scss' ],
})
export class ConfirmationSectionComponent implements OnInit {
  @Input() public defaultApprovers: IDefaultApprover[];
  @Input() public addLineBreak = true;

  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<BaseDialogComponent>,
    private i18n: I18nService
  ) {}
  ngOnInit(): void {

  }

  public goToRequests(): void {
    this.dialogRef.close();
    this.router.navigate([ 'requests' ]);
  }

  public get formattedDefaultApprovers(): string {
    // gives "element1 and element2" OR "element1, element2 and element3"
    return this.defaultApprovers
      .map((defaultApprover) => `${defaultApprover.firstName}`)
      .join(", ")
      .replace(/, ([^,]*)$/, ` ${this.i18n.translate("common.and")} $1`); 
  }

}
