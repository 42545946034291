import { List } from '@amcharts/amcharts4/core';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiEndpointPrefixEnum, EcoApi } from 'projects/@common/definitions/eco-api';
import { FeatureFlagsEnum } from 'projects/@common/modules/layout/definitions/base-sidebar.service';
import { UrlUtils } from 'projects/@common/utils/utils';
import {
  GetResourceGroupRequest,
  IResourceResponse
} from 'projects/console-sg/src/app/pages/teams-resources/services/resources-list.service';
import { EcoUrlService } from '../../core/url.service';
import { BackgroundTasksApiService } from '../../notifications/backgroundTasks-api.service';
import { IOrganizationResourceTendency, TendencyTimeRangesEnum } from '../shares/shares.definitions';
import { GroupsTemporaryDataService } from '../temporary-data/groups-temporary-data.service';
import { IGroup, IGroupMember, IRequest, IRequestCreation, ITeamsChannel } from './groups.definitions';

@Injectable({
  providedIn: 'root',
})
export class GroupsApiService extends EcoApi {
  protected prefix = ApiEndpointPrefixEnum.sg;

  constructor(
    readonly http: HttpClient,
    url: EcoUrlService,
    private temporaryDataService: GroupsTemporaryDataService,
    private backgroundTaskApiService: BackgroundTasksApiService
  ) {
    super(http, url);
  }

  public listOrgGroups(params: any): Promise<List<IGroup[]>> {
    return this.http.get<any>(`${this.host}/groups?${UrlUtils.jsonToQueryParams(params)}`).toPromise();
  }

  public listOrgOwnedGroups(): Promise<any[]> {
    return this.http.get<any>(`${this.host}/groups/atRisk`).toPromise();
  }

  public updateGroupArchiveDate(params: { groupOfficeId: string; newArchiveDate: number; }): Promise<number> {
    const url = `${this.host}/archive/archiveDate/${params.groupOfficeId}`;
    return this.http.patch<any>(url, { newArchiveDate: params.newArchiveDate }).toPromise();
  }

  public archiveGroup(body: { groupOfficeId: string; }): Promise<void> {
    return this.http.post<any>(`${this.host}/archive`, body).toPromise();
  }

  public async getUserRiskGroups(): Promise<{ total: number; atRisk: number; atRiskGroupIds: string[]; }> {
    const url = `${this.host}/user/groups/atRisk`;
    return this.http.get<any>(url, this.getFlagParams({ flags: [ FeatureFlagsEnum.RESOURCE_MANAGEMENT ] })).toPromise();
  }

  public async listOwnedGroup(request: GetResourceGroupRequest): Promise<IResourceResponse> {
    const [ groups ] = await Promise.all([
      this.http
        .get<IResourceResponse>(`${this.host}/groups/owned?${UrlUtils.jsonToQueryParams(request.getQueryParams())}`)
        .toPromise(),
      this.updateInProgressGroups(),
    ]);

    return groups;
  }

  public listBasicOwnedGroup(params: { limit: number; fulltext: string; }): Promise<any[]> {
    const param = `${UrlUtils.jsonToQueryParams(params)}`;
    return this.http.get<any[]>(`${this.host}/groups/owned/basic?${param}`).toPromise();
  }

  public listGroupMembers(
    groupOfficeId: string,
    fulltext?: string
  ): Promise<{ members: IGroupMember[]; total: number; }> {
    return this.http
      .get<any>(`${this.host}/groups/members/${groupOfficeId}?${UrlUtils.jsonToQueryParams({ fulltext })}`)
      .toPromise();
  }

  public getWebUrl(groupOfficeId: string): Promise<{ url: string; }> {
    return this.http.get<any>(`${this.host}/groups/${groupOfficeId}/webUrl`).toPromise();
  }

  public revokeUserAccess(
    groupOfficeId: string,
    groupName: string,
    members: { name: string; o365UserId: string; }[]
  ): Promise<IGroupMember[]> {
    return this.http
      .post<any>(`${this.host}/groups/${groupOfficeId}/users/revoke`, {
      groupName,
      members,
    })
      .toPromise();
  }

  public getGroup(groupId: string): Promise<any> {
    const url = `${this.host}/groups/${groupId}`;
    return this.http.get<any>(url).toPromise();
  }

  public createGroup(group: IRequestCreation): Promise<IGroup | IRequest> {
    const url = `${this.host}/groups`;
    group.requestInformation.useModelChannels = true;
    return this.http.post<any>(url, { ...group, useModelChannels: true }).toPromise<any>();
  }

  public async getGroupNameExists(groupName: string): Promise<IGroup> {
    return this.http.get<any>(`${this.host}/groups/name/${groupName}`).toPromise();
  }

  public applyTemplateToGroup(templateId: string, groupId: string, useModelChannels = true): Promise<void> {
    const url = `${this.host}/groups/${groupId}/template`;
    return this.http.put<any>(url, { templateId, useModelChannels }).toPromise<void>();
  }

  public async deleteGroup(groupId: string): Promise<any> {
    const url = `${this.host}/groups/${groupId}`;
    return this.http.delete<void>(url).toPromise();
  }

  public async createChannel(channel: ITeamsChannel, groupGuid: string): Promise<ITeamsChannel> {
    const url = `${this.host}/teams/${groupGuid}/channels`;
    return this.http.post<any>(url, channel).toPromise<any>();
  }

  public async listOrgResourceTendency(params?: {
    gte: TendencyTimeRangesEnum;
  }): Promise<{ organizationResources: IOrganizationResourceTendency[]; }> {
    const url = `${this.host}/groups/org/tendency${params?.gte ? `?${UrlUtils.jsonToQueryParams(params)}` : ''}`;
    return this.http.get<any>(url).toPromise();
  }

  public async listOrgTop10riskyUsers(): Promise<any> {
    const url = `${this.host}/groups/org/top10/highRisk`;
    return this.http.get<any>(url).toPromise();
  }

  public async getUsersSublistByOrganization(params: { order?: string; search?: string; filter?: string; } = {}): Promise<any> {
    const url = `${this.host}/groups/users/subgroup?${UrlUtils.jsonToQueryParams(params)}`;
    return this.http.get<any>(url).toPromise();
  }

  private async updateInProgressGroups(): Promise<void> {
    const jobGuids = this.temporaryDataService.inProgressGroupsJobGuids;
    await Promise.all(jobGuids.map(async (jobGuid) => {
      const details = await this.backgroundTaskApiService.getOpsManagerJobDetails(jobGuid);
      const msGroupId = details?.operations?.find((operation) => operation.payload.msGroupId)?.payload?.msGroupId;
      if (msGroupId) {
        if (details?.jobStatus.operationsCount !== details?.jobStatus.success) {
          this.temporaryDataService.addOwnerGroup({
            isCertifyExpiring: false,
            toHide: true,
            id: msGroupId,
            displayName: '',
            isCertifyExpired: false,
            securityModel: null,
            certificationInformation: null,
            createdAt: null,
            type: null,
            description: '',
          });
        } else {
          this.temporaryDataService.removeInProgressJobGuid([ jobGuid ]);
          this.temporaryDataService.removeInProgressOwnerGroup([ msGroupId ]);
        }
      }
    }));
  }
}
