import { Pipe, PipeTransform } from '@angular/core';
import { I18nService } from '../i18n/i18n.service';

@Pipe({
  name: 'dateAgo',
  pure: true,
})
export class DateAgoPipe implements PipeTransform {
  private SECOND = 1;

  private MINUTE = 60 * this.SECOND;

  private HOUR = 60 * this.MINUTE;

  private DAY = 24 * this.HOUR;

  private WEEK = 7 * this.DAY;

  private MONTH = 30 * this.DAY;

  private YEAR = 365 * this.DAY;

  constructor (private i18nService: I18nService) { }

  public transform(value: any, timeOnly = false, locale: string = 'fr'): any {
    if (!value) {
      return value;
    }

    const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);

    const intervals = {
      year: this.YEAR,
      month: this.MONTH,
      week: this.WEEK,
      day: this.DAY,
      hour: this.HOUR,
      minute: this.MINUTE,
      second: this.SECOND,
    };

    if (seconds < 29) {
      return this.i18nService.translateWithLanguage('interval.justNow', locale as any);
    };

    let counter: number;
    // eslint-disable-next-line guard-for-in
    for (const interval in intervals) {
      counter = Math.floor(seconds / intervals[interval]);

      if (counter > 0) {
        const intervalTranslation = this.i18nService.translate(interval);
        if (counter === 1 || intervalTranslation.endsWith('s')) {
          return this.i18nService.translateWithLanguage(timeOnly ? 'interval.ago.timeOnly' : 'interval.ago', locale as any, {
            counter: `${counter}`,
            interval: intervalTranslation,
          });
        }
        return this.i18nService.translateWithLanguage(timeOnly ? 'interval.agoPlurial.timeOnly' : 'interval.agoPlurial', locale as any, {
          counter: `${counter}`,
          interval: intervalTranslation,
        });
      }
    }
  }
}
