<ui-field-group class="relative">
  <ui-select
    *ngIf="isEditing"
    [options]="sharingOptions"
    [group]="defaultResourceSharingForm.form"
    control="type"
    [label]="'group.detail.defaultSharing.name' | t"
  ></ui-select>

  <ui-static
    *ngIf="!isEditing"
    class="fullwidth"
    [label]="'group.detail.defaultSharing.name' | t"
    [value]="defaultSharingDisplayValue | t"
  ></ui-static>
</ui-field-group>
