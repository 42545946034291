import { Clipboard } from '@angular/cdk/clipboard';
import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { UiStaticTableMapperService } from '@ui-kit/components/ui-static-table/ui-static-table-mapper.service';
import { UiStaticTableRowType } from '@ui-kit/components/ui-static-table/ui-static-table-row/ui-static-table-row.component';
import { LanguageEnum } from '@ui-kit/interfaces/ILanguage';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { AbstractDrawer } from 'projects/@common/modules/layout/components/drawer/AbstractDrawer';
import { DrawerService } from 'projects/@common/modules/layout/components/drawer/services/drawer.service';
import { Notice, NoticeLevels, NoticeService } from 'projects/@common/modules/notice/notice.service';
import { DateAgoPipe } from 'projects/@common/modules/pipes/dateAgo.pipe';
import { EcoUrlService } from 'projects/@common/services/api/core/url.service';
import { BackgroundTasksApiService } from 'projects/@common/services/api/notifications/backgroundTasks-api.service';
import { OperationStatus } from 'projects/@common/services/api/notifications/notifications.definitions';
import { MobileService } from 'projects/@common/services/mobile.service';
import { clone } from 'projects/@common/utils/utils';
import { OpsManagerNotificationsStatusEnum, OpsManagerService } from '../ops-manager.service';

@Component({
  selector: 'ops-manager-notification-detail',
  templateUrl: './ops-manager-notification-detail-drawer.component.html',
  styleUrls: [ './ops-manager-notification-detail-drawer.component.scss' ],
  providers: [ DateAgoPipe ],

})
export class OpsManagerNotificationDetailDrawer extends AbstractDrawer implements OnInit, OnDestroy {
  @Input() public data: any = null;

  private mondataSupportCenter = EcoUrlService.GetMondataSupportCenterUrl();

  public jobGuid = '';

  public loading = false;

  private nextToken = 0;

  private lastScrollTop = 0;

  public notification: any;

  public notificationStatus: OpsManagerNotificationsStatusEnum = OpsManagerNotificationsStatusEnum.PENDING;

  public notificationIcon = '';

  public operations: any[] = [];

  constructor (
    public readonly mobileService: MobileService,
    public opsManagerService: OpsManagerService,
    private readonly store: Store,
    private notice: NoticeService,
    private readonly backgroundTasksApiService: BackgroundTasksApiService,
    private readonly drawerService: DrawerService,
    private readonly i18n: I18nService,
    private readonly uiStaticTableMapperService: UiStaticTableMapperService,
    private readonly clipboard: Clipboard
  ) {
    super(store);
  }

  public columnsDef = [
    {
      label: this.i18n.translate('ops-manager.notices.detail.operations.title'),
      field: 'name',
      type: UiStaticTableRowType.TEXT,
      width: '70%',
      isResizable: false,
    },
    {
      label: this.i18n.translate('ops-manager.notices.detail.operations.status'),
      field: 'status',
      type: UiStaticTableRowType.ENUM_WITH_ICON,
      enumPrefix: 'ops-manager.notices.detail.operations.status.',
      iconName: this.getStatusIcon,
      customIcon: true,
      isSvg: true,
      iconSide: 'left',
      width: '25%',
      isResizable: false,
    },
    {
      label: '',
      field: 'icon',
      showIconOnHover: true,
      type: UiStaticTableRowType.EXPENDABLE_ICON,
      showIcon: false,
      noSorting: true,
      width: '5%',
      isResizable: false,
    },
  ];


  ngOnInit(): void {
    this.notification = clone(this.data.notification);
    this.jobGuid = this.notification.jobGuid;
    this.refreshStatus();
    this.handleRefresh();
  }

  public get notificationMessage(): string {
    return this.store.selectSnapshot<string>((state) => state.i18n.locale) === LanguageEnum.FRENCH
      ? this.notification.title.fr
      : this.notification.title.en;
  }

  public get notificationPendingOperationsCount(): number {
    return (
      this.notification.status.operationsCount -
      this.notification.status.error -
      (this.notification.status.critical || 0) -
      this.notification.status.success
    );
  }

  public handleRefresh(): void {
    this.operations = [];
    this.nextToken = 0;
    this.loadJobDetails();
  }

  private loadJobDetails(): void {
    if (!this.loading) {
      this.loading = true;
      this.backgroundTasksApiService
        .getOpsManagerJobDetails(this.jobGuid, {
          from: this.nextToken,
          locale: this.i18n.currentLocale,
        })
        .then((opsManagerJobDetails) => {
          this.notification.status = opsManagerJobDetails.jobStatus;
          this.refreshStatus();
          this.uiStaticTableMapperService.setUnexpandableRows(opsManagerJobDetails.operations, (operation) => operation.status !== OperationStatus.ERROR);
          this.operations = this.operations.concat(opsManagerJobDetails.operations);
          this.nextToken = this.operations.length;
        })
        .catch((error) => {
          this.notice.notifyUser(new Notice('ops-manager.error.list', NoticeLevels.ERROR));
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }

  public refreshStatus(): void {
    this.notificationStatus = this.opsManagerService.getNotificationStatus(this.notificationPendingOperationsCount, this.notification);
    this.notificationIcon = this.opsManagerService.getNotificationIconByStatus(this.notificationStatus);
  }

  public goToPreviousDrawer(): void {
    this.drawerService.previousDrawer();
  }

  private getStatusIcon(operation: any): string {
    switch (operation.status) {
      case OperationStatus.ERROR:
      case OperationStatus.CRITICAL:
        return 'favicons/icon_x_red';
      case OperationStatus.COMPLETED:
        return 'favicons/icon_conforme';
      default:
        return 'favicons/icon_in_progress';
    }
  }

  public copyOperationGuidToClipBoard(operationGuid: string): void {
    this.clipboard.copy(operationGuid);
  }

  @HostListener('scroll', [ '$event.target' ])
  onScroll(event: any): void {
    const tableViewHeight = event.target.offsetHeight;
    const tableScrollHeight = event.target.scrollHeight;
    const scrollLocation = event.target.scrollTop;

    const limit = tableScrollHeight - tableViewHeight - 1;

    if (scrollLocation > limit && scrollLocation > this.lastScrollTop) {
      this.loadJobDetails();
    }
    this.lastScrollTop = scrollLocation;
  }
}
