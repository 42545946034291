export interface GetDashboardProfileResponse {
  userActions: IUserAction;
  organizationActions: IOrganizationActions;
  conformities: IConformities;
  riskFactors: IUserRisks;
  badges: { userBadges: BadgeEnum[]; organizationBadgesCount: number };
}

export interface IUserRisks {
  amountShares: number;
  amountResources: number;
  amountSensitiveFiles: number
}

export interface IUserAction {
  archive: number;
  certify: { actions: number, revokes: number };
  revokeShares: { actions: number, links: number };
  revokeUsers: number;
  addTemplate: number;
  removeSensitiveFile: number;
  monthlyCounts: { date: number; total: number }[];
  total: number;
}

export interface IOrganizationActions {
  certify: number;
  revokeShares: number;
  revokeSharesLinks: number;
  addTemplate: number;
  archive: number;
  revokeUsers: number;
  total: number;
}

export interface IConformities {
  currentStreakTotal: number;
  currentStreakValues: number[];
  longestStreakTotal: number;
  longestStreakValues: number[];
  yearlyCount: Record<string, number>;
}

export interface GetOrganizationProfileResponse {
  proactiveActions: number;
  badges: number;
  yearsDropdownFilter: number[];
  commitmentLevel: number | null;
}

export enum BadgeEnum {
  FIRST_REVOKE_SHARE = 'FIRST_REVOKE_SHARE',
  FIRST_ADD_TEMPLATE = 'FIRST_ADD_TEMPLATE',
  FIRST_CERTIFY = 'FIRST_CERTIFY',
  FIRST_ARCHIVE_TEAMS = 'FIRST_ARCHIVE_TEAMS',
  FIRST_ARCHIVE_SHAREPOINT = 'FIRST_ARCHIVE_SHAREPOINT',
  FIRST_BATCH_REVOKE = 'FIRST_BATCH_REVOKE',
  FIRST_EXPRESS_REVOKE = 'FIRST_EXPRESS_REVOKE',
  FIRST_ACCESS_REVOKED = 'FIRST_ACCESS_REVOKED',
  FIRST_SENSITIVE_FILE_REMOVED = 'FIRST_SENSITIVE_FILE_REMOVED',

  OBJECTIVE_REVOKE_SHARES_50 = 'OBJECTIVE_REVOKE_SHARES_50',
  OBJECTIVE_REVOKE_SHARES_100 = 'OBJECTIVE_REVOKE_SHARES_100',
  OBJECTIVE_REVOKE_SHARES_250 = 'OBJECTIVE_REVOKE_SHARES_250',
  OBJECTIVE_REVOKE_SHARES_500 = 'OBJECTIVE_REVOKE_SHARES_500',
  OBJECTIVE_REVOKE_SHARES_1000 = 'OBJECTIVE_REVOKE_SHARES_1000',
  OBJECTIVE_REVOKE_SHARES_2000 = 'OBJECTIVE_REVOKE_SHARES_2000',
  OBJECTIVE_REVOKE_SHARES_3000 = 'OBJECTIVE_REVOKE_SHARES_3000',
  OBJECTIVE_EXPRESS_REVOKE_5 = 'OBJECTIVE_EXPRESS_REVOKE_5',
  OBJECTIVE_EXPRESS_REVOKE_10 = 'OBJECTIVE_EXPRESS_REVOKE_10',
  OBJECTIVE_EXPRESS_REVOKE_25 = 'OBJECTIVE_EXPRESS_REVOKE_25',
  OBJECTIVE_EXPRESS_REVOKE_50 = 'OBJECTIVE_EXPRESS_REVOKE_50',

  ACHIEVEMENT_REVOKE_SHARES_10 = 'ACHIEVEMENT_REVOKE_SHARES_10',
  ACHIEVEMENT_REVOKE_SHARES_25 = 'ACHIEVEMENT_REVOKE_SHARES_25',
  ACHIEVEMENT_REVOKE_SHARES_50 = 'ACHIEVEMENT_REVOKE_SHARES_50',
  ACHIEVEMENT_WEEKS_WITHOUT_RISK_4 = 'ACHIEVEMENT_WEEKS_WITHOUT_RISK_4',
  ACHIEVEMENT_WEEKS_WITHOUT_RISK_10 = 'ACHIEVEMENT_WEEKS_WITHOUT_RISK_10',
  ACHIEVEMENT_WEEKS_WITHOUT_RISK_25 = 'ACHIEVEMENT_WEEKS_WITHOUT_RISK_25',
  ACHIEVEMENT_WEEKS_WITHOUT_RISK_52 = 'ACHIEVEMENT_WEEKS_WITHOUT_RISK_52',
  ACHIEVEMENT_REVOKE_USERS_5 = 'ACHIEVEMENT_REVOKE_USERS_5',
  ACHIEVEMENT_REVOKE_USERS_10 = 'ACHIEVEMENT_REVOKE_USERS_10',
  ACHIEVEMENT_REVOKE_USERS_25 = 'ACHIEVEMENT_REVOKE_USERS_25',

  SENSITIVE_FILES_REMOVED_5 = 'SENSITIVE_FILES_REMOVED_5',
  SENSITIVE_FILES_REMOVED_10 = 'SENSITIVE_FILES_REMOVED_10',
  SENSITIVE_FILES_REMOVED_20 = 'SENSITIVE_FILES_REMOVED_20',
  SENSITIVE_FILES_REMOVED_50 = 'SENSITIVE_FILES_REMOVED_50',
  SENSITIVE_FILES_REMOVED_100 = 'SENSITIVE_FILES_REMOVED_100',
  SENSITIVE_FILES_REMOVED_1000 = 'SENSITIVE_FILES_REMOVED_1000',

  LEVE_TOT = 'LEVE_TOT',
  BETE_DE_TRAVAIL = 'BETE_DE_TRAVAIL',
  GARDIEN_DE_SOIREE = 'GARDIEN_DE_SOIREE',
  ANGE_DU_MATIN = 'ANGE_DU_MATIN'
}

export const imageMapping = {
  FIRST_REVOKE_SHARE: 'Badge_premier_usage',
  FIRST_ADD_TEMPLATE: 'Badge_premier_usage',
  FIRST_CERTIFY: 'Badge_premier_usage',
  FIRST_ARCHIVE_TEAMS: 'Badge_premier_usage',
  FIRST_ARCHIVE_SHAREPOINT: 'Badge_premier_usage',
  FIRST_BATCH_REVOKE: 'Badge_premier_usage',
  FIRST_EXPRESS_REVOKE: 'Badge_premier_usage',
  FIRST_ACCESS_REVOKED: 'Badge_premier_usage',
  FIRST_SENSITIVE_FILE_REMOVED: 'Badge_premier_usage',
  OBJECTIVE_REVOKE_SHARES_50: 'Badge_objectif_revocation_total',
  OBJECTIVE_REVOKE_SHARES_100: 'Badge_objectif_revocation_total',
  OBJECTIVE_REVOKE_SHARES_250: 'Badge_objectif_revocation_total',
  OBJECTIVE_REVOKE_SHARES_500: 'Badge_objectif_revocation_total',
  OBJECTIVE_REVOKE_SHARES_1000: 'Badge_objectif_revocation_total',
  OBJECTIVE_REVOKE_SHARES_2000: 'Badge_objectif_revocation_total',
  OBJECTIVE_REVOKE_SHARES_3000: 'Badge_objectif_revocation_total',
  OBJECTIVE_EXPRESS_REVOKE_5: 'Badge_objectif_revocation_express',
  OBJECTIVE_EXPRESS_REVOKE_10: 'Badge_objectif_revocation_express',
  OBJECTIVE_EXPRESS_REVOKE_25: 'Badge_objectif_revocation_express',
  OBJECTIVE_EXPRESS_REVOKE_50: 'Badge_objectif_revocation_express',
  ACHIEVEMENT_REVOKE_SHARES_10: 'Badge_exploit_revocation_en_lot',
  ACHIEVEMENT_REVOKE_SHARES_25: 'Badge_exploit_revocation_en_lot',
  ACHIEVEMENT_REVOKE_SHARES_50: 'Badge_exploit_revocation_en_lot',
  ACHIEVEMENT_WEEKS_WITHOUT_RISK_4: 'Badge_exploit_sans_risque',
  ACHIEVEMENT_WEEKS_WITHOUT_RISK_10: 'Badge_exploit_sans_risque',
  ACHIEVEMENT_WEEKS_WITHOUT_RISK_25: 'Badge_exploit_sans_risque',
  ACHIEVEMENT_WEEKS_WITHOUT_RISK_52: 'Badge_exploit_sans_risque',
  ACHIEVEMENT_REVOKE_USERS_5: 'Badge_exploit_certification',
  ACHIEVEMENT_REVOKE_USERS_10: 'Badge_exploit_certification',
  ACHIEVEMENT_REVOKE_USERS_25: 'Badge_exploit_certification',
  SENSITIVE_FILES_REMOVED_5: 'Badge_retrait_fichier_sensible',
  SENSITIVE_FILES_REMOVED_10: 'Badge_retrait_fichier_sensible',
  SENSITIVE_FILES_REMOVED_20: 'Badge_retrait_fichier_sensible',
  SENSITIVE_FILES_REMOVED_50: 'Badge_retrait_fichier_sensible',
  SENSITIVE_FILES_REMOVED_100: 'Badge_retrait_fichier_sensible',
  SENSITIVE_FILES_REMOVED_1000: 'Badge_retrait_fichier_sensible',
  LEVE_TOT: 'Badge_leve_tot',
  BETE_DE_TRAVAIL: 'Badge_bete_de_travail',
  GARDIEN_DE_SOIREE: 'Badge_gardien_de_soiree',
  ANGE_DU_MATIN: 'Badge_ange_du_matin',
};
