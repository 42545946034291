<ng-container *ngIf="isFromConsoleOrg">
  <div class="org-toolbar">
    <ng-container *ngTemplateOutlet="toolbar"></ng-container>
  </div>
</ng-container>

<ui-card
  class="not-nice-card"
  scrollToBottom
  (onScrollToBottom)="loadMore()"
  *ngIf="!mobileService.isMobile(isFromConsoleOrg ? null : 1000)"
>
  <ng-container *ngIf="!isFromConsoleOrg ">
    <div class="toolbar flex-toolbar">
      <ng-container *ngTemplateOutlet="toolbar"></ng-container>
    </div>
  </ng-container>
</ui-card>

<ui-card
  *ngIf="!mobileService.isMobile(isFromConsoleOrg ? null : 1000)"
  [withPadding]="false"
>
  <ui-static-table
    [loading]="isLoading"
    [dataSource]="dataSource"
    [columnsDef]="columnsDef"
    [twoHeaders]="true"
    [direction]="direction"
    [sortHeader]="sortParent"
    [sort]="sortField"
    (on-row-click)="handleRowClick($event)"
    [addPaddingOnFirst]="false"
    [isCustomSorting]="true"
    (handleCustomSort)="handleSort($event)"
    [locale]="i18n.currentLocale"
  >
  </ui-static-table>
</ui-card>
<div
  *ngIf="mobileService.isMobile(isFromConsoleOrg ? null : 1000)"
  zoneWithoutPadding
>
  <resources-shares-mobile
    [resources]="dataSource"
    [isLoading]="isLoading"
    (onRefresh)="handleRefresh()"
    (onRiskFilter)="handleRiskFilter($event)"
    (onSearch)="handleSearchQuery($event)"
  >
  </resources-shares-mobile>
</div>

<ng-template #toolbar>
  <div class="flex-card" *ngIf="!mobileService.isMobile(requiredWidthForMobile) && !isFromConsoleOrg">
    <ng-container *ngTemplateOutlet="revokeButtons"></ng-container>
  </div>
  <toolbar
    toolbar
    [total]="total"
    [activatedFiltersNumber]="activatedFilters"
    (onApply)="applySelectedFilters()"
    (onCancel)="cancelSelectedFilters()"
    (onClearAllFilters)="clearAllFilters()"
  >
    <ng-container *ngIf="!mobileService.isMobile(isFromConsoleOrg ? null : 1000)">
      <ui-table-search-tool
        [placeholder]="'ui.table-search-tool.placeholder' | t"
        [query]="fulltext"
        (clear)="handleSearchClear()"
        (query)="handleSearchQuery($event)"
      ></ui-table-search-tool>
      <ui-multi-select
        [multiSelectData]="sourcesMultiSelectData"
        (onOptionSelect)="handleSourceFilter($event)"
      ></ui-multi-select>
      <ui-multi-select
        [multiSelectData]="risksMultiSelectData"
        (onOptionSelect)="handleRiskFilter($event)"
      ></ui-multi-select>
      <ui-table-refresh-tool
        [loading]="isLoading"
        (click)="handleRefresh()"
      ></ui-table-refresh-tool>
    </ng-container>
    <ng-container *ngIf="mobileService.isMobile(isFromConsoleOrg ? null : 1000)">
      <div
        toolbar-collapsable-element
        [elementTitle]="'shares.filter.label.sources' | t"
      >
        <ui-multi-select
          [multiSelectData]="sourcesMultiSelectData"
          (onOptionSelect)="handleSourceFilter($event)"
        ></ui-multi-select>
      </div>
      <div
        toolbar-collapsable-element
        [elementTitle]="'shares.filter.label.risks' | t"
      >
        <ui-multi-select
          [multiSelectData]="risksMultiSelectData"
          (onOptionSelect)="handleRiskFilter($event)"
        ></ui-multi-select>
      </div>
    </ng-container>
  </toolbar>
</ng-template>


<ng-template #revokeButtons>
  <app-edit-with-modal
    #editor
    *ngIf="!mobileService.isMobile(requiredWidthForMobile)"
  >
    <div
      class="edit-content small-gap"
      [ngClass]="{ 'pointer': true }"
      [tooltip]="'group.action.remediation.message'| t"
    >
      <button
        ui-button
        [color]="mobileService.isMobile(requiredWidthForMobile) ? 'mobileListFlat' : 'linkFlat'"
        [ngClass]="{
        'margin--small--bottom': !mobileService.isMobile(requiredWidthForMobile),
        'shared-files--revoke-button-mobile': mobileService.isMobile(requiredWidthForMobile)
      }"
        icon="close"
        [icon-size]="mobileService.isMobile(requiredWidthForMobile) ? 'small' : 'medium'"
        (click)="toggle()"
      >
        {{'shares.dialog.revoke.actionButton' | t}}
        <img
          alt="edit-icon"
          src="assets/favicons/icon_chevron_down.svg"
        />
      </button>
    </div>
    <div
      class="revoke-content"
      content
    >
      <button
        class="subButton"
        (click)="openExpressRevokeDialog()"
        [disabled]="disableExpressRevoke"
      >
        <div>
          <p>{{'shares.button.revoke.express.revoke' | t}}</p>
          <p class="subButton--description">{{'shares.button.revoke.express.revoke.description' | t}}</p>
        </div>
      </button>
      <button
        class="subButton"
        (click)="showBatchRevokeDialog = true; toggle()"
      >
        <div>
          <p>{{'shares.button.revoke.express.revoke.byType' | t}} </p>
          <p class="subButton--description">{{'shares.button.revoke.express.revoke.byType.description' | t}}</p>
        </div>
      </button>
    </div>
  </app-edit-with-modal>
</ng-template>

<batch-revoke
  [(showBatchRevokeDialog)]="showBatchRevokeDialog"
  (showBatchRevokeDialogChange)="handleRefresh()"
  resourceId="all"
>
</batch-revoke>
