<div class="container">
  <p class="no-margin top-message">
    {{'groups.dialog.request.title' | t :{ resourceType: getTranslatedGroupType(template) } }}
  </p>

  <form *ngIf="!isLoadingApprovers;else spinner">
    <ui-field-group>
      <ui-field
        [required]="true"
        [label]="'groups.dialog.request.object.label' | t"
        name="description"
        control="description"
        [group]="requestForm"
        [value]="resourceName"
      >
      </ui-field>
    </ui-field-group>

    <ui-field-group>
      <ui-switch-text-static
        [isEditing]="true"
        [required]="true"
        [label]="'groups.dialog.request.justification.label' | t"
        name="justification"
        control="justification"
        [group]="requestForm"
        maxlength="1024"
        [rowsNumber]="4"
      ></ui-switch-text-static>
    </ui-field-group>

    <ui-field-group *ngIf="template.defaultApprovers?.length;else noApprovers">
      <div  class="approvers">
        <div class="label">
          <label ui-label [required]="true">{{ 'requests.drawer.creation.manager' | t }}</label>
          <ui-tooltip [data]="approbationTooltip"></ui-tooltip>
        </div>
        <autocomplete
            *ngIf="template?.defaultApprovers?.length >= 2"
            name="userAutocomplete"
            required="true"
            maxlength="50"
            [canMultiSelect]="false"
            [placeholder]="'search.approuver' | t"
            [itemsToIgnore]="selectedIds"
            [offlineUsers]="usersToSearch"
            (onItemSelected)="addManager($event)"
            [autocompleteType]="usersAutocomplete"
            [locale]="i18n.currentLocale"
            [toggleDropdownAfterAllSelection]="true"
            expandHeight="9rem"
            >
        </autocomplete>
        <ui-user-tags
            [readOnly]="template?.defaultApprovers?.length <= 2 && selectedManagers?.length === 1"
            (onDeleteUser)="removeManager($event)"
            [users]="selectedManagers"
            >
        </ui-user-tags>
      </div>
    </ui-field-group>
  </form>
  <ui-information-message *ngIf="template.defaultApprovers?.length">
    <div class="information-message">
      <p class="no-margin">{{ 'groups.dialog.request.information.text1' | t }}</p>
      <p class="no-margin">{{ 'groups.dialog.request.information.text2' | t }}</p>
    </div>
  </ui-information-message>
</div>

<ng-template #noApprovers>
  <ui-information-message>
    <div class="information-message">
      <p class="no-margin">{{ 'groups.dialog.request.noApprovers.text1' | t }}</p>
      <p class="no-margin">{{ 'groups.dialog.request.noApprovers.text2' | t }}</p>
    </div>
  </ui-information-message>
</ng-template>

<ng-template #spinner>
  <div  class="spinner">
    <ui-spinner size="medium"></ui-spinner>
  </div>
</ng-template>
