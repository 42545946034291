import { Component, Input } from '@angular/core';
import { MobileService } from 'projects/@common/services/mobile.service';
import { OpsManagerNotificationsSectionEnum } from '../../../enums/notificationSection.enum';
import { OrganizationSupportTypeEnum } from '../../../enums/organizationSupportType.enum';

@Component({
  selector: 'ops-manager-notification-section',
  templateUrl: './ops-manager-notification-section.component.html',
  styleUrls: [ './ops-manager-notification-section.component.scss' ],
})
export class OpsManagerNotificationSectionComponent {
  @Input() public notifications: any[] = [];

  @Input() public section: OpsManagerNotificationsSectionEnum;

  @Input() public supportType: OrganizationSupportTypeEnum;

  @Input() public supportLink: string;

  public opsManagerNotificationsSectionEnum = OpsManagerNotificationsSectionEnum;

  constructor(public readonly mobileService: MobileService) {}
}
