import { Component, Input } from '@angular/core';
import { RequestStatusEnum, IconUtil } from '@ui-kit/util/icon-util';
import { RequestHistory } from 'projects/@common/services/api/requests/requests.definitions';

@Component({
  selector: 'request-history',
  templateUrl: './request-history.component.html',
  styleUrls: [ './request-history.component.scss' ],
})
export class RequestHistoryComponent {
  public readonly EXPENDABLE_APPROVER_ATTRIBUTE = 'isExpended';

  @Input() public requestApproversHistory: RequestHistory[];

  @Input() public isLoading = false;

  public toggleRow(requestApprover: RequestHistory) {
    requestApprover[this.EXPENDABLE_APPROVER_ATTRIBUTE] = !requestApprover[this.EXPENDABLE_APPROVER_ATTRIBUTE];
  }

  public getActionIcon(action: RequestStatusEnum) {
    return IconUtil.getRequestStatusIcon(action);
  }
}
