<div
  (scroll)="onScroll($event)"
  class="drawer-detail--scrollable hidden drawer-detail--content"
  [class.drawer-detail--content-mobile]="mobileService.isMobile()"
>
  <div
    class="ops-manager-notices-list--tools"
    [class.ops-manager-notices-list--tools--mobile]="mobileService.isMobile()"
  >
    <button
      ui-button
      icon="icon_refresh"
      [isSvg]="true"
      [custom-icon]="true"
      custom-icon-size="medium"
      color="listActionFlat"
      (click)="handleRefresh()"
    >
      {{ 'ops-manager.notices.refresh' | t }}
    </button>
  </div>
  <div class="ops-manager-notices-list--content">
    <ng-container *ngIf="notifications.length > 0 && !isLoading">
      <ops-manager-notification-section
        class="ops-manager-notices-list--section"
        *ngIf="todayNotifications.length > 0"
        [notifications]="todayNotifications"
        [section]="opsManagerNotificationsSectionEnum.TODAY"
        [supportType]="supportType"
        [supportLink]="supportLink"
      ></ops-manager-notification-section>
      <ops-manager-notification-section
        class="ops-manager-notices-list--section"
        *ngIf="yesterdayNotifications.length > 0"
        [notifications]="yesterdayNotifications"
        [section]="opsManagerNotificationsSectionEnum.YESTERDAY"
        [supportType]="supportType"
        [supportLink]="supportLink"
      ></ops-manager-notification-section>
      <ops-manager-notification-section
        class="ops-manager-notices-list--section"
        *ngIf="oldestNotifications.length > 0"
        [notifications]="oldestNotifications"
        [section]="opsManagerNotificationsSectionEnum.OLDEST"
        [supportType]="supportType"
        [supportLink]="supportLink"
      ></ops-manager-notification-section>
    </ng-container>
    <ng-container *ngIf="isLoading">
      <ui-spinner size="medium" modifier="secondary"></ui-spinner>
    </ng-container>
    <div
      *ngIf="!isLoading && notifications.length === 0"
      class="ops-manager-notices-list--empty"
    >
      <span class="ops-manager-notices-list--empty-text">
        {{ 'ops-manager.notices.list.empty-message-1' | t }}
      </span>
      <span class="ops-manager-notices-list--empty-text">
        {{ 'ops-manager.notices.list.empty-message-2' | t }}
      </span>
      <!-- <span class="ops-manager-notices-list--empty-text"> -->
      <!--   {{ 'ops-manager.notices.list.empty-message-3' | t }} -->
        <!-- <span -->
        <!--   class="ops-manager-notices-list--empty-text--support-link" -->
        <!--   (click)="opsManagerService.handleSupportCenterClick()" -->
        <!-- > -->
        <!--   {{ 'topbar.support.popover.actions.mdsupport' | t }} -->
        <!--   <ui-icon name="open-new"></ui-icon> -->
        <!-- </span> -->
      <!-- </span> -->
    </div>
  </div>
</div>
