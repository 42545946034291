import { Component, ViewEncapsulation, Input } from '@angular/core';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { ISharedFilePermission } from 'projects/@common/services/api/sg/shares/shares.definitions';

@Component({
  selector: 'share-details',
  templateUrl: './share-details.component.html',
  styleUrls: [ './share-details.component.scss' ],
  host: {
    class: 'share-details',
  },
  encapsulation: ViewEncapsulation.None,
})
export class ShareDetails {
  @Input() sharedFilePermission: ISharedFilePermission;

  @Input() isSelect: boolean;

  @Input() fileCreationDateLimit: number;

  constructor (public i18nService: I18nService) {

  }
}
