<div class="slide-container">
  <h2>{{ "dashboard.dialog.carousel.page4.title" | t }}</h2>
  <h3>{{ "dashboard.dialog.carousel.page4.second-title" | t }}</h3>
  <p>{{ "dashboard.dialog.carousel.page4.text1" | t }}</p>
  <p>{{ "dashboard.dialog.carousel.page4.text2" | t }}</p>
  <div class="bottom-section">
    <div class="left-section">
      <div class="illustration-dashboard-container">
        <img
          class="dashboard-icon"
          src="assets/favicons/icon_illustration_dashboard.svg"
        />
        <img class="fusee-icon" src="assets/favicons/icon_fusee.svg" />
      </div>
    </div>
    <div class="right-section">
      <div class="step">
        <img src="assets/favicons/icon_proactive_cybersecurity_step_1.svg" />
        <p>{{ "dashboard.dialog.carousel.page4.text3" | t }}</p>
      </div>
      <div class="step">
        <img src="assets/favicons/icon_proactive_cybersecurity_step_2.svg" />
        <span>
          {{ "dashboard.dialog.carousel.page4.text4" | t }}
          <img src="assets/favicons/icon_pause_warning.svg" />
          {{ "dashboard.dialog.carousel.page4.text5" | t }}
          <img src="assets/favicons/icon_pause_ok.svg" />
        </span>
      </div>
      <div class="step">
        <img src="assets/favicons/icon_proactive_cybersecurity_step_3.svg" />
        <p>{{ "dashboard.dialog.carousel.page4.text6" | t }}</p>
      </div>
      <img
        class="img-zoomin"
        src="assets/favicons/icon_illustration_zoomin.svg"
      />
    </div>
  </div>
</div>
