<div
  class="drawer-detail--scrollable tab-menu"
  [ngClass]="{
    hidden: !isEditing,
    visible: isEditing,
    'dark-background': mobileService.isMobile()
  }"
  scrollToBottom
  (onScrollToBottom)="loadMoreShares()"
>
  <div>
    <ui-warning
      *ngIf="!isLoadingIsAutomaticRevokeActivated && isAutomaticRevokeActivated"
      [warningMessages]="automaticRevokeWarningMessage"
    ></ui-warning>
  </div>

  <div
    class="drawer-detail--content"
    *ngIf="!mobileService.isMobile(); else mobileShares"
  >
    <ng-container *ngIf="!isFromConsoleOrg">
      <ng-template *ngTemplateOutlet="drawerTop"></ng-template>
    </ng-container>

    <shares-section
      *ngIf="!isLoadingIsAutomaticRevokeActivated"
      [shares]="sharedFilesPermissions"
      [isAutomaticRevokeActivated]="isAutomaticRevokeActivated"
      [fileCreationDateLimit]="fileCreationDateLimit"
      [sort]="sort"
      [direction]="direction"
      [isEditing]="isEditing"
      [isLoading]="isLoadingShares"
      (onSorting)="handleSorting($event.sortingColumn, $event.sortingDirection)"
      (onPermissionRemove)="checkRemovePermission($event)"
    ></shares-section>
  </div>
</div>

<ui-form-actions
  *ngIf="isEditing"
  layout="centered"
  class="visible"
>
  <ng-container *ngIf="isSaving">
    <ui-spinner
      size="medium"
      modifier="secondary"
    ></ui-spinner>
  </ng-container>
  <ng-container *ngIf="!isSaving">
    <button
      ui-button
      color="standard"
      (click)="savePermissionsModifications()"
    >
      {{ 'common.action.save' | t }}
    </button>
    <button
      ui-button
      color="secondary"
      (click)="toggleEditMode(false)"
    >
      {{ 'common.action.cancel' | t }}
    </button>
  </ng-container>
</ui-form-actions>

<shared-file-shares-revoke-all
  [fileId]="fileId"
  [fileName]="fileName"
  [fileType]="fileType"
  [(showRevokeAllSharesConfirmationDialog)]="
    showRevokeAllSharesConfirmationDialog
  "
></shared-file-shares-revoke-all>

<shared-file-shares-revoke
  [(showRevokeShareConfirmationDialog)]="showRevokeShareConfirmationDialog"
  [permissionToRemove]="permissionToRemove"
  (isRevokingShare)="removePermission($event)"
></shared-file-shares-revoke>

<ng-template #drawerTop>
  <div class="shares--header">
    <div [ngClass]="{ 'buttons-container': !mobileService.isMobile() }">
      <button
        *ngIf="
          !mobileService.isMobile() &&
          !isEditing &&
          !isLoadingIsAutomaticRevokeActivated
        "
        ui-button
        icon="icon_manage"
        [custom-icon]="true"
        [isSvg]="true"
        icon-size="small"
        color="listActionFlat"
        (click)="toggleEditMode(true)"
      >
        {{ 'sharedFile.drawer.shares.button.manage' | t }}
      </button>

      <button
        *ngIf="isEditing"
        ui-button
        icon="icon_x"
        [custom-icon]="true"
        [isSvg]="true"
        icon-size="small"
        color="listActionFlat"
        (click)="showRevokeAllSharesConfirmationDialog = true"
      >
        {{ 'sharedFile.drawer.shares.button.revokeAll' | t }}
      </button>
    </div>

    <div
      class="shares--descriptions"
      [ngClass]="{ 'shares--descriptions--mobile': mobileService.isMobile() }"
      *ngIf="isEditing"
    >
      {{ 'sharedFile.drawer.shares.description.edit' | t }}
    </div>
  </div>
</ng-template>

<ng-template #mobileShares>
  <ng-template *ngTemplateOutlet="drawerTop"></ng-template>

  <shared-file-shares-mobile
    [shares]="sharedFilesPermissions"
    [isAutomaticRevokeActivated]="isAutomaticRevokeActivated"
    [isEditing]="isEditing"
    [isLoading]="isLoadingShares"
    (onPermissionRemove)="checkRemovePermission($event)"
  ></shared-file-shares-mobile>
</ng-template>