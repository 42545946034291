import { GroupTypeEnum } from '@ui-kit/util/icon-util';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { DropdownActions } from '../../../shared/components/dialogs/resources-dialogs/group-actions/dropdownActions.enum';

export enum ResourceTypeEnum {
  O365OFFICEGROUP = 'o365OfficeGroup',
  O365OFFICETEAMS = 'o365OfficeTeams'
}

export type ResourceTypesEnum = GroupTypeEnum | DeviceEnum;


export enum DeviceEnum {
  desktop = 'desktop',
  mobile = 'mobile'
}

export class ResourceCard {
  public cardValidity: boolean = true;
  public criterias: Criteria[] = [];
  public errorDescription: string;

  constructor(public type: ResourceTypesEnum, public name: string, public description?: string, public resourceId?: string, public groupModel?: any) {
  }

  public setErrorDescription(value: string): void {
    this.errorDescription = value;
  }

  public setCardToInvalid(): void {
    this.cardValidity = false;
  }

  public get isGroup(): boolean {
    return this.type === GroupTypeEnum.O365OFFICEGROUP;
  }

  public get resourceIcon(): { icon: string, background: string; } {
    switch (this.type) {
      case GroupTypeEnum.O365OFFICEGROUP:
        return { icon: 'icon_logo_office', background: '#dde8f1' };
      case GroupTypeEnum.O365OFFICETEAMS:
        return { icon: 'icon_teams', background: '#e0e2f4' };
      case DeviceEnum.desktop:
        return { icon: 'icon_desktop', background: '#f4f1fa' };
      case DeviceEnum.mobile:
        return { icon: 'icon_cell', background: '#e8f3fc' };
      default:
        return { icon: 'icon_cell', background: '#e8f3fc' };
    }
  }

  public isWarning(): boolean {
    return this.isResourceValid() && this.criterias.some((criteria) => criteria.warning);
  }

  public isResourceValid(): boolean {
    return this.criterias.every((criteria) => criteria.validity) && this.cardValidity;
  }

  public addCriteria(criteria: Criteria): void {
    this.criterias.push(criteria);
  }


  public removeCriteriaIcons(): void {
    this.criterias.forEach((criteria) => criteria.hideValidityIcon());
  }
}

export enum CriteriaTypeEnum {
  securityModel = 'securityModel',
  expiration = 'expiration',
  certificate = 'certificate',
  device_state = 'device_state',
  os = 'os',
  device_id = 'device_id'
}

export class Criteria {
  public showValidityIcon = true;
  public copyButton = { show: false, text: null };
  public isClickable = false;
  public type: DropdownActions;
  public tooltipText: string;

  constructor(public icon: string, public name: string, public validity: boolean, public description: string, type: DropdownActions, tooltipText?: string, isClickable?: boolean, public warning?: boolean) {
    this.isClickable = isClickable;
    this.type = type;
    this.tooltipText = tooltipText;
  }

  public hideValidityIcon(): void {
    this.showValidityIcon = false;
  }

  public get iconSrc(): string {
    return `assets/favicons/${this.icon}.svg`;
  }

  public get criteriaIcon(): string {
    if (this.validity !== null) {
      if (!this.validity) {
        return `assets/favicons/icon_erreur.svg`;
      }
      if (this.warning) {
        return `assets/favicons/icon_warning_checkout.svg`;
      }
      return `assets/favicons/icon_green_checkout.svg`;
    }
    return null;
  }

  public addCopyButton(copyText: string): void {
    this.copyButton = { show: true, text: copyText };
  }
}

export class CriteriaFactory {
  constructor(private i18nService: I18nService) {
  }

  public createCriteria(type: CriteriaTypeEnum, validity: boolean, description: string, isClickable?: boolean, warning?: boolean) {
    switch (type) {
      case CriteriaTypeEnum.securityModel:
        return new Criteria(
          'icon_paper',
          this.i18nService.translate('shares.gestions.security_model.label'),
          validity,
          description,
          DropdownActions.TEMPLATE,
          this.i18nService.translate('shares.gestions.security_model.tooltip'),
          isClickable,
          warning
        );
      case CriteriaTypeEnum.certificate:
        return new Criteria(
          'icon_certifier',
          this.i18nService.translate('shares.gestions.security_model.certification.label'),
          validity,
          description,
          DropdownActions.CERTIFY,
          this.i18nService.translate('shares.gestions.security_model.certification.tooltip'),
          isClickable,
          warning
        );
      case CriteriaTypeEnum.expiration:
        return new Criteria(
          'icon_calendar',
          this.i18nService.translate('shares.gestions.security_model.expiration.label'),
          validity,
          description,
          DropdownActions.EXP_DATE,
          this.i18nService.translate('shares.gestions.security_model.expiration.tooltip'),
          isClickable,
          warning
        );

      case CriteriaTypeEnum.device_state:
        return new Criteria(
          'icon_device_state',
          this.i18nService.translate('devices.gestion.device_state'),
          validity,
          description,
          null
        );
      case CriteriaTypeEnum.os:
        return new Criteria(
          'icon_system_grey',
          this.i18nService.translate('devices.gestion.device_os'),
          validity,
          description,
          null
        );
      case CriteriaTypeEnum.device_id:
        return new Criteria(
          'icon_parametre',
          this.i18nService.translate('devices.gestion.device_id'),
          validity,
          description,
          null
        );
      default:
        break;
    }
  }
}
