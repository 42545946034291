import { AfterViewInit, Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GroupsTemporaryDataService } from '@common/services/api/sg/temporary-data/groups-temporary-data.service';
import { Select } from '@ngxs/store';
import { TimeUtil } from '@ui-kit/services/time-util';
import { GroupTypeEnum } from '@ui-kit/util/icon-util';
import { BaseDialogComponent } from 'projects/@assist-ops/components/base-dialog/base-dialog.component';
import { SgStepper } from 'projects/@assist-ops/components/sg-stepper/sg-stepper.component';
import { NoticeLevels, NoticeService } from 'projects/@common/modules/notice/notice.service';
import { EcoSessionState } from 'projects/@common/modules/session/state/session.state';
import { GroupTemplateLifecycleEnum, IGroupTemplate } from 'projects/@common/services/api/sg/group-templates/group-templates.definitions';
import { GroupsApiService } from 'projects/@common/services/api/sg/groups/groupsApi.service';
import { DashboardProfileService } from 'projects/@common/services/dashboard-profile.service';
import { StorageService } from 'projects/@common/services/storage.service';
import { IOwnerGroup } from 'projects/console-sg/src/app/pages/teams-resources/services/resources-list.service';
import { Observable } from 'rxjs';
import { ApplyConfirmationSectionComponent } from '../sections/apply-confirmation-section/apply-confirmation-section.component';
import { CertifySectionComponent } from '../sections/certify-section/certify-section.component';
import { ExplanationSectionComponent } from '../sections/explanation-section/explanation-section.component';
import { ModelSectionComponent } from '../sections/model-section/model-section.component';
import { StructureSectionComponent } from '../sections/structure-section/structure-section.component';

enum SectionIndexes {
  EXPLANATION = 0,
  MODEL = 1 || 0,
  STRUCTURE = 2 || 1,
  ACCESS = 3 || 2 || 1
}

@Component({
  selector: 'sg-apply-model-dialog',
  templateUrl: './apply-model-dialog.component.html',
  styleUrls: [ './apply-model-dialog.component.scss' ],
})

export class ApplyModelDialogComponent implements OnInit, AfterViewInit {
  @ViewChild('explanation') public explanationSection: ExplanationSectionComponent;
  @ViewChild('model') public modelSection: ModelSectionComponent;
  @ViewChild('structure') public structureSection: StructureSectionComponent;
  @ViewChild('certify') public certifySection: CertifySectionComponent;
  @ViewChild('confirmation') public confirmationSection: ApplyConfirmationSectionComponent;

  @ViewChild('sgStepper') public sgStepper: SgStepper;

  @Select(EcoSessionState.user) public currentUser$: Observable<string>;

  @Input() public dataFromQuickAction: {
    group: IOwnerGroup;
    fromQuickAction: boolean;
    refresh: any;
  };

  public currentUser: any; // IUser

  public requestForm: UntypedFormGroup;

  public template: IGroupTemplate;

  public isSaving = false;

  public certifyIsLoaded = false;

  constructor(
    public dialogRef: MatDialogRef<BaseDialogComponent>,
    public fb: UntypedFormBuilder,
    private groupsApiService: GroupsApiService,
    private noticeService: NoticeService,
    private storageService: StorageService,
    private temporaryDataService: GroupsTemporaryDataService,
    private dashboardProfileService: DashboardProfileService,
    @Inject(MAT_DIALOG_DATA) public data: { group?: IOwnerGroup; refresh?: any; }
  ) { }

  ngAfterViewInit() {
    if (this.shouldSkipExplanation) {
      this.sgStepper.goForward();
    }
  }
  ngOnInit(): void {
    if (this.dataFromQuickAction) {
      this.data.group = this.dataFromQuickAction.group;
      this.data.refresh = this.dataFromQuickAction.refresh;
    }
    this.currentUser$.subscribe((currentUser) => this.currentUser = currentUser);
    this.requestForm = this.fb.group({
      request: {
        description: null,
        justification: null,
        managers: this.fb.array([]),
      },
      requestInformation: {
        name: null,
        description: null,
        email: null,
        groupType: null,
        owners: this.fb.array([]),
        templateId: null,
        visibility: null,
      },
      keepStructure: this.fb.control(false, []),
      revokeUsers: this.fb.control([], []),
    });
  }

  public get shouldSkipExplanation(): boolean {
    const value: { setMoment: number, value: string; } = JSON.parse(this.storageService.getLocal('skipApplyExplanation'));

    if (!value?.value) {
      return false;
    }

    if (Date.now() - value.setMoment > TimeUtil.dayDuration) {
      this.storageService.setLocal(
        'skipApplyExplanation',
        JSON.stringify({ setMoment: Date.now(), value: false })
      );
      return false;
    }
    return true;
  }

  public save(): void {
    this.isSaving = true;
    this.groupsApiService.applyTemplateToGroup(
      this.template.id,
      this.data.group.id,
      this.requestForm.value.keepStructure
    ).then(() => {
      this.temporaryDataService.addOwnerGroup(this.tempValidation(this.data.group));
      this.noticeService.notifyUser({ message: 'groups.dialog.apply.confirmation.message', level: NoticeLevels.SUCCESS });

      if (!!this.data.refresh) {
        this.data.refresh();
      }
      if (!this.dataFromQuickAction) {
        this.dialogRef.close();
      }
      if (this.dataFromQuickAction) {
        this.dataFromQuickAction.refresh();
        this.dashboardProfileService.getDashboardUserProfile();
      }
    })
      .catch((error) => {
        this.noticeService.notifyUser({ message: 'groups.dialog.apply.confirmation.message.error', level: NoticeLevels.ERROR });
      }).finally(() => {
        this.isSaving = false;
      });


    if (this.data.group.isCertifyExpired) {
      const membersToRevoke = this.requestForm.value.revokeUsers
        .filter((user) => !user.isCertified)
        .map((user) => ({
          name: user.name,
          o365UserId: user.o365UserId,
        }));
      this.groupsApiService.revokeUserAccess(
        this.data.group.id,
        this.data.group.displayName,
        membersToRevoke
      ).then(() => {
        this.temporaryDataService.addOwnerGroup(this.tempValidation(this.data.group));
        this.noticeService.notifyUser({ message: 'certify.confirmation.message', level: NoticeLevels.SUCCESS });
      }).finally(() => {
        this.isSaving = false;
      });
    }
  }

  public get showStructureSection(): boolean {
    const folders = this.template?.folders.length > 0;
    const channels = this.template?.channels?.length > 0 &&
      this.template?.channels?.some((channel) => channel.name !== 'General' && channel.name !== 'Général');
    return this.template?.type === GroupTypeEnum.O365OFFICETEAMS ? channels || folders : folders;
  }

  public handleCertifyLoaded() {
    this.certifyIsLoaded = true;
  }


  public updateForm(form: UntypedFormGroup, event: any): void {
    switch (event?.index) {
      case SectionIndexes.EXPLANATION:
        if (this.explanationSection) {
          this.storageService.setLocal(
            'skipApplyExplanation',
            JSON.stringify({
              setMoment: Date.now(),
              value: form.value.skipApplyExplanation,
            })
          );
          break;
        }
      case SectionIndexes.MODEL:
        if (this.modelSection) {
          this.template = form?.value?.securityModel;
          this.requestForm.patchValue({ request: this.requestForm.value.request, requestInformation: { templateId: form.value.securityModel.id, ...this.requestForm.value.requestInformation } });
          break;
        }
      case SectionIndexes.STRUCTURE:
        if (this.structureSection) {
          this.requestForm.patchValue({
            request: this.requestForm.value.request,
            requestInformation: this.requestForm.value.requestInformation,
            keepStructure: form.value.keepStructure,
          });
          break;
        }
      case SectionIndexes.ACCESS:
        if (this.certifySection) {
          this.certifyIsLoaded = false;
          this.requestForm.patchValue({
            request: this.requestForm.value.request,
            requestInformation: this.requestForm.value.requestInformation,
            keepStructure: this.requestForm.value.keepStructure,
            revokeUsers: this.certifySection?.cloneUsers,
          });
          break;
        }
    }
  }

  private tempValidation(group: IOwnerGroup): IOwnerGroup {
    const tempGroup = { ...group };
    if (group.isCertifyExpired) {
      tempGroup.certificationInformation = { lastValidation: Date.now(), lastValidatorName: this.currentUser.name };
      tempGroup.isCertifyExpired = false;
    }
    tempGroup.isCertifyExpiring = false;

    const startingDate = this.template.lifecycle.expirationTrigger === GroupTemplateLifecycleEnum.INACTIVITY
      ? group.lastActivityDate || group.createdAt
      : group.createdAt;

    tempGroup.archiveDate = startingDate + +this.template.lifecycle.expirationDuration * TimeUtil.dayDuration;
    tempGroup.securityModel.name = this.template.name;
    tempGroup.securityModel.expirationDuration = this.template.lifecycle.expirationDuration;
    tempGroup.securityModel.expirationTrigger = this.template.lifecycle.expirationTrigger;
    tempGroup.securityModel.isExpiring = false;

    return tempGroup;
  }
}
