import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { SharesApiService } from 'projects/@common/services/api/sg/shares/shares-api.service';
import { IFileActivity } from 'projects/@common/services/api/sg/shares/shares.definitions';
import { MobileService } from 'projects/@common/services/mobile.service';

@Component({
  selector: 'shared-file-activities',
  templateUrl: './shared-file-activities.component.html',
  host: {
    class: 'shared-file-activities',
  },
  encapsulation: ViewEncapsulation.None,
})
export class SharedFileActivities implements OnInit {
  @Input() isFromConsoleOrg: boolean = false;
  @Input() fileId: string;
  public activities: IFileActivity[] = [];
  public shownActivities: IFileActivity[] = [];
  public isLoading = true;
  private readonly PAGE_SIZE = 50;
  private from = 0;

  constructor(public readonly mobileService: MobileService, private readonly sharesService: SharesApiService) {
  }

  public ngOnInit() {
    this.loadSharedFileActivities();
  }

  public loadMoreActivities() {
    if (!this.isLoading && this.shownActivities.length < this.activities.length) {
      this.shownActivities = this.shownActivities.concat(this.activities.slice(this.from, Math.min(this.from + 50, this.activities.length)));
    }
  }

  private loadSharedFileActivities() {
    this.isLoading = true;

    const call = this.isFromConsoleOrg ? this.sharesService .getSharedFileActivitiesOrg(this.fileId, { }) : this.sharesService .getSharedFileActivities(this.fileId, { });

    call.then((activities) => {
      this.activities = activities;
      this.shownActivities = this.shownActivities.concat(activities.slice(0, Math.min(50, this.activities.length)));
      this.from += 50;
    })
      .finally(() => {
        this.isLoading = false;
      });
  }
}
