import { Injectable } from '@angular/core';
import { StorageService } from '../../../storage.service';
import { TemporaryDataAbstract } from './temporary-data.abstract';

interface IRevokedResources {
  jobGuid: string;
  siteIds: string[];
  listIds: string[];
  date?: number;
};

@Injectable({
  providedIn: 'root',
})
export class ResourcesTemporaryDataService extends TemporaryDataAbstract {
  protected storageKey = 'revokedResources';

  constructor(protected storageService: StorageService) {
    super(storageService);
  }

  public get revokedJobGuids(): string[] {
    return this.getFilteredValue(this.storageKey).map((data) => data.jobGuid);
  }

  public addRevokeResources(data: IRevokedResources): void {
    const ttlRevokedShares: IRevokedResources = { ...data, date: Date.now() };
    this.add(ttlRevokedShares, 'jobGuid');
  }

  public removeRevokedResources(jobGuids: string[]): void {
    this.remove(jobGuids, 'jobGuid');
  }

  public getPending(pendingJobGuids: string[]): { siteIds: string[], listIds: string[] } {
    const current: IRevokedResources[] = this.getFilteredValue(this.storageKey);
    const pending = current.filter((data) => pendingJobGuids.includes(data.jobGuid));
    const siteIds = [ ...new Set(pending.map((data) => data.siteIds).flat(1)) ];
    const listIds = [ ...new Set(pending.map((data) => data.listIds).flat(1)) ];
    return { siteIds, listIds };
  }
}
