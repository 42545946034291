import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { I18nService } from '@common/modules/i18n/i18n.service';
import { MobileService } from '@common/services/mobile.service';
import { IBreadcrumbs } from 'projects/@assist-ops/components/breadcrumbs/breadcrumbs.component';
import { SensitiveFilesService } from '../sensitive-files-service';

@Component({
  selector: 'sensitive-files-mobile-list',
  templateUrl: './sensitive-files-mobile-list.component.html',
  styleUrls: [ './sensitive-files-mobile-list.component.scss' ],
})
export class SensitiveFilesMobileListComponent {
  public breadcrumbs: IBreadcrumbs[] = [
    { nodeName: 'breadcrumb.dashboard', nodeLink: '/dashboard' },
    { nodeName: 'breadcrumb.teams.files', nodeLink: null },
  ];

  constructor(
    public dialog: MatDialog,
    public i18n: I18nService,
    public mobileService: MobileService,
    public filesListService: SensitiveFilesService
  ) {
  }
}
