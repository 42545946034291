import { Component, ElementRef, HostListener, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { UsersApiService } from 'projects/@common/services/api/sg/user/userApi.service';
import { MobileService } from 'projects/@common/services/mobile.service';
import { DOCUMENTATION_SECTION, generateDocumentationLink } from 'projects/@common/utils/eco.documentation-utils';
import { QuickActionsSelectorComponent } from './components/quick-actions-selector/quick-actions-selector.component';

@Component({
  selector: 'sg-quick-actions',
  templateUrl: './quick-actions.component.html',
  styleUrls: [ './quick-actions.component.scss' ],
})
export class QuickActionsComponent implements OnInit {
  public documentationSection = generateDocumentationLink(DOCUMENTATION_SECTION.sg.root, this.i18n.currentLocale);

  public hasArchiver = false;

  @ViewChild('quickActionsSelector') quickActionsSelector: QuickActionsSelectorComponent;

  @Input() public groupAmount: { total: number, atRisk: number; } = { total: null, atRisk: null };

  constructor(
    private eRef: ElementRef,
    public mobileService: MobileService,
    private i18n: I18nService,
    private usersApi: UsersApiService
  ) {
  }

  public get desktopDisplay(): boolean {
    return !this.mobileService.isMobile();
  }

  ngOnInit(): void {
    this.getOrganizationArchiver();
  }

  ngOnChanges(simpleChange: SimpleChanges) {
    this.groupAmount = simpleChange.groupAmount.currentValue;
  }

  @HostListener('document:click', [ '$event' ])
  clickOut(event: any) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      if (this.quickActionsSelector) {
        this.quickActionsSelector.isOpen = true;
        this.quickActionsSelector?.toggle();
      }
    }
  }

  private getOrganizationArchiver(): void {
    this.usersApi.getArchiver().then((result) => {
      this.hasArchiver = result;
    });
  }
}
