import { NgModule } from '@angular/core';
import { CompleteDTPipe } from './completeDT.pipe';
import { DateAgoPipe } from './dateAgo.pipe';
import { FileSizePipe } from './fileSize.pipe';
import { HtmlSanitizer } from './htmlSanitizer.pipe';
import { IndexSuffixPipe } from './indexSuffix.pipe';
import { MarkdownPipe } from './markdown.pipe';
import { ParametersPipe } from './parameters.pipe';
import { UserListToFullnamePipe } from './userListToFullname.pipe';

const pipes = [
  FileSizePipe,
  DateAgoPipe,
  CompleteDTPipe,
  UserListToFullnamePipe,
  ParametersPipe,
  IndexSuffixPipe,
  MarkdownPipe,
  HtmlSanitizer,
];

@NgModule({
  imports: [],
  providers: pipes,
  declarations: pipes,
  exports: pipes,
})
export class PipesModule { }
