import { Injectable, Renderer2, RendererFactory2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ModalService } from '@ui-kit/services/modal.service';
import { ExternalLinkDialog } from '@common/components/external-links-dialog/external-link-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class ExternalLinkService {
  private renderer: Renderer2;

  private clickListener: () => void;

  constructor(
    private modalService: ModalService,
    private rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
    this.addClickListener();
  }

  private addClickListener(): void {
    this.clickListener = this.renderer.listen(this.document, 'click', (event) => this.handleClick(event));
  }

  private handleClick(event: MouseEvent): void {
    const target = event.target as HTMLElement;

    if (target && target.tagName === 'A') {
      const href = (target as HTMLAnchorElement).href;

      if (this.isExternalLink(href)) {
        event.preventDefault();
        this.modalService.openDialog(ExternalLinkDialog, {
          url: href,
          callback: (closeValue: { value: boolean }) => {
            if (closeValue.value) {
              window.open(href, '_blank');
            }
          },
        });
      }
    }
  }

  private isExternalLink(url: string): boolean {
    return url.length !== 0 && !url.startsWith(window.location.origin);
  }

  ngOnDestroy(): void {
    if (this.clickListener) {
      this.clickListener();
    }
  }
}
