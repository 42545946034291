<sg-page-layout
  [title]="'eco-profile.page.title' | t"
  [breadcrumbs]="breadcrumbs"
  [showRightSide]="false"
  [documentationLink]="documentationSection"
>
  <div class="main-layout">
    <ui-tabs [color]="uiTabsColor.lightTabs" [borderBelowHeader]="true" [hasNoLeftPadding]="true">
      <ui-tab [tabTitle]="'eco-profile.page.tab.user-profile' | t">
        <sg-user-profile *ngIf="userProfileData; else spinner" [userProfile]="userProfileData"></sg-user-profile>
      </ui-tab>
      <ui-tab [tabTitle]="'eco-profile.page.tab.team-profile' | t" *ngIf="displayReportingProfile">
        <sg-team-profile
          [userProfile]="userProfileReporting"
          (refreshEmitter)="handleReportingRefresh($event)"
        ></sg-team-profile>
      </ui-tab>
    </ui-tabs>
  </div>
</sg-page-layout>

<ng-template #spinner>
  <ui-spinner class="profile-spinner" size="medium" modifier="secondary"></ui-spinner>
</ng-template>
