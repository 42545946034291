import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { SensitiveFilesApiService } from '@common/services/api/sg/files/sensitive-files-api.service';
import { DisplayRequirements, DisplayService } from 'projects/@common/modules/display/display.service';
import { FeatureFlagsEnum } from 'projects/@common/modules/layout/definitions/base-sidebar.service';
import { DevicesApiService } from 'projects/@common/services/api/sg/devices/devices-api.service';
import { ResourceSharesApiService } from 'projects/@common/services/api/sg/resourceSharedFiles/resource-shares-api.service';
import { PauseCard, PauseCardStateEnum, PauseCardTypeEnum } from './components/pause-card/pause-card.component';

@Component({
  selector: 'sg-pause-eco',
  templateUrl: './pause-eco.component.html',
  styleUrls: ['./pause-eco.component.scss'],
})
export class PauseEcoComponent implements OnInit, OnChanges {
  public cards: PauseCard[] = [
    new PauseCard(PauseCardTypeEnum.share, 'dashboard.md-eco.pause.card.share.title', 'dashboard.md-eco.pause.card.share.description', PauseCardStateEnum.ok),
    new PauseCard(PauseCardTypeEnum.file, 'dashboard.md-eco.pause.card.file.title', 'dashboard.md-eco.pause.card.file.description', PauseCardStateEnum.ok, this.displayService.meetsRequirements({ flags: [FeatureFlagsEnum.DLP_MANAGEMENT] })),
    new PauseCard(PauseCardTypeEnum.ressource, 'dashboard.md-eco.pause.card.ressource.title', 'dashboard.md-eco.pause.card.ressource.description', PauseCardStateEnum.ok),
    new PauseCard(PauseCardTypeEnum.device, 'dashboard.md-eco.pause.card.device.title', 'dashboard.md-eco.pause.card.device.description', PauseCardStateEnum.ok),
  ];

  public hasHighRisksResources: { riskUserSharesHIGH?: boolean, riskUserResources?: boolean; } = {};
  @Input() public groupAmount: { total: number, atRisk: number, atRiskGroupIds: string[]; } = {
    total: null,
    atRisk: null,
    atRiskGroupIds: [],
  };

  constructor(
    private resourcesApi: ResourceSharesApiService,
    private devicesApiService: DevicesApiService,
    private router: Router,
    private displayService: DisplayService,
    private sensitiveFilesApiService: SensitiveFilesApiService
  ) {
  }

  ngOnInit(): void {
    this.loadDevices();
    this.cards[0].toggleLoadingIcon();
    this.cards[1].toggleLoadingIcon();
    this.cards[3].toggleLoadingIcon();
    this.loadSensitiveFiles();
    this.loadResources();
    this.loadDevices();
  }

  ngOnChanges(simpleChange: SimpleChanges) {
    if (simpleChange.groupAmount.currentValue.atRiskGroupIds.length > 0) {
      this.cards[2].setStateToWarning();
    }
    this.cards[2].toggleLoadingIcon();
  }

  public isCardDisabled(requirements: DisplayRequirements): boolean {
    return !this.displayService.meetsRequirements(requirements);
  }

  public get availableCards(): PauseCard[] {
    return this.cards.filter((card) => card.isAvailable);
  }

  public loadDevices(): void {
    this.devicesApiService.getUserDevices()
      .then((devices) => {
        if (devices.length > 0 && !devices.every((device) => device.isCompliant)) {
          this.cards[3].setStateToWarning();
        }
        this.cards[3].isLoadingIcon = false;
      });
  }

  public loadSensitiveFiles(): void {
    this.sensitiveFilesApiService.listSensitiveFiles({ skip: 0, size: 0 })
      .then((results) => {
        if (results.total > 0) {
          this.cards[1].setStateToWarning();
        }
        this.cards[1].isLoadingIcon = false;
      });
  }

  public loadResources(): void {
    this.resourcesApi.getUserRiskResources()
      .then((response) => {
        this.hasHighRisksResources = response;
        if (response.riskUserResources || response.riskUserSharesHIGH) {
          this.cards[0].setStateToWarning();
        }
        this.cards[0].toggleLoadingIcon();
      })
      .catch((error) => {
      });
  }


  public handleClickEvent(type: PauseCardTypeEnum): void {
    switch (type) {
      case PauseCardTypeEnum.share:
        this.router.navigateByUrl(`myshares?hasHighRisksResources=${JSON.stringify(this.hasHighRisksResources)}`);
        break;
      case PauseCardTypeEnum.ressource:
        this.router.navigateByUrl(`teamsResources`);
        break;
      case PauseCardTypeEnum.device:
        this.router.navigateByUrl(`devices`);
        break;
      case PauseCardTypeEnum.file:
        this.router.navigateByUrl(`files`);
        break;
    }
  }
}
