import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { generateDocumentationLink, DOCUMENTATION_SECTION } from "@common/utils/eco.documentation-utils";
import { Actions, Store } from "@ngxs/store";
import { UiIndicatorArrow } from "@ui-kit/components/ui-indicator-arrow/ui-indicator-arrow.component";
import { MultiSelectData } from "@ui-kit/components/ui-multi-select/multi-select-data";
import { UiStaticTableRowType } from "@ui-kit/components/ui-static-table/ui-static-table-row/ui-static-table-row.component";
import { TableFilterData } from "@ui-kit/components/ui-table-tools/ui-table-filter-tool/ui-table-filter-data";
import { UiTableDirection } from "@ui-kit/components/ui-table/ui-table.component";
import { MultiSelectDataFactory } from "projects/@common/modules/i18n/component-wrapper/multi-select-data.factory";
import { StaticTableDataMapper } from "projects/@common/modules/i18n/component-wrapper/static-table-data-mapper.service";
import { TableFilterToolDataFactory } from "projects/@common/modules/i18n/component-wrapper/table-filter-tool-data.factory";
import { I18nService } from "projects/@common/modules/i18n/i18n.service";
import { SharesApiService } from "projects/@common/services/api/sg/shares/shares-api.service";
import { SharedFilesResourceTypeEnum } from "projects/@common/services/api/sg/shares/shares.definitions";
import { MobileService } from "projects/@common/services/mobile.service";
import { ShareRiskScore } from "../../components/share-risk-score/share-risk-score.component";

export enum CustomSourceEnum {
  ALL = 'ALL',
  ONEDRIVE = 'ONEDRIVE',
  LIBRARY = 'LIBRARY',
  PRIVATE_CHANNEL = 'PRIVATE_CHANNEL'
}

export enum FileRiskEnum {
  HIGH = 'HIGH',
  MODERATE = 'MODERATE',
  LOW = 'LOW'
}

@Component({
  selector: 'org-users-shares',
  templateUrl: './users-shares.container.html',
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'shares',
  },
})
export class UsersSharesContainer implements OnInit {
  public documentationSection = generateDocumentationLink(DOCUMENTATION_SECTION.sg.admin.userShares, this.i18n.currentLocale);

  private readonly USERS_PAGE_SIZE = 50;

  private latestCallId = 0;

  public isLoading = true;

  public fulltext = '';

  public total = 0;

  public nextToken = 0;

  public sortParent = null;

  public sortField = null;

  public sort = null;

  public direction = UiTableDirection.Asc;

  public shareSourcesFilter = CustomSourceEnum;

  public shareRisksFilter = FileRiskEnum;

  public shareSourcesFilterValue = null;

  public resourceSource = 'total';

  public selectedShareRisksFilters = [];

  public shareSourcesFilterDisplay = CustomSourceEnum.ALL;

  public defaultRiskFilter = this.route.snapshot.queryParams.riskFilter;

  public dataSource: {
    userName: string;
    files: { fileShared: number; file7: number; file52: string; };
    folders: { folderShared: number; folder7: number; folder52: string; };
    guid: string;
  }[] = [];

  public sourcesSelectData: TableFilterData;

  public risksMultiSelectData: MultiSelectData;

  public columnsDef: any[] = [
    {
      label: '',
      field: 'user',
      type: UiStaticTableRowType.AVATARS,
      withUser: true,
      rowspan: 2,
      width: '60px',
      noSorting: true,
      isResizable: false,
    },
    {
      label: 'shares.list.columns.name.name',
      field: 'userName',
      type: UiStaticTableRowType.TEXT,
      rowspan: 2,
      isResizable: false,
    },
    {
      label: 'shares.list.columns.risk.name',
      field: 'risk',
      type: UiStaticTableRowType.DYNAMIC_COMPONENT_HOST,
      component: ShareRiskScore,
      paramName: 'resource',
      rowspan: 2,
      width: '100px',
      isResizable: false,
    },
    {
      label: 'shares.list.columns.files.name',
      field: 'subHeaderFiles',
      type: UiStaticTableRowType.SUB_HEADER,
      colspan: 3,
      noSorting: true,
      firstColumnOfHeaderGroup: true,
      lastColumnOfHeaderGroup: true,
      isResizable: false,
    },
    {
      label: 'shares.list.columns.shares.name',
      field: 'fileShared',
      fields: [ 'files', 'fileShared' ],
      type: UiStaticTableRowType.DEEP_1,
      tooltip: this.i18n.translate('shares.list.columns.shares.files.fileShared.tooltip'),
      isSmall: true,
      firstColumnOfHeaderGroup: true,
      width: '100px',
      isResizable: false,
      isContentCentered: true,
    },
    {
      label: 'shares.list.columns.shares.delta7',
      field: 'file7',
      fields: [ 'files', 'file7' ],
      type: UiStaticTableRowType.DYNAMIC_COMPONENT_DEEP_1,
      component: UiIndicatorArrow,
      tooltip: this.i18n.translate('shares.list.columns.shares.files.delta7.tooltip'),
      isSmall: true,
      paramName: 'value',
      secondParamName: 'includePlusSign',
      secondParamValue: false,
      width: '115px',
      isResizable: false,
      isContentCentered: true,
    },
    {
      label: 'shares.list.columns.shares.delta52',
      field: 'file52',
      fields: [ 'files', 'file52' ],
      type: UiStaticTableRowType.DEEP_1,
      tooltip: this.i18n.translate('shares.list.columns.shares.files.delta52.tooltip'),
      isSmall: true,
      secondParamValue: false,
      width: '115px',
      isResizable: false,
      isContentCentered: true,
    },
    {
      label: 'shares.list.columns.folders.name',
      field: 'subHeaderFolders',
      type: UiStaticTableRowType.SUB_HEADER,
      colspan: 3,
      noSorting: true,
      firstColumnOfHeaderGroup: true,
      lastColumnOfHeaderGroup: true,
      isResizable: false,
    },
    {
      label: 'shares.list.columns.shares.name',
      field: 'folderShared',
      fields: [ 'folders', 'folderShared' ],
      type: UiStaticTableRowType.DEEP_1,
      tooltip: this.i18n.translate('shares.list.columns.shares.folders.folderShared.tooltip'),
      isSmall: true,
      firstColumnOfHeaderGroup: true,
      width: '100px',
      isResizable: false,
      isContentCentered: true,
    },
    {
      label: 'shares.list.columns.shares.delta7',
      field: 'folder7',
      fields: [ 'folders', 'folder7' ],
      type: UiStaticTableRowType.DYNAMIC_COMPONENT_DEEP_1,
      component: UiIndicatorArrow,
      tooltip: this.i18n.translate('shares.list.columns.shares.folders.delta7.tooltip'),
      isSmall: true,
      paramName: 'value',
      secondParamName: 'includePlusSign',
      secondParamValue: false,
      width: '115px',
      isResizable: false,
      isContentCentered: true,
    },
    {
      label: 'shares.list.columns.shares.delta52',
      field: 'folder52',
      fields: [ 'folders', 'folder52' ],
      type: UiStaticTableRowType.DEEP_1,
      tooltip: this.i18n.translate('shares.list.columns.shares.folders.delta52.tooltip'),
      isSmall: true,
      lastColumnOfHeaderGroup: true,
      width: '115px',
      isResizable: false,
      isContentCentered: true,
    },
  ];

  constructor (
    protected readonly store: Store,
    protected readonly actions$: Actions,
    private router: Router,
    private route: ActivatedRoute,
    public mobileService: MobileService,
    public readonly i18n: I18nService,
    private sharesApiService: SharesApiService,
    private staticTableDataMapper: StaticTableDataMapper,
    private tableFilterToolDataFactory: TableFilterToolDataFactory,
    private multiSelectDataMapper: MultiSelectDataFactory
  ) { }

  public ngOnInit() {
    if (this.defaultRiskFilter) {
      this.handleRiskFilter([ this.defaultRiskFilter ]);
    } else {
      this.loadUsers();
    }
    this.sourcesSelectData = this.tableFilterToolDataFactory.create(this.shareSourcesFilter, 'file.source.', this.shareSourcesFilterDisplay);
    this.risksMultiSelectData = this.multiSelectDataMapper.create(this.shareRisksFilter, this.defaultRiskFilter ? [ this.defaultRiskFilter ] : [], 'file.risk.');
    [ this.columnsDef, this.dataSource ] = this.staticTableDataMapper.translate(this.columnsDef, this.dataSource);
  }

  private loadUsers() {
    this.isLoading = true;

    const currentCallId = this.latestCallId + 1;
    this.latestCallId = currentCallId;

    this.sharesApiService
      .getUsersWithSharesOrg({
        from: this.nextToken,
        size: this.USERS_PAGE_SIZE,
        sort: this.sort,
        direction: this.direction,
        resourcesFilter: this.shareSourcesFilterValue,
        risks: this.selectedShareRisksFilters,
        fulltext: this.fulltext,
      })
      .then((users) => {
        if (this.latestCallId === currentCallId) {
          const usersWithStats = users.items.map((user) => this.mapUserToRow(user));
          this.dataSource = this.dataSource.concat(usersWithStats);
          this.total = users.total;
          this.nextToken = +users.nextToken;
        }
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  public handleRefresh() {
    this.isLoading = true;
    this.dataSource = [];
    this.nextToken = 0;
    this.loadUsers();
  }

  public handleSearchClear() {
    this.fulltext = '';
    this.handleRefresh();
  }

  public handleSearchQuery(fulltext: string) {
    this.fulltext = fulltext;
    this.handleRefresh();
  }

  public handleSort(sortParams: any) {
    if (sortParams.sortParent === 'files') {
      if (sortParams.sortColumn === 'fileShared') {
        this.sort = 'sharedFiles.number';
      } else if (sortParams.sortColumn === 'file7') {
        this.sort = 'sharedFiles.var7DayAvg';
      } else if (sortParams.sortColumn === 'file52') {
        this.sort = 'sharedFiles.max52weeks';
      }
    } else if (sortParams.sortParent === 'folders') {
      if (sortParams.sortColumn === 'folderShared') {
        this.sort = 'sharedFolders.number';
      } else if (sortParams.sortColumn === 'folder7') {
        this.sort = 'sharedFolders.var7DayAvg';
      } else if (sortParams.sortColumn === 'folder52') {
        this.sort = 'sharedFolders.max52weeks';
      }
    } else if (sortParams.sortColumn === 'risk') {
      this.sort = 'userRiskScore';
    } else if (sortParams.sortColumn === 'userName') {
      this.sort = 'userName';
    }
    this.sortField = sortParams.sortColumn;
    this.direction = sortParams.sortDirection;
    this.sortParent = sortParams.sortParent;
    this.handleRefresh();
  }

  public async handleSourceFilter(value: any): Promise<void> {
    if (value === CustomSourceEnum.ONEDRIVE) {
      this.resourceSource = 'drives';
      this.shareSourcesFilterValue = SharedFilesResourceTypeEnum.ONEDRIVE;
    } else if (value === CustomSourceEnum.LIBRARY) {
      this.resourceSource = 'libraries';
      this.shareSourcesFilterValue = SharedFilesResourceTypeEnum.LIBRARY;
    } else if (value === CustomSourceEnum.PRIVATE_CHANNEL) {
      this.resourceSource = 'privateChannels';
      this.shareSourcesFilterValue = SharedFilesResourceTypeEnum.PRIVATE_CHANNEL;
    } else {
      this.resourceSource = 'total';
      this.shareSourcesFilterValue = null;
    }
    await this.handleRefresh();
  }

  public async handleRiskFilter(filters: any[]): Promise<void> {
    this.selectedShareRisksFilters = filters;
    await this.handleRefresh();
  }

  public loadMore() {
    if (this.dataSource.length < this.total && !this.isLoading) {
      this.loadUsers();
    }
  }

  public handleRowClick(item) {
    this.router.navigate([ `/shares/user` ], {
      queryParams: {
        userGuid: item.guid,
        name: item.userName,
        isOrgAdmin: true,
      },
    });
  }

  private mapUserToRow(user: any): any {
    return {
      userName: user.userName,
      files: {
        fileShared: user.sharedFiles?.number[this.resourceSource] || 0,
        file7: user.sharedFiles?.var7DayAvg[this.resourceSource] || 0,
        file52: `${(user.sharedFiles?.min52weeks[this.resourceSource] || 0).toString()}-${(
          user.sharedFiles?.max52weeks[this.resourceSource] || 0
        ).toString()}`,
      },
      folders: {
        folderShared: user.sharedFolders?.number[this.resourceSource] || 0,
        folder7: user.sharedFolders?.var7DayAvg[this.resourceSource] || 0,
        folder52: `${(user.sharedFolders?.min52weeks[this.resourceSource] || 0).toString()}-${(
          user.sharedFolders?.max52weeks[this.resourceSource] || 0
        ).toString()}`,
      },
      user: [ { o365UserId: user.resourceId } ],
      guid: user.resourceId,
      userRiskScore: user.userRiskScore.total,
      risk: user.risk,
    };
  }
}
