import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { BaseDialogComponent } from 'projects/@assist-ops/components/base-dialog/base-dialog.component';


@Component({
  selector: 'models-help-dialog',
  templateUrl: './models-help-dialog.component.html',
  styleUrls: [ './models-help-dialog.component.scss' ],
})
export class ModelsHelpDialogComponent {
  constructor (public dialogRef: MatDialogRef<BaseDialogComponent>) {}
}
