import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { MobileService } from '@common/services/mobile.service';
import { LanguageEnum } from '@ui-kit/interfaces/ILanguage';


@Component({
  selector: 'admin-top10-row',
  templateUrl: './admin-top10-row.component.html',
  styleUrls: [ './admin-top10-row.component.scss' ],
  encapsulation: ViewEncapsulation.None,
})
export class AdminTop10RowComponent {
  @Input() public resource;

  public locale: LanguageEnum;

  constructor(public mobileService: MobileService){}
}
