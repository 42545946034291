import { Injectable } from "@angular/core";
import { GetDashboardProfileResponse, GetOrganizationProfileResponse } from "./api/sg/md.eco-profile/md.eco-profile.definition";
import { GetDashboardReportingProfileResponse, MdEcoApiService } from "./api/sg/md.eco-profile/md.eco-profile-api";
import { Subject } from "rxjs/internal/Subject";


@Injectable({
  providedIn: 'root',
})
export class DashboardProfileService {
  public dashboardUserProfile = new Subject<GetDashboardProfileResponse>();
  public dashboardReporingProfile = new Subject<GetDashboardReportingProfileResponse>();

  constructor(private mdEcoApiService: MdEcoApiService) { }

  public async getDashboardUserProfile(): Promise<void> {
    const profile = await this.mdEcoApiService.getDashboardUserProfile();
    this.dashboardUserProfile.next(profile);
  }

  public async getDashboardReportingProfile(userIds?: string[], isFirstLoad = true): Promise<void> {
    const profile = await this.mdEcoApiService.getDashboardReportingProfile(userIds, isFirstLoad);
    this.dashboardReporingProfile.next(profile);
  }
}
