<ng-container *ngIf="isLoading">
  <ui-spinner size="medium"></ui-spinner>
</ng-container>

<ng-container *ngIf="!isLoading">
  <mat-radio-group [(ngModel)]="selectedTemplate">
    <mat-radio-button
      *ngFor="let template of templates; let index = index"
      (click)="selectTemplate(template)"
      [value]="template"
    >
      <div
        class="template-card"
        [ngClass]="{ 'selected-template': template[TEMPLATE_SELECTION_PARAM] }"
      >
        <span class="title">
          <img src="assets/favicons/icon_paper_cropped.svg">
          {{ template.name }}
        </span>
        <div class="description">
          <p>{{template.description}}</p>
          <p
            (click)="expandDescription(template)"
            class="detail-button"
          >{{template.descriptionExpanded ? '-' : '+'}} {{ 'groups.dialog.model.card.details' | t }}</p>
        </div>
        <div
          *ngIf="template.descriptionExpanded"
          class="detail-section"
        >
          <sg-model-information
            [template]="template"
            [showChannels]="showChannels"
          ></sg-model-information>
        </div>
      </div>
    </mat-radio-button>
  </mat-radio-group>
</ng-container>

<ng-template
  #folders
  let-template="template"
>
  <div class="collapsable-content ">
    <div
      class="folders "
      *ngIf="template.folders.length > 0"
    >
      <p
        class="independant-folders"
        *ngIf="template.type === 'o365OfficeTeams'"
      >{{'groups.dialog.model.card.folders.title' | t }}</p>
      <ng-container *ngTemplateOutlet="recursiveFolders;context: { folders: template.folders , count: 0}">
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template
  #channels
  let-template="template"
>
  <div class="collapsable-content ">
    <div class="folders">
      <ng-container *ngFor="let channel of template.channels; let index = index ">
        <span class="text-image channel-name">
          <div class="thing">
            <p class="special-width">{{index + 1}}. </p>
            <p>{{channel.name}}</p>
          </div>
          <img
            *ngIf="channel.isPrivate"
            [tooltip]="'groups.dialog.model.card.channels.private.tooltip' | t "
            src="assets/favicons/icon_small_lock.svg"
          >
        </span>

        <div *ngIf="channel.folders?.length > 0">
          <ng-container *ngTemplateOutlet="recursiveFolders;context: { folders: channel.folders , count : 0}">
          </ng-container>
        </div>
      </ng-container>
    </div>
  </div>
  <ng-container *ngTemplateOutlet="folders; context: { template: template }">
  </ng-container>
</ng-template>

<ng-template
  #permissions
  let-template="template"
>
  <div class="collapsable-content">
    <ng-container *ngFor="let permission of template.permissions | keyvalue">
      <div
        *ngIf="shouldShowSubpermissions(permission.value)"
        class="permissions"
      >
        <p>{{('groupTemplates.permissions.' + permission.key + '.title') | t }}</p>
        <ng-container *ngFor="let subpermission of permission.value | keyvalue">
          <span
            class="subpermission"
            *ngIf="subpermission.value"
          >
            <img src="assets/favicons/icon_small_check.svg">
            <p>{{('groupTemplates.permissions.' + permission.key + '.' + subpermission.key) | t }}</p>
          </span>
        </ng-container>
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-template
  #recursiveFolders
  let-folders="folders"
  let-count="count"
>
  <ng-container *ngFor="let folder of folders">
    <span
      class="text-image folder-element"
      [ngStyle]="{'padding-left' : (20 + count * 12) + 'px'}"
    >
      <img src="assets/favicons/icon_folder_pale_grey.svg">
      <p>{{folder.name}}</p>
    </span>
    <div *ngIf="folder?.subFolders?.length > 0 && count < 8">
      <ng-container
        *ngTemplateOutlet="recursiveFolders; context: {folders: folder.subFolders, count: count + 1}"></ng-container>
    </div>
  </ng-container>
</ng-template>