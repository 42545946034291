import { Pipe, PipeTransform } from '@angular/core';
import { LanguageEnum } from '@ui-kit/interfaces/ILanguage';
import { I18nService } from '../i18n/i18n.service';

@Pipe({
  name: 'indexSuffix',
  pure: true,
})
export class IndexSuffixPipe implements PipeTransform {
  constructor(private i18nService: I18nService) {}

  public transform(value: any): any {
    let suffix;
    if (this.i18nService.currentLocale === LanguageEnum.FRENCH) {
      switch (+value) {
        case 1:
        case 21:
          suffix = 'er';
          break;
        default:
          suffix = 'e';
          break;
      }
    } else {
      switch (+value) {
        case 1:
        case 21:
          suffix = 'st';
          break;
        case 2:
          suffix = 'nd';
          break;
        case 3:
          suffix = 'rd';
          break;
        default:
          suffix = 'th';
          break;
      }
    }
    return value + suffix;
  }
}
