import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngxs/store';
import { HideDrawer } from 'projects/@common/modules/layout/components/drawer/stores/drawer.store';
import { CookDeliciousDrawerSnack } from 'projects/@common/modules/notice/implementations/snackbar/state/snackbar-drawer.state';
import { RequestsApiService } from 'projects/@common/services/api/requests/requests-api.service';
import { GetRequestResponse } from 'projects/@common/services/api/requests/requests.definitions';
import { RequestService } from '../../../services/request.service';

@Component({
  selector: 'request-form-process',
  templateUrl: './request-form-process.component.html',
  styleUrls: [ './request-form-process.component.scss' ],
})
export class RequestFormProcessComponent implements OnInit {
  @Input() public request: GetRequestResponse;

  @Input() public showDeclineButton = true;

  public requestApprobationForm: UntypedFormGroup;

  public isLoading = false;

  constructor(
    private readonly requestsApiService: RequestsApiService,
    private readonly requestService: RequestService,
    private store: Store
  ) {
  }

  ngOnInit(): void {
    this.createRequestApprobationForm();
  }

  public processApprobation(isAccepted: boolean) {
    this.isLoading = true;
    const note = this.requestApprobationForm.get('note').value;
    this.requestsApiService.updateDecision(this.request.approverId, this.request.id, isAccepted, note)
      .then((response) => {
        this.requestService.update.next();
        this.store.dispatch(new CookDeliciousDrawerSnack('request.batch.success.message'));
        this.store.dispatch(new HideDrawer());
      })
      .finally(() => this.isLoading = false);
  }

  private createRequestApprobationForm() {
    this.requestApprobationForm = new UntypedFormGroup({
      note: new UntypedFormControl(null),
    });
  }
}
