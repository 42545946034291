import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { I18nService } from '@common/modules/i18n/i18n.service';
import { GroupsApiService } from '@common/services/api/sg/groups/groupsApi.service';

@Component({
  selector: 'resource-add-details',
  templateUrl: './resource-add-details.component.html',
  styleUrls: [ './resource-add-details.component.scss' ],
})
export class ResourceAddDetailsComponent implements OnInit {
  @Input() public resourceAddDetails: any;

  @Input() public canEditOwner = false;

  @Input() public isEditing = false;

  public detailsForm: UntypedFormGroup;

  public owners: any[];

  public isLoading = false;

  constructor(
    public readonly i18n: I18nService,
    private groupsApiService: GroupsApiService
  ) {
  }

  public ngOnInit(): void {
    this.isLoading = true;
    this.owners = this.resourceAddDetails.resourcesToAdd.owners;
    this.createGroupCreationDetailsForm();
    this.groupsApiService.getGroup(this.resourceAddDetails.resourcesToAdd.id)
      .then((response) => this.resourceAddDetails = { ...this.resourceAddDetails, ...response })
      .finally(() => this.isLoading = false);
  }

  private createGroupCreationDetailsForm() {
    this.detailsForm = new UntypedFormGroup({
      name: new UntypedFormControl(this.resourceAddDetails.name, Validators.required),
      description: new UntypedFormControl(this.resourceAddDetails.description, Validators.required),
      owners: new UntypedFormControl(this.resourceAddDetails.owners, Validators.required),
    });
  }
}
