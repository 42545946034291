import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';

import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BackgroundTasksApiService } from '@common/services/api/notifications/backgroundTasks-api.service';
import { SharesTemporaryDataService } from '@common/services/api/sg/temporary-data/shares-temporary-data.service';
import * as microsoftTeams from '@microsoft/teams-js';
import { Actions, Select, Store } from '@ngxs/store';
import { HideDialog } from '@ui-kit/components/ui-dialog/stores/ui-dialog.store';
import { DialogActions } from '@ui-kit/components/ui-dialog/ui-dialog.component';
import { MultiSelectData } from '@ui-kit/components/ui-multi-select/multi-select-data';
import { UiMultiSelect } from '@ui-kit/components/ui-multi-select/ui-multi-select.component';
import { UiStaticTableRowType } from '@ui-kit/components/ui-static-table/ui-static-table-row/ui-static-table-row.component';
import { UiTableDirection } from '@ui-kit/components/ui-table/ui-table.component';
import { DTPipe } from '@ui-kit/pipes/dt.pipe';
import { IconUtil } from '@ui-kit/util/icon-util';
import { ShareLastAccess } from 'projects/@assist-ops/components/share-last-access/share-last-access.component';
import { SharedFileDrawer } from 'projects/@assist-ops/containers/drawer/shared-file-drawer/shared-file-drawer.container';

import { SearchBarState } from 'projects/@assist-ops/store/search-bar.store';
import { EditWithModalComponent } from 'projects/@common/components/edit-with-modal/edit-with-modal.component';
import { Accumulatables, Accumulator, AccumulatorsState } from 'projects/@common/modules/accumulator/accumulator.store';
import { MultiSelectDataFactory } from 'projects/@common/modules/i18n/component-wrapper/multi-select-data.factory';
import { StaticTableDataMapper } from 'projects/@common/modules/i18n/component-wrapper/static-table-data-mapper.service';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { DrawerPageEnum } from 'projects/@common/modules/layout/components/drawer/drawerPage.enum';
import { DrawerService } from 'projects/@common/modules/layout/components/drawer/services/drawer.service';
import { Notice, NoticeLevels, NoticeService } from 'projects/@common/modules/notice/notice.service';
import { EcoSessionState } from 'projects/@common/modules/session/state/session.state';
import { ResourceSharesApiService } from 'projects/@common/services/api/sg/resourceSharedFiles/resource-shares-api.service';
import { SharesApiService } from 'projects/@common/services/api/sg/shares/shares-api.service';
import {
  FileRiskEnum,
  FileShareLinkPermissionsTypeEnum,
  FileShareTypeEnum,
  FileTypeEnum,
  ShareNumberEnum,
  UserSharedFile,
} from 'projects/@common/services/api/sg/shares/shares.definitions';
import { MobileService } from 'projects/@common/services/mobile.service';
import { TeamsAuthService } from 'projects/@common/services/teams-auth.service';
import { JsonUtils } from 'projects/@common/utils/json-utils';
import { Observable, Subscription } from 'rxjs';
import { RevokeSharesDialogComponent, RevokeTypeEnum } from '../revoke-shares-dialog/revoke-shares-dialog.component';
import { ShareRiskScore } from '../share-risk-score/share-risk-score.component';

// Used like this to removed the 'all' for display purposes.
export enum ShareVisibilitiesFilterEnum {
  PUBLIC = FileShareTypeEnum.PUBLIC,
  GUEST = FileShareTypeEnum.GUEST,
  COMPANY = FileShareTypeEnum.COMPANY,
  SECURITY_GROUP = FileShareTypeEnum.SECURITY_GROUP,
  MEMBER = FileShareTypeEnum.MEMBER,
}

interface IFilters {
  risksFilters: FileRiskEnum[];
  visibilitiesFilters: any[];
  permissionsFilters: FileShareLinkPermissionsTypeEnum[];
  itemsFilters: FileTypeEnum[];
}

@Component({
  selector: 'shared-files-table',
  templateUrl: './shared-files-table.component.html',
  styleUrls: ['./shared-files-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'shared-files',
  },
})
export class SharedFilesTableComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('editor') editWithModalComponent: EditWithModalComponent;
  @Output('checkboxEvent') checkboxEmitter = new EventEmitter();
  @Output() isSharedFilesLoading: EventEmitter<boolean> = new EventEmitter();
  @Output() sharesNumber: EventEmitter<{ quantity: number; isFiltered: boolean }> = new EventEmitter();
  @Output() refresh: EventEmitter<number> = new EventEmitter();
  @Input() isFromConsoleOrg = false;
  @Input() hasResourcesWithShares: boolean;
  @Input() requiredWidthForMobile: number;
  @Input() public disableExpressRevoke;
  @Select(SearchBarState.searchQuery) public searchQuery$: Observable<string>;
  @ViewChildren(UiMultiSelect) uiMultiSelectChildren!: QueryList<UiMultiSelect>;
  public showRevokeDialog = false;
  public revokeDialogMessage = '';
  public revokeDialogTitle = '';
  public fulltext = null;
  public isLoading = false;
  public from = 0;
  public sort = null;
  public direction: UiTableDirection = UiTableDirection.Desc;
  public total = 0;
  public name = this.route.snapshot.queryParams.name;
  public userGuid = this.route.snapshot.queryParams.userGuid;
  public libraryGuid = this.route.snapshot.queryParams.guid;
  public resourceType = this.route.snapshot.queryParams.type;
  public shares: UserSharedFile[] = [];
  public selectedSharedFiles: UserSharedFile[] = [];
  public sharesToRevoke: UserSharedFile[];
  public shareRisksFilter = FileRiskEnum;
  public shareVisibilitiesFilter = ShareVisibilitiesFilterEnum;
  public sharePermissionsFilter = FileShareLinkPermissionsTypeEnum;
  public shareItemsFilter = FileTypeEnum;
  public pendingJobGuids = [];
  public selectedFilters: IFilters = {
    risksFilters: [],
    visibilitiesFilters: [],
    permissionsFilters: [],
    itemsFilters: [],
  };
  public appliedFilters: IFilters = {
    risksFilters: [],
    visibilitiesFilters: [],
    permissionsFilters: [],
    itemsFilters: [],
  };
  public isMyShares: boolean;
  public activatedFilters = 0;
  public showBatchRevokeDialog = false;
  public isAutomaticRevokeActivated = false;
  public defaultRiskFilter = this.route.snapshot.queryParams.sharesRiskFilter;
  public riskMultiSelectData: MultiSelectData;
  public visibilitiesMultiSelectData: MultiSelectData;
  public permissionsMultiSelectData: MultiSelectData;
  public itemsMultiSelectData: MultiSelectData;
  public initialLoad = true;
  public filesColumnsDef: any[] = [
    {
      label: 'shares.list.columns.name.name',
      field: 'name',
      type: UiStaticTableRowType.ICON_WITH_LINK,
      getIcon: this.getfileIcon,
      width: '150px',
      isTruncated: true,
    },
    {
      label: 'shares.list.columns.risk.name',
      field: 'risk',
      type: UiStaticTableRowType.DYNAMIC_COMPONENT_HOST,
      component: ShareRiskScore,
      tooltip: this.i18nService.translate('factor.table.tooltip'),
      paramName: 'resource',
      width: '90px',
      minWidth: '90px',
      maxWidth: '90px',
    },
    {
      label: 'shares.list.columns.name.external',
      field: 'exposure',
      type: UiStaticTableRowType.ICON,
      getIcon: this.getExternalIcon,
      width: '10px',
      minWidth: '10px',
      noSorting: true,
      autoSizeIcon: true,
    },
    {
      label: 'shares.list.columns.name.lastAccessed',
      field: 'lastAccessed',
      type: UiStaticTableRowType.DYNAMIC_COMPONENT_HOST,
      paramName: 'share',
      component: ShareLastAccess,
      width: '130px',
    },
  ];
  @Select(AccumulatorsState.accumulators) private accumulators$: Observable<Accumulator[]>;
  private readonly SIZE = 40;
  private readonly SELECTED_TAG = '__Selected';
  private shareNumber = ShareNumberEnum.SINGLE;
  private querySubscription: Subscription;
  private reloadSubscription: Subscription;
  private latestCallId = 0;
  private currentAccumulatorCount = 0;
  private queryParams: any;
  private intervalOptions = {
    isIntervalSet: false,
    intervalId: null,
  };

  constructor(
    protected readonly store: Store,
    protected readonly actions$: Actions,
    public i18nService: I18nService,
    private route: ActivatedRoute,
    private router: Router,
    public mobileService: MobileService,
    private resourceSharedFiles: ResourceSharesApiService,
    private sharesApiService: SharesApiService,
    private cdr: ChangeDetectorRef,
    private drawerService: DrawerService,
    private multiSelectFactory: MultiSelectDataFactory,
    private staticTableDataMapper: StaticTableDataMapper,
    private noticeService: NoticeService,
    private eRef: ElementRef,
    public dialog: MatDialog,
    private temporaryDataService: SharesTemporaryDataService,
    private backgroundTasksApiService: BackgroundTasksApiService
  ) {}

  public ngOnInit() {
    this.isMyShares = this.router.url.includes('/myshares');

    if (this.isMyShares) {
      this.userGuid = this.store.selectSnapshot(EcoSessionState.userGuid);
    }

    if (!this.router.url.includes('resources') && !this.isMyShares) {
      this.name = this.route.snapshot.queryParams.name;
    }

    this.sharesApiService.getAutomaticRevokeActivated().then((automaticRevoke) => {
      this.setupColumnsDef(automaticRevoke.isActivated);
      this.isAutomaticRevokeActivated = automaticRevoke.isActivated;
    });

    if (this.defaultRiskFilter) {
      this.handleRisksFilter([this.defaultRiskFilter], true);
    }

    if (TeamsAuthService.isInTeams) {
      microsoftTeams.app.getContext().then((context) => {
        if (context.page.subPageId) {
          try {
            this.queryParams = JsonUtils.stringToJson(context.page.subPageId);
            if (this.queryParams?.sharesRiskFilter) {
              this.defaultRiskFilter = this.queryParams?.sharesRiskFilter;
              this.createMultiSelectData();
              this.handleRisksFilter([this.defaultRiskFilter], true);
            }
          } catch (error) {}
        }
        this.initSubscriptions();
        this.createMultiSelectData();
      });
    } else {
      this.createMultiSelectData();
      this.initSubscriptions();
    }

    if (!this.isFromConsoleOrg) {
      this.filesColumnsDef = [
        {
          label: '',
          field: 'selected',
          selected: this.SELECTED_TAG,
          type: UiStaticTableRowType.CHECKBOX,
          checkboxEmitter: this.checkboxEmitter,
          noSorting: true,
          width: '25px',
          isResizable: false,
        },
        ...this.filesColumnsDef,
      ];
    }
  }

  @HostListener('document:click', ['$event'])
  onOutsideClick(event: any): void {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.close();
    }
  }

  public close(): void {
    this.editWithModalComponent?.close();
  }

  public ngOnDestroy() {
    this.querySubscription?.unsubscribe();
    this.reloadSubscription?.unsubscribe();
    clearInterval(this.intervalOptions.intervalId);
  }

  public ngAfterViewInit() {}

  public toggle(): void {
    this.editWithModalComponent.toggle();
  }

  public handleShareCheckbox(event) {
    if (event.event) {
      this.selectedSharedFiles.push(event.item);
    } else {
      this.selectedSharedFiles = this.selectedSharedFiles.filter((share) => share !== event.item);
    }
  }

  // Prevent infinite loop of refresh when called from shares container (Revoking from resource tab -> refresh shares tab -> refresh resource tab)
  public handleRefresh(preventRefresh = false) {
    if (!this.isLoading) {
      this.from = 0;
      this.total = 0;
      this.shares = [];
      this.selectedSharedFiles = [];
      !preventRefresh ? this.refresh.emit() : null;
      this.loadFiles(preventRefresh);
    }
  }

  public handleSearchClear() {
    this.fulltext = null;
    this.handleRefresh();
  }

  public handleSearchQuery(fulltext: string) {
    this.fulltext = fulltext;
    this.handleRefresh();
  }

  public handleRisksFilter(filters: any[], preventRefresh = false) {
    this.handleFilterChange('risksFilters', filters, preventRefresh);
  }

  public handleVisibilityFilter(filters: any[]) {
    this.handleFilterChange('visibilitiesFilters', filters);
  }

  public handlePermissionsFilter(filters: any[]) {
    this.handleFilterChange('permissionsFilters', filters);
  }

  public handleItemsFilter(filters: any[]) {
    this.handleFilterChange('itemsFilters', filters);
  }

  public applySelectedFilters() {
    for (const filter in this.selectedFilters) {
      if (this.selectedFilters[filter]) {
        this.appliedFilters[filter] = this.selectedFilters[filter];
      }
    }
    this.updateActivatedFilters();
    this.handleRefresh();
  }

  public cancelSelectedFilters() {
    for (const filter in this.appliedFilters) {
      if (this.appliedFilters[filter]) {
        this.selectedFilters[filter] = this.appliedFilters[filter];
      }
    }
    this.uiMultiSelectChildren.map((p) => {
      p.setSelected([
        ...this.selectedFilters.risksFilters,
        ...this.selectedFilters.visibilitiesFilters,
        ...this.selectedFilters.permissionsFilters,
        ...this.selectedFilters.itemsFilters,
      ]);
    });
  }

  public clearAllFilters() {
    this.uiMultiSelectChildren.map((p) => p.clearSelection());
    for (const filters of [this.appliedFilters, this.selectedFilters]) {
      filters.visibilitiesFilters = [];
      filters.permissionsFilters = [];
      filters.risksFilters = [];
      filters.itemsFilters = [];
    }
    this.activatedFilters = 0;
    this.handleRefresh();
  }

  public handleSort(sort: string, direction: UiTableDirection) {
    this.sort = sort;
    this.direction = direction;
    this.handleRefresh();
  }

  public onScroll() {
    if (this.shares.length < this.total && !this.isLoading) {
      this.isLoading = true;
      this.loadFiles();
    }
  }

  public handleRowClick(file: UserSharedFile) {
    if (!file.isPending) {
      this.shares.forEach((share) => {
        share[this.SELECTED_TAG] = share === file;
      });
      this.selectedSharedFiles = this.shares.filter((share) => share[this.SELECTED_TAG]);
      setTimeout(() => {
        this.cdr.detectChanges();
      }, 1);
      this.drawerService.replaceCurrentDrawer(SharedFileDrawer, {
        component: DrawerPageEnum.userFilesDrawer,
        data: {
          fileType: file.fileType || FileTypeEnum.FILE,
          fileId: file.id,
          title: `${file.name}`,
          fileCreationDateLimit: file.fileCreationDateLimit,
          isFromConsoleOrg: this.isFromConsoleOrg,
          risk: file.risk,
        },
      });
    }
  }

  public revokeShares() {
    this.prepareRevocationDialogMessage();
    this.showRevokeDialog = true;
  }

  public onRevokeSharesCallback(event: DialogActions) {
    if (event === DialogActions.ACTION) {
      this.isLoading = true;

      const sharesToRevokeId = this.selectedSharedFiles.map((share) => share.id);
      this.sharesApiService.revokeSharedFiles(sharesToRevokeId).then(() => {
        this.noticeService.notifyUser(
          new Notice('shares.dialog.batchRevoke.confirmation.success.message', NoticeLevels.SUCCESS)
        );
        this.isLoading = false;
        this.handleRefresh();
        this.showRevokeDialog = false;
        this.store.dispatch(new HideDialog());
      });
    } else {
      this.showRevokeDialog = false;
      this.store.dispatch(new HideDialog());
    }
  }

  public getfileIcon(element: UserSharedFile): string {
    const extension = element.name.substring(element.name.lastIndexOf('.') + 1, element.name.length);

    if (extension === element.name) {
      return IconUtil.getFileIconFromFileExtension(null);
    }
    return IconUtil.getFileIconFromFileExtension(extension);
  }

  public openExpressRevokeDialog(): void {
    this.dialog
      .open(RevokeSharesDialogComponent, {
        maxWidth: '75%',
        panelClass: 'custom-dialog',
        data: { actionType: RevokeTypeEnum.EXPRESS_REVOKE, resourceId: this.libraryGuid },
      })
      .afterClosed()
      .subscribe(() => {
        this.handleRefresh();
      });
    this.toggle();
  }

  private loadFiles(preventRefresh = false) {
    this.isLoading = true;

    const currentCallId = this.latestCallId + 1;
    this.latestCallId = currentCallId;

    const params = {
      from: this.from,
      size: this.SIZE,
      sort: this.sort,
      direction: this.direction,
      fulltext: this.fulltext,
      risks: this.appliedFilters.risksFilters,
      shareTypes: this.appliedFilters.visibilitiesFilters,
      permissions: this.appliedFilters.permissionsFilters,
      fileTypes: this.appliedFilters.itemsFilters,
    };
    const jobGuidsToRemove = [];
    this.pendingJobGuids = [];
    this.backgroundTasksApiService
      .getJobStatus(this.temporaryDataService.revokedSharesJobGuids)
      .then((jobs) => {
        for (const value of jobs) {
          const isPending =
            value.status.operationsCount - value.status.error - (value.status.critical || 0) - value.status.success > 0;
          isPending ? this.pendingJobGuids.push(value.jobGuid) : jobGuidsToRemove.push(value.jobGuid);
        }
        this.temporaryDataService.removeRevokedShares(jobGuidsToRemove);
      })
      .finally(() => {
        this.getShares(params, currentCallId, preventRefresh);
      });
  }

  private getShares(
    params: {
      risks: FileRiskEnum[];
      size: number;
      permissions: FileShareLinkPermissionsTypeEnum[];
      fulltext: any;
      from: number;
      sort: any;
      direction: UiTableDirection;
      shareTypes: any[];
      fileTypes: FileTypeEnum[];
    },
    currentCallId: number,
    preventRefresh = false
  ) {
    if (this.libraryGuid) {
      const resourceShares = this.isFromConsoleOrg
        ? this.resourceSharedFiles.getResourceSharesOrg(this.libraryGuid, params)
        : this.resourceSharedFiles.getResourceShares(this.libraryGuid, params);
      resourceShares
        .then((files) => {
          if (this.latestCallId === currentCallId) {
            this.shares = this.shares.concat(files.items);
            this.from = +files.nextToken;
            this.total = files.total;
            this.sharesNumber.emit({ quantity: files.total, isFiltered: this.isFiltered });
          }
        })
        .catch((error) => {
          this.noticeService.notifyUser(new Notice('common.error.retry', NoticeLevels.ERROR));
        })
        .finally(() => {
          [this.filesColumnsDef, this.shares] = this.staticTableDataMapper.translate(this.filesColumnsDef, this.shares);
          this.shares.forEach((share) =>
            this.temporaryDataService.getPendingSharedFileIds(this.pendingJobGuids).includes(share.id)
              ? (share.isPending = true)
              : null
          );
          this.handleSharesRefresh(this.shares, preventRefresh);
          this.isLoading = false;
          if (this.initialLoad) {
            this.initialLoad = false;
          }
        });
    } else {
      const shares = this.isFromConsoleOrg
        ? this.sharesApiService.getUserSharesOrg(this.userGuid, params)
        : this.sharesApiService.getMyShares(params);
      shares
        .then((files) => {
          if (this.latestCallId === currentCallId) {
            this.shares = this.shares.concat(files.items);
            this.from = +files.nextToken;
            this.total = files.total;
            this.sharesNumber.emit({ quantity: files.total, isFiltered: this.isFiltered });
          }
        })
        .catch((error) => {
          this.noticeService.notifyUser(new Notice('common.error.retry', NoticeLevels.ERROR));
        })
        .finally(() => {
          [this.filesColumnsDef, this.shares] = this.staticTableDataMapper.translate(this.filesColumnsDef, this.shares);
          this.shares.forEach((share) =>
            this.temporaryDataService.getPendingSharedFileIds(this.pendingJobGuids).includes(share.id)
              ? (share.isPending = true)
              : null
          );
          this.handleSharesRefresh(this.shares, preventRefresh);
          this.isLoading = false;
          if (this.initialLoad) {
            this.initialLoad = false;
          }
          this.isSharedFilesLoading.emit(this.isLoading);
        });
    }
  }

  private get isFiltered(): boolean {
    let isFiltered = this.fulltext;
    for (const key in this.appliedFilters) {
      isFiltered = isFiltered || !!this.appliedFilters?.[key]?.length;
    }
    return isFiltered;
  }

  private getSharedFilesTotal(params: any): any {
    if (this.libraryGuid) {
      return this.isFromConsoleOrg
        ? this.resourceSharedFiles.getResourceSharesOrg(this.libraryGuid, params)
        : this.resourceSharedFiles.getResourceShares(this.libraryGuid, params);
    }
    return this.isFromConsoleOrg
      ? this.sharesApiService.getUserSharesOrg(this.userGuid, params)
      : this.sharesApiService.getMyShares(params);
  }

  private initSubscriptions(): void {
    this.reloadSubscription = this.accumulators$.subscribe((accumulators) => {
      const count = accumulators
        .filter((accumulator) => accumulator.accumulatable === Accumulatables.LIST_REFRESHER)
        .shift()?.accumulator;
      if (count !== this.currentAccumulatorCount) {
        this.handleRefresh(true);
        this.currentAccumulatorCount = count;
      }
    });

    this.querySubscription = this.searchQuery$.subscribe((fulltext) => {
      this.handleSearchQuery(fulltext);
    });
  }

  private createMultiSelectData(): void {
    this.riskMultiSelectData = this.multiSelectFactory.create(
      this.shareRisksFilter,
      this.defaultRiskFilter ? [this.defaultRiskFilter] : [],
      'file.risk.'
    );
    this.visibilitiesMultiSelectData = this.multiSelectFactory.create(
      this.shareVisibilitiesFilter,
      [],
      'file.visibility.'
    );
    this.permissionsMultiSelectData = this.multiSelectFactory.create(
      this.sharePermissionsFilter,
      [],
      'file.permission.'
    );
    this.itemsMultiSelectData = this.multiSelectFactory.create(
      this.shareItemsFilter,
      this.defaultRiskFilter ? [this.defaultRiskFilter] : [],
      'file.type.'
    );
  }

  private handleFilterChange<T extends keyof IFilters>(filter: T, filters: any[], preventRefresh = false) {
    if (this.mobileService.isMobile()) {
      this.selectedFilters[filter] = filters;
      this.appliedFilters[filter] = filters;
    } else {
      this.appliedFilters[filter] = filters;
      this.updateActivatedFilters();
    }
    this.handleRefresh(preventRefresh);
  }

  private updateActivatedFilters() {
    this.activatedFilters = 0;
    for (const filter in this.appliedFilters) {
      if (this.appliedFilters[filter]) {
        this.activatedFilters += this.appliedFilters[filter].length;
      }
    }
  }

  private setupColumnsDef(isAutomaticRevokeActivated: boolean) {
    if ((this.isMyShares || this.userGuid) && !this.libraryGuid) {
      this.filesColumnsDef.splice(2, 0, {
        label: 'shares.list.columns.name.resource',
        field: 'resourceName',
        type: UiStaticTableRowType.TEXT,
        width: '130px',
        noSorting: true,
      });
    } else {
      this.filesColumnsDef.splice(3, 0, {
        label: 'shares.list.columns.name.sharedBy',
        field: 'sharedBy',
        type: UiStaticTableRowType.TEXT,
        width: '125px',
        noSorting: true,
      });
    }

    if (isAutomaticRevokeActivated) {
      this.filesColumnsDef.push({
        label: 'shares.list.columns.name.scheduledDate',
        field: 'automaticRevokeDate',
        type: UiStaticTableRowType.PIPED_FIELD,
        pipe: DTPipe,
        tooltip: `${this.i18nService.translate('shares.scheduledDate.tooltip.title')}`,
        width: '150px',
      });
    }
  }

  private getFormattedGroupsNamesForRevoke(shares: any[]): string {
    let sharesNames = '<ul>';
    for (const share of shares) {
      sharesNames += `<li>${share.name}</li>`;
    }
    sharesNames += '</ul>';
    return sharesNames;
  }

  private prepareRevocationDialogMessage() {
    let sharesNames = '';
    if (this.selectedSharedFiles.length === 1) {
      this.shareNumber = ShareNumberEnum.SINGLE;
      sharesNames = this.selectedSharedFiles[0].name;
    } else {
      this.shareNumber = ShareNumberEnum.MANY;
      sharesNames = this.getFormattedGroupsNamesForRevoke(this.selectedSharedFiles);
    }

    this.revokeDialogMessage = this.i18nService.translate(`shares.dialog.revoke.message.${this.shareNumber}`, {
      shares: sharesNames,
    });

    this.revokeDialogTitle = this.i18nService.translate(`shares.dialog.revoke.title.${this.shareNumber}`, {
      shares: sharesNames,
    });
  }

  private getExternalIcon(row: any): string {
    if (row.exposure === 'Public' || row.hasGuestShares) {
      return 'assets/favicons/icon_check_gray.svg';
    }
    return null;
  }

  private handleSharesRefresh(files: UserSharedFile[], preventRefresh = false): void {
    if (this.isFromConsoleOrg) {
      return;
    }

    const areSomeFilesPending = files.some((file) => file.isPending);
    const isIntervalInAction = this.intervalOptions.isIntervalSet;

    if (areSomeFilesPending) {
      if (!isIntervalInAction) {
        this.intervalOptions.isIntervalSet = true;
        this.setReloadInterval();
      }
    } else {
      this.intervalOptions.isIntervalSet = false;
      this.sharesApiService.getMyShares({ from: 0, size: this.SIZE, direction: this.direction }).then((response) => {
        const items = response.items;
        this.sharesNumber.emit({ quantity: response.total, isFiltered: this.isFiltered });
        if (!this.libraryGuid) {
          this.total = response.total;
        }
        !preventRefresh ? this.refresh.emit() : null;
        const tempShares = [...this.shares];
        this.shares.forEach((share, index) => {
          if (share.isPending) {
            const updatedElement = items.find((newShare) => newShare.id === share.id);
            if (updatedElement) {
              tempShares.splice(index, 1, updatedElement);
            } else {
              const elementToRemove = tempShares.findIndex((newShare) => newShare.id === share.id);
              tempShares.splice(elementToRemove, 1);
            }
          }
        });
        this.shares = tempShares;
      });

      if (!!this.intervalOptions.intervalId) {
        clearInterval(this.intervalOptions.intervalId);
        this.intervalOptions.intervalId = null;
      }
    }
  }

  private setNewTotal(preventRefresh = false): void {
    this.getSharedFilesTotal({ size: 1 }).then((sharesTotal) => {
      this.sharesNumber.emit({ quantity: sharesTotal.total, isFiltered: this.isFiltered });
      this.total = sharesTotal.total;
      !preventRefresh ? this.refresh.emit() : null;
    });
  }

  private setReloadInterval(): void {
    this.intervalOptions.intervalId = setInterval(() => {
      const jobGuidsToRemove = [];
      this.pendingJobGuids = [];
      this.backgroundTasksApiService
        .getJobStatus(this.temporaryDataService.revokedSharesJobGuids)
        .then((jobs) => {
          for (const value of jobs) {
            const isPending =
              value.status.operationsCount - value.status.error - (value.status.critical || 0) - value.status.success >
              0;
            isPending ? this.pendingJobGuids.push(value.jobGuid) : jobGuidsToRemove.push(value.jobGuid);
          }
          this.temporaryDataService.removeRevokedShares(jobGuidsToRemove);
        })
        .finally(() => {
          this.reloadShares();
        });
    }, 10000);
  }

  private reloadShares(): void {
    this.sharesApiService.getMyShares({ from: 0, size: this.SIZE, direction: this.direction }).then((response) => {
      const items = response.items;
      items.forEach((share) =>
        this.temporaryDataService.getPendingSharedFileIds(this.pendingJobGuids).includes(share.id)
          ? (share.isPending = true)
          : null
      );
      this.handleSharesRefresh(items);
    });
  }
}
