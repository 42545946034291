import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HttpStatusCodes } from '@common/services/api/core/api.definitions';
import { GroupsTemporaryDataService } from '@common/services/api/sg/temporary-data/groups-temporary-data.service';
import { Select } from '@ngxs/store';
import { UiDialog } from '@ui-kit/components/ui-dialog/ui-dialog.component';
import { LanguageEnum } from '@ui-kit/interfaces/ILanguage';
import { GroupTypeEnum } from '@ui-kit/util/icon-util';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { NoticeLevels, NoticeService } from 'projects/@common/modules/notice/notice.service';
import { EcoSessionState } from 'projects/@common/modules/session/state/session.state';
import { GroupsApiService } from 'projects/@common/services/api/sg/groups/groupsApi.service';
import { TeamsApiService } from 'projects/@common/services/api/sg/teams/teams-api.service';
import { DashboardProfileService } from 'projects/@common/services/dashboard-profile.service';
import { IOwnerGroup } from 'projects/console-sg/src/app/pages/teams-resources/services/resources-list.service';
import { Observable } from 'rxjs';


@Component({
  selector: 'archive-group-dialog',
  templateUrl: './archive-group-dialog.component.html',
  styleUrls: [ './archive-group-dialog.component.scss' ],
})
export class ArchiveGroupDialogComponent implements OnInit {
  @Select(EcoSessionState.user) public currentUser$: Observable<string>;
  public isLoading = false;
  public isOwnerAllChanells = true;
  public otherOwners = [];
  @Input() public dataFromQuickAction: {
    group: IOwnerGroup;
    refresh: any;
    fromQuickAction: boolean;
  };
  public confirmationData = {
    text: this.i18n.translate('group.action.archive.message.archiveManager'),
    justificationConfirmationLabel: this.i18n.translate('group.action.archive.message.justification'),
    justificationConfirmationPlaceholder: this.i18n.translate('group.action.remediation.button.archive'),
    confirmationText: this.i18n.translate('yes'),
    confirmationFieldText: {
      fr: this.i18n.translate('group.action.remediation.button.archive', { locale: LanguageEnum.FRENCH }),
      en: this.i18n.translate('group.action.remediation.button.archive', { locale: LanguageEnum.ENGLISH }),
    },
    cancelText: this.i18n.translate('no'),
    currentLang: this.i18n.currentLocale,
    callback: (closeValue: { value: boolean, justification: string; }) => {
      if (closeValue.value) {
        this.archiveGroup();
      } else {
        this.close();
      }
    },
  };
  private currentUser: any;

  constructor(
    public dialogRef: MatDialogRef<UiDialog>,
    @Inject(MAT_DIALOG_DATA) public data: { group: IOwnerGroup & { toHide?: boolean; }; refresh: any; },
    private notice: NoticeService,
    private groupsApiService: GroupsApiService,
    private temporaryDataService: GroupsTemporaryDataService,
    private dashboardProfileService: DashboardProfileService,
    private i18n: I18nService,
    private teamsApiService: TeamsApiService
  ) {
  }

  public get otherOwnerNames(): string[] {
    return this.otherOwners.slice(0, 5).map((owner) => owner.displayName);
  }

  ngOnInit(): void {
    this.currentUser$.subscribe((currentUser) => this.currentUser = currentUser);
    if (this.dataFromQuickAction) {
      this.data.group = this.dataFromQuickAction.group;
    }
    if (this.data.group.type === GroupTypeEnum.O365OFFICETEAMS) {
      this.validateChannelOwnership();
    }
  }

  public archiveGroup(): void {
    this.isLoading = true;
    this.groupsApiService.archiveGroup({ groupOfficeId: this.data.group.id }).then((response) => {
      this.notice.notifyUser({
        message: `group.action.confirmation.message.ARCHIVE.manager`,
        level: NoticeLevels.SUCCESS,
        secondMessage: `group.action.confirmation.submessage.ARCHIVE.manager`,
      });

      this.data.group.toHide = true;
      this.temporaryDataService.addOwnerGroup(this.data.group);
      if (!!this.data.refresh) {
        this.data.refresh();
      }
    })
      .catch((error) => {
        if (error?.status === HttpStatusCodes.BAD_REQUEST && error?.error?.message === 'NO_ARCHIVE_ACCOUNT') {
          this.notice.notifyUser({ message: 'groups.action.error.archive_account', level: NoticeLevels.ERROR });
        } else {
          this.notice.notifyUser({ message: 'groups.action.error.message', level: NoticeLevels.ERROR });
        }
      })
      .finally(() => {
        this.isLoading = false;
        this.close();
      });
  }

  public close(): void {
    if (!this.dataFromQuickAction) {
      this.dialogRef.close();
    }

    if (this.dataFromQuickAction) {
      this.dataFromQuickAction.refresh();
      this.dashboardProfileService.getDashboardUserProfile();
    }
  }

  private validateChannelOwnership(): void {
    this.isLoading = true;
    this.teamsApiService.validateTeamOwnerChannelOwnership(this.data.group.id)
      .then((response) => {
        this.isOwnerAllChanells = Object.values(response).every((entry) => entry);
        this.otherOwners = response.otherOwners;
      })
      .finally(() => this.isLoading = false);
  }
}
