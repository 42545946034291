import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationsApiService } from 'projects/@common/services/api/notifications/notifications-api.service';
import { imageMapping } from 'projects/@common/services/api/sg/md.eco-profile/md.eco-profile.definition';

@Component({
  selector: 'sg-new-badge-banner',
  templateUrl: './new-badge-banner.component.html',
  styleUrls: [ './new-badge-banner.component.scss' ],
})
export class NewBadgeBannerComponent implements OnChanges {
  @Input() notifications: any[];

  @Input() fullWidth: boolean = false;

  @Output() onClose = new EventEmitter();

  public badgeTypes = [];

  public imageMappings = imageMapping;

  constructor(
    private router: Router,
    private notificationsService: NotificationsApiService
  ) {
  }

  ngOnChanges(): void {
    this.badgeTypes = [ ...new Set(this.notifications.map((notification) => notification?.payload?.relatedObject[0].values).flat(1)) ];
  }

  public async redirect(): Promise<void> {
    this.router.navigateByUrl('/ecoProfile').then(async () => {
      await Promise.all(this.notifications.map((notification) => this.notificationsService.markNotifAsRead(notification)));
    });
  }

  public async close(): Promise<void> {
    this.badgeTypes = [];
    this.onClose.emit();
    await Promise.all(this.notifications.map((notification) => this.notificationsService.markNotifAsRead(notification)));
  }
}
