<app-edit-with-modal #editor [leftOrientation]="windowWidthSmallerThan">
  <div
    class="edit-content small-gap"
    [ngClass]="{ 'disabled':disabled, 'pointer': !disabled }"
    [tooltip]="disabled ? null : ('group.action.remediation.message'| t)"
  >
    <button
      class="option-button"
      (click)="toggle()"
    >
      <img
        alt="edit-icon"
        src="assets/favicons/icon_three_dot_dark.svg"
      />
    </button>

  </div>
  <div content>
    <ng-container *ngFor="let option of options">
      <div
        (mouseover)="hoveredOption = option"
        (mouseleave)="hoveredOption = null"
      >
        <button
          class="full-width"
          ui-button
          color="dropdown"
          [ripple]="false"
          (click)="selectOption(option.value)"
        >
          <div class="surrounding-images">
            <img
              alt="edit-icon"
              class="pointer"
              src='{{"assets/favicons/" + optionIcons[option.value] + ".svg"}}'
            />
          </div>
          {{option.translated}}
        </button>
      </div>
    </ng-container>
  </div>
</app-edit-with-modal>