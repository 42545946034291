<div page [appPagePaths]="['user', 'mdAssistOps', 'dashboard']">
  <div description class="dashboard--mobile-header">{{ dashboardTitle }}</div>
  <div toolbar class="dashboard--mobile-header--toolbar">
    {{'dashboard.mobile.toolbar.text' | t}}
  </div>
  <ng-container zoneWithoutPadding *ngIf="!orgIsCISOnly">
    <div class="dashboard--content--mobile">
      <ui-spinner *ngIf="isLoadingWidgetPreferences"></ui-spinner>

      <mobile-dashboard-card
        *ngIf="enabledWidgets?.breaktimeTasks?.isActive"
        title="dashboard.coffeeBreak.title"
        subtitle="dashboard.coffeeBreak.secondaryTitle"
        [isCollapsed]="breaktimeCard.isCollapsed"
        [withTopMargin]="true"
        (isCollapsedChange)="handleCallapsable($event, collapsableCardEnum.BREAKTIME)"
      >
        <eco-breaktime></eco-breaktime>
      </mobile-dashboard-card>

      <mobile-dashboard-card
        *ngIf="enabledWidgets"
        title="dashboard.myCyberBehaviour.title"
        subtitle="dashboard.myCyberBehaviour.subtitle"
        [isCollapsed]="cyberBehaviorCard.isCollapsed"
        (isCollapsedChange)="handleCallapsable($event, collapsableCardEnum.CYBER_BEHAVIOR)"
      >
        <shares-type-proportions-v2
          *ngIf="enabledWidgets?.sharesDistribution?.isActive"
        ></shares-type-proportions-v2>
      </mobile-dashboard-card>

      <mobile-dashboard-card
        *ngIf="enabledWidgets"
        title="dashboard.resourcesMonitoring.card.title"
        subtitle="dashboard.resourcesMonitoring.card.subtitle"
        [isCollapsed]="sharedAssetsCard.isCollapsed"
        (isCollapsedChange)="handleCallapsable($event, collapsableCardEnum.SHARED_ASSETS)"
      >
        <div
          cardIndicators
          *ngIf="resourcesMonitoringStats"
          class="dashboard-resource-monitoring-header spacing-top"
        >
          <div
            *ngFor="let level of ['high', 'moderate', 'low']"
            [ngClass]="{
              'dashboard-resource-monitoring-header--high':
                level === 'high',
              'dashboard-resource-monitoring-header--moderate':
                level === 'moderate',
              'dashboard-resource-monitoring-header--low': level === 'low'
            }"
          >
            {{ ('shares.shareRiskScore.' + level | t) + ' (' +
          resourcesMonitoringStats[level + 'Count'] + ')' }}
          </div>
        </div>
        <my-shared-assets
          (onStatsLoaded)="setResourceMonitoringStats($event)"
        ></my-shared-assets>
      </mobile-dashboard-card>
    </div>
  </ng-container>
</div>
