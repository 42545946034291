import { Component, OnInit } from '@angular/core';
import { I18nService } from '@common/modules/i18n/i18n.service';
import { MobileService } from '@common/services/mobile.service';
import { IBreadcrumbs } from 'projects/@assist-ops/components/breadcrumbs/breadcrumbs.component';
import { PageDescriptionService } from '../../../../services/page-description.service';
import { PageDescriptions } from '../../../shared/components/page-layout/page-descriptions.enum';
import { SensitiveFilesService } from './sensitive-files-service';

@Component({
  selector: 'files-list',
  templateUrl: './sensitive-files-list.html',
  styleUrls: [ './sensitive-files-list.scss' ],
})
export class SensitiveFilesList implements OnInit {
  public breadcrumbs: IBreadcrumbs[] = [
    { nodeName: 'breadcrumb.dashboard', nodeLink: '/dashboard' },
    { nodeName: 'breadcrumb.teams.files', nodeLink: null },
  ];
  public requiredWidthForMobile = 1000;

  constructor(
    public i18n: I18nService,
    public mobileService: MobileService,
    public filesListService: SensitiveFilesService,
    public pageDescriptionService: PageDescriptionService
  ) {
  }

  public openDescription(): void {
    this.pageDescriptionService.openTooltip(PageDescriptions.FILES);
  }

  public async ngOnInit() {
    this.filesListService.handleRefresh();
  }
}
