export enum HttpStatusCodes {
  OK                          = 200,
  CREATED                     = 201,
  ACCEPTED                    = 202,
  NO_CONTENT                  = 204,
  RESET_CONTENT               = 205,
  PARTIAL_CONTENT             = 206,
  MULTI_STATUS                = 207,

  MULTIPLE_CHOICES            = 300,
  MOVED_PERMANENTLY           = 301,
  NOT_MODIFIED                = 304,

  BAD_REQUEST                 = 400,
  UNAUTHORIZED                = 401,
  FORBIDDEN                   = 403,
  NOT_FOUND                   = 404,
  REQUEST_TIMEOUT             = 408,
  CONFLICT                    = 409,
  PRECONDITION_FAILED         = 412,
  IM_A_TEAPOT                 = 418,
  TOKEN_EXPIRED               = 498,

  INTERNAL_SERVER_ERROR       = 500,
  NOT_IMPLEMENTED             = 501,
  BAD_GATEWAY                 = 502,
  GATEWAY_TIMEOUT             = 504
}
