import { Component, Input } from '@angular/core';
import { TimeUtil } from '@ui-kit/services/time-util';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { IFileActivity } from 'projects/@common/services/api/sg/shares/shares.definitions';

@Component({
  selector: 'label-with-text',
  templateUrl: './label-with-text.component.html',
  styleUrls: [ './label-with-text.component.scss' ],
})
export class LabelWithTextComponent {
  @Input() activity: IFileActivity;

  constructor (private i18n: I18nService) { }

  public getName(activity: IFileActivity): string {
    let name = activity?.userName || activity?.userEmail;
    if (!name && activity.isSystem) {
      name = this.i18n.translate('common.system');
    }
    return name;
  }

  public getSilenceDuration(activity: IFileActivity): number {
    return TimeUtil.getNumberOfDaysBetweenTwoDates(activity.timestamp, activity.silencingEnds);
  }
}
