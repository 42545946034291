import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, Store } from '@ngxs/store';
import { UiTabs, UiTabsColor } from '@ui-kit/components/ui-tabs/ui-tabs.component';
import { IBreadcrumbs } from 'projects/@assist-ops/components/breadcrumbs/breadcrumbs.component';
import { ResourcesSharesTableComponent } from 'projects/@assist-ops/components/resoures-shares-table/resources-shares-table.component';
import { SharedFilesTableComponent } from 'projects/@assist-ops/components/shared-files-table/shared-files-table.component';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { EcoSessionState } from 'projects/@common/modules/session/state/session.state';
import { ResourceSharesApiService } from 'projects/@common/services/api/sg/resourceSharedFiles/resource-shares-api.service';
import { SharesApiService } from 'projects/@common/services/api/sg/shares/shares-api.service';
import { MobileService } from 'projects/@common/services/mobile.service';
import { TeamsAuthService } from 'projects/@common/services/teams-auth.service';
import { DOCUMENTATION_SECTION, generateDocumentationLink } from 'projects/@common/utils/eco.documentation-utils';
import { BannerTypeEnum } from 'projects/console-sg/src/app/shared/components/banners/banners.component';
import { PageDescriptions } from 'projects/console-sg/src/app/shared/components/page-layout/page-descriptions.enum';
import { PageDescriptionService } from 'projects/console-sg/src/services/page-description.service';

@Component({
  selector: 'shares',
  templateUrl: './shares.container.html',
  styleUrls: ['./shares.container.scss'],
})
export class SharesContainer implements OnInit, AfterViewInit {
  public documentationSection = generateDocumentationLink(DOCUMENTATION_SECTION.sg.reviewShare, this.i18n.currentLocale);
  public breadcrumbs: IBreadcrumbs[] = [
    { nodeName: 'breadcrumb.dashboard', nodeLink: '/dashboard' },
    { nodeName: 'breadcrumb.teams.shares', nodeLink: null },
  ];

  public hasHighRisksResources: {
    riskUserSharesLOW?: boolean,
    riskUserSharesMED?: boolean,
    riskUserSharesHIGH?: boolean,
    riskUserResources?: boolean;
  } = {};

  public sharesIcon = null;

  public resourcesIcon = null;

  public name = this.route.snapshot.queryParams.name;

  public userGuid = this.route.snapshot.queryParams.userGuid;

  public isMyShares: boolean;

  public isResourcesLoading = true;

  public showRightSide = true;

  public descriptionExpanded = false;

  public isSharedFilesLoading = true;
  public resourceGuid = this.route.snapshot.queryParams.guid;

  public disableExpressRevokePersonnal = false;
  public disableExpressRevokeResources = false;

  public isFiltered = false;

  @ViewChild('tabs') public tabs: UiTabs;
  private totalResourcesNumber = 1;
  private totalPersonnalSharesNumber = 1;
  private tabIndexSelected: number = +this.route.snapshot.queryParams?.tabIndexSelected || 0;
  @ViewChild(SharedFilesTableComponent) private sharedFilesTable: SharedFilesTableComponent;
  @ViewChild(ResourcesSharesTableComponent) private resourcesSharesTable: ResourcesSharesTableComponent;

  constructor(
    protected readonly store: Store,
    protected readonly actions$: Actions,
    private pageDescriptionService: PageDescriptionService,
    private route: ActivatedRoute,
    private router: Router,
    private i18n: I18nService,
    public mobileService: MobileService,
    private resourcesApi: ResourceSharesApiService,
    private sharesApiService: SharesApiService
  ) {
  }

  public get hasResourcesWithShares(): boolean {
    return this.totalResourcesNumber > 0;
  }

  public get hasPersonnalShares(): boolean {
    return this.totalPersonnalSharesNumber > 0;
  }

  public get tabColor(): string {
    return TeamsAuthService.isInTeams ? UiTabsColor.TeamsTabs : UiTabsColor.lightTabs;
  }

  public refreshBanner(triggeredFromMyShares: boolean): void {
    this.expressRevokedDisabled();
    this.resourcesApi.getUserRiskResources()
      .then((response) => {
        this.hasHighRisksResources = response;
        this.updateTabsIcon();
        triggeredFromMyShares ? this.resourcesSharesTable?.refreshLoad(true) : this.sharedFilesTable?.handleRefresh(true);
      })
      .catch((error) => {
      });
  }

  public async ngOnInit() {
    this.isMyShares = this.router.url.includes('/shares/myShares');

    if (this.isMyShares) {
      this.userGuid = this.store.selectSnapshot(EcoSessionState.userGuid);
    }

    if (!this.router.url.includes('resources') && !this.isMyShares) {
      this.userGuid = this.route.snapshot.params.guid;
      this.name = this.route.snapshot.queryParams.name;
    }


    this.tabs?.selectTabIndex(this.tabIndexSelected || 0);

    this.showRightSide = this.tabIndexSelected !== 1;

    if (this.route.snapshot.queryParams?.hasHighRisksResources && !TeamsAuthService.isInTeams) {
      this.hasHighRisksResources = JSON.parse(this.route.snapshot.queryParams.hasHighRisksResources);
    }

    this.expressRevokedDisabled();

    if (this.hasHighRisksResources?.riskUserSharesHIGH === undefined || this.hasHighRisksResources?.riskUserResources === undefined) {
      this.resourcesApi.getUserRiskResources()
        .then((response) => {
          this.hasHighRisksResources = response;
          this.updateTabsIcon();
        })
        .catch((error) => {
        });
    } else {
      this.updateTabsIcon();
    }
  }

  public ngAfterViewInit() {
    this.tabs?.selectTabIndex(this.tabIndexSelected);
  }

  public openDescription(): void {
    this.pageDescriptionService.openTooltip(PageDescriptions.MY_SHARES);
  }

  public updateQueryParams(): void {
    const tabIndexSelected = this.tabs.getSelectedTabIndex();
    if (tabIndexSelected || tabIndexSelected === 0) {
      this.showRightSide = true;
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: { tabIndexSelected },
        queryParamsHandling: 'merge',
      });
    }
    if (tabIndexSelected === 1) {
      this.showRightSide = false;
    }
    this.updateTabsIcon();
  }

  public getResourcesNumber(total: number): void {
    this.totalResourcesNumber = total;
    this.isResourcesLoading = false;
  }

  public updateTabsIcon(): void {
    if (this.hasHighRisksResources.riskUserSharesHIGH) {
      this.sharesIcon = 'icon_pause_warning';
    } else {
      this.sharesIcon = null;
    }
    if (this.hasHighRisksResources?.riskUserResources) {
      this.resourcesIcon = 'icon_pause_warning';
    } else {
      this.resourcesIcon = null;
    }
  }

  public getSharesNumber(value: { quantity: number, isFiltered: boolean }): void {
    this.totalPersonnalSharesNumber = value.quantity;
    this.isFiltered = value.isFiltered;
    this.isSharedFilesLoading = false;
  }

  public isSharedFilesLoadingFunc(): void {
  }

  public scroll(): void {
    this.tabs?.getSelectedTabIndex() ? this.resourcesSharesTable?.loadMore() : this.sharedFilesTable?.onScroll();
  }

  public getBannerType(): BannerTypeEnum {
    return BannerTypeEnum.ok;
  }

  public getBannerMessage(): string {
    return this.i18n.translate('shares.gestions.banner.compliant.resource.message');
  }

  public toggleDescription() {
    this.descriptionExpanded = !this.descriptionExpanded;
  }

  public expressRevokedDisabled() {
    this.sharesApiService.getMyFilesSharesTypesNumbers({ includeSilenced: true })
      .then((response) => {
        this.disableExpressRevokePersonnal = response.riskHigh === 0 && response.riskModerate === 0;
      })
      .catch(() => {
      });

    this.resourcesApi.getResourceFilesSharesTypesNumbers('all', { includeSilenced: true })
      .then((response) => {
        this.disableExpressRevokeResources = response.riskHigh === 0 && response.riskModerate === 0;
      })
      .catch(() => {
      });
  }
}
