import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MobileService } from '@common/services/mobile.service';
import { CarouselDialogComponent } from 'projects/@assist-ops/components/carousel-dialog/carousel-dialog.component';

@Component({template: '<div style="height: 100vh; background: white"></div>'})
export class OnboardingContactUsComponent implements OnInit {
  constructor(private dialog: MatDialog, private mobileService: MobileService) {
  }

  ngOnInit(): void {
    this.dialog.open(CarouselDialogComponent, {
      width: '85%',
      maxWidth: '1000px',
      data: { isNewClient: true },
      panelClass: 'custom-dialog',
    });
  }
}
