import { ITableFilterData } from './ui-table-filter-tool.component';

export class TableFilterData {
  constructor(public options: ITableFilterData[] = []) {
  }

  get defaultData(): ITableFilterData {
    return this.options.find((option) => option.isDefault);
  }

  public setNewDefaultData(option: ITableFilterData): void {
    this.defaultData.isDefault = false;
    option.isDefault = true;
  }
}
