import { Injectable } from '@angular/core';
import { MultiSelectData } from '@ui-kit/components/ui-multi-select/multi-select-data';
import { I18nService } from '../i18n.service';

@Injectable({
  providedIn: 'root',
})
export class MultiSelectDataFactory {
  constructor(private i18n: I18nService) {
  }

  public create(selectableOptions: Object, preSelectedOptions: any[] = [], translatePrefix = '', useKeyAsTranslationFallback = false, sort = false): MultiSelectData {
    let options = [];

    options.push({
      option: null,
      isSelected: !preSelectedOptions?.length,
      isDefaultValue: true,
      translated: this.i18n.translate(`${translatePrefix}ALL.filter`),
      translatedShort: this.i18n.translate(`${translatePrefix}ALL.filter.translatedShort`, null, ""),
    });

    for (const [ key, value ] of Object.entries(selectableOptions)) {
      options.push({
        option: value,
        isSelected: preSelectedOptions.includes(value),
        isDefaultValue: false,
        translated: this.i18n.translate(`${translatePrefix}${value}.filter`, null, useKeyAsTranslationFallback ? key : value),
        translatedShort: this.i18n.translate(`${translatePrefix}${value}.filter.translatedShort`, null, ""),
      });
    }

    if (sort) {
      options = [
        options[0], 
        ...options.slice(1).sort((o1, o2) => o1.translated.localeCompare(o2.translated))
      ];
    }

    return new MultiSelectData(options);
  }
}
