<div
  class="drawer-detail--scrollable tab-menu"
  [ngClass]="'hidden'"
  scrollToBottom
  (onScrollToBottom)="loadMoreActivities()"
>
  <div
    class="drawer-detail--content file-details"
    [class.drawer-detail--content-mobile]="mobileService.isMobile()"
  >
    <shared-file-activities-table
      [activities]="shownActivities"
    ></shared-file-activities-table>

    <ng-container *ngIf="isLoading">
      <ui-spinner size="medium" modifier="secondary"></ui-spinner>
    </ng-container>
  </div>
</div>
