import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DisplayModule } from '@common/modules/display/display.module';
import { UiKitModule } from '@ui-kit/uikit.module';
import { AssistOpsModule } from 'projects/@assist-ops/assist-ops.module';
import { DirectivesModule } from 'projects/@common/modules/directives/directives.module';
import { I18nModule } from 'projects/@common/modules/i18n/i18n.module';
import { SgSharedModule } from '../../shared/shared.module';
import { BadgesDetailsComponent } from './components/badges-list/badges-details.component';
import { BasicExpandableComponent } from './components/basic-expandable/basic-expandable.component';
import { ConsecutiveWeeklyGraphComponent } from './components/consecutive-weekly-graph/consecutive-weekly-graph.component';
import { MonthlyActionsGraphComponent } from './components/monthly-actions-graph/monthly-actions-graph.component';
import { EcoProfileComponent } from './eco-profile.component';
import { EcoProfileRoutingModule } from './eco-profile.routing.module';
import { TeamProfileComponent } from './org-profile/team-profile.component';
import { UserProfileComponent } from './user-profile/user-profile.component';

const components = [
  EcoProfileComponent,
  TeamProfileComponent,
  UserProfileComponent,
  BasicExpandableComponent,
  BadgesDetailsComponent,
  ConsecutiveWeeklyGraphComponent,
  MonthlyActionsGraphComponent,
];

@NgModule({
  declarations: [ ...components ],
  exports: [ ...components ],
  imports: [
    CommonModule,
    EcoProfileRoutingModule,
    I18nModule,
    UiKitModule,
    SgSharedModule,
    DirectivesModule,
    AssistOpsModule,
    DisplayModule
  ],
})
export class EcoProfileModule { }
