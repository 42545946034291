import { RequestStatusEnum } from "@ui-kit/util/icon-util";

export interface IGuestUserRequest {
  email?: string;
  firstName?: string;
  lastName?: string;
  groupGuid?: string;
  expirationDate?: number;
  jobTitle?: string;
  companyName?: string;
}

export enum RequestTypeEnum {
  RESOURCE_CREATION = 'RESOURCE_CREATION',
  GUEST_RESOURCE_ADD = 'GUEST_USER_INVITE_RESOURCES_OWNER',
  GUEST_USER_INVITE = 'GUEST_USER_INVITE'
}

export interface IRequestApprover {
  approver?: any;
  timestamp?: number;
  decision?: RequestStatusEnum;
  note?: string;
  approverRole?: RequestAssignedToEnum;
}

export enum RequestAssignedToEnum {
  REQUESTER = 'REQUESTER',
  OTHER = 'OTHER',
  MANAGER = 'MANAGER',
  OWNER = 'OWNER',
  ADMIN = 'ADMIN'
}

export enum UserNotificationActionStatusEnum {
  APPROVED = 'APPROVED',
  DECLINED = 'DECLINED',
  ACKNOWLEDGED = 'ACKNOWLEDGED',
  PENDING = 'PENDING',
  DELETED = 'DELETED'
}

export enum UserStatusEnum {
  ALL_USERS = 'allUsers',
  ENABLED = 'enabled',
  DISABLED = 'disabled',
  UNREGISTERED = 'unregistered',
  DELETED = 'deleted',
  PLATFORM = 'platform',
  O365 = 'o365',
  GUESTS = 'guests',
  NOT_GUEST = 'notGuest',
  MANAGER = "manager",
  O365_NOT_GUEST = "o365_notGuest",
  LOCAL_AD_USERS = "localAdUsers"
}


export interface GetRequestsResponse {
  total: number;
  nextToken: string;
  items: RequestList[];
  toProcessNumber: number;
}

export interface RequestList {
  id: string;
  createdAt: number;
  status: RequestStatusEnum | string;
  type: string;
  number: number;
  requesterName: string;
  description: string;
  currentApprovingType: string;
  requestInformation: any;
  organizationId: any;
  canProcess: boolean;
}

export interface GetRequestResponse {
  id: string,
  createdAt: number,
  updatedAt: number,
  status: RequestStatusEnum | string;
  type: string,
  requesterName: string,
  description: { fr: string, en: string },
  currentApprovingType: string,
  requestInformation: any,
  organizationId: string,
  justification: string;
  canProcess: boolean,
  approverId: string;
  history: RequestHistory[]
}
export interface RequestHistory {
  timestamp?: number,
  type?: string | RequestAssignedToEnum,
  name?: string,
  status?: RequestStatusEnum | string;
  note?: string,
}
