<div class="default-approving">
  <ng-container *ngIf="isEditing || groupTemplateForm.isCreating; then write; else read"></ng-container>
</div>

<ng-template #read>
    <div *ngIf="groupTemplateForm.approversForm?.value.reach" class="labels" [ngStyle]="{ 'margin-bottom': '.9rem' }">
      <ui-static
        [label]="'groupTemplates.details.model.reach.label' | t"
          [value]="'groupTemplates.details.model.reach.' + groupTemplateForm.approversForm?.value.reach | t: {segmentationIsDeactivated: segmentationIsDeactivated}"
      >
      </ui-static>
    </div>
    <div *ngIf="groupTemplateForm.approversForm?.value.reach && groupTemplateForm.approversForm?.value?.attribute[0]?.value" [ngStyle]="{ 'margin-bottom': '.9rem' }">
      <ui-static
        *ngIf="!segmentationIsDeactivated && groupTemplateForm.approversForm?.value.reach !== 'global'"
        [ngStyle]="{ 'margin-bottom': '.9rem' }"
        [label]="'groupTemplates.details.model.reach.attribute' | t"
        [value]="groupTemplateForm.approversForm?.value?.attribute[0]?.displayValue"
      >
      </ui-static>
    </div>
  <ng-container
    *ngIf="
      (!segmentationIsDeactivated && groupTemplateForm.approversForm?.value.attribute[0]?.value && groupTemplateForm.approversForm?.value.reach === groupTemplateReach.SEGMENTED) ||
      (segmentationIsDeactivated && defaultApprovers?.length)
    "
  >
    <p class="default-approving--label">{{ 'groupTemplates.details.defaultApproving.defaultApprovers' | t }}</p>
    <ui-user-tags
      [users]="defaultApprovers"
      [readOnly]="!isEditing"
      (onDeleteUser)="removeDefaultApprover($event)"
    ></ui-user-tags>
  </ng-container>
</ng-template>

<ng-template #write>
    <ng-container *ngIf="!isLoadingPossibleValues; else spinner">
      <label class="custom-label" ui-label [required]="true">{{ 'default.approving.reach.title' | t }}</label>
      <ui-field-group [formGroup]="groupTemplateForm.approversForm" class="approvers-buttons">
        <mat-radio-group formControlName="reach" class="buttons">
          <mat-radio-button *ngFor="let reachOption of reachOptions" (click)="selectReach(reachOption.value)" [value]="reachOption.value" >
            <div>{{ reachOption.displayValue | t }}</div>
          </mat-radio-button>
        </mat-radio-group>
      </ui-field-group>
    </ng-container>


    <ng-container *ngIf="canDisplaySegment && !segmentationIsDeactivated">
      <label class="custom-label" ui-label [required]="true">{{ 'default.approving.segment.title' | t }}</label>
      <autocomplete
        [placeholder]="'settings.general.segmentation.autocomplete.placeholder' | t"
        [required]="true"
        [autocompleteType]="customAutocompleteType"
        [customValues]="customValues"
        [initItems]="this.groupTemplateForm.approversForm?.value?.attribute"
        [selectedItems]="this.groupTemplateForm.approversForm?.value?.attribute"
        [canMultiSelect]="false"
        [oneItemAtATime]="false"
        [showInInputAtInit]="false"
        [locale]="i18n.currentLocale"
        [customNotFound]="'securityModel.approvers.attribute.empty' | t"
        expandHeight="max-content"
        (onItemSelected)="onAttributeSelected($event)"
      >
      </autocomplete>

      <div class="tag" *ngIf="groupTemplateForm.approversForm?.value?.attribute?.length">
        <span class="tag--name"> {{ selectedAttribute }} </span>
        <ui-icon
          class="tag--delete"
          size="medium"
          modifier="on-header"
          name="close-circle"
          (click)="clearAttribute()"
        ></ui-icon>
      </div>
    </ng-container>
  <div *ngIf="canDisplaySegment" class="spacing">
    <ui-warning [warningMessages]="messages" [color]="warningColors.YELLOW" [centerText]="false"></ui-warning>
    <autocomplete
      [disabled]="canDisplaySegment && !canEditApproversAutocomplete && !segmentationIsDeactivated"
      [canMultiSelect]="true"
      [placeholder]="'groupTemplates.details.defaultApproving.defaultApprovers' | t"
      [autocompleteType]="usersAutocomplete"
      [searchOnlyUserResources]="false"
      [(selectedItems)]="defaultApprovers"
      name="userAutocomplete"
      [searchFunction]="usersAutocompleteSearchFunction"
      [itemsToIgnore]="defaultApprovers"
      [label]="'groupTemplates.details.defaultApproving.defaultApprovers' | t"
      [required]="true"
      [locale]="i18n.currentLocale"
      [disabledLabel]="'groupTemplates.details.defaultApproving.warningMessage' | t "
      (onItemSelected)="handleApproversChange()"
      expandHeight="max-content"
    ></autocomplete>
    <ui-user-tags
      [users]="defaultApprovers"
      [readOnly]="!isEditing"
      (onDeleteUser)="removeDefaultApprover($event)"
    ></ui-user-tags>
  </div>
</ng-template>

<ng-template #spinner>
  <div class="spinner">
    <ui-spinner size="medium"></ui-spinner>
  </div>
</ng-template>
