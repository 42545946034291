import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Stages } from 'projects/@common/definitions/stages';
import { environment } from 'projects/@common/environments/environment';
import { SaineGestionModule } from './app/saine.gestion.app.module';

if (environment.stage == Stages.PROD) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(SaineGestionModule)
  .catch((err) => console.error(err));
