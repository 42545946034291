import { Component, OnDestroy, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { BreaktimeService } from '../../../service/breaktime.service';
import { IBreaktimeTask, IBreakTimeTaskEnum } from './breaktime-task/breaktime-task.component';
import { EcoSessionState } from 'projects/@common/modules/session/state/session.state';
import { RequestsApiService } from 'projects/@common/services/api/requests/requests-api.service';
import { UserBehaviorApiService } from 'projects/@common/services/api/sg/user-behavior/user-behavior.api';
import { MobileService } from 'projects/@common/services/mobile.service';
import { ListCategoryType } from '../../../../requests/containers/requests.container';

@Component({
  selector: 'eco-breaktime',
  templateUrl: './eco-breaktime.component.html',
  styleUrls: [ './eco-breaktime.component.scss' ],
})
export class EcoBreaktime implements OnInit, OnDestroy {
  @Select(EcoSessionState.user) public currentUser: Observable<any>;
  public readonly DEFAULT_TASKS_NUMBER_TO_SHOW = 5;

  public isLoading = true;

  public tasks: IBreaktimeTask[] = [];

  public showMore = false;


  private subscriptions: Subscription = new Subscription();

  constructor(
    public readonly mobileService: MobileService,
    private readonly userBehaviorApiService: UserBehaviorApiService,
    private readonly store: Store,
    private readonly breaktimeService: BreaktimeService,
    private readonly requestApiService: RequestsApiService
  ) {
  }

  public ngOnInit() {
    this.subscriptions.add(this.breaktimeService.update.subscribe(() => this.getBreaktimeTasks()));
  }

  public ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public toggleShowMore() {
    this.showMore = !this.showMore;
  }

  public removeTask(taskToRemove: IBreaktimeTask) {
    this.tasks = this.tasks.filter((task) => task.taskName !== taskToRemove.taskName || taskToRemove.device?.id !== task.device?.id);
  }

  private getBreaktimeTasks() {
    this.tasks = [];
    this.requestApiService.getRequests({ category: ListCategoryType.ACTION_REQUIRED, limit: 1 })
      .then((response) => {
        if (response.toProcessNumber > 0) {
          this.tasks.push({
            taskName: IBreakTimeTaskEnum.DOES_USER_HAS_PENDING_REQUESTS,
            icon: this.getTaskIcon(IBreakTimeTaskEnum.DOES_USER_HAS_PENDING_REQUESTS),
          });
        }
      });
    this.userBehaviorApiService
      .getBreaktimeTasks()
      .then((breaktimeTasks) => {
        for (const breaktimeTask of Object.keys(breaktimeTasks)) {
          if (breaktimeTasks[breaktimeTask]) {
            this.tasks.push({
              taskName: breaktimeTask,
              icon: this.getTaskIcon(breaktimeTask as IBreakTimeTaskEnum),
              twoActions: this.displayTwoActions(breaktimeTask as IBreakTimeTaskEnum),
            });
          }
        }
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  private displayTwoActions(type: IBreakTimeTaskEnum): boolean {
    return [ IBreakTimeTaskEnum.IS_THERE_HIGH_RISK_SHARES_MADE_BY_USER ].includes(type);
  }

  private getTaskIcon(task: IBreakTimeTaskEnum): string {
    switch (task) {
      case IBreakTimeTaskEnum.IS_THERE_AUTOMATIC_REVOKE_SHARES_MADE_BY_USER:
        return 'revoke_warning';
      case IBreakTimeTaskEnum.IS_THERE_AUTOMATIC_REVOKE_SHARES_IN_USER_RESOURCES:
        return 'revoke_warning';
      case IBreakTimeTaskEnum.IS_THERE_HIGH_RISK_SHARES_MADE_BY_USER:
        return 'review';
      case IBreakTimeTaskEnum.IS_THERE_HIGH_RISK_SHARES_IN_USER_RESOURCES:
        return 'review';
      case IBreakTimeTaskEnum.DOES_USER_HAS_PENDING_REQUESTS:
        return 'pending';
      default:
        return '';
    }
  }
}
