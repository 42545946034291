<sg-eco-profile-card>
  <div class="container">
    <p class="title" [innerHTML]="'eco-profile.page.tab.user-profile.weekly-section.weekly-graph.title' | t"></p>
    <div class="graph-container">
      <ng-container *ngFor="let streak of consecutiveStreak">
        <div class="graph">
          <p>{{streak.year}}</p>
          <div class="line">
            <div
              class="pourcentage"
              [ngStyle]="{width: streak.width + '%'}"
            >
              <img
                [ngClass]="{'atStart': streak.width < 5}"
                src="assets/favicons/icon_graph_shield_pro.svg"
                *ngIf="streak.isCurrentYear"
              >
            </div>
          </div>
          <div class="amount-container">
            <p>{{streak.amount}}</p>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</sg-eco-profile-card>
