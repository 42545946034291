import { DynamicQueryTimeRangeEnum } from '@ui-kit/components/widget-container/widget-container.component';
import { GroupTypeEnum } from '@ui-kit/util/icon-util';
import { SharedFileFactor } from 'projects/@assist-ops/components/share-risk-score/severityDecidingFactor.service';

export interface IResourceSharedFilesStats {
  highCount: number;
  moderateCount: number;
  lowCount: number;
  topResourcesStats: {
    resource: string;
    nearestRevokeDate?: number;
  }[];
  remainingResourcesCount: number;
}

export interface IOrganizationResourceTendency {
  id: string;
  createdAt: number;
  updatedAt: number;
  organizationId: string;
  total: number;
  atRisk: number;
  dailyTimestamp?: number;
}

export enum SharedFilesResourceTypeEnum {
  ONEDRIVE = 'ONEDRIVE',
  LIBRARY = 'LIBRARY',
  PRIVATE_CHANNEL = 'PRIVATE_CHANNEL'
}

export enum FileShareTypeEnum {
  ALL = 'AllTypes',
  MEMBER = 'Member',
  GUEST = 'Guest',
  PUBLIC = 'Public',
  COMPANY = 'Company',
  SECURITY_GROUP = 'SecurityGroup'
}

export enum ShareNumberEnum {
  SINGLE = 'single',
  MANY = 'many'
}

export interface ISharedFile {
  id: string;
  name: string;
  risk: any;
  resourceName: string;
  sharedBy: any[];
  exposure: string;
  fileType: any;
  fileSize: string;
  extension: string;
  createdBy: any;
  createdAt: number | string;
  updatedBy: any;
  updatedAt: number | string;
  webUrl: string;
  siteId: string;
  listId: string;
  hasGuestShares: boolean;
  sourceRelativeUrl: string;
  lastAccessed: string | number;
  silencingEnds?: number;
  automaticRevokeDate?: number;
  riskAssociatedFactors?: Array<{ name: keyof SharedFileFactor, value: string | number; }>;
}

export enum FileShareLinkPermissionsTypeEnum {
  VIEW = 'view',
  REVIEW = 'review',
  BLOCKSDOWNLOAD = 'blocksDownload',
  EDIT = 'edit'
}

export enum FileRiskEnum {
  HIGH = 'HIGH',
  MODERATE = 'MODERATE',
  LOW = 'LOW',
  SILENCED = 'SILENCED'
}

export enum ResourceTypeEnum {
  GROUP = 'GROUP',
  APPLICATION = 'APPLICATION',
  ROLE = 'ROLE',
  ONEDRIVE = 'ONEDRIVE'
}

export enum TopSitesAtRiskSortEnum {
  FILE_VAR_7_DAY_AVG = 'problematicSharedFiles.var7DayAvg',
  TOTAL_VAR_7_DAY_AVG = 'problematicSharedTotal.var7DayAvg'
}

export interface IFilesSharesTypeCounts {
  publicLinks?: number;
  members?: number;
  guests?: number;
  company?: number;
  securityGroups?: number;
}

export enum ChartTypeEnum {
  XY = 'XYChart',
  PIE = 'PieChart',
  DONUT = 'DonutChart',
  CHORD_DIAGRAM = 'ChordDiagram',
  XY_HISTOGRAM = 'XYHistogramChart',
  LINE = 'LineChart',
  SIMPLE_FILLED_MINI_CHART = 'SimpleFilledMiniChart',
  SIMPLE_LINE_SHADED_MINI_CHART = 'SimpleLineShadedMiniChart'
}

export enum TendencyTimeRangesEnum {
  LAST_7_DAYS = DynamicQueryTimeRangeEnum.LAST_7_DAYS,
  PREVIOUS_MONTH = DynamicQueryTimeRangeEnum.PREVIOUS_MONTH,
  LAST_3_MONTHS = DynamicQueryTimeRangeEnum.LAST_90_DAYS,
  LAST_YEAR = DynamicQueryTimeRangeEnum.LAST_52_WEEKS
}

export interface ISharedFilesRevokeConditions {
  riskHigh?: boolean;
  riskModerate?: boolean;
  publicShares?: boolean;
  organizationalShares?: boolean;
  groupShares?: boolean;
  guestShares?: boolean;
  memberShares?: boolean;
  sharedSince?: number;
  inactiveSince?: number;
  includeSilenced?: boolean;
}

export interface ISharedFilePermission {
  permissionId: string;
  name: string;
  o365Id: string;
  email: string;
  groupType?: GroupTypeEnum;
  isGuest?: boolean;
  risk: FileRiskEnum;
  riskInfo?: any;
  permission: FileShareLinkPermissionsTypeEnum;
  shareType: FileShareTypeEnum;
  automaticRevokeDate: number;
  isPasswordProtected: boolean;
  createdBy: string;
  createdAt: number;
  lastAccessed: string | number;
  expiresAt: number;
  linkUsers: string[];
  linkGroups: string[];
}

export interface UserSharedFile {
  id: string;
  createdAt: number;
  risk: FileRiskEnum;
  name: string;
  exposure: string;
  resourceName: string;
  lastAccessed: number;
  fileCreationDateLimit: number;
  isPending?: boolean;
  fileType: FileTypeEnum;
  silencingEnds?: number;
}

export interface ISharedFilesTendency {
  ['@timestamp']?: number;
  dailyTimestamp?: number;
  id?: string;
  organizationGuid: string;
  highCount: number;
  totalCount: number;
}

export interface ISharedFilesStats {
  highCount: number;
  moderateCount: number;
  lowCount: number;
}

export enum FileTypeEnum {
  FILE = 'File',
  FOLDER = 'Folder'
}

export interface IFilesTypesNumbers {
  riskHigh?: number;
  riskModerate?: number;
  publicShares?: number;
  organizationalShares?: number;
  groupShares?: number;
  guestShares?: number;
  memberShares?: number;
  sharedSince?: number;
  inactiveSince?: number;
}

export interface IFileActivity {
  activityType?: FileActivityTypeEnum;
  timestamp?: number;
  userEmail?: string;
  userName?: string;
  isAnonymous?: boolean;
  justification?: string;
  isSystem: boolean;
  silencingEnds: number;
}

export enum FileActivityTypeEnum {
  FILE_DOWNLOADED = 'FileDownloaded',
  FILE_ACCESSED = 'FileAccessed',
  FILE_MOVED = 'FileMoved',
  FILE_MODIFIED = 'FileModified',
  DEACTIVATION = 'DeactivateNotifications',
  ACTIVATION = 'ActivateNotifications'
}
