<ui-title
  [name]="'request.details.title.' + groupCreationDetails.groupType | t"
></ui-title>

<div class="margin--small--top margin--small--bottom">
  <form class="form" ui-form>
    <ui-field-group>
      <ui-switch-field-static
        [label]="'groups.table.column.name' | t"
        name="name"
        control="name"
        [value]="groupCreationDetails.name"
        [group]="detailsForm"
        [locale]="i18n.currentLocale"
        maxlength="256"
      ></ui-switch-field-static>
    </ui-field-group>

    <ui-field-group>
      <ui-switch-text-static
        [label]="'groups.table.column.description' | t"
        name="description"
        control="description"
        [value]="groupCreationDetails.description"
        [group]="detailsForm"
        maxlength="1024"
        [rowsNumber]="4"
      ></ui-switch-text-static>
    </ui-field-group>
  </form>

  <ui-field-group>
    <ui-static
      class="fullwidth"
      [label]="'group.detail.visibility.name' | t"
      [value]="'group.detail.visibility.' + groupCreationDetails.visibility | t"
    ></ui-static>
    <ui-static
      class="fullwidth"
      [label]="'groups.table.column.type' | t"
      [value]="groupCreationDetails.groupType | t"
    ></ui-static>
  </ui-field-group>

  <label ui-label>{{ 'group.detail.owners.name' | t }}</label>
  <ui-user-tags
    [users]="owners"
    [readOnly]="true"
  ></ui-user-tags>

  <ui-field-group class="spacing" *ngIf="securityModelName">
    <ui-switch-text-static
      [label]="'shares.gestions.security_model.label' | t"
      [value]="securityModelName"
    ></ui-switch-text-static>
  </ui-field-group>
</div>
