import { Component, EventEmitter, Input, Output } from '@angular/core';
import { expandCollapseAnimation } from 'projects/@common/modules/shared/animations/expand-collapse.animation';

@Component({
  selector: 'mobile-dashboard-card',
  templateUrl: './mobile-dashboard-card.component.html',
  styleUrls: [ './mobile-dashboard-card.component.scss' ],
  animations: [ expandCollapseAnimation() ],
})
export class MobileDashboardCardComponent {
  @Input() title;

  @Input() subtitle;

  @Input() isCollapsed = true;

  @Input() withTopMargin = false;

  @Output() isCollapsedChange: EventEmitter<boolean> = new EventEmitter();

  public emitCollapseState() {
    this.isCollapsedChange.emit(this.isCollapsed);
  }
}
