import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FeaturesEnum } from '@md.eco/interfaces/featureFlags';

@Component({
  selector: 'sg-pause-card',
  templateUrl: './pause-card.component.html',
  styleUrls: [ './pause-card.component.scss' ],
})
export class PauseCardComponent implements OnInit {
  @Input()
  public card: PauseCard;

  @Input()
  public disabled: boolean = false;

  @Output()
  public clickEvent: EventEmitter<PauseCardTypeEnum> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  public handleClick(type: PauseCardTypeEnum): void {
    this.clickEvent.emit(type);
  }

  public get validityTooltip(): string {
    return this.card.state === PauseCardStateEnum.ok ? 'common.compliant' : 'common.atRisk';
  }

  public get buttonLabel(): string {
    return this.disabled
      ? 'dashboard.md-eco.pause.card.confirmation.disabled'
      : 'dashboard.md-eco.pause.card.confirmation';
  }
}

export enum PauseCardTypeEnum {
  share = 'share',
  file = 'file',
  ressource = 'ressource',
  device = 'device'
}

export enum PauseCardStateEnum {
  warning = 'warning',
  ok = 'ok'
}

export class PauseCard {
  public flagRequired: string;

  public isLoadingIcon = false;

  constructor(
    public type: PauseCardTypeEnum,
    public title: string,
    public description: string,
    public state: PauseCardStateEnum,
    public isAvailable: boolean = true
  ) {
    this.flagRequired = this.mapTypeToFlag(type);
  }

  public toggleLoadingIcon(): void {
    this.isLoadingIcon = !this.isLoadingIcon;
  }

  public setStateToWarning(): void {
    this.state = PauseCardStateEnum.warning;
  }

  public setStateToOk(): void {
    this.state = PauseCardStateEnum.ok;
  }

  public get logo(): string {
    return `assets/favicons/icon_pause_${this.type}.svg`;
  }

  public get stateLogo(): string {
    return `assets/favicons/icon_pause_${this.state}.svg`;
  }

  private mapTypeToFlag(type: PauseCardTypeEnum): FeaturesEnum {
    switch (type) {
      case PauseCardTypeEnum.device:
        return FeaturesEnum.DEVICE_MANAGEMENT;
      case PauseCardTypeEnum.ressource:
        return FeaturesEnum.RESOURCE_MANAGEMENT;
      case PauseCardTypeEnum.share:
        return FeaturesEnum.SHARES_MANAGEMENT;
      case PauseCardTypeEnum.file:
        return FeaturesEnum.DLP_MANAGEMENT;
    }
  }
}
