<ui-title
    [name]="'request.details.title.guestUser.resource' | t : {'resourceName': resourceAddDetails.resourcesToAdd.name }"
></ui-title>

<div *ngIf="!isLoading" class="margin--small--top margin--small--bottom">
  <form class="form" ui-form>
    <ui-field-group>
      <ui-switch-field-static
        [label]="'groups.table.column.name' | t"
        name="name"
        control="name"
        [value]="resourceAddDetails.resourcesToAdd.name"
        [group]="detailsForm"
        [locale]="i18n.currentLocale"
        maxlength="256"
      ></ui-switch-field-static>
    </ui-field-group>

    <ui-field-group>
      <ui-switch-text-static
        [label]="'groups.table.column.description' | t"
        name="description"
        control="description"
        [value]="resourceAddDetails.description"
        [group]="detailsForm"
        maxlength="1024"
        [rowsNumber]="4"
      ></ui-switch-text-static>
    </ui-field-group>
  </form>

  <ui-field-group>
    <ui-static
      class="fullwidth"
      [label]="'group.detail.visibility.name' | t"
      [value]="'group.detail.visibility.' + resourceAddDetails.visibility | t"
    ></ui-static>
    <ui-static
      class="fullwidth"
      [label]="'groups.table.column.type' | t"
      [value]="resourceAddDetails.resourcesToAdd.type | t"
    ></ui-static>
  </ui-field-group>

  <label ui-label>{{ 'group.detail.owners.name' | t }}</label>
  <ui-user-tags
    [users]="owners"
    [readOnly]="true"
  ></ui-user-tags>

  <ui-field-group class="spacing" *ngIf="resourceAddDetails.modelName">
    <ui-switch-text-static
      [label]="'shares.gestions.security_model.label' | t"
      [value]="resourceAddDetails.modelName"
    ></ui-switch-text-static>
  </ui-field-group>
</div>

<div *ngIf="isLoading">
    <ui-spinner size="medium"></ui-spinner>
</div>
