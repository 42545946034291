<div
  class="drawer-detail--scrollable tab-menu request-form hidden"
>
  <ui-warning
    *ngIf="!isLoading"
    [warningMessages]="warningMessages"
  ></ui-warning>

  <div class="drawer-detail--content">
    <button
      *ngIf="includeReturnButton"
      ui-button
      class="request-form--return-button"
      icon="chevron-left"
      icon-size="medium"
      color="listActionFlat"
      (click)="handleReturnButtonClick()"
    >
      {{ 'common.action.return' | t }}
    </button>

    <ng-container *ngIf="isLoading">
      <ui-spinner size="medium" modifier="secondary"></ui-spinner>
    </ng-container>

    <ng-container *ngIf="!isLoading && request">
      <div *ngIf="request.canProcess" class="request-form--take-action-section">
        <div class="request-form--take-action-section--content">
            <request-form-process [request]="request" [showDeclineButton]="showDeclineButton"></request-form-process>
        </div>
      </div>

      <ui-title [name]="'requests.drawer.creation.title' | t"></ui-title>

      <div class="margin--small--top margin--small--bottom">
        <form class="form" ui-form>
          <ui-field-group>
            <ui-switch-field-static
              class="fullwidth"
              [label]="'requests.drawer.creation.object' | t"
              name="object"
              control="object"
              [value]="request.description | translatedObject"
              [group]="requestForm"
              maxlength="500"
            ></ui-switch-field-static>
          </ui-field-group>

          <ui-field-group>
            <ui-switch-text-static
              [label]="'requests.drawer.creation.justification' | t "
              name="justification"
              control="justification"
              [value]="request.justification | t "
              [group]="requestForm"
              maxlength="500"
              [rowsNumber]="4"
            ></ui-switch-text-static>
          </ui-field-group>
        </form>

        <div class="flex-box center-horizontal">
          <ui-field-group class="type-section">
            <ui-static
              [label]="'requests.drawer.creation.type' | t"
              [value]="'request.type.enum.' + request.type | t"
            ></ui-static>
          </ui-field-group>
        </div>

          <ui-field-group>
            <ui-static
                [label]="'request.drawer.form.status' | t"
                [value]="status"
                [iconName]="icon"
                [isSvg]="true"
                [isCustomIcon]="true"
                ></ui-static>
          </ui-field-group>

          <ui-field-group *ngIf="request.currentApprovers?.length">
            <div>
              <label ui-label>{{ 'request.drawer.form.currentApprovers' | t }}</label>
              <ui-user-tags [readOnly]="true" [users]="request.currentApprovers" ></ui-user-tags>
            </div>
          </ui-field-group>

        <div class="flex-box center-horizontal">
          <ui-field-group class="fullwidth">
            <ui-static
              [label]="'request.drawer.form.requester' | t"

              [value]="request.requesterName ? request.requesterName : ('common.system' | t)"
            ></ui-static>
          </ui-field-group>
        </div>

        <div class="flex-box center-horizontal">
          <ui-field-group class="fullwidth">
            <ui-static
              [label]="'request.drawer.form.requestDate' | t"
                [value]="request.createdAt | dt: {locale: i18nService.currentLocale}"
            ></ui-static>
          </ui-field-group>
          <ui-field-group class="fullwidth">
            <ui-static
              [label]="'request.drawer.form.requestUpdate' | t"
                [value]="request.updatedAt | dt: {locale: i18nService.currentLocale}" 
            ></ui-static>
          </ui-field-group>
        </div>
      </div>
    </ng-container>
  </div>
</div>
