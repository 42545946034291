<sg-page-layout
  [title]="'shares.gestions.title' | t"
  [documentationLink]="documentationSection"
  [breadcrumbs]="breadcrumbs"
  (onScrollToBottom)="loadMore()"
  (onTooltipEvent)="openDescription()"
>
  <sg-banners *ngIf="!initialLoading" [type]="getBannerType()" [text]="getBannerMessage()"></sg-banners>

  <ng-container *ngIf="!initialLoading; else spinner">
    <div [ngClass]="{'smaller-search': mobileService.isMobile(900)}" class="card-container" *ngIf="resourcesListService.userResourceAvailable">
      <div class="toolbar" *ngIf="!mobileService.isMobile()">
        <resource-quick-actions [hasResourceApprovers]="hasResourceApprovers" [hasArchiver]="hasArchiver"></resource-quick-actions>
        <div class="right-toolbar">
          <ng-container toolbar>
            <ui-table-total-tool [total]="resourcesListService.total ?? 0"></ui-table-total-tool>
            <ui-table-search-tool
              [query]="resourcesListService.textSearch"
              (clear)="resourcesListService.clearTextSearch()"
              (query)="resourcesListService.textSearchAction($event)"
              [placeholder]="'report-publicFiles.fulltext.placeholder' | t"
            ></ui-table-search-tool>
            <ui-multi-select
              *ngIf="multiSelectData"
              [multiSelectData]="multiSelectData"
              (onOptionSelect)="sortResources($event)"
            ></ui-multi-select>
            <ui-table-refresh-tool
              [loading]="resourcesListService.isLoading"
              (click)="refresh()"
            ></ui-table-refresh-tool>
          </ng-container>
        </div>
      </div>

      <div class="mobile-toolbar" *ngIf="mobileService.isMobile()">
        <div class="tools-container">
          <ui-table-search-tool
            class="search-tool"
            [query]="resourcesListService.textSearch"
            (clear)="resourcesListService.clearTextSearch()"
            (query)="resourcesListService.textSearchAction($event)"
            [placeholder]="'report-publicFiles.fulltext.placeholder' | t"
          ></ui-table-search-tool>
          <ui-multi-select
            *ngIf="multiSelectData"
            [multiSelectData]="multiSelectData"
            (onOptionSelect)="sortResources($event)"
            [leftOrientation]="mobileService.isMobile()"
          ></ui-multi-select>
          <ui-table-refresh-tool [loading]="resourcesListService.isLoading" (click)="refresh()"></ui-table-refresh-tool>
        </div>
        <ui-table-total-tool [total]="resourceTotal"></ui-table-total-tool>
      </div>

      <ng-container *ngIf="resourcesListService.userResourceAvailable">
        <ng-container *ngFor="let card of cards; let index = index">
          <sg-expendable-card (onToggle)="refreshCardStates()" [isOpen]="openedCards.includes(index)">
            <sg-resource-card-header
              header
              class="full-width"
              [info]="card"
            ></sg-resource-card-header>
            <sg-resource-card-content
              content
              class="full-width"
              [isOpen]="openedCards.includes(index)"
              [info]="card"
              (click)="openDialog($event, index)"
            ></sg-resource-card-content>
            <group-actions
              *ngIf="!mobileService.isMobile()"
              options
              (refreshEvent)="refresh()"
              [group]="resourcesListService.getOwnerGroupById(card.resourceId)"
              [name]="card.name"
              [groupType]="card.type"
              [hasArchiver]="hasArchiver"
            ></group-actions>
          </sg-expendable-card>
        </ng-container>
        <ui-spinner
          *ngIf="resourcesListService.isLoadingMore"
          class="spinner"
          size="medium"
          modifier="secondary"
        ></ui-spinner>
      </ng-container>

      <div class="empty" *ngIf="currentResourceTotal === 0">
        {{ 'shares.gestions.no-result' | t }}
      </div>
    </div>
  </ng-container>
  <div class="empty-resources-container" *ngIf="!resourcesListService.userResourceAvailable && !initialLoading">
    <p class="top-text">{{ 'shares.gestions.no-resources.1' | t }}</p>
    <p class="bottom-text">{{ 'shares.gestions.no-resources.2' | t }}</p>
    <img class="madame" src="assets/favicons/logo_madame_pc.svg" />
  </div>

  <div class="page-description" description>
    <teams-resources-description></teams-resources-description>
  </div>
</sg-page-layout>

<ng-template #spinner>
  <ui-spinner class="spinner-container" size="medium" modifier="secondary"></ui-spinner>
</ng-template>

