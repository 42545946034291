import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'base-dialog',
  templateUrl: './base-dialog.component.html',
  styleUrls: [ './base-dialog.component.scss' ],
})
export class BaseDialogComponent  {
  @Input() hasStepper = false;
  @Input() isEditing = false;
  @Input() title: string;
  @Input() description: string;
  @Output() onCancel = new EventEmitter();
}
