import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'quick-actions-description-slide',
  templateUrl: './quick-actions-description-slide.component.html',
  styleUrls: [ './quick-actions-description-slide.component.scss' ],
})
export class QuickActionsDescriptionSlideComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
