import { Injectable } from '@angular/core';
import { MobileDescriptionWrapperContainer } from '../app/pages/mobile-description-wrapper/mobile-description-wrapper.container';
import { MatDialog } from '@angular/material/dialog';
import { PageDescriptions } from '../app/shared/components/page-layout/page-descriptions.enum';

@Injectable({
  providedIn: 'root',
})
export class PageDescriptionService {
  constructor (private dialog: MatDialog) { }

  public openTooltip(pageDescription: PageDescriptions): void {
    if (pageDescription) {
      this.dialog.open(MobileDescriptionWrapperContainer, {
        maxWidth: '95%',
        maxHeight: '90vh',
        panelClass: ['custom-dialog', 'custom-height'],
        data: {
          pageDescription,
        },
      });
    }
  }
}
