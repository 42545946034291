<div
  class="drawer-detail--scrollable tab-menu request-details hidden"
>
  <div class="drawer-detail--content">
    <ng-container *ngIf="isLoading">
      <ui-spinner size="medium" modifier="secondary"></ui-spinner>
    </ng-container>

    <ng-container *ngIf="!isLoading && requestDetails">
      <ng-container [ngSwitch]="request.type">
        <group-creation-details
          *ngSwitchCase="requestTypeEnum.RESOURCE_CREATION"
          [groupCreationDetails]="requestDetails"
        ></group-creation-details>
        <resource-add-details
            *ngSwitchCase="requestTypeEnum.GUEST_RESOURCE_ADD"
          [resourceAddDetails]="requestDetails"
        ></resource-add-details>
      </ng-container>
    </ng-container>
  </div>
</div>
