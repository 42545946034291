export class DateUtils {
  public static daysPassedSince(passedDate: number): number {
    const dateNow = Date.now();
    const difference = dateNow - passedDate;
    return Math.ceil(difference / (1000 * 3600 * 24));
  }

  public static tomorrowDate() {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrow.setHours(0, 0, 0, 0);

    return tomorrow;
  }

  public static getDateFromATimestamp(numberOfTime: number): Date {
    const date = new Date(Date.now() + numberOfTime);
    date.setHours(0, 0, 0, 0);
    return date;
  }

}
