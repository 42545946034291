import { Injectable } from '@angular/core';
import { State, Store, Selector, StateContext, Action } from '@ngxs/store';
import { clone } from 'projects/@common/utils/utils';

export interface SearchBarStateModel {
  fulltext: string | null;
}

export class SetSearchQuery {
  static readonly type = '[SearchBar] Set Search Query';

  public constructor(public payload: { fulltext: string | null }) {}
}

export class ResetSearchQuery {
  static readonly type = '[SearchBar] Reset search query';
}

export class ClearSearchQuery {
  static readonly type = '[SearchBar] Clear search query';
}

export const initialState: SearchBarStateModel = {
  fulltext: null,
};

@State<SearchBarStateModel>({
  name: 'searchBar',
  defaults: clone(initialState),
})
@Injectable()
export class SearchBarState {
  public constructor(private readonly store: Store) {}

  @Selector()
  public static searchQuery(state: SearchBarStateModel): string {
    return state.fulltext;
  }

  @Action(SetSearchQuery)
  public setSearchQuery(ctx: StateContext<SearchBarStateModel>, action: SetSearchQuery): void {
    const { fulltext } = action.payload;

    ctx.patchState({
      fulltext,
    });
  }

  @Action(ResetSearchQuery)
  public resetSearchQuery(ctx: StateContext<SearchBarStateModel>): void {
    ctx.patchState({
      fulltext: null,
    });
  }

  @Action(ClearSearchQuery)
  public clearSearchBar(ctx: StateContext<SearchBarStateModel>): void {
    ctx.patchState({
      fulltext: null,
    });
  }
}
