import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { I18nService } from '@common/modules/i18n/i18n.service';
import { MobileService } from '@common/services/mobile.service';
import { IconUtil } from '@ui-kit/util/icon-util';
import { SensitiveFilesService } from 'projects/console-sg/src/app/pages/sensitive-files/sensitive-files-list/sensitive-files-service';
import { ISensitiveFileDrawerData } from '../sensitive-file-drawer/sensitive-file-drawer.container';

@Component({
  selector: 'sensitive-file-details',
  templateUrl: './sensitive-file-details.component.html',
  styleUrls: [ './sensitive-file-details.component.scss' ],
})
export class SensitiveFileDetailsComponent {
  @Input() file: ISensitiveFileDrawerData;
  @Input() public isLoading = false;

  constructor(public dialog: MatDialog, public i18n: I18nService, public mobileService: MobileService, public filesListService: SensitiveFilesService) {
  }

  public getFileIcon(fileName?: string): string {
    const extension = fileName?.substring(fileName?.lastIndexOf('.') + 1, fileName?.length);

    if (extension !== fileName) {
      return IconUtil.getFileIconFromFileExtension(extension);
    }

    return IconUtil.getFileIconFromFileExtension(null);
  }

  public get dataTypes(): string {
    let formattedDataTypes = '';
    for (const pattern of Object.keys(this.file?.patterns)) {
      formattedDataTypes += `${this.file?.patterns[pattern]} ${pattern}\n`;
    }
    return formattedDataTypes;
  }
}
