<div class="pause-container">
  <div class="title-section">
    <span>{{'dashboard.md-eco.pause.description.1' | t}}
      <img src="assets/favicons/icon_coffe-cropped.svg">
      {{'dashboard.md-eco.pause.description.2'| t}}</span>
  </div>
  <div [ngClass]="availableCards.length >= 4 ? 'card-section': 'card-section-small'">
    <ng-container *ngFor="let card of cards">
      <ng-container>
        <sg-pause-card
          class="pause-card"
          (clickEvent)="handleClickEvent($event)"
          *ngIf="card.isAvailable"
          [disabled]="isCardDisabled({flags : [card.flagRequired]})"
          [card]="card"
        ></sg-pause-card>
      </ng-container>
    </ng-container>
  </div>
</div>
