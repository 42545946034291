<div
    class="mobile-toolbar"
    >
    <div class="tools-container">
      <ui-table-search-tool
          class="search-tool"
          [query]="textSearch"
          (clear)="clearTextSearch()"
          (query)="textSearchAction($event)"
          [placeholder]="'report-publicFiles.fulltext.placeholder' | t"
          ></ui-table-search-tool>
      <ui-multi-select
          *ngIf="riskMultiSelectData"
          [multiSelectData]="riskMultiSelectData"
          [leftOrientation]="true"
          (onOptionSelect)="sortResources($event)"
          ></ui-multi-select>
      <ui-table-refresh-tool
          [loading]="isLoading"
          (click)="refresh()"
          ></ui-table-refresh-tool>
    </div>
  <div class="total-tool">
    <ui-table-total-tool [total]="resources.length"></ui-table-total-tool>
  </div>
</div>
<div class="resources-shares-mobile--container" *ngIf="resources?.length">
  <div
    *ngFor="let resource of resources"
    class="resources-shares-mobile--resource"
    (click)="handleClick(resource)"
  >
    <div class="resources-shares-mobile--resource-title">
      <shares-resource-avatar [resource]="resource"></shares-resource-avatar>
      <p class="resources-shares-mobile--resource-name">
        {{ resource.name }}
      </p>
      <div class="resources-shares-mobile--data--risk">
        <ui-dot-indicator [riskInfo]="convertAndMapFileRiskToSeverity(resource)"></ui-dot-indicator>
      </div>
    </div>
    <div class="resources-shares-mobile--data">
      <div class="resources-shares-mobile--data--table">
        <div class="resources-shares-mobile--data--table--headers">
          <div class="resources-shares-mobile--data--table--column-share">
            {{ 'shares.list.columns.shares.name' | t }}
          </div>
          <div class="resources-shares-mobile--data--table--column-week">
            {{ 'shares.list.columns.shares.delta7' | t }}
          </div>
          <div class="resources-shares-mobile--data--table--column-year">
            {{ 'shares.list.columns.shares.delta52' | t }}
          </div>
          <ui-tooltip *ngIf="!mobileService.isMobile(365)" icon="assets/favicons/icon_info_small.svg">
            <div class="resources-shares-mobile--tooltip--title">
              {{ 'shares.list.columns.shares.name' | t }}
            </div>
            <div class="resources-shares-mobile--tooltip--text">
              {{ 'shares.mobile.tooltip.shared' | t }}
            </div>
            <div class="resources-shares-mobile--tooltip--title">
              {{ 'shares.list.columns.shares.delta7' | t }}
            </div>
            <div class="resources-shares-mobile--tooltip--text">
              {{ 'shares.mobile.tooltip.delta7' | t }}
            </div>
            <div class="resources-shares-mobile--tooltip--title">
              {{ 'shares.list.columns.shares.delta52' | t }}
            </div>
            <div class="resources-shares-mobile--tooltip--text">
              {{ 'shares.mobile.tooltip.delta52' | t }}
            </div>
          </ui-tooltip>
        </div>
        <div class="resources-shares-mobile--data--table--row">
          <div class="resources-shares-mobile--data--table--column-share">
            {{ resource.files.fileShared }} {{ 'common.files' | t }}
          </div>
          <div class="resources-shares-mobile--data--table--column-week">
            <ui-indicator-arrow
              [value]="resource.files.file7"
              [includePlusSign]="false"
              fontColor="#313942"
              fontWeight="400"
            ></ui-indicator-arrow>
          </div>
          <div class="resources-shares-mobile--data--table--column-year">
            {{ resource.files.file52 }}
          </div>
        </div>
        <div class="resources-shares-mobile--data--table--row">
          <div class="resources-shares-mobile--data--table--column-share">
            {{ resource.folders.folderShared }} {{ 'common.folders' | t }}
          </div>
          <div class="resources-shares-mobile--data--table--column-week">
            <ui-indicator-arrow
              [value]="resource.folders.folder7"
              [includePlusSign]="false"
              fontColor="#313942"
              fontWeight="400"
            ></ui-indicator-arrow>
          </div>
          <div class="resources-shares-mobile--data--table--column-year">
            {{ resource.folders.folder52 }}
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
<ui-spinner *ngIf="isLoading"></ui-spinner>

<div
  *ngIf="!isLoading && !resources?.length"
  class="resources-shares-mobile--no-content"
>
  {{ 'shares.none.filter' | t }}
</div>
