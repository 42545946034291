import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { FeatureSharedModule } from 'projects/@common/modules/shared/feature.shared.module';
import { I18nModule } from 'projects/@common/modules/i18n/i18n.module';
import { GuestUserExpirationDateComponent } from './guest-user-expiration-date/guest-user-expiration-date.component';
import { UiKitModule } from '@ui-kit/uikit.module';

@NgModule({
  declarations: [ GuestUserExpirationDateComponent ],
  providers: [],
  imports: [
    UiKitModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NgxsFormPluginModule,
    FeatureSharedModule,
    I18nModule,
  ],
  exports: [ ],
})
export class TablesModule {
}
