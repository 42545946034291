<div class="container">
  <img class="image" src="assets/favicons/icon_women_computer_airplane.svg">
  <div>
    <p class="title">{{ 'groups.dialog.confirmation.request.sent' | t : { requestApprovers: formattedDefaultApprovers } }}.</p>
  </div>
  <br *ngIf="addLineBreak">
  <div class="custom-content">
    <ng-content></ng-content>
  </div>
  <p class="requests" (click)="goToRequests()"> {{ 'groups.dialog.confirmation.see.request' | t }}</p>

</div>
