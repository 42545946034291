import { UserTypeEnum } from "@ui-kit/components/autocomplete/user-autocomplete/user-autocomplete.component";
import { GroupTypeEnum } from "@ui-kit/util/icon-util";

export enum GroupTemplateStateEnum {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED'
}

export enum GroupTemplateLifecycleEnum {
  INACTIVITY = 'INACTIVITY',
  CREATION = 'CREATION'
}

export enum GroupTemplateScopeEnum {
  GLOBAL = 'GLOBAL',
  ORGANIZATIONAL = 'ORGANIZATIONAL'
}

export enum DefaultSiteSharingLinkTypeEnum {
  TENANT_DEFAULT = 0,
  SPECIFIC_PEOPLE = 1,
  ONLY_ORGANIZATION = 2,
  ANYONE = 3,
  EXISTING_ACCESS = 4
}

export interface IGroupTemplatePermissions {
  memberSettings?: {
    allowCreateUpdateChannels?: boolean;
    allowDeleteChannels?: boolean;
    allowGuestCreateUpdateChannels?: boolean;
    allowGuestDeleteChannels?: boolean;
    allowAddRemoveApps?: boolean;
    allowCreateUpdateRemoveTabs?: boolean;
    allowCreateUpdateRemoveConnectors?: boolean;
  };
  messagingSettings?: {
    allowUserEditMessages?: boolean;
    allowUserDeleteMessages?: boolean;
    allowOwnerDeleteMessages?: boolean;
    allowTeamMentions?: boolean;
    allowChannelMentions?: boolean;
  };
  funSettings?: {
    allowGiphy?: boolean;
    allowStickersAndMemes?: boolean;
    allowCustomMemes?: boolean;
  };
}

export interface IGroupTemplateFolder {
  name: string;
  alreadyExisting?: boolean;
  subFolders?: IGroupTemplateFolder[];
}

export interface IGroupTemplateChannel {
  name: string;
  description: string;
  isPrivate: boolean;
  alreadyExisting?: boolean;
  folders?: IGroupTemplateFolder[];
  ownersGuid?: string[];
}

export interface IGroupTemplateLifecycle {
  expirationTrigger: GroupTemplateLifecycleEnum;
  expirationDuration: number;
  notifyMembersInAdvance: boolean;
  notifyGuestUsersInAdvance: boolean;
  archiveConversations: boolean;
}

export interface IGroupTemplateSharepoint {
  pnpTemplate: string;
  pnpTemplateName: string;
  hubSiteId: string;
  hubSiteName: string;
  hubSiteGroups: IGroupHub[];
}

export interface IGroupHub {
  groupName: string;
  groupUsers: any[];
}

export enum GroupTemplateReach {
  GLOBAL = 'global',
  SEGMENTED = 'segment'
}

export interface IGroupTemplate {
  guid: string;
  id: string;
  name: string;
  description: string;
  type: GroupTypeEnum;
  state?: GroupTemplateStateEnum;
  updatedAt?: number;
  updatedBy?: any;
  permissions?: IGroupTemplatePermissions;
  folders?: IGroupTemplateFolder[];
  channels?: IGroupTemplateChannel[];
  lifecycle?: IGroupTemplateLifecycle;
  scope?: GroupTemplateScopeEnum;
  defaultSiteShareType?: DefaultSiteSharingLinkTypeEnum;
  certifyExpirationDuration?: number;
  sharepoint?: IGroupTemplateSharepoint;
  defaultApprovers?: IDefaultApprover[];
  reach?: GroupTemplateReach;
  attribute?: string;
}

export interface IDefaultApprover {
  firstName: string;
  o365UserId?: string;
  guid?: string;
  type?: UserTypeEnum;
  mail?: string
}

export interface IArchiveManager extends IDefaultApprover {}

export interface IBackupLocation {
  displayName: string;
  o365GroupId?: string;
  guid?: string;
}
