<div class="overflow">
  <div class="content">
    <ng-container [ngSwitch]="data.pageDescription">
      <ng-container *ngSwitchCase="pageDescriptions.MY_SHARES">
        <shares-description (closing)="close()"></shares-description>
      </ng-container>
      <ng-container *ngSwitchCase="pageDescriptions.RESOURCES_SHARES">
        <shares-resources-description (closing)="close()"></shares-resources-description>
      </ng-container>
      <ng-container *ngSwitchCase="pageDescriptions.TEAMS_RESOURCES">
        <teams-resources-description (closing)="close()"></teams-resources-description>
      </ng-container>
      <ng-container *ngSwitchCase="pageDescriptions.DEVICES">
        <device-conformity-description (closing)="close()"></device-conformity-description>
      </ng-container>
      <ng-container *ngSwitchCase="pageDescriptions.FILES">
        <sensitive-files-description (closing)="close()"></sensitive-files-description>
      </ng-container>
    </ng-container>
    <img
      class="close"
      (click)="close()"
      alt="close-icon"
      src="assets/favicons/close.svg"
    />
  </div>
</div>
