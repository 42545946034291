<base-dialog [title]="'guests.quickAction.title' | t" (onCancel)="dialogRef.close()">
  <div class="container">
    <div>
      <div class="title">
        <p>
        {{ 'guests.quickAction.description' | t }}
        </p>
        <div class="title-with-documentation">
          <h4>{{ 'guests.quickAction.subtitle' | t }} </h4>
          <a
              class="doc-button desktop"
              [href]="documentationLink"
              target="_blank"
              ></a>
        </div>
        <hr>
      </div>
            <ui-field-group>
              <ui-field 
                [label]="'guests.steps.label.email' | t" 
                [placeholder]="'guests.quickAction.search' | t"
                name="email" 
                control="email" 
                [required]="true" 
                [group]="emailForm" 
                [forceInvalid]="false" 
                [locale]="locale" 
              ></ui-field>
            </ui-field-group>
      <p *ngIf="!guestExists && emailForm.valid">
      {{ 'guests.quickAction.search.noGuestFound.message' | t }} 
      </p>
      <p *ngIf="guestExists && emailForm.valid">
      {{ 'guests.quickAction.search.guestFound.message' | t }} 
      </p>
    </div>
    <div class="continueButton">
      <button 
        *ngIf="!buttonLoading"
        ui-button 
        [disabled]="!(!guestExists && emailForm.valid)" 
        [loading]="isLoading" 
        color="standard" 
        (click)="onInvite()"
      >
      {{ 'guests.quickAction.button.access.demand.form' | t }}
      </button>
      <ui-spinner
        *ngIf="buttonLoading"
        size="medium"
        modifier="secondary"
      ></ui-spinner>
    </div>
  </div>
</base-dialog>
