<div class="title-wrapper">
  <p class="title">{{'sensitive.description.title' | t}}</p>
  <img src="assets/favicons/icon_protection.svg">
</div>
<p class="text bold">{{ 'sensitive.description.text.subtext1' | t }} </p>
<p class="subtitle">{{ 'shares.description.text.indicators.subtitle' | t }}</p>
<div class="list">
  <div class="listElement">
    <div class="indicator">
      <ui-dot-indicator [riskInfo]="{'risk': 'high', 'translatedRisk': 'HIGH RISK'}"></ui-dot-indicator>
    </div>
    <p [ngStyle]="{'color': '#DE3618'}">{{ 'shares.shareRiskScore.high' | t }} </p>
  </div>
  <p class="list-element-description">{{ 'sensitive.description.text.indicators.high.text' | t }} </p>

  <div class="listElement">
    <div class="indicator">
      <ui-dot-indicator [riskInfo]="{'risk': 'moderate', 'translatedRisk': 'HIGH RISK'}"></ui-dot-indicator>
    </div>
    <p [ngStyle]="{'color': '#FF7800'}">{{ 'shares.shareRiskScore.moderate' | t }} </p>
  </div>
  <p class="list-element-description">{{ 'sensitive.description.text.indicators.medium.text' | t }} </p>

  <div class="listElement">
    <div class="indicator">
      <ui-dot-indicator [riskInfo]="{'risk': 'low', 'translatedRisk': 'HIGH RISK'}"></ui-dot-indicator>
    </div>
    <p [ngStyle]="{'color': '#0BB786'}">{{ 'shares.shareRiskScore.low' | t }} </p>
  </div>
  <p class="list-element-description">{{ 'sensitive.description.text.indicators.low.text' | t }} </p>

  <div class="listElement">
    <div class="indicator">
      <ui-dot-indicator [riskInfo]="{'risk': 'none', 'translatedRisk': 'HIGH RISK'}"></ui-dot-indicator>
    </div>
    <p [ngStyle]="{'color': '#999999'}">{{ 'sensitive.description.text.indicators.justified.title' | t }} </p>
  </div>
  <p class="list-element-description">{{ 'sensitive.description.text.indicators.justified.description' | t }} </p>

</div>
