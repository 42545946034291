<div class="container">
  <form [formGroup]="informationForm">

    <div class="resource-wrapper">
      <div class="label-with-i">
        <label class="strong-label">{{'groups.dialog.info.type.label' | t}}</label>
        <ui-tooltip>
          <label class="strong-label strong-label--tooltip">{{'groups.dialog.info.type.tooltip.team.label' | t}}</label>
          <p>{{'groups.dialog.info.type.tooltip.team.text' | t}}</p>
          <br>
          <label class="strong-label strong-label--tooltip">{{'groups.dialog.info.type.tooltip.sharepoint.label' |
            t}}</label>
          <p>{{'groups.dialog.info.type.tooltip.sharepoint.text' | t}}</p>
        </ui-tooltip>
      </div>
      <div class="resource-checkbox-container">
        <mat-radio-group
          class="group"
          formControlName="groupType"
        >
          <mat-radio-button [value]="groupTypeEnum.O365OFFICETEAMS">
            <div class="label-img">
              <img src="assets/favicons/icon_teams.svg">
              <p class="no-margin">{{'groups.dialog.info.radio.team' | t}}</p>
            </div>
          </mat-radio-button>
          <mat-radio-button [value]="groupTypeEnum.O365OFFICEGROUP">
            <div class="label-img">
              <img src="assets/favicons/icon_resource_sharepoint.svg">
              <p class="no-margin">{{'groups.dialog.info.radio.sharepoint' | t}}</p>
            </div>
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>

    <div class="sub-section">
      <label class="strong-label">{{'groups.dialog.info.resource_creation.label' | t}}</label>
      <ui-field-group>
        <ui-field
          [label]="'groups.list.columns.name.name' | t"
          name="name"
          control="name"
          [required]="true"
          [group]="informationForm"
          [locale]="i18nService.currentLocale"
          maxlength="50"
        ></ui-field>
      </ui-field-group>

      <ui-field-group>
        <ui-text-field
          [label]="'groups.list.columns.description.name' | t"
          name="description"
          control="description"
          [required]="true"
          [group]="informationForm"
          [rowsNumber]="4"
          maxlength="1024"
        ></ui-text-field>
      </ui-field-group>

      <div class="resource-wrapper">
        <div class="label-container">
          <label ui-label>{{'groups.dialog.info.visibility.label' | t}}</label>
          <ui-tooltip>
            <ng-container *ngTemplateOutlet="visibilityTooltip"></ng-container>
          </ui-tooltip>
        </div>

        <div class="resource-checkbox-container">
          <mat-radio-group
            class="group"
            formControlName="visibility"
          >
            <mat-radio-button [value]="groupVisibilityEnum.PRIVATE">
              <div class="label-img">
                <p class="no-margin">{{'groups.dialog.info.radio.visibility.member' | t}}</p>
              </div>
            </mat-radio-button>
            <mat-radio-button [value]="groupVisibilityEnum.PUBLIC">
              <div class="label-img">
                <p class="no-margin">{{'groups.dialog.info.radio.visibility.org' | t}}</p>
              </div>
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <div class="normal-wrapper">
        <div class="label-container">
          <label ui-label>{{'groups.dialog.info.expiration.label' | t}}</label>
          <ui-tooltip>{{'groups.dialog.info.expiration.tooltip' | t}}</ui-tooltip>
        </div>

        <div class="resource-checkbox-container">
          <p class="no-margin">{{'groups.dialog.info.expiration.text' | t}}</p>
        </div>
      </div>

      <div class="sub-section">
        <div class="field-margin">
          <label
            ui-label
            [required]="true"
          >{{
            'group.detail.owners.name' | t
            }}</label>
          <autocomplete
            #userAutocomplete
            name="userAutocomplete"
            required="true"
            maxlength="50"
            [canMultiSelect]="true"
            [placeholder]="'group.detail.owners.search.name' | t"
            [itemsToIgnore]="groupOwners"
            [filter]="o365UsersFilter"
            [(selectedItems)]="groupOwners"
            [oneItemAtATime]="true"
            [searchFunction]="usersAutocompleteSearchFunction"
            (onItemSelected)="addOwner($event)"
            [autocompleteType]="usersAutocomplete"
            [locale]="i18nService.currentLocale"
            [toggleDropdownAfterAllSelection]="true"
            expandHeight="8rem"
          >
          </autocomplete>
          <ui-user-tags
            [users]="groupOwners"
            (onDeleteUser)="removeOwner($event)"
            [readOnly]="groupOwners.length === 1"
          ></ui-user-tags>
        </div>
        <p class="normal-font-size">{{'groups.dialog.info.owner_descrption.text' | t}}</p>
      </div>

    </div>
  </form>
</div>

<ng-template #visibilityTooltip>
  <div>
    <div class="flex-box center-horizontal margin--small--bottom visibility-icon">
      <img
        class="tooltip-visibility-icon"
        src="assets/favicons/icon_visibility.svg"
        alt=""
      />
      <p>{{ 'visibility.tooltip.title' | t }}</p>
    </div>
    <div>
      <div class="margin--small--bottom">
        <span>{{
          'visibility.tooltip.organization.subtitle' | t
          }}</span>
        <p>
          {{ 'visibility.tooltip.organization.description' | t }}
        </p>
      </div>
      <div>
        <span>{{ 'visibility.tooltip.member.subtitle' | t }}</span>
        <p>{{ 'visibility.tooltip.member.description' | t }}</p>
      </div>
    </div>
  </div>
</ng-template>