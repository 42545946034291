import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { color } from '@amcharts/amcharts4/core';
import { Router } from '@angular/router';
import { ResourceSharesApiService } from 'projects/@common/services/api/sg/resourceSharedFiles/resource-shares-api.service';
import { Notice, NoticeLevels, NoticeService } from 'projects/@common/modules/notice/notice.service';
import { TeamsApiService } from 'projects/@common/services/api/sg/teams/teams-api.service';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { SharesApiService } from 'projects/@common/services/api/sg/shares/shares-api.service';
import { IResourceSharedFilesStats, FileRiskEnum } from 'projects/@common/services/api/sg/shares/shares.definitions';
import { MobileService } from 'projects/@common/services/mobile.service';
import { UiStaticTableRowType } from '@ui-kit/components/ui-static-table/ui-static-table-row/ui-static-table-row.component';
import { DTPipe } from '@ui-kit/pipes/dt.pipe';

@Component({
  selector: 'my-shared-assets',
  templateUrl: './my-shared-assets.component.html',
  styleUrls: [ './my-shared-assets.component.scss' ],
})
export class MySharedAssetsComponent implements OnInit {
  public stats: IResourceSharedFilesStats;

  public isLoading = false;

  public isLoadingTable = false;

  public fileRiskEnum = FileRiskEnum;

  public riskFilter = FileRiskEnum.HIGH;

  public isAutomaticRevokeActivated: boolean;

  public columnDef: any[];

  public riskProportionsData: any[] = [];

  public isMobile = false;

  @Output() public onStatsLoaded = new EventEmitter<IResourceSharedFilesStats>();

  constructor(
    private sharesService: SharesApiService,
    private resourceSharesService: ResourceSharesApiService,
    private router: Router,
    private i18n: I18nService,
    private mobileService: MobileService,
    private noticeService: NoticeService,
    private teamsService: TeamsApiService
  ) {}

  public ngOnInit(): void {
    this.isMobile = this.mobileService.isMobile();
    this.isLoading = true;
    this.sharesService
      .getAutomaticRevokeActivated()
      .then((automaticRevoke) => {
        this.isAutomaticRevokeActivated = automaticRevoke.isActivated;
      })
      .finally(() => {
        this.handleColumnDef();
      });
    this.refresh();
  }


  private refresh(): void {
    this.resourceSharesService
      .getResourceMonitoringStats({ risk: this.riskFilter })
      .then((stats) => {
        this.onStatsLoaded.emit(stats);
        this.stats = stats;
        this.formatData();
        this.handleColumnDef();
      })
      .catch(() => {
        this.noticeService.notifyUser(new Notice('common.error.retry', NoticeLevels.ERROR));
      })
      .finally(() => {
        this.isLoading = false;
        this.isLoadingTable = false;
      });
  }

  private formatData() {
    this.riskProportionsData = [];

    this.riskProportionsData.push({
      level: this.i18n.translate('shares.shareRiskScore.high'),
      count: this.stats.highCount,
      color: color('#DE3618'),
    });

    this.riskProportionsData.push({
      level: this.i18n.translate('shares.shareRiskScore.moderate'),
      count: this.stats.moderateCount,
      color: color('#ff7800'),
    });

    this.riskProportionsData.push({
      level: this.i18n.translate('shares.shareRiskScore.low'),
      count: this.stats.lowCount,
      color: color('#00d873'),
    });
  }

  private handleColumnDef(): void {
    this.columnDef = [
      {
        label: this.i18n.translate('dashboard.resourcesMonitoring.table.resources'),
        field: 'resource',
        type: UiStaticTableRowType.TEXT,
        noSorting: true,
        isResizable: false,
      },
    ];
    if (this.riskFilter === FileRiskEnum.HIGH && this.isAutomaticRevokeActivated) {
      this.columnDef.push({
        label: '',
        field: 'fake',
        type: UiStaticTableRowType.TEXT,
        noSorting: true,
        isResizable: false,
        width: '25px',
        headerIcon: 'icon_attention',
        headerIconWidth: '17px',
        headerIconContainerWidth: '0',
        headerIconMarginBottom: '10px',
      });
      this.columnDef.push({
        label: this.i18n.translate('dashboard.resourcesMonitoring.table.revokeDate'),
        field: 'nearestRevokeDate',
        type: UiStaticTableRowType.PIPED_FIELD,
        pipe: DTPipe,
        noSorting: true,
        isResizable: false,
        width: '150px',
        color: '#e24e35',
      });
    }
  }

  public handleRiskFilter(risk: FileRiskEnum): void {
    this.riskFilter = risk;
    this.isLoadingTable = true;
    this.refresh();
  }

  public async handleManageClick(): Promise<void> {
    const queryParams = {
      resourcesRiskFilter: this.riskFilter,
      tabIndexSelected: 1,
    };

    this.router.navigate([ '/myshares' ], {
      queryParams,
    });
  }
}
