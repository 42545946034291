<div [ngStyle]="{ height: stepperHeight, minWidth: minWidth , maxWidth: maxWidth}" style="position: relative">
  <mat-horizontal-stepper [@.disabled]="true" linear #stepper>
    <mat-step *ngFor="let step of steps; let i = index; let first = first; let last = last">
      <ng-template matStepLabel>{{ step.stepName }}</ng-template>

      <div class="stepper-container fixed-height" [class.guest-fixed-height]="isGuestUserType" *ngIf="stepper?.selectedIndex === i">
        <ng-container [ngTemplateOutlet]="step.templateRef"></ng-container>
      </div>
    </mat-step>
    <ng-template matStepperIcon="number"></ng-template>
    <ng-template matStepperIcon="edit"></ng-template>
  </mat-horizontal-stepper>
  <div class="stepper-buttons">
    <ui-spinner *ngIf="isSaving"></ui-spinner>
    <ng-container *ngIf="!isSaving">
      <ng-container *ngIf="steps.get(stepper.selectedIndex).customFooter">
        <ng-template
          [ngTemplateOutlet]="steps.get(stepper.selectedIndex).customFooter"
        >
       </ng-template>
      </ng-container>
      <button
        *ngIf="stepper.selectedIndex !== 0 && steps.get(stepper.selectedIndex).canGoBack"
        ui-button
        color="secondary"
        (click)="goBack()"
        >
        {{ 'common.action.previous' | t }}
      </button>
      <button
        *ngIf="stepper.selectedIndex !== (steps.length - 1) && !steps.get(stepper.selectedIndex).saveButton"
        ui-button
        [ngClass]="{ initialButton: stepper.selectedIndex === 0}"
        [disabled]="!steps.get(stepper.selectedIndex).canGoForward"
        color="standard"
        (click)="goForward()"
        >
        {{ 'common.action.next' | t }}
      </button>
      <button
        *ngIf="stepper.selectedIndex === (steps.length - 1)"
        ui-button
        color="standard"
        [disabled]="saveButtonDisabled"
        (click)="save()">
        {{ 'create.group.step.submit' | t }}
      </button>
      <div class="cancel-button">
        <button ui-button color="secondary" (click)="cancel()">
          {{ 'common.action.cancel' | t }}
        </button>
      </div>
    </ng-container>
  </div>
</div>
