/* eslint-disable no-restricted-properties */
import { Pipe, PipeTransform } from '@angular/core';
import { I18nService } from '../i18n/i18n.service';

@Pipe({
  name: 'fileSize',
})
export class FileSizePipe implements PipeTransform {
  private readonly MAX_BYTE_VALUE = 1024;

  private readonly UNITS_VALUES = {
    BYTES: {
      min: 1,
      max: this.MAX_BYTE_VALUE,
      unit: 'bytes',
    },
    KILOBYTES: {
      min: this.MAX_BYTE_VALUE,
      max: Math.pow(this.MAX_BYTE_VALUE, 2),
      unit: 'kilobytes',
    },
    MEGABYTES: {
      min: Math.pow(this.MAX_BYTE_VALUE, 2),
      max: Math.pow(this.MAX_BYTE_VALUE, 3),
      unit: 'megabytes',
    },
    GIGABYTES: {
      min: Math.pow(this.MAX_BYTE_VALUE, 3),
      max: Math.pow(this.MAX_BYTE_VALUE, 4),
      unit: 'gigabytes',
    },
    TERABYTES: {
      min: Math.pow(this.MAX_BYTE_VALUE, 4),
      max: Math.pow(this.MAX_BYTE_VALUE, 5),
      unit: 'terabytes',
    },
  };

  constructor(private i18n: I18nService) {}

  public transform(value: any): string {
    if (value == null) {
      return '-';
    }

    for (const unitValues in this.UNITS_VALUES) {
      if (value < this.UNITS_VALUES[unitValues].max || this.UNITS_VALUES[unitValues].unit === 'terabytes') {
        const size = value / this.UNITS_VALUES[unitValues].min;
        const unit = this.i18n.translate(`file.size.${this.UNITS_VALUES[unitValues].unit}`);

        // toFixed(2) remove decimals to keep only 2 and the + remove the extras 0 if it is something like : 1.00 --> 1
        return +size.toFixed(2) + unit;
      }
    }

    return value;
  }
}
