import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { StaticTableDataMapper } from '@common/modules/i18n/component-wrapper/static-table-data-mapper.service';
import { I18nService } from '@common/modules/i18n/i18n.service';
import { DrawerPageEnum } from '@common/modules/layout/components/drawer/drawerPage.enum';
import { DrawerService } from '@common/modules/layout/components/drawer/services/drawer.service';
import { NoticeService } from '@common/modules/notice/notice.service';
import { SensitiveFilesApiService } from '@common/services/api/sg/files/sensitive-files-api.service';
import { UserSharedFile } from '@common/services/api/sg/shares/shares.definitions';
import { UiStaticTableRowType } from '@ui-kit/components/ui-static-table/ui-static-table-row/ui-static-table-row.component';
import { UiTableDirection } from '@ui-kit/components/ui-table/ui-table.component';
import { LanguageEnum } from '@ui-kit/interfaces/ILanguage';
import { IconUtil } from '@ui-kit/util/icon-util';
import { ISensitiveFileDrawerData, SensitiveFileDrawer } from 'projects/@assist-ops/components/drawer/sensitive-file/sensitive-file-drawer/sensitive-file-drawer.container';
import { RiskBadgeComponent } from 'projects/@assist-ops/components/risk-badge/risk-badge.component';
import { SensitiveFilesActionsDialogComponent } from 'projects/@assist-ops/components/sensitive-files-actions-dialog/sensitive-files-actions-dialog.component';

export interface ISensitiveDataList {
  fileId: string;
  name: string;
  path: string;
  createdAt: number;
  sourceType: string;
  risk: string;
  lastScanned: number;
  upn: string;
  likelihood: number;
  hasJustification: boolean;
  fullName: string;
  oid: string;
  patterns: string;
  dlpType?: string;
}

@Injectable({
  providedIn: 'root',
})
export class SensitiveFilesService {
  public initialLoading = true;
  public isLoading = true;
  public from = 0;
  public sort = 'name';
  public direction: UiTableDirection = UiTableDirection.Asc;
  public total = 0;
  public fulltext = null;
  public datasource: ISensitiveDataList[] = [];
  public selected: ISensitiveDataList;
  private readonly SELECTED_TAG = '__Selected';
  public columnsDef: any[] = [
    {
      label: '',
      field: 'selected',
      selected: this.SELECTED_TAG,
      type: UiStaticTableRowType.CHECKBOX,
      noSorting: true,
      width: '35px',
      isResizable: false,
    },
    {
      label: 'common.name',
      field: 'name',
      type: UiStaticTableRowType.ICON_WITH_LINK,
      getIcon: this.getFileIcon,
      isTruncated: true,
      minWidth: '200px',
    },
    {
      label: 'Source',
      field: 'sourceType',
      translationKeyPrefix: 'sensitive.sourceType.',
      type: UiStaticTableRowType.TRANSLATED_TEXT,
      width: '100px',
      isResizable: false,
    },
    {
      label: 'common.risk',
      field: 'risk',
      type: UiStaticTableRowType.DYNAMIC_COMPONENT_HOST,
      component: RiskBadgeComponent,
      paramName: 'resource',
      noSorting: true,
      width: '100px',
      isResizable: false,
    },
    {
      label: 'sensitiveFiles.types.column',
      field: 'patterns',
      type: UiStaticTableRowType.TEXT,
      width: '250px',
      noSorting: true,
      isTruncated: true,
    },
    {
      label: 'common.lastScanned',
      field: 'lastScanned',
      type: UiStaticTableRowType.DATE,
      width: '170px',
    },
  ];

  constructor(
    public dialog: MatDialog,
    public i18n: I18nService,
    private drawerService: DrawerService,
    private staticTableDataMapper: StaticTableDataMapper,
    private sensitiveFilesApiService: SensitiveFilesApiService,
    private noticeService: NoticeService
  ) {
  }

  public init() {
    this.sort = 'name';
    this.direction = UiTableDirection.Asc;
    this.fulltext = null;
    this.selected = null;
  }

  public onAction(data: ISensitiveFileDrawerData | ISensitiveDataList, isFromList: boolean): void {
    this.dialog.open(SensitiveFilesActionsDialogComponent, {
      data: { ...data, isFromList },
      maxWidth: '50vw',
      maxHeight: '85vh',
      panelClass: 'custom-dialog',
    }).afterClosed().subscribe((response) => {
      if (response?.type) {
        this.isLoading = true;
        this.hideData();
        this.sensitiveFilesApiService.action(data.fileId, response).then(() => {
          this.noticeService.success(`sensitiveFile.${response?.type}.success`);
        }).catch(() => {
          this.noticeService.error('common.error.retry');
        }).finally(() => {
          this.isLoading = false;
          this.init();
          this.drawerService.hideDrawer();
          this.handleRefresh();
        });
      }
    });
  }

  public handleRowClick(file: any) {
    this.drawerService.replaceCurrentDrawer(SensitiveFileDrawer, {
      component: DrawerPageEnum.sensitiveFileDrawer,
      data: { id: file.fileId, title: file.name },
    });
  }

  public handleCheckboxEvent(isSelected: any, data: any) {
    this.selected = isSelected ? data : null;
  }

  public handleSort(sort: string, direction: UiTableDirection) {
    this.sort = sort;
    this.direction = direction;
    this.handleRefresh();
  }

  public onScroll() {
    if (this.datasource.length < this.total) {
      this.load();
    }
  }

  public handleSearchClear() {
    this.fulltext = null;
    this.handleRefresh();
  }

  public handleSearchQuery(fulltext: string) {
    this.fulltext = fulltext;
    this.handleRefresh();
  }

  public hideData() {
    this.from = 0;
    this.total = 0;
    this.datasource = [];
  }

  public handleRefresh() {
    this.from = 0;
    this.total = 0;
    this.datasource = [];
    this.load();
  }

  public getFileIcon(element: UserSharedFile): string {
    const extension = element.name.substring(element.name.lastIndexOf('.') + 1, element.name.length);

    if (extension === element.name) {
      return IconUtil.getFileIconFromFileExtension(null);
    }
    return IconUtil.getFileIconFromFileExtension(extension);
  }

  private load() {
    if (this.isLoading && !this.initialLoading) {
      return;
    }
    this.isLoading = true;
    const params = {
      language: this.i18n.currentLocale as LanguageEnum,
      skip: this.from || 0,
      size: 40,
      sort: this.sort,
      direction: this.direction,
      search: this.fulltext,
    };

    this.sensitiveFilesApiService
      .listSensitiveFiles(params)
      .then((response) => {
        this.total = response.total;
        this.datasource = [...this.datasource, ...response.items.map((item) => ({ ...item, id: item.fileId }))];
        const nextToken = +response.nextItem;
        this.from = Number.isNaN(nextToken) ? null : nextToken;
      })
      .finally(() => {
        [this.columnsDef, this.datasource] = this.staticTableDataMapper.translate(this.columnsDef, this.datasource);
        this.initialLoading = false;
        this.isLoading = false;
      });
  }
}
