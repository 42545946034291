<div
  class="card actions-container"
  *ngIf="desktopDisplay; else mobile"
>
  <img
    class="actions-container--coffe-guy"
    src="assets/favicons/icon_computer_guy_blue.svg"
  >
  <div class="title-container">
    <p class="actions-container--title" [innerHTML]="'dashboard.md-eco.quickAction.title' | t "></p>
    <a
      class="doc-button"
      [href]="documentationSection"
      target="_blank"
    ></a>
  </div>

  <div class="actions-container--description-section wrap" [innerHTML]="'dashboard.md-eco.quickAction.description' | t">
  </div>
  <div class="selectors">
    <sg-quick-actions-selector
      #quickActionsSelector
      [groupAmount]="groupAmount"
      [hasArchiver]="hasArchiver"
    ></sg-quick-actions-selector>
  </div>
</div>

<ng-template #mobile>
  <div class="card actions-mobile-container">
    <img
      class="coffe-guy-mobile"
      src="assets/favicons/icon_computer_guy_blue.svg"
    >
    <p class="gras">{{'dashboard.md-eco.quickAction.description.unavailable' | t}}</p>

    <p>{{'dashboard.md-eco.quickAction.description.unavailable_bottom' | t}}</p>
  </div>
</ng-template>
