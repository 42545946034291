import { ValidatorFn, AbstractControl } from '@angular/forms';
import { UrlUtils } from 'projects/@common/utils/utils';

export class EmailUtil {
  public static emailValidation(context: any): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value && control.value.length > 0) {
        const regexp = new RegExp(
          // eslint-disable-next-line no-useless-escape
          /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
        if (!regexp.test(control.value)) {
          context.invalidEmail = true;
          return { invalidEmail: true };
        }
      }
      context.invalidEmail = false;
      return null;
    };
  }

  public static adaptStringAsEmailAlias(inputString: string) {
    const regexA = new RegExp(`[ÀÁÂÃÄàáâãäĀāĂăĄą]`, 'g');
    const regexC = new RegExp(`[ÇçĆćĈĉĊċČč]`, 'g');
    const regexD = new RegExp(`[ÐðĎďĐđ]`, 'g');
    const regexE = new RegExp(`[ÈÉÊËèéêëĒēĔĕĖėĘęĚě]`, 'g');
    const regexG = new RegExp(`[ĜĝĞğĠġĢģ]`, 'g');
    const regexH = new RegExp(`[ĤĥĦħ]`, 'g');
    const regexI = new RegExp(`[ÌÍÎÏìíîïĨĩĪīĬĭĮįİı]`, 'g');
    const regexJ = new RegExp(`[Ĵĵ]`, 'g');
    const regexK = new RegExp(`[Ķķ]`, 'g');
    const regexL = new RegExp(`[ĹĺĻļĽľĿŀŁł]`, 'g');
    const regexN = new RegExp(`[ÑñŃńŅņŇňŉŊŋ]`, 'g');
    const regexO = new RegExp(`[ÒÓÔÕòóôõŌōŎŏŐő]`, 'g');
    const regexQ = new RegExp(`[ĸ]`, 'g');
    const regexR = new RegExp(`[ŔŕŖŗŘř]`, 'g');
    const regexS = new RegExp(`[ŚśŜŝŞşŠš]`, 'g');
    const regexT = new RegExp(`[ŢţŤťŦŧ]`, 'g');
    const regexU = new RegExp(`[ÙÚÛùúûŨũŪūŬŭŮůŰűŲų]`, 'g');
    const regexW = new RegExp(`[Ŵŵ]`, 'g');
    const regexY = new RegExp(`[ÝýÿŶŷŸ]`, 'g');
    const regexZ = new RegExp(`[ŹźŻżŽž]`, 'g');
    // eslint-disable-next-line no-useless-escape
    const regexFlankedDash = new RegExp(`(\\s?)(\\-)(\\s?)`, 'g');
    // eslint-disable-next-line no-useless-escape
    const regexNulls = new RegExp(`(?<=\\S)([\\s\\,]{1,2})`, 'g');
    // eslint-disable-next-line no-useless-escape
    const illegalChars = new RegExp(`[^A-Za-z0-9\\-\\_\\.]|[\\+]`, 'g');
    let outputString: string = inputString;
    outputString = outputString.replace(regexA, 'a');
    outputString = outputString.replace(regexC, 'c');
    outputString = outputString.replace(regexD, 'd');
    outputString = outputString.replace(regexE, 'e');
    outputString = outputString.replace(regexG, 'g');
    outputString = outputString.replace(regexH, 'h');
    outputString = outputString.replace(regexI, 'i');
    outputString = outputString.replace(regexJ, 'j');
    outputString = outputString.replace(regexK, 'k');
    outputString = outputString.replace(regexL, 'l');
    outputString = outputString.replace(regexN, 'n');
    outputString = outputString.replace(regexO, 'o');
    outputString = outputString.replace(regexQ, 'q');
    outputString = outputString.replace(regexR, 'r');
    outputString = outputString.replace(regexS, 's');
    outputString = outputString.replace(regexT, 't');
    outputString = outputString.replace(regexU, 'u');
    outputString = outputString.replace(regexW, 'w');
    outputString = outputString.replace(regexY, 'y');
    outputString = outputString.replace(regexZ, 'z');
    outputString = outputString.replace(regexFlankedDash, '-');
    outputString = outputString.replace(regexNulls, '.');
    outputString = outputString.replace(illegalChars, '');
    return outputString;
  }

  public static getMailTo(
    email: string | string[],
    params: { subject?: string; body?: string; cc?: string | string[]; bcc?: string | string[] } = {}
  ): string {
    const recipients = Array.isArray(email) ? email.join(',') : email;
    return `mailto:${recipients}?${UrlUtils.jsonToQueryParams(params)}`;
  }
}
