import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { EditWithModalComponent } from '@common/components/edit-with-modal/edit-with-modal.component';
import { BackgroundTasksApiService } from '@common/services/api/notifications/backgroundTasks-api.service';
import { ResourcesTemporaryDataService } from '@common/services/api/sg/temporary-data/resources-temporary-data.service';
import * as microsoftTeams from "@microsoft/teams-js";
import { Actions, Select, Store } from '@ngxs/store';
import { UiIndicatorArrow } from '@ui-kit/components/ui-indicator-arrow/ui-indicator-arrow.component';
import { MultiSelectData } from '@ui-kit/components/ui-multi-select/multi-select-data';
import { UiMultiSelect } from '@ui-kit/components/ui-multi-select/ui-multi-select.component';
import { UiStaticTableRowType } from '@ui-kit/components/ui-static-table/ui-static-table-row/ui-static-table-row.component';
import { UiTableDirection } from '@ui-kit/components/ui-table/ui-table.component';
import { GroupTypeEnum } from '@ui-kit/util/icon-util';
import { SearchBarState } from 'projects/@assist-ops/store/search-bar.store';
import { Eco } from 'projects/@common/definitions/eco';
import { MultiSelectDataFactory } from 'projects/@common/modules/i18n/component-wrapper/multi-select-data.factory';
import { StaticTableDataMapper } from 'projects/@common/modules/i18n/component-wrapper/static-table-data-mapper.service';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { AppPages, SetActivePage } from 'projects/@common/modules/layout/stores/app.state';
import { EcoSessionState } from 'projects/@common/modules/session/state/session.state';
import { ResourceSharesApiService } from 'projects/@common/services/api/sg/resourceSharedFiles/resource-shares-api.service';
import { IGetResourcesWithSharesResponse } from 'projects/@common/services/api/sg/resourceSharedFiles/resource-shares.definitions';
import { SharesApiService } from 'projects/@common/services/api/sg/shares/shares-api.service';
import { FileRiskEnum, SharedFilesResourceTypeEnum } from 'projects/@common/services/api/sg/shares/shares.definitions';
import { MobileService } from 'projects/@common/services/mobile.service';
import { TeamsAuthService } from 'projects/@common/services/teams-auth.service';
import { JsonUtils } from 'projects/@common/utils/json-utils';
import { Observable, Subscription } from "rxjs";
import { RevokeSharesDialogComponent, RevokeTypeEnum } from '../revoke-shares-dialog/revoke-shares-dialog.component';
import { ShareRiskScore } from '../share-risk-score/share-risk-score.component';
import { SharesResourceAvatar } from '../shares-resource-avatar/shares-resource-avatar.component';

export enum GroupNumberEnum {
  SINGLE = 'single',
  MANY = 'many'
}

export enum CustomRisksEnum {
  ALL = 'ALL_SHARE_RISKS',
  HIGH = 'HIGH',
  MODERATE = 'MODERATE',
  LOW = 'LOW'
}

export enum CustomSourceEnum {
  ALL = 'ALL_SHARE_SOURCES',
  ONEDRIVE = 'ONEDRIVE',
  LIBRARY = 'LIBRARY',
  PRIVATE_CHANNEL = 'PRIVATE_CHANNEL'
}

export enum FilterEnum {
  SOURCES_FILTERS = 'sourcesFilters',
  RISKS_FILTERS = 'risksFilters'
}

interface IFilters {
  sourcesFilters: any[];
  risksFilters: FileRiskEnum[];
}

interface IDataSource {
  name: string;
  files: { fileShared: number; file7: number; file52: string; };
  folders: { folderShared: number; folder7: number; folder52: string; };
  owners: any[];
  guid: string;
  siteId: string;
  listId: string;
  isPending?: boolean;
  groupType: GroupTypeEnum | SharedFilesResourceTypeEnum;
}

@Component({
  selector: 'resources-shares-table',
  templateUrl: './resources-shares-table.component.html',
  styleUrls: [ './resources-shares-table.component.scss' ],
})
export class ResourcesSharesTableComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('editor') editWithModalComponent: EditWithModalComponent;
  @Select(SearchBarState.searchQuery) public searchQuery$: Observable<string>;
  @Select(EcoSessionState.user) public currentUser$: Observable<string>;
  @Input() isFromConsoleOrg: boolean = false;
  @Input() hasPersonnalShares: boolean = false;
  @Input() public disableExpressRevoke = false;
  @Output() resourcesNumber: EventEmitter<number> = new EventEmitter();
  @Output() refresh: EventEmitter<number> = new EventEmitter();
  @ViewChildren(UiMultiSelect) uiMultiSelectChildren!: QueryList<UiMultiSelect>;
  public currentUser: any; // IUser
  public isLoading = true;
  public initialLoading = false;
  public fulltext = '';
  public total = 0;
  public nextToken = 0;
  public sortParent = null;
  public sortField = null;
  public sort = null;
  public direction = UiTableDirection.Desc;
  public shareSourcesFilter = SharedFilesResourceTypeEnum;
  public shareRisksFilter = FileRiskEnum;
  public activatedFilters = 0;
  public requiredWidthForMobile = 1000;
  public queryParams = { resourcesRiskFilter: [], sourcesFilter: [] };
  public showBatchRevokeDialog = false;
  public intervalOptions = {
    isIntervalSet: false,
    intervalId: null,
  };
  public selectedFilters: IFilters = {
    sourcesFilters: [],
    risksFilters: [],
  };
  public appliedFilters: IFilters = {
    sourcesFilters: [],
    risksFilters: [],
  };
  public defaultRiskFilter = this.route.snapshot.queryParams.resourcesRiskFilter;
  public defaultSourceFilter = this.route.snapshot.queryParams.sourcesFilter;
  public dataSource: IDataSource[] = [];
  public sourcesMultiSelectData: MultiSelectData;
  public risksMultiSelectData: MultiSelectData;
  public pendingJobGuids = [];
  private readonly SMALL_SCREEN_SIZE = 1550;
  private readonly LIBRAIRIES_PAGE_SIZE = 50;
  private latestCallId = 0;
  private querySubscription: Subscription;
  private isSmallScreen = window.innerWidth <= this.SMALL_SCREEN_SIZE;
  private fileSubHeaderTooltipContent = `<p class="semi-bold">${this.i18n
    .translate('shares.list.columns.shares.name')
    .toUpperCase()}</p>${this.i18n.translate('shares.list.columns.shares.files.fileShared.tooltip')}<br/><br/><p class="semi-bold">${this.i18n
    .translate('shares.list.columns.shares.delta7')
    .toUpperCase()}</p>${this.i18n.translate('shares.list.columns.shares.files.delta7.tooltip')}<br/><br/><p class="semi-bold">${this.i18n
    .translate('shares.list.columns.shares.delta52')
    .toUpperCase()}</p>${this.i18n.translate('shares.list.columns.shares.files.delta52.tooltip')}`;
  private folderSubHeaderTooltipContent = `<p class="semi-bold">${this.i18n
    .translate('shares.list.columns.shares.name')
    .toUpperCase()}</p>${this.i18n.translate('shares.list.columns.shares.folders.folderShared.tooltip')}<br/><br/><p class="semi-bold">${this.i18n
    .translate('shares.list.columns.shares.delta7')
    .toUpperCase()}</p>${this.i18n.translate('shares.list.columns.shares.folders.delta7.tooltip')}<br/><br/><p class="semi-bold">${this.i18n
    .translate('shares.list.columns.shares.delta52')
    .toUpperCase()}</p>${this.i18n.translate('shares.list.columns.shares.folders.delta52.tooltip')}`;
  public columnsDef: any[] = [
    {
      label: this.i18n.translate('shares.list.columns.type.name'),
      field: 'groupType',
      type: UiStaticTableRowType.DYNAMIC_COMPONENT_HOST,
      component: SharesResourceAvatar,
      paramName: 'resource',
      size: 'normal',
      rowspan: 2,
      noSorting: true,
      width: this.isSmallScreen ? '60px' : '75px',
      isResizable: false,
    },
    {
      label: this.i18n.translate('shares.list.columns.name.name'),
      field: 'name',
      type: UiStaticTableRowType.TEXT,
      rowspan: 2,
      isResizable: false,
      isTruncated: true,
    },
    {
      label: this.i18n.translate('shares.list.columns.risk.name'),
      field: 'risk',
      type: UiStaticTableRowType.DYNAMIC_COMPONENT_HOST,
      component: ShareRiskScore,
      paramName: 'resource',
      rowspan: 2,
      width: '100px',
      isResizable: false,
    },
    {
      label: this.i18n.translate('shares.list.columns.files.name'),
      field: 'subHeaderFiles',
      type: UiStaticTableRowType.SUB_HEADER,
      tooltip: this.isSmallScreen ? this.fileSubHeaderTooltipContent : null,
      colspan: 3,
      noSorting: true,
      firstColumnOfHeaderGroup: true,
      lastColumnOfHeaderGroup: true,
      isResizable: false,
    },
    {
      label: this.i18n.translate('shares.list.columns.shares.name'),
      field: 'fileShared',
      fields: [ 'files', 'fileShared' ],
      type: UiStaticTableRowType.DEEP_1,
      tooltip: this.isSmallScreen ? null : this.i18n.translate('shares.list.columns.shares.files.fileShared.tooltip'),
      isSmall: true,
      firstColumnOfHeaderGroup: true,
      width: '50px',
      isResizable: false,
      isContentCentered: true,
    },
    {
      label: this.i18n.translate('shares.list.columns.shares.delta7'),
      field: 'file7',
      fields: [ 'files', 'file7' ],
      type: UiStaticTableRowType.DYNAMIC_COMPONENT_DEEP_1,
      component: UiIndicatorArrow,
      tooltip: this.isSmallScreen ? null : this.i18n.translate('shares.list.columns.shares.files.delta7.tooltip'),
      isSmall: true,
      paramName: 'value',
      secondParamName: 'includePlusSign',
      secondParamValue: false,
      width: this.isSmallScreen ? '75px' : '115px',
      isResizable: false,
      isContentCentered: true,
    },
    {
      label: this.i18n.translate('shares.list.columns.shares.delta52'),
      field: 'file52',
      fields: [ 'files', 'file52' ],
      type: UiStaticTableRowType.DEEP_1,
      tooltip: this.isSmallScreen ? null : this.i18n.translate('shares.list.columns.shares.files.delta52.tooltip'),
      isSmall: true,
      secondParamValue: false,
      width: this.isSmallScreen ? '70px' : '115px',
      isResizable: false,
      isContentCentered: true,
    },
    {
      label: this.i18n.translate('shares.list.columns.folders.name'),
      field: 'subHeaderFolders',
      type: UiStaticTableRowType.SUB_HEADER,
      tooltip: this.isSmallScreen ? this.folderSubHeaderTooltipContent : null,
      colspan: 3,
      noSorting: true,
      firstColumnOfHeaderGroup: true,
      lastColumnOfHeaderGroup: true,
      isResizable: false,
    },
    {
      label: this.i18n.translate('shares.list.columns.shares.name'),
      field: 'folderShared',
      fields: [ 'folders', 'folderShared' ],
      type: UiStaticTableRowType.DEEP_1,
      tooltip: this.isSmallScreen
        ? null
        : this.i18n.translate('shares.list.columns.shares.folders.folderShared.tooltip'),
      isSmall: true,
      firstColumnOfHeaderGroup: true,
      width: '50px',
      isResizable: false,
      isContentCentered: true,
    },
    {
      label: this.i18n.translate('shares.list.columns.shares.delta7'),
      field: 'folder7',
      fields: [ 'folders', 'folder7' ],
      type: UiStaticTableRowType.DYNAMIC_COMPONENT_DEEP_1,
      component: UiIndicatorArrow,
      tooltip: this.isSmallScreen ? null : this.i18n.translate('shares.list.columns.shares.folders.delta7.tooltip'),
      isSmall: true,
      paramName: 'value',
      secondParamName: 'includePlusSign',
      secondParamValue: false,
      width: this.isSmallScreen ? '75px' : '115px',
      isResizable: false,
      isContentCentered: true,
    },
    {
      label: this.i18n.translate('shares.list.columns.shares.delta52'),
      field: 'folder52',
      fields: [ 'folders', 'folder52' ],
      type: UiStaticTableRowType.DEEP_1,
      tooltip: this.isSmallScreen ? null : this.i18n.translate('shares.list.columns.shares.folders.delta52.tooltip'),
      isSmall: true,
      lastColumnOfHeaderGroup: true,
      width: this.isSmallScreen ? '70px' : '115px',
      isResizable: false,
      isContentCentered: true,
    },
    {
      label: this.i18n.translate('shares.list.columns.owners.name'),
      field: 'owners',
      type: UiStaticTableRowType.AVATARS,
      avatarSize: this.isSmallScreen ? 'tiny' : 'small',
      rowspan: 2,
      width: this.isSmallScreen ? '120px' : '150px',
      minWidth: this.isSmallScreen ? '120px' : '150px',
      noSorting: true,
      isResizable: false,
    },
  ];

  constructor(
    protected readonly store: Store,
    protected readonly actions$: Actions,
    private router: Router,
    public mobileService: MobileService,
    public readonly i18n: I18nService,
    private sharesApiService: SharesApiService,
    private cdr: ChangeDetectorRef,
    private readonly route: ActivatedRoute,
    private staticTableDataMapper: StaticTableDataMapper,
    private multiSelectDataMapper: MultiSelectDataFactory,
    private activatedRoute: ActivatedRoute,
    private eRef: ElementRef,
    public dialog: MatDialog,
    private resourceSharesApiService: ResourceSharesApiService,
    private backgroundTasksApiService: BackgroundTasksApiService,
    private temporaryDataService: ResourcesTemporaryDataService
  ) {
  }

  public async ngOnInit() {
    this.currentUser$.subscribe((currentUser) => this.currentUser = currentUser);
    if (TeamsAuthService.isInTeams) {
      const context = await microsoftTeams.app.getContext();
      if (context.page.subPageId) {
        try {
          const queryParams = JsonUtils.stringToJson(context.page.subPageId);
          this.defaultRiskFilter = queryParams['resourcesRiskFilter'];
        } catch (error) {
        }
      }
    }
    this.loadLibraries(true);
    this.setInitialFilters();
    this.getResourcesWithShares({ size: 1 }).then((resources) => this.resourcesNumber.emit(resources.total));
  }

  @HostListener('document:click', [ '$event' ])
  onOutsideClick(event: any): void {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.close();
    }
  }

  public close(): void {
    this.editWithModalComponent?.close();
  }

  public toggle(): void {
    this.editWithModalComponent.toggle();
  }

  // Prevent infinite loop of refresh when called from shares container (Revoking from resource tab -> refresh shares tab -> refresh resource tab)
  public refreshLoad(preventRefresh = false): void {
    this.handleRefresh(preventRefresh);
  }

  public ngAfterViewInit() {
    setTimeout(() => {
      this.store.dispatch(new SetActivePage({ activePage: AppPages.RESOURCES_WITH_SHARES_PAGE }));
      this.cdr.detectChanges();
    }, 1);
  }

  public ngOnDestroy() {
    this.querySubscription?.unsubscribe();
  }

  public applySelectedFilters() {
    for (const filter in this.selectedFilters) {
      if (this.selectedFilters[filter]) {
        this.appliedFilters[filter] = this.selectedFilters[filter];
      }
    }
    this.updateActivatedFilters();
    this.handleRefresh();
  }

  public cancelSelectedFilters() {
    for (const filter in this.appliedFilters) {
      if (this.appliedFilters[filter]) {
        this.selectedFilters[filter] = this.appliedFilters[filter];
      }
    }
    this.uiMultiSelectChildren.map((p) => {
      p.setSelected([ ...this.selectedFilters.sourcesFilters, ...this.selectedFilters.risksFilters ]);
    });
  }

  public clearAllFilters() {
    this.uiMultiSelectChildren.map((p) => p.clearSelection());
    for (const filters of [ this.appliedFilters, this.selectedFilters ]) {
      filters.sourcesFilters = [];
      filters.risksFilters = [];
    }
    this.activatedFilters = 0;
    this.handleRefresh();
  }

  public handleRefresh(preventRefresh = false) {
    this.isLoading = true;
    this.dataSource = [];
    this.nextToken = 0;
    this.loadLibraries(preventRefresh);
  }

  public handleSearchClear() {
    this.fulltext = '';
    this.handleRefresh();
  }

  public handleSearchQuery(fulltext: string) {
    this.fulltext = fulltext;
    this.handleRefresh();
  }

  public handleSort(sortParams: any) {
    if (sortParams.sortParent === 'files') {
      if (sortParams.sortColumn === 'fileShared') {
        this.sort = 'sharedFiles.number';
      } else if (sortParams.sortColumn === 'file7') {
        this.sort = 'sharedFiles.var7DayAvg';
      } else if (sortParams.sortColumn === 'file52') {
        this.sort = 'sharedFiles.max52weeks';
      }
    } else if (sortParams.sortParent === 'folders') {
      if (sortParams.sortColumn === 'folderShared') {
        this.sort = 'sharedFolders.number';
      } else if (sortParams.sortColumn === 'folder7') {
        this.sort = 'sharedFolders.var7DayAvg';
      } else if (sortParams.sortColumn === 'folder52') {
        this.sort = 'sharedFolders.max52weeks';
      }
    } else if (sortParams.sortColumn === 'risk') {
      this.sort = 'resourceRiskScore';
    } else if (sortParams.sortColumn === 'name') {
      this.sort = 'resourceName';
    }
    this.sortField = sortParams.sortColumn;
    this.sortParent = sortParams.sortParent;
    this.direction = sortParams.sortDirection;
    this.handleRefresh();
  }

  public async handleSourceFilter(filters: any[]): Promise<void> {
    this.handleFilterChange('sourcesFilters', filters);
  }

  public async handleRiskFilter(filters: any[]): Promise<void> {
    this.handleFilterChange('risksFilters', filters);
  }

  public loadMore() {
    if (this.dataSource.length < this.total && !this.isLoading) {
      this.loadLibraries();
    }
  }

  public handleRowClick(item) {
    const route = `/${this.isFromConsoleOrg ? 'shares' : 'myshares'}/resource`;
    this.router.navigate([ route ], {
      queryParams: {
        type: item.groupType,
        guid: item.guid,
        name: item.name,
        isOrgAdmin: true,
      },
    });
  }

  public openExpressRevokeDialog(): void {
    this.dialog.open(RevokeSharesDialogComponent, {
      maxWidth: '75%',
      panelClass: 'custom-dialog',
      data: { actionType: RevokeTypeEnum.EXPRESS_REVOKE, resourceId: 'all' },
    }).afterClosed().subscribe(() => {
      this.handleRefresh();
    });
    this.toggle();
  }


  private setInitialFilters() {
    this.risksMultiSelectData = this.multiSelectDataMapper.create(this.shareRisksFilter, this.defaultRiskFilter ? this.defaultRiskFilter : [], 'file.risk.');
    if (this.defaultRiskFilter) {
      this.handleRiskFilter(this.defaultRiskFilter);
    }

    this.sourcesMultiSelectData = this.multiSelectDataMapper.create(this.shareSourcesFilter, this.defaultSourceFilter ? this.defaultSourceFilter : [], 'file.source.');
    if (this.defaultSourceFilter) {
      this.handleSourceFilter(this.defaultSourceFilter);
    }
  }

  private loadLibraries(preventRefresh = false) {
    this.isLoading = true;

    const currentCallId = this.latestCallId + 1;
    this.latestCallId = currentCallId;

    const params = {
      from: this.nextToken,
      size: this.LIBRAIRIES_PAGE_SIZE,
      sort: this.sort,
      direction: this.direction,
      resourcesFilter: this.appliedFilters.sourcesFilters,
      risks: this.appliedFilters.risksFilters,
      fulltext: this.fulltext,
    };

    const jobGuidsToRemove = [];
    this.pendingJobGuids = [];
    this.backgroundTasksApiService.getJobStatus(this.temporaryDataService.revokedJobGuids).then((jobs) => {
      for (const value of jobs) {
        const isPending = value.status.operationsCount - value.status.error - (value.status.critical || 0) - value.status.success > 0;
        isPending ? this.pendingJobGuids.push(value.jobGuid) : jobGuidsToRemove.push(value.jobGuid);
      }
      this.temporaryDataService.removeRevokedResources(jobGuidsToRemove);
    }).finally(() => {
      this.getResourcesWithShares(params).then((resources) => {
        if (this.latestCallId === currentCallId) {
          const resourcesWithStats = this.resourcesMapper(resources);
          this.dataSource = this.dataSource.concat(resourcesWithStats);
          this.dataSource.forEach((data) => {
            this.setPendingData(data);
          });
          this.total = resources.total;
          this.nextToken = +resources.nextToken;
        }
      }).finally(() => {
        this.isLoading = false;
        this.handleSharesRefresh(this.dataSource, preventRefresh);
      });
    });
  }

  private translateTable(): void {
    [ this.columnsDef, this.dataSource ] = this.staticTableDataMapper.translate(this.columnsDef, this.dataSource);
  }

  private getResourcesWithShares(params: any) {
    return this.isFromConsoleOrg ? this.sharesApiService.getResourcesWithSharesOrg(params) : this.resourceSharesApiService.getResourcesWithShares(params);
  }

  private resourcesMapper(resources: Eco.IFetchResult<IGetResourcesWithSharesResponse>): any {
    return resources.items.map((resource) => ({
      name:
          resource.resourceType === 'ONEDRIVE' ? `OneDrive - ${resource.resourceName}` : resource.resourceName,
      files: {
        fileShared: resource.sharedFiles?.number || 0,
        file7: resource.sharedFiles?.var7DayAvg || 0,
        file52: `${(resource.sharedFiles?.min52weeks || 0).toString()}-${(
          resource.sharedFiles?.max52weeks || 0
        ).toString()}`,
      },
      folders: {
        folderShared: resource.sharedFolders?.number || 0,
        folder7: resource.sharedFolders?.var7DayAvg || 0,
        folder52: `${(resource.sharedFolders?.min52weeks || 0).toString()}-${(
          resource.sharedFolders?.max52weeks || 0
        ).toString()}`,
      },
      owners: resource.owners,
      guid: resource.resourceId,
      groupType: resource.resourceType,
      resourceRiskScore: resource.resourceRiskScore,
      siteId: resource.siteId,
      listId: resource.listId,
      risk: resource.risk,
    }));
  }

  private setPendingData(data: {
    name: string;
    files: { fileShared: number; file7: number; file52: string };
    folders: { folderShared: number; folder7: number; folder52: string };
    owners: any[];
    guid: string;
    siteId: string;
    listId: string;
    isPending?: boolean;
    groupType: GroupTypeEnum | SharedFilesResourceTypeEnum
  }) {
    const pending = this.temporaryDataService.getPending(this.pendingJobGuids);
    pending.siteIds.includes(data.siteId) && pending.listIds.includes(data.listId) ? data.isPending = true : null;
  }

  private handleFilterChange<T extends keyof IFilters>(filter: T, filters: any[]) {
    if (this.mobileService.isMobile()) {
      this.appliedFilters[filter] = filters;
    } else {
      this.appliedFilters[filter] = filters;
      this.queryParams.resourcesRiskFilter = this.appliedFilters[FilterEnum.RISKS_FILTERS];
      this.queryParams.sourcesFilter = this.appliedFilters[FilterEnum.SOURCES_FILTERS];
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: this.queryParams,
        queryParamsHandling: 'merge',
      });
    }
    this.updateActivatedFilters();
    this.handleRefresh();
  }

  private updateActivatedFilters() {
    this.activatedFilters = 0;
    for (const filter in this.appliedFilters) {
      if (this.appliedFilters[filter]) {
        this.activatedFilters += this.appliedFilters[filter].length;
      }
    }
  }

  private handleSharesRefresh(files: IDataSource[], preventRefresh = false): void {
    if (this.isFromConsoleOrg) {
      return;
    }

    const areSomeFilesPending = files.some((file) => file.isPending);
    const isIntervalInAction = this.intervalOptions.isIntervalSet;

    if (areSomeFilesPending) {
      if (!isIntervalInAction) {
        this.intervalOptions.isIntervalSet = true;
        this.setReloadInterval();
      }
    } else {
      this.intervalOptions.isIntervalSet = false;
      this.getResourcesWithShares({ from: 0, size: this.LIBRAIRIES_PAGE_SIZE, direction: this.direction })
        .then((resources) => {
          this.resourcesNumber.emit(resources.total);
          !preventRefresh ? this.refresh.emit() : null;
          const resourcesWithStats = this.resourcesMapper(resources);
          const tempShares = [ ...this.dataSource ];
          this.dataSource.forEach((data, index) => {
            if (data.isPending) {
              const updatedElement = resourcesWithStats.find((newShare) => newShare.guid === data.guid);
              if (updatedElement) {
                tempShares.splice(index, 1, updatedElement);
              } else {
                const elementToRemove = tempShares.findIndex((newShare) => newShare.guid === data.guid);
                tempShares.splice(elementToRemove, 1);
              }
            }
          });
          this.dataSource = tempShares;
        });

      if (!!this.intervalOptions.intervalId) {
        clearInterval(this.intervalOptions.intervalId);
        this.intervalOptions.intervalId = null;
      }
    }
  }

  private setReloadInterval(): void {
    this.intervalOptions.intervalId = setInterval(() => {
      const jobGuidsToRemove = [];
      this.pendingJobGuids = [];
      this.backgroundTasksApiService.getJobStatus(this.temporaryDataService.revokedJobGuids).then((jobs) => {
        for (const value of jobs) {
          const isPending = value.status.operationsCount - value.status.error - (value.status.critical || 0) - value.status.success > 0;
          isPending ? this.pendingJobGuids.push(value.jobGuid) : jobGuidsToRemove.push(value.jobGuid);
        }
        this.temporaryDataService.removeRevokedResources(jobGuidsToRemove);
      }).finally(() => {
        this.reloadShares();
      });
    }, 10000);
  }

  private reloadShares(): void {
    this.getResourcesWithShares({ from: 0, size: this.LIBRAIRIES_PAGE_SIZE, direction: this.direction })
      .then((resources) => {
        const resourcesWithStats = this.resourcesMapper(resources);
        resourcesWithStats.forEach((share) => this.setPendingData(share));
        this.handleSharesRefresh(resourcesWithStats);
      });
  }
}
