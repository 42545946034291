  <certify-users-rows
    [isFromQuickActions] = isFromQuickActions
    [users]="users"
    [owners]="owners"
    [unfilteredUsers]="unfilteredUsers"
    [showBadges]="true"
    [isLoading]="isLoading"
    [total]="total"
    (onCertify)="onCertify.emit($event)"
  ></certify-users-rows>

<ng-container *ngIf="!isLoading && users?.length <= 0">
  <span class="empty-table-message">{{ 'certify.no.user' | t }}</span>
</ng-container>

<div [class.empty-div-placeholder]="isFromQuickActions" *ngIf="isLoading">
  <ui-spinner
    class="table-loading-spinner"
    size="medium"
    modifier="secondary"
  ></ui-spinner>
</div>

<div class="stepper-buttons" *ngIf="!isLoading">
  <div class="left-side">
    <certify-bottom-indicators [unfilteredUsers]="unfilteredUsers" ></certify-bottom-indicators>
  </div>
  <button
    ui-button
    color="standard"
    [disabled]="disableSave"
    (click)="save()">
    {{ 'certify.save' | t }}
  </button>
  <div class="cancel-button">
    <button ui-button color="secondary" (click)="cancel()">
      {{ 'common.action.cancel' | t }}
    </button>
  </div>
</div>
