    <div class="title-wrapper">
        <p class="title">{{'shares.description.title' | t}}</p>
        <img src="assets/favicons/icon_protection.svg">
    </div>
    <p class="text bold">{{ 'shares.description.text.subtext1' | t }} </p>
    <p class="subtitle">{{ 'shares.description.text.learn.subtitle' | t }} </p>
    <collapsable-description [icon]="'assets/favicons/icon_contextual_help_small.svg'" [title]="'shares.resources.description.subtitle1' | t">
      <p class="description">{{ 'shares.resources.description.subtext1' | t }}</p>
    </collapsable-description>
    <collapsable-description [icon]="'assets/favicons/icon_protection_grey_cropped.svg'" [title]="'shares.resources.description.subtitle2' | t">
      <p class="description">{{ 'shares.resources.description.subtext2' | t }} </p>
        <div class="sub-description">
          <div class="text-image">
            <img src="assets/favicons/icon_odometre.svg">
          </div>
          <p>{{'shares.resources.description.subtext3' | t }}</p>
        </div>
    </collapsable-description>

<!-- <a href="http://google.ca" class="outside-link"> -->
<!--   <p class="subtitle">{{ 'common.learn.more' | t }}</p> -->
<!--   <img src="assets/favicons/icon_external_list.svg"> -->
<!-- </a> -->
