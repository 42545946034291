<ui-dialog
  *ngIf="showRevokeAllSharesConfirmationDialog"
  [headtitle]="
    'sharedFile.drawer.shares.dialog.revokeAll.title'
      | t: { fileName: fileName }
  "
  [actionButton]="'sharedFile.drawer.shares.dialog.revokeAll.action' | t"
  [cancelButton]="'common.action.cancel' | t"
  (onButtonClick)="onRevokeAllSharesConfirmation($event)"
  [isLoading]="isRevokingAll"
  [isInDrawer]="true"
>
  {{ 'sharedFile.drawer.shares.dialog.revokeAll.message1' | t }}
  <br />
  {{ 'sharedFile.drawer.shares.dialog.revokeAll.message2' | t }}
  <br />
  <br />
  {{ 'sharedFile.drawer.shares.dialog.revokeAll.message3' | t }}
  <img
    style="vertical-align:middle;line-height: 24px;"
    [src]="'assets/favicons/icon_in_progress_grey.svg'"
  />
  {{ 'sharedFile.drawer.shares.dialog.revokeAll.message4' | t }}
</ui-dialog>