<div
  class="breaktime-task-container"
  [ngClass]="{
    'breaktime-task-container--first': isFirst && !isMobile,
    'breaktime-task-container--not-first': !isFirst && !isMobile,
    'breaktime-task-container--last': isLast && !isMobile,
    'breaktime-task-container--mobile': isMobile
  }"
>
  <section
    class="breaktime-task-container--header"
    (click)="toggleTaskDescription()"
  >
    <div
      class="breaktime-task-container--header--collapse"
      *ngIf="!isMobile"
    >
      <img [src]="
          'assets/favicons/icon_chevron_' +
          (isCollapsed ? 'close' : 'open') +
          '.svg'
        " />
    </div>

    <img
      class="breaktime-task-container--header--image"
      [src]="'assets/favicons/breaktime/icon_' + task.icon + '.svg'"
    />

    <span
      class="breaktime-task-container--header--name"
      [ngClass]="{'breaktime-task-container--header--name--desktop': !isMobile }"
    >
      {{'dashboard.breaktime.task.' + task?.taskName + '.title.part1'
      | t : { name: task?.translate?.name, groupName: task?.translate?.groupName }
      }}{{'dashboard.breaktime.task.' + task.taskName + '.title.link' | t}}{{ 'dashboard.breaktime.task.' +
      task.taskName + '.title.part2' | t }}
    </span>
  </section>

  <section
    class="breaktime-task-container--header--actions--desktop"
    *ngIf="!isMobile"
  >
    <ng-container *ngTemplateOutlet="actions"></ng-container>
  </section>

  <section
    *ngIf="!isCollapsed"
    class="breaktime-task-container--description"
    [ngClass]="
      isMobile
        ? 'breaktime-task-container--description--mobile'
        : 'breaktime-task-container--description--desktop'
    "
  >
    {{ 'dashboard.breaktime.task.' + task.taskName + '.description' | t }}
  </section>

  <section
    class="breaktime-task-container--header--actions--mobile"
    *ngIf="isMobile"
  >
    <ng-container *ngTemplateOutlet="actions"></ng-container>
  </section>
</div>

<ng-template #loadingAnimation>
  <ui-spinner size="small"></ui-spinner>
</ng-template>

<ng-template #actions>
  <div
    class="breaktime-task-container--header--actions"
    *ngIf="!isActionLoading; else loadingAnimation"
  >
    <div
      class="task-action"
      (click)="clickOnAction1(); $event.stopPropagation()"
    >
      {{ 'dashboard.breaktime.task.' + task.taskName + '.action' | t }}
    </div>
    <div
      class="task-action task-action2"
      *ngIf="task.twoActions"
      (click)="clickOnAction2(); $event.stopPropagation()"
    >
      {{ 'dashboard.breaktime.task.' + task.taskName + '.action.2' | t }}
    </div>
  </div>
</ng-template>

<batch-revoke
  [(revokeHighRiskDialog)]="revokeHighRiskDialog"
  [userId]="resourceGuid"
>
</batch-revoke>