<widget-container
  class="content"
  [title]="('dashboard.admin.top10HighRIsk.title' + (resources.length < 10 ? '.lessThan' : '')) | t"
  [withIcon]="true"
  iconName="icon_resources_widget"
  [withBottomMargin]="isFromConsoleOrg"
  [locale]="locale"
  [withTitleTooltip]="true"
>
  <div tooltip-content>
    <p>{{ 'dashboard.admin.top10HighRIsk.tooltip' + (!isFromConsoleOrg ? '.team' : '') | t }}</p>
  </div>

  <div *ngIf="resources?.length && !isLoading" class="sub-content">
    <div class="sub-content--title" *ngIf="!mobileService.windowWidthSmallerThan(600)">
      <p [ngStyle]="{ width: '365px' }">{{ 'dashboard.admin.top10HighRIsk.subtitle.resources' | t }}</p>
      <p class="owners-title">{{ 'dashboard.admin.top10HighRIsk.subtitle.owners' | t }}</p>
    </div>
    <div class="sub-content--rows">
      <ng-container *ngFor="let resource of resources">
        <admin-top10-row [resource]="resource"></admin-top10-row>
      </ng-container>
    </div>
  </div>
  <ui-spinner *ngIf="isLoading"></ui-spinner>
  <div *ngIf="!resources?.length && !isLoading" class="no-content">
    {{ customEmptyMessageKey ?? 'dashboard.admin.noData' | t }}
  </div>
</widget-container>
