import { Component, Input } from '@angular/core';

@Component({
  selector: 'certify-bottom-indicators',
  templateUrl: './certify-bottom-indicators.component.html',
  styleUrls: [ '../tables.component.scss', './certify-bottom-indicators.component.scss' ],
})
export class CertifyBottomIndicatorsComponent {
  @Input() public unfilteredUsers = [];
  @Input() public isLoading = false;

  constructor() {}
  public get confirmNumber(): number {
    return this.unfilteredUsers?.filter((user) => user.isCertified).length;
  }

  public get revokeNumber(): number {
    return this.unfilteredUsers?.filter((user) => !user.isCertified).length;
  }
}
