import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Select } from '@ngxs/store';
import { UiStaticTableRowType } from '@ui-kit/components/ui-static-table/ui-static-table-row/ui-static-table-row.component';
import { TableFilterData } from '@ui-kit/components/ui-table-tools/ui-table-filter-tool/ui-table-filter-data';
import { DTPipe } from '@ui-kit/pipes/dt.pipe';
import { RequestStatusEnum, IconUtil } from '@ui-kit/util/icon-util';
import { IBreadcrumbs } from 'projects/@assist-ops/components/breadcrumbs/breadcrumbs.component';
import {
  Accumulatables,
  Accumulator,
  AccumulatorsState
} from 'projects/@common/modules/accumulator/accumulator.store';
import { TablesManager } from 'projects/@common/modules/directives/tables-manager.directive';
import { TableFilterToolDataFactory } from 'projects/@common/modules/i18n/component-wrapper/table-filter-tool-data.factory';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { RequestTypeEnum } from 'projects/@common/services/api/requests/requests.definitions';
import { MobileService } from 'projects/@common/services/mobile.service';
import { DOCUMENTATION_SECTION, generateDocumentationLink } from 'projects/@common/utils/eco.documentation-utils';
import { Observable, Subscription } from 'rxjs';
import { RequestsCollapsableTable } from '../components/requests-collapsable-table/requests-collapsable-table.component';
import { RequestService } from '../services/request.service';

export enum ListCategoryType {
  ACTION_REQUIRED = 'actionRequired', // Requests that the current user needs to take action
  MY_REQUESTS = 'myRequests', // All request made by current user
  OTHER_REQUESTS = 'otherRequests' // Requester is not current user and does not require an action
}

export interface RequestTableDefinition {
  title: string;
  columnDef: any[];
  statusesFilter?: RequestStatusEnum;
  categoryFilter?: ListCategoryType;
  headerIcon?: string;
  filter?: string;
}

const FILTER_ALL = 'all';
const FILTER_CATEGORIES = 'categories';
const FILTER_STATUS = 'status';

export enum UserRequestsFilterEnum {
  ALL = 'all',
  CATEGORIES = 'categories',
  STATUS = 'status'
}

@Component({
  selector: 'requests-container',
  templateUrl: './requests.container.html',
  styleUrls: ['./requests.container.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'requests',
  },
})
export class RequestsContainer extends TablesManager implements OnInit, OnDestroy {
  public documentationSection = generateDocumentationLink(DOCUMENTATION_SECTION.sg.request, this.i18n.currentLocale);

  @ViewChild(RequestsCollapsableTable)
  requestsCollapsableTable: RequestsCollapsableTable;

  public breadcrumbs: IBreadcrumbs[] = [
    { nodeName: 'breadcrumb.dashboard', nodeLink: '/dashboard' },
    { nodeName: 'requests.title', nodeLink: null },
  ];

  public requestsQuery = '';
  public requestsFilterEnum: any;
  public defaultRequestsFilterEnum: any;
  public currentFilter: any;
  public type: RequestTypeEnum;
  public requestTableDefinitions: RequestTableDefinition[] = [];
  public selectedFilter: string;
  public requestsToHandle = [];
  public requestsFilterEnumTableFilterData: TableFilterData;
  @Select(AccumulatorsState.accumulators) private accumulators$: Observable<Accumulator[]>;
  private ALL_FILTER_TABLE_COLUMN_DEF = [
    {
      label: '',
      headerIcon: 'icon_modify_disabled',
      field: 'canProcess',
      type: UiStaticTableRowType.ICON,
      getIcon: this.getToProcessIcon,
      width: '90px',
      isResizable: false,
      noSorting: true,
    },
    {
      label: this.i18n.translate('requests.list.columns.number.name'),
      field: 'number',
      type: UiStaticTableRowType.TEXT,
      width: '80px',
    },
    {
      label: this.i18n.translate('requests.list.columns.date.name'),
      field: 'createdAt',
      type: UiStaticTableRowType.PIPED_FIELD,
      pipe: DTPipe,
      pipeParams: { locale: this.i18n.currentLocale },
      width: '120px',
    },
    {
      label: this.i18n.translate('requests.list.columns.type.name'),
      field: 'type',
      type: UiStaticTableRowType.ENUM,
      enumPrefix: 'request.type.enum.',
      width: '200px',
    },
    {
      label: this.i18n.translate('requests.list.columns.object.name'),
      field: 'description',
      type: UiStaticTableRowType.TEXT,
      isTruncated: true,
      noSorting: true,
    },
    {
      label: this.i18n.translate('requests.list.columns.requester.name'),
      field: 'requesterName',
      defaultContent: 'common.system',
      type: UiStaticTableRowType.TEXT,
      width: '150px',
    },
    {
      label: this.i18n.translate('requests.list.columns.assignedTo.name'),
      field: 'currentApprovingType',
      type: UiStaticTableRowType.ENUM,
      width: '150px',
      enumPrefix: 'request.assignedTo.enum.',
      paramName: 'request',
      noSorting: true,
    },
    {
      label: this.i18n.translate('requests.list.columns.status.name'),
      field: 'status',
      type: UiStaticTableRowType.ENUM_WITH_ICON,
      width: '120px',
      customIcon: true,
      isSvg: true,
      enumPrefix: 'request.status.enum.',
      iconName: this.getStatusIcon,
      iconSide: 'left',
    },
  ];
  private CATEGORIES_FILTER_TABLE_COLUMN_DEF = [
    {
      label: this.i18n.translate('requests.list.columns.number.name'),
      field: 'number',
      type: UiStaticTableRowType.TEXT,
      width: '90px',
    },
    {
      label: this.i18n.translate('requests.list.columns.date.name'),
      field: 'createdAt',
      type: UiStaticTableRowType.PIPED_FIELD,
      pipe: DTPipe,
      width: '120px',
    },
    {
      label: this.i18n.translate('requests.list.columns.type.name'),
      field: 'type',
      type: UiStaticTableRowType.ENUM,
      enumPrefix: 'request.type.enum.',
      width: '200px',
    },
    {
      label: this.i18n.translate('requests.list.columns.object.name'),
      field: 'description',
      type: UiStaticTableRowType.TEXT,
      isTruncated: true,
    },
    {
      label: this.i18n.translate('requests.list.columns.requester.name'),
      field: 'requesterName',
      defaultContent: 'common.system',
      type: UiStaticTableRowType.TEXT,
      width: '150px',
    },
    {
      label: this.i18n.translate('requests.list.columns.assignedTo.name'),
      field: 'currentApprovingType',
      type: UiStaticTableRowType.ENUM,
      width: '150px',
      enumPrefix: 'request.assignedTo.enum.',
      noSorting: true,
    },
    {
      label: this.i18n.translate('requests.list.columns.status.name'),
      field: 'status',
      type: UiStaticTableRowType.ENUM_WITH_ICON,
      width: '120px',
      customIcon: true,
      isSvg: true,
      enumPrefix: 'request.status.enum.',
      iconName: this.getStatusIcon,
      iconSide: 'left',
    },
  ];
  private STATUS_PENDING_FILTER_TABLE_COLUMN_DEF = [ // TODO: si pas request manager, devrait pas etre là
    {
      label: this.i18n.translate(''),
      headerIcon: 'icon_modify_disabled',
      field: 'canProcess',
      type: UiStaticTableRowType.ICON,
      getIcon: this.getToProcessIcon,
      width: '90px',
      isResizable: false,
    },
    {
      label: this.i18n.translate('requests.list.columns.number.name'),
      field: 'number',
      type: UiStaticTableRowType.TEXT,
      width: '80px',
    },
    {
      label: this.i18n.translate('requests.list.columns.date.name'),
      field: 'createdAt',
      type: UiStaticTableRowType.PIPED_FIELD,
      pipe: DTPipe,
      width: '120px',
    },
    {
      label: this.i18n.translate('requests.list.columns.type.name'),
      field: 'type',
      type: UiStaticTableRowType.ENUM,
      enumPrefix: 'request.type.enum.',
      width: '200px',
    },
    {
      label: this.i18n.translate('requests.list.columns.object.name'),
      field: 'description',
      type: UiStaticTableRowType.TEXT,
      isTruncated: true,
    },
    {
      label: this.i18n.translate('requests.list.columns.requester.name'),
      field: 'requesterName',
      defaultContent: 'common.system',
      type: UiStaticTableRowType.TEXT,
      width: '150px',
    },
    {
      label: this.i18n.translate('requests.list.columns.assignedTo.name'),
      field: 'currentApprovingType',
      type: UiStaticTableRowType.ENUM,
      width: '150px',
      enumPrefix: 'request.assignedTo.enum.',
      noSorting: true,
    },
  ];
  private STATUS_FILTER_TABLE_COLUMN_DEF = [
    {
      label: this.i18n.translate('requests.list.columns.number.name'),
      field: 'number',
      type: UiStaticTableRowType.TEXT,
      width: '80px',
    },
    {
      label: this.i18n.translate('requests.list.columns.date.name'),
      field: 'createdAt',
      type: UiStaticTableRowType.PIPED_FIELD,
      pipe: DTPipe,
      width: '120px',
    },
    {
      label: this.i18n.translate('requests.list.columns.type.name'),
      field: 'type',
      type: UiStaticTableRowType.ENUM,
      enumPrefix: 'request.type.enum.',
      width: '200px',
    },
    {
      label: this.i18n.translate('requests.list.columns.object.name'),
      field: 'description',
      type: UiStaticTableRowType.TEXT,
      isTruncated: true,
    },
    {
      label: this.i18n.translate('requests.list.columns.requester.name'),
      field: 'requesterName',
      defaultContent: 'common.system',
      type: UiStaticTableRowType.TEXT,
      width: '150px',
    },
  ];
  private accumulatorSubscription: Subscription;
  private currentAccumulatorCount: number;

  constructor(
    protected readonly cdr: ChangeDetectorRef,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    public readonly mobileService: MobileService,
    protected i18n: I18nService,
    private tableFilterToolDataFactory: TableFilterToolDataFactory,
    private requestService: RequestService
  ) {
    super(cdr);
  }

  public ngOnInit() {
    const filterType = this.route.snapshot.queryParams.filterType;
    this.selectedFilter = this.route.snapshot.queryParams.filter;

    if (filterType) {
      this.requestsFilterEnum = UserRequestsFilterEnum;
      this.defaultRequestsFilterEnum = filterType;
    } else {
      this.requestsFilterEnum = UserRequestsFilterEnum;
      this.defaultRequestsFilterEnum = UserRequestsFilterEnum.ALL;
    }

    this.handleFilter(this.defaultRequestsFilterEnum, true);
    this.accumulatorSubscription = this.accumulators$.subscribe((accumulators) => {
      const count = accumulators
        .filter((accumulator) => accumulator.accumulatable === Accumulatables.REQUESTS_LIST)
        .shift()?.accumulator;
      if (count !== this.currentAccumulatorCount) {
        this.handleRefresh();
        this.currentAccumulatorCount = count;
      }
    });

    this.requestService.update.subscribe(() => this.handleRefresh());
    this.requestsFilterEnumTableFilterData =
      this.tableFilterToolDataFactory.create(
        this.requestsFilterEnum,
        'requests.table.',
        this.defaultRequestsFilterEnum,
        true
      );
  }

  public ngOnDestroy() {
    this.accumulatorSubscription?.unsubscribe();
  }

  public handleRefresh() {
    // Since the children components load the data themself during initialization,
    // we can force a refresh easily by destroying them and recreating them.
    this.requestsToHandle = [];
    const tables = [...this.requestTableDefinitions];
    this.requestTableDefinitions = [];
    setTimeout(() => {
      this.tableStatuses = {};
      this.requestTableDefinitions = tables;
      this.cdr.detectChanges();
    });
  }

  public handleSearchClear() {
    this.requestsQuery = null;
    this.handleRefresh();
  }

  public handleSearchQuery(fulltext: string) {
    this.requestsQuery = fulltext;
    this.handleRefresh();
  }

  public handleFilter(filter: any, keepQueryParams = false) {
    this.currentFilter = filter;

    if (!keepQueryParams) {
      this.selectedFilter = null;
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: { filterType: null, filter: null },
        queryParamsHandling: 'merge',
      });
    }

    setTimeout(() => {
      this.tableStatuses = {};
      this.cdr.detectChanges();
    });

    switch (filter) {
      case FILTER_STATUS:
        this.requestTableDefinitions = [
          {
            title: 'requests.table.title.pending',
            columnDef: this.STATUS_PENDING_FILTER_TABLE_COLUMN_DEF,
            statusesFilter: RequestStatusEnum.PENDING,
            headerIcon: 'icon_in_progress',
          },
          {
            title: 'requests.table.title.approved',
            columnDef: this.STATUS_FILTER_TABLE_COLUMN_DEF,
            statusesFilter: RequestStatusEnum.APPROVED,
            headerIcon: 'icon_success',
          },
          {
            title: 'requests.table.title.declined',
            columnDef: this.STATUS_FILTER_TABLE_COLUMN_DEF,
            statusesFilter: RequestStatusEnum.DECLINED,
            headerIcon: 'icon_stop',
          },
          {
            title: 'requests.table.title.canceled',
            columnDef: this.STATUS_FILTER_TABLE_COLUMN_DEF,
            statusesFilter: RequestStatusEnum.CANCELED,
            headerIcon: 'icon_non_applicable',
          },
          {
            title: 'requests.table.title.error',
            columnDef: this.STATUS_FILTER_TABLE_COLUMN_DEF,
            statusesFilter: RequestStatusEnum.ERROR,
            headerIcon: 'icon_error',
          },
          {
            title: 'requests.table.title.expired',
            columnDef: this.STATUS_FILTER_TABLE_COLUMN_DEF,
            statusesFilter: RequestStatusEnum.EXPIRED,
            headerIcon: 'icon_timer',
          },
        ];
        break;
      case FILTER_CATEGORIES:
        this.requestTableDefinitions = [
          {
            title: 'requests.table.title.toProcess',
            columnDef: this.CATEGORIES_FILTER_TABLE_COLUMN_DEF,
            categoryFilter: ListCategoryType.ACTION_REQUIRED,
            headerIcon: 'icon_modify',
            filter: 'pending',
          },
          {
            title: 'requests.table.title.mines',
            columnDef: this.CATEGORIES_FILTER_TABLE_COLUMN_DEF,
            categoryFilter: ListCategoryType.MY_REQUESTS,
            headerIcon: 'icon_tracking',
          },
          {
            title: 'requests.table.title.other',
            columnDef: this.CATEGORIES_FILTER_TABLE_COLUMN_DEF,
            headerIcon: 'icon_other_requests',
            categoryFilter: ListCategoryType.OTHER_REQUESTS,
          },
        ];
        break;
      case FILTER_ALL:
      default:
        this.requestTableDefinitions = [
          {
            title: 'requests.table.title.all',
            columnDef: this.ALL_FILTER_TABLE_COLUMN_DEF,
          },
        ];
    }
  }

  public handleCheckboxEvent(isSelected: any, request: any) {
    if (isSelected) {
      this.requestsToHandle.push(request);
    } else {
      const index = this.requestsToHandle.indexOf(request, 0);
      if (index > -1) {
        this.requestsToHandle.splice(index, 1);
      }
    }
  }

  private getToProcessIcon(request: any): string {
    if (request?.canProcess) {
      return 'assets/favicons/icon_modify.svg';
    }
    return null;
  }

  private getStatusIcon(request: any): string {
    return IconUtil.getRequestStatusIcon(request?.status, request.canNoLongerTakeAction);
  }
}
