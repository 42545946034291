import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CarouselDialogComponent } from 'projects/@assist-ops/components/carousel-dialog/carousel-dialog.component';

@Component({template: '<div style="height: 100vh; background: white"></div>'})
export class OnboardingContactUsComponent implements OnInit {
  constructor(private dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.dialog.open(CarouselDialogComponent, {
      width: '75%',
      minWidth: '720px',
      maxWidth: '1000px',
      data: { isNewClient: true },
      panelClass: 'custom-dialog',
    });
  }
}
