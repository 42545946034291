<div class="slide-container">
  <h2>{{ "dashboard.dialog.carousel.page1.title" | t }}</h2>
  <div class="content-wrapper">
    <section class="left-section">
      <h3>{{ "dashboard.dialog.carousel.page1.second-title1" | t }}</h3>
      <p>{{ "dashboard.dialog.carousel.page1.text1" | t }}</p>
      <h3>{{ "dashboard.dialog.carousel.page1.second-title2" | t }}</h3>
      <p>{{ "dashboard.dialog.carousel.page1.text2" | t }}</p>
      <h3>{{ "dashboard.dialog.carousel.page1.second-title3" | t }}</h3>
      <p>{{ "dashboard.dialog.carousel.page1.text3" | t }}</p>
    </section>
    <section class="right-section">
      <img src="assets/favicons/icon_illustration_fusee.svg" />
    </section>
  </div>
</div>
