<div class="dashboard">
  <div class="content">
    <div class="top-section">
      <sg-request-awaiting
        *ngIf="userTotalRequest > 0"
        class="full-width"
        [userTotalRequest]="userTotalRequest"
      ></sg-request-awaiting>
      <sg-new-badge-banner
        *ngIf="badgeNotifications.length"
        [class.full-width]="userTotalRequest === 0"
        [fullWidth]="userTotalRequest === 0"
        [notifications]="badgeNotifications"
        (onClose)="badgeNotifications = []"
      >
      </sg-new-badge-banner>
    </div>
    <div class="middle-section">
      <sg-quick-actions [groupAmount]="groupAmount"></sg-quick-actions>
      <sg-my-profile></sg-my-profile>
    </div>
    <div class="bottom-section">
      <sg-pause-eco [groupAmount]="groupAmount"></sg-pause-eco>
    </div>
  </div>
</div>