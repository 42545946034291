import { Component, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { DrawerPageEnum } from 'projects/@common/modules/layout/components/drawer/drawerPage.enum';
import { DrawerService } from 'projects/@common/modules/layout/components/drawer/services/drawer.service';
import { AppState } from 'projects/@common/modules/layout/stores/app.state';
import { NotificationsApiService } from 'projects/@common/services/api/notifications/notifications-api.service';
import { Observable } from 'rxjs';
import { OpsManagerNoticesListComponent } from './ops-manager-notices-list/ops-manager-notices-list/ops-manager-notices-list.component';
import { imageMapping } from 'projects/@common/services/api/sg/md.eco-profile/md.eco-profile.definition';
import { SnackbarService } from 'projects/@common/modules/notice/implementations/snackbar/snackbar.service';
import { Notice, NoticeLevels } from 'projects/@common/modules/notice/notice.service';
import { Router } from '@angular/router';
import { DashboardProfileService } from 'projects/@common/services/dashboard-profile.service';
import { GroupsTemporaryDataService } from 'projects/@common/services/api/sg/temporary-data/groups-temporary-data.service';
import { BackgroundTasksApiService } from 'projects/@common/services/api/notifications/backgroundTasks-api.service';
import { EcoSessionState } from '@common/modules/session/state/session.state';

@Component({
  selector: 'background-tasks-button',
  templateUrl: './background-tasks-button.component.html',
  styleUrls: ['./background-tasks-button.component.scss'],
})
export class BackgroundTasksButtonComponent implements OnInit {
  @Select(AppState.isReady) public isReady: Observable<boolean>;
  @Select(EcoSessionState.authentified) public isAuthnd: Observable<boolean>;
  public notifications = [];
  public buttonColor = '';
  public isOnAllowedPage = false;
  public authConfirmed = false;
  public total = 0;
  public showHideButton = false;
  public isCollapsed = false;
  private imageMappings = imageMapping;
  private readonly alertButtonColor = '#ff8000';

  constructor(
    private drawerService: DrawerService,
    private notificationsService: NotificationsApiService,
    private snackbarService: SnackbarService,
    private router: Router,
    private dashboardProfileService: DashboardProfileService,
    private readonly i18n: I18nService,
    private temporaryDataService: GroupsTemporaryDataService,
    private backgroundTaskApiService: BackgroundTasksApiService
  ) {}

  ngOnInit(): void {
    this.isReady.subscribe((readyState) => {
      if (readyState) {
        this.isOnAllowedPage = true;
        this.notificationsService.getObservable().subscribe((event) => {
          const messageEvent = event as MessageEvent;
          const notification = JSON.parse(messageEvent.data);
          this.notifications.unshift(notification);
          const relatedObject = notification?.sentWithConfig?.payload?.relatedObject;
          const jobGuid = relatedObject?.find((element) => element.objectType === 'jobGuid')?.objectGuid;
          if (jobGuid) {
            this.buttonColor = this.alertButtonColor;
            this.backgroundTaskApiService.getOpsManagerJobDetails(jobGuid).then((jobDetails) => {
              const isGroupCreationJob = jobDetails?.operations?.some(
                (operation) => operation.payload.operation.group === 'CREATE_GROUP'
              );
              if (isGroupCreationJob) {
                this.temporaryDataService.addInProgressGroup(jobGuid);
              }
            });
          }

          if (relatedObject?.find((element) => element?.objectType === 'newBadgeTypes')) {
            this.setNewBadgeNotifSnackbar(notification?.sentWithConfig?.payload?.relatedObject[0]);
          }
        });

        this.loadNotifications();
      }
    });

    this.isAuthnd.subscribe((authState) => {
      this.authConfirmed = authState;
    });
  }

  public openDrawer(): void {
    this.buttonColor = '';
    this.drawerService.showDrawer(OpsManagerNoticesListComponent, {
      component: DrawerPageEnum.opsManagerDrawer,
      data: {
        title: this.i18n.translate('ops-manager.notices.drawer.title'),
        notifications: this.notifications,
        total: this.total,
      },
    });
  }

  private loadNotifications(): void {
    this.notificationsService.listNotifications({ from: 0, order: 'DESC', size: 50 }).then((response) => {
      this.notifications = this.notifications.concat(response.items);
      this.total = response.total;
    });
  }

  private setNewBadgeNotifSnackbar(badge: any) {
    this.snackbarService.handleNotice(
      new Notice(
        this.i18n.translate(
          badge.values.length > 1 ? 'dashboard.md-eco.badge.new.multiple' : 'dashboard.md-eco.badge.new.single',
          badge.values.length > 1
            ? { badgeNumber: badge.values.length }
            : { badgeName: this.i18n.translate(badge.values[0]) }
        ),
        NoticeLevels.SUCCESS,
        null,
        null,
        null,
        `${this.imageMappings[badge.values[0]]}.svg`
      )
    );

    if (this.router.url.includes('/dashboard')) {
      this.dashboardProfileService.getDashboardUserProfile();
    }
  }
  public onHoverEnter() {
    this.showHideButton = true;
  }
  public onHoverLeave() {
    this.showHideButton = false;
  }
  public collapse() {
    this.isCollapsed = true;
  }
  public unCollapse() {
    if (this.buttonColor === this.alertButtonColor) {
      this.openDrawer();
    } else {
      this.isCollapsed = false;
    }
  }
}
