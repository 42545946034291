<div
  class="notification--container"
  [class.notification--mobile]="mobileService.isMobile()"
  (click)="onNotificationClick()"
>
  <div class="notification--detail">
    <div class="notification--row">
      <div class="notification--content">
        <div class="notification--title">{{ notificationMessage }}</div>
        <div class="notification--date">{{ sent }}</div>
      </div>
      <div class="operation--status">
        <img [src]="notificationIcon" [alt]="notificationIcon" />
        <div>
          {{ 'ops-manager.notices.status.' + notificationStatus | t }}
        </div>
      </div>
    </div>
  </div>
</div>
