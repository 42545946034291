<base-dialog
  [title]="'groups.dialog.model.help.title' | t"
  [hasStepper]="false"
  (onCancel)="dialogRef.close()"
>
  <div class="container" >
    <p class="subtitle">{{ 'groups.dialog.certify.help.subtitle1' | t }} </p>
    <p class="text">
    {{ 'groups.dialog.certify.help.subtext1' | t }}
    </p>
    <ul>
      <li>{{ 'groups.dialog.certify.help.subtext1.element1' | t }}</li>
      <li>{{ 'groups.dialog.certify.help.subtext1.element2' | t }}</li>
    </ul>
    <p class="text">{{ 'groups.dialog.certify.help.subtext2' | t }}</p>
  </div>
</base-dialog>

