import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UiTableDirection } from '@ui-kit/components/ui-table/ui-table.component';
import { Eco } from 'projects/@common/definitions/eco';
import { ApiEndpointPrefixEnum, EcoApi } from 'projects/@common/definitions/eco-api';
import { FeatureFlagsEnum } from 'projects/@common/modules/layout/definitions/base-sidebar.service';
import { IGetResourcesWithSharesResponse } from 'projects/@common/services/api/sg/resourceSharedFiles/resource-shares.definitions';
import {
  FileRiskEnum,
  FileShareLinkPermissionsTypeEnum,
  FileShareTypeEnum,
  FileTypeEnum,
  IFilesTypesNumbers,
  IResourceSharedFilesStats,
  SharedFilesResourceTypeEnum,
  UserSharedFile,
} from 'projects/@common/services/api/sg/shares/shares.definitions';
import { UrlUtils } from '../../../../utils/utils';
import { EcoUrlService } from '../../core/url.service';
import IFetchResult = Eco.IFetchResult;

@Injectable({
  providedIn: 'root',
})
export class ResourceSharesApiService extends EcoApi {
  protected prefix = ApiEndpointPrefixEnum.sg;

  constructor(
    readonly http: HttpClient,
    url: EcoUrlService
  ) {
    super(http, url);
  }

  public async getResourceFilesSharesTypesNumbers(
    resourceId: string,
    params: {
      sharedSince?: number;
      inactiveSince?: number;
      includeSilenced?: boolean;
    } = {}
  ): Promise<IFilesTypesNumbers> {
    return this.http
      .get(`${this.host}/resources/${resourceId}/revoke/stats?${UrlUtils.jsonToQueryParams(params)}`)
      .toPromise();
  }

  public async getResourceMonitoringStats(params: { risk?: FileRiskEnum } = {}): Promise<IResourceSharedFilesStats> {
    const url = `${this.host}/resources/shares/stats?${UrlUtils.jsonToQueryParams(params)}`;
    return this.http.get<IResourceSharedFilesStats>(url).toPromise();
  }

  public async getResourcesWithShares(params: {
    from: number;
    size: number;
    sort?: string;
    direction?: UiTableDirection;
    resourcesFilter?: SharedFilesResourceTypeEnum[];
    risks?: FileRiskEnum[];
    fulltext?: string;
  }): Promise<IFetchResult<IGetResourcesWithSharesResponse>> {
    const url = `${this.host}/resources/shares?${UrlUtils.jsonToQueryParams(params)}`;
    return this.http.get<any>(url).toPromise();
  }

  public async getResourceShares(
    guid: string,
    params: {
      from: number;
      size: number;
      sort?: string;
      direction?: UiTableDirection;
      fulltext?: any;
      risks?: FileRiskEnum[];
      shareTypes?: FileShareTypeEnum[];
      permissions?: FileShareLinkPermissionsTypeEnum[];
      fileTypes?: FileTypeEnum[];
    }
  ): Promise<IFetchResult<UserSharedFile>> {
    const url = `${this.host}/resources/${guid}/shares?${UrlUtils.jsonToQueryParams(params)}`;
    return this.http.get<any>(url).toPromise();
  }

  public async getResourceSharesOrg(
    guid: string,
    params: {
      from: number;
      size: number;
      sort?: string;
      direction?: UiTableDirection;
      fulltext?: any;
      risks?: FileRiskEnum[];
      shareTypes?: FileShareTypeEnum[];
      permissions?: FileShareLinkPermissionsTypeEnum[];
      fileTypes?: FileTypeEnum[];
    }
  ): Promise<IFetchResult<UserSharedFile>> {
    const url = `${this.host}/org/resources/${guid}/shares?${UrlUtils.jsonToQueryParams(params)}`;
    return this.http.get<any>(url).toPromise();
  }

  public async getUserRiskResources(): Promise<{
    riskUserSharesLOW: boolean;
    riskUserSharesMED: boolean;
    riskUserSharesHIGH: boolean;
    riskUserResources: boolean;
  }> {
    const url = `${this.host}/user/resources/atRisk`;
    return this.http.get<any>(url, this.getFlagParams({ flags: [FeatureFlagsEnum.SHARES_MANAGEMENT] })).toPromise();
  }
}
