
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiEndpointPrefixEnum, EcoApi } from 'projects/@common/definitions/eco-api';
import { UrlUtils } from 'projects/@common/utils/utils';
import { EcoUrlService } from '../core/url.service';
import { IOperationManagerJobDetails } from './notifications.definitions';
import { SseClient } from './sseClient.service';

@Injectable({
  providedIn: 'root',
})
export class BackgroundTasksApiService extends EcoApi {
  protected prefix = ApiEndpointPrefixEnum.sg;

  constructor(readonly http: HttpClient, url: EcoUrlService, private sseClient: SseClient) {
    super(http, url);
  }

  public async getOpsManagerJobDetails(jobGuid: string, params = {}): Promise<IOperationManagerJobDetails> {
    const url = `${this.host}/ops-manager/jobs/${jobGuid}?${UrlUtils.jsonToQueryParams(params)}`;
    return this.http.get<IOperationManagerJobDetails>(url).toPromise();
  }

  public async getJobStatus(jobGuids: string[]): Promise<any> {
    const url = `${this.host}/ops-manager/jobs/all/status?jobGuids=${jobGuids}`;
    return this.http.get<any>(url).toPromise();
  }
}
