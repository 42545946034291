<widget-container
  [title]="'dashboard.admin.resourcesNumberWithShares.title' | t"
  [withIcon]="true"
  iconName="icon_resources_widget"
  [withBottomMargin]="true"
  [locale]="locale"
>
  <div
    *ngIf="resourcesNumberWithSharesData?.length && !isLoading"
    class="admin-resources-number-with-shares--content"
  >
    <div>
      <dynamic-chart
        [locale]="i18n.currentLocale"
        [data]="resourcesNumberWithSharesData"
        name="resourcesNumberWithShares"
        type="DonutChart"
        key="level"
        [legend]="true"
        [series]="['count']"
        [innerSeriesLabel]="{
          count: stats?.highCount + stats?.moderateCount + stats?.lowCount
        }"
        pieChartHeight="120px"
        pieChartRadius="30"
        pieChartInnerRadius="70"
        [legendTemplate]="legendTemplate"
        [hasLegendRedirection]="true"
        (redirectionValue)="handleLegendItemClick($event)"
      ></dynamic-chart>
    </div>
    <div class="admin-resources-number-with-shares--content--text">
      {{ 'dashboard.admin.resourcesNumberWithShares.text' | t }}
    </div>
    <div
      class="admin-resources-number-with-shares--content--action"
      (click)="handleAction()"
    >
      {{ 'dashboard.admin.resourcesNumberWithShares.action' | t }}
    </div>
  </div>
  <ui-spinner *ngIf="isLoading"></ui-spinner>
  <div
    *ngIf="!resourcesNumberWithSharesData?.length && !isLoading"
    class="admin-tendency-widget--no-history"
  >
    {{ 'dashboard.admin.noData' | t }}
  </div>
</widget-container>
