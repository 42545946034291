<base-dialog 
  [title]="(isOwnerAllChanells ? 'groups.action.message.ARCHIVE' : 'group.action.archive.message.notAllOwner.title') | t : { groupName : data.group.displayName } " 
  [hasStepper]="false" 
  [isEditing]="isOwnerAllChanells"
  (onCancel)="close()"
  >
  <div class="content" *ngIf="!isLoading; else loading">

    <ng-container *ngIf="!isOwnerAllChanells">
      <p class="non-owner-text">{{ 'group.action.archive.message.notAllOwner' | t }}</p>
      <div class="list">
        <div class="listElement">
          <p class="subtitle" >{{ 'group.action.archive.message.notAllOwner.item1' | t }} </p>
          <p class="list-element-description">{{ 'group.action.archive.message.notAllOwner.item1.description' | t: {otherOwnerNames: otherOwnerNames}  }} </p>
        </div>

        <div class="listElement">
          <p class="subtitle">{{ 'group.action.archive.message.notAllOwner.item2' | t : {optionNumber: otherOwners.length ? 2 : 1} }} </p>
          <p class="list-element-description">{{ 'group.action.archive.message.notAllOwner.item2.description' | t }} </p>
        </div>

        <div class="listElement">
          <p class="subtitle">{{ 'group.action.archive.message.notAllOwner.item3' | t : {optionNumber: otherOwners.length ? 3 : 2} }} </p>
          <p class="list-element-description">{{ 'group.action.archive.message.notAllOwner.item3.description' | t }} </p>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="isOwnerAllChanells">
      <ui-justification-confirmation-modal 
             [forceInvalid]="!isOwnerAllChanells" 
             [needsJustification]="false" 
             [closeOnCompletion]="false"
             [confirmationData]="confirmationData"
             ></ui-justification-confirmation-modal>
    </ng-container>
  </div>

</base-dialog>

<ng-template #loading>
  <div class="content"> 
    <ui-spinner size="medium"></ui-spinner>
  </div>
</ng-template>
