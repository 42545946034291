import { Component, Input } from '@angular/core';
import { RequestStatusEnum } from '@ui-kit/util/icon-util';
import { RequestList } from 'projects/@common/services/api/requests/requests.definitions';

@Component({
  selector: 'request-status-badge',
  templateUrl: './request-status-badge.component.html',
  styleUrls: [ './request-status-badge.component.scss' ],
})
export class RequestStatusBadgeComponent {
  @Input() public request: RequestList;
  public requestStatusEnum = RequestStatusEnum;

  constructor() {}
}
