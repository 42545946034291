import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { UiTabs, UiTabsColor } from '@ui-kit/components/ui-tabs/ui-tabs.component';
import { GroupTypeEnum } from '@ui-kit/util/icon-util';
import { TemplateStatesFilterEnum } from 'projects/@assist-ops/definitions/templateStatesFilter.enum';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { GroupTemplatesApiService } from 'projects/@common/services/api/sg/group-templates/group-templates-api.service';
import {
  GroupTemplateReach,
  IGroupTemplate
} from 'projects/@common/services/api/sg/group-templates/group-templates.definitions';
import { MobileService } from 'projects/@common/services/mobile.service';
import { ModelsHelpDialogComponent } from './help-dialog/models-help-dialog.component';

interface Templates {
  items: any[];
  total: number;
}
@Component({
  selector: 'sg-model-section',
  templateUrl: './model-section.component.html',
  styleUrls: [ './model-section.component.scss' ],
})
export class ModelSectionComponent implements OnInit {
  @ViewChild('tabs') public tabs: UiTabs;

  @Input() public modelType: GroupTypeEnum = null;
  @Input() public currentTemplate = null;
  @Input() public showChannels = true;

  public uiTabsColor = UiTabsColor;

  public fulltext: string = null;

  public specificTemplates: Templates = { items: [], total: 0 };
  public genericTemplates: Templates = { items: [], total: 0 };

  public userAttributeValue: string;

  public hasContextualisation: boolean;

  public isLoading = false;

  public modelForm: UntypedFormGroup;

  public selectedTab;

  private from = null;

  constructor(
    private groupModelApiService: GroupTemplatesApiService,
    private fb: UntypedFormBuilder,
    public i18n: I18nService,
    public dialog: MatDialog,
    private mobileService: MobileService
  ) { }

  ngOnInit(): void {
    this.modelForm = this.fb.group({
      securityModel: this.fb.control(null, [ Validators.required ]),
    });
    this.loadModels();
  }

  private loadModels(): void {
    this.isLoading = true;
    this.groupModelApiService
      .getEcoGroupTemplates({
        direction: 'ASC',
        sort: 'name',
        from: this.from,
        limit: 25,
        state: TemplateStatesFilterEnum.PUBLISHED,
        fulltext: this.fulltext,
        type: this.modelType,
        splitGlobalAndSegmented: true,
      })
      .then((response) => {
        this.userAttributeValue = response.userAttributeValue;
        this.hasContextualisation = response.hasContextualisation;

        const specificTemplates = response.items[GroupTemplateReach.SEGMENTED] || [];
        this.specificTemplates.items.push(...(response.hasContextualisation ? specificTemplates.filter((template) => template.attribute === this.userAttributeValue) : specificTemplates));

        const tempGenericTemplates = [ ...(response.items[GroupTemplateReach.GLOBAL] || []), ...(response.items['undefined'] || []) ];

        this.genericTemplates.items.push(...(tempGenericTemplates));
        this.currentTemplate = [ ...this.genericTemplates.items, ...this.specificTemplates.items ].find((template) => template.id === this.modelForm.get('securityModel')?.value?.id);

        this.genericTemplates.total = response.total?.globalTotal === 0 ? 0 : (response.total?.globalTotal || response.total);
        this.specificTemplates.total = response.total?.segmentTotal;

        // No specific available or picked generic previously, go to generics
        if ((this.specificTemplates.total === 0 && this.genericTemplates.total > 0) || this.currentTemplate?.reach === GroupTemplateReach.GLOBAL) {
          this.tabs?.selectTabIndex(1);
        }

        this.from = response.nextToken;
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  public handleRefresh(): void {
    this.specificTemplates = { items: [], total: 0 };
    this.genericTemplates = { items: [], total: 0 };
    this.from = 0;
    this.loadModels();
  }

  public handleSearchQuery(query: string): void {
    this.fulltext = query;
    this.handleRefresh();
  }

  public handleSearchClear(): void {
    this.fulltext = null;
    this.handleRefresh();
  }

  public openContextualHelp(): void {
    this.dialog.open(ModelsHelpDialogComponent, {
      maxWidth: !this.mobileService.isMobile() ? '75%' : '100%',
      width: 'max-content',
      panelClass: 'custom-dialog',
      data: {},
    });
  }

  public handleTemplateSelection(template: IGroupTemplate): void {
    this.currentTemplate = template;
    this.modelForm.setValue({ securityModel: template });
  }

  public onTabChange(tab: any) {
    this.selectedTab = tab;
    if (this.fulltext) {
      this.handleSearchClear();
    }
  }

  public get emptyTableKey(): string {
    let key = 'groups.dialog.model.card.details.empty.specific';
    if (this.hasContextualisation) {
      key += '.segmented';
      if (!this.userAttributeValue) {
        key += '.noAttribute';
      }
    }
    return key;
  }
}
