import { Component, Input, OnInit } from '@angular/core';
import {
  BadgeEnum,
  GetOrganizationProfileResponse,
  IConformities,
  IUserAction,
  IUserRisks,
} from '@common/services/api/sg/md.eco-profile/md.eco-profile.definition';
import { Store } from '@ngxs/store';
import { ProfileCardColorEnum } from '../eco-profile-card/eco-profile-card.component';
import { toTitleCaseName } from 'projects/@common/utils/utils';
import { EcoSessionState } from '@common/modules/session/state/session.state';

export type UserProfile = {
  userActions: IUserAction;
  conformities: IConformities;
  badges: {
    userBadges: BadgeEnum[];
    organizationBadgesCount: number;
  };
  riskFactors?: IUserRisks
};

@Component({
  selector: 'user-info-dashboard',
  templateUrl: './user-info-dashboard.component.html',
  styleUrls: ['./user-info-dashboard.component.scss'],
})
export class UserInfoDashbaordComponent implements OnInit {
  @Input() private userProfile: UserProfile & { hasUsersReporting : number , teamName: string} ;
  @Input() isTeamDashboard = false;
  @Input() color = ProfileCardColorEnum.green;
  constructor(private readonly store: Store) {}

  public badges: any[];
  public portrait;

  ngOnInit() {
    this.setupPortrait();
    this.setupBadges();
  }

  private setupBadges(): void {
    this.badges = this.isTeamDashboard
      ? [
          {
            icon: 'assets/favicons/icon_pause_warning.svg',
            name: 'eco-profile.page.tab.user-profile.user-info.badge.shares',
            quantity: this.userProfile.riskFactors?.amountShares ?? 0
          },
          {
            icon: 'assets/favicons/icon_pause_alert.svg',
            name: 'eco-profile.page.tab.user-profile.user-info.badge.resources',
            quantity: this.userProfile.riskFactors?.amountResources ?? 0
          },
          {
            icon: 'assets/favicons/icon_show_enable.svg',
            name: 'eco-profile.page.tab.user-profile.user-info.badge.sensitiveFiles',
            quantity: this.userProfile.riskFactors?.amountSensitiveFiles ?? 0
          },
        ]
      : [
          {
            icon: 'assets/favicons/icon_Thumbs_up_green.svg',
            name: 'eco-profile.page.tab.user-profile.user-info.badge.action',
            quantity: this.userProfile.userActions.total - this.userProfile.userActions.revokeUsers,
          },
          {
            icon: 'assets/favicons/icon_pause_ok_no_fill.svg',
            name: 'eco-profile.page.tab.user-profile.user-info.badge.week',
            quantity: this.userProfile.conformities.yearlyCount?.[new Date().getFullYear()] || 0,
          },
          {
            icon: 'assets/favicons/icon_medal.svg',
            name: 'eco-profile.page.tab.user-profile.user-info.badge.amount',
            quantity: this.userProfile.badges.userBadges.length,
          },
        ];
  }

  private setupPortrait(): void {
    const username = toTitleCaseName(this.store.selectSnapshot(EcoSessionState.userName).split(' ')[0]);

    this.portrait = {
      hasUsersReporting: this.userProfile.hasUsersReporting ?? 0,
      teamName: this.userProfile.teamName,
      name: username.charAt(0).toUpperCase() + username.slice(1),
      year: new Date().getFullYear(),
    };
  }
}
