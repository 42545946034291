import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { MobileService } from 'projects/@common/services/mobile.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { clone, normalizeString } from "projects/@common/utils/utils";
import { GroupsApiService } from 'projects/@common/services/api/sg/groups/groupsApi.service';
import { IOwnerGroup } from 'projects/console-sg/src/app/pages/teams-resources/services/resources-list.service';
import { CertifyHelpDialogComponent } from './help-dialog/certify-help-dialog.component';

@Component({
  selector: 'sg-certify-section',
  templateUrl: './certify-section.component.html',
  styleUrls: [ './certify-section.component.scss' ],
})
export class CertifySectionComponent implements OnInit {
  @Input() public dataFromQuickAction: {
    group: IOwnerGroup;
    fromQuickAction: boolean;
    refresh: any;
  };
  @Input() public cloneUsers = [];
  @Input() public firstLoad = true;
  @Output() public hasLoaded = new EventEmitter();

  public certifyForm: UntypedFormGroup;

  public fulltext = null;

  public owners = [];

  public profileUsers = [];


  public total = 0;

  public isLoading = false;

  constructor(
    public i18n: I18nService,
    private fb: UntypedFormBuilder,
    private groupsApiService: GroupsApiService,
    private mobileService: MobileService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.certifyForm = this.fb.group({
      keepStructure : this.fb.control(false, [ Validators.required ]),
      usersLoaded: this.fb.control(null, [ Validators.required ]),
    });
    this.getGroupMembers(this.dataFromQuickAction.group);
  }

  public onUserSearch(query: string): void {
    this.fulltext = query;
    this.setUserFilter();
  }

  public setUserFilter(): void {
    this.onClear();
    if (this.fulltext) {
      this.isLoading = true;
      this.profileUsers = this.profileUsers?.filter((user) => user.name.toLowerCase().includes(normalizeString(this.fulltext)));
      this.isLoading = false;
    }
  }

  public async getGroupMembers(group: IOwnerGroup): Promise<void> {
    this.isLoading = true;

    this.groupsApiService.listGroupMembers(group.id, this.fulltext).then((res) => {
      if (this.firstLoad) {
        this.total = res.members.length;
        this.profileUsers = res.members.map((member) => ({
          ...member,
          isCertified: true,
        })).sort((a, b) => a.name.localeCompare(b.name));
        this.owners = this.profileUsers.filter((member) => member.isOwner);
        this.cloneUsers = clone(this.profileUsers);
      } else {
        this.total = this.cloneUsers.length;
        this.profileUsers = res.members.map((member) => ({
          ...member,
          isCertified: this.cloneUsers.find((user) => user.o365UserId === member.o365UserId).isCertified,
        })).sort((a, b) => a.name.localeCompare(b.name));
      }
    }).finally(() => {
      this.isLoading = false;
      this.firstLoad = false;
      this.hasLoaded.emit();
      this.certifyForm.patchValue({
        keepStructure : this.certifyForm.value.keepStructure,
        usersLoaded: true,
      });
    });
  }

  public onCertify(value: any): void {
    if ([ true, false ].includes(value.isCertified)) {
      this.cloneUsers.find((user) => user.o365UserId === value.id).isCertified = value.isCertified;
    }
  }

  public openContextualHelp(): void {
    this.dialog.open(CertifyHelpDialogComponent, {
      maxWidth: !this.mobileService.isMobile() ? '75%' : '100%',
      width: 'max-content',
      panelClass: 'custom-dialog',
      data: {
      },
    });
  }


  private onClear(): void {
    this.profileUsers = this.cloneUsers;
  }
}
