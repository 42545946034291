import { Injectable } from "@angular/core";
import { CanActivate, UrlTree } from "@angular/router";
import { Stages } from "projects/@common/definitions/stages";
import { environment } from "projects/@common/environments/environment";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class DevEnvironmentGuard implements CanActivate {
  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return environment.stage === Stages.DEV;
  }
}
