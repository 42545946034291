<div class="breadcrumbs-container">
  <ng-container *ngFor="let item of breadcrumbs;let last = last">
    <span
      [ngClass]="item.nodeLink !== null ? 'back' : 'grey'"
      [routerLink]="item.nodeLink ? item.nodeLink : null"
      [queryParams]="item.nodeParams ? item.nodeParams : null">
      {{item.nodeName | t}}
    </span>
    <span class="chevron" *ngIf="!last"> > </span>
  </ng-container>
</div>
