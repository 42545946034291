import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Select } from "@ngxs/store";
import { GroupTypeEnum } from "@ui-kit/util/icon-util";
import { EditWithModalComponent } from "projects/@common/components/edit-with-modal/edit-with-modal.component";
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { EcoSessionState } from "projects/@common/modules/session/state/session.state";
import { MobileService } from "projects/@common/services/mobile.service";
import { IOwnerGroup } from 'projects/console-sg/src/app/pages/teams-resources/services/resources-list.service';
import { Observable } from 'rxjs';
import { ApplyModelDialogComponent } from "../apply-model-dialog/apply-model-dialog.component";
import { ArchiveGroupDialogComponent } from "../archive-group-dialog/archive-group-dialog.component";
import { CertifyAccessDialogComponent } from '../certify-action/certify-access-dialog/certify-access-dialog.component';
import { EditGroupExpirationDateDialogComponent } from "../edit-group-expiration-date-dialog/edit-group-expiration-date-dialog.component";
import { DropdownActions } from "./dropdownActions.enum";

@Component({
  selector: 'group-actions',
  templateUrl: './group-actions.component.html',
  styleUrls: [ './group-actions.component.scss' ],
})
export class GroupActionsComponent implements OnInit {
  @Select(EcoSessionState.user) public currentUser$: Observable<string>;

  @ViewChild('editor') editWithModalComponent: EditWithModalComponent;

  @Input() public disabled = false;

  @Input() public group: IOwnerGroup;

  @Input() public groupType: GroupTypeEnum;

  @Input() public name: string;

  @Input() public hasArchiver = false;

  @Output() public refreshEvent = new EventEmitter();

  public dropdownActions = DropdownActions;

  public options: { value: string, translated: string; }[];

  public optionIcons = {
    EXP_DATE: "icon_calendar_dark",
    ARCHIVE: "icon_archive",
    CERTIFY: "icon_certify_access_dark",
    TEMPLATE: "icon_model_picker",
  };

  public selected: { value: string; } = { value: null };

  public dialogTitle = 'groups.action.message.';

  private currentUser: any;

  constructor(
    private eRef: ElementRef,
    private i18n: I18nService,
    private dialog: MatDialog,
    public mobileService: MobileService
  ) {
  }

  public get windowWidthSmallerThan(): boolean {
    return this.mobileService.windowWidthSmallerThan(1350);
  }

  @HostListener('document:keydown.escape', [ '$event' ])
  onEscapeKey(event: KeyboardEvent): void {
    this.close();
  }

  @HostListener('document:click', [ '$event' ])
  onOutsideClick(event: any): void {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.close();
    }
  }

  ngOnInit(): void {
    this.currentUser$.subscribe((currentUser) => this.currentUser = currentUser);
  }

  ngOnChanges(): void {
    this.getOptions(!!this.group.securityModel.name);
  }

  public toggle(): void {
    this.editWithModalComponent.toggle();
  }

  public open(): void {
    if (!this.disabled && !this.editWithModalComponent.isOpen()) {
      this.editWithModalComponent.open();
    } else {
      this.close();
    }
  }

  public close(): void {
    this.editWithModalComponent.close();
  }

  public refresh() {
    this.refreshEvent.emit();
  }

  public selectOption(action: DropdownActions): void {
    switch (action) {
      case DropdownActions.EXP_DATE:
        this.dialog.open(EditGroupExpirationDateDialogComponent, {
          maxWidth: !this.mobileService.isMobile() ? this.group.securityModel.expirationTrigger === 'CREATION' ? '40%' : '75%' : '100%',
          minWidth: '750px',
          panelClass: 'custom-dialog',
          data: { group: this.group, refresh: this.refresh.bind(this) },
        });
        break;
      case DropdownActions.ARCHIVE:
        this.dialog.open(ArchiveGroupDialogComponent, {
          minWidth: '730px',
          maxWidth: !this.mobileService.isMobile() ? '50%' : '100%',
          panelClass: 'custom-dialog',
          data: {
            group: this.group,
            refresh: this.refresh.bind(this),
          },
        });
        break;
      case DropdownActions.TEMPLATE:
        this.dialog.open(ApplyModelDialogComponent, {
          minWidth: '730px',
          width: !this.mobileService.isMobile() ? '50%' : '100%',
          maxHeight: '85vh',
          panelClass: 'custom-dialog',
          data: {
            group: this.group,
            refresh: this.refresh.bind(this),
          },
        });
        break;
      case DropdownActions.CERTIFY:
        this.dialog.open(CertifyAccessDialogComponent, {
          panelClass: 'custom-dialog',
          data: {
            name: this.name,
            group: this.group,
            refresh: this.refresh.bind(this),
          },
        });
        break;
    }
    this.close();
  }

  public getOptions(hasSecurityModel: boolean): void {
    this.options = [
      { value: "TEMPLATE", translated: this.i18n.translate('group.action.remediation.button.model') },
      { value: "CERTIFY", translated: this.i18n.translate('group.action.remediation.button.certify') },
    ];

    if (hasSecurityModel) {
      this.options = this.options.concat([ { value: "EXP_DATE", translated: this.i18n.translate('group.action.remediation.button.date') } ]);
    }

    if (this.hasArchiver) {
      this.options.push({ value: "ARCHIVE", translated: this.i18n.translate('group.action.remediation.button.archive') });
    }
  }
}
