<div class="toolbar-header">

  <ng-content select="[contentBeforeTotal]"></ng-content>

  <ui-table-total-tool
    *ngIf="total != null"
    [total]="total"
  ></ui-table-total-tool>

  <ng-content></ng-content>

  <div
    class="filter-toggle"
    (click)="toggleFilters()"
    [ngClass]="{ 'filter-toggle--expanded': areFiltersExpanded }"
    *ngIf="filtersInsideCollapsableArea?.length"
  >
    <div class="filter-toggle--button-elements">
      <div class="filter-toggle--button-elements--name">
        <img src="assets/favicons/icon_filter.svg" />

        {{ 'toolbar.filters.collapsable.button.name' | t }}
      </div>

      <ui-badge
        [value]="activatedFiltersNumber"
        [isHidden]="activatedFiltersNumber < 1"
      ></ui-badge>

      <img
        [src]="
          'assets/favicons/icon_chevron_' +
          (areFiltersExpanded ? 'up' : 'open') +
          '.svg'
        "
      />
    </div>
  </div>
</div>

<div
  *ngIf="!mobileService.isMobile()"
  class="filters-container"
  [ngClass]="
    areFiltersExpanded
      ? 'filters-container--expanded'
      : 'filters-container--collapsed'
  "
>
  <div class="expandable-box">
    <section class="expandable-box--header">
      <img src="assets/favicons/icon_filter.svg" />

      {{ 'toolbar.filters.collapsable.button.name' | t }}

      <ui-anchor
        [label]="'toolbar.filters.eraseAll.button.name' | t"
        (onClick)="onClearAllFilters.emit()"
      ></ui-anchor>
    </section>

    <section class="expandable-box--filters">
      <div
        *ngFor="let filterInsideCollapsableArea of filtersInsideCollapsableArea"
      >
        <label ui-label *ngIf="filterInsideCollapsableArea.elementTitle">
          {{ filterInsideCollapsableArea.elementTitle | t }}
        </label>
        <ng-template
          [ngTemplateOutlet]="filterInsideCollapsableArea.template"
        ></ng-template>
      </div>
    </section>
  </div>
</div>

<div
  *ngIf="mobileService.isMobile() && areFiltersExpanded"
  class="filters-container--mobile"
>
  <div class="filters-container--mobile--title">
    {{ 'toolbar.filters.collapsable.button.name' | t }}
  </div>

  <div class="filters-container--mobile--filters">
    <div
      *ngFor="let filterInsideCollapsableArea of filtersInsideCollapsableArea"
      class="filters-container--mobile--filter"
    >
      <label ui-label *ngIf="filterInsideCollapsableArea.elementTitle">
        {{ filterInsideCollapsableArea.elementTitle | t }}
      </label>
      <ng-template
        [ngTemplateOutlet]="filterInsideCollapsableArea.template"
      ></ng-template>
    </div>
  </div>

  <ui-form-actions layout="centered" class="visible">
    <button
      ui-button
      class="filters-container--mobile--button"
      color="standard"
      (click)="applyMobileFilters()"
    >
      {{ 'common.action.apply' | t }}
    </button>
    <button
      ui-button
      class="filters-container--mobile--button"
      color="secondary"
      (click)="cancelMobileFilters()"
    >
      {{ 'common.action.cancel' | t }}
    </button>
    <button
      ui-button
      class="filters-container--mobile--button"
      color="secondaryActionFlat"
      (click)="clearAllMobileFilters()"
    >
      {{ 'toolbar.filters.eraseAll.button.name' | t }}
    </button>
  </ui-form-actions>
</div>
