import { ChangeDetectionStrategy, Component, ContentChildren, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { SgStep } from './sg-step.directive';

export enum StepperNavigation {
  back = 'back',
  forward = 'forward'
}

@Component({
  selector: 'sg-stepper',
  templateUrl: './sg-stepper.component.html',
  styleUrls: [ './sg-stepper.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  preserveWhitespaces: false,
})
export class SgStepper implements OnInit {
  @ViewChild('stepper') private stepper: MatStepper;
  @ContentChildren(SgStep) public steps: QueryList<SgStep>;

  @Input() public isGuestUserType = false;
  @Input() public stepperHeight = '100%';
  @Input() public minWidth;
  @Input() public maxWidth;
  @Input() public isSaving = false;

  @Output() public onCancel: EventEmitter<void> = new EventEmitter();
  @Output() public onSave: EventEmitter<void> = new EventEmitter();
  @Output() public onNavigation: EventEmitter<{ index: number, direction: StepperNavigation }> = new EventEmitter();

  public get saveButtonDisabled(): boolean {
    return !this.steps.get(this.stepper?.selectedIndex || 0).saveButton;
  }

  public ngOnInit() {
  }

  public cancel() {
    this.onCancel.emit();
  }

  public save() {
    this.onSave.emit();
  }

  public goBack() {
    this.steps.get(this.stepper?.selectedIndex).onNavigation.emit({ index: this.stepper?.selectedIndex, direction: StepperNavigation.back });
    this.stepper.previous();
  }

  public goForward() {
    this.steps.get(this.stepper?.selectedIndex).onNavigation.emit({ index: this.stepper?.selectedIndex, direction: StepperNavigation.forward });
    this.stepper.next();
  }
}
