import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import {
  FileShareTypeEnum,
  ISharedFilePermission
} from 'projects/@common/services/api/sg/shares/shares.definitions';
import { MobileService } from 'projects/@common/services/mobile.service';

@Component({
  selector: 'shared-to',
  templateUrl: './shared-to.component.html',
  styleUrls: [ './shared-to.component.scss' ],
  host: {
    class: 'shared-to',
  },
  encapsulation: ViewEncapsulation.None,
})
export class SharedTo implements OnInit {
  @Input() public sharedFilePermission: ISharedFilePermission;

  @Input() public withSpecialMargins = true;

  @Input() public isAutomaticRevokeActivated = false;

  @Input() public isEditing = false;

  public iconName: string;

  public linkName: string;

  public isLink = false;

  constructor(public readonly mobileService: MobileService) {}

  public ngOnInit() {
    switch (this.sharedFilePermission.shareType) {
      case FileShareTypeEnum.PUBLIC:
        this.iconName = 'icon_link_public';
        this.linkName = 'sharedFile.drawer.shares.table.shareType.public';
        this.isLink = true;
        break;

      case FileShareTypeEnum.COMPANY:
        this.iconName = 'icon_link_organization';
        this.linkName = 'sharedFile.drawer.shares.table.shareType.organization';
        this.isLink = true;
        break;

      default:
        break;
    }
  }
}
