import { RequestStatusEnum, GroupTypeEnum } from '@ui-kit/util/icon-util';
import { ResourceTypeEnum } from 'projects/@common/services/api/sg/shares/shares.definitions';

export interface IRequest {
  guid?: string;
  object?: string;
  object_fr?: string;
  object_en?: string;
  justification?: string;
  justification_fr?: string;
  justification_en?: string;
  error?: string;
  requestInformation?: any;
  createdAt?: number;
  updatedAt?: number;
  requester?: any;
  managers?: any;
  status?: RequestStatusEnum;
  requestType?: RequestTypeEnum;
  number?: number;
  canProcess?: boolean;
  assignedToSpecificUser?: any;
}

export declare enum RequestTypeEnum {
  RESOURCE_CREATION = "RESOURCE_CREATION",
  PROFILE_GROUP = "PROFILE_GROUP",
  PROFILE_ROLE = "PROFILE_ROLE",
  GUEST_USER_INVITATION = "GUEST_USER_INVITATION",
  GUEST_USER_RENEWAL = "GUEST_USER_RENEWAL",
  CHANNEL_TEMPLATE_APPROBATION = "CHANNEL_TEMPLATE_APPROBATION",
  CHANNEL_DELETION = "CHANNEL_DELETION",
  GROUP_ARCHIVING = "GROUP_ARCHIVING"
}

export interface IRequestCreation {
  request?: IRequest;
  [key: string]: any;
}

export interface IGroupCreation {
  name: string;
  description?: string;
  groupType: GroupTypeEnum;
  isTeamsEnabled?: boolean;
  email?: string;
  visibility?: GroupVisibilityEnum;
  confidentialityLevel?: GroupConfidentialityLevelEnum;
  ipAddress?: string;
  externalUrl?: string;
  comment?: string;
  owners?: IGroupOwner[];
  websitehost?: string;
  websitehostId?: string;
  virtualHostName?: string;
  virtualHostId?: string;
  logoUrl?: string;
  templateId?: string;
  channels?: IGroupCreationChannel[];
  defaultSharingType?: DefaultSiteSharingLinkTypeEnum;
}

export interface IGroupOwner {
  name: string;
  o365UserId: string;
}

export interface IPutGroup {
  templateId: string,
  channels: any
}

export enum RoleAccessTypeEnum {
  STANDARD = "STANDARD",
  PRIVILEGED = "PRIVILEGED"
}

export enum GroupVisibilityEnum {
  PUBLIC = "Public",
  PRIVATE = "Private",
  MONDATA = "MonData"
}

export enum GroupConfidentialityLevelEnum {
  CONFIDENTIAL = "CONFIDENTIAL",
  RESTRICTED = "RESTRICTED",
  PUBLIC = "PUBLIC"
}

export enum OfficeSynchronizationStatusEnum {
  VOID = "VOID",
  ERROR = "ERROR",
  PENDING = "PENDING",
  COMPLETED = "COMPLETED",
  DELETING = "DELETING",
  SYNC = "SYNC"
}

export interface IDefaultSharingType {
  organization: IDefaultSharingTypeAttributes;
  site: IDefaultSharingTypeAttributes;
}

export enum DefaultSiteSharingLinkTypeEnum {
  TENANT_DEFAULT = 0,
  SPECIFIC_PEOPLE = 1,
  ONLY_ORGANIZATION = 2,
  ANYONE = 3,
  EXISTING_ACCESS = 4
}

export enum DefaultTenantSharingLinkTypeEnum {
  SPECIFIC_PEOPLE = 1,
  ONLY_ORGANIZATION = 2,
  ANYONE = 3
}

export interface IDefaultSharingTypeAttributes {
  type: DefaultSiteSharingLinkTypeEnum | DefaultTenantSharingLinkTypeEnum;
  isAnyoneAccessAllowed: boolean;
}

export interface IGroup {
  guid?: string;
  name: string;
  description?: string;
  o365GroupId?: string;
  groupType: GroupTypeEnum;
  isO365Group?: boolean;
  isTeamsEnabled?: boolean;
  email?: string;
  organizationId?: string;
  createdAt?: number;
  updatedAt?: number;
  visibility?: GroupVisibilityEnum;
  confidentialityLevel?: GroupConfidentialityLevelEnum;
  ipAddress?: string;
  externalUrl?: string;
  logoUrl?: string;
  comment?: string;
  owners?: any[];
  groups?: IGroup[];
  resources?: IResource[];
  applicationOfficeSynchronizationStatus?: OfficeSynchronizationStatusEnum;
  memberOfficeSynchronizationStatus?: OfficeSynchronizationStatusEnum;
  ownerOfficeSynchronizationStatus?: OfficeSynchronizationStatusEnum;
  sharepointUrl?: string;
  websitehost?: string;
  websitehostId?: string;
  virtualHostName?: string;
  virtualHostId?: string;
  isFavorite?: boolean;
  membersNumber?: number;
  suspendedNumber?: number;
  availableNumber?: number;
  defaultSharingType?: IDefaultSharingType;
  defaultGuestUserExpirationTime?: number;
  // template?: IGroupTemplate;
  channels?: IGroupCreationChannel[];
}

export interface IGroupCreationChannel {
  name: string;
  ownersGuid: string[];
}

export interface ITeamsChannel {
  guid?: string;
  name?: string;
  o365GroupId?: string;
  description?: string;
  isPrivate?: boolean;
  organizationId?: string;
  owners?: any[];
  members?: any[];
  guestUsers?: any[];
  membersCount?: number;
  o365ChannelId?: string;
  email?: string;
  status?: string;
}

export interface IGroupLifecycle {
  projectedDeletionDate?: number;
  lastActivity?: number;
  isSetToBeArchived?: boolean;
  // templateLifecycleOptions: IGroupTemplateLifecycle;
}

export interface IResource {
  guid?: string;
  name_fr?: string;
  name_en?: string;
  status?: string;
  description_fr?: string;
  description_en?: string;
  organizationId?: string;
  createdAt?: number;
  updatedAt?: number;
  owners?: any[];
  membersNumber?: number;
  resourceType?: ResourceTypeEnum;
  templateName?: string;
  suspendedNumber?: number;
  availableNumber?: number;
  accessType?: RoleAccessTypeEnum;
  isDefaultRole?: boolean;
  isFavorite?: boolean;
  logoUrl?: string;
  groups?: IGroup[];
  officeId?: string;
  externalUrl?: string;
  groupType?: GroupTypeEnum;
  applicationOfficeSynchronizationStatus?: OfficeSynchronizationStatusEnum;
  visibility?: GroupVisibilityEnum;
  confidentialityLevel?: GroupConfidentialityLevelEnum;
  teamsChannels?: ITeamsChannel[];
  memberOfficeSynchronizationStatus?: OfficeSynchronizationStatusEnum;
  ownerOfficeSynchronizationStatus?: OfficeSynchronizationStatusEnum;
  lifecycleOptions?: IGroupLifecycle;
  expirationDate?: number;
  websitehost?: string;
  websitehostId?: string;
  virtualHostName?: string;
  virtualHostId?: string;
}

export interface IGroupMember {
  o365UserId: string;
  name: string;
  upn: string;
  isGuest?: boolean;
  isOwner?: boolean;
  isPrivateChannelOwner?: boolean;
  isInactive?: boolean;
  isDisabled?: boolean;
  isCertified: boolean;
}


