import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'sensitive-files-description',
  templateUrl: './sensitive-files-description.component.html',
  styleUrls: [ './sensitive-files-description.component.scss' ],
})
export class SensitiveFilesDescriptionComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {

  }
}
