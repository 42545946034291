import { Component, Input, OnChanges, ViewChild } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { UiTabs } from '@ui-kit/components/ui-tabs/ui-tabs.component';
import { AbstractDrawer } from 'projects/@common/modules/layout/components/drawer/AbstractDrawer';
import { DrawerState } from 'projects/@common/modules/layout/components/drawer/stores/drawer.store';
import { RequestsApiService } from 'projects/@common/services/api/requests/requests-api.service';
import { GetRequestResponse, RequestAssignedToEnum, RequestHistory, RequestTypeEnum } from 'projects/@common/services/api/requests/requests.definitions';
import { Observable } from 'rxjs';

@Component({
  selector: 'request-drawer',
  templateUrl: './request-drawer.container.html',
})
export class RequestDrawerContainer extends AbstractDrawer implements OnChanges {
  @Input('data') public data: any = null;

  @Select(DrawerState.isEditing) public isEditing$: Observable<boolean>;

  @Select(DrawerState.tabName) public tabName$: Observable<string>;

  @ViewChild('tabs', { static: false }) tabs: UiTabs;

  public isLoading = true;
  public request: GetRequestResponse;

  public requestApproversHistory: RequestHistory[];

  public hasNotBeenProcessed = false;

  public displayDetailsTab = false;

  private onChangesExecuted = false;

  constructor(private readonly store: Store, private readonly requestsService: RequestsApiService) {
    super(store);
  }

  public ngOnInit() {
    // ngOnChanges is not called when the Inputs are modified manually like in the dynamicComponent case
    if (!this.onChangesExecuted) {
      this.loadRequest();
    }
  }

  public ngOnChanges() {
    this.onChangesExecuted = true;
    this.loadRequest();
  }

  private loadRequest() {
    this.isLoading = true;
    this.requestsService
      .getRequest(this.data.requestId)
      .then((request) => {
        this.request = request;
        this.requestApproversHistory = [ ...request.history, {
          name: request.requesterName,
          type: RequestAssignedToEnum.REQUESTER,
          note: request.justification,
          timestamp: request.createdAt,
        } ];
        this.displayDetailsTab = [ RequestTypeEnum.RESOURCE_CREATION, RequestTypeEnum.GUEST_RESOURCE_ADD ].includes(request.type as RequestTypeEnum);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }
}
