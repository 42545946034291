<div>
  <div>
    <span class="expiration" *ngIf="showExpiration">
      <div class="left-image">
        <img src="assets/favicons/icon_calendar.svg" />
      </div>
      <p>
        {{
          'groups.dialog.model.card.expiration.date.' + template.lifecycle.expirationTrigger
            | t: { resourceType: getTranslatedGroupType(template), expDays: template.lifecycle.expirationDuration }
        }}
      </p>
      <ui-tooltip [placementRight]="false"  icon="assets/favicons/icon_info_small.svg">
        {{
          'groups.dialog.model.card.expiration.tooltip.' + template.lifecycle.expirationTrigger
            | t
              : {
                  resourceType: getTranslatedGroupType(template).toLowerCase(),
                  expDays: template.lifecycle.expirationDuration
                }
        }}
      </ui-tooltip>
    </span>

    <span *ngIf="template.reach" class="expiration segmentation">
      <div class="left-image">
        <img [src]="segmentationInformation.icon" />
      </div>
      <p>{{ segmentationInformation.text }}</p>
    </span>

    <collapsable-description
      *ngIf="showChannels"
      [title]="getFoldersSectionTitle(template)"
      [descriptionExpanded]="expandChannels"
      icon="assets/favicons/icon_folder.svg"
    >
      <ng-container
        *ngTemplateOutlet="template.type === 'o365OfficeTeams' ? channels : folders; context: { template: template }"
      >
      </ng-container>
    </collapsable-description>
    <collapsable-description
      *ngIf="getTotalPermissions(template.permissions) > 0 && showPermissions"
      [title]="
        'groups.dialog.model.card.permissions.title'
          | t: { permissionsNumber: getTotalPermissions(template.permissions) }
      "
      [descriptionExpanded]="false"
      icon="assets/favicons/icon_permissions.svg"
    >
      <ng-container *ngTemplateOutlet="permissions; context: { template: template }"> </ng-container>
    </collapsable-description>
  </div>
</div>

<ng-template #folders let-template="template">
  <div class="collapsable-content">
    <div class="folders" *ngIf="template.folders.length > 0">
      <p class="independant-folders" *ngIf="template.type === 'o365OfficeTeams'">
        {{ 'groups.dialog.model.card.folders.title' | t }}
      </p>
      <ng-container
        *ngTemplateOutlet="
          recursiveFolders;
          context: {
            folders: template.folders,
            count: 0,
            groupType: template.type
          }
        "
      >
      </ng-container>
    </div>
    <div class="folders" *ngIf="template.folders.length === 0">
      <p *ngIf="template.type === 'o365OfficeGroup'">
        {{ 'groups.dialog.model.card.folders.empty.sharepoint' | t }}
      </p>
    </div>
  </div>
</ng-template>

<ng-template #channels let-template="template">
  <div class="collapsable-content">
    <div class="folders">
      <ng-container *ngFor="let channel of template.channels; let index = index">
        <span class="text-image channel-name">
          <div class="thing">
            <p class="special-width">{{ index + 1 }}.</p>
            <p>{{ channel.name }}</p>
          </div>
          <img
            *ngIf="channel.isPrivate"
            [tooltip]="'groups.dialog.model.card.channels.private.tooltip' | t"
            src="assets/favicons/icon_small_lock.svg"
          />
        </span>

        <div *ngIf="channel.folders?.length > 0">
          <ng-container
            *ngTemplateOutlet="
              recursiveFolders;
              context: { folders: channel.folders, count: 0, groupType: template.type }
            "
          >
          </ng-container>
        </div>
      </ng-container>
    </div>
  </div>
  <ng-container *ngTemplateOutlet="folders; context: { template: template }"> </ng-container>
</ng-template>

<ng-template #permissions let-template="template">
  <div class="collapsable-content">
    <ng-container *ngFor="let permission of template.permissions | keyvalue">
      <div *ngIf="shouldShowSubpermissions(permission.value)" class="permissions">
        <p>{{ 'groupTemplates.permissions.' + permission.key + '.title' | t }}</p>
        <ng-container *ngFor="let subpermission of permission.value | keyvalue">
          <span class="subpermission" *ngIf="subpermission.value">
            <img src="assets/favicons/icon_small_check.svg" />
            <p>{{ 'groupTemplates.permissions.' + permission.key + '.' + subpermission.key | t }}</p>
          </span>
        </ng-container>
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-template #recursiveFolders let-folders="folders" let-count="count" let-groupType="groupType">
  <ng-container *ngFor="let folder of folders">
    <span
      class="text-image folder-element"
      [ngStyle]="{ 'padding-left': (groupType === 'o365OfficeTeams' ? 20 : 0) + count * 12 + 'px' }"
    >
      <img src="assets/favicons/icon_folder_pale_grey.svg" />
      <p>{{ folder.name }}</p>
    </span>
    <div *ngIf="folder?.subFolders?.length > 0 && count < 8">
      <ng-container
        *ngTemplateOutlet="
          recursiveFolders;
          context: { folders: folder.subFolders, count: count + 1, groupType: groupType }
        "
      ></ng-container>
    </div>
  </ng-container>
</ng-template>
