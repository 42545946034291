import { Component, Input } from '@angular/core';

export interface IBreadcrumbs {
  nodeName: string;
  nodeLink: string;
  nodeParams?: { [param: string]: string };
}

@Component({
  selector: 'breadcrumbs',
  templateUrl: './breadrumbs.component.html',
  styleUrls: [ './breadcrumbs.component.scss' ],
})
export class BreadcrumbsComponent {
  @Input() breadcrumbs: IBreadcrumbs[] = [];
}
