import { Component, Input, OnInit } from '@angular/core';
import { ResourceTypeEnum } from '@ui-kit/util/icon-util';

@Component({
  selector: 'shares-resource-avatar',
  templateUrl: './shares-resource-avatar.component.html',
})
export class SharesResourceAvatar implements OnInit {
  @Input() resource;
  @Input() size = 'normal';

  public resourceTypeValue;

  public groupTypeValue;

  public ngOnInit() {
    if (this.resource.groupType === 'ONEDRIVE') {
      this.resourceTypeValue = ResourceTypeEnum.ONEDRIVE;
      this.groupTypeValue = 'ONEDRIVE';
    } else {
      this.resourceTypeValue = ResourceTypeEnum.GROUP;
      this.groupTypeValue = this.resource.groupType;
    }
  }
}
