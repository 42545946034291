import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MobileService } from '@common/services/mobile.service';
import { MultiSelectData } from '@ui-kit/components/ui-multi-select/multi-select-data';
import { MultiSelectDataFactory } from 'projects/@common/modules/i18n/component-wrapper/multi-select-data.factory';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { FileRiskEnum } from 'projects/@common/services/api/sg/shares/shares.definitions';

@Component({
  selector: 'resources-shares-mobile',
  templateUrl: './resources-shares-mobile.component.html',
  styleUrls: ['./resources-shares-mobile.component.scss'],
})
export class ResourcesSharesMobileComponent implements OnInit {
  @Input() resources;

  @Input() isLoading;
  @Output() public onRefresh = new EventEmitter<void>();
  @Output() public onRiskFilter = new EventEmitter<string[]>();
  @Output() public onSearch = new EventEmitter<string>();

  public textSearch = "";

  public riskMultiSelectData: MultiSelectData;
  public shareRisksFilter = FileRiskEnum;
  public defaultRiskFilter = this.route.snapshot.queryParams.sharesRiskFilter;

  constructor(
    public mobileService: MobileService,
    public router: Router,
    private route: ActivatedRoute,
    protected readonly i18n: I18nService,
    private multiSelectFactory: MultiSelectDataFactory
  ) {
  }

  ngOnInit() {
    this.riskMultiSelectData = this.multiSelectFactory.create(this.shareRisksFilter, this.defaultRiskFilter ? [this.defaultRiskFilter] : [], 'file.risk.');
  }

  public refresh(): void {
    this.onRefresh.emit();
  }

  public sortResources(filter: string[]) {
    this.onRiskFilter.emit(filter);
  }

  public clearTextSearch() {
    this.textSearch = null;
    this.onSearch.emit(this.textSearch);
  }

  public textSearchAction(query: string) {
    this.textSearch = query;
    this.onSearch.emit(this.textSearch);
  }

  public handleClick(resource): void {
    this.router.navigate(['myshares/resource'], {
      queryParams: {
        type: resource.groupType,
        guid: resource.guid,
        name: resource.name,
      },
    });
  }

  public convertAndMapFileRiskToSeverity(resource: any): any {
    const riskLowercase = resource.risk.toLowerCase();
    return { risk: riskLowercase, translatedRisk: this.i18n.translate(`shares.shareRiskScore.${riskLowercase}`) };
  }
}
