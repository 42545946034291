import { Directive, ElementRef, HostListener } from '@angular/core';
import { AsYouType } from 'libphonenumber-js/min';

const asYouTypeFormatter = new AsYouType();

@Directive({
  selector: '[phoneNumberMask]',
})
export class PhoneMaskDirective {
  constructor(private el: ElementRef) { }

  @HostListener('input', [ '$event' ]) onInputChange(event: InputEvent) {
    asYouTypeFormatter.reset();

    const rawValue = this.el.nativeElement.value;
    const formattedValue = asYouTypeFormatter.input(rawValue);

    this.el.nativeElement.value = formattedValue;
  }
}
