// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { EnvConfiguration } from "projects/@common/definitions/env.definitions";
import { Stages } from "projects/@common/definitions/stages";


export const environment: EnvConfiguration = {
  stage: Stages.DEV,
  admUrl: 'https://adm.dev.f2s.io',
  orgUrl: 'https://org.dev.f2s.io',
  ecoUrls: {
    defaultUrl: 'https://dev.eco.api.f2s.io',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
