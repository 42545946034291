import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UiTableDirection } from '@ui-kit/components/ui-table/ui-table.component';
import { RevokeTypeEnum } from 'projects/@assist-ops/components/revoke-shares-dialog/revoke-shares-dialog.component';
import { ApiEndpointPrefixEnum, EcoApi } from 'projects/@common/definitions/eco-api';
import { FeatureFlagsEnum } from 'projects/@common/modules/layout/definitions/base-sidebar.service';
import { Eco } from '../../../../definitions/eco';
import { UrlUtils } from '../../../../utils/utils';
import { EcoUrlService } from '../../core/url.service';
import { ResourcesTemporaryDataService } from '../temporary-data/resources-temporary-data.service';
import { SharesTemporaryDataService } from '../temporary-data/shares-temporary-data.service';
import {
  FileRiskEnum,
  FileShareLinkPermissionsTypeEnum,
  FileShareTypeEnum,
  FileTypeEnum,
  IFileActivity,
  IFilesSharesTypeCounts,
  IFilesTypesNumbers,
  IResourceSharedFilesStats,
  ISharedFile,
  ISharedFilePermission,
  ISharedFilesRevokeConditions,
  ISharedFilesStats,
  ISharedFilesTendency,
  SharedFilesResourceTypeEnum,
  TendencyTimeRangesEnum,
  UserSharedFile,
} from './shares.definitions';
import IFetchResult = Eco.IFetchResult;

@Injectable({
  providedIn: 'root',
})
export class SharesApiService extends EcoApi {
  protected prefix = ApiEndpointPrefixEnum.sg;

  constructor(
    readonly http: HttpClient,
    url: EcoUrlService,
    private sharesTemporaryDataService: SharesTemporaryDataService,
    private resourcesTemporaryDataService: ResourcesTemporaryDataService
  ) {
    super(http, url);
  }

  public async getAutomaticRevokeActivated(): Promise<{ isActivated: boolean }> {
    const url = `${this.host}/sharedFiles/automaticRevokeActivated`;
    return this.http.get<{ isActivated: boolean }>(url).toPromise();
  }

  public async getSharedFileActivities(
    sharedFileId: string,
    params: {
      from?: number;
      size?: number;
    } = {}
  ): Promise<IFileActivity[]> {
    const url = `${this.host}/sharedFiles/${sharedFileId}/activities?${UrlUtils.jsonToQueryParams(params)}`;
    return this.http.get<IFileActivity[]>(url).toPromise();
  }

  public async revokeSharedFiles(sharedFilesGuids: string[]): Promise<any> {
    const url = `${this.host}/sharedFiles/revoke`;
    const result: any = await this.http.post(url, { ids: sharedFilesGuids }).toPromise();
    this.sharesTemporaryDataService.addRevokeShares(result.jobGuid, result.sharedFileIds);
  }

  public async removeSharedFilePermissions(sharedFileId: string, permissionsToRemove: string[]): Promise<void> {
    const result: any = await this.http
      .post(`${this.host}/sharedFiles/${sharedFileId}/permissions`, {
        permissionIds: permissionsToRemove,
      })
      .toPromise();
    this.sharesTemporaryDataService.addRevokeShares(result.jobGuid, result.sharedFileIds);
  }

  public async getSharedFilePermissions(
    sharedFileId: string,
    params: { from?: number; size?: number; sort?: string; direction?: UiTableDirection } = {}
  ): Promise<IFetchResult<ISharedFilePermission>> {
    const url = `${this.host}/sharedFiles/${sharedFileId}/permissions?${UrlUtils.jsonToQueryParams(params)}`;
    return this.http.get<IFetchResult<ISharedFilePermission>>(url).toPromise();
  }

  public async getSharedFileDetails(guid: string): Promise<ISharedFile> {
    return this.http.get<ISharedFile>(`${this.host}/sharedFiles/${guid}/details`).toPromise();
  }

  public async getMyFilesSharesTypesNumbers(
    params: {
      sharedSince?: number;
      inactiveSince?: number;
      includeSilenced?: boolean;
    } = { includeSilenced: false }
  ): Promise<IFilesTypesNumbers> {
    return this.http.get(`${this.host}/sharedFiles/revoke/stats?${UrlUtils.jsonToQueryParams(params)}`).toPromise();
  }

  public async getResourceMonitoringStats(params: { risk?: FileRiskEnum } = {}): Promise<IResourceSharedFilesStats> {
    const url = `${this.host}/resources/shares/stats?${UrlUtils.jsonToQueryParams(params)}`;
    return this.http.get<any>(url, ).toPromise();
  }

  public async revokeMySharesInBatch(
    revokeConditions: ISharedFilesRevokeConditions,
    actionType: RevokeTypeEnum
  ): Promise<void> {
    const result: any = await this.http
      .post(`${this.host}/sharedFiles/revoke/conditions?${UrlUtils.jsonToQueryParams(revokeConditions)}`, {
        actionType,
      })
      .toPromise();
    this.sharesTemporaryDataService.addRevokeShares(result.jobGuid, result.sharedFileIds);
  }

  public async getResourcesWithSharesReportingTo(
    params: {
      from?: number;
      size?: number;
      sort?: string;
      direction?: UiTableDirection;
      resourcesFilter?: SharedFilesResourceTypeEnum[];
      risks?: FileRiskEnum[];
      fulltext?: any;
    },
    userIds?: string[]
  ): Promise<IFetchResult<any>> {
    const url = `${this.host}/shares/resources/reporting?${UrlUtils.jsonToQueryParams(params)}`;
    return this.http.post<any>(url, { userIds }).toPromise();
  }

  public async getResourcesWithSharesOrg(params: {
    from?: number;
    size?: number;
    sort?: string;
    direction?: UiTableDirection;
    resourcesFilter?: SharedFilesResourceTypeEnum[];
    risks?: FileRiskEnum[];
    fulltext?: any;
  }): Promise<IFetchResult<any>> {
    const url = `${this.host}/org/shares/resources?${UrlUtils.jsonToQueryParams(params)}`;
    return this.http.get<any>(url).toPromise();
  }

  public async getUsersWithSharesOrg(params: {
    from: number;
    size: number;
    sort?: string;
    direction?: UiTableDirection;
    resourcesFilter?: SharedFilesResourceTypeEnum[];
    risks?: FileRiskEnum[];
    fulltext?: any;
  }): Promise<IFetchResult<any>> {
    const url = `${this.host}/org/shares/users?${UrlUtils.jsonToQueryParams(params)}`;
    return this.http.get<any>(url).toPromise();
  }

  public async revokeSharesInBatch(revokeConditions: ISharedFilesRevokeConditions): Promise<void> {
    await this.http
      .post(`${this.host}/sharedFiles/revoke/conditions?${UrlUtils.jsonToQueryParams(revokeConditions)}`, {})
      .toPromise();
  }

  public async revokeResourceSharedFilesByConditions(
    resourceId: string,
    revokeConditions: ISharedFilesRevokeConditions,
    actionType: RevokeTypeEnum
  ): Promise<void> {
    const result: any = await this.http
      .post(`${this.host}/resources/${resourceId}/revoke/conditions?${UrlUtils.jsonToQueryParams(revokeConditions)}`, {
        actionType,
      })
      .toPromise();
    this.sharesTemporaryDataService.addRevokeShares(result.jobGuid, result.sharedFileIds);
    this.resourcesTemporaryDataService.addRevokeResources({
      jobGuid: result.jobGuid,
      siteIds: result.siteIds,
      listIds: result.listIds,
    });
  }

  public async getMyFilesSharesTypeCountsV2(): Promise<IFilesSharesTypeCounts> {
    const url = `${this.host}/sharedFiles/typeCounts`;
    return this.http.get<IFilesSharesTypeCounts>(url).toPromise();
  }

  public async getUserHighRiskCount(): Promise<{ files: number; folders: number }> {
    const url = `${this.host}/sharedFiles/typeCounts/user`;
    return this.http.get<{ files: number; folders: number }>(url).toPromise();
  }

  public async getMyShares(params: {
    from: number;
    size: number;
    sort?: string;
    direction?: UiTableDirection;
    fulltext?: string;
    risks?: FileRiskEnum[];
    shareTypes?: FileShareTypeEnum[];
    permissions?: FileShareLinkPermissionsTypeEnum[];
    fileTypes?: FileTypeEnum[];
  }): Promise<IFetchResult<UserSharedFile>> {
    return this.http
      .get<IFetchResult<UserSharedFile>>(`${this.host}/sharedFiles/mySharedFiles?${UrlUtils.jsonToQueryParams(params)}`)
      .toPromise();
  }

  public async getResourceMonitoringStatsAdmin(
    params: {
      risk?: FileRiskEnum;
    } = {}
  ): Promise<IResourceSharedFilesStats> {
    const url = `${this.host}/admin/resources/shares/stats?${UrlUtils.jsonToQueryParams(params)}`;
    return this.http.get<any>(url).toPromise();
  }

  public async getSharedFilesTendency(params: { gte?: TendencyTimeRangesEnum } = {}): Promise<ISharedFilesTendency[]> {
    const url = `${this.host}/sharedFiles/tendency?${UrlUtils.jsonToQueryParams(params)}`;
    return this.http.get<any>(url).toPromise();
  }

  public async getSharedFilesMonitoringStats(
    limitToReportingTo: boolean,
    fulltext?: string,
    userIds?: string[]
  ): Promise<ISharedFilesStats> {
    const url = `${this.host}/sharedFiles/stats?${UrlUtils.jsonToQueryParams({ fulltext, limitToReportingTo })}`;
    return this.http.post<any>(url, { userIds }).toPromise();
  }

  public async getUserSharesOrg(
    guid: string,
    params: {
      from: number;
      size: number;
      sort?: string;
      direction?: UiTableDirection;
      fulltext?: any;
      risks?: FileRiskEnum[];
      shareTypes?: FileShareTypeEnum[];
      permissions?: FileShareLinkPermissionsTypeEnum[];
      fileTypes?: FileTypeEnum[];
    }
  ): Promise<IFetchResult<UserSharedFile>> {
    return this.http
      .get<IFetchResult<UserSharedFile>>(`${this.host}/org/users/${guid}/shares?${UrlUtils.jsonToQueryParams(params)}`)
      .toPromise();
  }

  public async getSharedFileDetailsOrg(guid: string): Promise<ISharedFile> {
    return this.http.get<ISharedFile>(`${this.host}/org/sharedFiles/${guid}/details`).toPromise();
  }

  public async getSharedFileActivitiesOrg(
    sharedFileId: string,
    params: {
      from?: number;
      size?: number;
    } = {}
  ): Promise<IFileActivity[]> {
    const url = `${this.host}/org/sharedFiles/${sharedFileId}/activities?${UrlUtils.jsonToQueryParams(params)}`;
    return this.http.get<any>(url).toPromise();
  }

  public async getSharedFilePermissionsOrg(
    sharedFileId: string,
    params: { from?: number; size?: number; sort?: string; direction?: UiTableDirection } = {}
  ): Promise<IFetchResult<ISharedFilePermission> | any> {
    const url = `${this.host}/org/sharedFiles/${sharedFileId}/permissions?${UrlUtils.jsonToQueryParams(params)}`;
    return this.http.get<any>(url).toPromise();
  }

  public async silenceSharedFileNotifications(
    sharedFileId: string,
    payload: { silenceDuration: number; justification: string }
  ): Promise<void> {
    const url = `${this.host}/sharedFiles/${sharedFileId}/silence`;
    return this.http.patch<any>(url, payload).toPromise();
  }

  public async getSharesBySeverityLevel(): Promise<any> {
    const url = `${this.host}/stats/org/shares-by-sensitivity`;
    return this.http.get<any>(url, this.getFlagParams({ flags: [FeatureFlagsEnum.SHARES_MANAGEMENT] })).toPromise();
  }
}
