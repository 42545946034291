import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import * as am4core from '@amcharts/amcharts4/core';
import { Store } from '@ngxs/store';
import { Router } from '@angular/router';
import { SharesApiService } from 'projects/@common/services/api/sg/shares/shares-api.service';
import { Notice, NoticeLevels, NoticeService } from 'projects/@common/modules/notice/notice.service';
import { TeamsApiService } from 'projects/@common/services/api/sg/teams/teams-api.service';
import { EcoSessionState } from 'projects/@common/modules/session/state/session.state';
import { I18nService } from 'projects/@common/modules/i18n/i18n.service';
import { IFilesSharesTypeCounts, FileRiskEnum, ChartTypeEnum } from 'projects/@common/services/api/sg/shares/shares.definitions';
import { MobileService } from 'projects/@common/services/mobile.service';

@Component({
  selector: 'shares-type-proportions-v2',
  templateUrl: './shares-type-proportions-v2.component.html',
  styleUrls: [ './shares-type-proportions-v2.component.scss' ],
  encapsulation: ViewEncapsulation.None,
})
export class SharesTypeProportionsComponentV2 implements OnInit {
  public isLoading = false;

  public sharesTypeCounts: IFilesSharesTypeCounts;

  public sharesTypeCountsChartData: any[] = [];

  public chartType = ChartTypeEnum.DONUT;

  public series: string[] = [];

  public key: string;

  public isDataEmpty: boolean;

  constructor(
    private sharesService: SharesApiService,
    private i18nService: I18nService,
    private store: Store,
    private router: Router,
    public mobileService: MobileService,
    private noticeService: NoticeService,
    private teamsService: TeamsApiService
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.sharesService
      .getMyFilesSharesTypeCountsV2()
      .then((counts) => {
        this.sharesTypeCounts = counts;
        this.formatData();
      })
      .catch(() => {
        this.noticeService.notifyUser(new Notice('common.error.retry', NoticeLevels.ERROR));
      })
      .finally(() => {
        this.isLoading = false;
        this.isDataEmpty = this.sharesTypeCountsChartData.filter((data) => data.count !== 0).length === 0;
      });
  }

  private formatData() {
    const sortedCounts = Object.entries(this.sharesTypeCounts)
      .sort(([ , a ], [ , b ]) => b - a)
      .reduce((r, [ k, v ]) => ({ ...r, [k]: v }), {});
    // eslint-disable-next-line guard-for-in
    for (const fileShareType in sortedCounts) {
      const shareTypeKey = `dashboard.sharesTypeProportions.chart.legend.${fileShareType}`;
      const translatedShareType = this.i18nService.translate(shareTypeKey);

      this.sharesTypeCountsChartData.push({
        shareType: translatedShareType,
        count: this.sharesTypeCounts[fileShareType],
        color: this.getChartColor(fileShareType),
      });
    }

    this.series = [ 'count' ];
    this.key = 'shareType';
  }

  private getChartColor(fileShareType: string): am4core.Color {
    switch (fileShareType) {
      case 'company':
        return am4core.color('#47c1bf');
      case 'members':
        return am4core.color('#ff7800');
      case 'securityGroups':
        return am4core.color('#1e88e5');
      case 'guests':
        return am4core.color('#de3618');
      case 'publicLinks':
        return am4core.color('#9575cd');
      default:
        return am4core.color('#000000');
    }
  }

  public async handleReviewClick(): Promise<void> {
    const userGuid = this.store.selectSnapshot(EcoSessionState.userGuid);
    this.router.navigate([ `myshares` ], { queryParams: { userGuid: userGuid, riskFilter: FileRiskEnum.HIGH } });
  }
}
